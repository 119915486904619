/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/require-default-props */
/* eslint-disable no-restricted-globals */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@material-ui/core/Modal";
import { SAVE_DISCORD_ID } from "../../../../redux/Dashboard/Dashboard.types";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { Tooltip } from "@material-ui/core";
import FindServerIDModal from "./FindServerIDModal";

// const DiscordOauth2 = require("discord-oauth2");
// const oauth = new DiscordOauth2();

function SlackLogin({ onSuccess, onFailure }) {
  const dispatch = useDispatch();
  const [code, setCode] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  // useEffect(() => {
  //   if (code.length > 10) {
  //     oauth
  //       .tokenRequest({
  //         clientId: "953744657785974914",
  //         clientSecret: "O0p4LXYufPJOCSCCcPtXs_Jps6nH1-PA",
  //         code: code,
  //         scope: "identify guilds",
  //         grantType: "authorization_code",

  //         redirectUri: "http://localhost:3000",
  //       })
  //       .then(console.log("done"));
  //   }
  // }, [code]);

  function openPopup() {
    const width = 400;
    const height = 600;
    const left = screen.width / 2 - width / 2;
    const top = screen.height / 2 - height / 2;

    return window.open(
      "https://discord.com/oauth2/authorize?client_id=953272762091331614&permissions=66560&scope=bot",
      // "https://discord.com/oauth2/authorize?client_id=953744657785974914&scope=bot&permissions=8",
      // "https://discord.com/api/oauth2/authorize?client_id=953744657785974914&redirect_uri=http%3A%2F%2Flocalhost%3A3000&response_type=code&scope=email%20guilds%20messages.read",
      "",
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
    );
  }

  function polling(popup) {
    const pollingInterval = setInterval(() => {
      if (!popup || popup.closed || popup.closed === undefined) {
        clearInterval(pollingInterval);
        onFailure("Popup has been closed by user");
      }

      const closeDialog = () => {
        clearInterval(pollingInterval);
        popup.close();
      };
      try {
        function getCode(link) {
          const startPos = link.indexOf("=") + 1;
          const endPos = link.indexOf("#");
          return link.substring(startPos, link.length - 2);
        }
        if (popup.closed) {
          // return onSuccess();
        }
        // if (!popup.location.hostname.includes("discord.com")) {
        //   if (popup.location.search) {
        //     const currentValueCode = getCode(popup.location.href);
        //     if (currentValueCode) {
        //       closeDialog();
        //       setCode(currentValueCode);
        //       console.log(currentValueCode);
        //       return onSuccess();
        //     }
        //     if (onFailure) {
        //     }
        //   }
        // }
        if (!popup.location.hostname.includes("authorized")) {
          if (popup.location.search) {
            const currentValueCode = getCode(popup.location.href);
            if (currentValueCode) {
              closeDialog();
              return onSuccess();
            }
            if (onFailure) {
            }
          }
        }
      } catch (error) {}
    }, 1000);
  }

  function handleClose() {
    setModalOpen(false);
  }
  function handleClick(e) {
    e.preventDefault();
    polling(openPopup());
  }
  const isDemo = JSON.parse(localStorage.getItem("demoMode"))

  const [serverID, setServerID] = useState("");
  function saveID() {
    !isDemo && dispatch({ type: SAVE_DISCORD_ID, payload: { server_id: serverID } });
  }
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  return (
    <>
      {" "}
      <p style={{ marginBottom: "0", fontSize: "0.9rem", color: !isNight ? "#aebcc9" : "#000" }}>
        1. Add our bot to discord
      </p>
      <button
        onClick={(e) => handleClick(e)}
        style={{
          background: "#6352c6",
          margin: " 10px 0",
          padding: "0.5rem",
          fontSize: "1rem",
          color: "#fff",
	  border:"none",
          borderRadius: "5px",
        }}
      >
        Add bot
      </button>
      <p style={{ marginBottom: "0", fontSize: "0.9rem", color: !isNight ? "#aebcc9" : "#000" }}>
        2. Enter your discord server ID{" "}
        <span
          style={{ marginBottom: "0", fontSize: "0.9rem", cursor: "pointer" }}
        >
          {" "}
          <Tooltip title="How to find  your server ID?">
            <HelpOutlineIcon
              onClick={() => setModalOpen(true)}
              style={{ fontSize: "1rem", position: "relative", top: "3px" }}
            />
          </Tooltip>
          <Modal open={modalOpen} onClose={handleClose}>
            <FindServerIDModal open={modalOpen} handleClose={handleClose} />
          </Modal>
        </span>
      </p>
      <input
        type="text"
        onChange={(e) => {
          setServerID(e.target.value);
        }}
        style={{
          padding: "5px 10px",
          borderRadius: "4px",
          border: "none !important",
          outline: "none",
          backgroundColor: "#313150 !important",
          // padding-left: 15px;
          color: "#000",
        }}
      /><br/>
      <button
        onClick={saveID}
        style={{
          background: "#6352c6",
          margin: " 10px 0",
          padding: "0.5rem",
          fontSize: "1rem",
          color: "#fff",
	  border:"none",
          borderRadius: "5px",
        }}
      >
        Submit
      </button>
    </>
  );
}

SlackLogin.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onFailure: PropTypes.func,
};

export default SlackLogin;
