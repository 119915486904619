import { Box, Typography } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { Card, CardContent, Grid, Paper, Container } from "@mui/material";
import MoraleResults from "./Recommendation/Result/MoraleResults";
import EngagementResult from "./Recommendation/Result/EngagementResult";
import { useSelector } from "react-redux";
import { demoActionPlans } from "./Recommendation/demoActionPlans";
import { demoFocusGroup } from "../Feedback/demoSurveys";
import LinkIcon from "@mui/icons-material/Link";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";

function formatQuery(query) {
  // Parse the JSON string into an object
  if (!query || query === "overall") {
    return "Overall";
  } else {
    let queryObject = typeof query === "string" ? JSON.parse(query) : query;

    // Map for comparison operators
    const comparisonMap = {
      $lte: "<=",
      $gte: ">=",
      $lt: "<",
      $gt: ">",
    };

    let result = [];

    // Loop through each key-value pair in the object
    for (let [key, value] of Object.entries(queryObject)) {
      // Capitalize the key (replace underscores with spaces and capitalize words)
      let formattedKey = key
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());

      // Check if value is an object (for comparison operators like $lte, $gte, etc.)
      if (typeof value === "object" && value !== null) {
        for (let [operator, comparisonValue] of Object.entries(value)) {
          // Map the operator to its symbol or keep it as is
          let comparison = comparisonMap[operator] || operator;
          result.push(`${formattedKey}: ${comparison} ${comparisonValue}`);
        }
      } else {
        // For simple key-value pairs, just capitalize the value and append
        let formattedValue = value
          .toString()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        result.push(`${formattedKey}: ${formattedValue}`);
      }
    }

    // Join the results into a string
    return result.join(", ");
  }
}

export default function ResultDetails({ planID }) {
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const actionPlans = isDemo
    ? demoActionPlans
    : useSelector((data) => data.dashboard.actionPlans);

  const focusGroups = isDemo
    ? demoFocusGroup
    : useSelector((data) => data.dashboard.focusGroups);

  const [currentPlan, setCurrentPlan] = useState({});
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  useEffect(() => {
    setCurrentPlan(actionPlans.filter((item) => item._id === planID)[0]);
  }, [actionPlans]);

  return (
    <Box
      style={{
        margin: "2rem",
        padding: "1rem 2rem",
        minHeight: "700px",
        background: "#fff",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "start" }}>
        <Link
          to={`/dashboard/action-plan/result`}
          style={{ textDecoration: "none", color: "#484848" }}
        >
          <Button
            variant="outlined"
            style={{
              padding: "0.5rem 0",
              border: "2px solid #D06283",
              color: "#D06283",
              margin: "1rem 0",
            }}
          >
            <ArrowBackIcon />
          </Button>
        </Link>
      </Box>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          variant="h5"
          align="left"
          style={{ margin: "2rem 1rem 1rem 0", fontWeight: "bold" }}
          gutterBottom
        >
          {currentPlan?.objective}
        </Typography>
        {(currentPlan.status === "active" ||
          currentPlan.status === "completed") && (
          <Box style={{ margin: "2rem 0 0" }}>
            <Typography
              style={{
                margin: "0",
                textAlign: "left",
              }}
            >
              <span style={{ fontWeight: "bold" }}>Start Date:</span>{" "}
              {moment(currentPlan.startDate).format("MMM DD yyyy")}
            </Typography>
            <Typography
              style={{
                margin: "0",
                textAlign: "left",
              }}
            >
              <span style={{ fontWeight: "bold" }}>End Date:</span> {}
              {moment(currentPlan.endDate).format("MMM DD yyyy")}
            </Typography>
          </Box>
        )}
      </Box>
      <Typography
        style={{
          margin: "2rem 0 1rem",
          textAlign: "left",
        }}
      >
        <span style={{ fontWeight: "bold" }}>Purpose:</span>{" "}
        {currentPlan.reason}
      </Typography>
      <Typography style={{ textAlign: "left", margin: "0 " }} gutterBottom>
        <span style={{ fontWeight: "bold" }}>Source: </span>
        {!currentPlan.type ? "Insight" : currentPlan.type}
      </Typography>
      <Typography style={{ textAlign: "left", margin: "1rem 0 " }} gutterBottom>
        <span style={{ fontWeight: "bold" }}>Insight: </span>
        {currentPlan.statement}
      </Typography>
      {currentPlan.type === "Focus Group" && currentPlan.focusGroupID && (
        <Typography
          style={{ textAlign: "left", margin: "1rem 0 " }}
          gutterBottom
        >
          <span style={{ fontWeight: "bold" }}>Focus Group: </span>
          {`${
            focusGroups.filter(
              (item) => item._id === currentPlan.focusGroupID
            )[0].topic
          } Focus Group`}
          <Link
            to={`/dashboard/feedback/focus-group-res/${currentPlan.focusGroupID}`}
            target="_blank"
            style={{ textDecoration: "none", color: "#484848" }}
          >
            <LinkIcon
              style={{ margin: "0 0.5rem", position: "relative", top: "5px" }}
            />
          </Link>
        </Typography>
      )}

      <Typography
        style={{
          textAlign: "left",
          margin: "1rem 0 1rem",
          textTransform: "capitalize",
        }}
        gutterBottom
      >
        <span style={{ fontWeight: "bold" }}>Metric: </span>
        {currentPlan.section}
      </Typography>
      <Typography
        style={{
          margin: "1rem 0 1rem",
          textAlign: "left",
        }}
      >
        <span style={{ fontWeight: "bold" }}>Target Group:</span>{" "}
        {currentPlan.targetGroup === ""
          ? "Overall"
          : formatQuery(currentPlan.targetGroup)}
      </Typography>
      <Typography
        style={{
          margin: "2rem 0 0",
          textAlign: "left",
          fontSize: "1.3rem",
          fontWeight: "bold",
        }}
      >
        Steps
      </Typography>
      <Grid container>
        {currentPlan?.steps?.map((step, index) => (
          <Grid item xs={12} key={index}>
            <Box style={{ margin: "1rem" }}>
              <Typography variant="h6" gutterBottom align="left">
                <span style={{ fontWeight: "bold" }}>Action:</span>{" "}
                {step?.action}
              </Typography>
              <Typography variant="body1" gutterBottom align="left">
                <span style={{ fontWeight: "bold" }}>Description:</span>{" "}
                {step?.description}
              </Typography>
              <Typography variant="body2" align="left">
                <span style={{ fontWeight: "bold" }}>Implementation:</span>{" "}
                {step?.implementation}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <hr />
      <Box>
        {currentPlan.section === "morale" && <MoraleResults />}
        {currentPlan.section === "engagement" && <EngagementResult />}
        {currentPlan.section === "retention" && <MoraleResults />}
        {currentPlan.section === "eNPS" && <MoraleResults />}
        {currentPlan.section === "topic" && <MoraleResults />}
        {currentPlan.section === "culture" && <MoraleResults />}
      </Box>
    </Box>
  );
}
