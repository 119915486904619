import React from "react";
import { useSelector } from "react-redux";
import "./index.css";
import ReactECharts from "echarts-for-react";
import { graphic } from "echarts";
import * as echarts from "echarts";
import { ICONS } from "../../../../AppConfig";

const TopStatusBoxGauge = (props) => {
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const option = {
    tooltip: {
      confine: true,
      formatter: "{b}: {c}",
    },
    // color: colorPalette,
    series: [
      {
        name: props.name,
        // lineStyle: {
        //   width: 6,
        // },
        startAngle: 224,
        endAngle: 316,
        min: props.min,
        max: props.max,
        type: "gauge",
        // progress: {
        //   show: true,
        //   roundCap: true,
        //   width: 15,
        // },
        axisLine: {
          roundCap: true,
          // lineStyle: {
          //   color: !isNight ? [[1, "#fff"]] : [[1, "#EDF9FF"]],
          //   width: 15,
          // },
          lineStyle: {
            width: 8,
            color:
              props.name === "Negative Culture" ||
              props.name === "Retention Risk"
                ? [
                    [0.3, "#04B8AF"],
                    [0.7, "#9FDCD9"],
                    [1, "#D06283"],
                  ]
                : [
                    [0.3, "#D06283"],
                    [0.7, "#9FDCD9"],
                    [1, "#04B8AF"],
                  ],
          },
        },

        splitLine: {
          length: 8,
          distance: 5,
          lineStyle: {
            width: 1,
            color: !isNight ? "#aebcc9" : "#666",
          },
        },
        axisLabel: {
          distance: 5,
          color: !isNight ? "#aebcc9" : "#666",
          formatter: function (value) {
            return value.toFixed(0);
          },
        },
        splitNumber: 5,
        axisTick: {
          show: true,
          distance: 0,
          lineStyle: {
            color: !isNight ? "#aebcc9" : "#666",
          },
        },

        pointer: {
          icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
        },
        anchor: {
          show: true,
          showAbove: true,
          size: 18,
          itemStyle: {
            borderWidth: 5,
            color: "#484848",
            borderColor: "#fff",
            shadowColor: "rgba(0, 0, 0, 0.5)",
            shadowBlur: 8,
          },
        },
        title: {
          show: false,
        },
        data: [
          {
            value: props.value,
            name:
              props.name === "Morale"
                ? "Average Daily Morale Score"
                : props.name === "Text Engagement"
                ? "Average Daily Number of Messages"
                : props.name === "Retention Risk"
                ? "Average Retention Risk"
                : props.name === "Positive Culture"
                ? "Average Daily Number of Positive Culture Messages"
                : props.name === "Negative Culture"
                ? "Average Daily Number of Negative Culture Messages"
                : `Overall Average ${props.name}`,
            pointer: {
              // icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
              icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
              length: "75%",

              width: 8,
              offsetCenter: [0, "0"],
              itemStyle: {
                color: "#D06283",
              },
            },
            detail: {
              valueAnimation: true,
              formatter:
                props.name === "Morale"
                  ? "{value}"
                  : props.name === "Retention Risk"
                  ? "{value}%"
                  : "{value}",
              offsetCenter: [0, "90%"],
              fontSize: 23,
              borderRadius: 8,
              color: !isNight ? "#fff" : "#333",
            },
          },
          {
            itemStyle: {
              color: "none",
            },
            value: Math.round(props.minMax.min),
            name:
              props.name === "Morale"
                ? "Lowest Daily Morale Score"
                : props.name === "Text Engagement"
                ? "Lowest Daily Number of Messages"
                : props.name === "Retention Risk"
                ? "Lowest Retention Risk"
                : props.name === "Positive Culture"
                ? "Lowest Daily Number of Positive Culture Messages"
                : props.name === "Negative Culture"
                ? "Lowest Daily Number of Negative Culture Messages"
                : `Lowest Daily Average ${props.name}`,
            pointer: {
              length: "12%",
              width: 8,
              offsetCenter: [0, "-80"],
              itemStyle: {
                color: "#D06283",
              },
            },
            detail: {
              show: false,
            },
          },
          {
            itemStyle: {
              color: "none",
            },
            value: Math.round(props.minMax.max),
            name:
              props.name === "Morale"
                ? "Highest Daily Morale Score"
                : props.name === "Text Engagement"
                ? "Highest Daily Number of Messages"
                : props.name === "Retention Risk"
                ? "Highest Retention Risk"
                : props.name === "Positive Culture"
                ? "Highest Daily Number of Positive Culture Messages"
                : props.name === "Negative Culture"
                ? "Highest Daily Number of Negative Culture Messages"
                : `Highest Daily Average ${props.name}`,
            pointer: {
              length: "12%",
              width: 8,
              offsetCenter: [0, "-80"],
              itemStyle: {
                color: "#04B8AF",
              },
            },
            detail: {
              show: false,
            },
          },
        ],
      },
    ],
  };

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <ReactECharts option={option} />
      </div>
    </>
  );
};

TopStatusBoxGauge.defaultProps = {
  value: "",
  low: "",
  high: "",
};
export default TopStatusBoxGauge;
