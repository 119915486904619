import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Badge from "@mui/material/Badge";

import { useHistory } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@material-ui/core";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import EventIcon from "@mui/icons-material/Event";

const sampleNotifications = [
  {
    id: 1,
    title: "New Monthly Report",
    message: "The latest monthly performance report is now available.",
    timestamp: "5 minutes ago",
    read: false,
    type: "info",
  },
  {
    id: 2,
    title: "New Monthly Key Insights",
    message: "Discover the key insights from this month's employee data.",
    timestamp: "1 hour ago",
    read: false,
    type: "info",
  },
  {
    id: 3,
    title: "New Recommended Surveys",
    message: "Recommended surveys have been generated for employee engagement.",
    timestamp: "3 hours ago",
    read: true,
    type: "announcement",
  },
  {
    id: 4,
    title: "Survey Results Available",
    message:
      "Results for the latest employee morale survey are ready for review.",
    timestamp: "Yesterday",
    read: true,
    type: "success",
  },
  {
    id: 5,
    title: "New Recommended Focus Groups",
    message: "New focus groups have been recommended based on recent insights.",
    timestamp: "2 days ago",
    read: true,
    type: "announcement",
  },
  {
    id: 6,
    title: "Focus Group Results Available",
    message:
      "Results from the latest focus group discussions are now available.",
    timestamp: "3 days ago",
    read: false,
    type: "success",
  },
  {
    id: 7,
    title: "Recommended Action Plans Ready",
    message:
      "New action plans have been generated to address key employee feedback.",
    timestamp: "Last week",
    read: true,
    type: "performance",
  },
  {
    id: 8,
    title: "Action Plan Results Ready",
    message: "Results from the implemented action plans are now ready.",
    timestamp: "Last week",
    read: false,
    type: "success",
  },
];

export default function Notification({
  workflowChangeHandler,
  currentWorkflowName,
  currentWorkflowID,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const [open, setOpen] = useState(false);

  const toggleList = () => {
    setOpen(!open);
  };

  return (
    <>
      <div style={{ position: "relative", margin: "0 1rem" }}>
        <Badge
          badgeContent={sampleNotifications.filter((n) => !n.read).length}
          color="secondary"
        >
          <NotificationsNoneIcon
            onClick={toggleList}
            style={{ color: "#484848", cursor: "pointer" }}
          />
        </Badge>
        {open && (
          <div
            className="notification-panel"
            style={{
              position: "absolute",
              zIndex: 500,
              right: "0",
            }}
          >
            <div
              style={{
                width: "400px",
                height: "500px",
                background: "#fff",
                right: "0",

                borderRadius: "8px",
                boxShadow:
                  "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                position: "relative",
                top: "10px",
              }}
            >
              <CloseIcon
                aria-label="Close Notifications"
                onClick={() => {
                  setOpen(false);
                }}
                style={{
                  position: "absolute",
                  right: "10px",
                  top: "10px",
                  cursor: "pointer",
                }}
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "start",
                  flexDirection: "row",
                  padding: "1rem 0.5rem 0",
                }}
              >
                <Typography
                  style={{
                    fontSize: "1.3rem",
                    fontWeight: "bold",
                    textAlign: "left",
                    color: "#484848",
                  }}
                >
                  Notifications
                </Typography>
                <Typography
                  style={{
                    fontSize: "1rem",
                    textAlign: "left",
                    color: "#484848",
                    margin: "0.3rem 1rem",
                  }}
                >
                  {`${
                    sampleNotifications.filter((n) => !n.read).length
                  } unread`}
                </Typography>
              </div>
              <hr />
              <Box
                style={{
                  maxHeight: "440px",
                  borderRadius: "8px",
                  overflowY: "scroll",
                }}
              >
                {sampleNotifications.length === 0 ? (
                  <Typography
                    style={{
                      color: "#999",
                      textAlign: "center",
                      padding: "2rem",
                    }}
                  >
                    No new notifications
                  </Typography>
                ) : (
                  sampleNotifications.map((notification) => (
                    <Box
                      key={notification.id}
                      minHeight="70px"
                      style={{
                        backgroundColor: notification.read ? "#e0e0e0" : "#fff",
                        filter: notification.read
                          ? "opacity(0.7)"
                          : "opacity(1)",
                        padding: "0.5rem 0.5rem",
                        cursor: "pointer",
                      }}
                      //   onClick={() => markAsRead(notification.id)}
                    >
                      <Box display="flex">
                        <ErrorOutlineIcon style={{ color: "#D06283" }} />
                        <Typography
                          style={{
                            fontWeight: "bold",
                            color: "#484848",
                            margin: "0 0.5rem",
                          }}
                        >
                          {notification.title}
                        </Typography>
                      </Box>
                      <Typography
                        style={{ color: "#484848", textAlign: "left" }}
                      >
                        {notification.message}
                      </Typography>
                      <Typography
                        style={{
                          fontSize: "0.8rem",
                          color: "#484848",
                          textAlign: "left",
                        }}
                      >
                        {notification.timestamp}
                      </Typography>
                    </Box>
                  ))
                )}
              </Box>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
