import {
  CHANGE_LOGIN_SUCCESS,
  LOGIN_BY_LOCAL_STORAGE_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_SUCCESS, DEMO_LOGIN
} from "./Login.types";

const initialState = {
  userLoggedIn: false,
  loginData: {
    message: "",
  },
};

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { ...state, loginData: action.payload, userLoggedIn: true };
    case LOGIN_FAILURE:
      return { ...state, loginData: action.payload, userLoggedIn: false };
    case DEMO_LOGIN:
      return { ...state, loginData: action.payload, userLoggedIn: true };

    case LOGIN_BY_LOCAL_STORAGE_SUCCESS:
      return { ...state, loginData: action.payload.data, userLoggedIn: true };
    case CHANGE_LOGIN_SUCCESS:
      return {
        ...state,
        userLoggedIn: false,
        loginData: initialState.loginData,
      };
    default:
      return state;
  }
};

export default LoginReducer;
