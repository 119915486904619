import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function Source() {
  const onDragStart = (event, nodeType, messenger, tabType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.setData("application/messenger", messenger);
    event.dataTransfer.setData("application/tabType", tabType);
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.effectAllowed = "move";
  };
  const uploadedChannels = useSelector(
    (data) => data.dashboard.uploadSourceStatus
  );

  const checkSourceDiscord = useSelector(
    (data) => data.dashboard.checkSourceDiscord
  );

  const [channels, setChannels] = useState([]);

  useEffect(() => {
    if (uploadedChannels.status === "FAIL") {
      setChannels([]);
    } else if (uploadedChannels.status === "PASS") {
      setChannels(uploadedChannels.channels);
    } else if (uploadedChannels.status === "EMPTY") {
      setChannels([]);
    }
  }, [uploadedChannels]);

  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  return (
    <div className="filter-container">
      {isDemo && (
        <>
          <div
            onDragStart={(event) =>
              onDragStart(event, "default", "Development-team", "filter")
            }
            // eslint-disable-next-line react/no-array-index-key
            key={0}
            draggable
          >
            <p style={{ color: "#aebcc9" }}># {"Development Team"}</p>
          </div>
          <div
            onDragStart={(event) =>
              onDragStart(event, "default", "Marketing-team", "filter")
            }
            // eslint-disable-next-line react/no-array-index-key
            key={1}
            draggable
          >
            <p style={{ color: "#aebcc9" }}># {"Marketing Team"}</p>
          </div>
          <div
            onDragStart={(event) =>
              onDragStart(event, "default", "Sales-team", "filter")
            }
            // eslint-disable-next-line react/no-array-index-key
            key={2}
            draggable
          >
            <p style={{ color: "#aebcc9" }}># {"Sales Team"}</p>
          </div>
        </>
      )}

      {channels?.map((channel, index) => {
        return (
          <div
          style={!isNight?{color:"#aebcc9"}:{}}
            onDragStart={(event) =>
              onDragStart(event, "default", channel, "filter")
            }
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            draggable
          >
            <p># {channel}</p>
          </div>
        );
      })}
      {checkSourceDiscord ? (
        <p style={{ textAlign: "center" }}>
          {" "}
          You don't need to add filters for discord
        </p>
      ) : (
        <p></p>
      )}
    </div>
  );
}
