import React, { useState } from "react";
import "./styles/Custom.css";
import MenuIcon from "@mui/icons-material/Menu";
import ChatIcon from "@mui/icons-material/Chat";
import { Typography } from "@material-ui/core";
import AddIcon from '@mui/icons-material/Add';
import axios from "axios";
import moment from "moment";

const CustomNav = ({ chatlist, onSelectChat, isNavOpen, setIsNavOpen ,setNewChat }) => {
  const [selectedItem, setSelectedItem] = useState(null);

  const openClose = () => {
    setIsNavOpen(!isNavOpen);
  };

  const callChatHistory = async (chatID) => {
    const response = await axios.post(
      `https://api.trupulse.ai/HRAssistant`,
      {
        chatID: chatID,
      }
    );
  };

  // Group chats into "Today", "Last 7 Days", and "Last 30 Days"
  const groupedChats = chatlist.reduce((acc, chat) => {
    const chatDate = moment(chat.updatedAt);
    const today = moment().startOf('day');

    if (chatDate.isSame(today, 'day')) {
      acc["Today"].push(chat);
    } else if (chatDate.isBetween(today.clone().subtract(7, 'days'), today)) {
      acc["Last 7 Days"].push(chat);
    } else if (chatDate.isBetween(today.clone().subtract(30, 'days'), today.clone().subtract(7, 'days'))) {
      acc["Last 30 Days"].push(chat);
    } else {
      acc["Older"].push(chat);
    }
    return acc;
  }, {
    "Today": [],
    "Last 7 Days": [],
    "Last 30 Days": [],
    "Older": []
  });

  const chatSections = ["Today", "Last 7 Days", "Last 30 Days", "Older"];

  return (
    <nav
      className="navbar-menu"
      style={{
        borderRadius: "7px",
        height: "70vh",
        margin: "0.5rem",
      }}
    >
      <div className="burger" onClick={openClose}>
        {isNavOpen ? (
          <Typography
            variant="h5"
            style={{ fontWeight: "bold", color: "#484848" }}
          >
            <MenuIcon style={{ position: "relative", top: "5px" }} /> Chat History
          </Typography>
        ) : (
          <h1 style={{ fontFamily: "work sans-serif" }}>
            <MenuIcon />
          </h1>
        )}
      </div>

      <ul
        className="navbar__list"
        style={{
          overflowY: "auto",
          maxHeight: "calc(100vh - 35vh)",
          scrollbarWidth: "none",
          "-ms-overflow-style": "none",
          "&::-webkit-scrollbar": { display: "none" },
        }}
      >
        <div className="navbar__li-box" key={0}>
          <li
            className="navbar__li"
            style={{
              display: isNavOpen ? "inline-block" : "none",
              backgroundColor: selectedItem === null ? "#d06290" : "#e2d6dd",
              color: selectedItem === null ? "#fff" : "#484848",
              transition: "background-color 0.3s ease",
            }}
            onClick={() => {
              onSelectChat(null);
              setSelectedItem(null);
            }}
          >
            <Typography>
              <AddIcon style={{ margin: "1rem 0.5rem" }} />{" "}
              <span style={{ position: "relative", top: "-23px" }}
                onClick={() => {
                  onSelectChat(item.chatID);
                  setSelectedItem(item);
                  setNewChat(true);
              }}>
                New Chat
              </span>
            </Typography>
          </li>
        </div>

        {chatSections.map(section => (
          groupedChats[section].length > 0 && (
            <div key={section}>
              <div className="navbar__li-box" style={{ marginTop: "1rem" }}>
                <li className="navbar__li" style={{ display: isNavOpen ? "inline-block" : "none", backgroundColor: "#f0f0f0", borderTop: "1px solid #ccc", borderBottom: "1px solid #ccc", padding: "0.5rem", color: "#333", transition: "background-color 0.3s ease" }}>
                  <Typography variant="p" style={{ fontWeight: "bold", padding: "0.5rem", textAlign: "start" }}>
                    {section}
                  </Typography>
                </li>
              </div>
              {groupedChats[section].sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)).map((item, i) => (
                <div className="navbar__li-box" key={item.chatID}>
                  <li
                    className="navbar__li"
                    style={{
                      display: isNavOpen ? "inline-block" : "none",
                      backgroundColor: selectedItem === item ? "#d06290" : "#e2d6dd",
                      color: selectedItem === item ? "#fff" : "#484848",
                      transition: "background-color 0.3s ease",
                    }}
                    onClick={() => {
                      onSelectChat(item.chatID);
                      setSelectedItem(item);
                      setNewChat(false);
                    }}
                  >
                    <Typography style={{display:"flex"}}>
                      <ChatIcon style={{ margin: "1rem 0.5rem" }} />{" "}
                      <span style={{ position: "relative", top: "15px", whiteSpace: "nowrap",overflow: "hidden", textOverflow: "ellipsis", display: "inline-block", maxWidth: "230px"}}>
                        {item.chatName ? item.chatName : item.chatID}
                      </span>
                    </Typography>
                  </li>
                </div>
              ))}
            </div>
          )
        ))}
      </ul>
    </nav>
  );
};

export default CustomNav;