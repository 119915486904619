import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import "../../../styles/loginPage.css";
import "../../../styles/profilePage.css";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import KeyIcon from "@mui/icons-material/Key";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { useSelector, useDispatch } from "react-redux";
import "../../../styles/setting.css";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import Modal from "@material-ui/core/Modal";
import CompanyProfile from "./CompanyProfile";
import Configure from "./Configure";
import AddUserModal from "./AddUserModal";
import UpdateUserModal from "./UpdateUserModal";
import DeleteUserModal from "./DeleteUserModal";
import AssignLicenseModal from "./AssignLicenseModal";
import { GridColDef, GridApi, GridCellValue } from "@mui/x-data-grid";
import { GET_USERS } from "../../../redux/Dashboard/Dashboard.types";
import { Tooltip } from "@material-ui/core";
import EmpMgmt from "./EmpMgmt";
// import * as XLSX from 'xlsx';
import {
  GridToolbarExport,
  gridClasses,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import Step1Modal from "../Walkthrough/Setting/Step1Modal";
import Step2Modal from "../Walkthrough/Setting/Step2Modal";
import Step3Modal from "../Walkthrough/Setting/Step3Modal";
import Step4Modal from "../Walkthrough/Setting/Step4Modal";
import Step5Modal from "../Walkthrough/Setting/Step5Modal";

export default function SettingPage() {
  const dispatch = useDispatch();
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  const useStyles = makeStyles((theme) => ({
    root: {
      color: !isNight ? "#aebcc9 !important" : "#000! important",
      fontSize: 13,
      border: "none",
      backgroundColor: !isNight ? "#212047" : "#F6F7FC",
      fontWeight: "600",
    },
    tableRoot: {
      color: !isNight ? "#aebcc9" : "#000 !important",
      fontSize: 13,
      border: "none",
      backgroundColor: !isNight ? "#212047" : "#F6F7FC",
      fontWeight: "500",
      "& .MuiDataGrid-columnHeaderWrapper": {
        color: !isNight ? "#aebcc9" : "#000!important",
        backgroundColor: "#e5e5e5 !important",
      },
      "& .MuiTablePagination-root": {
        color: !isNight ? "#aebcc9" : "#000!important",
      },
      "& .Mui-disabled": {
        color: "rgba(255,255,255,0.5)!important",
      },
      "& .MuiIconButton-root": {
        color: "#6352C6!important",
      },
      "MuiDataGrid-columnHeaderTitle": {
        color: "#6352C6!important",
      },
    },

    container: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
      "& div": {
        color: "#fff",
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
      marginTop: 20,
      color: "rgba(0, 0, 0, 0.87)!important",
      borderBlockColor: "#6352C6",
    },
    input: {
      color: "#fff",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    paper: {
      padding: 0,
    },
    columnHeader: {
      backgroundColor: !isNight ? "#17123B" : "#e5e5e5",
      color: !isNight ? "#fff" : "#000",
    },
    cell: {
      "& .MuiDataGrid-sortIcon": {
        fill: "white!important",
        opacity: 1,
      },
      "& .MuiDataGrid-cellCheckbox": {
        borderColor: "white",
        color: "white",
      },
      "& 	.MuiDataGrid-iconSeparator": {
        fill: "white",
      },
    },
  }));

  const [editUserModal, setEditUserModal] = useState(false);
  const [deleteUserModal, setDeleteUserModal] = useState(false);
  const [assignLicenseModal, setAssignLicenseModal] = useState(false);
  const userManagementTableData = useSelector(
    (data) => data.dashboard.userManagementTableData
  );
  const userDetails = useSelector((data) => data.dashboard.userDetails);
  const [currentScreen, setCurrentScreen] = useState("setting");
  const [addUserModalOpen, setAddUserModalOpen] = useState(false);
  const [selectedMail, setSelectedMail] = useState("");
  const [selectedUser, setSelectedUser] = useState({});
  const classes = useStyles();

  const closeAddUserModal = () => {
    setAddUserModalOpen(false);
  };
  const closeAccessManageModal = () => {
    setEditUserModal(false);
  };
  const closeDeleteUserModal = () => {
    setDeleteUserModal(false);
  };
  const closeAssignLicenseModal = () => {
    setAssignLicenseModal(false);
  };
  // eslint-disable-next-line
  const [colDefs, setColDefs] = useState();
  // eslint-disable-next-line
  const [data, setData] = useState();

  // const getExention = (file) => {
  //   const parts = file.name.split('.')
  //   const extension = parts[parts.length - 1]
  //   return EXTENSIONS.includes(extension) // return boolean
  // }

  // const importExcel = (e) => {
  //   const file = e.target.files[0]

  //   const reader = new FileReader()
  //   reader.onload = (event) => {
  //     //parse data

  //     const bstr = event.target.result
  //     const workBook = XLSX.read(bstr, { type: "binary" })

  //     //get first sheet
  //     const workSheetName = workBook.SheetNames[0]
  //     const workSheet = workBook.Sheets[workSheetName]
  //     //convert to array
  //     const fileData = XLSX.utils.sheet_to_json(workSheet, { header: 1 })
  //     const headers = fileData[0]
  //     const heads = headers.map(head => ({ title: head, field: head }))
  //     setColDefs(heads)

  //     //removing header
  //     fileData.splice(0, 1)

  //     const tempData = [];
  //     fileData.forEach((item, idx) => {
  //       const cellData = {};
  //       cellData["id"] = idx + 1;
  //       cellData["firstName"] = item[1]
  //       cellData["lastName"] = item[2]
  //       cellData["email"] = item[3]
  //       tempData.push(cellData)
  //     })
  //     setData(tempData)
  //     setRows1(tempData)
  //   }

  //   if (file) {
  //     if (getExention(file)) {
  //       reader.readAsBinaryString(file)
  //     }
  //     else {
  //       alert("Invalid file input, Select Excel, CSV file")
  //     }
  //   } else {
  //     setData([])
  //     setColDefs([])
  //   }
  // }

  // const handleClose = () => {
  //   setAnchorEl1(null);
  // };

  // const handleClick = (event) => {
  //   setAnchorEl1(event.currentTarget);
  // };
  // const open = Boolean(anchorEl1);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport style={{ color: !isNight ? "#aebcc9" : "#000" }} />
      </GridToolbarContainer>
    );
  }

  const columns = [
    { field: "id", headerName: "S. No.", width: 120 },
    {
      field: "firstName",
      headerName: "First Name",
      width: 150,
      editable: false,
    },
    {
      field: "lastName",
      headerName: "Last Name",
      width: 150,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
      editable: false,
    },
    {
      field: "level",
      headerName: "Level",
      width: 120,
      editable: false,
    },

    {
      field: "actions",
      headerName: " ",
      width: 130,
      editable: false,
      renderCell: (params) => {
        const handleEditClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );

          setSelectedUser(
            userManagementTableData.users.filter(
              (item) => item.email === thisRow.email
            )[0]
          );
          setEditUserModal(true);
        };

        const handleDeleteClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );

          setSelectedMail(thisRow.email);

          setDeleteUserModal(true);
        };

        const handleAssign = (e) => {
          e.stopPropagation(); // don't select this row after clicking

          const api = params.api;
          const thisRow = {};

          api
            .getAllColumns()
            .filter((c) => c.field !== "__check__" && !!c)
            .forEach(
              (c) => (thisRow[c.field] = params.getValue(params.id, c.field))
            );

          setSelectedUser(thisRow);
          setAssignLicenseModal(true);
        };

        return (
          <>
            {(userDetails.level === "admin" || isDemo) && (
              <div style={{ position: "relative", top: "5px" }}>
                <span
                  style={{ margin: "5px", cursor: "pointer" }}
                  onClick={handleEditClick}
                >
                  {
                    <Tooltip title="Edit User Details">
                      <EditOutlinedIcon style={{ color: "#484848" }} />
                    </Tooltip>
                  }
                </span>

                <span
                  style={{ margin: "5px", cursor: "pointer" }}
                  onClick={handleDeleteClick}
                >
                  <Tooltip title="Delete User">
                    <DeleteOutlineOutlinedIcon style={{ color: "#484848" }} />
                  </Tooltip>
                </span>
              </div>
            )}
            <Modal
              open={editUserModal}
              onClose={closeAccessManageModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <UpdateUserModal
                handleClose={closeAccessManageModal}
                userData={selectedUser}
              />
            </Modal>
            <Modal
              open={deleteUserModal}
              onClose={closeDeleteUserModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <DeleteUserModal
                handleClose={closeDeleteUserModal}
                userMail={selectedMail}
              />
            </Modal>
            <Modal
              open={assignLicenseModal}
              onClose={closeAssignLicenseModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <AssignLicenseModal
                handleClose={closeAssignLicenseModal}
                userData={selectedUser}
              />
            </Modal>
          </>
        );
      },
    },
  ];

  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (window.location.href.includes("userManagement")) {
      setCurrentScreen("userManagement");
    } else if (window.location.href.includes("employeeManagement")) {
      setCurrentScreen("employeeManagement");
    } else if (window.location.href.includes("configure")) {
      setCurrentScreen("configure");
    } else if (window.location.href.includes("customizeMessage")) {
      setCurrentScreen("customizeMessage");
    } else if (window.location.href.includes("companyProfile")) {
      setCurrentScreen("companyProfile");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  useEffect(() => {
    !isDemo &&
      dispatch({
        type: GET_USERS,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const updatedRow = [];
    if (isDemo) {
      updatedRow.push({
        id: 1,
        firstName: "John",
        lastName: "Doe",
        email: "john.doe@scanta.io",
        level: "Admin",
        overview: "Read and Write",
        department: "Human Resources",
        logic: "Read and Write",
        investigation: "Read and Write",
      });
      updatedRow.push({
        id: 2,
        firstName: "Jane",
        lastName: "Doe",
        email: "jane.doe@scanta.io",
        level: "Manager",
        overview: "Read only",
        department: "Human Resources",
        logic: "Read only",
        investigation: "Read only",
      });
      updatedRow.push({
        id: 3,
        firstName: "Raymond",
        lastName: "Baker",
        email: "raymond.baker@scanta.io",
        level: "Employee",
        overview: "Read only",
        department: "Information Technology",
        logic: "Read only",
        investigation: "Read only",
      });
    }

    userManagementTableData.users &&
      userManagementTableData.users.forEach((item, idx) => {
        updatedRow.push({
          id: idx + 1,
          firstName: item.first_name,
          lastName: item.last_name,
          email: item.email,
          level: _.capitalize(item.level),
          overview:
            item.permissions.overview === "W" ? "Read and Write" : "Read Only",
          logic:
            item.permissions.logic === "W" ? "Read and Write" : "Read Only",
          investigation:
            item.permissions.investigation === "W"
              ? "Read and Write"
              : item.permissions.investigation === "R"
                ? "Read Only"
                : "-",
        });
      });
    setRows(updatedRow);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userManagementTableData]);


  let skipped = !JSON.parse(localStorage.getItem("skipped"));

  const [openIntroTutModal, setOpenIntroTutModal] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);

  const handleCloseIntroTutModal = () => {
    setOpenIntroTutModal(false);
  };

  return (
    <div
      style={{
        height: "95vh",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        marginTop: "60px",
        paddingTop: "10px",
      }}
    >

      {skipped ? (
        <Modal
          open={openIntroTutModal}
          onClose={handleCloseIntroTutModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {currentStep === 1 ? (
            <Step1Modal
              handleClose={handleCloseIntroTutModal}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
          ) : currentStep === 2 ? (
            <Step2Modal
              handleClose={handleCloseIntroTutModal}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              setCurrentScreen={setCurrentScreen}
            />
          ) : currentStep === 3 ? (
            <Step3Modal
              handleClose={handleCloseIntroTutModal}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              setCurrentScreen={setCurrentScreen}
            />
          ) : currentStep === 4 ? (
            <Step4Modal
              handleClose={handleCloseIntroTutModal}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              setCurrentScreen={setCurrentScreen}

            />
          ) : <Step5Modal
            handleClose={handleCloseIntroTutModal}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            setCurrentScreen={setCurrentScreen}

          />}
        </Modal>
      ) : (
        ""
      )}


      <div className="settingCard">
        <div className="logic-sidebar">
          <div
            className="settingTab"
            style={!isNight ? { backgroundColor: "#212047" } : {}}
          >
            <div>
              <Link
                to="/dashboard/setting/userManagement"
                style={{
                  textDecoration: "none",
                  color: "#484848",
                }}
              >
                <li
                  onClick={() => setCurrentScreen("userManagement")}
                  className={`${currentScreen === "userManagement" ||
                    currentScreen === "setting"
                    ? "active userManagement"
                    : ""
                    }`}
                >
                  User Management
                </li>
              </Link>
            </div>
            <div>
              <Link
                to="/dashboard/setting/employeeManagement"
                style={{
                  textDecoration: "none",
                  color: "#484848",
                }}
              >
                <li
                  onClick={() => setCurrentScreen("employeeManagement")}
                  className={`${currentScreen === "employeeManagement"
                    ? "active employeeManagement"
                    : ""
                    }`}
                >
                  Employee Management
                </li>
              </Link>
            </div>
            <div>
              <Link
                to="/dashboard/setting/configure"
                style={{
                  textDecoration: "none",
                  color: "#484848",
                }}
              >
                <li
                  onClick={() => setCurrentScreen("configure")}
                  className={`${currentScreen === "configure" ? "active configure" : ""
                    }`}
                >
                  Configurations
                </li>
              </Link>
            </div>
            <div>
              <Link
                to="/dashboard/setting/customization"
                style={{
                  textDecoration: "none",
                  color: "#484848",
                }}
              >
                <li
                  onClick={() => setCurrentScreen("customization")}
                  className={`${currentScreen === "customization"
                    ? "active customization"
                    : ""
                    }`}
                >
                  Customization
                </li>
              </Link>
            </div>

            {/* <div>
              <Link
                to="/dashboard/setting/subscription"
                style={{
                  textDecoration: "none",
                  color: !isNight ? "#aebcc9" : "#989ba1",
                }}
              >
                <li
                  onClick={() => setCurrentScreen("subscription")}
                  className={`${
                    currentScreen === "subscription"
                      ? "active subscription"
                      : ""
                  }`}
                >
                  Subscription
                </li>
              </Link>
            </div> */}

            <div>
              <Link
                to="/dashboard/setting/companyProfile"
                style={{
                  textDecoration: "none",
                  color: "#484848",
                }}
              >
                <li
                  onClick={() => setCurrentScreen("companyProfile")}
                  className={`${currentScreen === "companyProfile"
                    ? "active companyProfile"
                    : ""
                    }`}
                >
                  Company Profile and License
                </li>
              </Link>
            </div>
          </div>
          {(currentScreen === "userManagement" ||
            currentScreen === "setting") && (
              <div
                className="userManagementContainer"
                style={
                  !isNight
                    ? { backgroundColor: "#212047" }
                    : { backgroundColor: "#F6F7FC" }
                }
              >
                <div className="searchAdd">
                  <div className="searchContainer">
                    {(userDetails.level === "admin" || isDemo) && (
                      <Tooltip title="Add user">
                        <AddCircleOutlineIcon
                          onClick={() => setAddUserModalOpen(true)}
                          style={{
                            fill: "#484848",
                            fontSize: 36,
                            position: "absolute",
                            right: 50,
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    )}
                    <Modal
                      open={addUserModalOpen}
                      onClose={closeAddUserModal}
                      aria-labelledby="simple-modal-title"
                      aria-describedby="simple-modal-description"
                    >
                      <AddUserModal
                        open={addUserModalOpen}
                        handleClose={closeAddUserModal}
                        title="Add User"
                      />
                    </Modal>
                  </div>
                </div>
                <div className="userTable">
                  <div style={{ width: "85%", color: "white" }}>
                    <DataGrid
                      components={{
                        Toolbar: CustomToolbar,
                      }}
                      rows={rows}
                      columns={columns}
                      classes={{
                        root: classes.tableRoot,
                        columnHeader: classes.columnHeader,
                        columnHeaderWrapper: classes.columnHeaderWrapper,
                        cell: classes.cell,
                      }}
                      autoHeight
                    />
                  </div>
                </div>
              </div>
            )}

          {currentScreen === "employeeManagement" && <EmpMgmt />}

          {currentScreen === "configure" && <Configure />}
          {currentScreen === "customizeMessage" && <h1>Hello</h1>}
          {currentScreen === "companyProfile" && <CompanyProfile />}
        </div>
      </div>
    </div>
  );
}
