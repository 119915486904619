import React from "react";

function TrialBanner({ open, handleClose, days, openPlans }) {
  return (
    <>
      <div className="trialBar">
        {days > 2 && days < 5 ? (
          <div
            style={{
              background: "#41c7a9",
              color: "#fff",
              width: "98%",
              display: "flex",
              justifyContent: "space-between",
              padding: "1rem",
            }}
          >
            <p style={{ margin: "0", fontSize: "0.9rem" }}>
              Your <strong>trial period</strong> will expire in{" "}
              <strong>{days+1} days</strong>. Complete your billing details to
              make the transition.
            </p>
            <div>
              <button
                onClick={openPlans}
                style={{
                  border: "1px solid #fff",
                  cursor: "pointer",
                  background: "transparent",
                  margin: "0 1rem",
                  borderRadius: "5px",
                  color: "#fff",
                  padding: "5px",
                }}
              >
                {" "}
                See details
              </button>
              <span
                onClick={handleClose}
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                x
              </span>
            </div>
          </div>
        ) :days<1?<div
        style={{
          background: "#fc586c",
          color: "#fff",
          width: "98%",
          display: "flex",
          justifyContent: "space-between",
          padding: "1rem",
        }}
      >
        <p style={{ margin: "0", fontSize: "0.9rem" }}>
          Your <strong>trial period</strong> has expired 
          <strong>{days+1} days ago</strong>. Complete your billing details to
          make the transition.
        </p>
        <div>
          <button
            onClick={openPlans}
            style={{
              border: "1px solid #fff",
              cursor: "pointer",
              background: "transparent",
              margin: "0 1rem",
              borderRadius: "5px",
              color: "#fff",
              padding: "5px",
            }}
          >
            {" "}
            See details
          </button>
          <span
            onClick={handleClose}
            style={{
              fontSize: "1rem",
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            x
          </span>
        </div>
      </div>: (
          <div
            style={{
              background: "#fc586c",
              color: "#fff",
              width: "98%",
              display: "flex",
              justifyContent: "space-between",
              padding: "1rem",
            }}
          >
            <p style={{ margin: "0", fontSize: "0.9rem" }}>
              Your <strong>trial period</strong> will expire in{" "}
              <strong>{days+1} days</strong>. Complete your billing details to
              make the transition.
            </p>
            <div>
              <button
                onClick={openPlans}
                style={{
                  border: "1px solid #fff",
                  cursor: "pointer",
                  background: "transparent",
                  margin: "0 1rem",
                  borderRadius: "5px",
                  color: "#fff",
                  padding: "5px",
                }}
              >
                {" "}
                See details
              </button>
              <span
                onClick={handleClose}
                style={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
              >
                x
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default TrialBanner;
