export default function ZoomLogin({
  zoomClientID,
  responseType,
  redirectUrl,
  onFailure,
  onSuccess,
  state,
}) {
  function openPopup() {
    const width = 400;
    const height = 600;
    const left = screen.width / 2 - width / 2;
    const top = screen.height / 2 - height / 2;

    let url = `https://zoom.us/oauth/authorize?client_id=${zoomClientID}&response_type=${responseType}&redirect_uri=${redirectUrl}&state=${state}`;

    return window.open(
      url,
      "",
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
    );
  }

  function polling(popup) {
    const pollingInterval = setInterval(() => {
      if (!popup || popup.closed || popup.closed === undefined) {
        clearInterval(pollingInterval);
        onFailure("Popup has been closed by user");
      }

      const closeDialog = () => {
        clearInterval(pollingInterval);
        popup.close();
      };
      try {
        if (!popup.location.hostname.includes("api.trupulse.ai")) {
          if (popup.location.search) {
            const query = new URLSearchParams(popup.location.search);
            const slackCode = query.get("code");
            closeDialog();
            if (slackCode) {
              console.log(slackCode);
              // return onSuccess(slackCode);
            }
            if (onFailure) {
              console.log(query.get("error"));
              // onFailure(query.get("error"));
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    }, 1000);
  }

  function handleAuth() {
    polling(openPopup());
  }

  return (
    <>
      <div
        className="submitBtn"
        style={{
          // width: "100px",
          margin: "2rem auto",
          background: "#2196f3",
          display: "flex",
        }}
        onClick={handleAuth}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          height="35"
          width="35"
          viewBox="0 0 50.667 50.667"
        >
          <path
            d="M25.333 50.667c13.992 0 25.334-11.343 25.334-25.334S39.325 0 25.333 0 0 11.342 0 25.333s11.342 25.334 25.333 25.334z"
            fill="#2196f3"
          />
          <path
            clip-rule="evenodd"
            d="M14.866 32.574h16.755V20.288c0-1.851-1.5-3.351-3.351-3.351H11.515v12.286c0 1.851 1.5 3.351 3.351 3.351zm18.988-4.467l6.702 4.467V16.937l-6.701 4.468z"
            fill="#fff"
            fill-rule="evenodd"
          />
        </svg>
        <div
          className="btnText"
          style={{ textAlign: "center", padding: "0.3rem" }}
        >
          Authenticate
        </div>
      </div>
    </>
  );
}
