import React from "react";
import { Link } from "react-router-dom";
import "../styles/global.css";
import { Box } from "@material-ui/core";
import Navbar from "../Components/Navbar";

const VerifyOTPPage = () => {
  return (
    <div className="loginPage">
      <div style={{ width: "100%" }}>
        <Navbar isLoggedIn={false} />
      </div>
      <div className="formLoginContainer">
        <div className="formLogo">
          <img src="/Logo-3x.png" alt="logo" width="150px" />
        </div>
        <form className="loginForm">
          <div className="inputContainer">
            <label className="formLabels">OTP</label>
            <input
              type="text"
              placeholder="Enter your OTP"
              name="uname"
              required
            />
          </div>
          <Box
            component="div"
            sx={{ my: 2 }}
            role="presentation"
            className="submitButton"
          >
            <Link to="set-password">
              <div className="buttonText">Verify</div>
            </Link>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default VerifyOTPPage;
