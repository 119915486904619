import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Tooltip } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import {
  GET_ENGAGEMENT,
  GET_ENGAGEMENT_THRESHOLD,
  GET_ENPS_THRESHOLD,
  SAVE_ENGAGEMENT_THRESHOLD,
  SAVE_ENPS_THRESHOLD,
} from "../../../../../redux/Dashboard/Dashboard.types";
import SetGoal from "../../../../common/SetGoal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function ConfigModal({ handleClose }) {
  const dispatch = useDispatch();
  const insightThreshold = useSelector(
    (data) => data.dashboard.insightThreshold
  );

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const eNPSThreshold = useSelector((data) => data.dashboard.eNPSThreshold);

  const [textEngageThreshold, setTextEngageThreshold] = useState(
    eNPSThreshold.textWeight * 100
  );

  const [topicEngageThreshold, setTopicEngageThreshold] = useState(
    eNPSThreshold.topicWeight * 100
  );

  const [surveyEngageThreshold, setSurveyEnagegThreshold] = useState(
    eNPSThreshold.surveyWeight * 100
  );

  const [interval, setInterval] = useState("monthly");

  const [alertMsg, setAlertMsg] = useState("");

  const handleSave = () => {
    const total =
      parseInt(topicEngageThreshold) / 100 +
      parseInt(surveyEngageThreshold) / 100 +
      parseInt(textEngageThreshold) / 100;
    if (total === 1) {
      setAlertMsg("");
      const data = {
        topicWeight: parseInt(topicEngageThreshold) / 100,
        surveyWeight: parseInt(surveyEngageThreshold) / 100,
        textWeight: parseInt(textEngageThreshold) / 100,
      };

      dispatch({ type: SAVE_ENPS_THRESHOLD, payload: data });

      handleClose();
    } else {
      setAlertMsg(
        "The total threshold percentage must add up to 100%. Please adjust your values."
      );
    }
  };

  useEffect(() => {
    setTopicEngageThreshold(eNPSThreshold.topicWeight * 100);
    setTextEngageThreshold(eNPSThreshold.textWeight * 100);
    setSurveyEnagegThreshold(eNPSThreshold.surveyWeight * 100);
  }, [eNPSThreshold]);

  useEffect(() => {
    dispatch({ type: GET_ENPS_THRESHOLD });
  }, []);
  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Settings
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />
      <hr />
      <Typography
        id="modal-modal-title"
        style={{ margin: "1rem 0", fontSize: "1.1rem" }}
      >
        Set Goal
      </Typography>

      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <SetGoal metric="ENPS" handleClose={handleClose} />
      </Box>
      <hr />

      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        {" "}
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          style={{ margin: "1rem 0" }}
        >
          Overall eNPS Score Weighting (%)
        </Typography>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Survey eNPS"
            variant="outlined"
            value={surveyEngageThreshold}
            onChange={(e) => setSurveyEnagegThreshold(e.target.value)}
          />
        </Stack>
      </Box>
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Topic eNPS"
            variant="outlined"
            value={topicEngageThreshold}
            onChange={(e) => setTopicEngageThreshold(e.target.value)}
          />
        </Stack>
      </Box>

      {alertMsg.length > 0 && (
        <Box
          sx={{
            margin: "1rem 0",
          }}
        >
          <Typography style={{ color: "#f00" }}>{alertMsg}</Typography>
        </Box>
      )}
      <Stack direction="row" spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          style={{
            background: "#D06283",
            color: "#fff",
          }}
          variant="contained"
        >
          Save
        </Button>
      </Stack>
    </Box>
  );
}

export default ConfigModal;
