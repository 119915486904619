/* eslint-disable react/no-array-index-key */
import React from "react";
import "../../../styles/loginPage.css";
import "../../../styles/profilePage.css";
import { useDispatch } from "react-redux";
import "../../../styles/setting.css";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import "react-phone-input-2/lib/style.css";
import {
  DELETE_USER,
  GET_USERS,
} from "../../../redux/Dashboard/Dashboard.types";

export default function UserManagement({ handleClose, userMail }) {
  const dispatch = useDispatch();
  const isDemo = JSON.parse(localStorage.getItem("demoMode"))

  const handleSave = () => {
    !isDemo && dispatch({
      type: DELETE_USER,
      payload: { email: userMail },
    });
    setTimeout(() => {
      !isDemo && dispatch({
        type: GET_USERS,
      });
    }, 1500);
    handleClose();
  };
  return (
    <div className="deleteUserCard">
      <p style={{ fontSize: "1.2rem", color: "#484848", marginBottom: "0" }}>
        Do you want to delete this user?
      </p>

      <div className="submitBtnSection">
        <div className="btnOptions" style={{ marginTop: "10px" }}>
          <div className="cancelBtn" onClick={handleClose}>
            <div className="rangeBtn">
              <ClearIcon />
              <div className="btnText">Cancel</div>
            </div>
          </div>
          <div className="submitBtn">
            <div className="rangeBtn" type="submit" onClick={handleSave}>
              <DeleteOutlineOutlinedIcon />
              <div className="btnText">Delete</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
