import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import {
  GET_STATUS_BOX,
  GET_STATUS_BOX_SUCCESS,
  UPDATE_STATUS_BOX,
} from "../../../../redux/Dashboard/Dashboard.types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function ConfigModal({ plan, handleClose }) {
  const dispatch = useDispatch();
  const statusBoxItems = useSelector((data) => data.dashboard.statusBoxItems);
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const [moraleChecked, setMoraleChecked] = useState(
    statusBoxItems.includes("Morale")
  );
  const [engagementChecked, setEngagementChecked] = useState(
    statusBoxItems.includes("Engagement")
  );
  const [userChecked, setUserChecked] = useState(
    statusBoxItems.includes("Users")
  );
  const [retentionChecked, setRetentionChecked] = useState(
    statusBoxItems.includes("Retention")
  );
  const [eNPSChecked, setENPSChecked] = useState(
    statusBoxItems.includes("ENPS")
  );
  const [sentimentChecked, setSentimentChecked] = useState(
    statusBoxItems.includes("Sentiment")
  );
  const [positiveChecked, setPositiveChecked] = useState(
    statusBoxItems.includes("Positive Culture")
  );
  const [cultureChecked, setCultureChecked] = useState(
    statusBoxItems.includes("Culture")
  );
  const [topicChecked, setTopicChecked] = useState(
    statusBoxItems.includes("Topic")
  );
  const [negativeChecked, setNegativeChecked] = useState(
    statusBoxItems.includes("Nagative Culture")
  );

  const handleSave = () => {
    const temp = [];

    moraleChecked && temp.push("Morale");
    engagementChecked && temp.push("Engagement");
    retentionChecked && temp.push("Retention");
    eNPSChecked && temp.push("ENPS");
    cultureChecked && temp.push("Culture");
    topicChecked && temp.push("Topic");

    isDemo
      ? dispatch({
          type: GET_STATUS_BOX_SUCCESS,
          payload: temp,
        })
      : dispatch({
          type: UPDATE_STATUS_BOX,
          payload: { statusBox: temp },
        });
    handleClose();
  };

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
      Settings
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />
      <hr />
      <Typography id="modal-modal-title"  style={{ margin: "1rem 0", fontSize: "1.1rem" }}>
        Add/Hide Meters
      </Typography>
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  setMoraleChecked(e.target.checked);
                }}
                checked={moraleChecked}
              />
            }
            label="Morale"
          />

          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  setEngagementChecked(e.target.checked);
                }}
                checked={engagementChecked}
              />
            }
            label="Engagement"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  setRetentionChecked(e.target.checked);
                }}
                checked={retentionChecked}
              />
            }
            label="Retention Risk"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  setENPSChecked(e.target.checked);
                }}
                checked={eNPSChecked}
              />
            }
            label="eNPS"
          />

          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  setCultureChecked(e.target.checked);
                }}
                checked={cultureChecked}
              />
            }
            label="Culture"
          />
          <FormControlLabel
            control={
              <Checkbox
                onChange={(e) => {
                  setTopicChecked(e.target.checked);
                }}
                checked={topicChecked}
              />
            }
            label="Trending Topics"
          />
        </FormGroup>
      </Box>
      <Stack direction="row" spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          style={{
            background: "#D06283",
            color: "#fff",
          }}
          variant="contained"
        >
          Save
        </Button>
      </Stack>
    </Box>
  );
}

export default ConfigModal;
