import { toast } from "react-toastify";
import api from "../axiosConfig";
import { SessionOut } from "../../utilities/dashboardUtils";

// eslint-disable-next-line import/prefer-default-export
export function* getUserWorkflowsData(action) {
    let resp;
    yield api
        .get("/userWorkflows", {
            headers: {
                "Access-Control-Allow-Headers": "x-access-token",
                "x-access-token": localStorage.getItem("auth-token"),
            },
        })
        .then((response) => {
            if (response.status === 404) {
                toast.error("Error in uploading file");
            } else if (response.status === 400) {
                SessionOut();
            } else {
                resp = response;
            }
        })
        .catch((err) => { 
            resp = err;
            // SessionOut();
        });
    return resp;
} 