import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import { Button } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function AddQuestion({ handleClose }) {
  const [question, setQuestion] = useState("");
  const [questionType, setQuestionType] = useState("radiogroup");

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Add Question
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Stack direction="column" spacing={2}>
          <TextField
            id="outlined-basic"
            label="Question"
            variant="outlined"
            value={question}
            onChange={(e) => {
              setQuestion(e.target.value);
            }}
          />
          <Stack direction="row">
            <FormControlLabel
              control={
                <Checkbox
                  checked={questionType === "radiogroup"}
                  onChange={(e) => {
                    e.target.checked
                      ? setQuestionType("radiogroup")
                      : setQuestionType("comment");
                  }}
                />
              }
              label="Multiple Choice"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={questionType === "comment"}
                  onChange={(e) => {
                    e.target.checked
                      ? setQuestionType("comment")
                      : setQuestionType("radiogroup");
                  }}
                />
              }
              label="Freeform Text"
            />
          </Stack>
        </Stack>
      </Box>
      <Stack direction="row" spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          style={{ background: "#D06283", color: "#fff" }}
          variant="contained"
        >
          Update
        </Button>
      </Stack>
    </Box>
  );
}

export default AddQuestion;
