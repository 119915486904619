import React from "react";
import { useHistory } from 'react-router-dom';

const Step9Modal = ({
  handleClose,
  setCurrentScreen,
  currentScreen,
  currentStep,
  setCurrentStep,
  setTopStatusAccordian,
  setInsightAccordion,
  setCultureAccordion,
  setSupporterAccordion,
}) => {

    const history = useHistory();

  const handleNextStep = () => {
    // handleClose();
    // setInsightAccordion(false);
    history.push('/dashboard/feedback');
    setCurrentScreen("feedback");
    // setCurrentStep(1);
  };

  const handleSkip = () => {
    handleClose();
    localStorage.setItem("skipped", true);
  };

  return (
    <>
     
      <div
        className="addChartsContainer"
        style={{
          width: "24vw",
          marginTop: "55px",
          position: "absolute",
          left: "35%",
          top: "62%",
        }}
      >
        <div style={{ color: "#484848", fontSize: "1rem" }}>
          <b>Culture Section</b>
        </div>
        <p style={{ color: "#484848" }}>
          This section displays the overall status of the culture score on a
          trendline, allowing you to track changes over time. You can also
          compare the culture score using various filter options.
        </p>
        <div className="btnOptions" style={{ marginTop: "0px" }}>
          <div className="submitBtn">
            <div className="rangeBtn" type="submit" onClick={handleNextStep}>
              <div className="btnText">{"NEXT >>"}</div>
            </div>
          </div>
          <div className="submitBtn">
            <div className="rangeBtn" type="submit" onClick={handleSkip}>
              <div className="btnText">{"SKIP >>>>"}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step9Modal;
