import { Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import ChatSource from "./ChatSource";
import Hris from "./Hris";
import ChatDelete from "./ChatDelete";

function Widgets() {
  const [currentWidget, setCurrentWidget] = useState("chatSource");
  const [hidden, setHidden] = useState(true);
  console.log(currentWidget);
  return (
    <Box style={{ position: "relative" }}>
      {currentWidget === "chatSource" && (
        <Box>
          {hidden && (
            <Box
              style={{
                position: "absolute",
                right: "45px",
                top: "120px",
                borderRadius: "8px",
                width: "200px",
                height: "120px",
                background: "#d06283",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                zIndex: 500,
                padding: "1rem",
                color: "#fff",
              }}
            >
              <CloseIcon
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  fontSize: "0.9rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCurrentWidget("");
                  setTimeout(() => {
                    setCurrentWidget("hris");
                  }, 3000);
                }}
              />
              <ChatSource />
            </Box>
          )}
          <Box
            style={{
              position: "absolute",
              right: "10px",
              top: "170px",
              borderRadius: "8px",
              width: "15px",
              height: "50px",
              background: "#d06283",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              padding: "0.5rem",
              color: "#fff",
              zIndex: 500,
            }}
            onClick={() => {
              setHidden(!hidden);
            }}
          >
            {hidden ? (
              <NavigateNextIcon
                style={{
                  position: "relative",
                  top: "10px",
                  left: "-3px",
                  cursor: "pointer",
                }}
              />
            ) : (
              <NavigateBeforeIcon
                style={{
                  position: "relative",
                  top: "10px",
                  left: "-3px",
                  cursor: "pointer",
                }}
              />
            )}
          </Box>
        </Box>
      )}
      {currentWidget === "hris" && (
        <Box>
          {hidden && (
            <Box
              style={{
                position: "absolute",
                right: "45px",
                top: "300px",
                borderRadius: "8px",
                width: "200px",
                // height: "120px",
                background: "#d06283",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                zIndex: 500,
                padding: "0.5rem 1rem",
                color: "#fff",
              }}
            >
              <CloseIcon
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  fontSize: "0.9rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCurrentWidget("");
                  setTimeout(() => {
                    setCurrentWidget("chatDelete");
                  }, 6000);
                }}
              />
              <Hris />
            </Box>
          )}
          <Box
            style={{
              position: "absolute",
              right: "10px",
              top: "350px",
              borderRadius: "8px",
              width: "15px",
              height: "50px",
              background: "#d06283",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              padding: "0.5rem",
              color: "#fff",
              zIndex: 500,
            }}
            onClick={() => {
              setHidden(!hidden);
            }}
          >
            {hidden ? (
              <NavigateNextIcon
                style={{
                  position: "relative",
                  top: "10px",
                  left: "-3px",
                  cursor: "pointer",
                }}
              />
            ) : (
              <NavigateBeforeIcon
                style={{
                  position: "relative",
                  top: "10px",
                  left: "-3px",
                  cursor: "pointer",
                }}
              />
            )}
          </Box>
        </Box>
      )}
      {currentWidget === "chatDelete" && (
        <Box>
          {hidden && (
            <Box
              style={{
                position: "absolute",
                right: "45px",
                top: "430px",
                borderRadius: "8px",
                width: "200px",
                // height: "120px",
                background: "#d06283",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                zIndex: 500,
                padding: "0.5rem 1rem",
                color: "#fff",
              }}
            >
              <CloseIcon
                style={{
                  position: "absolute",
                  top: "5px",
                  right: "5px",
                  fontSize: "0.9rem",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setCurrentWidget("");
                  setTimeout(() => {
                    setCurrentWidget("");
                  }, 6000);
                }}
              />
              <ChatDelete />
            </Box>
          )}
          <Box
            style={{
              position: "absolute",
              right: "10px",
              top: "465px",
              borderRadius: "8px",
              width: "15px",
              height: "50px",
              background: "#d06283",
              boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
              padding: "0.5rem",
              color: "#fff",
              zIndex: 500,
            }}
            onClick={() => {
              setHidden(!hidden);
            }}
          >
            {hidden ? (
              <NavigateNextIcon
                style={{
                  position: "relative",
                  top: "10px",
                  left: "-3px",
                  cursor: "pointer",
                }}
              />
            ) : (
              <NavigateBeforeIcon
                style={{
                  position: "relative",
                  top: "10px",
                  left: "-3px",
                  cursor: "pointer",
                }}
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default Widgets;
