/* eslint-disable no-underscore-dangle */
import { takeLatest, put } from "@redux-saga/core/effects";
import {
  LOGIN,
  LOGIN_SUCCESS,
  LOGIN_BY_LOCAL_STORAGE,
  CHANGE_LOGIN,
  CHANGE_LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_EMAIL,
  SAVE_DEMO_ID,
  CHECK_DOMAIN_EXISTS,
  CHECK_DOMAIN_EXISTS_SUCCESS,
  CHECK_DOMAIN_EXISTS_FAILURE,
} from "../redux/Login/Login.types";
import { login } from "../services/login.api";
import { saveDemoID } from "../services/saveDemoID.api";
import { checkDomain } from "../services/checkDomain.api";
import { loginByLC } from "../services/loginByLC.api";
import { forgetPassword } from "../services/Profile/forgetPassword.api";

function* loginUser(action) {
  const response = yield login(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: LOGIN_SUCCESS,
        payload: response,
      });
    } else {
      yield put({
        type: LOGIN_FAILURE,
        payload: { message: response.response.data },
      });
    }
  } else {
    yield put({
      type: LOGIN_FAILURE,
      payload: response,
    });
  }
}
function* loginByLocalStorage(action) {
  const response = yield loginByLC(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: LOGIN_SUCCESS,
        payload: response,
      });
    }
  }
}
function* changeLoginStatus() {
  yield put({
    type: CHANGE_LOGIN_SUCCESS,
  });
}

function* sendMailForPassReset(action) {
  const response = yield forgetPassword(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: LOGIN_EMAIL,
        payload: response,
      });
    }
  }
}
function* saveDemoId(action) {
  const response = yield saveDemoID(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      // yield put({
      //   type: LOGIN_EMAIL,
      //   payload: response,
      // });
    }
  }
}

function* checkDomainExists(action) {
  const response = yield checkDomain(action);
  if (response) {
    if (response.status >= 200 && response.status < 300) {
      yield put({
        type: CHECK_DOMAIN_EXISTS_SUCCESS,
        payload: response.data,
      });
    }
  }
}

function* LoginSaga() {
  yield takeLatest(LOGIN, loginUser);
  yield takeLatest(SAVE_DEMO_ID, saveDemoId);
  yield takeLatest(LOGIN_BY_LOCAL_STORAGE, loginByLocalStorage);
  yield takeLatest(CHANGE_LOGIN, changeLoginStatus);
  yield takeLatest(LOGIN_EMAIL, sendMailForPassReset);
  yield takeLatest(CHECK_DOMAIN_EXISTS, checkDomainExists);
}

export default LoginSaga;
