import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { useSelector } from "react-redux";
import classNames from "classnames";

export default function DefaultModal({
  open,
  handleClose,
  title,
  currentState,
  changeStateData,
  dispatch,
  dispatchType,
}) {
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);
  const useStyles = makeStyles((theme) => ({
    root: {
      color: "#fff",
      padding: "0!important",
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
      
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
      marginTop: 20,
      color: "rgba(0, 0, 0, 0.87)!important",
      borderBlockColor: "#6352C6",
    },
    input: {
      color: "#fff",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    paper: {
      padding: 0,
    },
    getColor: {
      color: !isNight ? "#aebcc9" : "#000",
    },
    getBg: {
      backgroundColor: !isNight ? "#212047" : "#fff",
    },
  }));
  const classes = useStyles();
  const [modelValue, setModelValue] = useState(currentState);

  const changeHandler = (event) => {
    setModelValue(event.target.value);
  };
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const submitHandler = () => {
    if (dispatch) {
      !isDemo && dispatch({ type: dispatchType, payload: currentState });
    } else {
      changeStateData(modelValue);
      handleClose();
    }
  };

  const popupTitle = classNames("popUpTitle", classes.getColor);
  const btnText = classNames("btnText", classes.getColor);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <DialogContent classes={{ root: classes.root }}>
        <div
          className="popUp"
          style={{ background: !isNight ? "#212047" : "Light" }}
        >
          <div className={popupTitle}>{title}</div>
          <form className={classes.container}>
            <input
              type="text"
              value={modelValue}
              className="keyInput"
              onChange={(event) => changeHandler(event)}
            />
            <div className="btnOptions">
              <div className="cancelBtn" onClick={handleClose}>
                <div className="rangeBtn">
                  <CloseIcon className={classes.getColor} />
                  <div className={btnText}>Cancel</div>
                </div>
              </div>
              <div className="submitBtn" onClick={submitHandler}>
                <div className="rangeBtn">
                  <CheckIcon />
                  <div className="btnText" type="submit">
                    Submit
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
}
