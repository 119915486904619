import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#fff",
    padding: "0!important",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    "& div": {
      color: "#fff",
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    marginTop: 20,
    color: "rgba(0, 0, 0, 0.87)!important",
    borderBlockColor: "#6352C6",
  },
  input: {
    color: "#fff",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    padding: 0,
  },
}));

export default function BuildOptonModal({ open, handleClose, onBuilt }) {
  const classes = useStyles();

  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      {" "}
      <div className="closeBtn" onClick={handleClose}>
        <HighlightOffIcon
          style={{
            fill: !isNight ? "#aebcc9" : "#666",
            position: "absolute",
            top: "15px",
            right: "15px",
            cursor: "pointer",
          }}
        />
      </div>
      <DialogContent classes={{ root: classes.root }}>
        <div
          className="popUp"
          style={{
            padding: "30px 30px",
            backgroundColor: !isNight ? "#212047" : "#e5e5e5",
          }}
        >
          {/* <div className="popUpTitle" style={{ marginBottom: "15px" }}>
            {title}
          </div> */}
          <div
            style={{
              margin: "15px 25px 25px 0",
              color: !isNight ? "#aebcc9" : "#000",
            }}
          >
            Build workflow once or periodically?
          </div>
          <div className="btnOptions">
            <div
              className="submitBtn"
              onClick={() => {
                onBuilt("once");
              }}
            >
              <div className="rangeBtn" type="submit">
                <div className="btnText">Once</div>
              </div>
            </div>
            <div
              className="submitBtn"
              onClick={() => {
                onBuilt("periodically");
              }}
            >
              <div className="rangeBtn" type="submit">
                <div className="btnText">Periodically</div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
