import { toast } from "react-toastify";
import axios from "axios";
import { SessionOut } from "../../utilities/dashboardUtils";

// eslint-disable-next-line import/prefer-default-export
export function* savingToken(action) {
  let resp;
  const { headerToken } = action.payload;
  yield axios
    .post("https://api.trupulse.ai/appToken", action.payload, {
      headers: {
        "x-access-token": headerToken,
      },
    })
    .then((response) => {
      if (response.status === 404) {
        toast.error("Error in uploading file");
      } else if (response.status === 400) {
        SessionOut();
      } else {
        resp = response;
      }
    })
    .catch((err) => {
      resp = err;
      toast.error("Data not found");
    });
  return resp;
}
