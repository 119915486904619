/* eslint-disable no-underscore-dangle */
import React, {
  useEffect,
  useState,
  createRef,
  Suspense,
  useMemo,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { lazy } from "react";
import Modal from "@material-ui/core/Modal";
import Grid from "@mui/material/Grid";
import Wordcloud from "./Wordcloud";
import EmotionDist from "./EmotionDist";
import Gauge from "./Gauge";
import Filters from "./Filters";
import WorkflowSelect from "../../common/WorkflowSelect";
import KeyTopics from "./KeyTopics";
import TrendingTopics from "./TrendingTopics";
import MoraleTrend from "./MoraleTrend";
import "../../../styles/dashboard.css";
import ChartsModal from "../../Charts/ChartsSelection";
import IntroTutModal from "../Walkthrough/LogicModal";
import ModelDist from "./MoraleDist";
import SentimentTrend from "./SentimentTrend";
import MoraleCompare from "./MoraleCompare";
import Skeleton from "@mui/material/Skeleton";
import OutlinedInput from "@mui/material/OutlinedInput";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import SentimentScore from "./SentimentScore";
import SettingsIcon from "@mui/icons-material/Settings";
import EventIcon from "@mui/icons-material/Event";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Step,
  Tooltip,
} from "@material-ui/core";
import CustomDatePicker from "./CustomDatePicker";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoIcon from "@mui/icons-material/Info";
import AddSectionModal from "./AddSectionModal";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CircularProgress from "@mui/material/CircularProgress";
import SupporterDetractor from "./SupporterDetractor";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { useScreenshot } from "use-react-screenshot";
import * as clipboard from "clipboard-polyfill";
import { base64StringToBlob } from "blob-util";
import LoadingOverlay from "react-loading-overlay";
import ChatBot from "./ChatBot";
import AnnotationModal from "../../common/Annotation/AnnotationModal";
import _ from "lodash";

const EngagementTexts = lazy(() => import("./EngagementTexts"));
const SupporterDetractorTrend = lazy(() => import("./SupporterDetractorTrend"));
const RetentionTrend = lazy(() => import("./RetentionTrend"));
const EnagagementUsers = lazy(() => import("./EngagementUsers"));

import {
  getLengthOfService,
  getIncome,
  getAge,
} from "../../../utilities/dashboardUtils";
import {
  GET_TOTAL_CONVO_DETAILS,
  GET_MODEL_CHANNEL,
  GET_USER_WORKFLOW,
  GET_MORALE_SCORE,
  GET_SENTIMENTS_DIST,
  GET_WORD_CLOUD_DATA,
  GET_TRENDING_TOPICS,
  GET_HASH_TAGS,
  GET_POLITICAL_WORDS,
  GET_USER_DETAILS,
  CHARTS_IMAGE_DATA,
  SET_DATE_FILTER_SENTIMENT,
  GET_HATE_SPEECH_TRENDS,
  GET_INSULT_TRENDS,
  GET_DASHBOARD_SECTIONS,
  GET_TEAM_SIZE,
  GET_TOTAL_TEXTS,
  GET_MORALE_CHANGE,
  GET_ENGAGEMENT,
  GET_RETENTION,
  GET_SUPPORTER_TREND,
  GET_FILTER_CHANNEL,
  GET_SEXUAL_HARRAS_TRENDS,
  GET_TEXT_ABUSE_DATA,
  GET_RACISM_TREND_DATA,
  GET_EMOTION_JOY,
  GET_EMOTION_ANGER,
  GET_EMOTION_FEAR,
  GET_EMOTION_SAD,
  GET_EMOTION_SURPRISE,
  GET_EMOTION_NEUTRAL,
  GET_EMOTION_TOTAL,
  GET_MORALE_COMPARISON,
  GET_ENGAGEMENT_COMPARISON,
  GET_RETENTION_COMPARISON,
  GET_SUPPORTER_COMPARISON,
  GET_MORALE_INSIGHTS,
  GET_PUBLIC_HOLIDAYS,
  GET_SINGLE_INSIGHTS,
  GET_MULTIPLE_INSIGHTS,
  GET_CALENDER_EVENTS,
  SET_LOADING,
  GET_NONE_INSIGHTS,
  GET_ALL_SURVEYS,
  GET_POSITIVE_CULTURE,
  GET_NEGATIVE_CULTURE,
  GET_MORALE_TOPICS,
  GET_FAVORITE_FILTERS,
  GET_ENPS_SURVEY_SCORE,
  GET_ENPS_TEXT_SCORE,
  GET_ENPS_TOPIC_SCORE,
  GET_ENPS_TEXT_TREND,
  GET_ENPS_TOPIC_TREND,
  GET_ENPS_SURVEY_TREND,
  GET_ENPS_OVERALL_SCORE,
  GET_ENPS_OVERALL_TREND,
  GET_OVERALL_CULTURE,
  GET_ENGAGEMENT_TOPIC_TREND,
  GET_FILTER_EMAILS,
  GET_TOPIC_SINGLE_INSIGHTS,
  GET_TOPIC_MULTIPLE_INSIGHTS,
  GET_TOPIC_NONE_INSIGHTS,
  GET_EVENT_SINGLE_INSIGHTS,
  GET_EVENT_MULTIPLE_INSIGHTS,
  GET_EVENT_NONE_INSIGHTS,
  GET_ALL_ACTION_PLANS,
} from "../../../redux/Dashboard/Dashboard.types";
import {
  detectedUserParam,
  doughnutData,
  modelTrendsParam,
  totalConvoParam,
  workflowCountParam,
} from "../../Charts/params";
import {
  demoData,
  demoSentimentTrend,
  demoTrendingTopicsData,
  generateEngagementData,
  generateMoraleScore,
  generateRetentionData,
  generateHateSpeechData,
  generatePositiveCultureData,
  generateNegativeCultureData,
  generateInsultData,
  generateSexualData,
  generateRacismData,
  generateTextAbuseData,
  generateSupporterData,
  demoHashTagsData,
  demoTopicEngagementData,
  generateRetentionCompareData,
  generateDemoEngagementData,
} from "./demoData";
import CustomLoader from "../../common/CustomLoader";
import IosShareIcon from "@mui/icons-material/IosShare";
import AppliedFilters from "./AppliedFilters";
import UserMeter from "./EngagementBlock/UserMeter";
import TextMeter from "./EngagementBlock/TextMeter";
import TextCompare from "./EngagementBlock/TextCompare";
import UserCompare from "./EngagementBlock/UserCompare";
import RetentionCompare from "./RetentionBlock/RetentionCompare";
import SupporterCompare from "./SupporterDetractor/SupporterCompare";
import RetentionMeter from "./RetentionBlock/RetentionMeter";
import DetractorCompare from "./SupporterDetractor/DetractorCompare";
import Insights from "./Insights";
import CulturalOverview from "./Cultural";
import TopStatusBox from "./TopStatusBox";
import TextENPSScore from "./SupporterDetractor/TextENPSScore";
import TextENPSTrend from "./SupporterDetractor/TextENPSTrend";
import TopicENPSScore from "./SupporterDetractor/TopicENPSScore";
import TopicENPSTrend from "./SupporterDetractor/TopicENPSTrend";
import SurveyENPSScore from "./SupporterDetractor/SurveyENPSScore";
import SurveyENPSTrend from "./SupporterDetractor/SurveyENPSTrend";
import EngagementBlock from "./EngagementBlock";
import OverallENPSScore from "./SupporterDetractor/OverallENPSScore";
import OverallENPSTrend from "./SupporterDetractor/OverallENPSTrend";
import TextENPSDist from "./SupporterDetractor/TextENPSDist";
import TopicENPSDist from "./SupporterDetractor/TopicENPSDist";
import SurveyENPSDist from "./SupporterDetractor/SurveyENPSDist";
import OverallENPSDist from "./SupporterDetractor/OverallENPSDist";
import DemoMoraleCompare from "./MoraleCompare/DemoCompare";
import SetGoalModal from "../../common/SetGoalModal";
import Step1Modal from "../Walkthrough/Step1Modal";
import Step2Modal from "../Walkthrough/Step2Modal";
import Step3Modal from "../Walkthrough/Step3Modal";
import Step4Modal from "../Walkthrough/Step4Modal";
import Step5Modal from "../Walkthrough/Step5Modal";
import Step6Modal from "../Walkthrough/Step6Modal";
import Step7Modal from "../Walkthrough/Step7Modal";
import Step8Modal from "../Walkthrough/Step8Modal";
import Step9Modal from "../Walkthrough/Step9Modal";
import TimeFilterSelect from "../../common/TimeFilterSelect";

export default function Overview({ setCurrentScreen, currentScreen }) {
  const [clipProcessing, setClipProcessing] = useState(false);
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);
  const [isZoom, setIsZoom] = useState(false);
  async function clip(b64Data) {
    const item = new clipboard.ClipboardItem({
      "image/png": base64StringToBlob(b64Data.split(",")[1], "image/png"),
    });

    await clipboard.write([item]);
  }

  const moraleMeterRef = createRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const getImage = async () => {
    setClipProcessing(true);
    await takeScreenshot(moraleMeterRef.current);
    setClipProcessing(false);
    image && clip(image);
  };

  useEffect(() => {
    image && clip(image);
  }, [image]);

  const [words, setWords] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [openIntroTutModal, setOpenIntroTutModal] = useState(true);
  const [annotaionModalOpen, setAnnotationModalOpen] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(isDemo ? "60Days" : "max");
  const [customDate, setcustomDate] = useState({ start: "", end: "" });
  const [customDatePickerOpen, setCustomDatePickerOpen] = useState(false);
  const [topStatusAccordian, setTopStatusAccordian] = useState(true);
  const [moraleAccordion, setMoraleAccordion] = useState(false);
  const [insightAccordion, setInsightAccordion] = useState(false);
  const [enagagementAccordion, setEnagagementAccordion] = useState(false);
  const [retentionAccordion, setRetentionAccordion] = useState(false);
  const [supporterAccordion, setSupporterAccordion] = useState(false);
  const [topicAccordion, setTopicAccordion] = useState(false);
  const [cultureAccordion, setCultureAccordion] = useState(false);

  const diffInMonths = (end, start) => {
    var timeDiff = Math.abs(end.getTime() - start.getTime());
    return Math.round(timeDiff / (2e3 * 3600 * 365.25));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getProfileData = () => {
    !isDemo && dispatch({ type: GET_USER_DETAILS });
  };

  useEffect(() => {
    getProfileData();
  }, []);

  useEffect(() => {
    if (currentFilter === "max") {
      dispatch({
        type: SET_DATE_FILTER_SENTIMENT,
        payload: { start: "", end: "" },
      });
    } else if (currentFilter === "1D") {
      dispatch({
        type: SET_DATE_FILTER_SENTIMENT,
        payload: {
          start: new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000),
          end: new Date(),
        },
      });
    } else if (currentFilter === "5D") {
      dispatch({
        type: SET_DATE_FILTER_SENTIMENT,
        payload: {
          start: new Date(new Date().getTime() - 5 * 24 * 60 * 60 * 1000),
          end: new Date(),
        },
      });
    } else if (currentFilter === "1M") {
      dispatch({
        type: SET_DATE_FILTER_SENTIMENT,
        payload: {
          start: new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000),
          end: new Date(),
        },
      });
    } else if (currentFilter === "3M") {
      dispatch({
        type: SET_DATE_FILTER_SENTIMENT,
        payload: {
          start: new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000),
          end: new Date(),
        },
      });
    } else if (currentFilter === "1Y") {
      dispatch({
        type: SET_DATE_FILTER_SENTIMENT,
        payload: {
          start: new Date(new Date().getTime() - 365 * 24 * 60 * 60 * 1000),
          end: new Date(),
        },
      });
    }
  }, [currentFilter]);

  const customDateCloseHandler = () => {
    setCustomDatePickerOpen(false);
  };

  const onDateChange = (dates) => {
    // const date = new Date(value);
    // const formattedDate = date
    //   .toLocaleDateString("en-GB", {
    //     day: "numeric",
    //     month: "short",
    //     year: "numeric",
    //   })
    //   .replace(/ /g, "-");
    setcustomDate(dates);
    // if (type === "start") {
    //   setcustomDate({ ...customDate, start: formattedDate });
    //   dispatch({
    //     type: SET_DATE_FILTER_SENTIMENT,
    //     payload: { ...customDate, start: formattedDate },
    //   });
    // } else if (type === "end") {
    //   setcustomDate({ ...customDate, end: formattedDate });
    //   dispatch({
    //     type: SET_DATE_FILTER_SENTIMENT,
    //     payload: { ...customDate, end: formattedDate },
    //   });
    // }
    setCurrentFilter("custom");
  };

  const dispatch = useDispatch();
  const totalConvoData = useSelector(
    (data) => data.dashboard.totalConvoAnalyzed
  );
  const totalConvoDataMale = useSelector(
    (data) => data.dashboard.totalConvoAnalyzedMale
  );
  const totalConvoDataFemale = useSelector(
    (data) => data.dashboard.totalConvoAnalyzedFemale
  );

  const hateSpeechData = isDemo
    ? generateHateSpeechData(currentFilter, isZoom)
    : useSelector((data) => data.dashboard.hateSpeechData);

  const positiveCultureData = isDemo
    ? generatePositiveCultureData(currentFilter, isZoom)
    : generatePositiveCultureData(currentFilter, isZoom);

  const negativeCultureData = isDemo
    ? generateNegativeCultureData(currentFilter, isZoom)
    : generateNegativeCultureData(currentFilter, isZoom);

  const insultData = isDemo
    ? generateInsultData(currentFilter, isZoom)
    : useSelector((data) => data.dashboard.insultData);
  const sexualHarassmentData = isDemo
    ? generateSexualData(currentFilter, isZoom)
    : useSelector((data) => data.dashboard.sexualHarassmentData);
  const racismData = isDemo
    ? generateRacismData(currentFilter, isZoom)
    : useSelector((data) => data.dashboard.racismData);

  const profanityData = useSelector((data) => data.dashboard.profanityData);
  const profanityDataMale = useSelector(
    (data) => data.dashboard.profanityDataMale
  );
  const profanityDataFemale = useSelector(
    (data) => data.dashboard.profanityDataFemale
  );

  const deiData = useSelector((data) => data.dashboard.deiData);
  const deiDataMale = useSelector((data) => data.dashboard.deiDataMale);
  const deiDataFemale = useSelector((data) => data.dashboard.deiDataFemale);

  const trendingTopicsData = useSelector(
    (data) => data.dashboard.trendingTopicsData
  );
  const hashTagsData = useSelector((data) => data.dashboard.hashTagsData);
  const textAbuseData = isDemo
    ? generateTextAbuseData(currentFilter, isZoom)
    : useSelector((data) => data.dashboard.textAbuseData);

  const emotionDistData = useSelector(
    (data) => data.dashboard.emotionPercenatge
  );

  const filterChannels = useSelector((data) => data.dashboard.filterChannels);

  const access = useSelector((data) => data.dashboard.access);

  const emotionDistDataMale = useSelector(
    (data) => data.dashboard.emotionPercenatgeMale
  );
  const emotionDistDataFemale = useSelector(
    (data) => data.dashboard.emotionPercenatgeFemale
  );

  const filters = useSelector((data) => data.dashboard.filters);

  const moraleUserCount = useSelector((data) => data.dashboard.moraleUserCount);
  const loadingStatus = useSelector((data) => data.dashboard.isLoading);
  const totalText = useSelector((data) => data.dashboard.totalText);
  const teamSize = useSelector((data) => data.dashboard.teamSize);
  const topicNumber = useSelector((data) => data.dashboard.topicNumber);
  const moraleChange = useSelector((data) => data.dashboard.moraleChange);
  const [demoScore, setScore] = useState(58);
  const [demoScore1, setScore1] = useState(0);
  const [demoScore2, setScore2] = useState(0);

  const [emotionPercenatge, setEmotionPercentage] = useState({
    joy: 0,
    anger: 0,
    sad: 0,
    surprise: 0,
    fear: 0,
    total: 0,
  });

  useEffect(() => {
    var tempObj = {};
    setEmotionPercentage({
      joy: emotionDistData.joy.emotionPercenatge.Emotion,
      anger: emotionDistData.anger.emotionPercenatge.Emotion,
      sad: emotionDistData.sad.emotionPercenatge.Emotion,
      surprise: emotionDistData.surprise.emotionPercenatge.Emotion,
      fear: emotionDistData.fear.emotionPercenatge.Emotion,
      total: emotionDistData.total.emotionPercenatge.Emotion,
    });
  }, [emotionDistData]);

  useEffect(() => {
    setScore(getRandomInt(50, 65));
  }, [filters, customDate, currentFilter]);

  useEffect(() => {
    setScore1(Math.random());
    setScore2(Math.random());
  }, [filters, currentFilter, customDate]);

  const wordCloudData = useSelector((data) => data.dashboard.wordcloudData);
  const workflows = useSelector((data) => data.dashboard.userWorkflows.data);
  const modelChannel = useSelector((data) => data.dashboard.modelChannel?.data);
  // const moraleScore = isDemo
  //   ? demoData
  //   : useSelector((data) => data.dashboard.moraleScore);

  // engagementTopicsData
  const stateRetentionData = useSelector(
    (state) => state.dashboard.retentionData
  );
  const stateRetentionCompareData = useSelector(
    (state) => state.dashboard.retentionComparison
  );
  const stateMoraleScore = useSelector((state) => state.dashboard.moraleScore);
  console.log(currentFilter, customDate);
  const moraleScore = useMemo(() => {
    return isDemo
      ? generateMoraleScore(currentFilter, customDate)
      : stateMoraleScore;
  }, [isDemo, currentFilter, customDate, stateMoraleScore]);

  const stateEngagementData = useSelector(
    (state) => state.dashboard.engagementData
  );
  const stateEngagementTopicData = useSelector(
    (state) => state.dashboard.engagementTopicTrendData
  );

  const engagementTrendData = useMemo(() => {
    return isDemo
      ? generateDemoEngagementData(currentFilter, customDate)
      : stateEngagementData;
  }, [isDemo, currentFilter, customDate, stateEngagementData]);
  const engagementTopicTrendData = useMemo(() => {
    return isDemo
      ? generateDemoEngagementData(currentFilter, customDate)
      : stateEngagementTopicData;
  }, [isDemo, currentFilter, customDate, stateEngagementData]);

  const retentionData = useMemo(() => {
    return isDemo
      ? generateRetentionData(currentFilter, customDate)
      : stateRetentionData;
  }, [isDemo, currentFilter, customDate]);

  const retentionCompareData = useMemo(() => {
    return isDemo
      ? generateRetentionCompareData(currentFilter, customDate)
      : stateRetentionCompareData;
  }, [isDemo, currentFilter, customDate]);

  const supporterData = useSelector((data) => data.dashboard.supporterData);
  const sentimentsDist = useSelector((data) => data.dashboard.sentimentsDist);
  const currentModel = useSelector((data) => data.dashboard.currentModel);
  const currentSource = useSelector((data) => data.dashboard.currentSource);
  const dashboardSections = useSelector((data) =>
    data.dashboard.dashboardSections.models
      ? data.dashboard.dashboardSections.models
      : []
  );

  const [minMaxMorale, setMinMaxMorale] = useState({ min: 0, max: 0 });
  const [moraleLineToCompare, setMoraleLineToCompare] = useState([]);
  const updateMoraleLine = (line) => {
    setMoraleLineToCompare(line);
  };
  useEffect(() => {
    moraleScore?.moraleScoreAvg?.length > 0 &&
      setMinMaxMorale({
        min: Math.min(
          ...moraleScore?.moraleScoreAvg?.map((item) =>
            isDemo ? item?.morale_avg : item?.morale_avg * 100
          )
        ),
        max: Math.max(
          ...moraleScore?.moraleScoreAvg?.map((item) =>
            isDemo ? item?.morale_avg : item?.morale_avg * 100
          )
        ),
      });
  }, [moraleScore]);

  useEffect(() => {
    if (wordCloudData.message === "Success") {
      const res = wordCloudData?.result;
      let tempwords = [];
      Object.keys(res).forEach((item) => {
        tempwords.push({ text: item, value: res[item] });
      });
      setWords(tempwords);
    } else {
      setWords([]);
    }
  }, [wordCloudData]);

  useEffect(() => {
    if (!isDemo) {
      setTimeout(() => {
        dispatch({ type: GET_USER_WORKFLOW });
      }, 1900);
    }
  }, []);

  useEffect(() => {
    workflowChangeHandler("overall");
    dispatch({ type: SET_LOADING, payload: { status: false } });
  }, []);

  useEffect(() => {
    detectedUserParam.channel = modelChannel.allChannels
      ? modelChannel.allChannels[0]
      : currentSource;
    modelTrendsParam.channel = "all";
    totalConvoParam.channel = "all";
    workflowCountParam.channel = "all";
    totalConvoParam.demography = "";
    totalConvoParam.gender = "";
    totalConvoParam.timeOption = "custom";
    totalConvoParam.startDate = "";
    totalConvoParam.endDate = "";
    detectedUserParam.model = modelChannel.allModels
      ? modelChannel.allModels[0]
      : modelChannel;
    workflowCountParam.model = currentModel;
    if (!isDemo) {
      totalConvoParam.workflowID &&
        dispatch({
          type: GET_DASHBOARD_SECTIONS,
          payload: totalConvoParam.workflowID,
        });
    }
  }, [modelChannel]);

  const [smallNumber, setSmallNumber] = useState(0);

  useEffect(() => {
    setSmallNumber(getRandomInt(0, 100));
  }, [filters, currentFilter, customDate]);

  const handleCloseIntroTutModal = () => {
    setOpenIntroTutModal(false);
  };

  const [currentWorkflowID, setCurrentWorkflowID] = useState("");
  const [currentWorkflowName, setCurrentWorkflowName] = useState("");
  const [addSecModalOpen, setAddSecModalOpen] = useState(false);

  const addSecModalOpenHandler = () => {
    setAddSecModalOpen(true);
  };

  const addSecModalCloseHandler = () => {
    setAddSecModalOpen(false);
  };

  const workflowChangeHandler = (value) => {
    dispatch({ type: "RESET_DATA", payload: value });

    isDemo ? setCurrentFilter("3M") : setCurrentFilter("max");

    const currentWorkflow = workflows.Workflows?.filter(
      (item) => item.workflowID === value
    )[0];
    if (
      (currentWorkflow && currentWorkflow.source === "zoom") ||
      value === "overall-zoom"
    ) {
      setIsZoom(true);
    } else {
      setIsZoom(false);
    }

    setCurrentWorkflowID(value);
    const workflowName = workflows.Workflows.filter(
      (item) => item.workflowID === value
    );
    value === "overall"
      ? setCurrentWorkflowName("Overall")
      : value === "overall-zoom"
      ? setCurrentWorkflowName("Overall-Zoom")
      : setCurrentWorkflowName(workflowName[0]?.workflowName);
    modelChannel.allChannels = [];
    modelChannel.allModels = [];
    totalConvoParam.workflowID = value;
    // totalConvoParam.demography = "gender";
    modelTrendsParam.workflowID = value;
    modelTrendsParam.demography = "gender";

    if (!isDemo && totalConvoParam?.workflowID !== "") {
      value !== "overall-zoom" &&
        dispatch({
          type: GET_FILTER_CHANNEL,
          payload: {
            workflowID: value,
          },
        });

      workflowCountParam.workflowID = value;
      value !== "overall-zoom" &&
        dispatch({
          type: GET_MODEL_CHANNEL,
          payload: workflowCountParam,
        });
      dispatch({
        type: CHARTS_IMAGE_DATA,
        payload: [],
      });
    }
  };

  let skipped = !JSON.parse(localStorage.getItem("skipped"));
  // let skipped = false;

  function _exportPdf() {
    html2canvas(document.querySelector("#capture-morale")).then((canvas1) => {
      html2canvas(document.querySelector("#capture-topic")).then((canvas2) => {
        html2canvas(document.querySelector("#capture-engagement")).then(
          (canvas3) => {
            html2canvas(document.querySelector("#capture-retention")).then(
              (canvas4) => {
                html2canvas(document.querySelector("#capture-culture")).then(
                  (canvas5) => {
                    html2canvas(
                      document.querySelector("#capture-supporter")
                    ).then((canvas6) => {
                      const pdf = jsPDF();
                      const imgData1 = canvas1.toDataURL("image/png");
                      pdf.addImage(imgData1, "PNG", 8, 10, 195, 220);
                      pdf.addPage();
                      const imgData2 = canvas2.toDataURL("image/png");
                      pdf.addImage(imgData2, "PNG", 8, 8, 195, 210);
                      pdf.addPage();
                      const imgData3 = canvas3.toDataURL("image/png");
                      pdf.addImage(imgData3, "PNG", 8, 8, 195, 210);
                      pdf.addPage();
                      const imgData4 = canvas4.toDataURL("image/png");
                      pdf.addImage(imgData4, "PNG", 8, 8, 195, 210);
                      pdf.addPage();
                      const imgData6 = canvas6.toDataURL("image/png");
                      pdf.addImage(imgData6, "PNG", 8, 8, 195, 210);
                      pdf.addPage();
                      const imgData5 = canvas5.toDataURL("image/png");
                      pdf.addImage(imgData5, "PNG", 8, 8, 195, 210);
                      pdf.save(`trupulse_report.pdf`);
                    });
                  }
                );
              }
            );
          }
        );
      });
    });
  }
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  function getRandom(min, max) {
    return Math.random() * (max - min + 1) + min;
  }

  const [demoDistData, setDemoDistData] = useState({
    bucketWiseScore: {
      zero_twenty: 37,
      zero_twenty_M: 18,
      zero_twenty_F: 11,

      twenty_forty: 17,
      twenty_forty_M: 23,
      twenty_forty_F: 23,

      forty_sixty: 15,
      forty_sixty_M: 25,
      forty_sixty_F: 17,

      sixty_eighty: 11,
      sixty_eighty_M: 25,
      sixty_eighty_F: 25,

      eighty_hundred: 20,
      eighty_hundred_M: 9,
      eighty_hundred_F: 25,
    },
  });

  const handleShuffleDemoDistData = () => {
    const tempData = {
      bucketWiseScore: {
        zero_twenty: getRandomInt(30, 70),
        zero_twenty_M: getRandomInt(30, 70),
        zero_twenty_F: getRandomInt(30, 70),

        twenty_forty: getRandomInt(30, 70),
        twenty_forty_M: getRandomInt(30, 70),
        twenty_forty_F: getRandomInt(30, 70),

        forty_sixty: getRandomInt(30, 70),
        forty_sixty_M: getRandomInt(30, 70),
        forty_sixty_F: getRandomInt(30, 70),

        sixty_eighty: getRandomInt(30, 70),
        sixty_eighty_M: getRandomInt(30, 70),
        sixty_eighty_F: getRandomInt(30, 70),

        eighty_hundred: getRandomInt(30, 70),
        eighty_hundred_M: getRandomInt(30, 70),
        eighty_hundred_F: getRandomInt(30, 70),
      },
    };
    setDemoDistData(tempData);
  };

  const [leftHidden, setLeftHidden] = useState(false);

  const handleSwitchHidden = () => {
    setLeftHidden(!leftHidden);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Function to handle window resize
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  // useEffect to add event listener when component mounts and cleanup when it unmounts
  useEffect(() => {
    // Add event listener
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    windowWidth < 768 && setLeftHidden(true);
    windowWidth > 768 && setLeftHidden(false);
  }, [windowWidth]);

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const payload = {
    workflowID: currentWorkflowID,
    timeOption: currentFilter === "max" ? "all" : "custom",
    channels: filters.channels,
    gender: filters.gender,
    startDate:
      currentFilter === "max"
        ? ""
        : currentFilter === "1Y"
        ? new Date(new Date().setFullYear(new Date().getFullYear() - 1))
        : currentFilter === "3M"
        ? new Date(new Date().setMonth(new Date().getMonth() - 3))
        : currentFilter === "1M"
        ? new Date(new Date().setMonth(new Date().getMonth() - 1))
        : currentFilter === "5D"
        ? new Date(new Date().setHours(new Date().getHours() - 24 * 5))
        : currentFilter === "1D"
        ? new Date(new Date().setHours(new Date().getHours() - 24))
        : currentFilter === "custom"
        ? new Date(customDate.start)
        : "",
    endDate:
      currentFilter === "max"
        ? ""
        : currentFilter === "custom"
        ? new Date(customDate.end)
        : new Date(),
    location: filters.location,
    department: filters.department,
    ethinicity: filters.ethinicity,
    marital_status:
      filters.marital_status !== "" ? [filters.marital_status] : [],
    region: filters.region,
    length_of_service: getLengthOfService(filters.length_of_service),
    management_level: filters.management_level,
    income: getIncome(filters.income),
    age: getAge(filters.age),
    performance: filters.performance,
  };

  const [moraleCompareOption, setMoraleCompareOption] = useState("gender");
  moraleCompareOption;
  const [retentionCompareOption, setRetentionCompareOption] =
    useState("gender");

  useEffect(() => {
    payload["option"] = [moraleCompareOption];
    dispatch({
      type: GET_MORALE_COMPARISON,
      payload: payload,
    });
  }, [moraleCompareOption]);

  useEffect(() => {
    dispatch({
      type: GET_PUBLIC_HOLIDAYS,
      payload: { countryCode: "US" },
    });

    if (!isDemo && currentWorkflowID != "") {
      dispatch({ type: GET_ALL_SURVEYS });
       dispatch({ type: GET_ALL_ACTION_PLANS });

      dispatch({
        type: GET_SINGLE_INSIGHTS,
        payload: { workflowID: currentWorkflowID },
      });
      dispatch({
        type: GET_MULTIPLE_INSIGHTS,
        payload: { workflowID: currentWorkflowID },
      });
      dispatch({
        type: GET_NONE_INSIGHTS,
        payload: { workflowID: currentWorkflowID },
      });

      dispatch({
        type: GET_TOPIC_SINGLE_INSIGHTS,
        payload: { workflowID: currentWorkflowID },
      });
      dispatch({
        type: GET_TOPIC_MULTIPLE_INSIGHTS,
        payload: { workflowID: currentWorkflowID },
      });
      dispatch({
        type: GET_TOPIC_NONE_INSIGHTS,
        payload: { workflowID: currentWorkflowID },
      });

      dispatch({
        type: GET_EVENT_SINGLE_INSIGHTS,
        payload: { workflowID: currentWorkflowID },
      });
      dispatch({
        type: GET_EVENT_MULTIPLE_INSIGHTS,
        payload: { workflowID: currentWorkflowID },
      });
      dispatch({
        type: GET_EVENT_NONE_INSIGHTS,
        payload: { workflowID: currentWorkflowID },
      });

      dispatch({
        type: GET_CALENDER_EVENTS,
        payload: {},
      });

      dispatch({
        type: GET_TOTAL_TEXTS,
        payload: payload,
      });
      dispatch({
        type: GET_FAVORITE_FILTERS,
        payload: payload,
      });
      dispatch({
        type: GET_TEAM_SIZE,
        payload: payload,
      });
      dispatch({
        type: GET_MORALE_CHANGE,
        payload: payload,
      });
      dispatch({
        type: GET_TRENDING_TOPICS,
        payload: payload,
      });
      payload["term"] = "";
      payload["engagementTopic"] =
        filters.engagementTopic.length > 0 &&
        filters.engagementTopic[0].toLowerCase() !== "all"
          ? filters.engagementTopic[0]
          : "";
      payload["trendingTopic"] =
        filters.trendingTopic.length > 0 &&
        filters.trendingTopic[0].toLowerCase() !== "all"
          ? filters.trendingTopic[0]
          : "";
      dispatch({
        type: GET_MORALE_SCORE,
        payload: payload,
      });

      delete payload.option;
      dispatch({
        type: GET_ENGAGEMENT,
        payload: payload,
      });
      dispatch({
        type: GET_ENGAGEMENT_TOPIC_TREND,
        payload: payload,
      });
      dispatch({
        type: GET_ENGAGEMENT_COMPARISON,
        payload: payload,
      });
      dispatch({
        type: GET_RETENTION,
        payload: payload,
      });
      dispatch({
        type: GET_RETENTION_COMPARISON,
        payload: payload,
      });
      dispatch({
        type: GET_SUPPORTER_TREND,
        payload: payload,
      });

      dispatch({
        type: GET_ENPS_TEXT_SCORE,
        payload: payload,
      });
      dispatch({
        type: GET_ENPS_TOPIC_SCORE,
        payload: payload,
      });
      dispatch({
        type: GET_ENPS_SURVEY_SCORE,
        payload: payload,
      });
      dispatch({
        type: GET_ENPS_TEXT_TREND,
        payload: payload,
      });
      dispatch({
        type: GET_ENPS_TOPIC_TREND,
        payload: payload,
      });
      dispatch({
        type: GET_ENPS_SURVEY_TREND,
        payload: payload,
      });
      payload["textWeight"] = 0.33;
      payload["topicWeight"] = 0.33;
      payload["surveyWeight"] = 0.33;
      dispatch({
        type: GET_ENPS_OVERALL_SCORE,
        payload: payload,
      });

      dispatch({
        type: GET_ENPS_OVERALL_TREND,
        payload: payload,
      });
      delete payload.textWeight;
      delete payload.topicWeight;
      delete payload.surveyWeight;
      dispatch({
        type: GET_SUPPORTER_COMPARISON,
        payload: payload,
      });
      dispatch({
        type: GET_MORALE_INSIGHTS,
        payload: payload,
      });

      payload["hashTags"] = "";

      payload["politicalConv"] = "";
      dispatch({
        type: GET_SENTIMENTS_DIST,
        payload: payload,
      });
      dispatch({
        type: GET_TOTAL_CONVO_DETAILS,
        payload: payload,
      });
      dispatch({
        type: GET_WORD_CLOUD_DATA,
        payload: payload,
      });
      payload["emotion"] = "joy";
      dispatch({
        type: GET_EMOTION_JOY,
        payload: payload,
      });
      payload["emotion"] = "neutral";
      dispatch({
        type: GET_EMOTION_NEUTRAL,
        payload: payload,
      });
      payload["emotion"] = "anger";

      dispatch({
        type: GET_EMOTION_ANGER,
        payload: payload,
      });
      payload["emotion"] = "fear";
      dispatch({
        type: GET_EMOTION_FEAR,
        payload: payload,
      });
      payload["emotion"] = "sad";
      dispatch({
        type: GET_EMOTION_SAD,
        payload: payload,
      });
      payload["emotion"] = "surprise";
      dispatch({
        type: GET_EMOTION_SURPRISE,
        payload: payload,
      });
      payload["emotion"] = "total";
      dispatch({
        type: GET_EMOTION_TOTAL,
        payload: payload,
      });
      delete payload.emotion;
      delete payload.hashTags;
      delete payload.politicalConv;
      payload["feature"] = "overall";
      dispatch({
        type: GET_OVERALL_CULTURE,
        payload: payload,
      });
      delete payload.feature;
      dispatch({
        type: GET_POSITIVE_CULTURE,
        payload: payload,
      });
      dispatch({
        type: GET_NEGATIVE_CULTURE,
        payload: payload,
      });
      dispatch({
        type: GET_MORALE_TOPICS,
        payload: payload,
      });
      dispatch({
        type: GET_HATE_SPEECH_TRENDS,
        payload: payload,
      });
      dispatch({
        type: GET_INSULT_TRENDS,
        payload: payload,
      });
      dispatch({
        type: GET_SEXUAL_HARRAS_TRENDS,
        payload: payload,
      });
      dispatch({
        type: GET_RACISM_TREND_DATA,
        payload: payload,
      });
      dispatch({
        type: GET_TEXT_ABUSE_DATA,
        payload: payload,
      });
      dispatch({
        type: GET_HASH_TAGS,
        payload: payload,
      });
      dispatch({
        type: GET_POLITICAL_WORDS,
        payload: payload,
      });
      dispatch({
        type: GET_FILTER_EMAILS,
        payload: payload,
      });
    }
  }, [currentFilter, customDate, filters, currentWorkflowID]);

  const engagementThreshold = useSelector(
    (data) => data.dashboard.engagementThreshold
  );

  useEffect(() => {
    if (!isDemo && currentWorkflowID != "") {
      const payload = {
        workflowID: currentWorkflowID,
        timeOption: currentFilter === "max" ? "all" : "custom",
        channels: filters.channels,
        gender: filters.gender,
        startDate:
          currentFilter === "max"
            ? ""
            : currentFilter === "1Y"
            ? new Date(new Date().setFullYear(new Date().getFullYear() - 1))
            : currentFilter === "3M"
            ? new Date(new Date().setMonth(new Date().getMonth() - 3))
            : currentFilter === "1M"
            ? new Date(new Date().setMonth(new Date().getMonth() - 1))
            : currentFilter === "5D"
            ? new Date(new Date().setHours(new Date().getHours() - 24 * 5))
            : currentFilter === "1D"
            ? new Date(new Date().setHours(new Date().getHours() - 24))
            : currentFilter === "custom"
            ? new Date(customDate.start)
            : "",
        endDate:
          currentFilter === "max"
            ? ""
            : currentFilter === "custom"
            ? new Date(customDate.end)
            : new Date(),
        location: filters.location,
        department: filters.department,
        ethinicity: filters.ethinicity,
        marital_status:
          filters.marital_status !== "" ? [filters.marital_status] : [],
        region: filters.region,
        length_of_service: getLengthOfService(filters.length_of_service),
        management_level: filters.management_level,
        income: getIncome(filters.income),
        age: getAge(filters.age),
        performance: filters.performance,
      };

      dispatch({
        type: GET_ENGAGEMENT,
        payload: payload,
      });
      dispatch({
        type: GET_ENGAGEMENT_TOPIC_TREND,
        payload: payload,
      });
    }
  }, [engagementThreshold]);

  // useEffect(() => {
  //   if (!isDemo && currentWorkflowID != "") {
  //     const payload = {
  //       workflowID: currentWorkflowID,
  //       timeOption: currentFilter === "max" ? "all" : "custom",
  //       channels: filters.channels,
  //       gender: filters.gender,
  //       startDate:
  //         currentFilter === "max"
  //           ? ""
  //           : currentFilter === "1Y"
  //           ? new Date(new Date().setFullYear(new Date().getFullYear() - 1))
  //           : currentFilter === "3M"
  //           ? new Date(new Date().setMonth(new Date().getMonth() - 3))
  //           : currentFilter === "1M"
  //           ? new Date(new Date().setMonth(new Date().getMonth() - 1))
  //           : currentFilter === "5D"
  //           ? new Date(new Date().setHours(new Date().getHours() - 24 * 5))
  //           : currentFilter === "1D"
  //           ? new Date(new Date().setHours(new Date().getHours() - 24))
  //           : currentFilter === "custom"
  //           ? new Date(customDate.start)
  //           : "",
  //       endDate:
  //         currentFilter === "max"
  //           ? ""
  //           : currentFilter === "custom"
  //           ? new Date(customDate.end)
  //           : new Date(),
  //       location: filters.location,
  //       department: filters.department,
  //       ethinicity: filters.ethinicity,
  //       marital_status:
  //         filters.marital_status !== "" ? [filters.marital_status] : [],
  //       region: filters.region,
  //       length_of_service: getLengthOfService(filters.length_of_service),
  //       management_level: filters.management_level,
  //       income: getIncome(filters.income),
  //       age: getAge(filters.age),
  //       performance: filters.performance,
  //     };

  //     dispatch({
  //       type: GET_ENPS_OVERALL_SCORE,
  //       payload: payload,
  //     });

  //     dispatch({
  //       type: GET_ENPS_OVERALL_TREND,
  //       payload: payload,
  //     });
  //   }
  // }, [eNPSThreshold]);

  const [cards, setCards] = useState(() => {
    return JSON.parse(localStorage.getItem("keyTopicData")) || [];
  });

  useEffect(() => {
    !access.filters && setLeftHidden(true);
  }, [access.filters]);

  const [moraleConfigOpen, setMoraleConfigOpen] = useState(false);
  const [engagementConfigOpen, setEngagementConfigOpen] = useState(false);
  const [retentionConfigOpen, setRetentionConfigOpen] = useState(false);
  const [enpsConfigOpen, setEnpsConfigOpen] = useState(false);
  const [cultureConfigOpen, setCultureConfigOpen] = useState(false);

  const [currentStep, setCurrentStep] = useState(1);

  const [dateRange, setDateRange] = useState({
    start: new Date(),
    end: new Date(),
  });

  const [dateRangeOption, setDateRangeOption] = useState("90Days");
  return (
    <div>
      {workflows.Workflows.length == 0 ? (
        skipped ? (
          <Modal
            open={openIntroTutModal}
            onClose={handleCloseIntroTutModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            {currentStep === 1 ? (
              <Step1Modal
                handleClose={handleCloseIntroTutModal}
                setCurrentScreen={(screen) => setCurrentScreen(screen)}
                currentScreen={currentScreen}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
              />
            ) : currentStep === 2 ? (
              <Step2Modal
                handleClose={handleCloseIntroTutModal}
                setCurrentScreen={(screen) => setCurrentScreen(screen)}
                currentScreen={currentScreen}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
              />
            ) : currentStep === 3 ? (
              <Step3Modal
                handleClose={handleCloseIntroTutModal}
                setCurrentScreen={(screen) => setCurrentScreen(screen)}
                currentScreen={currentScreen}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                setTopStatusAccordian={setTopStatusAccordian}
                setInsightAccordion={setInsightAccordion}
              />
            ) : currentStep === 4 ? (
              <Step4Modal
                handleClose={handleCloseIntroTutModal}
                setCurrentScreen={(screen) => setCurrentScreen(screen)}
                currentScreen={currentScreen}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                setTopStatusAccordian={setTopStatusAccordian}
                setInsightAccordion={setInsightAccordion}
                setMoraleAccordion={setMoraleAccordion}
              />
            ) : currentStep === 5 ? (
              <Step5Modal
                handleClose={handleCloseIntroTutModal}
                setCurrentScreen={(screen) => setCurrentScreen(screen)}
                currentScreen={currentScreen}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                setTopStatusAccordian={setTopStatusAccordian}
                setInsightAccordion={setInsightAccordion}
                setMoraleAccordion={setMoraleAccordion}
                setEnagagementAccordion={setEnagagementAccordion}
              />
            ) : currentStep === 6 ? (
              <Step6Modal
                handleClose={handleCloseIntroTutModal}
                setCurrentScreen={(screen) => setCurrentScreen(screen)}
                currentScreen={currentScreen}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                setTopStatusAccordian={setTopStatusAccordian}
                setInsightAccordion={setInsightAccordion}
                setEnagagementAccordion={setEnagagementAccordion}
                setRetentionAccordion={setRetentionAccordion}
              />
            ) : currentStep === 7 ? (
              <Step7Modal
                handleClose={handleCloseIntroTutModal}
                setCurrentScreen={(screen) => setCurrentScreen(screen)}
                currentScreen={currentScreen}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                setTopStatusAccordian={setTopStatusAccordian}
                setInsightAccordion={setInsightAccordion}
                setRetentionAccordion={setRetentionAccordion}
                setSupporterAccordion={setSupporterAccordion}
              />
            ) : currentStep === 8 ? (
              <Step8Modal
                handleClose={handleCloseIntroTutModal}
                setCurrentScreen={(screen) => setCurrentScreen(screen)}
                currentScreen={currentScreen}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                setTopStatusAccordian={setTopStatusAccordian}
                setInsightAccordion={setInsightAccordion}
                setSupporterAccordion={setSupporterAccordion}
                setCultureAccordion={setCultureAccordion}
              />
            ) : currentStep === 9 ? (
              <Step9Modal
                handleClose={handleCloseIntroTutModal}
                setCurrentScreen={(screen) => setCurrentScreen(screen)}
                currentScreen={currentScreen}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                setTopStatusAccordian={setTopStatusAccordian}
                setInsightAccordion={setInsightAccordion}
                setSupporterAccordion={setSupporterAccordion}
                setCultureAccordion={setCultureAccordion}
              />
            ) : (
              <Step9Modal
                handleClose={handleCloseIntroTutModal}
                setCurrentScreen={(screen) => setCurrentScreen(screen)}
                currentScreen={currentScreen}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                setTopStatusAccordian={setTopStatusAccordian}
                setInsightAccordion={setInsightAccordion}
                setSupporterAccordion={setSupporterAccordion}
                setCultureAccordion={setCultureAccordion}
              />
            )}
          </Modal>
        ) : (
          ""
        )
      ) : (
        ""
      )}
      <div style={{ width: "100%", zIndex: "4" }}>
        <div
          className="optionContainer"
          style={!isNight ? { backgroundColor: "#212047" } : {}}
        >
          <div style={{ display: "flex" }}>
            {access.direct_reports && (
              <Tooltip title="Download Report">
                <IosShareIcon
                  style={{
                    margin: "1rem 0.5rem",
                    filter: !isNight ? "invert(0.6)" : "invert(0.5)",
                    cursor: "pointer",
                    maxHeight: "50px",
                  }}
                  onClick={() => {
                    _exportPdf();
                  }}
                />
              </Tooltip>
            )}
            <Tooltip
              title={
                <span
                  style={{
                    fontSize: "0.8rem",
                    textAlign: "center",
                  }}
                >
                  Events
                </span>
              }
            >
              <EventIcon
                style={{
                  margin: "1rem 0.5rem",
                  filter: !isNight ? "invert(0.6)" : "invert(0.5)",
                  cursor: "pointer",
                  maxHeight: "50px",
                  position: "relative",
                  top: "2px",
                }}
                onClick={() => {
                  setAnnotationModalOpen(true);
                }}
              />
            </Tooltip>
          </div>
          <div>
            <AppliedFilters />
          </div>
          <div>
            <Modal
              open={addSecModalOpen}
              onClose={addSecModalCloseHandler}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <AddSectionModal
                open={addSecModalOpen}
                handleClose={addSecModalCloseHandler}
                title="Add Section"
                workflowID={currentWorkflowID}
                // handleDelete={onDeleteWorkflow}
              />
            </Modal>
            <span style={{ margin: "1rem 1rem 1rem 0", display: "flex" }}>
              <div
                style={{
                  display: "flex",
                }}
              >
                {/* <div
                  className="filterContainer"
                  onClick={() => {
                    setCustomDatePickerOpen(true);
                  }}
                  style={
                    currentFilter === "custom"
                      ? {
                          backgroundColor: "#D06283",
                          color: "#fff",
                        }
                      : !isNight
                      ? {
                          background: "#212047",
                          border: "1px solid #aebcc9",
                          color: "#aebcc9",
                        }
                      : {}
                  }
                >
                  <EventIcon
                    style={
                      currentFilter !== "custom"
                        ? {
                            color: "#A1A4AC",
                            fontSize: "14px",
                            position: "relative",
                            top: "3px",
                          }
                        : {
                            color: "#fff",
                            fontSize: "14px",
                            position: "relative",
                            top: "3px",
                          }
                    }
                  />
                  {customDate.start !== ""
                    ? `${customDate.start} - ${customDate.end}`
                    : "Custom"}
                </div>
                <Modal
                  open={customDatePickerOpen}
                  onClose={customDateCloseHandler}
                  aria-labelledby="simple-modal-title"
                  aria-describedby="simple-modal-description"
                >
                  <CustomDatePicker
                    currentDate={customDate}
                    open={customDatePickerOpen}
                    handleClose={customDateCloseHandler}
                    title="Delete Workflow"
                    handleDateChange={onDateChange}
                  />
                </Modal> */}

                {/* <div
                  className="filterContainer"
                  onClick={() => {
                    setCurrentFilter("1M");
                  }}
                  style={
                    currentFilter === "1M"
                      ? {
                          backgroundColor: "#D06283",
                          color: "#fff",
                        }
                      : !isNight
                      ? {
                          background: "#212047",
                          border: "1px solid #aebcc9",
                          color: "#aebcc9",
                        }
                      : {}
                  }
                >
                  1M
                </div>
                <div
                  className="filterContainer"
                  onClick={() => {
                    setCurrentFilter("3M");
                  }}
                  style={
                    currentFilter === "3M"
                      ? {
                          backgroundColor: "#D06283",
                          color: "#fff",
                        }
                      : !isNight
                      ? {
                          background: "#212047",
                          border: "1px solid #aebcc9",
                          color: "#aebcc9",
                        }
                      : {}
                  }
                >
                  3M
                </div>
                <div
                  className="filterContainer"
                  onClick={() => {
                    setCurrentFilter("1Y");
                  }}
                  style={
                    currentFilter === "1Y"
                      ? {
                          backgroundColor: "#D06283",
                          color: "#fff",
                        }
                      : !isNight
                      ? {
                          background: "#212047",
                          border: "1px solid #aebcc9",
                          color: "#aebcc9",
                        }
                      : {}
                  }
                >
                  1Y
                </div>
                <div
                  className="filterContainer"
                  onClick={() => {
                    setCurrentFilter("max");
                  }}
                  style={
                    currentFilter === "max"
                      ? {
                          backgroundColor: "#D06283",
                          color: "#fff",
                        }
                      : !isNight
                      ? {
                          background: "#212047",
                          border: "1px solid #aebcc9",
                          color: "#aebcc9",
                        }
                      : {}
                  }
                >
                  Max
                </div> */}
                <TimeFilterSelect
                  workflowChangeHandler={workflowChangeHandler}
                  currentWorkflowName={currentWorkflowName}
                  currentWorkflowID={currentWorkflowID}
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                  dateRangeOption={dateRangeOption}
                  setDateRangeOption={setDateRangeOption}
                  onDateChange={onDateChange}
                  setCurrentFilter={setCurrentFilter}
                />
              </div>
              <WorkflowSelect
                workflowChangeHandler={workflowChangeHandler}
                currentWorkflowName={currentWorkflowName}
                currentWorkflowID={currentWorkflowID}
              />
            </span>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <ChartsModal
              handleClose={handleClose}
              doughnutData={doughnutData}
            />
          </Modal>
        </div>
      </div>
      <div>
        {/* <Box component="main"> */}
        <LoadingOverlay
          active={loadingStatus}
          // active
          spinner={<CustomLoader />}
        >
          <Grid container>
            {leftHidden && (
              <Grid
                item
                style={{
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: isNight ? "#f2f2f2" : "#212047",
                }}
                lg={0.8}
                sm={0.8}
                xl={0.8}
                xs={0.8}
              >
                <div>
                  <Grid container spacing={3}>
                    <Grid item lg={12} sm={12} xl={12} xs={12}>
                      <Filters
                        currentWorkID={currentWorkflowID}
                        handleSwitchHidden={handleSwitchHidden}
                        leftHidden={leftHidden}
                        addSecModalOpenHandler={addSecModalOpenHandler}
                        currentFilter={currentFilter}
                        customDate={customDate}
                        currentWorkflowID={currentWorkflowID}
                        trendingTopicsData={
                          isDemo ? demoTrendingTopicsData : trendingTopicsData
                        }
                        hashTagsData={isDemo ? demoHashTagsData : hashTagsData}
                        filterChannels={filterChannels}
                      />
                    </Grid>
                    {!leftHidden && (
                      <Grid item lg={12} sm={12} xl={12} xs={12}>
                        <div
                          style={{
                            backgroundColor: !isNight ? "#212047" : "#f2f2f2",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              padding: "10px 10px 40px",
                              backgroundColor: !isNight ? "#212047" : "#f2f2f2",
                            }}
                          >
                            <KeyTopics
                              currentWorkflowID={currentWorkflowID}
                              handleSwitchHidden={handleSwitchHidden}
                              leftHidden={leftHidden}
                              cards={cards}
                              setCards={setCards}
                            />
                            <TrendingTopics
                              currentFilter={currentFilter}
                              customDate={customDate}
                              currentWorkflowID={currentWorkflowID}
                              handleSwitchHidden={handleSwitchHidden}
                              leftHidden={leftHidden}
                              cards={cards}
                              setCards={setCards}
                              trendingTopicsData={
                                isDemo
                                  ? demoTrendingTopicsData
                                  : trendingTopicsData
                              }
                              hashTagsData={
                                isDemo ? demoHashTagsData : hashTagsData
                              }
                            />
                          </div>
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </div>
              </Grid>
            )}
            {!leftHidden && (
              <Grid
                item
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  backgroundColor: isNight ? "#F2F2F2" : "#212047",
                  zIndex: 2,
                  // cursor: isZoom ? "not-allowed" : "",
                }}
                xl={2.5}
                lg={2.5}
                sm={2.5}
                xs={2.5}
              >
                <div
                  style={{
                    height: "75vh",
                    minHeight: "600px",
                    overflowY: "scroll",
                    // pointerEvents: isZoom ? "none" : "",
                  }}
                >
                  <Grid container>
                    <Grid
                      style={{
                        height: "100%",
                        overflowY: "scroll",
                        minWidth: "21%",
                        width: "1%",
                      }}
                      item
                      lg={12}
                      sm={12}
                      xl={12}
                      xs={12}
                    >
                      <Filters
                        currentWorkID={currentWorkflowID}
                        handleSwitchHidden={handleSwitchHidden}
                        leftHidden={leftHidden}
                        addSecModalOpenHandler={addSecModalOpenHandler}
                        cards={cards}
                        setCards={setCards}
                        filterChannels={filterChannels}
                        isZoom={isZoom}
                        workflowChangeHandler={workflowChangeHandler}
                      />
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            )}
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                paddingLeft: "10px",
              }}
              xl={leftHidden ? 11.2 : 9.5}
              lg={leftHidden ? 11.2 : 9.5}
              sm={leftHidden ? 11.2 : 9.5}
              xs={leftHidden ? 11.2 : 9.5}
            >
              <div
                style={{
                  backgroundColor: !isNight ? "#17123B" : "#fff",
                  paddingRight: "20px",
                  marginLeft: "14px",
                  marginTop: "20px",
                  position: "relative",
                  height: "75vh",
                  minHeight: "400px",
                  overflowY: "scroll",
                }}
              >
                {access.overall_company && (
                  <div style={{ marginBottom: "20px" }}>
                    <Accordion
                      onChange={(e, expanded) => {
                        setTopStatusAccordian(expanded);
                      }}
                      expanded={topStatusAccordian}
                      defaultExpanded
                      sx={{
                        borderRadius: "1px !important",
                        background: !isNight
                          ? "#17123c !important"
                          : "#f4f5f7 !important",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: !isNight
                                ? "#aebcc9 !important"
                                : "#343434 !important",
                              margin: "0 0.5rem",
                            }}
                          />
                        }
                        sx={{
                          backgroundColor: isNight
                            ? "#F6F7FC !important"
                            : "#212047 !important",
                          color: !isNight
                            ? "#aebcc9 !important"
                            : "#343434 !important",
                          width: "100%",
                          textAlign: "left",
                          borderRadius: "10px !important",
                        }}
                      >
                        <Tooltip
                          title={
                            <span
                              style={{
                                fontSize: "0.8rem",
                                textAlign: "center",
                              }}
                            >
                              See your favorite employee metrics in the Overview
                              section
                            </span>
                          }
                        >
                          <p
                            style={{
                              color: "#fff",
                              width: "100px",
                              background: "#D06283",
                              fontWeight: 600,
                              padding: "5px 0",
                              margin: "0 0.8rem",
                              textAlign: "center",
                              boxShadow:
                                "0px 3.86842px 23.2105px rgba(0, 0, 0, 0.05)",
                              borderRadius: "7.73684px",
                            }}
                          >
                            Overview
                          </p>
                        </Tooltip>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          backgroundColor: isNight
                            ? "#F6F7FC !important"
                            : "#212047 !important",
                          color: "#999 !important",
                          borderRadius: "10px",
                        }}
                      >
                        {topStatusAccordian && (
                          <TopStatusBox
                            currentFilter={currentFilter}
                            customDate={customDate}
                            currentWorkflowID={currentWorkflowID}
                            moraleScore={moraleScore}
                            engagementData={engagementTrendData}
                            retentionData={retentionData}
                            supporterData={supporterData}
                            sentimentsDist={sentimentsDist}
                          />
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                )}

                {access.key_insights && (
                  <div style={{ marginBottom: "20px" }}>
                    <Accordion
                      expanded={insightAccordion}
                      onChange={(e, expanded) => {
                        setInsightAccordion(expanded);
                      }}
                      sx={{
                        background: !isNight
                          ? "#17123c !important"
                          : "#f4f5f7 !important",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: !isNight
                                ? "#aebcc9 !important"
                                : "#343434 !important",
                              margin: "0 0.5rem",
                            }}
                          />
                        }
                        sx={{
                          backgroundColor: isNight
                            ? "#F6F7FC !important"
                            : "#212047 !important",
                          color: !isNight
                            ? "#aebcc9 !important"
                            : "#343434 !important",
                          width: "100%",
                          textAlign: "left",
                          borderRadius: "10px !important",
                        }}
                      >
                        <Tooltip
                          title={
                            <span
                              style={{
                                fontSize: "0.8rem",
                                textAlign: "center",
                              }}
                            >
                              See the biggest changes in your organization every
                              month in the Key Insights section
                            </span>
                          }
                        >
                          <p
                            style={{
                              color: "#fff",
                              width: "130px",
                              background: "#D06283",
                              fontWeight: 600,
                              padding: "5px 0",
                              margin: "0 0.8rem",
                              textAlign: "center",
                              boxShadow:
                                "0px 3.86842px 23.2105px rgba(0, 0, 0, 0.05)",
                              borderRadius: "7.73684px",
                            }}
                          >
                            Key Insights
                          </p>
                        </Tooltip>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          backgroundColor: isNight
                            ? "#F6F7FC !important"
                            : "#212047 !important",
                          color: "#999 !important",
                          borderRadius: "10px",
                        }}
                      >
                        {insightAccordion && (
                          <div>
                            <Insights />
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                )}

                {access.morale && (
                  <div style={{ marginBottom: "20px" }}>
                    <Accordion
                      expanded={moraleAccordion}
                      onChange={(e, expanded) => {
                        setMoraleAccordion(expanded);
                      }}
                      sx={{
                        background: !isNight
                          ? "#17123c !important"
                          : "#f4f5f7 !important",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: !isNight
                                ? "#aebcc9 !important"
                                : "#343434 !important",
                              margin: "0 0.5rem",
                            }}
                          />
                        }
                        sx={{
                          backgroundColor: isNight
                            ? "#F6F7FC !important"
                            : "#212047 !important",
                          color: !isNight
                            ? "#aebcc9 !important"
                            : "#343434 !important",
                          width: "100%",
                          textAlign: "left",
                          borderRadius: "10px !important",
                        }}
                      >
                        {" "}
                        <Tooltip
                          title={
                            <span
                              style={{
                                fontSize: "0.8rem",
                                textAlign: "center",
                              }}
                            >
                              See the morale score of your organization in the
                              Morale section
                            </span>
                          }
                        >
                          <p
                            style={{
                              color: "#fff",
                              width: "75px",
                              background: "#D06283",
                              fontWeight: 600,
                              padding: "5px 0",
                              margin: "0 0.8rem",
                              textAlign: "center",
                              boxShadow:
                                "0px 3.86842px 23.2105px rgba(0, 0, 0, 0.05)",
                              borderRadius: "7.73684px",
                            }}
                          >
                            Morale
                          </p>
                        </Tooltip>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          backgroundColor: isNight
                            ? "#F6F7FC !important"
                            : "#212047 !important",
                          color: "#999 !important",
                          borderRadius: "10px",
                        }}
                      >
                        {moraleAccordion && (
                          <div>
                            <SettingsIcon
                              onClick={() => {
                                setMoraleConfigOpen(true);
                              }}
                              style={{
                                color: "#727272",
                                cursor: "pointer",
                                zIndex: "400",
                                position: "absolute",
                                right: "65px",
                                top: "25px",
                              }}
                            />
                            <Modal
                              open={moraleConfigOpen}
                              onClose={() => {
                                setMoraleConfigOpen(false);
                              }}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <SetGoalModal
                                metric="morale"
                                handleClose={() => {
                                  setMoraleConfigOpen(false);
                                }}
                              />
                            </Modal>
                            <div id="capture-morale">
                              <div style={{ margin: "0 0 0 0.2rem" }}>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={4}
                                    xl={4}
                                  >
                                    <div
                                      ref={moraleMeterRef}
                                      style={{
                                        height: "330px",
                                        borderRadius: "12px",
                                        backgroundColor: !isNight
                                          ? "#2A2953"
                                          : "#fff",
                                      }}
                                    >
                                      <div
                                        style={{
                                          position: "relative",
                                          top: "15px",
                                          margin: "1rem",
                                          display: "flex",
                                          width: "92%",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <p
                                          style={{
                                            textAlign: "left",
                                            fontWeight: 500,
                                            margin: "0",
                                            color: !isNight
                                              ? "#fff"
                                              : "#484848",
                                          }}
                                        >
                                          Morale Meter{" "}
                                          <Tooltip
                                            title={
                                              <span
                                                style={{
                                                  fontSize: "0.8rem",
                                                  textAlign: "center",
                                                }}
                                              >
                                                Morale Score Over the Selected Date Range
                                              </span>
                                            }
                                          >
                                            <InfoIcon
                                              style={{
                                                fontSize: "1rem",
                                                position: "relative",
                                                top: "3px",
                                                filter: "opacity(0.7)",
                                              }}
                                            />
                                          </Tooltip>
                                        </p>
                                        <Tooltip
                                          title={
                                            <span
                                              style={{
                                                fontSize: "0.8rem",
                                                textAlign: "center",
                                              }}
                                            >
                                              Copy to clipboard
                                            </span>
                                          }
                                        >
                                          {clipProcessing ? (
                                            <CircularProgress
                                              size={16}
                                              color="inherit"
                                            />
                                          ) : (
                                            <ContentCopyOutlinedIcon
                                              onClick={getImage}
                                              style={{
                                                fontSize: "1.2rem",
                                                zIndex: 3,
                                                cursor: clipProcessing
                                                  ? "wait"
                                                  : "pointer",
                                              }}
                                            />
                                          )}
                                        </Tooltip>
                                      </div>
                                      <div
                                        style={{
                                          position: "relative",
                                          top: "0px",
                                        }}
                                      >
                                        {moraleScore.status === "success" ||
                                        (isDemo && !isLoading) ? (
                                          <>
                                            <Gauge
                                              value={Math.round(
                                                isDemo
                                                  ? demoScore
                                                  : moraleScore.avgMorale
                                              )}
                                              min={0}
                                              max={100}
                                              high={moraleScore.maxMorale}
                                              low={moraleScore.minMorale}
                                              minMaxMorale={minMaxMorale}
                                            />
                                            <div
                                              style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                margin: "0 2rem",
                                                position: "relative",
                                                top: "-50px",
                                              }}
                                            >
                                              <p
                                                style={{
                                                  color: "#727272",
                                                  fontSize: "0.8rem",
                                                  textAlign: "center",
                                                  width: "20%",
                                                }}
                                              >
                                                Score
                                              </p>
                                            </div>
                                          </>
                                        ) : moraleScore?.status === "fail" ||
                                          currentWorkflowID === "" ? (
                                          <div
                                            style={{
                                              position: "relative",
                                              top: "100px",
                                            }}
                                          >
                                            <ErrorOutlineIcon
                                              style={{ color: "#94989f" }}
                                            />
                                            <p
                                              style={{
                                                margin: "0",
                                                color: "#94989f",
                                              }}
                                            >
                                              {currentWorkflowID === ""
                                                ? "Select a workflow!"
                                                : "No Data found!"}
                                            </p>
                                          </div>
                                        ) : (
                                          <Skeleton
                                            variant="circular"
                                            width={"75%"}
                                            height={"250px"}
                                            style={{
                                              position: "relative",
                                              top: "30px",
                                              margin: "0 auto",
                                            }}
                                          />
                                        )}
                                      </div>
                                    </div>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={8}
                                    xl={8}
                                  >
                                    <ModelDist
                                      currentWorkflowID={currentWorkflowID}
                                      moraleData={
                                        isDemo ? demoDistData : moraleScore
                                      }
                                      currentFilterMorale={currentFilter}
                                      customDateMorale={customDate}
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                              <div style={{ margin: " 0 " }}>
                                <Grid container spacing={3}>
                                  <Grid item xl={12} lg={12} sm={12} xs={12}>
                                    <MoraleTrend
                                      moraleData={moraleScore}
                                      currentWorkflowID={currentWorkflowID}
                                      handleShuffleDemoDistData={
                                        handleShuffleDemoDistData
                                      }
                                      updateMoraleLine={updateMoraleLine}
                                      currentFilter={currentFilter}
                                    />
                                  </Grid>
                                </Grid>
                              </div>
                              <div style={{ margin: "0 " }}>
                                <Grid container spacing={3}>
                                  <Grid item xl={12} lg={12} sm={12} xs={12}>
                                    {isDemo ? (
                                      <DemoMoraleCompare
                                        currentWorkflowID={currentWorkflowID}
                                        moraleData={moraleScore}
                                        currentFilter={currentFilter}
                                        customDate={customDate}
                                        moraleLineToCompare={
                                          moraleLineToCompare
                                        }
                                        moraleCompareOption={
                                          moraleCompareOption
                                        }
                                        setMoraleCompareOption={
                                          setMoraleCompareOption
                                        }
                                      />
                                    ) : (
                                      <MoraleCompare
                                        currentWorkflowID={currentWorkflowID}
                                        moraleData={moraleScore}
                                        currentFilter={currentFilter}
                                        customDate={customDate}
                                        moraleLineToCompare={
                                          moraleLineToCompare
                                        }
                                        moraleCompareOption={
                                          moraleCompareOption
                                        }
                                        setMoraleCompareOption={
                                          setMoraleCompareOption
                                        }
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </div>
                            </div>
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                )}
                {access.engagement && (
                  <div style={{ marginBottom: "20px" }}>
                    <Accordion
                      expanded={enagagementAccordion}
                      onChange={(e, expanded) => {
                        setEnagagementAccordion(expanded);
                      }}
                      sx={{
                        borderRadius: "1px !important",
                        background: !isNight
                          ? "#17123c !important"
                          : "#f4f5f7 !important",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: !isNight
                                ? "#aebcc9 !important"
                                : "#343434 !important",
                              margin: "0 0.5rem",
                            }}
                          />
                        }
                        sx={{
                          backgroundColor: isNight
                            ? "#F6F7FC !important"
                            : "#212047 !important",
                          color: !isNight
                            ? "#aebcc9 !important"
                            : "#343434 !important",
                          width: "100%",
                          textAlign: "left",
                          borderRadius: "10px !important",
                        }}
                      >
                        <Tooltip
                          title={
                            <span
                              style={{
                                fontSize: "0.8rem",
                                textAlign: "center",
                              }}
                            >
                              See the employee engagement score of your
                              organization in the Engagement section
                            </span>
                          }
                        >
                          <p
                            style={{
                              color: "#fff",
                              width: "130px",
                              background: "#D06283",
                              fontWeight: 600,
                              padding: "5px 0",
                              margin: "0 0.8rem",
                              textAlign: "center",
                              boxShadow:
                                "0px 3.86842px 23.2105px rgba(0, 0, 0, 0.05)",
                              borderRadius: "7.73684px",
                            }}
                          >
                            Engagement
                          </p>
                        </Tooltip>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          backgroundColor: isNight
                            ? "#F6F7FC !important"
                            : "#212047 !important",
                          color: "#999 !important",
                          borderRadius: "10px",
                        }}
                      >
                        {enagagementAccordion && (
                          <div id="capture-engagement">
                            {!isZoom && (
                              <>
                                <EngagementBlock
                                  currentWorkflowID={currentWorkflowID}
                                  engagementData={engagementTrendData}
                                  engagementTopicTrendData={
                                    engagementTopicTrendData
                                  }
                                  currentFilter={currentFilter}
                                  customDate={customDate}
                                />
                              </>
                            )}
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                )}
                {access.retention_risk && (
                  <div style={{ marginBottom: "20px" }}>
                    <Accordion
                      expanded={retentionAccordion}
                      onChange={(e, expanded) => {
                        setRetentionAccordion(expanded);
                      }}
                      sx={{
                        borderRadius: "1px !important",
                        background: !isNight
                          ? "#17123c !important"
                          : "#f4f5f7 !important",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: !isNight
                                ? "#aebcc9 !important"
                                : "#343434 !important",
                              margin: "0 0.5rem",
                            }}
                          />
                        }
                        sx={{
                          backgroundColor: isNight
                            ? "#F6F7FC !important"
                            : "#212047 !important",
                          color: !isNight
                            ? "#aebcc9 !important"
                            : "#343434 !important",
                          width: "100%",
                          textAlign: "left",
                          borderRadius: "10px !important",
                        }}
                      >
                        <Tooltip
                          title={
                            <span
                              style={{
                                fontSize: "0.8rem",
                                textAlign: "center",
                              }}
                            >
                              See the Retention Risk score of your organization
                              in the Retention Risk section
                            </span>
                          }
                        >
                          <p
                            style={{
                              color: "#fff",
                              width: "150px",
                              background: "#D06283",
                              fontWeight: 600,
                              padding: "5px 0",
                              margin: "0 0.8rem",
                              textAlign: "center",
                              boxShadow:
                                "0px 3.86842px 23.2105px rgba(0, 0, 0, 0.05)",
                              borderRadius: "7.73684px",
                            }}
                          >
                            Retention Risk
                          </p>
                        </Tooltip>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          backgroundColor: isNight
                            ? "#F6F7FC !important"
                            : "#212047 !important",
                          color: "#999 !important",
                          borderRadius: "10px",
                        }}
                      >
                        {retentionAccordion && (
                          <div id="capture-retention">
                            <SettingsIcon
                              onClick={() => {
                                setRetentionConfigOpen(true);
                              }}
                              style={{
                                color: "#727272",
                                cursor: "pointer",
                                zIndex: "400",
                                position: "absolute",
                                right: "65px",
                                top: "25px",
                              }}
                            />
                            <Modal
                              open={retentionConfigOpen}
                              onClose={() => {
                                setRetentionConfigOpen(false);
                              }}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                            >
                              <SetGoalModal
                                metric="retention"
                                handleClose={() => {
                                  setRetentionConfigOpen(false);
                                }}
                              />
                            </Modal>
                            <div style={{ margin: "0" }}>
                              <Grid container spacing={3}>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={4}
                                  xl={4}
                                >
                                  <Suspense fallback={<p>loading</p>}>
                                    <RetentionMeter
                                      currentWorkflowID={currentWorkflowID}
                                      retentionData={retentionData}
                                      currentFilter={currentFilter}
                                    />
                                  </Suspense>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={8}
                                  xl={8}
                                >
                                  <Suspense fallback={<p>loading</p>}>
                                    <RetentionTrend
                                      currentWorkflowID={currentWorkflowID}
                                      retentionData={retentionData}
                                    />
                                  </Suspense>
                                </Grid>
                              </Grid>
                            </div>

                            {!isZoom && (
                              <div style={{ margin: "0 " }}>
                                <Grid container spacing={3}>
                                  <Grid item xl={12} lg={12} sm={12} xs={12}>
                                    {isDemo && (
                                      <RetentionCompare
                                        currentWorkflowID={currentWorkflowID}
                                        currentFilter={currentFilter}
                                        retentionData={retentionData}
                                        retentionCompareData={
                                          retentionCompareData
                                        }
                                      />
                                    )}
                                  </Grid>
                                </Grid>
                              </div>
                            )}
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                )}
                {access.supporter_detractor && (
                  <div style={{ marginBottom: "20px" }}>
                    <Accordion
                      expanded={supporterAccordion}
                      onChange={(e, expanded) => {
                        setSupporterAccordion(expanded);
                      }}
                      sx={{
                        borderRadius: "1px !important",
                        background: !isNight
                          ? "#17123c !important"
                          : "#f4f5f7 !important",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: !isNight
                                ? "#aebcc9 !important"
                                : "#343434 !important",
                              margin: "0 0.5rem",
                            }}
                          />
                        }
                        sx={{
                          backgroundColor: isNight
                            ? "#F6F7FC !important"
                            : "#212047 !important",
                          color: !isNight
                            ? "#aebcc9 !important"
                            : "#343434 !important",
                          width: "100%",
                          textAlign: "left",
                          borderRadius: "10px !important",
                        }}
                      >
                        <Tooltip
                          title={
                            <span
                              style={{
                                fontSize: "0.8rem",
                                textAlign: "center",
                              }}
                            >
                              See your employee Net Promoter Score in the eNPS
                              section
                            </span>
                          }
                        >
                          <p
                            style={{
                              color: "#fff",
                              width: "270px",
                              background: "#D06283",
                              fontWeight: 600,
                              padding: "5px 0",
                              margin: "0 0.8rem",
                              textAlign: "center",
                              boxShadow:
                                "0px 3.86842px 23.2105px rgba(0, 0, 0, 0.05)",
                              borderRadius: "7.73684px",
                            }}
                          >
                            Employee Net Promoter Score
                          </p>
                        </Tooltip>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          backgroundColor: isNight
                            ? "#F6F7FC !important"
                            : "#212047 !important",
                          color: "#999 !important",
                          borderRadius: "10px",
                        }}
                      >
                        {supporterAccordion && (
                          <div id="capture-supporter">
                            <SupporterDetractor
                              currentWorkflowID={currentWorkflowID}
                              currentFilter={currentFilter}
                              customDate={customDate}
                            />
                            {/* <div style={{ margin: "0 " }}>
                              <Grid container spacing={3}>
                                <Grid item xl={12} lg={12} sm={12} xs={12}>
                                  <SupporterCompare
                                    currentWorkflowID={currentWorkflowID}
                                    currentFilter={currentFilter}
                                    supporterData={supporterData}
                                  />
                                </Grid>
                              </Grid>
                            </div>
                            <div style={{ margin: "0 " }}>
                              <Grid container spacing={3}>
                                <Grid item xl={12} lg={12} sm={12} xs={12}>
                                  <DetractorCompare
                                    currentWorkflowID={currentWorkflowID}
                                    currentFilter={currentFilter}
                                    retentionData={retentionData}
                                  />
                                </Grid>
                              </Grid>
                            </div> */}
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                )}
                {access.topic_analysis && (
                  <div style={{ marginBottom: "20px" }}>
                    <Accordion
                      onChange={(e, expanded) => {
                        setTopicAccordion(expanded);
                      }}
                      sx={{
                        borderRadius: "1px !important",
                        background: !isNight
                          ? "#17123c !important"
                          : "#f4f5f7 !important",
                      }}
                    >
                      <AccordionSummary
                        expandIcon={
                          <ExpandMoreIcon
                            sx={{
                              color: !isNight
                                ? "#aebcc9 !important"
                                : "#343434 !important",
                              margin: "0 0.5rem",
                            }}
                          />
                        }
                        sx={{
                          backgroundColor: isNight
                            ? "#F6F7FC !important"
                            : "#212047 !important",
                          color: !isNight
                            ? "#aebcc9 !important"
                            : "#343434 !important",
                          width: "100%",
                          textAlign: "left",
                          borderRadius: "10px !important",
                        }}
                      >
                        <Tooltip
                          title={
                            <span
                              style={{
                                fontSize: "0.8rem",
                                textAlign: "center",
                              }}
                            >
                              See what topics are trending in your workplace in
                              the Topics section
                            </span>
                          }
                        >
                          <p
                            style={{
                              color: "#fff",
                              width: "80px",
                              background: "#D06283",
                              fontWeight: 600,
                              padding: "5px 0",
                              margin: "0 0.8rem",
                              textAlign: "center",
                              boxShadow:
                                "0px 3.86842px 23.2105px rgba(0, 0, 0, 0.05)",
                              borderRadius: "7.73684px",
                            }}
                          >
                            Topics
                          </p>
                        </Tooltip>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          backgroundColor: isNight
                            ? "#F6F7FC !important"
                            : "#212047 !important",
                          color: "#999 !important",
                          borderRadius: "10px",
                        }}
                      >
                        {topicAccordion && (
                          <div id="capture-topic">
                            <div style={{ position: "relative" }}>
                              <Grid container spacing={3}>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={4}
                                  xl={4}
                                >
                                  <Suspense fallback={<p>loading</p>}>
                                    <SentimentScore
                                      sentimentsDist={sentimentsDist}
                                      currentFilter={currentFilter}
                                      currentWorkflowID={currentWorkflowID}
                                      customDate={customDate}
                                    />
                                  </Suspense>
                                </Grid>
                                <Grid
                                  item
                                  style={{ paddingLeft: "16px" }}
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={8}
                                  xl={8}
                                >
                                  <Suspense fallback={<p>loading</p>}>
                                    <SentimentTrend
                                      currentFilter={currentFilter}
                                      customDate={customDate}
                                      totalConvoData={
                                        isDemo
                                          ? demoSentimentTrend
                                          : filters.gender === ""
                                          ? totalConvoData
                                          : filters.gender === "male"
                                          ? totalConvoDataMale
                                          : filters.gender === "female"
                                          ? totalConvoDataFemale
                                          : totalConvoData
                                      }
                                      currentWorkflowID={currentWorkflowID}
                                    />
                                  </Suspense>
                                </Grid>
                              </Grid>
                            </div>
                            <div style={{ margin: "0" }}>
                              <Grid container spacing={3}>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={9}
                                  xl={9}
                                >
                                  <Suspense fallback={<p>loading</p>}>
                                    <Wordcloud
                                      currentWorkflowID={currentWorkflowID}
                                      words={words}
                                      currentFilter={currentFilter}
                                      customDate={customDate}
                                    />
                                  </Suspense>
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  sm={12}
                                  md={12}
                                  lg={3}
                                  xl={3}
                                >
                                  <Suspense fallback={<p>loading</p>}>
                                    <EmotionDist
                                      currentFilter={currentFilter}
                                      customDate={customDate}
                                      currentWorkflowID={currentWorkflowID}
                                      emotionPercenatge={emotionPercenatge}
                                    />
                                  </Suspense>
                                </Grid>
                              </Grid>
                            </div>
                          </div>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                )}
                {access.culture && (
                  <div style={{ marginBottom: "20px" }}>
                    {
                      <Accordion
                        expanded={cultureAccordion}
                        onChange={(e, expanded) => {
                          setCultureAccordion(expanded);
                        }}
                        sx={{
                          borderRadius: "1px !important",
                          background: !isNight
                            ? "#17123c !important"
                            : "#f4f5f7 !important",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon
                              sx={{
                                color: !isNight
                                  ? "#aebcc9 !important"
                                  : "#343434 !important",
                                margin: "0 0.5rem",
                              }}
                            />
                          }
                          sx={{
                            backgroundColor: isNight
                              ? "#F6F7FC !important"
                              : "#212047 !important",
                            color: !isNight
                              ? "#aebcc9 !important"
                              : "#343434 !important",
                            width: "100%",
                            textAlign: "left",
                            borderRadius: "10px !important",
                          }}
                        >
                          <Tooltip
                            title={
                              <span
                                style={{
                                  fontSize: "0.8rem",
                                  textAlign: "center",
                                }}
                              >
                                See the culture score of your organization in
                                the Culture section
                              </span>
                            }
                          >
                            <p
                              style={{
                                color: "#fff",
                                width: "80px",
                                background: "#D06283",
                                fontWeight: 600,
                                padding: "5px 0",
                                margin: "0 0.8rem",
                                textAlign: "center",
                                boxShadow:
                                  "0px 3.86842px 23.2105px rgba(0, 0, 0, 0.05)",
                                borderRadius: "7.73684px",
                              }}
                            >
                              Culture
                            </p>
                          </Tooltip>
                        </AccordionSummary>
                        <AccordionDetails
                          sx={{
                            backgroundColor: isNight
                              ? "#F6F7FC !important"
                              : "#212047 !important",
                            color: "#999 !important",
                            borderRadius: "10px",
                          }}
                        >
                          {cultureAccordion && (
                            <div>
                              <CulturalOverview
                                moraleCompareOption={moraleCompareOption}
                                setMoraleCompareOption={setMoraleCompareOption}
                                currentWorkflowID={currentWorkflowID}
                                currentFilter={currentFilter}
                                customDate={customDate}
                              />
                            </div>
                          )}
                        </AccordionDetails>
                      </Accordion>
                    }
                  </div>
                )}
              </div>
              <div
                style={{
                  // height: "50px",
                  width: "100%",
                  backgroundColor: !isNight ? "#212047" : "#f2f2f2",
                  padding: " 0  0",
                  margin: "1.5rem 0 0",
                  display: "flex",
                  justifyContent: "end",
                }}
              >
                {" "}
                <p className="termCondition" style={{ margin: "1rem" }}>
                  <a
                    style={{
                      color: !isNight ? "#aebcc9" : "#484848",
                      textDecoration: "none",
                    }}
                    href="https://scanta-web-resources.s3.amazonaws.com/scanta-terms.pdf"
                    target="_blank"
                  >
                    <span>Terms and Conditions</span>
                  </a>{" "}
                  |{" "}
                  <a
                    style={{
                      color: !isNight ? "#aebcc9" : "#484848",
                      textDecoration: "none",
                    }}
                    href="https://scanta-web-resources.s3.amazonaws.com/Scanta%2BPrivacy%2BPolicy_Revised_2022.07.06_Clean.pdf"
                    target="_blank"
                  >
                    <span>Privacy Policy</span>
                  </a>
                </p>
              </div>
            </Grid>
          </Grid>
        </LoadingOverlay>
        {/* </Box> */}
      </div>
      {!isDemo && <ChatBot />}
      <Modal
        open={annotaionModalOpen}
        onClose={() => {
          setAnnotationModalOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <AnnotationModal
          handleClose={() => {
            setAnnotationModalOpen(false);
          }}
        />
      </Modal>
    </div>
  );
}
