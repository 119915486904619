import { toast } from "react-toastify";
import api from "../axiosConfig";

export function* getDetailedInvestigationTable(action) {
  let resp;
  yield api
    .post(`/detailedInvestigation`, action.payload, {
      headers: {
        "Access-Control-Allow-Headers": "x-access-token",
        "x-access-token": localStorage.getItem("auth-token"),
      },
    })
    .then((response) => {
      resp = response;
      if (response.data.data.length === 0) {
        toast.error("Data not found!!!");
      }
    })
    .catch((error) => {
      toast.error("No data found!!!");
      return error;
    });
  return resp;
}
