export const demoWords = [
  {
    text: "MENTAL",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 1056,
    yoff: 165,
    x1: 0,
    y1: 1, 
    x0: 0,
    y0: 2,
    hasText: true,
    x: -77,
    y: -24,
  },
  {
    text: "HEALTH",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 1056,
    yoff: 165,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: -26,
    y: -70,
  },
  {
    text: "FREE",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1056,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 41,
    y: -16,
  },
  {
    text: "PASS",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 1088,
    yoff: 165,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: -90,
    y: 32,
  },
  {
    text: "SHITTY",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1088,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -52,
    y: -21,
  },
  {
    text: "PEOPLE",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 10,
    padding: 10,
    width: 32,
    height: 48,
    xoff: 1088,
    yoff: 0,
    x1: 16,
    y1: 23,
    x0: -16,
    y0: -24,
    hasText: true,
    x: 11,
    y: -34,
  },
  {
    text: "ALLOWED",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1120,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 104,
    y: 2,
  },
  {
    text: "finally",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1152,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 164,
    y: -5,
  },
  {
    text: "able",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 10,
    padding: 10,
    width: 64,
    height: 20,
    xoff: 1120,
    yoff: 0,
    x1: 32,
    y1: 9,
    x0: -32,
    y0: -10,
    hasText: true,
    x: 142,
    y: 10,
  },
  {
    text: "bed",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 1184,
    yoff: 165,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: 41,
    y: 32,
  },
  {
    text: "shower",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 1184,
    yoff: 165,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 29,
    y: 5,
  },
  {
    text: "depressed",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 6,
    xoff: 1184,
    yoff: 165,
    x1: 0,
    y1: 2,
    x0: 0,
    y0: 3,
    hasText: true,
    x: 147,
    y: 30,
  },
  {
    text: "leave",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 10,
    padding: 10,
    width: 64,
    height: 20,
    xoff: 1184,
    yoff: 0,
    x1: 32,
    y1: 9,
    x0: -32,
    y0: -10,
    hasText: true,
    x: -203,
    y: -32,
  },
  {
    text: "room",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 10,
    padding: 10,
    width: 32,
    height: 40,
    xoff: 1248,
    yoff: 0,
    x1: 16,
    y1: 19,
    x0: -16,
    y0: -20,
    hasText: true,
    x: 236,
    y: 40,
  },
  {
    text: "days",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 1184,
    yoff: 165,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: 152,
    y: 7,
  },
  {
    text: "hate",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 10,
    padding: 10,
    width: 32,
    height: 36,
    xoff: 1280,
    yoff: 0,
    x1: 16,
    y1: 17,
    x0: -16,
    y0: -18,
    hasText: true,
    x: -232,
    y: 12,
  },
  {
    text: "way",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1184,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -91,
    y: 19,
  },
  {
    text: "kids",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1216,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 150,
    y: -11,
  },
  {
    text: "casually",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1248,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -147,
    y: -51,
  },
  {
    text: "heartbreaking",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1280,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 85,
    y: -14,
  },
  {
    text: "things",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1312,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 37,
    y: 4,
  },
  {
    text: "Testing",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1344,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -112,
    y: 49,
  },
  {
    text: "slack",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1376,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 180,
    y: -52,
  },
  {
    text: "ap",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1408,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 43,
    y: 35,
  },
  {
    text: "integration",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 6,
    xoff: 1440,
    yoff: 165,
    x1: 0,
    y1: 2,
    x0: 0,
    y0: 3,
    hasText: true,
    x: 100,
    y: -46,
  },
  {
    text: "want",
    value: 3,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 15,
    padding: 10,
    width: 32,
    height: 56,
    xoff: 416,
    yoff: 0,
    x1: 16,
    y1: 27,
    x0: -16,
    y0: -28,
    hasText: true,
    x: 29,
    y: 18,
  },
  {
    text: "tired",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 1440,
    yoff: 165,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: -9,
    y: 60,
  },
  {
    text: "pisses",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1440,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 125,
    y: -49,
  },
  {
    text: "said",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 10,
    padding: 10,
    width: 64,
    height: 20,
    xoff: 1344,
    yoff: 0,
    x1: 32,
    y1: 9,
    x0: -32,
    y0: -10,
    hasText: true,
    x: 198,
    y: 95,
  },
  {
    text: "change",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 10,
    padding: 10,
    width: 64,
    height: 20,
    xoff: 1408,
    yoff: 0,
    x1: 32,
    y1: 9,
    x0: -32,
    y0: -10,
    hasText: true,
    x: 35,
    y: -85,
  },
  {
    text: "mind",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1472,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 9,
    y: 36,
  },
  {
    text: "annoyed",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1504,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 141,
    y: 36,
  },
  {
    text: "YouTube",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 5,
    xoff: 1536,
    yoff: 165,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 167,
    y: 64,
  },
  {
    text: "Music",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 1536,
    yoff: 165,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: 113,
    y: -65,
  },
  {
    text: "Fucking",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1536,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -26,
    y: -73,
  },
  {
    text: "Garbage",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 5,
    xoff: 1568,
    yoff: 165,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 172,
    y: -15,
  },
  {
    text: "know",
    value: 3,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 15,
    padding: 10,
    width: 64,
    height: 30,
    xoff: 448,
    yoff: 0,
    x1: 32,
    y1: 14,
    x0: -32,
    y0: -15,
    hasText: true,
    x: 159,
    y: -15,
  },
  {
    text: "Fuck",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 1568,
    yoff: 165,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: -72,
    y: 13,
  },
  {
    text: "politics",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 1568,
    yoff: 165,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 133,
    y: -15,
  },
  {
    text: "house",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 1568,
    yoff: 165,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: -44,
    y: 52,
  },
  {
    text: "love",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1568,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -113,
    y: 45,
  },
  {
    text: "Pete",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1600,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -64,
    y: 56,
  },
  {
    text: "stop",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1632,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -46,
    y: -9,
  },
  {
    text: "drinking",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 5,
    xoff: 1664,
    yoff: 165,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: -105,
    y: -76,
  },
  {
    text: "driving",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1664,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 144,
    y: 71,
  },
  {
    text: "Work",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 1696,
    yoff: 165,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: 80,
    y: -54,
  },
  {
    text: "live",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1696,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -60,
    y: -32,
  },
  {
    text: "getting",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 1728,
    yoff: 165,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 45,
    y: 46,
  },
  {
    text: "People",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1728,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 37,
    y: 24,
  },
  {
    text: "general",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1760,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 28,
    y: 55,
  },
  {
    text: "worst",
    value: 3,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 15,
    padding: 10,
    width: 64,
    height: 30,
    xoff: 512,
    yoff: 0,
    x1: 32,
    y1: 14,
    x0: -32,
    y0: -15,
    hasText: true,
    x: -48,
    y: 55,
  },
  {
    text: "fucking",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 1792,
    yoff: 165,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 56,
    y: 59,
  },
  {
    text: "people",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 10,
    padding: 10,
    width: 32,
    height: 49,
    xoff: 1472,
    yoff: 0,
    x1: 16,
    y1: 23,
    x0: -16,
    y0: -24,
    hasText: true,
    x: 86,
    y: -88,
  },
  {
    text: "saying",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 10,
    padding: 10,
    width: 32,
    height: 47,
    xoff: 1504,
    yoff: 0,
    x1: 16,
    y1: 22,
    x0: -16,
    y0: -23,
    hasText: true,
    x: 122,
    y: -94,
  },
  {
    text: "humans",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 10,
    padding: 10,
    width: 32,
    height: 56,
    xoff: 1536,
    yoff: 0,
    x1: 16,
    y1: 27,
    x0: -16,
    y0: -28,
    hasText: true,
    x: -25,
    y: 97,
  },
  {
    text: "suck",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 1792,
    yoff: 165,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: 138,
    y: -46,
  },
  {
    text: "species",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1792,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -26,
    y: -11,
  },
  {
    text: "shit",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1824,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -136,
    y: 53,
  },
  {
    text: "Adding",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1856,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 25,
    y: -66,
  },
  {
    text: "messages",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 6,
    xoff: 1888,
    yoff: 165,
    x1: 0,
    y1: 2,
    x0: 0,
    y0: 3,
    hasText: true,
    x: -118,
    y: -19,
  },
  {
    text: "fill",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 2,
    xoff: 1888,
    yoff: 165,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: 27,
    y: 25,
  },
  {
    text: "test",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1888,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 56,
    y: -15,
  },
  {
    text: "data",
    value: 4,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 20,
    padding: 10,
    width: 64,
    height: 71,
    xoff: 224,
    yoff: 0,
    x1: 32,
    y1: 34,
    x0: -32,
    y0: -34,
    hasText: true,
    x: 25,
    y: 82,
  },
  {
    text: "buckle",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 1920,
    yoff: 165,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: -61,
    y: 26,
  },
  {
    text: "thank",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 10,
    padding: 10,
    width: 64,
    height: 20,
    xoff: 1568,
    yoff: 0,
    x1: 32,
    y1: 9,
    x0: -32,
    y0: -10,
    hasText: true,
    x: 37,
    y: -104,
  },
  {
    text: "UCHHHZAQ",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 6,
    xoff: 1920,
    yoff: 165,
    x1: 0,
    y1: 2,
    x0: 0,
    y0: 3,
    hasText: true,
    x: 52,
    y: 71,
  },
  {
    text: "list",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1920,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -136,
    y: -62,
  },
  {
    text: "personal",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 5,
    xoff: 1952,
    yoff: 165,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: -111,
    y: -28,
  },
  {
    text: "informations",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 7,
    xoff: 1952,
    yoff: 165,
    x1: 0,
    y1: 2,
    x0: 0,
    y0: 3,
    hasText: true,
    x: 2,
    y: -37,
  },
  {
    text: "detect",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1952,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -68,
    y: -15,
  },
  {
    text: "right",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 1984,
    yoff: 165,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: -47,
    y: -73,
  },
  {
    text: "think",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 1984,
    yoff: 165,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: 169,
    y: -7,
  },
  {
    text: "tagline",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1984,
    yoff: 165,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 177,
    y: -46,
  },
  {
    text: "long",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 2016,
    yoff: 165,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: -149,
    y: 43,
  },
  {
    text: "explains",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 0,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 148,
    y: -45,
  },
  {
    text: "product",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 5,
    xoff: 32,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 163,
    y: 9,
  },
  {
    text: "improvement",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 32,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -68,
    y: 0,
  },
  {
    text: "team",
    value: 4,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 20,
    padding: 10,
    width: 64,
    height: 78,
    xoff: 288,
    yoff: 0,
    x1: 32,
    y1: 38,
    x0: -32,
    y0: -38,
    hasText: true,
    x: 82,
    y: 21,
  },
  {
    text: "stepping",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 64,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 139,
    y: 56,
  },
  {
    text: "delivering",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 96,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -84,
    y: 74,
  },
  {
    text: "goals",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 128,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 103,
    y: 33,
  },
  {
    text: "today",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 10,
    padding: 10,
    width: 64,
    height: 20,
    xoff: 1632,
    yoff: 0,
    x1: 32,
    y1: 9,
    x0: -32,
    y0: -10,
    hasText: true,
    x: 256,
    y: -76,
  },
  {
    text: "demo",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 10,
    padding: 10,
    width: 64,
    height: 20,
    xoff: 1696,
    yoff: 0,
    x1: 32,
    y1: 9,
    x0: -32,
    y0: -10,
    hasText: true,
    x: -92,
    y: -98,
  },
  {
    text: "received",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 160,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 110,
    y: -21,
  },
  {
    text: "breath",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 192,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -129,
    y: 4,
  },
  {
    text: "ready",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 224,
    yoff: 234,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: 130,
    y: -18,
  },
  {
    text: "wild",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 224,
    yoff: 234,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: 140,
    y: -70,
  },
  {
    text: "ride",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 224,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 179,
    y: 71,
  },
  {
    text: "logo",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 10,
    padding: 10,
    width: 64,
    height: 20,
    xoff: 1760,
    yoff: 0,
    x1: 32,
    y1: 9,
    x0: -32,
    y0: -10,
    hasText: true,
    x: 55,
    y: -22,
  },
  {
    text: "sooo",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 256,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -106,
    y: 13,
  },
  {
    text: "dumb",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 288,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 169,
    y: -35,
  },
  {
    text: "like",
    value: 4,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 20,
    padding: 10,
    width: 64,
    height: 55,
    xoff: 352,
    yoff: 0,
    x1: 32,
    y1: 26,
    x0: -32,
    y0: -26,
    hasText: true,
    x: 128,
    y: 50,
  },
  {
    text: "didn",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 320,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -36,
    y: 16,
  },
  {
    text: "effort",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 352,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 53,
    y: -39,
  },
  {
    text: "picked",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 384,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 9,
    y: -32,
  },
  {
    text: "Canva",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 384,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -159,
    y: -52,
  },
  {
    text: "stuck_out_tongue_closed_eyes",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 416,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 144,
    y: -24,
  },
  {
    text: "look",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 10,
    padding: 10,
    width: 64,
    height: 20,
    xoff: 1824,
    yoff: 0,
    x1: 32,
    y1: 9,
    x0: -32,
    y0: -10,
    hasText: true,
    x: 160,
    y: -90,
  },
  {
    text: "horrible",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 448,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 128,
    y: 67,
  },
  {
    text: "Welcome",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 10,
    padding: 10,
    width: 64,
    height: 20,
    xoff: 1888,
    yoff: 0,
    x1: 32,
    y1: 9,
    x0: -32,
    y0: -10,
    hasText: true,
    x: -227,
    y: -54,
  },
  {
    text: "wave",
    value: 3,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 15,
    padding: 10,
    width: 32,
    height: 59,
    xoff: 576,
    yoff: 0,
    x1: 16,
    y1: 28,
    x0: -16,
    y0: -29,
    hasText: true,
    x: 181,
    y: 54,
  },
  {
    text: "Hello",
    value: 3,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 15,
    padding: 10,
    width: 64,
    height: 30,
    xoff: 608,
    yoff: 0,
    x1: 32,
    y1: 14,
    x0: -32,
    y0: -15,
    hasText: true,
    x: -87,
    y: -72,
  },
  {
    text: "Anjani",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 448,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: -130,
    y: 64,
  },
  {
    text: "Praveen",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 10,
    padding: 10,
    width: 32,
    height: 56,
    xoff: 1952,
    yoff: 0,
    x1: 16,
    y1: 27,
    x0: -16,
    y0: -28,
    hasText: true,
    x: 237,
    y: 88,
  },
  {
    text: "showed",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 448,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 32,
    y: 3,
  },
  {
    text: "great",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 10,
    padding: 10,
    width: 64,
    height: 20,
    xoff: 0,
    yoff: 165,
    x1: 32,
    y1: 9,
    x0: -32,
    y0: -10,
    hasText: true,
    x: 259,
    y: -28,
  },
  {
    text: "dashboard",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 448,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 19,
    y: 57,
  },
  {
    text: "progress",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 5,
    xoff: 480,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 151,
    y: -38,
  },
  {
    text: "far",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 480,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -26,
    y: -30,
  },
  {
    text: "Thank",
    value: 3,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 15,
    padding: 10,
    width: 64,
    height: 30,
    xoff: 672,
    yoff: 0,
    x1: 32,
    y1: 14,
    x0: -32,
    y0: -15,
    hasText: true,
    x: 87,
    y: -48,
  },
  {
    text: "hard",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 10,
    padding: 10,
    width: 64,
    height: 20,
    xoff: 64,
    yoff: 165,
    x1: 32,
    y1: 9,
    x0: -32,
    y0: -10,
    hasText: true,
    x: 283,
    y: 36,
  },
  {
    text: "work",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 512,
    yoff: 234,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: 65,
    y: 74,
  },
  {
    text: "day",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 512,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -131,
    y: -47,
  },
  {
    text: "worse",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 544,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: -2,
    y: 12,
  },
  {
    text: "socialism",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 5,
    xoff: 544,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: -85,
    y: -28,
  },
  {
    text: "don",
    value: 3,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 15,
    padding: 10,
    width: 64,
    height: 30,
    xoff: 736,
    yoff: 0,
    x1: 32,
    y1: 14,
    x0: -32,
    y0: -15,
    hasText: true,
    x: 159,
    y: -55,
  },
  {
    text: "Venezuela",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 544,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -49,
    y: -75,
  },
  {
    text: "wrote",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 576,
    yoff: 234,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: 22,
    y: 0,
  },
  {
    text: "play",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 576,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -60,
    y: 41,
  },
  {
    text: "drama",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 608,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 65,
    y: 64,
  },
  {
    text: "teaching",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 608,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 19,
    y: 44,
  },
  {
    text: "trying",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 640,
    yoff: 234,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: -87,
    y: -34,
  },
  {
    text: "completely",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 640,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -149,
    y: 11,
  },
  {
    text: "Sure",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 672,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 154,
    y: 38,
  },
  {
    text: "ll",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 704,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -138,
    y: 10,
  },
  {
    text: "paste",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 736,
    yoff: 234,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: 140,
    y: -48,
  },
  {
    text: "output",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 736,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 67,
    y: -22,
  },
  {
    text: "input",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 736,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 42,
    y: 57,
  },
  {
    text: "example",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 10,
    padding: 10,
    width: 64,
    height: 20,
    xoff: 128,
    yoff: 165,
    x1: 32,
    y1: 9,
    x0: -32,
    y0: -10,
    hasText: true,
    x: 257,
    y: 6,
  },
  {
    text: "Joy",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 2,
    xoff: 768,
    yoff: 234,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: -59,
    y: -26,
  },
  {
    text: "thought",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 10,
    padding: 10,
    width: 64,
    height: 20,
    xoff: 192,
    yoff: 165,
    x1: 32,
    y1: 9,
    x0: -32,
    y0: -10,
    hasText: true,
    x: 290,
    y: 56,
  },
  {
    text: "lovely",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 768,
    yoff: 234,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: -41,
    y: -12,
  },
  {
    text: "thing",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 768,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 124,
    y: 12,
  },
  {
    text: "Specially",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 5,
    xoff: 800,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 110,
    y: 14,
  },
  {
    text: "UBMUJQJ",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 5,
    xoff: 800,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 2,
    y: 52,
  },
  {
    text: "UBMDMC",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 10,
    padding: 10,
    width: 64,
    height: 20,
    xoff: 256,
    yoff: 165,
    x1: 32,
    y1: 9,
    x0: -32,
    y0: -10,
    hasText: true,
    x: 259,
    y: -49,
  },
  {
    text: "consistent",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 6,
    xoff: 800,
    yoff: 234,
    x1: 0,
    y1: 2,
    x0: 0,
    y0: 3,
    hasText: true,
    x: -127,
    y: -44,
  },
  {
    text: "support",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 800,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: -5,
    y: 19,
  },
  {
    text: "motivating",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 800,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 56,
    y: 43,
  },
  {
    text: "credits",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 832,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 136,
    y: -31,
  },
  {
    text: "development",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 832,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -16,
    y: 22,
  },
  {
    text: "goes",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 864,
    yoff: 234,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: 162,
    y: -30,
  },
  {
    text: "happy",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 864,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: -154,
    y: -46,
  },
  {
    text: "configuration",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 7,
    xoff: 864,
    yoff: 234,
    x1: 0,
    y1: 2,
    x0: 0,
    y0: 3,
    hasText: true,
    x: -160,
    y: 55,
  },
  {
    text: "visual",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 864,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 138,
    y: -72,
  },
  {
    text: "indicators",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 6,
    xoff: 896,
    yoff: 234,
    x1: 0,
    y1: 2,
    x0: 0,
    y0: 3,
    hasText: true,
    x: 85,
    y: -52,
  },
  {
    text: "terminal",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 10,
    padding: 10,
    width: 32,
    height: 57,
    xoff: 320,
    yoff: 165,
    x1: 16,
    y1: 27,
    x0: -16,
    y0: -28,
    hasText: true,
    x: -168,
    y: 66,
  },
  {
    text: "git",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 2,
    xoff: 896,
    yoff: 234,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: -12,
    y: 18,
  },
  {
    text: "status",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 896,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 178,
    y: -13,
  },
  {
    text: "overall",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 928,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 181,
    y: 54,
  },
  {
    text: "feel",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 2,
    xoff: 928,
    yoff: 234,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: 105,
    y: 24,
  },
  {
    text: "resonate",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 5,
    xoff: 928,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 20,
    y: 41,
  },
  {
    text: "Edit",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 928,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 63,
    y: 56,
  },
  {
    text: "Hey",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 960,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -150,
    y: -45,
  },
  {
    text: "fuckers",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 992,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 141,
    y: 21,
  },
  {
    text: "listen",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 992,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -59,
    y: -68,
  },
  {
    text: "podcast",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1024,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -91,
    y: 57,
  },
  {
    text: "target",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 1056,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 83,
    y: -19,
  },
  {
    text: "demographic",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 7,
    xoff: 1056,
    yoff: 234,
    x1: 0,
    y1: 2,
    x0: 0,
    y0: 3,
    hasText: true,
    x: 165,
    y: 58,
  },
  {
    text: "bent",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1056,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 55,
    y: -12,
  },
  {
    text: "Channel",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 5,
    xoff: 1088,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 108,
    y: 7,
  },
  {
    text: "successfully",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1088,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -156,
    y: -50,
  },
  {
    text: "captured",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 5,
    xoff: 1120,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: -29,
    y: -53,
  },
  {
    text: "relationship",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1120,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 158,
    y: -20,
  },
  {
    text: "problems",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1152,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 111,
    y: 72,
  },
  {
    text: "obvious",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 1184,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 65,
    y: 13,
  },
  {
    text: "solutions",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1184,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -53,
    y: 7,
  },
  {
    text: "dislike",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1216,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 122,
    y: 49,
  },
  {
    text: "food",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1248,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 142,
    y: 5,
  },
  {
    text: "online",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 1280,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: -18,
    y: 41,
  },
  {
    text: "spent",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1280,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 23,
    y: 26,
  },
  {
    text: "half",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 2,
    xoff: 1312,
    yoff: 234,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: -85,
    y: -25,
  },
  {
    text: "time",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1312,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 100,
    y: 50,
  },
  {
    text: "worrying",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 10,
    padding: 10,
    width: 64,
    height: 20,
    xoff: 352,
    yoff: 165,
    x1: 32,
    y1: 9,
    x0: -32,
    y0: -10,
    hasText: true,
    x: 170,
    y: -111,
  },
  {
    text: "instead",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1344,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 42,
    y: 56,
  },
  {
    text: "everybody",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 6,
    xoff: 1376,
    yoff: 234,
    x1: 0,
    y1: 2,
    x0: 0,
    y0: 3,
    hasText: true,
    x: 20,
    y: -52,
  },
  {
    text: "world",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 1376,
    yoff: 234,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: -110,
    y: 58,
  },
  {
    text: "better",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1376,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -132,
    y: 51,
  },
  {
    text: "place",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1408,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -84,
    y: -33,
  },
  {
    text: "workers",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 1440,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 45,
    y: -26,
  },
  {
    text: "smoothie",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 5,
    xoff: 1440,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 65,
    y: -39,
  },
  {
    text: "video",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1440,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 24,
    y: -14,
  },
  {
    text: "excuse",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1472,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 137,
    y: 56,
  },
  {
    text: "added",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1504,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 105,
    y: -36,
  },
  {
    text: "peanut",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 1536,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: -100,
    y: 61,
  },
  {
    text: "butter",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1536,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -160,
    y: 66,
  },
  {
    text: "confuses",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1568,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 46,
    y: 74,
  },
  {
    text: "annoys",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 1600,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 28,
    y: 9,
  },
  {
    text: "girls",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 1600,
    yoff: 234,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: 47,
    y: 15,
  },
  {
    text: "leading",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 1600,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 116,
    y: -2,
  },
  {
    text: "read",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 1600,
    yoff: 234,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: 68,
    y: 68,
  },
  {
    text: "social",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 1600,
    yoff: 234,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: 76,
    y: 24,
  },
  {
    text: "cues",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1600,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -67,
    y: -52,
  },
  {
    text: "https",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1632,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 132,
    y: -56,
  },
  {
    text: "www",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1664,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 59,
    y: 70,
  },
  {
    text: "reddit",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1696,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -63,
    y: -50,
  },
  {
    text: "com",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 1728,
    yoff: 234,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: -38,
    y: -73,
  },
  {
    text: "rant",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1728,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 41,
    y: 53,
  },
  {
    text: "comments",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1760,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 29,
    y: 28,
  },
  {
    text: "svb",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 2,
    xoff: 1792,
    yoff: 234,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: -98,
    y: -74,
  },
  {
    text: "is_this_a_stroke_is_this_what_having_a_stroke",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 26,
    xoff: 1792,
    yoff: 234,
    x1: 0,
    y1: 12,
    x0: 0,
    y0: 13,
    hasText: true,
    x: -165,
    y: 60,
  },
  {
    text: "stroke",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 10,
    padding: 10,
    width: 32,
    height: 44,
    xoff: 416,
    yoff: 165,
    x1: 16,
    y1: 21,
    x0: -16,
    y0: -22,
    hasText: true,
    x: -201,
    y: 60,
  },
  {
    text: "having",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1792,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 140,
    y: 28,
  },
  {
    text: "feels",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1824,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -61,
    y: 53,
  },
  {
    text: "Card",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1856,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 160,
    y: -47,
  },
  {
    text: "Details",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 10,
    padding: 10,
    width: 64,
    height: 20,
    xoff: 448,
    yoff: 165,
    x1: 32,
    y1: 9,
    x0: -32,
    y0: -10,
    hasText: true,
    x: -217,
    y: 96,
  },
  {
    text: "credit",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1888,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -132,
    y: 36,
  },
  {
    text: "card",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1920,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 72,
    y: 20,
  },
  {
    text: "number",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 5,
    xoff: 1952,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 118,
    y: -61,
  },
  {
    text: "cvv",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 10,
    padding: 10,
    width: 32,
    height: 20,
    xoff: 512,
    yoff: 165,
    x1: 16,
    y1: 9,
    x0: -16,
    y0: -10,
    hasText: true,
    x: -84,
    y: -50,
  },
  {
    text: "expiry",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 10,
    padding: 10,
    width: 64,
    height: 20,
    xoff: 544,
    yoff: 165,
    x1: 32,
    y1: 9,
    x0: -32,
    y0: -10,
    hasText: true,
    x: -223,
    y: -76,
  },
  {
    text: "date",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 10,
    padding: 10,
    width: 32,
    height: 37,
    xoff: 608,
    yoff: 165,
    x1: 16,
    y1: 17,
    x0: -16,
    y0: -18,
    hasText: true,
    x: 299,
    y: -101,
  },
  {
    text: "_Card",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 1952,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 6,
    y: -27,
  },
  {
    text: "details",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1952,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -148,
    y: 64,
  },
  {
    text: "present",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 1984,
    yoff: 234,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 30,
    y: 10,
  },
  {
    text: "Detected",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 10,
    padding: 10,
    width: 32,
    height: 61,
    xoff: 640,
    yoff: 165,
    x1: 16,
    y1: 29,
    x0: -16,
    y0: -30,
    hasText: true,
    x: -211,
    y: -116,
  },
  {
    text: "otp",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 2,
    xoff: 1984,
    yoff: 234,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: 69,
    y: -40,
  },
  {
    text: "Date",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 1984,
    yoff: 234,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -75,
    y: 35,
  },
  {
    text: "Birth",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 2016,
    yoff: 234,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: 16,
    y: -52,
  },
  {
    text: "DoB",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 2,
    xoff: 2016,
    yoff: 234,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: 175,
    y: 6,
  },

  {
    text: "valid",
    value: 7,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 28,
    padding: 10,
    width: 64,
    height: 99,
    xoff: 96,
    yoff: 0,
    x1: 32,
    y1: 48,
    x0: -32,
    y0: -49,
    hasText: true,
    x: -38,
    y: -17,
  },
  {
    text: "DoB_",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 2016,
    yoff: 234,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: -165,
    y: -10,
  },
  {
    text: "Driving",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 0,
    yoff: 260,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 29,
    y: 2,
  },
  {
    text: "License",
    value: 3,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 15,
    padding: 10,
    width: 96,
    height: 30,
    xoff: 800,
    yoff: 0,
    x1: 48,
    y1: 14,
    x0: -48,
    y0: -15,
    hasText: true,
    x: 124,
    y: 94,
  },
  {
    text: "HR",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 2,
    xoff: 32,
    yoff: 260,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: -32,
    y: 58,
  },
  {
    text: "Email",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 32,
    yoff: 260,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: -18,
    y: -26,
  },
  {
    text: "id",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 10,
    padding: 10,
    width: 32,
    height: 20,
    xoff: 672,
    yoff: 165,
    x1: 16,
    y1: 9,
    x0: -16,
    y0: -10,
    hasText: true,
    x: -194,
    y: 26,
  },
  {
    text: "email",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 3,
    xoff: 32,
    yoff: 260,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: 19,
    y: 68,
  },
  {
    text: "cb",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 2,
    xoff: 32,
    yoff: 260,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: 65,
    y: 10,
  },
  {
    text: "email_",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 32,
    yoff: 260,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: -47,
    y: -30,
  },
  {
    text: "IP",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 10,
    padding: 10,
    width: 32,
    height: 20,
    xoff: 704,
    yoff: 165,
    x1: 16,
    y1: 9,
    x0: -16,
    y0: -10,
    hasText: true,
    x: -46,
    y: -108,
  },
  {
    text: "address",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 10,
    padding: 10,
    width: 32,
    height: 56,
    xoff: 736,
    yoff: 165,
    x1: 16,
    y1: 27,
    x0: -16,
    y0: -28,
    hasText: true,
    x: 290,
    y: 95,
  },
  {
    text: "IP_",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 32,
    yoff: 260,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 29,
    y: -43,
  },
  {
    text: "Login",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 64,
    yoff: 260,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 3,
    y: 41,
  },
  {
    text: "Username",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 96,
    yoff: 260,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 140,
    y: 30,
  },
  {
    text: "Prakriti_",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 10,
    padding: 10,
    width: 64,
    height: 20,
    xoff: 768,
    yoff: 165,
    x1: 32,
    y1: 9,
    x0: -32,
    y0: -10,
    hasText: true,
    x: 36,
    y: -126,
  },
  {
    text: "_Username",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 6,
    xoff: 128,
    yoff: 260,
    x1: 0,
    y1: 2,
    x0: 0,
    y0: 3,
    hasText: true,
    x: -84,
    y: 76,
  },
  {
    text: "Detected_",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 10,
    padding: 10,
    width: 32,
    height: 69,
    xoff: 832,
    yoff: 165,
    x1: 16,
    y1: 33,
    x0: -16,
    y0: -34,
    hasText: true,
    x: 303,
    y: -49,
  },
  {
    text: "Password",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 128,
    yoff: 260,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -47,
    y: 52,
  },
  {
    text: "_Password",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 6,
    xoff: 160,
    yoff: 260,
    x1: 0,
    y1: 2,
    x0: 0,
    y0: 3,
    hasText: true,
    x: 69,
    y: -13,
  },
  {
    text: "OTP",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 160,
    yoff: 260,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -19,
    y: 32,
  },
  {
    text: "_OTP",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 192,
    yoff: 260,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -99,
    y: 48,
  },
  {
    text: "detected_",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 6,
    xoff: 224,
    yoff: 260,
    x1: 0,
    y1: 2,
    x0: 0,
    y0: 3,
    hasText: true,
    x: 135,
    y: -5,
  },
  {
    text: "MAC",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 10,
    padding: 10,
    width: 64,
    height: 20,
    xoff: 864,
    yoff: 165,
    x1: 32,
    y1: 9,
    x0: -32,
    y0: -10,
    hasText: true,
    x: -81,
    y: 107,
  },
  {
    text: "Address",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 5,
    xoff: 224,
    yoff: 260,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 49,
    y: -31,
  },
  {
    text: "MAC_",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 224,
    yoff: 260,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -80,
    y: 46,
  },
  {
    text: "Passport",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 256,
    yoff: 260,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -142,
    y: 27,
  },
  {
    text: "Number",
    value: 5,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 23,
    padding: 10,
    width: 64,
    height: 121,
    xoff: 160,
    yoff: 0,
    x1: 32,
    y1: 59,
    x0: -32,
    y0: -57,
    hasText: true,
    x: -155,
    y: -84,
  },
  {
    text: "PassPort",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 288,
    yoff: 260,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 121,
    y: 32,
  },
  {
    text: "passport_",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 320,
    yoff: 260,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -16,
    y: 13,
  },
  {
    text: "Phone",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 352,
    yoff: 260,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: -45,
    y: 21,
  },
  {
    text: "PhoneNo",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 5,
    xoff: 352,
    yoff: 260,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: -22,
    y: 10,
  },
  {
    text: "tel",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 2,
    xoff: 352,
    yoff: 260,
    x1: 0,
    y1: 0,
    x0: 0,
    y0: 1,
    hasText: true,
    x: 92,
    y: -15,
  },
  {
    text: "PhoneNo_",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 352,
    yoff: 260,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 179,
    y: 75,
  },
  {
    text: "Social",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 384,
    yoff: 260,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: -119,
    y: 8,
  },
  {
    text: "Security",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 384,
    yoff: 260,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 135,
    y: -32,
  },
  {
    text: "SSN",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 10,
    padding: 10,
    width: 32,
    height: 20,
    xoff: 928,
    yoff: 165,
    x1: 16,
    y1: 9,
    x0: -16,
    y0: -10,
    hasText: true,
    x: 312,
    y: -3,
  },
  {
    text: "ssn_",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 416,
    yoff: 260,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 34,
    y: 27,
  },
  {
    text: "Vehicle",
    value: 3,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 15,
    padding: 10,
    width: 96,
    height: 30,
    xoff: 896,
    yoff: 0,
    x1: 48,
    y1: 14,
    x0: -48,
    y0: -15,
    hasText: true,
    x: -178,
    y: -8,
  },
  {
    text: "Identification",
    value: 3,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 15,
    padding: 10,
    width: 32,
    height: 121,
    xoff: 992,
    yoff: 0,
    x1: 16,
    y1: 59,
    x0: -16,
    y0: -60,
    hasText: true,
    x: 208,
    y: -39,
  },
  {
    text: "VIN",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 448,
    yoff: 260,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -115,
    y: 56,
  },
  {
    text: "SALVAAEEH",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 480,
    yoff: 260,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 2,
    y: -21,
  },
  {
    text: "Valid",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 512,
    yoff: 260,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -131,
    y: -27,
  },
  {
    text: "Number_",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 5,
    xoff: 544,
    yoff: 260,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: -156,
    y: -4,
  },
  {
    text: "Zip",
    value: 3,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 15,
    padding: 10,
    width: 64,
    height: 30,
    xoff: 1024,
    yoff: 0,
    x1: 32,
    y1: 14,
    x0: -32,
    y0: -15,
    hasText: true,
    x: -22,
    y: -82,
  },
  {
    text: "Code",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 10,
    padding: 10,
    width: 64,
    height: 20,
    xoff: 960,
    yoff: 165,
    x1: 32,
    y1: 9,
    x0: -32,
    y0: -10,
    hasText: true,
    x: -90,
    y: -120,
  },
  {
    text: "Code_",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 544,
    yoff: 260,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 160,
    y: 52,
  },
  {
    text: "UBBQUNT",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 544,
    yoff: 260,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 45,
    y: -67,
  },
  {
    text: "build",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 576,
    yoff: 260,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 44,
    y: 19,
  },
  {
    text: "channels",
    value: 2,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 10,
    padding: 10,
    width: 32,
    height: 61,
    xoff: 1024,
    yoff: 165,
    x1: 16,
    y1: 29,
    x0: -16,
    y0: -30,
    hasText: true,
    x: -272,
    y: 71,
  },
  {
    text: "produce",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 608,
    yoff: 260,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 66,
    y: 5,
  },
  {
    text: "models",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 640,
    yoff: 260,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 87,
    y: 72,
  },
  {
    text: "Depending",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 6,
    xoff: 672,
    yoff: 260,
    x1: 0,
    y1: 2,
    x0: 0,
    y0: 3,
    hasText: true,
    x: -147,
    y: -69,
  },
  {
    text: "model",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 4,
    xoff: 672,
    yoff: 260,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: -58,
    y: -1,
  },
  {
    text: "offensive",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 5,
    xoff: 672,
    yoff: 260,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 103,
    y: 74,
  },
  {
    text: "understand",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 6,
    xoff: 672,
    yoff: 260,
    x1: 0,
    y1: 2,
    x0: 0,
    y0: 3,
    hasText: true,
    x: -64,
    y: 59,
  },
  {
    text: "training",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 672,
    yoff: 260,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: -18,
    y: 49,
  },
  {
    text: "purposes",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 5,
    xoff: 704,
    yoff: 260,
    x1: 0,
    y1: 1,
    x0: 0,
    y0: 2,
    hasText: true,
    x: 88,
    y: 36,
  },
  {
    text: "uncomfortable",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 90,
    size: 0,
    padding: 10,
    width: 0,
    height: 8,
    xoff: 704,
    yoff: 260,
    x1: 0,
    y1: 3,
    x0: 0,
    y0: 4,
    hasText: true,
    x: -28,
    y: 58,
  },
  {
    text: "prefer",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 704,
    yoff: 260,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 178,
    y: -40,
  },
  {
    text: "opt",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 736,
    yoff: 260,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 72,
    y: -42,
  },
  {
    text: "let",
    value: 1,
    font: "Poppins",
    style: "normal",
    weight: "normal",
    rotate: 0,
    size: 0,
    padding: 10,
    width: 32,
    height: 0,
    xoff: 768,
    yoff: 260,
    x1: 16,
    y1: -1,
    x0: -16,
    y0: 0,
    hasText: true,
    x: 43,
    y: 59,
  },
];
