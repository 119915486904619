import React, { useState, useEffect, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import "./index.css";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Chart from "react-apexcharts";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@material-ui/core";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { useScreenshot } from "use-react-screenshot";
import * as clipboard from "clipboard-polyfill";
import { base64StringToBlob } from "blob-util";
import moment from "moment";
import {
  getLengthOfService,
  getIncome,
  getAge,
} from "../../../../utilities/dashboardUtils";
import { generateMoraleCompareData } from "../demoData";
import {
  GET_MORALE_COMPARISON,
  SET_CHAT_CONTEXT,
} from "../../../../redux/Dashboard/Dashboard.types";
import EventDrop from "../../../common/Annotation/EventDrop";
import StatsOnTrendline from "../../../common/StatsOnTrendline";
function MoraleCompare({
  moraleData,
  currentWorkflowID,
  currentFilter,
  customDate,
  moraleLineToCompare,
  moraleCompareOption,
  setMoraleCompareOption,
}) {
  const dispatch = useDispatch();
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);
  const [clipProcessing, setClipProcessing] = useState(false);

  const moraleCompareData = isDemo
    ? generateMoraleCompareData(currentFilter)
    : useSelector((data) => data.dashboard.moraleComparison);

  const goals = useSelector((data) => data.dashboard.goals);

  //copy component to clipboard
  async function clip(b64Data) {
    const item = new clipboard.ClipboardItem({
      "image/png": base64StringToBlob(b64Data.split(",")[1], "image/png"),
    });

    await clipboard.write([item]);
  }
  const moraleCompareRef = createRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const getImage = async () => {
    setClipProcessing(true);
    await takeScreenshot(moraleCompareRef.current);
    setClipProcessing(false);
    image && clip(image);
  };
  useEffect(() => {
    image && clip(image);
  }, [image]);
  const totalText = useSelector((data) => data.dashboard.totalText);
  const teamSize = useSelector((data) => data.dashboard.teamSize);
  const filters = useSelector((data) => data.dashboard?.filters);
  const [isLoading, setIsLoading] = useState(true);
  const [overallTrends, setOverallTrends] = useState([]);

  const [genderTrends, setGenderTrends] = useState({
    male: [],
    female: [],
    trans: [],
    nb: [],
  });
  const [regionTrends, setRegionTrends] = useState({
    asiaPacific: [],
    middleEastAfrica: [],
    europe: [],
    northAmerica: [],
    southAmerica: [],
  });
  const [departmentTrend, setDepartmentTrend] = useState({
    marketing: [],
    researchAndDepelopment: [],
    operations: [],
    informationTech: [],
    accountsUserData: [],
    salesUserData: [],
    HRUserData: [],
    engineeringUserData: [],
  });
  const [locationTrend, setLocationTrend] = useState({
    homeOffice: [],
    regionalOffice: [],
    headquarters: [],
  });
  const [managementLevelTrend, setManagementLevelTrend] = useState({
    executiveUserData: [],
    SVPUserData: [],
    VPUserData: [],
    directorUserData: [],
    managerUserData: [],
    individualContributorUserData: [],
  });
  const [ageTrends, setAgeTrends] = useState({
    belowTwentyFiveTrend: [],
    thirtysixToFourtynineTrend: [],
    twentyfiveToThirtyfiveTrend: [],
    moreThanFiftyTrend: [],
  });
  const [incomeTrends, setIncomeTrends] = useState({
    belowFiftyThousandTrend: [],
    fiftyThousandToOnelakhTrend: [],
    OnelakhToTwolakhFiftyThousandTrend: [],
    moreThenTwolakhFiftyThousandTrend: [],
  });

  const [maritalTrend, setMaritalTrend] = useState({
    marriedTrend: [],
    unMarriedTrend: [],
  });
  const [ethnicityTrend, setEthnicityTrend] = useState({
    africanAmericanTrend: [],
    asianTrend: [],
    hispanicTrend: [],
    whiteTrend: [],
    otherTrend: [],
  });

  const [experienceTrend, setExperienceTrend] = useState({
    belowOneTrend: [],
    oneToFiveTrend: [],
    fiveToNineTrend: [],
    moreThanTen: [],
  });

  const [performanceTrend, setPerformanceTrend] = useState({
    outstandingTrend: [],
    verySatisfactryTrend: [],
    satisfactryTrend: [],
    unSatisfactryTrend: [],
    poorTrend: [],
  });

  const publicHolidays = useSelector((data) => data.dashboard.publicHolidays);
  const calenderEvents = useSelector((data) => data.dashboard.calenderEvents);
  const publicHolidaySwitch = useSelector(
    (data) => data.dashboard.publicHolidaySwitch
  );
  const [annotations, setAnnotations] = useState([]);

  // Add Graph annotations for public holidays
  useEffect(() => {
    const temp = [];
    publicHolidaySwitch &&
      publicHolidays.forEach((holiday) => {
        temp.push({
          x: new Date(holiday.date).getTime(),
          borderColor: "#d06283",
          label: {
            borderRadius: 4,
            position: "bottom",
            style: {
              color: "#fff",
              opacity: "0.3",
              fontSize: "13px",
              background: "rgba(208, 98, 131, 0.8)",
            },
            text: holiday.name,
          },
        });
      });
    calenderEvents.forEach((item) => {
      temp.push({
        x: new Date(item.date).getTime(),
        borderColor: item.color,
        label: {
          borderRadius: 4,
          position: "bottom",
          style: {
            color: "#fff",
            opacity: "0.3",
            fontSize: "13px",
            background: item.color,
          },
          text: item.name,
        },
      });
    });
    setAnnotations(temp);
  }, [publicHolidays, calenderEvents]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);
  const colorPalette = ["#04B8AF", "#FF9499", "#89788D", "#D06283", "#666"];
  const parseSeriesData = (data) => {
    // Dynamically create series from the data
    return Object.keys(data).map((group, index) => ({
      name: group.charAt(0).toUpperCase() + group.slice(1),
      color: colorPalette[index % colorPalette.length], // Use color palette
      data: data[group]
        .sort((a, b) => (a._id > b._id ? 1 : -1))
        ?.map((item) => ({
          x: new Date(item._id), // Convert timestamp to Date object
          y: item.morale_avg,
        })),
    }));
  };

  useEffect(() => {
    const overallData = moraleData.moraleScoreAvg
      ?.filter(
        (item) => new Date(item._id) < new Date() && item.morale_avg !== null
      )
      ?.sort((a, b) => (a._id > b._id ? 1 : -1))
      .map((item) => {
        return {
          y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
          x: new Date(item._id),
        };
      });
    setOverallTrends(overallData);
  }, [
    moraleCompareOption,
    moraleData,
    currentFilter,
    filters,
    !isDemo && moraleCompareData,
  ]);

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const [smallNumber, setSmallNumber] = useState(0);
  useEffect(() => {
    setSmallNumber(getRandomInt(-10, 10));
  }, [filters]);

  var options = {
    annotations: {
      xaxis: annotations,
      yaxis: [
        {
          y: goals.morale,
          borderColor: "#00E396",
          strokeWidth: 5,
          borderWidth: 3,
          label: {
            borderColor: "#00E396",
            style: {
              color: "#484848",
              background: "#00E396",
            },
            text: `Goal - ${goals.morale}`,
          },
        },
      ],
    },
    chart: {
      type: "line",
      toolbar: {
        show: true,
        tools: {
          download: false, // <== line to add
        },
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#1F3BB3"],
    stroke: {
      show: true,
      curve: "straight",
      lineCap: "butt",
      colors: undefined,
      width: 3,
      dashArray: 0,
    },
    markers: {
      size: [2, 3],
      strokeWidth: 1,
      strokeOpacity: 0.3,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: isNight ? "light" : "dark",
        shadeIntensity: 0.3,
        opacityFrom: 0.7,
        opacityTo: 0.3,
        stops: [0, 90, 100],
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          colors: !isNight ? ["#727272"] : ["#666"],
          cssClass: "apexcharts-yaxis-label",
        },
        formatter: function (value) {
          return moment(value).format("MMM DD yyyy");
        },
      },
    },
    yaxis: {
      min: 0,
      max: 100,
      tickAmount: 7,
      seriesName: "test",
      axisTicks: {
        show: true,
        color: !isNight ? "#727272" : "#666",
      },
      labels: {
        style: {
          colors: !isNight ? ["#727272"] : ["#666"],
          cssClass: "apexcharts-yaxis-label",
        },
        formatter: function (value) {
          return value && Math.round(value);
        },
      },
      title: {
        text: "Average Morale Score",
        style: {
          color: !isNight ? "#727272" : "#666",
          fontSize: "12px",
        },
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      x: {
        show: true,
        format: "MM/DD/YYYY",
      },
      y: {
        show: true,
        formatter: function (val) {
          return val && Math.round(val);
        },
        title: {
          formatter: function (seriesName) {
            return seriesName;
          },
        },
      },
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "right",
      floating: false,
      fontSize: "14px",
      fontWeight: 400,
      offsetX: 0,
      offsetY: 10,
      labels: {
        colors: !isNight ? ["#727272"] : ["#666"],
        useSeriesColors: false,
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        radius: 2,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
  };

  const [graphData, setGraphData] = useState([]);
  useEffect(() => {
    moraleCompareData.result?.overallScore &&
      setGraphData([
        {
          name: "Overall",
          color: "#9B878A",
          data: moraleLineToCompare,
        },
        ...parseSeriesData(moraleCompareData.result?.overallScore),
      ]);
  }, [moraleCompareData]);

  return (
    <div
      ref={moraleCompareRef}
      style={{
        height: "400px",
        borderRadius: "12px",
        backgroundColor: !isNight ? "#2A2953" : "#fff",

        position: "relative",
        margin: "20px 0 0 0.3rem",
      }}
    >
      {" "}
      <div
        style={{
          width: "97.5%",
          position: "relative",
          top: "15px",
          margin: "1rem",
          display: "flex",
          justifyContent: "space-between",
          margin: "1rem",
        }}
      >
        <p
          style={{
            margin: "0",
            textAlign: "left",
            fontWeight: 500,
            color: !isNight ? "#fff" : "#484848",
          }}
        >
          Morale Comparison
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                Morale Score Comparison by Groups
              </span>
            }
          >
            <InfoIcon
              style={{
                fontSize: "1rem",
                filter: "opacity(0.7)",
                position: "relative",
                top: "3px",
              }}
            />
          </Tooltip>
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                Available Events
              </span>
            }
          >
            <EventDrop />
          </Tooltip>
        </p>
        <div
          style={{
            position: "absolute",
            top: "28px",
            left: "30px",
          }}
        >
          <StatsOnTrendline userCount={teamSize} textCount={totalText} />
        </div>
        <div>
          <select
            style={
              !isNight
                ? {
                    border: "1px solid #aebcc9",
                    backgroundColor: "#212047",
                    color: "#aebcc9",
                    height: "2rem",
                    margin: "0 1rem ",
                    padding: "0.4rem",
                  }
                : {
                    height: "2rem",
                    margin: "0 1rem ",
                    padding: "0.4rem",
                  }
            }
            name="Workflow"
            className="dropdown"
            onChange={(e) => {
              setMoraleCompareOption(e.target.value);
            }}
          >
            <option key={1} value="gender">
              Gender
            </option>

            <option key={2} value="department">
              Department
            </option>
            <option key={3} value="region">
              Region
            </option>
            <option key={4} value="location">
              Location
            </option>
            <option key={5} value="serviceLength">
              Length of Service
            </option>
            <option key={7} value="managementLevel">
              Management Level
            </option>
            <option key={8} value="ethinicty">
              Ethnicity
            </option>
            <option key={9} value="maritalStatus">
              Marital Status
            </option>
            <option key={10} value="income">
              Salary Level
            </option>
            <option key={11} value="age">
              Age
            </option>
            <option key={12} value="performance">
              Performance
            </option>
          </select>
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                Copy to clipboard
              </span>
            }
          >
            {clipProcessing ? (
              <CircularProgress size={16} color="inherit" />
            ) : (
              <ContentCopyOutlinedIcon
                onClick={getImage}
                style={{
                  fontSize: "1.2rem",
                  cursor: clipProcessing ? "wait" : "pointer",
                }}
              />
            )}
          </Tooltip>
        </div>
      </div>
      {moraleCompareData.status === "success" || (isDemo && !isLoading) ? (
        <div style={{ position: "relative", top: "30px" }}>
          <div className="morale-compare-container">
            {/* {<CanvasJSChart options={options} id="morale-compare-container" />} */}
            <Chart
              options={options}
              series={graphData}
              type="line"
              width="100%"
              height="330px"
            />
          </div>
        </div>
      ) : moraleCompareData.status === "fail" || currentWorkflowID === "" ? (
        <div style={{ position: "relative", top: "100px" }}>
          <ErrorOutlineIcon style={{ color: "#94989f" }} />
          <p style={{ margin: "0", color: "#94989f" }}>
            {currentWorkflowID === "" ? "Select a workflow!" : "No Data found!"}
          </p>
        </div>
      ) : (
        <>
          <div style={{ margin: "2rem" }}>
            <Stack spacing={1}>
              <Skeleton
                variant="text"
                width={"100%"}
                height={"40px"}
                style={{ position: "relative", top: "10px" }}
              />
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={"200px"}
                style={{ position: "relative", top: "10px" }}
              />
            </Stack>
          </div>
        </>
      )}
    </div>
  );
}

export default MoraleCompare;
