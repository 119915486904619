import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RESET_PASSWORD } from "../redux/Dashboard/Dashboard.types";
import "../styles/global.css";
import Navbar from "../Components/Navbar";

const SetPasswordPage = () => {
  const { userId, tokenId } = useParams();

  const history = useHistory();

  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConfirmPass] = useState("");

  const dispatch = useDispatch();
  const reset_pass_sucess = useSelector(
    (data) => data.dashboard.resetPassSuccess
  );

  const handlePassChange = (e) => {
    e.preventDefault();
    if (newPass === "") {
      toast.error("Please Fill New Password");
    } else if (confirmPass === "") {
      toast.error("Please Confirm Password");
    } else if (newPass !== confirmPass) {
      toast.error("Passwords do not match");
    } else {
      try {
        dispatch({
          type: RESET_PASSWORD,
          payload: {
            password: newPass,
            userId: userId,
            tokenId: tokenId,
          },
        });
      } catch (error) {
        toast.success(error);
      }
    }
  };

  useEffect(() => {
    if (reset_pass_sucess) history.push("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset_pass_sucess]);

  return (
    <div className="loginPage">
      <div style={{ width: "100%" }}>
        <Navbar isLoggedIn={false} />
      </div>
      <div className="formLoginContainer">
        <div className="formLogo">
          <img src="/Logo-3x.png" alt="logo" width="150px" />
        </div>
        <form className="loginForm">
          <div className="inputContainer">
            <label className="formLabels">New Password</label>
            <input
              placeholder="Enter your password"
              name="uname"
              type="password"
              onChange={(event) => setNewPass(event.target.value)}
              required
            />
          </div>
          <div className="inputContainer">
            <label className="formLabels">Confirm New Password</label>
            <input
              placeholder="Confirm your password"
              name="uname"
              type="password"
              onChange={(event) => setConfirmPass(event.target.value)}
              required
            />
          </div>
          <div
            role="presentation"
            className="submitButton"
            style={{ margin: "1rem 0" }}
            onClick={(event) => handlePassChange(event)}
          >
            <div className="buttonText">Confirm</div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SetPasswordPage;
