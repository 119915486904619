import React, { useState, useEffect, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { GET_TOTAL_CONVO_DETAILS } from "../../../../redux/Dashboard/Dashboard.types";
import "./index.css";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Chart from "react-apexcharts";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@material-ui/core";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { useScreenshot } from "use-react-screenshot";
import * as clipboard from "clipboard-polyfill";
import { base64StringToBlob } from "blob-util";
import moment from "moment";
import EventDrop from "../../../common/Annotation/EventDrop";
import StatsOnTrendline from "../../../common/StatsOnTrendline";

function SentimentTrend({
  totalConvoData,
  currentWorkflowID,
  currentFilter,
  customDate,
}) {
  const dispatch = useDispatch();

  const [clipProcessing, setClipProcessing] = useState(false);

  async function clip(b64Data) {
    const item = new clipboard.ClipboardItem({
      "image/png": base64StringToBlob(b64Data.split(",")[1], "image/png"),
    });

    await clipboard.write([item]);
  }

  const sentTrendRef = createRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const getImage = async () => {
    setClipProcessing(true);
    await takeScreenshot(sentTrendRef.current);
    setClipProcessing(false);
    image && clip(image);
  };

  useEffect(() => {
    image && clip(image);
  }, [image]);
  const totalText = useSelector((data) => data.dashboard.totalText);
  const teamSize = useSelector((data) => data.dashboard.teamSize);
  const filters = useSelector((data) => data.dashboard.filters);
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  const [isLoading, setIsLoading] = useState(true);

  const [totalData, setTotalData] = useState([]);
  const [positiveData, setPositiveData] = useState([]);
  const [negativeData, setNegativeData] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    var totalData;
    var positiveData;
    var negativeData;
    var tempTotal;
    var tempPositive;
    var tempNegative;

    if (currentFilter === "1D") {
      tempTotal = totalConvoData.totalData?.filter(
        (item) =>
          new Date(item._id) >
            new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000) &&
          new Date(item._id) < new Date()
      );
      tempPositive = totalConvoData.positiveData?.filter(
        (item) =>
          new Date(item._id) >
            new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000) &&
          new Date(item._id) < new Date()
      );
      tempNegative = totalConvoData.negativeData?.filter(
        (item) =>
          new Date(item._id) >
            new Date(new Date().getTime() - 1 * 24 * 60 * 60 * 1000) &&
          new Date(item._id) < new Date()
      );
    } else if (currentFilter === "5D") {
      tempTotal = totalConvoData.totalData?.filter(
        (item) =>
          new Date(item._id) >
            new Date(new Date().getTime() - 5 * 24 * 60 * 60 * 1000) &&
          new Date(item._id) < new Date()
      );
      tempPositive = totalConvoData.positiveData?.filter(
        (item) =>
          new Date(item._id) >
            new Date(new Date().getTime() - 5 * 24 * 60 * 60 * 1000) &&
          new Date(item._id) < new Date()
      );
      tempNegative = totalConvoData.negativeData?.filter(
        (item) =>
          new Date(item._id) >
            new Date(new Date().getTime() - 5 * 24 * 60 * 60 * 1000) &&
          new Date(item._id) < new Date()
      );
    } else if (currentFilter === "1M") {
      tempTotal = totalConvoData.totalData?.filter(
        (item) =>
          new Date(item._id) >
            new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000) &&
          new Date(item._id) < new Date()
      );
      tempPositive = totalConvoData.positiveData?.filter(
        (item) =>
          new Date(item._id) >
            new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000) &&
          new Date(item._id) < new Date()
      );
      tempNegative = totalConvoData.negativeData?.filter(
        (item) =>
          new Date(item._id) >
            new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000) &&
          new Date(item._id) < new Date()
      );
    } else if (currentFilter === "3M") {
      tempTotal = totalConvoData.totalData?.filter(
        (item) =>
          new Date(item._id) >
            new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000) &&
          new Date(item._id) < new Date()
      );
      tempPositive = totalConvoData.positiveData?.filter(
        (item) =>
          new Date(item._id) >
            new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000) &&
          new Date(item._id) < new Date()
      );
      tempNegative = totalConvoData.negativeData?.filter(
        (item) =>
          new Date(item._id) >
            new Date(new Date().getTime() - 90 * 24 * 60 * 60 * 1000) &&
          new Date(item._id) < new Date()
      );
    } else if (currentFilter === "1Y") {
      tempTotal = totalConvoData.totalData?.filter(
        (item) =>
          new Date(item._id) >
            new Date(new Date().getTime() - 365 * 24 * 60 * 60 * 1000) &&
          new Date(item._id) < new Date()
      );
      tempPositive = totalConvoData.positiveData?.filter(
        (item) =>
          new Date(item._id) >
            new Date(new Date().getTime() - 365 * 24 * 60 * 60 * 1000) &&
          new Date(item._id) < new Date()
      );
      tempNegative = totalConvoData.negativeData?.filter(
        (item) =>
          new Date(item._id) >
            new Date(new Date().getTime() - 365 * 24 * 60 * 60 * 1000) &&
          new Date(item._id) < new Date()
      );
    } else if (currentFilter === "custom") {
      tempTotal = totalConvoData.totalData?.filter(
        (item) =>
          new Date(item._id) > new Date(customDate.start) &&
          new Date(item._id) < new Date(customDate.end)
      );
      tempPositive = totalConvoData.positiveData?.filter(
        (item) =>
          new Date(item._id) > new Date(customDate.start) &&
          new Date(item._id) < new Date(customDate.end)
      );
      tempNegative = totalConvoData.negativeData?.filter(
        (item) =>
          new Date(item._id) > new Date(customDate.start) &&
          new Date(item._id) < new Date(customDate.end)
      );
    } else if (currentFilter === "max") {
      tempTotal = totalConvoData.totalData;
      tempPositive = totalConvoData.positiveData;
      tempNegative = totalConvoData.negativeData;
    }

    totalData = tempTotal
      ?.filter((item) => new Date(item._id) < new Date())
      ?.sort((a, b) => (a._id > b._id ? 1 : -1))
      .map((item, idx) => {
        return {
          y: isDemo ? item.count + demoTotal : item.count,
          x: new Date(item._id),
          color: "#43B5F4",
        };
      });

    positiveData = tempPositive
      ?.filter((item) => new Date(item._id) < new Date())
      ?.sort((a, b) => (a._id > b._id ? 1 : -1))
      .map((item, idx) => {
        return {
          y: isDemo ? item.count + demoTotal : item.count,
          x: new Date(item._id),
          color: "#91cd76",
        };
      });

    negativeData = tempNegative
      ?.filter((item) => new Date(item._id) < new Date())
      ?.sort((a, b) => (a._id > b._id ? 1 : -1))
      .map((item, idx) => {
        return {
          y: -(isDemo ? item.count : item.count),
          x: new Date(item._id),
          color: "#ef6667",
        };
      });

    setTotalData(totalData);
    setPositiveData(positiveData);
    setNegativeData(negativeData);
  }, [currentFilter, customDate, totalConvoData, filters]);

  const [demoTotal, setDemoTotal] = useState(0);

  useEffect(() => {
    setDemoTotal(getRandomInt(0, 5));
  }, [filters]);

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  var options = {
    chart: {
      stacked: true,

      toolbar: {
        show: true,
        tools: {
          download: false, // <== line to add
        },
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 12,
        borderRadiusWhenStacked: "all",
        borderRadiusApplication: "end",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      curve: "smooth",
      lineCap: "round",
      colors: undefined,
      width: 0,
      dashArray: 0,
    },

    xaxis: {
      type: "datetime",
      labels: {
        style: {
          colors: !isNight ? ["#727272"] : ["#666"],
          cssClass: "apexcharts-yaxis-label",
        },
        formatter: function (value) {
          return moment(value).format("MMM DD yyyy");
        },
      },
    },
    yaxis: {
      // min: 0,
      tickAmount: 7,
      seriesName: "test",
      axisTicks: {
        show: true,
        color: !isNight ? "#727272" : "#666",
      },
      labels: {
        style: {
          colors: !isNight ? ["#727272"] : ["#666"],
          cssClass: "apexcharts-yaxis-label",
        },
        formatter: function (value) {
          return value && Math.abs(Math.round(value));
        },
      },
      title: {
        text: "Sentiment Count",
        style: {
          color: !isNight ? "#727272" : "#666",
          fontSize: "12px",
        },
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      x: {
        show: true,
        format: "MM/DD/YYYY",
      },
      y: {
        show: true,
        formatter: function (val) {
          return val && Math.abs(Math.round(val));
        },
        title: {
          formatter: function (seriesName) {
            return seriesName;
          },
        },
      },
    },
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
      fontSize: "14px",
      fontWeight: 400,
      offsetX: 0,
      offsetY: 7,
      labels: {
        colors: !isNight ? ["#727272"] : ["#666"],
        useSeriesColors: false,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
  };

  var series = [
    {
      name: "Positive",
      color: "#04B8AF",
      type: "column",
      data: positiveData,
    },
    {
      name: "Negative",
      color: "#B9375E",
      type: "column",
      data: negativeData,
    },
  ];

  return (
    <div
      ref={sentTrendRef}
      style={{
        height: "360px",
        borderRadius: "12px",
        backgroundColor: !isNight ? "#2A2953" : "#fff",
        position: "relative",
        margin: "20px 0 0",
      }}
    >
      <div
        style={{
          width: "96.5%",
          position: "relative",
          top: "15px",
          margin: "1rem",
          display: "flex",
          justifyContent: "space-between",
          margin: "1rem",
        }}
      >
        <p
          style={{
            margin: "0",
            textAlign: "left",
            fontWeight: 500,
            color: !isNight ? "#fff" : "#484848",
          }}
        >
          Topic Trend
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
               Topic Trend Volume and Sentiment Breakdown Over Time
              </span>
            }
          >
            <InfoIcon
              style={{
                fontSize: "1rem",
                filter: "opacity(0.7)",
                position: "relative",
                top: "3px",
              }}
            />
          </Tooltip><Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                Available Events
              </span>
            }
          >
            <EventDrop />
          </Tooltip>
        </p><div
          style={{
            position: "absolute",
            top: "28px",
            left: "30px",
          }}
        >
          <StatsOnTrendline userCount={teamSize} textCount={totalText} />
        </div>
        <Tooltip
          title={
            <span
              style={{
                fontSize: "0.8rem",
                textAlign: "center",
              }}
            >
              Copy to clipboard
            </span>
          }
        >
          {clipProcessing ? (
            <CircularProgress size={16} color="inherit" />
          ) : (
            <ContentCopyOutlinedIcon
              onClick={getImage}
              style={{
                fontSize: "1.2rem",
                cursor: clipProcessing ? "wait" : "pointer",
              }}
            />
          )}
        </Tooltip>
      </div>
      {totalConvoData.status === "success" || (isDemo && !isLoading) ? (
        <div>
          <div className="sentiment-trend-container">
            {/* <CanvasJSChart options={options} id="sentiment-trend-container" /> */}
            <Chart
              options={options}
              series={series}
              // type="area"
              width="100%"
              height="270px"
            />
          </div>
        </div>
      ) : totalConvoData.status === "fail" || currentWorkflowID === "" ? (
        <div style={{ position: "relative", top: "100px" }}>
          <ErrorOutlineIcon style={{ color: "#94989f" }} />
          <p style={{ margin: "0", color: "#94989f" }}>
            {currentWorkflowID === "" ? "Select a workflow!" : "No Data found!"}
          </p>
        </div>
      ) : (
        <Stack spacing={1}>
          <Skeleton
            variant="text"
            width={"90%"}
            height={"40px"}
            style={{ position: "relative", top: "10px", margin: "0 auto" }}
          />
          <Skeleton
            variant="rectangular"
            width={"90%"}
            height={"220px"}
            style={{ position: "relative", top: "20px", margin: "0 auto" }}
          />
        </Stack>
      )}
    </div>
  );
}

export default SentimentTrend;
