import React, { useState } from "react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useDispatch, useSelector } from "react-redux";
import { FormGroup, FormControlLabel, Checkbox } from "@material-ui/core/";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import _ from "lodash";
import { SAVE_DASHBOARD_SECTIONS } from "../../../redux/Dashboard/Dashboard.types";
const AddSectionModal = ({ handleClose, workflowID }) => {
  const dispatch = useDispatch();
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);
  const modelChannel = useSelector((data) => data.dashboard.modelChannel?.data);
  const dashboardSections = useSelector((data) =>
    data.dashboard.dashboardSections.models
      ? data.dashboard.dashboardSections.models
      : []
  );

  // const sectionsStored = localStorage.getItem("sectionsToShow").split(",");

  const [sections, setSections] = useState(dashboardSections);

  function removeItemAll(arr, value) {
    var i = 0;
    while (i < arr.length) {
      if (arr[i] === value) {
        arr.splice(i, 1);
      } else {
        ++i;
      }
    }
    return arr;
  }

  const handleChange = (value) => {
    var temp = sections;
    if (sections.includes(value)) {
      temp = removeItemAll(temp, value);
    } else {
      temp.push(value);
    }
    setSections(_.union(temp));
  };

  const handleSave = () => {
    const data = sections;
    data.push("morale");
    data.push("emotional");
    data.push("sentiment");

    dispatch({
      type: SAVE_DASHBOARD_SECTIONS,
      payload: {
        workflowID: workflowID,
        models: _.union(data),
      },
    });
    // dispatch({ type: "SET_SECTIONS", payload: sections });
    // localStorage.setItem("sectionsToShow", sections);
    handleClose();
  };

  return (
    <div
      className="addChartsContainer"
      style={{
        width: "600px",
        overflowY: "scroll",
        marginTop: "120px",
        background: !isNight ? "#212047" : "#f6f7fc",
      }}
    >
      <div style={{ color: !isNight ? "#aebcc9" : "#666", fontSize: "1.3rem" }}>
        Add Section
      </div>
      <div className="closeBtn" onClick={handleClose}>
        <HighlightOffIcon
          style={{
            fill: !isNight ? "#aebcc9" : "#666",
            position: "absolute",
            top: "15px",
            right: "15px",
          }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          minHeight: "80%",
        }}
      >
        <div style={{ margin: "0 0.5rem" }}>
          <p
            style={{
              fontSize: "1rem",
              textAlign: "left",
              marginBottom: "0",
              margin: "20px 0",
              color: !isNight ? "#aebcc9" : "#343434",
            }}
          >
            Sections
          </p>
          <FormGroup>
            <Box
              component="main"
              sx={
                {
                  // flexGrow: 1,
                  // mt: 3,
                }
              }
            >
              <Grid container spacing={3}>
                <Grid item xl={6} lg={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked
                        onClick={() => {
                          handleChange("morale");
                        }}
                        style={{
                          color: !isNight ? "#aebcc9" : "#1554F6",
                        }}
                      />
                    }
                    label={
                      <p
                        style={{
                          fontSize: "14px",
                          color: !isNight ? "#aebcc9" : "#343434",
                          margin: "0",
                        }}
                      >
                        Morale
                      </p>
                    }
                  />
                </Grid>
                <Grid item xl={6} lg={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked
                        onClick={() => {
                          handleChange("emotion");
                        }}
                        style={{
                          color: !isNight ? "#aebcc9" : "#1554F6",
                        }}
                      />
                    }
                    label={
                      <p
                        style={{
                          fontSize: "14px",
                          color: !isNight ? "#aebcc9" : "#343434",
                          margin: "0",
                        }}
                      >
                        Emotional
                      </p>
                    }
                  />
                </Grid>
                <Grid item xl={6} lg={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked
                        onClick={() => {
                          handleChange("sentiment");
                        }}
                        style={{
                          color: !isNight ? "#aebcc9" : "#1554F6",
                        }}
                      />
                    }
                    label={
                      <p
                        style={{
                          fontSize: "14px",
                          color: !isNight ? "#aebcc9" : "#343434",
                          margin: "0",
                        }}
                      >
                        Sentiment
                      </p>
                    }
                  />
                </Grid>
                {modelChannel.allModels?.includes("personalInfo") && (
                  <Grid item xl={6} lg={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sections.includes("personalInfo")}
                          onClick={() => {
                            handleChange("personalInfo");
                          }}
                          style={{
                            color: !isNight ? "#aebcc9" : "#1554F6",
                          }}
                        />
                      }
                      label={
                        <p
                          style={{
                            fontSize: "14px",
                            color: !isNight ? "#aebcc9" : "#343434",
                            margin: "0",
                          }}
                        >
                          Personal Information
                        </p>
                      }
                    />
                  </Grid>
                )}
                {modelChannel.allModels?.includes("hateSpeech") && (
                  <Grid item xl={6} lg={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sections.includes("hateSpeech")}
                          onClick={() => {
                            handleChange("hateSpeech");
                          }}
                          style={{
                            color: !isNight ? "#aebcc9" : "#1554F6",
                          }}
                        />
                      }
                      label={
                        <p
                          style={{
                            fontSize: "14px",
                            color: !isNight ? "#aebcc9" : "#343434",
                            margin: "0",
                          }}
                        >
                          Hate Speech
                        </p>
                      }
                    />
                  </Grid>
                )}
                {modelChannel.allModels?.includes("script") && (
                  <Grid item xl={6} lg={6}>
                    {" "}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sections.includes("script")}
                          onClick={() => {
                            handleChange("script");
                          }}
                          style={{
                            color: !isNight ? "#aebcc9" : "#1554F6",
                          }}
                        />
                      }
                      label={
                        <p
                          style={{
                            fontSize: "14px",
                            color: !isNight ? "#aebcc9" : "#343434",
                            margin: "0",
                          }}
                        >
                          Script
                        </p>
                      }
                    />
                  </Grid>
                )}
                {modelChannel.allModels?.includes("textualAbuse") && (
                  <Grid item xl={6} lg={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sections.includes("textualAbuse")}
                          onClick={() => {
                            handleChange("textualAbuse");
                          }}
                          style={{
                            color: !isNight ? "#aebcc9" : "#1554F6",
                          }}
                        />
                      }
                      label={
                        <p
                          style={{
                            fontSize: "14px",
                            color: !isNight ? "#aebcc9" : "#343434",
                            margin: "0",
                          }}
                        >
                          Textual Abuse
                        </p>
                      }
                    />
                  </Grid>
                )}
                {modelChannel.allModels?.includes("Insult") && (
                  <Grid item xl={6} lg={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sections.includes("Insult")}
                          onClick={() => {
                            handleChange("Insult");
                          }}
                          style={{
                            color: !isNight ? "#aebcc9" : "#1554F6",
                          }}
                        />
                      }
                      label={
                        <p
                          style={{
                            fontSize: "14px",
                            color: !isNight ? "#aebcc9" : "#343434",
                            margin: "0",
                          }}
                        >
                          Insult Detection
                        </p>
                      }
                    />
                  </Grid>
                )}
                {modelChannel.allModels?.includes("sexualHarrasment") && (
                  <Grid item xl={6} lg={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sections.includes("sexualHarrasment")}
                          onClick={() => {
                            handleChange("sexualHarrasment");
                          }}
                          style={{
                            color: !isNight ? "#aebcc9" : "#1554F6",
                          }}
                        />
                      }
                      label={
                        <p
                          style={{
                            fontSize: "14px",
                            color: !isNight ? "#aebcc9" : "#343434",
                            margin: "0",
                          }}
                        >
                          Sexual Harrasment
                        </p>
                      }
                    />
                  </Grid>
                )}
                {modelChannel.allModels?.includes("sexismDetection") && (
                  <Grid item xl={6} lg={6}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sections.includes("sexismDetection")}
                          onClick={() => {
                            handleChange("sexismDetection");
                          }}
                          style={{
                            color: !isNight ? "#aebcc9" : "#1554F6",
                          }}
                        />
                      }
                      label={
                        <p
                          style={{
                            fontSize: "14px",
                            color: !isNight ? "#aebcc9" : "#343434",
                            margin: "0",
                          }}
                        >
                          Sexism Detection
                        </p>
                      }
                    />
                  </Grid>
                )}
                {modelChannel.allModels?.includes("profanityDetection") && (
                  <Grid item xl={6} lg={6}>
                    {" "}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={sections.includes("profanityDetection")}
                          onClick={() => {
                            handleChange("profanityDetection");
                          }}
                          style={{
                            color: !isNight ? "#aebcc9" : "#1554F6",
                          }}
                        />
                      }
                      label={
                        <p
                          style={{
                            fontSize: "14px",
                            color: !isNight ? "#aebcc9" : "#343434",
                            margin: "0",
                          }}
                        >
                          Profanity
                        </p>
                      }
                    />
                  </Grid>
                )}
                <Grid item xl={6} lg={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={sections.includes("dei")}
                        onClick={() => {
                          handleChange("dei");
                        }}
                        style={{
                          color: !isNight ? "#aebcc9" : "#1554F6",
                        }}
                      />
                    }
                    label={
                      <p
                        style={{
                          fontSize: "14px",
                          color: !isNight ? "#aebcc9" : "#343434",
                          margin: "0",
                        }}
                      >
                        DEI detections
                      </p>
                    }
                  />
                </Grid>
              </Grid>
            </Box>
            <div className="submitBtn">
              <div className="rangeBtn" type="submit" onClick={handleSave}>
                {/* <TurnedInNotIcon /> */}
                <div className="btnText">Add</div>
              </div>
            </div>
          </FormGroup>
        </div>
      </div>
    </div>
  );
};

export default AddSectionModal;
