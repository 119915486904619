/* eslint-disable import/prefer-default-export */
import api from "./axiosConfig";

export function* login(action) {
  let resp;
  yield api
    .post("/login", action.payload)
    .then((response) => {
      resp = response;
      // localStorage.setItem("auth-token", response.data.token);
      
      setTimeout(() => {if (
        localStorage.getItem("auth-token") === null ||
        localStorage.getItem("auth-token") === undefined
      ) {
        window.location.reload();
      }}, 2000);
    })
    .catch((error) => {
      resp = error;
    });
  return resp;
}
