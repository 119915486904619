import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import { toast } from "react-toastify";
import { SET_ACTION_CONFIGURATION } from "../../../../redux/Dashboard/Dashboard.types";
const sn = require("servicenow-rest-api");

// import { Box, Typography, withStyles } from "@material-ui/core";
// import LinearProgress from "@material-ui/core/LinearProgress";
// import CloseIcon from "@material-ui/icons/Close";
// import CheckIcon from "@material-ui/icons/Check";
// import { CHECK_KEYS } from "../../redux/Dashboard/Dashboard.types";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#fff",
    padding: "0!important",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    "& div": {
      color: "#fff",
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    marginTop: 20,
    color: "rgba(0, 0, 0, 0.87)!important",
    borderBlockColor: "#6352C6",
  },
  input: {
    color: "#fff",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    padding: 0,
  },
}));

export default function ActionConfigModal({
  open,
  handleClose,
  selectedAction,
  //   source,
}) {
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  const classes = useStyles();
  const [toEmail, setToEmail] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");

  const [snowInstance, setSnowInstance] = useState("");
  const [snowAdmin, setSnowAdmin] = useState("");
  const [snowPassword, setSnowPassword] = useState("");
  const dispatch = useDispatch();

  const actionConfigData = useSelector(
    (data) => data.dashboard.actionConfigData
  );

  const EmailConfigHandler = () => {
    actionConfigData.email.push({
      id: "",
      data: {
        toEmail,
        emailSubject,
        emailBody,
      },
    });
    dispatch({
      type: SET_ACTION_CONFIGURATION,
      payload: {
        actionConfigData,
      },
    });
    toast.success("Email Configuration Saved");
    handleClose();
  };

  const snowAuthHandler = () => {
    const ServiceNow = new sn(
      "dev89915.service-now.com",
      "admin",
      "yQqjq/27YV-X"
    );
    ServiceNow.Authenticate((res) => {
      // console.log("done");
    });
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <DialogContent classes={{ root: classes.root }} style={{ width: 350 }}>
        <div
          className="popUp"
          style={{
            backgroundColor: !isNight ? "#212047" : "#e5e5e5",
          }}
        >
          <div className="popUpTitle">{selectedAction.title}</div>

          {selectedAction.title === "Email configuration" && (
            <form className={classes.container} style={{ width: "100%" }}>
              <input
                type="text"
                value={toEmail}
                className="keyInput"
                placeholder="To"
                onChange={(event) => setToEmail(event.target.value)}
                style={{ paddingLeft: 10, width: "90%", height: 32 }}
              />
              <input
                type="text"
                value={emailSubject}
                className="keyInput"
                placeholder="Subject"
                onChange={(event) => setEmailSubject(event.target.value)}
                style={{ paddingLeft: 10, width: "90%", height: 32 }}
              />
              <textarea
                value={emailBody}
                className="keyInput"
                placeholder="Message"
                onChange={(event) => setEmailBody(event.target.value)}
                style={{
                  height: 60,
                  paddingLeft: 10,
                  paddingTop: 8,
                  width: "90%",
                }}
              />
              <div className="btnOptions" style={{ width: "100%" }}>
                <div className="cancelBtn" onClick={handleClose}>
                  <div className="rangeBtn">
                    <CloseIcon />
                    <div className="btnText">Cancel</div>
                  </div>
                </div>
                <div className="submitBtn" onClick={EmailConfigHandler}>
                  <div className="rangeBtn">
                    <BookmarkBorderIcon />
                    <div className="btnText" type="submit">
                      Submit
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
          {selectedAction.title === "ServiceNow configuration" && (
            <form className={classes.container} style={{ width: "100%" }}>
              <input
                type="text"
                value={snowInstance}
                className="keyInput"
                placeholder="InstanceID i.e. dev89915"
                onChange={(event) => setSnowInstance(event.target.value)}
                style={{ paddingLeft: 10, width: "90%", height: 32 }}
              />
              <input
                type="text"
                value={snowAdmin}
                className="keyInput"
                placeholder="UserId"
                onChange={(event) => setSnowAdmin(event.target.value)}
                style={{ paddingLeft: 10, width: "90%", height: 32 }}
              />
              <input
                value={snowPassword}
                className="keyInput"
                placeholder="Password"
                onChange={(event) => setSnowPassword(event.target.value)}
                style={{ paddingLeft: 10, width: "90%", height: 32 }}
              />
              <div className="btnOptions" style={{ width: "100%" }}>
                <div className="cancelBtn" onClick={handleClose}>
                  <div className="rangeBtn">
                    <CloseIcon style={{ color: "#000" }} />
                    <div className="btnText" style={{ color: "#000" }}>
                      Cancel
                    </div>
                  </div>
                </div>
                <div className="submitBtn" onClick={snowAuthHandler}>
                  <div className="rangeBtn">
                    <BookmarkBorderIcon />
                    <div className="btnText" type="submit">
                      Submit
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}
