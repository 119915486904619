import React, { useState } from "react";
import {
  Typography,
  Box,
  Paper,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import CloseIcon from "@mui/icons-material/Close";
// import SaveActionPlanModal from "./popup/SaveActionPlanModal";
import Modal from "@mui/material/Modal";
import SaveActionPlan from "./SaveActionPlan";

function formatQuery(query) {
  // Parse the JSON string into an object
  if (!query || query === "overall") {
    return "Overall";
  } else {
    let queryObject = typeof query === "string" ? JSON.parse(query) : query;

    // Map for comparison operators
    const comparisonMap = {
      $lte: "<=",
      $gte: ">=",
      $lt: "<",
      $gt: ">",
    };

    let result = [];

    // Loop through each key-value pair in the object
    for (let [key, value] of Object.entries(queryObject)) {
      // Capitalize the key (replace underscores with spaces and capitalize words)
      let formattedKey = key
        .replace(/_/g, " ")
        .replace(/\b\w/g, (char) => char.toUpperCase());

      // Check if value is an object (for comparison operators like $lte, $gte, etc.)
      if (typeof value === "object" && value !== null) {
        for (let [operator, comparisonValue] of Object.entries(value)) {
          // Map the operator to its symbol or keep it as is
          let comparison = comparisonMap[operator] || operator;
          result.push(`${formattedKey}: ${comparison} ${comparisonValue}`);
        }
      } else {
        // For simple key-value pairs, just capitalize the value and append
        let formattedValue = value
          .toString()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        result.push(`${formattedKey}: ${formattedValue}`);
      }
    }

    // Join the results into a string
    return result.join(", ");
  }
}
const PlanCard = ({ plan, page }) => {
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  return (
    <Box style={{ height: "100%" }}>
      <Paper elevation={3} style={{ padding: "16px" }}>
        <Typography
          variant="h6"
          gutterBottom
          style={{ textAlign: "left", fontWeight: "bold" }}
        >
          {plan.objective}
        </Typography>
        {/* <Typography style={{ textAlign: "left", margin: "0 " }} gutterBottom>
            <span style={{ fontWeight: "bold" }}>Source: </span>
            {!plan.type ? "Insight" : plan.type}
          </Typography> */}
        {/* <Typography style={{ textAlign: "left", margin: "0 " }} gutterBottom>
            <span style={{ fontWeight: "bold" }}>Target Group: </span>
            {plan.targetGroup === ""
              ? "Overall"
              : formatQuery(plan.targetGroup)}
          </Typography> */}
        <List>
          {plan.steps.map((step, index) => (
            <ListItem key={index} alignItems="flex-start">
              <ListItemText
                primary={step.action}
                secondary={
                  <>
                    <Typography
                      component="span"
                      variant="body2"
                      color="textPrimary"
                    >
                      {step.description}
                    </Typography>
                    {/* {" — " + step.implementation} */}
                  </>
                }
              />
            </ListItem>
          ))}
        </List>
        <Box display="flex" justifyContent="right">
          <Button
            onClick={() => {
              setSaveModalOpen(true);
            }}
            variant="contained"
            style={{
              background: "#D06283",
              color: "#fff",
              margin: "0 0.5rem",
            }}
          >
            <BookmarkIcon />
            Save
          </Button>{" "}
        </Box>
      </Paper>
      <Modal
        open={saveModalOpen}
        onClose={() => {
          setSaveModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SaveActionPlan
          plan={plan}
          handleClose={() => {
            setSaveModalOpen(false);
          }}
        />
      </Modal>
      {/* <Modal
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DeleteActionPlanModal
          plan={plan}
          handleClose={() => {
            setDeleteModalOpen(false);
          }}
        />
      </Modal> */}
    </Box>
  );
};

export default PlanCard;
