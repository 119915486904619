import { toast } from "react-toastify";
import api from "../axiosConfig";
import { SessionOut } from "../../utilities/dashboardUtils";

// eslint-disable-next-line import/prefer-default-export
export function* getChannelsFromApp(action) {
  let resp;
  yield api
    .get(`/appToken/${action.payload.key}`, {
      headers: {
        "Access-Control-Allow-Headers": "x-access-token",
        "x-access-token": localStorage.getItem("auth-token"),
      },
    })
    .then((response) => {
      if (response.status === 404) {
        toast.error("Error in uploading file");
      } else if (response.status === 400) {
        SessionOut();
      } else {
        const channels = [];
        response.data.channels.map((item) => channels.push(item.name));
        resp = { status: "PASS", channels };
      }
    })
    .catch((err) => {
      resp = err;
      toast.error("Data not found");
    });
  return resp;
}
