import { toast } from "react-toastify";
import api from "../axiosConfig";
import { SessionOut } from "../../utilities/dashboardUtils";

export function* updateUser(action) {
  let resp;
  yield api
    .post(`/updateUser`, action.payload, {
      headers: {
        "Access-Control-Allow-Headers": "x-access-token",
        "x-access-token": localStorage.getItem("auth-token"),
      },
    })
    .then((response) => {
      if (response.status === 404) {
        toast.error("Data not found");
      } else if (response.status === 400) {
        SessionOut();
      } else {
        resp = response;
        toast.success("User Updated successfully");
      }
    })
    .catch((error) => {
      // toast.error("Data not found");
      return error;
    });
  return resp;
}
