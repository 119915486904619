/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect, useState } from "react";
import "../styles/loginPage.css";
import LoadingButton from "../Components/common/LoadingButton";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LockIcon from "@material-ui/icons/Lock";
import { AuthContext } from "../Context/UserContext";
import {
  LOGIN,
  // LOGIN_BY_LOCAL_STORAGE
} from "../redux/Login/Login.types";
import Navbar from "../Components/Navbar";

const LoginPage = () => {
  const [Username, setUsername] = useState("");
  const [Password, setPassword] = useState("");
  const [UserStatus, setUserStatus] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const auth = useContext(AuthContext);
  const storedData = JSON.parse(localStorage.getItem("userData"));

  const dispatch = useDispatch();
  const login = useSelector((data) => data.login);
  const history = useHistory();

  useEffect(() => {
    if (
      storedData &&
      storedData.usertoken &&
      new Date(storedData.expiration) > new Date()
    ) {
      history.push("/dashboard");
    }
  });

  useEffect(() => {
    setUserStatus("");
    const authUser = JSON.parse(localStorage.getItem("userData"));
    if (login.userLoggedIn) {
      localStorage.setItem("auth-token", login.loginData.data.token);
      localStorage.setItem("userDetails", JSON.stringify(login.loginData.data));
      auth.login(login.loginData.data._id, login.loginData.data.token);
    }
  }, [login.userLoggedIn]);

  useEffect(() => {
    setUserStatus(login.loginData.message);
    setIsLoading(false);
  }, [login.loginData.message]);

  const usernameHandler = (event) => {
    setUsername(event.target.value);
  };
  const passwordHandler = (event) => {
    setPassword(event.target.value);
  };
  const enterHandler = (event) => {
    if (event.charCode === 13) {
      FormHandler(event);
    }
  };

  const FormHandler = (e) => {
    e.preventDefault();
    localStorage.setItem("demoMode", JSON.stringify(false));
    setIsLoading(true);
    if (Username && Password) {
      const user = { email: Username.toLowerCase().trim(), password: Password };
      dispatch({ type: LOGIN, payload: user });
    } else if (Username) {
      setUserStatus("Please enter password");
    } else {
      setUserStatus("Please enter username");
    }
    localStorage.setItem("nightMode", true);
  };

  return (
    <div className="loginPage">
      <div style={{ width: "100%" }}>
        <Navbar isLoggedIn={false} />
      </div>
      <div className="formLoginContainer">
        <div className="formLogo">
          <img src="/Logo-3x.png" alt="logo" width="180px" />
        </div>
        <form className="loginForm">
          <div className="inputContainer">
            <label className="formLabels">Email</label>
            <input
              type="text"
              onKeyPress={(event) => enterHandler(event)}
              onChange={(event) => usernameHandler(event)}
              value={Username}
              placeholder="Enter your email"
              name="uname"
              required
            />
          </div>

          <div className="inputContainer">
            <label className="formLabels">Password</label>
            <input
              type="password"
              onKeyPress={(event) => enterHandler(event)}
              onChange={(event) => passwordHandler(event)}
              value={Password}
              placeholder="Enter your password"
              name="psw"
              required
            />
          </div>
          <label className="checkboxContainer"></label>
          {UserStatus && UserStatus.length > 0 && (
            <div className="errorMessage">{UserStatus}</div>
          )}

          <LoadingButton
            className="submitButton"
            loading={isLoading}
            onClick={(e) => FormHandler(e)}
          >
            <div className="buttonText">Login</div>
          </LoadingButton>
        </form>
        <div className="forgetPassword">
          <LockIcon className="lockIcon links" />
          <Link to="forget-password" className="links">
            Forgot Password?
          </Link>
        </div>
      </div>
      <div className="signup">
        <span className="signupText">
          Open in{" "}
          <Link to="/demo-login" className="links">
            demo mode
          </Link>
        </span>
      </div>
      {/* <div className="signup" style={{ marginTop: "10px" }}>
        <span className="signupText">
          Don’t have an account?{" "}
          <Link to="/signup" className="links">
            Sign up now
          </Link>
        </span>
      </div> */}
      <p className="termCondition">
        <a
          className="td-none signupText"
          href="https://scanta-web-resources.s3.amazonaws.com/scanta-terms.pdf"
          target="_blank"
          rel="noreferrer"
        >
          <span>Terms and Conditions</span>
        </a>{" "}
        |{" "}
        <a
          className="td-none signupText"
          href="https://scanta-web-resources.s3.amazonaws.com/Scanta%2BPrivacy%2BPolicy_Revised_2022.07.06_Clean.pdf"
          target="_blank"
          rel="noreferrer"
        >
          <span>Privacy Policy</span>
        </a>
      </p>
    </div>
  );
};

export default LoginPage;
