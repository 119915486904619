import api from "../axiosConfig";
import { SessionOut } from "../../utilities/dashboardUtils";

export function* getUsers(action) {
  let resp;
  yield api
    .get(`/getUsers`, {
      headers: {
        "Access-Control-Allow-Headers": "x-access-token",
        "x-access-token": localStorage.getItem("auth-token"),
      },
    })
    .then((response) => {
      if (response.status === 404) {
        // toast.error("Data not found");
      } else if (response.status === 400) {
        SessionOut();
      } else {
        resp = response;
        // toast.success("Invite sent Successfully");
      }
    })
    .catch((error) => {
      // toast.error("Data not found");
      return error;
    });
  return resp;
}
