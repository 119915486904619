import React, { useState, useEffect, createRef } from "react";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import "./index.css";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Chart from "react-apexcharts";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@material-ui/core";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { useScreenshot } from "use-react-screenshot";
import * as clipboard from "clipboard-polyfill";
import { base64StringToBlob } from "blob-util";
import { SET_CHAT_CONTEXT } from "../../../../redux/Dashboard/Dashboard.types";

function MoraleDist({
  moraleData,
  currentFilterMorale,
  customDateMorale,
  currentWorkflowID,
}) {
  const dispatch = useDispatch();
  const [clipProcessing, setClipProcessing] = useState(false);

  async function clip(b64Data) {
    const item = new clipboard.ClipboardItem({
      "image/png": base64StringToBlob(b64Data.split(",")[1], "image/png"),
    });

    await clipboard.write([item]);
  }

  const moraleDistRef = createRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const getImage = async () => {
    setClipProcessing(true);
    await takeScreenshot(moraleDistRef.current);
    setClipProcessing(false);
    image && clip(image);
  };

  useEffect(() => {
    image && clip(image);
  }, [image]);

  const filters = useSelector((data) => data.dashboard.filters);
  const [isLoading, setIsLoading] = useState(true);
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);
  const teamSize = useSelector((data) => data.dashboard.teamSize);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const [smallNumber, setSmallNumber] = useState(0);
  useEffect(() => {
    setSmallNumber(getRandomInt(-10, 10));
  }, [filters, currentFilterMorale, customDateMorale]);

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  var options = {
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: false, // <== line to add
        },
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#04B8AF"],
    plotOptions: {
      bar: {
        dataLabels: {
          position: "top", // top, center, bottom
        },
        columnWidth: "30%",
      },
      cylinder: {
        enabled: true,
      },
    },
    xaxis: {
      labels: {
        style: {
          colors: !isNight
            ? ["#727272", "#727272", "#727272", "#727272", "#727272"]
            : ["#666"],
          cssClass: "apexcharts-xaxis-label",
        },
      },
      title: {
        text: "Morale Score",
        offsetY: 0,
        style: {
          color: !isNight ? "#727272" : "#666",
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      min: 0,
      tickAmount: 7,
      seriesName: "test",
      axisTicks: {
        show: true,
        color: !isNight ? "#727272" : "#666",
      },
      labels: {
        style: {
          colors: !isNight ? ["#727272"] : ["#666"],
          cssClass: "apexcharts-yaxis-label",
        },
        formatter: function (value) {
          return value && parseInt(value);
        },
      },
      title: {
        text: "% Users",
        style: {
          color: !isNight ? "#727272" : "#666",
          fontSize: "12px",
        },
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: true,
      followCursor: true,
      style: {
        fontSize: "12px",
      },

      y: {
        show: true,
        formatter: function (val) {
          return val && Math.round(val);
        },
        title: {
          formatter: function (seriesName) {
            return " ";
          },
        },
      },
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        var data = w.globals.initialSeries[seriesIndex].data[dataPointIndex];

        return (
          "<p style='margin:1rem 1rem'><b>" +
          data.x +
          ": </b>" +
          data.y +
          "%</p>" +
          "<br/>" +
          "<p style='position:relative;top:-30px;margin:0 1rem'><b>" +
          "Users: " +
          "</b>" +
          Math.round(((!isDemo ? teamSize : 28) * data.y) / 100) +
          "</p>"
        );
      },
    },
  };

  const series = [
    {
      data: [
        {
          x: "0-20",
          y:
            filters.gender === "male"
              ? isDemo
                ? moraleData.bucketWiseScore?.zero_twenty_M + smallNumber
                : moraleData.bucketWiseScore?.zero_twenty_M
              : filters.gender === "female"
              ? isDemo
                ? moraleData.bucketWiseScore?.zero_twenty_F + smallNumber
                : moraleData.bucketWiseScore?.zero_twenty_F
              : isDemo
              ? moraleData.bucketWiseScore?.zero_twenty + smallNumber
              : moraleData.bucketWiseScore?.zero_twenty,
        },
        {
          x: "21-40",
          y:
            filters.gender === "male"
              ? isDemo
                ? moraleData.bucketWiseScore?.twenty_forty_M - smallNumber
                : moraleData.bucketWiseScore?.twenty_forty_M
              : filters.gender === "female"
              ? isDemo
                ? moraleData.bucketWiseScore?.twenty_forty_F - smallNumber
                : moraleData.bucketWiseScore?.twenty_forty_F
              : isDemo
              ? moraleData.bucketWiseScore?.twenty_forty - smallNumber
              : moraleData.bucketWiseScore?.twenty_forty,
        },
        {
          x: "41-60",
          y:
            filters.gender === "male"
              ? moraleData.bucketWiseScore?.forty_sixty_M
              : filters.gender === "female"
              ? moraleData.bucketWiseScore?.forty_sixty_F
              : moraleData.bucketWiseScore?.forty_sixty,
        },
        {
          x: "61-80",
          y:
            filters.gender === "male"
              ? isDemo
                ? moraleData.bucketWiseScore?.sixty_eighty_M + smallNumber
                : moraleData.bucketWiseScore?.sixty_eighty_M
              : filters.gender === "female"
              ? isDemo
                ? moraleData.bucketWiseScore?.sixty_eighty_F + smallNumber
                : moraleData.bucketWiseScore?.sixty_eighty_F
              : isDemo
              ? moraleData.bucketWiseScore?.sixty_eighty + smallNumber
              : moraleData.bucketWiseScore?.sixty_eighty,
        },
        {
          x: "81-100",
          y:
            filters.gender === "male"
              ? isDemo
                ? moraleData.bucketWiseScore?.eighty_hundred_M - smallNumber
                : moraleData.bucketWiseScore?.eighty_hundred_M
              : filters.gender === "female"
              ? isDemo
                ? moraleData.bucketWiseScore?.eighty_hundred_F - smallNumber
                : moraleData.bucketWiseScore?.eighty_hundred_F
              : isDemo
              ? moraleData.bucketWiseScore?.eighty_hundred - smallNumber
              : moraleData.bucketWiseScore?.eighty_hundred,
        },
      ],
    },
  ];

  useEffect(() => {
    dispatch({
      type: SET_CHAT_CONTEXT,
      payload: `Morale user group based on score: ${JSON.stringify({
        bucketWiseScore: moraleData?.bucketWiseScore,
      })}`,
    });
  }, [moraleData]);

  return (
    <div
      ref={moraleDistRef}
      style={{
        height: "330px",
        borderRadius: "12px",
        backgroundColor: !isNight ? "#2A2953" : "#fff",
        position: "relative",
        margin: "0px 0 0 0.3rem",
      }}
    >
      <div
        style={{
          width: "96%",
          position: "relative",
          top: "15px",
          margin: "1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            margin: "0",
            textAlign: "left",
            fontWeight: 500,
            zIndex: 3,
            color: !isNight ? "#fff" : "#484848",
          }}
        >
          Morale Distribution{" "}
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                 % of Users Falling into Different Morale Ranges
              </span>
            }
          >
            <InfoIcon
              style={{
                fontSize: "1rem",
                position: "relative",
                top: "3px",
                filter: "opacity(0.7)",
              }}
            />
          </Tooltip>
        </p>
        <Tooltip
          title={
            <span
              style={{
                fontSize: "0.8rem",
                textAlign: "center",
              }}
            >
              Copy to clipboard
            </span>
          }
        >
          {clipProcessing ? (
            <CircularProgress size={16} color="inherit" />
          ) : (
            <ContentCopyOutlinedIcon
              onClick={getImage}
              style={{
                fontSize: "1.2rem",
                cursor: clipProcessing ? "wait" : "pointer",
              }}
            />
          )}
        </Tooltip>
      </div>
      {moraleData.status === "success" || (isDemo && !isLoading) ? (
        <div>
          <div className="morale-dist-container">
            <Chart
              options={options}
              series={series}
              type="bar"
              width="100%"
              height="280px"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "0 2rem",
                position: "relative",
                top: "-50px",
              }}
            >
              <p
                style={{
                  color: "#727272",
                  textAlign: "left",
                  width: "50%",
                  fontSize: "0.8rem",
                  marginLeft: "3rem",
                }}
              >
                Low Morale
              </p>

              <p
                style={{
                  color: "#727272",
                  textAlign: "right",
                  width: "50%",
                  fontSize: "0.8rem",
                }}
              >
                High Morale
              </p>
            </div>
          </div>
        </div>
      ) : moraleData.status === "fail" || currentWorkflowID === "" ? (
        <div style={{ position: "relative", top: "100px" }}>
          <ErrorOutlineIcon style={{ color: "#94989f" }} />
          <p style={{ margin: "0", color: "#94989f" }}>
            {currentWorkflowID === "" ? "Select a workflow!" : "No Data found!"}
          </p>
        </div>
      ) : (
        <Stack spacing={1}>
          <Skeleton
            variant="text"
            width={"90%"}
            height={"40px"}
            style={{ position: "relative", top: "10px", margin: "0 auto" }}
          />
          <Skeleton
            variant="rectangular"
            width={"90%"}
            height={"180px"}
            style={{ position: "relative", top: "20px", margin: "0 auto" }}
          />
        </Stack>
      )}
    </div>
  );
}

export default MoraleDist;
