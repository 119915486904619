import {
  Box,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { titleCase } from "../../../../utilities/dashboardUtils";
import { DELETE_FAVORITE_FILTER } from "../../../../redux/Dashboard/Dashboard.types";

function FavoriteFilters() {
  const dispatch = useDispatch();
  const isNight = useSelector((state) => state.dashboard.isNightSwitchOn);
  const favoriteFilters = useSelector(
    (state) => state.dashboard.favoriteFilters
  );
  const filters = useSelector((state) => state.dashboard.filters);

  const handleFilterChange = (data, isChecked) => {
    const updatedFilters = { ...filters };
    Object.keys(data).forEach((key) => {
      if (Array.isArray(data[key])) {
        if (isChecked) {
          updatedFilters[key] = updatedFilters[key].filter(
            (val) => !data[key].includes(val)
          );
        } else {
          updatedFilters[key] = [...(updatedFilters[key] || []), ...data[key]];
        }
      } else {
        if (isChecked) {
          delete updatedFilters[key];
        } else {
          updatedFilters[key] = data[key];
        }
      }
    });
    dispatch({
      type: "SET_FILTERS",
      payload: updatedFilters,
    });
  };

  const getFilterLabels = (data) => {
    return Object.keys(data)
      .map((key) => data[key] && data[key] !== "" && data[key].join(", "))
      .filter((label) => label)
      .join(", ");
  };

  const isChecked = (data) => {
    return Object.keys(data).every((key) => {
      if (Array.isArray(data[key])) {
        return data[key].every((val) => filters[key]?.includes(val));
      }
      return filters[key] === data[key];
    });
  };

  const deleteFavoriteFilter = (filter) => {
    dispatch({ type: DELETE_FAVORITE_FILTER, payload: filter });
  };
  return (
    <Box>
      <div style={{ position: "relative", top: "-1rem", margin: "1rem 0 0" }}>
        <Accordion sx={{ width: "100%", boxShadow: "none" }}>
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                sx={{
                  color: !isNight ? "#aebcc9" : "#343434",
                  margin: "0 0.5rem",
                }}
              />
            }
            sx={{
              backgroundColor: isNight ? "#F6F7FC" : "#212047",
              color: !isNight ? "#aebcc9" : "#343434",
              width: "100%",
              textAlign: "left",
              padding: "0 0.5rem",
            }}
          >
            <Typography style={{ margin: "0 0.5rem" }}>Favorites</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              backgroundColor: isNight ? "#F6F7FC" : "#212047",
              color: "#999",
            }}
          >
            <div style={{ position: "relative", top: "-1rem" }}>
              <FormGroup>
                {Array.from(new Set(favoriteFilters.map((item) => JSON.stringify(item))))
                  .map((json) => JSON.parse(json))
                  .map((item, index) => (
                    <Box
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            checked={isChecked(item)}
                            onChange={() =>
                              handleFilterChange(item, isChecked(item))
                            }
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <Typography
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            {titleCase(getFilterLabels(item))}
                          </Typography>
                        }
                      />
                      <DeleteOutlineIcon
                        onClick={() => {
                          deleteFavoriteFilter(item);
                        }}
                        style={{
                          position: "relative",
                          top: "5px",
                          cursor: "pointer",
                        }}
                      />
                    </Box>
                  ))}
              </FormGroup>
            </div>
          </AccordionDetails>
        </Accordion>
      </div>
    </Box>
  );
}

export default FavoriteFilters;
