export const totalConvoParam = {

};

export const detectedUserParam = {
    timeOption: "all",
    startDate: "",
    endDate: "",
};

export const modelTrendsParam = {
  timeOption: "all",
//   startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
//   endDate: new Date(),
channels: [],
  startDate: "",
  endDate: "",
};

export const actionParam = {
    timeOption: "all",
    startDate: "",
    endDate: "",
    workflowID: "",
};
export const modelChannelParam = {
    userID: "",
};
export const workflowCountParam = {};
export const doughnutData = {
    backgroundColor: [
        "rgba(31, 120, 180, 0.8)",
        "rgba(166, 206, 227, 0.8)",
        "rgba(251, 154, 153, 0.8)",
        "rgba(178, 223, 138, 0.8)",
        "rgba(51, 160, 44, 0.8)",
    ],
    borderColor: [
        "rgba(31, 120, 180, 1)",
        "rgba(166, 206, 227, 1)",
        "rgba(251, 154, 153, 1)",
        "rgba(178, 223, 138, 1)",
        "rgba(51, 160, 44, 1)",
    ],
    labels: ["Joy", "Anger", "Fear", "Surprise", "Sad"],
};