import React, { useState } from "react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import TutorialSubModal from "./TutorialSubModal";
import { ICONS } from "../../../../AppConfig";
import Modal from "@material-ui/core/Modal";
import { useSelector } from "react-redux";

const TutorialModal = ({ handleClose }) => {
  const [open, setOpen] = useState(false);
  const [selectedModel, setSelectedModel] = useState({
    title: null,
  });
  const [currentScreen, setCurrentScreen] = useState("source");

  const handleOpen = (e) => {
    setSelectedModel({ title: e });
    if (selectedModel.title !== null) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const tutorialModalCloseHandler = () => {
    setOpen(false);
  };
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  return (
    <div
      className="addChartsContainer"
      style={{
        width: "80vw",
        height: "90vh",
        overflowY: "scroll",
        marginTop: "20px",
        background: isNight ? "#f6f7fc" : "#17123b",
      }}
    >
      <div style={{ color: !isNight ? "#aebcc9" : "#666", fontSize: "2rem" }}>
        How To Use?
      </div>
      <div className="closeBtn" onClick={handleClose}>
        <HighlightOffIcon
          style={{
            fill: !isNight ? "#aebcc9" : "#666",
            position: "absolute",
            top: "15px",
            right: "15px",
          }}
        />
      </div>
      <div
        style={{
          // display: "flex",
          // justifyContent: "space-between",
          minHeight: "80%",
        }}
      >
        {currentScreen === "source" && (
          <>
            <div style={{ color: "#666", width: "100%", minHeight: "300px" }}>
              <p style={{ color: "#1abcfe", fontSize: "1.2rem" }}>
                1. Configure source
              </p>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: "50%",
                    color: "#666",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  onClick={() => handleOpen("Archive")}
                >
                  <img
                    style={{ width: "100px", margin: "auto" }}
                    src={ICONS.ARCHIVE}
                    alt="archive"
                  />
                  <p
                    style={{
                      textAlign: "center",
                      color: !isNight ? "#aebcc9" : "#666",
                    }}
                  >
                    Archive
                  </p>
                </div>
                <div
                  style={{
                    width: "50%",
                    color: "#666",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  onClick={() => handleOpen("Slack")}
                >
                  <img
                    style={{ width: "100px", margin: "auto" }}
                    src={ICONS.SLACK}
                    alt="archieve"
                  />
                  <p
                    style={{
                      textAlign: "center",
                      color: !isNight ? "#aebcc9" : "#666",
                    }}
                  >
                    Slack
                  </p>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: "50%",
                    color: "#666",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  onClick={() => handleOpen("Teams")}
                >
                  <img
                    style={{ width: "100px", margin: "auto" }}
                    src={ICONS.TEAMS}
                    alt="archieve"
                  />
                  <p
                    style={{
                      textAlign: "center",
                      color: !isNight ? "#aebcc9" : "#666",
                    }}
                  >
                    Teams
                  </p>
                </div>
                <div
                  style={{
                    width: "50%",
                    color: "#666",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  onClick={() => handleOpen("Discord")}
                >
                  <img
                    style={{ width: "100px", margin: "auto" }}
                    src={ICONS.DISCORD}
                    alt="archieve"
                  />
                  <p
                    style={{
                      textAlign: "center",
                      color: !isNight ? "#aebcc9" : "#666",
                    }}
                  >
                    Discord
                  </p>
                </div>
              </div>
            </div>
            <div style={{ color: isNight ? "#aebcc9" : "#666" }}>
              <ul>
                <li>configure one of the source from the sidebar.</li>
                <li>Drag and Drop the tile you configured to the workflow.</li>
              </ul>
            </div>

            <div
              style={{
                display: "flex",
                position: "absolute",
                bottom: "50px",
                left: "45%",
                justifyContent: "center",
                margin: "2rem 0 0",
              }}
            >
              <div onClick={() => setCurrentScreen("action")}>
                {/* <ArrowRightAltIcon /> */}
                <img
                  src={ICONS.PREVIOUSICON}
                  alt="prev"
                  style={{
                    width: "35px",
                    height: "35px",
                    filter: !isNight ? "invert(0.6)" : "invert(0.4)",
                    transform: "rotate(180deg)",
                  }}
                />
              </div>
              <div onClick={() => setCurrentScreen("filter")}>
                {/* <ArrowRightAltIcon /> */}
                <img
                  src={ICONS.PREVIOUSICON}
                  alt="prev"
                  style={{
                    width: "35px",
                    height: "35px",
                    filter: !isNight ? "invert(0.6)" : "invert(0.4)",
                  }}
                />
              </div>
            </div>
          </>
        )}
        {currentScreen === "filter" && (
          <>
            <div style={{ color: "#666", width: "100%", minHeight: "120px" }}>
              <p style={{ color: "#ffa300", fontSize: "1.2rem" }}>
                2. Configure filter
              </p>{" "}
              <div style={{ color: !isNight ? "#aebcc9" : "#666" }}>
                <ul>
                  <li>
                    Drag and Drop the filter to the workflow you want to use.
                  </li>
                </ul>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                position: "absolute",
                bottom: "50px",
                left: "45%",
                margin: "2rem 0 0",
              }}
            >
              <div onClick={() => setCurrentScreen("source")}>
                {/* <ArrowRightAltIcon /> */}
                <img
                  src={ICONS.PREVIOUSICON}
                  alt="prev"
                  style={{
                    width: "35px",
                    height: "35px",
                    filter: !isNight ? "invert(0.6)" : "invert(0.4)",
                    transform: "rotate(180deg)",
                  }}
                />
              </div>
              <div onClick={() => setCurrentScreen("model")}>
                {/* <ArrowRightAltIcon /> */}
                <img
                  src={ICONS.PREVIOUSICON}
                  alt="prev"
                  style={{
                    width: "35px",
                    height: "35px",
                    filter: !isNight ? "invert(0.6)" : "invert(0.4)",
                  }}
                />
              </div>
            </div>
          </>
        )}{" "}
        {currentScreen === "model" && (
          <>
            <div style={{ color: "#666", width: "100%", minHeight: "250px" }}>
              <p style={{ color: "#4cda89", fontSize: "1.2rem" }}>
                3. Configure model
              </p>{" "}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{ width: "50%", color: !isNight ? "#aebcc9" : "#666" }}
                  onClick={() => handleOpen("Emotional")}
                >
                  Emotional
                </div>
                <div
                  style={{ width: "50%", color: !isNight ? "#aebcc9" : "#666" }}
                  onClick={() => handleOpen("Personal Information")}
                >
                  Personal Information
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{ width: "50%", color: !isNight ? "#aebcc9" : "#666" }}
                  onClick={() => handleOpen("Text analysis")}
                >
                  Text analysis
                </div>
                <div
                  style={{ width: "50%", color: !isNight ? "#aebcc9" : "#666" }}
                  onClick={() => handleOpen("Profanity")}
                >
                  Profanity
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{ width: "50%", color: !isNight ? "#aebcc9" : "#666" }}
                  onClick={() => handleOpen("Hate Speech")}
                >
                  Hate Speech
                </div>
                <div
                  style={{ width: "50%", color: !isNight ? "#aebcc9" : "#666" }}
                  onClick={() => handleOpen("Script")}
                >
                  Script
                </div>
              </div>{" "}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{ width: "50%", color: !isNight ? "#aebcc9" : "#666" }}
                  onClick={() => handleOpen("Textual Abuse")}
                >
                  Textual Abuse
                </div>
                <div
                  style={{ width: "50%", color: !isNight ? "#aebcc9" : "#666" }}
                  onClick={() => handleOpen("Insult Detection")}
                >
                  Insult Detection
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{ width: "50%", color: !isNight ? "#aebcc9" : "#666" }}
                  onClick={() => handleOpen("Sexual Harrasment")}
                >
                  Sexual Harrasment
                </div>
                <div
                  style={{ width: "50%", color: !isNight ? "#aebcc9" : "#666" }}
                  onClick={() => handleOpen("Sentiment Analysis")}
                >
                  Sentiment Analysis
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{ width: "50%", color: !isNight ? "#aebcc9" : "#666" }}
                  onClick={() => handleOpen("Diversity Inclusion")}
                >
                  Diversity Inclusion
                </div>
                <div
                  style={{ width: "50%", color: !isNight ? "#aebcc9" : "#666" }}
                  onClick={() => handleOpen("Sexism Detection")}
                >
                  Sexism Detection
                </div>
              </div>
            </div>{" "}
            <div style={{ color: isNight ? "#aebcc9" : "#666" }}>
              <ul>
                <li>configure one of the model from the sidebar.</li>
                <li>Drag and Drop the tile you configured to the workflow.</li>
              </ul>
            </div>
            <div
              style={{
                display: "flex",
                position: "absolute",
                bottom: "50px",
                left: "45%",
                justifyContent: "center",
                margin: "2rem 0 0",
              }}
            >
              <div onClick={() => setCurrentScreen("filter")}>
                {/* <ArrowRightAltIcon /> */}
                <img
                  src={ICONS.PREVIOUSICON}
                  alt="prev"
                  style={{
                    width: "35px",
                    height: "35px",
                    filter: isNight ? "invert(0.6)" : "invert(0.4)",
                    transform: "rotate(180deg)",
                  }}
                />
              </div>
              <div onClick={() => setCurrentScreen("action")}>
                {/* <ArrowRightAltIcon /> */}
                <img
                  src={ICONS.PREVIOUSICON}
                  alt="prev"
                  style={{
                    width: "35px",
                    height: "35px",
                    filter: isNight ? "invert(0.6)" : "invert(0.4)",
                  }}
                />
              </div>
            </div>{" "}
          </>
        )}
        {currentScreen === "action" && (
          <>
            <div style={{ color: "#666", width: "100%", minHeight: "120px" }}>
              <p style={{ color: "#ff1431", fontSize: "1.2rem" }}>
                4. Configure action
              </p>{" "}
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{
                    width: "50%",
                    color: "#666",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  onClick={() => handleOpen("Email")}
                >
                  <img
                    style={{ width: "100px", margin: "auto" }}
                    src={ICONS.EMAIL}
                    alt="email"
                  />
                  <p
                    style={{
                      textAlign: "center",
                      color: isNight ? "#aebcc9" : "666",
                    }}
                  >
                    Email
                  </p>
                </div>
                <div
                  style={{
                    width: "50%",
                    color: "#666",
                    display: "flex",
                    flexDirection: "column",
                  }}
                  onClick={() => handleOpen("Servicenow")}
                >
                  <img
                    style={{ width: "100px", margin: "auto" }}
                    src={ICONS.SERVICENOW}
                    alt="servicenow"
                  />
                  <p
                    style={{
                      textAlign: "center",
                      color: isNight ? "#aebcc9" : "666",
                    }}
                  >
                    Servicenow
                  </p>
                </div>
              </div>
            </div>
            <div style={{ color: isNight ? "#aebcc9" : "#666" }}>
              <ul>
                <li>configure one of the action from the sidebar.</li>
                <li>Drag and Drop the tile you configured to the workflow.</li>
              </ul>
            </div>
            <div
              style={{
                display: "flex",
                position: "absolute",
                bottom: "50px",
                left: "45%",
                justifyContent: "center",
                margin: "2rem 0 0",
              }}
            >
              <div onClick={() => setCurrentScreen("model")}>
                {/* <ArrowRightAltIcon /> */}
                <img
                  src={ICONS.PREVIOUSICON}
                  alt="prev"
                  style={{
                    width: "35px",
                    height: "35px",
                    filter: isNight ? "invert(0.6)" : "invert(0.4)",
                    transform: "rotate(180deg)",
                  }}
                />
              </div>
              <div onClick={() => setCurrentScreen("source")}>
                {/* <ArrowRightAltIcon /> */}
                <img
                  src={ICONS.PREVIOUSICON}
                  alt="prev"
                  style={{
                    width: "35px",
                    height: "35px",
                    filter: isNight ? "invert(0.6)" : "invert(0.4)",
                  }}
                />
              </div>
            </div>
          </>
        )}
      </div>
      <Modal
        open={open}
        onClose={tutorialModalCloseHandler}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <TutorialSubModal
          open={open}
          handleClose={tutorialModalCloseHandler}
          title="Delete Workflow"
          selectedModel={selectedModel}
          // handleDelete={onDeleteWorkflow}
        />
      </Modal>
    </div>
  );
};

export default TutorialModal;
