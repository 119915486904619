import React, { useState, useEffect, createRef } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import { useDispatch, useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@material-ui/core";
import "./index.css";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { useScreenshot } from "use-react-screenshot";
import * as clipboard from "clipboard-polyfill";
import { base64StringToBlob } from "blob-util";

function EmotionDist({
  emotionPercenatge,
  currentWorkflowID,
  currentFilter,
  customDate,
}) {
  const [clipProcessing, setClipProcessing] = useState(false);

  async function clip(b64Data) {
    const item = new clipboard.ClipboardItem({
      "image/png": base64StringToBlob(b64Data.split(",")[1], "image/png"),
    });

    await clipboard.write([item]);
  }

  const emoDistRef = createRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const getImage = async () => {
    setClipProcessing(true);
    await takeScreenshot(emoDistRef.current);
    setClipProcessing(false);
    image && clip(image);
  };

  useEffect(() => {
    image && clip(image);
  }, [image]);

  const [demoEmotions, setDemoEmotions] = useState({
    joy: 0,
    anger: 0,
    sad: 0,
    surprise: 0,
    fear: 0,
    neutral: 0,
  });

  useEffect(() => {
    const tempEmo = {
      joy: 31,
      anger: 12,
      sad: 28,
      surprise: 16,
      fear: 14,
      neutral: 9,
    };
    setTimeout(() => {
      setDemoEmotions(tempEmo);
    }, 1500);
  }, []);

  const dispatch = useDispatch();

  const filters = useSelector((data) => data.dashboard.filters);

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  const [smallNumber1, setSmallNumber1] = useState(0);
  const [smallNumber2, setSmallNumber2] = useState(0);
  const [smallNumber3, setSmallNumber3] = useState(0);

  useEffect(() => {
    setSmallNumber1(getRandomInt(-3, 5));
    setSmallNumber2(getRandomInt(-3, 5));
    setSmallNumber3(getRandomInt(-3, 5));
  }, [filters, currentFilter, customDate]);

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  return (
    <div
      ref={emoDistRef}
      style={{
        height: "450px",
        borderRadius: "12px",
        backgroundColor: !isNight ? "#2A2953" : "#fff",
        margin: "20px 0 0",
        padding: "0 0.5rem",
      }}
    >
      <div
        style={{
          width: "92%",
          position: "relative",
          top: "15px",
          margin: "1rem",
          display: "flex",
          justifyContent: "space-between",
          margin: "1rem 1rem 0",
        }}
      >
        <p
          style={{
            // left: "-8px",
            margin: "0",
            textAlign: "left",
            fontWeight: 500,
            color: !isNight ? "#fff" : "#484848",
          }}
        >
          Topic Emotions
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
               Emotional Breakdown of Messages for the Selected Topic
              </span>
            }
          >
            <InfoIcon
              style={{
                fontSize: "1rem",
                filter: "opacity(0.7)",
                position: "relative",
                top: "3px",
              }}
            />
          </Tooltip>
        </p>
        <Tooltip
          title={
            <span
              style={{
                fontSize: "0.8rem",
                textAlign: "center",
              }}
            >
              Copy to clipboard
            </span>
          }
        >
          {clipProcessing ? (
            <CircularProgress size={16} color="inherit" />
          ) : (
            <ContentCopyOutlinedIcon
              onClick={getImage}
              style={{
                fontSize: "1.2rem",
                cursor: clipProcessing ? "wait" : "pointer",
              }}
            />
          )}
        </Tooltip>
      </div>
      <div
        style={{
          
          padding: "10px",
          height: "95%",
        }}
      >
        <div>
          <div>
            <p
              style={{
                textAlign: "left",
                fontSize: "1rem",
                color: !isNight ? "#aebcc9" : "#5B5B5B",
                margin: "10px 0",
              }}
            >
              Joy
            </p>
            <div>
              <ProgressBar
                completed={
                  isDemo
                    ? demoEmotions.joy + smallNumber2
                    : emotionPercenatge.total !== 0
                    ? parseFloat(
                        (
                          (emotionPercenatge.joy / emotionPercenatge.total) *
                          100
                        ).toFixed(2)
                      )
                    : 0
                }
                className="emo-progress-wrapper"
                labelClassName="emo-progress-label"
                baseBgColor={!isNight ? "#212047" : "#D5F2F1"}
                labelColor={!isNight ? "#aebcc9" : "#6E7C84"}
              />
            </div>
          </div>
          <div>
            <p
              style={{
                textAlign: "left",
                fontSize: "1rem",
                color: !isNight ? "#aebcc9" : "#5B5B5B",
                margin: "10px 0",
              }}
            >
              Anger
            </p>

            <div>
              <ProgressBar
                completed={
                  isDemo
                    ? demoEmotions.anger + smallNumber1
                    : emotionPercenatge.total !== 0
                    ? parseFloat(
                        (
                          (emotionPercenatge.anger / emotionPercenatge.total) *
                          100
                        ).toFixed(2)
                      )
                    : 0
                }
                className="emo-progress-wrapper"
                labelClassName="emo-progress-label"
                baseBgColor={!isNight ? "#212047" : "#D5F2F1"}
                labelColor={!isNight ? "#aebcc9" : "#6E7C84"}
              />
            </div>
          </div>
          <div>
            <p
              style={{
                textAlign: "left",
                fontSize: "1rem",
                color: !isNight ? "#aebcc9" : "#5B5B5B",
                margin: "10px 0",
              }}
            >
              Fear
            </p>
            <div>
              <ProgressBar
                completed={
                  isDemo
                    ? demoEmotions.fear - smallNumber1
                    : emotionPercenatge.total !== 0
                    ? parseFloat(
                        (
                          (emotionPercenatge.fear / emotionPercenatge.total) *
                          100
                        ).toFixed(2)
                      )
                    : 0
                }
                className="emo-progress-wrapper"
                labelClassName="emo-progress-label"
                baseBgColor={!isNight ? "#212047" : "#D5F2F1"}
                labelColor={!isNight ? "#aebcc9" : "#6E7C84"}
              />
            </div>
          </div>
          <div>
            <p
              style={{
                textAlign: "left",
                fontSize: "1rem",
                color: !isNight ? "#aebcc9" : "#5B5B5B",
                margin: "10px 0",
              }}
            >
              Sadness
            </p>
            <div>
              <ProgressBar
                completed={
                  isDemo
                    ? demoEmotions.sad
                    : emotionPercenatge.total !== 0
                    ? parseFloat(
                        (
                          (emotionPercenatge.sad / emotionPercenatge.total) *
                          100
                        ).toFixed(2)
                      )
                    : 0
                }
                className="emo-progress-wrapper"
                labelClassName="emo-progress-label"
                baseBgColor={!isNight ? "#212047" : "#D5F2F1"}
                labelColor={!isNight ? "#aebcc9" : "#6E7C84"}
              />
            </div>
          </div>
          <div>
            <p
              style={{
                textAlign: "left",
                fontSize: "1rem",
                color: !isNight ? "#aebcc9" : "#5B5B5B",
                margin: "10px 0",
              }}
            >
              Surprise
            </p>{" "}
            <div>
              <ProgressBar
                completed={
                  isDemo
                    ? demoEmotions.surprise - smallNumber2
                    : emotionPercenatge.total !== 0
                    ? parseFloat(
                        (
                          (emotionPercenatge.surprise /
                            emotionPercenatge.total) *
                          100
                        ).toFixed(2)
                      )
                    : 0
                }
                className="emo-progress-wrapper"
                labelClassName="emo-progress-label"
                baseBgColor={!isNight ? "#212047" : "#D5F2F1"}
                labelColor={!isNight ? "#aebcc9" : "#6E7C84"}
              />
            </div>
          </div>
          <div>
            <p
              style={{
                textAlign: "left",
                fontSize: "1rem",
                color: !isNight ? "#aebcc9" : "#5B5B5B",
                margin: "10px 0",
              }}
            >
              Neutral
            </p>{" "}
            <div>
              <ProgressBar
                completed={
                  isDemo
                    ? demoEmotions.neutral - smallNumber2
                    : emotionPercenatge.total !== 0
                    ? emotionPercenatge.neutral
                      ? parseFloat(
                          (
                            (emotionPercenatge.neutral /
                              emotionPercenatge.total) *
                            100
                          ).toFixed(2)
                        )
                      : 0
                    : 0
                }
                className="emo-progress-wrapper"
                labelClassName="emo-progress-label"
                baseBgColor={!isNight ? "#212047" : "#D5F2F1"}
                labelColor={!isNight ? "#aebcc9" : "#6E7C84"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmotionDist;
