import { toast } from "react-toastify";
import api from "../axiosConfig";
import axios from "axios";
import { SessionOut } from "../../utilities/dashboardUtils";

export function* getPublicHolidays(action) {
  let resp;
  let holidays = [];
  yield axios
    .get(
      `https://date.nager.at/api/v3/PublicHolidays/${
        new Date().getFullYear() - 1
      }/${action.payload.countryCode}`
    )
    .then((response) => {
      if (response.status === 404) {
        toast.error("Data not found");
      } else if (response.status === 400) {
        SessionOut();
      } else {
        resp = response;
        holidays.push(...response.data);
      }
    })
    .catch((error) => {
      return error;
    });
  yield axios
    .get(
      `https://date.nager.at/api/v3/PublicHolidays/${new Date().getFullYear()}/${
        action.payload.countryCode
      }`
    )
    .then((response) => {
      if (response.status === 404) {
        toast.error("Data not found");
      } else if (response.status === 400) {
        SessionOut();
      } else {
        resp = response;
        holidays.push(...response.data);
      }
    })
    .catch((error) => {
      return error;
    });
  return holidays;
}
