import React from "react";

const BuildTutModal = ({ handleClose, setNextOpen, nextOpen }) => {

  const setNextOpenTrue = () => {
    setNextOpen(true);
  }

  const handleNextStep = () => {
    handleClose();
    setNextOpenTrue();
  }

  const handleSkip = () => {
    handleClose();
    localStorage.setItem('skipped', true);
  }

  return (
    <>
      <div style={{
          width: "0",
          height: "0",
          borderLeft: "5px solid transparent",
          borderRight: "5px solid transparent",
          borderBottom: "10px solid #1b1834",
          position: "absolute",
          marginTop: "105px",
          left: "96%",
        }}>
      </div>
      <div
        className="addChartsContainer"
        style={{
          width: "24vw",
          marginTop: "115px",
          position: "absolute",
          left: "72%",
        }}
      >
        <div style={{ color: "#fff", fontSize: "1rem" }}>Build</div>
        <p style={{ color: "#ddd"}}>Click on this button to build your workflow. Wait for sometime to complete the build and then head over to Investigation tab to get detailed results.</p>
        <div className="btnOptions" style={{ marginTop: "0px" }}>
          <div className="submitBtn">
            <div
              className="rangeBtn"
              type="submit"
              onClick={handleNextStep} >
              <div className="btnText">NEXT >></div>
            </div>
          </div>
          <div className="submitBtn">
            <div
              className="rangeBtn"
              type="submit"
              onClick={handleSkip} >
              <div className="btnText">SKIP >>>></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BuildTutModal;
