import React from "react";
import { useHistory } from "react-router-dom";
import "../styles/global.css";

const SuccessfulRegistration = () => {
  const history = useHistory();
  setTimeout(() => {
    history.push("/");
  }, 2000);
  return (
    <div className="sucessRegisterContainer">
      <div className="successMessage">Registration successful</div>
      <div className="successMesageDetails">
        Redirecting to login page in few seconds...
      </div>
    </div>
  );
};

export default SuccessfulRegistration;
