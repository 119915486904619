import React from "react";

const IntroTutModal = ({ handleClose, setCurrentScreen, currentScreen }) => {
  const handleNextStep = () => {
    handleClose();
    setCurrentScreen("logic");
  };

  const handleSkip = () => {
    handleClose();
    localStorage.setItem("skipped", true);
  };

  return (
    <>
      <div
        style={{
          width: "0",
          height: "0",
          borderLeft: "5px solid transparent",
          borderRight: "5px solid transparent",
          borderBottom: "10px solid #1b1834",
          position: "absolute",
          marginTop: "45px",
          left: "25%",
        }}
      ></div>
      <div
        className="addChartsContainer"
        style={{
          width: "24vw",
          marginTop: "55px",
          position: "absolute",
          left: "12.5%",
        }}
      >
        <div style={{ color: "#484848", fontSize: "1rem" }}>
          Welcome to the Insights Page!
        </div>
        <p style={{ color: "#484848" }}>
          Here, you can access all the key insights, including{" "}
          <b>
            Morale, Engagement, Retention Risk, eNPS (Employee Net Promoter
            Score), Key Topics, Culture Metrics
          </b>
        </p>
        <div className="btnOptions" style={{ marginTop: "0px" }}>
          <div className="submitBtn">
            <div className="rangeBtn" type="submit" onClick={handleNextStep}>
              <div className="btnText">{"NEXT >>"}</div>
            </div>
          </div>
          <div className="submitBtn">
            <div className="rangeBtn" type="submit" onClick={handleSkip}>
              <div className="btnText">{"SKIP >>>>"}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IntroTutModal;
