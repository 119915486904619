import logo from "./assets/trupulse-logo.png";
import logoWhite from "./assets/trupulse-white.png";
import serviceNow from "./assets/icons/servicenow.png";
import discordShot from "./assets/img/discordshot.PNG";
import speedometer from "./assets/img/speedometer.png";
import cloudDummy from "./assets/img/cloudDummy.png";
import moralCompare from "./assets/img/moralCompare.png";
import moralDist from "./assets/img/moralDist.png";
import moralTrend from "./assets/img/moralTrend.png";
import sentimentTrend from "./assets/img/sentimentTrend.png";
import emotionCard from "./assets/img/emotionCard.png";
import sentimentScore from "./assets/img/sentimentScore.png";
import previous from "./assets/icons/next.png";
import pin from "./assets/icons/pin.png";
import unpin from "./assets/icons/unpin.png";
import next from "./assets/icons/next.png";
import ExcelIcon from "./assets/icons/excelIcon.svg";
import slack from "./assets/icons/slack.svg";
import discord from "./assets/icons/discord.svg";
import zoomLogo from "./assets/icons/zoom-logo-gray.svg";
import teams from "./assets/icons/teams.svg";
import archive from "./assets/icons/upload.svg";
import intellectual from "./assets/icons/intellectual.svg";
import pll from "./assets/icons/pll.svg";
import sms from "./assets/icons/sms.svg";
import alert from "./assets/icons/alert.svg";
import email from "./assets/icons/email.svg";
import notification from "./assets/icons/notification.svg";
import chatBtn from "./assets/chatBTN.png";
import guageGradient from "./assets/img/Ellipse-12.png";
import glassDoor from "./assets/icons/glassdoor.svg";
import blind from "./assets/icons/blind.svg";
const telegram = "https://chec-front.s3.amazonaws.com/icons/telegram.svg";
const messenger = "https://chec-front.s3.amazonaws.com/icons/messenger.svg";
const skype = "https://chec-front.s3.amazonaws.com/icons/skype.svg";
const zoom = "https://chec-front.s3.amazonaws.com/icons/zoom.svg";
const channel = "https://chec-front.s3.amazonaws.com/icons/channel.svg";
const emotional = "https://chec-front.s3.amazonaws.com/icons/emotional.svg";
const phishing = "https://chec-front.s3.amazonaws.com/icons/phishing.svg";

// const pll = "https://chec-front.s3.amazonaws.com/icons/pll.svg";
// const intellectual =
//   "https://chec-front.s3.amazonaws.com/icons/intellectual.svg";
// const sms = "https://chec-front.s3.amazonaws.com/icons/sms.svg";
// const notification =
//   "https://chec-front.s3.amazonaws.com/icons/notification.svg";
// const alert = "https://chec-front.s3.amazonaws.com/icons/alert.svg";
// const email = "https://chec-front.s3.amazonaws.com/icons/email.svg";
// const archive = "https://chec-front.s3.amazonaws.com/icons/upload.svg";
// const slack = "https://chec-front.s3.amazonaws.com/icons/slack.svg";
// const teams = "https://chec-front.s3.amazonaws.com/icons/teams.png";
// const discord = "https://chec-front.s3.amazonaws.com/icons/discord.png";

export const ICONS = {
  EXCELICON: ExcelIcon,
  logo: logo,
  logoWhite: logoWhite,
  SERVICENOW: serviceNow,
  TELEGRAM: telegram,
  ZOOM: zoom,
  TEAMS: teams,
  DISCORD: discord,
  ZOOM: zoomLogo,
  DISCORDSHOT: discordShot,
  speedometer: speedometer,
  cloudDummy: cloudDummy,
  sentimentTrend: sentimentTrend,
  sentimentScore: sentimentScore,
  moralCompare: moralCompare,
  emotionCard: emotionCard,
  moralDist: moralDist,
  moralTrend: moralTrend,
  MESSENGER: messenger,
  SKYPE: skype,
  CHANNEL: channel,
  EMOTIONAL: emotional,
  PHISHING: phishing,
  PLL: pll,
  INTELLECTUAL: intellectual,
  SMS: sms,
  NOTIFICATION: notification,
  ALERT: alert,
  EMAIL: email,
  ARCHIVE: archive,
  SLACK: slack,
  PREVIOUSICON: previous,
  PIN: pin,
  UNPIN: unpin,
  NEXTICON: next,
  CHATBTN: chatBtn,
  GuageGradient: guageGradient,
  GlassDoor: glassDoor,
  Blind: blind,
};
