import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#fff",
    padding: "0!important",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    "& div": {
      color: "#fff",
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    marginTop: 20,
    color: "rgba(0, 0, 0, 0.87)!important",
    borderBlockColor: "#6352C6",
  },
  input: {
    color: "#fff",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    padding: 0,
  },
}));

export default function DeleteModal({
  open,
  handleClose,
  //   title,
  handleDelete,
}) {
  const classes = useStyles();

  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <DialogContent classes={{ root: classes.root }}>
        <div
          className="popUp"
          style={{
            padding: "30px 20px",
            backgroundColor: !isNight ? "#212047" : "#e5e5e5",
          }}
        >
          {/* <div className="popUpTitle" style={{ marginBottom: "15px" }}>
            {title}
          </div> */}
          <div
            style={{
              marginBottom: "25px",
              color: !isNight ? "#aebcc9" : "#000",
            }}
          >
            Are you sure you want to delete this Workflow?
          </div>
          <div className="btnOptions">
            <div className="cancelBtn" onClick={handleClose}>
              <div className="rangeBtn">
                <ClearIcon style={{ color: !isNight ? "#aebcc9" : "#000" }} />
                <div
                  className="btnText"
                  style={{ color: !isNight ? "#aebcc9" : "#000" }}
                >
                  NO
                </div>
              </div>
            </div>
            <div className="submitBtn" onClick={handleDelete}>
              <div className="rangeBtn" type="submit">
                <CheckIcon />
                <div className="btnText">YES</div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
