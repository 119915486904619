import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  GET_CULTURE_GOAL_SUCCESS,
  GET_ENGAGEMENT_GOAL_SUCCESS,
  GET_ENPS_GOAL_SUCCESS,
  GET_MORALE_GOAL_SUCCESS,
  GET_RETENTION_GOAL_SUCCESS,
} from "../../redux/Dashboard/Dashboard.types";

function SetGoal({ metric, handleClose }) {
  const dispatch = useDispatch();
  const goals = useSelector((data) => data.dashboard.goals);

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const [moraleGoal, setMoraleGoal] = useState(goals.morale ? goals.morale : 0);
  const [overallEngagementGoal, setOverallEngagementGoal] = useState(
    goals.engagement?.overall ? goals.engagement?.overall : 0
  );
  const [textEngagementGoal, setTextEngagementGoal] = useState(
    goals.engagement?.message ? goals.engagement?.message : 0
  );
  const [userEngagementGoal, setUserEngagementGoal] = useState(
    goals.engagement?.user ? goals.engagement?.user : 0
  );
  const [topicEngagementGoal, setTopicEngagementGoal] = useState(
    goals.engagement?.topic ? goals.engagement?.topic : 0
  );
  const [retentionGoal, setRetentionGoal] = useState(
    goals.retention ? goals.retention : 0
  );
  const [overallENPSGoal, setOverallENPSGoal] = useState(
    goals.enps?.overall ? goals.enps?.overall : 0
  );
  const [topicENPSGoal, setTopicENPSGoal] = useState(
    goals.enps?.topic ? goals.enps?.topic : 0
  );
  const [surveyENPSGoal, setSurveyENPSGoal] = useState(
    goals.enps?.survey ? goals.enps?.survey : 0
  );
  const [overallCultureGoal, setOverallCultureGoal] = useState(
    goals.culture?.overall ? goals.culture?.overall : 0
  );
  const [subcultureGoal, setSubCultueGoal] = useState(
    goals.culture?.individual ? goals.culture?.individual : 0
  );

  const handleSave = () => {
    if (isDemo) {
      if (metric === "morale") {
        dispatch({
          type: GET_MORALE_GOAL_SUCCESS,
          payload: parseInt(moraleGoal),
        });
        handleClose();
      } else if (metric === "engagement") {
        dispatch({
          type: GET_ENGAGEMENT_GOAL_SUCCESS,
          payload: {
            overall: parseInt(overallEngagementGoal),
            user: parseInt(userEngagementGoal),
            message: parseInt(textEngagementGoal),
            topic: parseInt(topicEngagementGoal),
          },
        });
        handleClose();
      } else if (metric === "retention") {
        dispatch({
          type: GET_RETENTION_GOAL_SUCCESS,
          payload: parseInt(retentionGoal),
        });
        handleClose();
      } else if (metric === "ENPS") {
        dispatch({
          type: GET_ENPS_GOAL_SUCCESS,
          payload: {
            overall: parseInt(overallENPSGoal),
            topic: parseInt(topicENPSGoal),
            survey: parseInt(surveyENPSGoal),
          },
        });
        handleClose();
      } else if (metric === "culture") {
        dispatch({
          type: GET_CULTURE_GOAL_SUCCESS,
          payload: {
            overall: parseInt(overallCultureGoal),
            individual: parseInt(subcultureGoal),
          },
        });
        handleClose();
      }
    }
  };

  return (
    <Box>
      {metric === "morale" && (
        <Box
          sx={{
            margin: "1rem 0",
          }}
        >
          <Stack direction="column" spacing={2}>
            <Typography> Morale Goal</Typography>
            <TextField
              fullWidth
              id="question-text"
              type="number"
              label="Goal"
              variant="outlined"
              value={moraleGoal}
              onChange={(e) => setMoraleGoal(e.target.value)}
            />
          </Stack>
        </Box>
      )}

      {metric === "engagement" && (
        <Box
          sx={{
            margin: "1rem 0",
          }}
        >
          <Stack direction="row" spacing={2}>
            <Stack direction="column" spacing={2}>
              <Typography> Overall Engagement Goal</Typography>
              <TextField
                fullWidth
                id="question-text"
                type="number"
                label="Goal"
                variant="outlined"
                value={overallEngagementGoal}
                onChange={(e) => setOverallEngagementGoal(e.target.value)}
              />
            </Stack>
            <Stack direction="column" spacing={2}>
              <Typography> Text Engagement Goal</Typography>
              <TextField
                fullWidth
                id="question-text"
                type="number"
                label="Goal"
                variant="outlined"
                value={textEngagementGoal}
                onChange={(e) => setTextEngagementGoal(e.target.value)}
              />
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Stack direction="column" spacing={2}>
              <Typography> User Engagement Goal</Typography>
              <TextField
                fullWidth
                id="question-text"
                type="number"
                label="Goal"
                variant="outlined"
                value={userEngagementGoal}
                onChange={(e) => setUserEngagementGoal(e.target.value)}
              />
            </Stack>
            <Stack direction="column" spacing={2}>
              <Typography> Topic Engagement Goal</Typography>
              <TextField
                fullWidth
                id="question-text"
                type="number"
                label="Goal"
                variant="outlined"
                value={topicEngagementGoal}
                onChange={(e) => setTopicEngagementGoal(e.target.value)}
              />
            </Stack>
          </Stack>
        </Box>
      )}

      {metric === "retention" && (
        <Box
          sx={{
            margin: "1rem 0",
          }}
        >
          <Stack direction="column" spacing={2}>
            <Typography> Retention Risk Goal</Typography>
            <TextField
              fullWidth
              id="question-text"
              type="number"
              label="Goal"
              variant="outlined"
              value={retentionGoal}
              onChange={(e) => setRetentionGoal(e.target.value)}
            />
          </Stack>
        </Box>
      )}

      {metric === "ENPS" && (
        <Box
          sx={{
            margin: "1rem 0",
          }}
        >
          <Stack direction="row" spacing={2}>
            <Stack direction="column" spacing={2}>
              <Typography> Overall eNPS Goal</Typography>
              <TextField
                fullWidth
                id="question-text"
                type="number"
                label="Goal"
                variant="outlined"
                value={overallENPSGoal}
                onChange={(e) => setOverallENPSGoal(e.target.value)}
              />
            </Stack>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Stack direction="column" spacing={2}>
              <Typography> Topic eNPS Goal</Typography>
              <TextField
                fullWidth
                id="question-text"
                type="number"
                label="Goal"
                variant="outlined"
                value={topicENPSGoal}
                onChange={(e) => setTopicENPSGoal(e.target.value)}
              />
            </Stack>
            <Stack direction="column" spacing={2}>
              <Typography> Survey eNPS Goal</Typography>
              <TextField
                fullWidth
                id="question-text"
                type="number"
                label="Goal"
                variant="outlined"
                value={surveyENPSGoal}
                onChange={(e) => setSurveyENPSGoal(e.target.value)}
              />
            </Stack>
          </Stack>
        </Box>
      )}

      {metric === "culture" && (
        <Box
          sx={{
            margin: "1rem 0",
          }}
        >
          <Stack direction="row" spacing={2}>
            <Stack direction="column" spacing={2}>
              <Typography> Overall Culture Goal</Typography>
              <TextField
                fullWidth
                id="question-text"
                type="number"
                label="Goal"
                variant="outlined"
                value={overallCultureGoal}
                onChange={(e) => setOverallCultureGoal(e.target.value)}
              />
            </Stack>
            <Stack direction="column" spacing={2}>
              <Typography> Sub-Culture Goal</Typography>
              <TextField
                fullWidth
                id="question-text"
                type="number"
                label="Goal"
                variant="outlined"
                value={subcultureGoal}
                onChange={(e) => setSubCultueGoal(e.target.value)}
              />
            </Stack>
          </Stack>
        </Box>
      )}

      <Stack direction="row" spacing={2} justifyContent="end">
        {metric !== "engagement" &&
          metric !== "ENPS" &&
          metric !== "culture" && (
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          )}
        <Button
          onClick={handleSave}
          style={{
            background: "#D06283",
            color: "#fff",
          }}
          variant="contained"
        >
          SET
        </Button>
      </Stack>
    </Box>
  );
}

export default SetGoal;
