import { Box, TextField, Typography } from "@material-ui/core";
import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Button } from "@material-ui/core";

const issueTypes = [
  "Bug Report",
  "Feature Request",
  "Account Issues",
  "Payment/Billing",
  "Technical Support",
  "Feedback and Suggestions",
  "Integration Issues",
  "Data and Privacy",
  "Survey and Insights Issues",
  "Morale Insights Issue",
  "Focus Group Feedback",
  "Action Plan Suggestions",
  "Chat Integration Issues (Slack/Teams)",
  "Other"
];

function SupportPage() {

  const [currentMetric, setCurrentMetric] = useState("Bug Report");
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [issue, setIssue] = useState("")

  return <Box style={{ padding: "2rem", background: "#f2f2f2", height: "85vh" }}>
    <Box style={{ margin: "1rem 0" }}>
      <Typography style={{ textAlign: "left", fontSize: "1.2rem" }}>Name</Typography>
      <TextField
        fullWidth
        style={{ background: "#fff" }}
        variant="outlined"
        value={name}
        onChange={(event) => setName(event.target.value)}
      /></Box>
    <Box style={{ margin: "1rem 0" }}>
      <Typography style={{ textAlign: "left", fontSize: "1.2rem" }}>Email</Typography>
      <TextField
        fullWidth
        style={{ background: "#fff" }}
        variant="outlined"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      /></Box>
    <Box style={{ margin: "1rem 0" }}>
      <Typography style={{ textAlign: "left", fontSize: "1.2rem" }}>Issue</Typography>
      <Select style={{ background: "#fff", width: "100%", textAlign: "left" }}
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={currentMetric}
        label="Metric"
        onChange={(e) => {
          setCurrentMetric(e.target.value);
        }}
      >{issueTypes.map((issue) => <MenuItem value={issue}>{issue}</MenuItem>)}

      </Select></Box>
    <Box style={{ margin: "1rem 0" }}>
      <Typography style={{ textAlign: "left", fontSize: "1.2rem" }}>Description</Typography>
      <TextField
        fullWidth multiline
        rows={8}
        maxRows={8}
        style={{ background: "#fff", width: "100%" }}
        variant="outlined"
        value={email}
        onChange={(event) => setEmail(event.target.value)}
      /></Box>

    <Box style={{ margin: "1rem 0", display: "flex", justifyContent: "start" }}>
      <Button
        // onClick={handleSave}
        style={{
          background: "#D06283",
          color: "#fff",
          textAlign: "left"
        }}
        variant="contained"
      >
        Submit
      </Button></Box>
  </Box>;
}

export default SupportPage;
