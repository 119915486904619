import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const Step4Modal = ({ handleClose, setCurrentStep }) => {
  const history = useHistory();

  const handleNextStep = () => {
    // handleClose();
    // setInsightAccordion(false);
    // history.push("/dashboard/setting");
    setCurrentStep(5);
    // history.push("/dashboard/overview");
  };

  const handleSkip = () => {
    handleClose();
    localStorage.setItem("skipped", true);
  };

  return (
    <>
      <div
        className="addChartsContainer"
        style={{
          width: "24vw",
          marginTop: "55px",
          position: "absolute",
          left: "45%",
          top: "17%",
        }}
      >
        <div style={{ color: "#484848", fontSize: "1rem" }}>
          <b>Configuration Section</b>
        </div>
        <p style={{ color: "#484848" }}>
          In this section, you can connect your workspaces to TruPulse and also
          manually upload your data.
        </p>
        <div className="btnOptions" style={{ marginTop: "0px" }}>
          <div className="submitBtn">
            <div className="rangeBtn" type="submit" onClick={handleNextStep}>
              <div className="btnText">{"NEXT >>"}</div>
            </div>
          </div>
          <div className="submitBtn">
            <div className="rangeBtn" type="submit" onClick={handleSkip}>
              <div className="btnText">{"SKIP >>>>"}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step4Modal;
