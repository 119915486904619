import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";
import CheckIcon from "@material-ui/icons/Check";
import { Input } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import CircularProgress from "@mui/material/CircularProgress";
import DownloadIcon from "@mui/icons-material/Download";
import SlackUpload from "./SlackUpload";

import _ from "lodash";
import {
  SAVE_ZOOM_TRANSCRIPT,
  ZOOM_FILE_UPLOAD_SUCCESS,
  VERIFY_TOKEN,
  GET_ZOOM_TOKEN,
  GET_ACCESS_TOKEN,
  GET_CHANNELS,
  SAVING_ACCESS_TOKEN,
  BUILD_WORKFLOW,
  GET_USER_DETAILS,
  SAVE_WORKFLOW,
} from "../../../../redux/Dashboard/Dashboard.types";
import axios from "axios";
import api from "../../../../services/axiosConfig";
import ZoomLogin from "./KeyModal";
import SlackLogin from "./SlackLogin";
const useStyles = makeStyles((theme) => ({
  root: {
    color: "#fff",
    padding: "0!important",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    "& div": {
      color: "#fff",
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    marginTop: 20,
    color: "rgba(0, 0, 0, 0.87)!important",
    borderBlockColor: "#6352C6",
  },
  input: {
    color: "#fff",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    padding: 0,
  },
}));

export default function GlassdoorModal({ open, handleClose, title }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const userID = JSON.parse(localStorage.getItem("userData")).userId;
  const userToken = JSON.parse(localStorage.getItem("userData")).usertoken;
  const slackKey = useSelector((data) => data.dashboard.slackKey);
  const userDetails = useSelector((data) => data.dashboard.userDetails);

  const accessTokenSave = useSelector((data) => data.dashboard.accessTokenSave);
  const saveStatus = useSelector((data) => data.dashboard.saveStatus);

  const [dateTime, setDateTime] = useState(
    new Date(new Date().getTime() - 12 * 60 * 60 * 1000)
  );
  const [method, setMethod] = useState("link");
  const [selectedRec, setSelectedRec] = useState(null);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const onFailed = () => {
    !isDemo &&
      dispatch({
        type: VERIFY_TOKEN,
        payload: { app: "slack", userID },
      });
  };
  const onSuccess = () => {
    toast.success("Oauth Successful");
  };

  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);
  const uploadedChannels = useSelector(
    (data) => data.dashboard.uploadSourceStatus
  );
  const uploadStatus = useSelector((data) => data.dashboard.uploadSourceStatus);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSaveWorkflow = () => {
    if (!isDemo) {
      const tempChannel = [];
      const work = [
        {
          id: "dndnode_0",
          type: "input",
          position: {
            x: 50.77777099609375,
            y: 150.99999237060547,
          },
          targetPosition: "left",
          sourcePosition: "right",
          data: {
            label: "Archive",
          },
          style: {
            backgroundRepeat: "no-repeat",
            backgroundSize: "40% 40%",
            backgroundOrigin: "content-box",
            fontSize: "14px",
            color: "#aebcc9",
            fontWeight: "600",
            borderRadius: "8px ",
            background:
              "url(/static/media/upload.dda4abc2.svg) no-repeat center center",
            border: "3px solid #5FA7F5",
          },
        },
      ];
      uploadedChannels?.channels?.forEach((item, id) => {
        tempChannel.push({
          id: `dndnode_${id + 1}`,
          type: "default",
          position: {
            x: 265.77777099609375,
            y: (id + 1) * 180.99999237060547,
          },
          targetPosition: "left",
          sourcePosition: "right",
          data: {
            label: item,
          },
          style: {
            backgroundRepeat: "no-repeat",
            backgroundSize: "40% 40%",
            backgroundOrigin: "content-box",
            fontSize: "14px",
            color: "#aebcc9",
            fontWeight: "600",
            borderRadius: "8px ",
            background:
              "url(https://chec-front.s3.amazonaws.com/icons/channel.svg) no-repeat center center",
            border: "3px solid #FFA300",
          },
        });
        tempChannel.push({
          source: "dndnode_0",
          sourceHandle: null,
          target: `dndnode_${id + 1}`,
          targetHandle: null,
          arrowHeadType: "arrowclosed",
          animated: true,
          style: {
            stroke: "red",
          },
          id: "reactflow__edge-dndnode_0null-dndnode_1null",
        });
      });
      work.push(...tempChannel);
      const Models = [
        "Emotional",
        "Personal Information",
        "Profanity",
        "Hate Speech",
        "Textual Abuse",
        "Insult Detection",
        "Sexual Harrasment",
      ];
      const tempModels = [];
      Models.forEach((item, id) => {
        tempModels.push({
          id: `dndnode_${tempChannel.length + id + 1}`,
          type: "default",
          position: {
            x: 510.77777099609375,
            y: (id + 1) * 180.99999237060547,
          },
          targetPosition: "left",
          sourcePosition: "right",
          data: {
            label: item,
          },
          style: {
            backgroundRepeat: "no-repeat",
            backgroundSize: "40% 40%",
            backgroundOrigin: "content-box",
            fontSize: "14px",
            color: "#aebcc9",
            fontWeight: "600",
            borderRadius: "8px ",
            background:
              "url(/static/media/intellectual.bf40f944.svg) no-repeat center center",
            border: "3px solid #4cda89",
          },
        });
      });
      work.push(...tempModels);
      work.push({
        id: `dndnode_${work.length + 1}`,
        type: "default",
        position: {
          x: 752.8959970605468,
          y: 180.0627866931152,
        },
        targetPosition: "left",
        sourcePosition: "right",
        data: {
          label: "Email",
        },
        style: {
          backgroundRepeat: "no-repeat",
          backgroundSize: "40% 40%",
          backgroundOrigin: "content-box",
          fontSize: "14px",
          color: "#aebcc9",
          fontWeight: "600",
          borderRadius: "8px ",
          background:
            "url(/static/media/email.ab571ebd.svg) no-repeat center center",
          border: "3px solid #FF0892",
        },
      });
      dispatch({
        type: SAVE_WORKFLOW,
        payload: {
          workflowID: userID + Math.round(new Date().getTime() / 1000),
          workflow: work,
          dm: uploadStatus.dms,
          workflowName: uploadStatus.fileName,
          userID,
          clientID: JSON.parse(localStorage.getItem("userData")).userId,
          fileName: uploadStatus.fileName,
          model_config: {
            emotionData: [
              {
                id: "dndnode_5",
                data: {
                  emotionData: {
                    joy: 2,
                    sadness: 3,
                    love: 3,
                    anger: 2,
                    fear: 2,
                    surprise: 3,
                    neutral: 3,
                  },
                },
              },
            ],
            personalInfoData: [
              {
                id: "dndnode_6",
                data: {
                  personalInfoData: [
                    "cardDetails",
                    "DOB",
                    "drivingLicence",
                    "email",
                    "IPaddress",
                    "otp",
                    "MACaddress",
                    "passportNumber",
                    "phoneNumber",
                    "SSN",
                    "VIN",
                    "ZIP",
                    "userName",
                    "password",
                  ],
                },
              },
            ],
            profanityData: [
              {
                id: "dndnode_7",
                data: {
                  profanity: 3,
                },
              },
            ],
            hateSpeechData: [
              {
                id: "dndnode_8",
                data: {
                  hateSpeechData: {
                    any: null,
                  },
                },
              },
            ],
            scriptData: [],
            textAbuseData: [
              {
                id: "dndnode_9",
                data: {
                  textualAbuseData: {
                    positive: 1,
                    negative: 2,
                    neutral: 1,
                    toxic: 3,
                    severe_toxic: 1,
                    obscene: 2,
                    threat: 2,
                    insult: 0,
                    identity_hate: 4,
                  },
                },
              },
            ],
            sexualHarassmentData: [
              {
                id: "dndnode_11",
                data: {
                  SexualHarassmentData: {
                    any: null,
                  },
                },
              },
            ],
            RacismData: [],
            SentimentData: [],
            insultData: [
              {
                id: "dndnode_10",
                data: {
                  insultData: {
                    any: null,
                  },
                },
              },
            ],
            sexismData: [],
            diversityData: [],
          },
          action_config: {
            email: [
              {
                id: "dndnode_12",
                data: {
                  toEmail: "test@scanta.io",
                  emailSubject: "test",
                  emailBody: "test",
                },
              },
            ],
            sms: [],
            alert: [],
            notification: [],
          },
        },
      });
    }
  };

  const buildWorkflow = (workflowID) => {
    dispatch({
      type: BUILD_WORKFLOW,
      payload: {
        workflowID: workflowID,
        userID,
        buildOnce: true,
      },
    });
    handleClose();
  };

  useEffect(() => {
    saveStatus?.data?.data?.workflowID &&
      buildWorkflow(saveStatus?.data?.data?.workflowID);
  }, [saveStatus]);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <DialogContent classes={{ root: classes.root }} style={{ width: 550 }}>
        <div
          className="popUp"
          style={{
            backgroundColor: !isNight ? "#212047" : "#e5e5e5",
          }}
        >
          <div
            className="popUpTitle"
            style={{ color: !isNight ? "#aebcc9" : "#000" }}
          >
            {title}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {method === "link" && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "500px",
                  }}
                >
                  <div style={{ width: "100%", margin: "1rem 2rem" }}>
                    <div className="inputContainer">
                      <label className="formLabels form_label">Link</label>
                      <input
                        type="text"
                        name="first-name"
                        placeholder="Enter your company's glassdoor URL"
                      />
                    </div>
                  </div>
                </div>
                <div className="btnOptions">
                  <div className="cancelBtn" onClick={handleClose}>
                    <div className="rangeBtn">
                      <ArrowBackIosIcon
                        style={{
                          color: !isNight ? "#aebcc9" : "#000",
                          fontSize: "1rem",
                        }}
                      />
                      <div
                        className="btnText"
                        style={{ color: !isNight ? "#aebcc9" : "#000" }}
                      >
                        Back
                      </div>
                    </div>{" "}
                  </div>
                  <div className="submitBtn">
                    <div
                      className="rangeBtn"
                      type="submit"
                      onClick={handleClose}
                    >
                      {isLoading ? (
                        <CircularProgress
                          size="1rem"
                          color="inherit"
                          sx={{ margin: "0 0.5rem" }}
                        />
                      ) : (
                        <CheckIcon />
                      )}
                      <div className="btnText">Save</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
