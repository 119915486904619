import { toast } from "react-toastify";
import api from "../axiosConfig";
import { SessionOut } from "../../utilities/dashboardUtils";

export function* getExportPdf(action) {
  let resp;
  yield api
    .post(
      `/createPdf`,
      action.payload,
      { responseType: 'blob' }
    )
    .then((response) => {
      if (response.status === 404) {
        toast.error("Data not found");
      } else if (response.status === 400) {
        SessionOut();
      } else {
        resp = response;
        const pdfBlob = new Blob([response.data], { type: "application/pdf" });

        const blobUrl = window.URL.createObjectURL(pdfBlob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.setAttribute("download", "Overview");
        link.click();
        link.remove();
        URL.revokeObjectURL(blobUrl);
      }
    })
    .catch((error) => {
      toast.error(error.message);
      return error;
    });
  return resp;
}
