import React, { useState } from "react";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import CloseIcon from "@mui/icons-material/Close";
import AskSection from "./AskSection";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  borderRadius: "12px",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

function AskSectionPopup({ handleClose }) {
  const dispatch = useDispatch();

  return (
    <Box sx={style}>
      <HighlightOffIcon onClick={handleClose}
        style={{
          position: "absolute",
          right: "15px",
          top: "15px",
          fontSize: "20px",
          cursor: "pointer",
        }}
      />
      <AskSection />
    </Box>
  );
}

export default AskSectionPopup;
