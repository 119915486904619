import React, { useState, useEffect, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Chart from "react-apexcharts";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@material-ui/core";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { useScreenshot } from "use-react-screenshot";
import * as clipboard from "clipboard-polyfill";
import { base64StringToBlob } from "blob-util";
import { demoGlassdoorReviews } from "../../demoData";
import moment from "moment";

function SubRatingTrend({ currentWorkflowID, textAbuseData, isZoom }) {
  const [clipProcessing, setClipProcessing] = useState(false);
  async function clip(b64Data) {
    const item = new clipboard.ClipboardItem({
      "image/png": base64StringToBlob(b64Data.split(",")[1], "image/png"),
    });

    await clipboard.write([item]);
  }
  const subRatingTrendRef = createRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const getImage = async () => {
    setClipProcessing(true);
    await takeScreenshot(subRatingTrendRef.current);
    setClipProcessing(false);
    image && clip(image);
  };

  useEffect(() => {
    image && clip(image);
  }, [image]);

  const filters = useSelector((data) => data.dashboard.filters);
  const [isLoading, setIsLoading] = useState(true);

  const [workLifeTrend, setWorkLifeTrend] = useState([]);
  const [cultureValueTrend, setCultureValueTrend] = useState([]);
  const [careerTrend, setCareerTrend] = useState([]);
  const [benefitsTrend, setBenefitsTrend] = useState([]);
  const [managementTrend, setManagementTrend] = useState([]);

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const [smallNumber, setSmallNumber] = useState(0);
  useEffect(() => {
    setSmallNumber(getRandomInt(-10, 10));
  }, [filters]);

  function getRandomInt(min, max) {
    return (Math.floor(Math.random() * (max - min + 1)) + min).toFixed(1);
  }

  useEffect(() => {
    var worklifeData = [];
    var cultureData = [];
    var managementData = [];
    var careerData = [];
    var benefitsData = [];

    worklifeData = [
      { date: "2024-02-10", averageRating: getRandomInt(1, 4) },
      { date: "2024-01-10", averageRating: getRandomInt(1, 4) },
      { date: "2023-12-10", averageRating: getRandomInt(1, 4) },
      { date: "2023-11-10", averageRating: getRandomInt(1, 4) },
    ]
      ?.sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1))
      .map((item) => {
        return {
          y: item.averageRating,
          x: new Date(item.date),
          color: "#3A53BC",
          markerBorderColor: "#3A53BC",
        };
      });
    cultureData = [
      { date: "2024-02-10", averageRating: getRandomInt(1, 4) },
      { date: "2024-01-10", averageRating: getRandomInt(1, 4) },
      { date: "2023-12-10", averageRating: getRandomInt(1, 4) },
      { date: "2023-11-10", averageRating: getRandomInt(1, 4) },
    ]
      ?.sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1))
      .map((item) => {
        return {
          y: item.averageRating,
          x: new Date(item.date),
          color: "#3A53BC",
          markerBorderColor: "#3A53BC",
        };
      });
    managementData = [
      { date: "2024-02-10", averageRating: getRandomInt(1, 4) },
      { date: "2024-01-10", averageRating: getRandomInt(1, 4) },
      { date: "2023-12-10", averageRating: getRandomInt(1, 4) },
      { date: "2023-11-10", averageRating: getRandomInt(1, 4) },
    ]
      ?.sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1))
      .map((item) => {
        return {
          y: item.averageRating,
          x: new Date(item.date),
          color: "#3A53BC",
          markerBorderColor: "#3A53BC",
        };
      });
    careerData = [
      { date: "2024-02-10", averageRating: getRandomInt(1, 4) },
      { date: "2024-01-10", averageRating: getRandomInt(1, 4) },
      { date: "2023-12-10", averageRating: getRandomInt(1, 4) },
      { date: "2023-11-10", averageRating: getRandomInt(1, 4) },
    ]
      ?.sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1))
      .map((item) => {
        return {
          y: item.averageRating,
          x: new Date(item.date),
          color: "#3A53BC",
          markerBorderColor: "#3A53BC",
        };
      });
    benefitsData = [
      { date: "2024-02-10", averageRating: getRandomInt(1, 4) },
      { date: "2024-01-10", averageRating: getRandomInt(1, 4) },
      { date: "2023-12-10", averageRating: getRandomInt(1, 4) },
      { date: "2023-11-10", averageRating: getRandomInt(1, 4) },
    ]
      ?.sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1))
      .map((item) => {
        return {
          y: item.averageRating,
          x: new Date(item.date),
          color: "#3A53BC",
          markerBorderColor: "#3A53BC",
        };
      });
    setWorkLifeTrend(worklifeData);
    setCultureValueTrend(cultureData);
    setManagementTrend(managementData);
    setCareerTrend(careerData);
    setBenefitsTrend(benefitsData);
  }, [demoGlassdoorReviews, filters]);

  const publicHolidays = useSelector((data) => data.dashboard.publicHolidays);
  const calenderEvents = useSelector((data) => data.dashboard.calenderEvents);
  const publicHolidaySwitch = useSelector(
    (data) => data.dashboard.publicHolidaySwitch
  );
  const [annotations, setAnnotations] = useState([]);

  // Add Graph annotations for public holidays
  useEffect(() => {
    const temp = [];
    publicHolidaySwitch &&
      publicHolidays.forEach((holiday) => {
        temp.push({
          x: new Date(holiday.date).getTime(),
          borderColor: "#d06283",
          label: {
            borderRadius: 4,
            position: "bottom",
            style: {
              color: "#fff",
              opacity: "0.3",
              fontSize: "13px",
              background: "rgba(208, 98, 131, 0.8)",
            },
            text: holiday.name,
          },
        });
      });
    calenderEvents.forEach((item) => {
      temp.push({
        x: new Date(item.date).getTime(),
        borderColor: item.color,
        label: {
          borderRadius: 4,
          position: "bottom",
          style: {
            color: "#fff",
            opacity: "0.3",
            fontSize: "13px",
            background: item.color,
          },
          text: item.name,
        },
      });
    });
    setAnnotations(temp);
  }, [publicHolidays, calenderEvents]);

  var options = {
    annotations: {
      xaxis: annotations,
    },
    chart: {
      type: "line",
      toolbar: {
        show: false,
        tools: {
          download: false, // <== line to add
        },
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#1F3BB3"],
    stroke: {
      show: true,
      curve: "straight",
      lineCap: "butt",
      colors: undefined,
      width: 2,
      dashArray: 0,
    },
    markers: {
      size: [2, 3],
      strokeWidth: 1,
      strokeOpacity: 0.3,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: isNight ? "light" : "dark",
        shadeIntensity: 0,
        opacityFrom: 0,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          colors: !isNight ? ["#727272"] : ["#666"],
          cssClass: "apexcharts-yaxis-label",
        },
        formatter: function (value) {
          return moment(value).format("MMM yyyy");
        },
      },
    },
    yaxis: {
      min: 0,
      max: 5,
      tickAmount: 5,
      seriesName: "Subratings",
      axisTicks: {
        show: true,
        color: !isNight ? "#727272" : "#666",
      },
      labels: {
        style: {
          colors: !isNight ? ["#727272"] : ["#666"],
          cssClass: "apexcharts-yaxis-label",
        },
        formatter: function (value) {
          return parseInt(value);
        },
      },
      title: {
        text: "Rating",
        style: {
          color: !isNight ? "#727272" : "#666",
          fontSize: "12px",
        },
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      x: {
        show: true,
        format: "MMM yyyy",
      },
      y: {
        show: true,
        formatter: function (val) {
          return `${val}`;
        },
        title: {
          formatter: function (seriesName) {
            return seriesName;
          },
        },
      },
    },
  };

  var series = [
    {
      name: "Work/Life Balance",
      color: "#F05919",
      data: workLifeTrend,
    },
    {
      name: "Culture and Values",
      color: "#D06283",
      data: cultureValueTrend,
    },
    {
      name: "Management",
      color: "#578826",
      data: managementTrend,
    },
    {
      name: "Career Opportunities",
      color: "#0E71AA",
      data: careerTrend,
    },
    {
      name: "Compensation and Benefits",
      color: "#607D8B",
      data: benefitsTrend,
    },
  ];

  return (
    <div
      ref={subRatingTrendRef}
      style={{
        height: "360px",
        borderRadius: "12px",
        backgroundColor: !isNight ? "#2A2953" : "#fff",
        position: "relative",
      }}
    >
      <div
        style={{
          width: "97.5%",
          position: "relative",
          top: "15px",
          margin: "1rem",
          display: "flex",
          justifyContent: "space-between",
          margin: "1rem",
        }}
      >
        <p
          style={{
            margin: "0",
            textAlign: "left",
            fontWeight: 500,
            zIndex: 3,
            color: !isNight ? "#fff" : "#484848",
          }}
        >
          Culture Ratings Over Time
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                Sub Rating over time
              </span>
            }
          >
            <InfoIcon
              style={{
                fontSize: "1rem",
                filter: "opacity(0.7)",
                position: "relative",
                top: "2px",
              }}
            />
          </Tooltip>
        </p>
        <Tooltip
          title={
            <span
              style={{
                fontSize: "0.8rem",
                textAlign: "center",
              }}
            >
              Copy to clipboard
            </span>
          }
        >
          {clipProcessing ? (
            <CircularProgress size={16} color="inherit" />
          ) : (
            <ContentCopyOutlinedIcon
              onClick={getImage}
              style={{
                fontSize: "1.2rem",
                cursor: clipProcessing ? "wait" : "pointer",
              }}
            />
          )}
        </Tooltip>
      </div>
      {demoGlassdoorReviews.status === "success" || (isDemo && !isLoading) ? (
        <div>
          <div className="morale-trend-container">
            <Chart
              options={options}
              series={series}
              type="area"
              width="100%"
              height="290px"
            />
          </div>
        </div>
      ) : demoGlassdoorReviews.status === "fail" || currentWorkflowID === "" ? (
        <div style={{ position: "relative", top: "100px" }}>
          <ErrorOutlineIcon style={{ color: "#94989f" }} />
          <p style={{ margin: "0", color: "#94989f" }}>
            {currentWorkflowID === "" ? "Select a workflow!" : "No Data found!"}
          </p>
        </div>
      ) : (
        <>
          <div style={{ margin: "2rem" }}>
            <Stack spacing={1}>
              <Skeleton
                variant="text"
                width={"100%"}
                height={"40px"}
                style={{ position: "relative", top: "10px" }}
              />
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={"220px"}
                style={{ position: "relative", top: "10px" }}
              />
            </Stack>
          </div>
        </>
      )}
    </div>
  );
}

export default SubRatingTrend;
