import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { DataGrid } from "@mui/x-data-grid";
import { GridColDef, GridApi, GridCellValue } from "@mui/x-data-grid";
import { useSelector, useDispatch } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import {
  GridToolbarExport,
  gridClasses,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { CSVLink } from "react-csv";
import { GET_HRIS_USERS } from "../../../redux/Dashboard/Dashboard.types";

const Hrisheaders = [
  { label: "userID", key: "userID" },
  { label: "User Name", key: "username" },
  { label: "Email", key: "email" },
  { label: "gender", key: "gender" },
  { label: "Age", key: "age" },
  { label: "ethnicity", key: "ethnicity" },
  { label: "marital_status", key: "marital_status" },
  { label: "department", key: "department" },
  { label: "region", key: "region" },
  { label: "location", key: "location" },
  { label: "experience", key: "experience" },
  { label: "management_level", key: "management_level" },
  { label: "income", key: "income" },
  { label: "joining_date", key: "joining_date" },
  { label: "exit_date", key: "exit_date" },
];

const Hrisdata = [
  {
    userID: "U02B5bf859b",
    username: "MusicJunkie_99",
    email: "MusicJunkie_99@scanta.io",
    gender: "male",
    age: 32,
    ethnicity: "Hispanic",
    marital_status: "single",
    department: "Research & Development",
    region: "Europe",
    location: "Home Office",
    experience: 10,
    management_level: "Individual Contributor",
    income: "196342",
    joining_date: "2012-06-13",
    exit_date: "",
  },
];
export default function HrisTable() {
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);
  const dispatch = useDispatch();

  const useStyles = makeStyles((theme) => ({
    root: {
      color: !isNight ? "#aebcc9 !important" : "#000! important",
      fontSize: 13,
      border: "none",
      backgroundColor: !isNight ? "#212047" : "#F6F7FC",
      fontWeight: "600",
    },
    tableRoot: {
      color: !isNight ? "#aebcc9" : "#000 !important",
      fontSize: 13,
      border: "none",
      backgroundColor: !isNight ? "#212047" : "#F6F7FC",
      fontWeight: "500",
      "& .MuiDataGrid-columnHeaderWrapper": {
        color: !isNight ? "#aebcc9" : "#000!important",
        backgroundColor: "#e5e5e5 !important",
      },
      "& .MuiTablePagination-root": {
        color: !isNight ? "#aebcc9" : "#000!important",
      },
      "& .Mui-disabled": {
        color: "rgba(255,255,255,0.5)!important",
      },
      "& .MuiIconButton-root": {
        color: "#6352C6!important",
      },
      "MuiDataGrid-columnHeaderTitle": {
        color: "#6352C6!important",
      },
    },

    container: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
      "& div": {
        color: "#fff",
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
      marginTop: 20,
      color: "rgba(0, 0, 0, 0.87)!important",
      borderBlockColor: "#6352C6",
    },
    input: {
      color: "#fff",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    paper: {
      padding: 0,
    },
    columnHeader: {
      backgroundColor: !isNight ? "#17123B" : "#e5e5e5",
      color: !isNight ? "#fff" : "#000",
    },
    cell: {
      "& .MuiDataGrid-sortIcon": {
        fill: "white!important",
        opacity: 1,
      },
      "& .MuiDataGrid-cellCheckbox": {
        borderColor: "white",
        color: "white",
      },
      "& 	.MuiDataGrid-iconSeparator": {
        fill: "white",
      },
    },
  }));

  const classes = useStyles();

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        className={gridClasses.toolbarContainer}
        style={{ display: "flex", justifyContent: "space-between" }}
      >
        <div style={{ color: !isNight ? "#aebcc9" : "#000" }}>
          <CSVLink
            data={Hrisdata}
            headers={Hrisheaders}
            filename={"sample-hris-data.csv"}
            style={{
              color: !isNight ? "#aebcc9" : "#000",
              textDecoration: "none",
            }}
          >
            <DownloadIcon
              style={{ fontSize: "1rem", position: "relative", top: "4px" }}
            />
            Download Sample
          </CSVLink>
        </div>
        <GridToolbarExport style={{ color: !isNight ? "#aebcc9" : "#000" }} />
      </GridToolbarContainer>
    );
  }

  const columns: GridColDef[] = [
    { field: "id", headerName: "S. No.", width: 120 },
    { field: "userID", headerName: "User ID", width: 120 },
    {
      field: "username",
      headerName: "User Name",
      width: 150,
      editable: false,
    },
    {
      field: "email",
      headerName: "Email",
      width: 150,
      editable: false,
    },
    {
      field: "gender",
      headerName: "Gender",
      width: 150,
      editable: false,
    },
    {
      field: "age",
      headerName: "Age",
      width: 120,
      editable: false,
    },
    {
      field: "ethnicity",
      headerName: "Ethnicity/Race",
      width: 140,
      editable: false,
    },
    {
      field: "marital_status",
      headerName: "Marital Status",
      width: 130,
      editable: false,
    },
    {
      field: "department",
      headerName: "Department",
      width: 160,
      editable: false,
    },
    {
      field: "region",
      headerName: "Region",
      width: 160,
      editable: false,
    },
    {
      field: "location",
      headerName: "Location",
      width: 160,
      editable: false,
    },
    {
      field: "experience",
      headerName: "Experience",
      width: 160,
      editable: false,
    },
    {
      field: "management_level",
      headerName: "Management Level",
      width: 160,
      editable: false,
    },
    {
      field: "income",
      headerName: "Income",
      width: 160,
      editable: false,
    },
    {
      field: "joining_date",
      headerName: "Joining Date",
      width: 160,
      editable: false,
    },
    {
      field: "exit_date",
      headerName: "Exit Date",
      width: 160,
      editable: false,
    },
  ];

  const [rows, setRows] = useState([]);

  const hrisUsers = useSelector((data) => data.dashboard.hrisUsers);

  useEffect(() => {
    dispatch({
      type: GET_HRIS_USERS,
      payload: {},
    });
  }, []);

  useEffect(() => {
    const updatedRow = [];
    hrisUsers.forEach((user, idx) => {
      updatedRow.push({
        id: idx + 1,
        userID: user.userID,
        username: user.userName,
        email: user.email,
        gender: user.gender,
        age: user.age,
        ethnicity: user.ethnicity,
        marital_status: user.marital_status,
        department: user.department,
        region: user.region,
        location: user.location,
        experience: user.experience,
        management_level: user.management_level,
        income: user.income,
        joining_date: user.joining_date,
        exit_date: user.exit_date,
      });
    });

    setRows(updatedRow);
  }, [hrisUsers]);

  return (
    <div>
      <DataGrid
        components={{
          Toolbar: CustomToolbar,
        }}
        rows={rows}
        columns={columns}
        classes={{
          root: classes.tableRoot,
          columnHeader: classes.columnHeader,
          columnHeaderWrapper: classes.columnHeaderWrapper,
          cell: classes.cell,
        }}
        autoHeight
      />
    </div>
  );
}
