import { Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import ChatTranscript from "../ChatTranscript";
import { demoFocusGroup } from "../../demoSurveys";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import axios from "axios";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Stack from "@mui/material/Stack";
import moment from "moment";
import {
  CREATE_FOCUS_GROUP_ACTION_PLAN,
  GET_ALL_FOCUS_GROUPS,
} from "../../../../../redux/Dashboard/Dashboard.types";
function FocusGroup({ focusGroupID }) {
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const dispatch = useDispatch();
  const focusGroups = isDemo
    ? demoFocusGroup
    : useSelector((data) => data.dashboard.focusGroups);

  const currentFocusGroup = focusGroups.filter(
    (item) => item._id === focusGroupID
  )[0];

  const [reportContent, setReportContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const generateReport = async () => {
    setIsLoading(true);
    const res = await axios.post("https://api.trupulse.ai/focusGroup/report", {
      focusGroupID: focusGroupID,
    });
    if (res.status === 200) {
      !isDemo && dispatch({ type: GET_ALL_FOCUS_GROUPS });
    }
    setIsLoading(false);
  };

  const createActionPlan = () => {
    !isDemo &&
      dispatch({
        type: CREATE_FOCUS_GROUP_ACTION_PLAN,
        payload: {
          focusGroupID: focusGroupID,
        },
      });
  };
  return (
    <Box style={{ margin: "2rem" }}>
      <Box sx={{ display: "flex", justifyContent: "start" }}>
        <Link
          to={`/dashboard/feedback/focus-group-res/`}
          style={{ textDecoration: "none", color: "#484848" }}
        >
          <Button
            variant="outlined"
            style={{
              padding: "0.5rem 0",
              border: "2px solid #D06283",
              color: "#D06283",
              margin: "1rem",
            }}
          >
            <ArrowBackIcon />
          </Button>
        </Link>
      </Box>
      <Box
        sx={{
          padding: "1rem 1rem",
          background: "#f9f9f9",
          border: "1px solid #dddddd",
          borderRadius: "4px",
          margin: "1rem 0",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography
            style={{
              fontSize: "1.5rem",
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            {currentFocusGroup.display?.name
              ? currentFocusGroup.display?.name
              : currentFocusGroup?.topic}
          </Typography>
          {
            <Typography
              style={{
                fontSize: "1.2rem",
                fontWeight: "normal",
                textAlign: "left",
              }}
            >
              {/* {`${currentSurvey.section}: ${currentSurvey.change}`} */}

              {currentFocusGroup.anonymous ? "Anonymous" : "Not Anonymous"}
            </Typography>
          }
          {currentFocusGroup?.display?.targetGroup && (
            <Typography
              style={{
                fontSize: "1.2rem",
                fontWeight: "normal",
                textAlign: "left",
              }}
            >
              Target Group: {currentFocusGroup?.display?.targetGroup}
            </Typography>
          )}
          <Typography
            style={{
              fontSize: "1.2rem",
              fontWeight: "normal",
              textAlign: "left",
            }}
          >
            Participants: {currentFocusGroup?.users?.length}
          </Typography>

          <Typography
            style={{
              fontSize: "1.2rem",
              fontWeight: "normal",
              textAlign: "left",
            }}
          >
            Topics:{" "}
            {currentFocusGroup === "Insults"
              ? "Types of Insults Experienced, Frequency and Severity, Workplace Culture and Communication"
              : "Current State of Morale, Factors Influencing Morale,Communication and Feedback"}
          </Typography>
        </Box>
        <Box>
          <Typography style={{ textAlign: "right", fontSize: "1.1rem" }}>
            Date/Time:{" "}
            {moment(currentFocusGroup?.startTime).format("MMM DD yyyy HH:MM")}
          </Typography>

          <Typography style={{ textAlign: "right", fontSize: "1.1rem" }}>
            Duration:{" "}
            {moment(currentFocusGroup?.endTime).diff(
              moment(currentFocusGroup?.startTime),
              "minutes"
            )}{" "}
            Minutes
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{ marginTop: "2rem", display: "flex", justifyContent: "center" }}
      >
        <Box>
          {currentFocusGroup.report && (
            <Box
              style={{
                background: "#f9f9f9",
                border: "1px solid #dddddd",
                borderRadius: "4px",
                margin: "1rem 0",
                textAlign: "left",
                padding: " 0.5rem 1rem",
              }}
            >
              <Markdown>
                {currentFocusGroup.report.includes("<br>")
                  ? currentFocusGroup.report.replaceAll("<br>", "\n")
                  : currentFocusGroup.report}
              </Markdown>
              <Box style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  onClick={createActionPlan}
                  variant="contained"
                  style={{
                    background: "#D06283",
                    color: "#fff",
                    margin: "0 0 1rem",
                    cursor: "pointer",
                    textTransform: "capitalize",
                  }}
                >
                  Create Action Plan
                </Button>
              </Box>
            </Box>
          )}

          <ChatTranscript messages={currentFocusGroup.messages.slice(1)} />
          <Box style={{ display: "flex", justifyContent: "end" }}>
            {!currentFocusGroup.report && (
              <Button
                onClick={generateReport}
                variant="contained"
                style={{
                  background: "#D06283",
                  color: "#fff",
                  margin: "1rem 0",
                  cursor: "pointer",
                  textTransform: "capitalize",
                }}
              >
                Generate Report
              </Button>
            )}
          </Box>
        </Box>
      </Box>{" "}
    </Box>
  );
}

export default FocusGroup;
