import { toast } from "react-toastify";
import api from "../axiosConfig";
import { SessionOut } from "../../utilities/dashboardUtils";

// eslint-disable-next-line import/prefer-default-export
export function* uploadSource(action) {
  let resp;
  // const headers = {
  //   "Content-Type": "multipart/form-data",
  //   "x-access-token": localStorage.getItem("auth-token"),
  // };
  
  const formData = new FormData();
  formData.append("file", action.payload.file);
  formData.append("userID", action.payload.userID);
  yield api
    .post("/fileUpload", formData, {
      headers: {
        "Access-Control-Allow-Headers": "x-access-token",
        "x-access-token": localStorage.getItem("auth-token"),
      },
    })
    .then((response) => { 
      if (response.status === 404) {
        toast.error("Error in uploading file");
      } else if (response.status === 400) {
        SessionOut();
      } else {
        resp = response;
      }
    })
    .catch(() => {
      toast.error("Data not found");
    });
  return resp;
}
