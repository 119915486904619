import axios from "axios";

const instance = axios.create({
  baseURL: "https://api.trupulse.ai/",
  // baseURL: "http://localhost:80/",
});

export const reqHeaders = {
  headers: {
    "Access-Control-Allow-Headers": "x-access-token",
    "x-access-token": localStorage.getItem("auth-token"),
  },
};

export default instance;
