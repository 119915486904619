import { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes.js";
import { useDispatch, useSelector } from "react-redux";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Tooltip } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { ICONS } from "../../../../AppConfig";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { FormGroup, FormControlLabel, Checkbox } from "@material-ui/core/";

export const Card = ({
  id,
  text,
  index,
  moveCard,
  deleteTopic,
  selected,
  workflowChangeHandler,
}) => {
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  const style = {
    // borderBottom: '1px dashed gray',
    width: "100%",
    padding: " 0",
    marginBottom: ".5rem",
    backgroundColor: !isNight ? "#212047" : "white",
    cursor: "move",
    display: "flex",
    color: !isNight ? "#aebcc9" : "#666",
    justifyContent: "space-between",
    borderRadius: "5px",
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const filters = useSelector((data) => data.dashboard.filters);
  const level = useSelector((data) => data.dashboard.level);
  const department = useSelector((data) => data.dashboard.department);
  const currentSource = useSelector((data) => data.dashboard.currentSource);

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const filterChannels = useSelector((data) => data.dashboard.filterChannels);
  const [maritalChecked, setMaritalChecked] = useState("");

  const handleGenderChange = (value) => {
    var temp = filters.gender;
    if (filters.gender.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, gender: temp },
    });
  };

  const handleMaritalChange = (value) => {
    if (value !== filters.marital_status) {
      setMaritalChecked(value);
      dispatch({
        type: "SET_FILTERS",
        payload: { ...filters, marital_status: value },
      });
    } else {
      setMaritalChecked("");
      dispatch({
        type: "SET_FILTERS",
        payload: { ...filters, marital_status: "" },
      });
    }
  };

  const handleChannelChange = (value) => {
    // zoom-meeting
    if (value === "zoom-meeting") {
      workflowChangeHandler("overall-zoom");
    } else if (value === "glassdoor") {
      history.push("/dashboard/glassdoor");
      window.location.reload();
    }
    var temp = _.union(filters.channels);
    const adding = !filters.channels.includes(value);
    if (adding) {
      temp.push(value);
    } else {
      _.remove(temp, (x) => x === value);
    }

    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, channels: temp },
    });
  };

  const handleDeptChange = (value) => {
    var temp = filters.department;
    if (filters.department.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, department: temp },
    });
  };

  const handleRegionChange = (value) => {
    var temp = filters.region;
    if (filters.region.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, region: temp },
    });
  };

  const handleLocationChange = (value) => {
    var temp = filters.location;
    if (filters.location.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, location: temp },
    });
  };

  const handleServiceLengthChange = (value) => {
    var temp = filters.length_of_service;
    if (filters.length_of_service.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, length_of_service: temp },
    });
  };

  const handleMgmtLevelChange = (value) => {
    var temp = filters.management_level;
    if (filters.management_level.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, management_level: temp },
    });
  };

  const handleSalaryChange = (value) => {
    var temp = filters.income;
    if (filters.income.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, income: temp },
    });
  };

  const handleAgeChange = (value) => {
    var temp = filters.age;
    if (filters.age.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, age: temp },
    });
  };
  const handlePerformanceChange = (value) => {
    var temp = filters.performance;
    if (filters.performance.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, performance: temp },
    });
  };

  const handleRaceChange = (value) => {
    var temp = filters.ethinicity;
    if (filters.ethinicity.includes(value)) {
      temp = temp.filter((item) => item !== value);
    } else {
      temp.push(value);
    }
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, ethinicity: temp },
    });
  };

  const handleLocChange = (value) => {
    dispatch({ type: "SET_FILTERS", payload: { ...filters, location: value } });
  };

  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const checkVisibility = (level, department) => {
    if (level === "admin") {
      return "all";
    } else if (level === "employee") {
      return "none";
    } else if (level === "manager") {
      return isDemo ? "Accounting & Finance" : department;
    }
  };

  return (
    <>
      <div
        ref={ref}
        style={{
          ...style,
          opacity,
          backgroundColor:
            filters.keyTopic[0] === text
              ? "#D06283"
              : !isNight
              ? "#212047"
              : "#f6f7fc",
        }}
        data-handler-id={handlerId}
      >
        {level !== "employee" && (
          <div style={{ width: "100%" }}>
            {text === "Gender" ? (
              <Accordion sx={{ width: "100%", boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: !isNight
                          ? "#aebcc9 !important"
                          : "#343434 !important",
                        margin: "0 0.5rem",
                      }}
                    />
                  }
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: !isNight
                      ? "#aebcc9 !important"
                      : "#343434 !important",
                    width: "100%",
                    padding: "0 0.5rem",
                    textAlign: "left",
                  }}
                >
                  <p style={{ margin: "0 0.5rem" }}>Gender</p>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: "#999 !important",
                  }}
                >
                  <div style={{ position: "relative", top: "-1rem" }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.gender.includes("male")}
                            onClick={() => {
                              handleGenderChange("male");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Male
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.gender.includes("female")}
                            onClick={() => {
                              handleGenderChange("female");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Female
                          </p>
                        }
                      />

                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filters.gender.includes("non-binary")}
                              onClick={() => {
                                handleGenderChange("non-binary");
                              }}
                              style={{
                                color: !isNight ? "#aebcc9" : "#1554F6",
                              }}
                            />
                          }
                          label={
                            <p
                              style={{
                                fontSize: "14px",
                                color: !isNight ? "#aebcc9" : "#343434",
                                margin: "0",
                                textAlign: "left",
                              }}
                            >
                              Non-binary
                            </p>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filters.gender.includes("transgender")}
                              onClick={() => {
                                handleGenderChange("transgender");
                              }}
                              style={{
                                color: !isNight ? "#aebcc9" : "#1554F6",
                              }}
                            />
                          }
                          label={
                            <p
                              style={{
                                fontSize: "14px",
                                color: !isNight ? "#aebcc9" : "#343434",
                                margin: "0",
                                textAlign: "left",
                              }}
                            >
                              Transgender
                            </p>
                          }
                        />
                      </>
                    </FormGroup>
                  </div>
                </AccordionDetails>
              </Accordion>
            ) : text === "Channel" ? (
              <Accordion sx={{ width: "100%", boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: !isNight
                          ? "#aebcc9 !important"
                          : "#343434 !important",
                        margin: "0 0.5rem",
                      }}
                    />
                  }
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: !isNight
                      ? "#aebcc9 !important"
                      : "#343434 !important",
                    width: "100%",
                    textAlign: "left",
                    padding: "0 0.5rem",
                  }}
                >
                  <p style={{ margin: "0 0.5rem" }}>Channel</p>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: "#999 !important",
                  }}
                >
                  <div style={{ position: "relative", top: "-1rem" }}>
                    <FormGroup>
                      {isDemo && (
                        <>
                          {currentSource.includes("slack") && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={filters.channels.includes("all") || filters.channels.length === 0}
                                  onClick={() => {
                                    handleChannelChange("all");
                                  }}
                                  style={{
                                    color: !isNight ? "#aebcc9" : "#1554F6",
                                  }}
                                />
                              }
                              label={
                                <p
                                  style={{
                                    fontSize: "14px",
                                    color: !isNight ? "#aebcc9" : "#343434",
                                    margin: "0",
                                    textAlign: "left",
                                  }}
                                >
                                  {"All"}
                                </p>
                              }
                            />
                          )}

                          {(currentSource.includes("slack") ||
                            currentSource.includes("teams")) && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={filters.channels.includes(
                                    "marketing"
                                  )}
                                  onClick={() => {
                                    handleChannelChange("marketing");
                                  }}
                                  style={{
                                    color: !isNight ? "#aebcc9" : "#1554F6",
                                  }}
                                />
                              }
                              label={
                                <p
                                  style={{
                                    fontSize: "14px",
                                    color: !isNight ? "#aebcc9" : "#343434",
                                    margin: "0",
                                    textAlign: "left",
                                  }}
                                >
                                  {"Marketing"}
                                </p>
                              }
                            />
                          )}
                          {(currentSource.includes("slack") ||
                            currentSource.includes("teams")) && (
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={filters.channels.includes(
                                    "project-blackbird"
                                  )}
                                  onClick={() => {
                                    handleChannelChange("project-blackbird");
                                  }}
                                  style={{
                                    color: !isNight ? "#aebcc9" : "#1554F6",
                                  }}
                                />
                              }
                              label={
                                <p
                                  style={{
                                    fontSize: "14px",
                                    color: !isNight ? "#aebcc9" : "#343434",
                                    margin: "0",
                                    textAlign: "left",
                                  }}
                                >
                                  {"Project Blackbird"}
                                </p>
                              }
                            />
                          )}
                        </>
                      )}
                      {filterChannels.map((item) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filters.channels.includes(item)}
                              onClick={() => {
                                handleChannelChange(item);
                              }}
                              style={{
                                color: !isNight ? "#aebcc9" : "#1554F6",
                              }}
                            />
                          }
                          label={
                            <p
                              style={{
                                fontSize: "14px",
                                color: !isNight ? "#aebcc9" : "#343434",
                                margin: "0",
                                textAlign: "left",
                              }}
                            >
                              {item}
                            </p>
                          }
                        />
                      ))}
                    </FormGroup>
                  </div>
                </AccordionDetails>
              </Accordion>
            ) : text === "Department" ? (
              <Accordion sx={{ width: "100%", boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: !isNight
                          ? "#aebcc9 !important"
                          : "#343434 !important",
                        margin: "0 0.5rem",
                      }}
                    />
                  }
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: !isNight
                      ? "#aebcc9 !important"
                      : "#343434 !important",
                    width: "100%",
                    textAlign: "left",
                    padding: "0 0.5rem",
                  }}
                >
                  <p style={{ margin: "0 0.5rem" }}>Department</p>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: "#999 !important",
                  }}
                >
                  <div style={{ position: "relative", top: "-1rem" }}>
                    <FormGroup>
                      {(checkVisibility(level, department) ===
                        "Accounting & Finance" ||
                        checkVisibility(level, department) === "all") && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filters.department.includes(
                                "Accounting & Finance"
                              )}
                              onClick={() => {
                                handleDeptChange("Accounting & Finance");
                              }}
                              style={{
                                color: !isNight ? "#aebcc9" : "#1554F6",
                              }}
                            />
                          }
                          label={
                            <p
                              style={{
                                fontSize: "14px",
                                color: !isNight ? "#aebcc9" : "#343434",
                                margin: "0",
                                textAlign: "left",
                              }}
                            >
                              Accounting & Finance
                            </p>
                          }
                        />
                      )}

                      {(checkVisibility(level, department) === "Engineering" ||
                        checkVisibility(level, department) === "all") && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filters.department.includes(
                                "Engineering"
                              )}
                              onClick={() => {
                                handleDeptChange("Engineering");
                              }}
                              style={{
                                color: !isNight ? "#aebcc9" : "#1554F6",
                              }}
                            />
                          }
                          label={
                            <p
                              style={{
                                fontSize: "14px",
                                color: !isNight ? "#aebcc9" : "#343434",
                                margin: "0",
                                textAlign: "left",
                              }}
                            >
                              Engineering
                            </p>
                          }
                        />
                      )}
                      {(checkVisibility(level, department) ===
                        "Human Resources" ||
                        checkVisibility(level, department) === "all") && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filters.department.includes(
                                "Human Resources"
                              )}
                              onClick={() => {
                                handleDeptChange("Human Resources");
                              }}
                              style={{
                                color: !isNight ? "#aebcc9" : "#1554F6",
                              }}
                            />
                          }
                          label={
                            <p
                              style={{
                                fontSize: "14px",
                                color: !isNight ? "#aebcc9" : "#343434",
                                margin: "0",
                                textAlign: "left",
                              }}
                            >
                              Human Resources
                            </p>
                          }
                        />
                      )}
                      {(checkVisibility(level, department) ===
                        "Information Technology" ||
                        checkVisibility(level, department) === "all") && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filters.department.includes(
                                "Information Technology"
                              )}
                              onClick={() => {
                                handleDeptChange("Information Technology");
                              }}
                              style={{
                                color: !isNight ? "#aebcc9" : "#1554F6",
                              }}
                            />
                          }
                          label={
                            <p
                              style={{
                                fontSize: "14px",
                                color: !isNight ? "#aebcc9" : "#343434",
                                margin: "0",
                                textAlign: "left",
                              }}
                            >
                              Information Technology
                            </p>
                          }
                        />
                      )}
                      {(checkVisibility(level, department) === "Operations" ||
                        checkVisibility(level, department) === "all") && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filters.department.includes(
                                "Operations"
                              )}
                              onClick={() => {
                                handleDeptChange("Operations");
                              }}
                              style={{
                                color: !isNight ? "#aebcc9" : "#1554F6",
                              }}
                            />
                          }
                          label={
                            <p
                              style={{
                                fontSize: "14px",
                                color: !isNight ? "#aebcc9" : "#343434",
                                margin: "0",
                                textAlign: "left",
                              }}
                            >
                              Operations
                            </p>
                          }
                        />
                      )}
                      {(checkVisibility(level, department) ===
                        "Research & Development" ||
                        checkVisibility(level, department) === "all") && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filters.department.includes(
                                "Research & Development"
                              )}
                              onClick={() => {
                                handleDeptChange("Research & Development");
                              }}
                              style={{
                                color: !isNight ? "#aebcc9" : "#1554F6",
                              }}
                            />
                          }
                          label={
                            <p
                              style={{
                                fontSize: "14px",
                                color: !isNight ? "#aebcc9" : "#343434",
                                margin: "0",
                                textAlign: "left",
                              }}
                            >
                              Research & Development
                            </p>
                          }
                        />
                      )}
                      {(checkVisibility(level, department) ===
                        "Sales & Marketing" ||
                        checkVisibility(level, department) === "all") && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filters.department.includes(
                                "Sales & Marketing"
                              )}
                              onClick={() => {
                                handleDeptChange("Sales & Marketing");
                              }}
                              style={{
                                color: !isNight ? "#aebcc9" : "#1554F6",
                              }}
                            />
                          }
                          label={
                            <p
                              style={{
                                fontSize: "14px",
                                color: !isNight ? "#aebcc9" : "#343434",
                                margin: "0",
                                textAlign: "left",
                              }}
                            >
                              Sales & Marketing
                            </p>
                          }
                        />
                      )}
                    </FormGroup>
                  </div>
                </AccordionDetails>
              </Accordion>
            ) : text === "Region" ? (
              <Accordion sx={{ width: "100%", boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: !isNight
                          ? "#aebcc9 !important"
                          : "#343434 !important",
                        margin: "0 0.5rem",
                      }}
                    />
                  }
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: !isNight
                      ? "#aebcc9 !important"
                      : "#343434 !important",
                    width: "100%",
                    textAlign: "left",
                    padding: "0 0.5rem",
                  }}
                >
                  <p style={{ margin: "0 0.5rem" }}>Region</p>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: "#999 !important",
                  }}
                >
                  <div style={{ position: "relative", top: "-1rem" }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.region.includes("Asia Pacific")}
                            onClick={() => {
                              handleRegionChange("Asia Pacific");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Asia Pacific
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.region.includes("Europe")}
                            onClick={() => {
                              handleRegionChange("Europe");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Europe
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.region.includes(
                              "Middle East/Africa"
                            )}
                            onClick={() => {
                              handleRegionChange("Middle East/Africa");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Middle East/Africa
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.region.includes("North America")}
                            onClick={() => {
                              handleRegionChange("North America");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            North America
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.region.includes("South America")}
                            onClick={() => {
                              handleRegionChange("South America");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            South America
                          </p>
                        }
                      />
                    </FormGroup>
                  </div>
                </AccordionDetails>
              </Accordion>
            ) : text === "Location" ? (
              <Accordion sx={{ width: "100%", boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: !isNight
                          ? "#aebcc9 !important"
                          : "#343434 !important",
                        margin: "0 0.5rem",
                      }}
                    />
                  }
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: !isNight
                      ? "#aebcc9 !important"
                      : "#343434 !important",
                    width: "100%",
                    textAlign: "left",
                    padding: "0 0.5rem",
                  }}
                >
                  <p style={{ margin: "0 0.5rem" }}>Location</p>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: "#999 !important",
                  }}
                >
                  <div style={{ position: "relative", top: "-1rem" }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.location.includes("Headquarters")}
                            onClick={() => {
                              handleLocationChange("Headquarters");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Headquarters
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.location.includes(
                              "Regional Office"
                            )}
                            onClick={() => {
                              handleLocationChange("Regional Office");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Regional Office
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.location.includes("Home Office")}
                            onClick={() => {
                              handleLocationChange("Home Office");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Home Office
                          </p>
                        }
                      />
                    </FormGroup>
                  </div>
                </AccordionDetails>
              </Accordion>
            ) : text === "Length of Service" ? (
              <Accordion sx={{ width: "100%", boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: !isNight
                          ? "#aebcc9 !important"
                          : "#343434 !important",
                        margin: "0 0.5rem",
                      }}
                    />
                  }
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: !isNight
                      ? "#aebcc9 !important"
                      : "#343434 !important",
                    width: "100%",
                    textAlign: "left",
                    padding: "0 0.5rem",
                  }}
                >
                  <p style={{ margin: "0 0.5rem" }}>Length of Service</p>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: "#999 !important",
                  }}
                >
                  <div style={{ position: "relative", top: "-1rem" }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.length_of_service.includes("0-1")}
                            onClick={() => {
                              handleServiceLengthChange("0-1");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            {"<1 Year"}
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.length_of_service.includes("1-5")}
                            onClick={() => {
                              handleServiceLengthChange("1-5");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            1 Year - 5 Years
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.length_of_service.includes("5-9")}
                            onClick={() => {
                              handleServiceLengthChange("5-9");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            5 Years - 9 Years
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.length_of_service.includes("10+")}
                            onClick={() => {
                              handleServiceLengthChange("10+");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            10 Years +
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.length_of_service.includes(
                              "exited"
                            )}
                            onClick={() => {
                              handleServiceLengthChange("exited");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Exited Employees
                          </p>
                        }
                      />
                    </FormGroup>
                  </div>
                </AccordionDetails>
              </Accordion>
            ) : text === "Management Level" ? (
              <Accordion sx={{ width: "100%", boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: !isNight
                          ? "#aebcc9 !important"
                          : "#343434 !important",
                        margin: "0 0.5rem",
                      }}
                    />
                  }
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: !isNight
                      ? "#aebcc9 !important"
                      : "#343434 !important",
                    width: "100%",
                    textAlign: "left",
                    padding: "0 0.5rem",
                  }}
                >
                  <p style={{ margin: "0 0.5rem" }}>Management Level</p>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: "#999 !important",
                  }}
                >
                  <div style={{ position: "relative", top: "-1rem" }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.management_level.includes(
                              "Executive"
                            )}
                            onClick={() => {
                              handleMgmtLevelChange("Executive");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Executive
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.management_level.includes("SVP")}
                            onClick={() => {
                              handleMgmtLevelChange("SVP");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            SVP
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.management_level.includes("VP")}
                            onClick={() => {
                              handleMgmtLevelChange("VP");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            VP
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.management_level.includes(
                              "Director"
                            )}
                            onClick={() => {
                              handleMgmtLevelChange("Director");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Director
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.management_level.includes(
                              "Manager"
                            )}
                            onClick={() => {
                              handleMgmtLevelChange("Manager");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Manager
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.management_level.includes(
                              "Individual Contributor"
                            )}
                            onClick={() => {
                              handleMgmtLevelChange("Individual Contributor");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Individual Contributor
                          </p>
                        }
                      />
                    </FormGroup>
                  </div>
                </AccordionDetails>
              </Accordion>
            ) : text === "Ethinicty" ? (
              <Accordion sx={{ width: "100%", boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: !isNight
                          ? "#aebcc9 !important"
                          : "#343434 !important",
                        margin: "0 0.5rem",
                      }}
                    />
                  }
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: !isNight
                      ? "#aebcc9 !important"
                      : "#343434 !important",
                    width: "100%",
                    textAlign: "left",
                    padding: "0 0.5rem",
                  }}
                >
                  <p style={{ margin: "0 0.5rem" }}>Race</p>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: "#999 !important",
                  }}
                >
                  <div style={{ position: "relative", top: "-1rem" }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.ethinicity.includes(
                              "American Indian or Alaska Native"
                            )}
                            onClick={() => {
                              handleRaceChange(
                                "American Indian or Alaska Native"
                              );
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            American Indian or Alaska Native
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.ethinicity.includes("Asian")}
                            onClick={() => {
                              handleRaceChange("Asian");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Asian
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.ethinicity.includes(
                              "Black or African American"
                            )}
                            onClick={() => {
                              handleRaceChange("Black or African American");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Black or African American
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.ethinicity.includes(
                              "Hispanic or Latino"
                            )}
                            onClick={() => {
                              handleRaceChange("Hispanic or Latino");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Hispanic or Latino
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.ethinicity.includes(
                              "Native Hawaiian or Other Pacific Islander"
                            )}
                            onClick={() => {
                              handleRaceChange(
                                "Native Hawaiian or Other Pacific Islander"
                              );
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Native Hawaiian or Other Pacific Islander
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.ethinicity.includes("White")}
                            onClick={() => {
                              handleRaceChange("White");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            White
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.ethinicity.includes("Others")}
                            onClick={() => {
                              handleRaceChange("Others");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Others
                          </p>
                        }
                      />
                    </FormGroup>
                  </div>
                </AccordionDetails>
              </Accordion>
            ) : text === "Marital Status" ? (
              <Accordion sx={{ width: "100%", boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: !isNight
                          ? "#aebcc9 !important"
                          : "#343434 !important",
                        margin: "0 0.5rem",
                      }}
                    />
                  }
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: !isNight
                      ? "#aebcc9 !important"
                      : "#343434 !important",
                    width: "100%",
                    textAlign: "left",
                    padding: "0 0.5rem",
                  }}
                >
                  <p style={{ margin: "0 0.5rem" }}>Marital Status</p>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: "#999 !important",
                  }}
                >
                  <div style={{ position: "relative", top: "-1rem" }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.marital_status?.includes("Married")}
                            onClick={() => {
                              handleMaritalChange("Married");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Married
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.marital_status?.includes("Single")}
                            onClick={() => {
                              handleMaritalChange("Single");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Single
                          </p>
                        }
                      />
                    </FormGroup>
                  </div>
                </AccordionDetails>
              </Accordion>
            ) : text === "Salary Level" ? (
              <Accordion sx={{ width: "100%", boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: !isNight
                          ? "#aebcc9 !important"
                          : "#343434 !important",
                        margin: "0 0.5rem",
                      }}
                    />
                  }
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: !isNight
                      ? "#aebcc9 !important"
                      : "#343434 !important",
                    width: "100%",
                    textAlign: "left",
                    padding: "0 0.5rem",
                  }}
                >
                  <p style={{ margin: "0 0.5rem" }}>Salary Level</p>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: "#999 !important",
                  }}
                >
                  <div style={{ position: "relative", top: "-1rem" }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.income.includes("0-50000")}
                            onClick={() => {
                              handleSalaryChange("0-50000");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            {"<$50,000"}
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.income.includes("50001-100000")}
                            onClick={() => {
                              handleSalaryChange("50001-100000");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            $50,001 - $100,000
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.income.includes("100001-250000")}
                            onClick={() => {
                              handleSalaryChange("100001-250000");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            $100,001 - $250,000
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.income.includes("250,001+")}
                            onClick={() => {
                              handleSalaryChange("250,001+");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            $250,001 +
                          </p>
                        }
                      />
                    </FormGroup>
                  </div>
                </AccordionDetails>
              </Accordion>
            ) : text === "Age" ? (
              <Accordion sx={{ width: "100%", boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: !isNight
                          ? "#aebcc9 !important"
                          : "#343434 !important",
                        margin: "0 0.5rem",
                      }}
                    />
                  }
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: !isNight
                      ? "#aebcc9 !important"
                      : "#343434 !important",
                    width: "100%",
                    textAlign: "left",
                    padding: "0 0.5rem",
                  }}
                >
                  <p style={{ margin: "0 0.5rem" }}>Age</p>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: "#999 !important",
                  }}
                >
                  <div style={{ position: "relative", top: "-1rem" }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.age.includes("0-25")}
                            onClick={() => {
                              handleAgeChange("0-25");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            {"< 25"}
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.age.includes("26-35")}
                            onClick={() => {
                              handleAgeChange("26-35");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            26 - 35
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.age.includes("36-49")}
                            onClick={() => {
                              handleAgeChange("36-49");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            36 - 49
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.age.includes("50+")}
                            onClick={() => {
                              handleAgeChange("50+");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            50+
                          </p>
                        }
                      />
                    </FormGroup>
                  </div>
                </AccordionDetails>
              </Accordion>
            ) : text === "Performance" ? (
              <Accordion sx={{ width: "100%", boxShadow: "none" }}>
                <AccordionSummary
                  expandIcon={
                    <ExpandMoreIcon
                      sx={{
                        color: !isNight
                          ? "#aebcc9 !important"
                          : "#343434 !important",
                        margin: "0 0.5rem",
                      }}
                    />
                  }
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: !isNight
                      ? "#aebcc9 !important"
                      : "#343434 !important",
                    width: "100%",
                    textAlign: "left",
                    padding: "0 0.5rem",
                  }}
                >
                  <p style={{ margin: "0 0.5rem" }}>Performance</p>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    backgroundColor: isNight
                      ? "#F6F7FC !important"
                      : "#212047 !important",
                    color: "#999 !important",
                  }}
                >
                  <div style={{ position: "relative", top: "-1rem" }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.performance.includes("5")}
                            onClick={() => {
                              handlePerformanceChange("5");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            {"Outstanding"}
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.performance.includes("4")}
                            onClick={() => {
                              handlePerformanceChange("4");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Very Satisfactory
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.performance.includes("3")}
                            onClick={() => {
                              handlePerformanceChange("3");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Satisfactory
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.performance.includes("2")}
                            onClick={() => {
                              handlePerformanceChange("2");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Unsatisfactory
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filters.performance.includes("1")}
                            onClick={() => {
                              handlePerformanceChange("1");
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Poor
                          </p>
                        }
                      />
                    </FormGroup>
                  </div>
                </AccordionDetails>
              </Accordion>
            ) : (
              <>{text}</>
            )}
          </div>
        )}
      </div>
    </>
  );
};
