import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Tooltip } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import {
  ADD_SURVEY_QUESTION,
  GET_SUGGESTED_QUESTION,
} from "../../../../redux/Dashboard/Dashboard.types";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/Refresh";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function SuggestQuestionModal({ survey, handleClose }) {
  const dispatch = useDispatch();
  const suggestedQuestion = useSelector(
    (data) => data.dashboard.suggestedQuestion
  );

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const [questionText, setQuestionText] = useState();

  const [options, setOptions] = useState([]);

  const [interval, setInterval] = useState("monthly");

  const handleSave = () => {
    const data = {
      surveyID: survey._id,
      question: questionText,
      options: options,
    };

    dispatch({ type: ADD_SURVEY_QUESTION, payload: data });

    handleClose();
  };

  useEffect(() => {
    dispatch({
      type: GET_SUGGESTED_QUESTION,
      payload: { surveyID: survey._id },
    });
  }, []);
  useEffect(() => {
    setQuestionText(suggestedQuestion.question);
    setOptions(suggestedQuestion.options);
  }, [suggestedQuestion]);

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const refreshQuestion = () => {
    dispatch({
      type: GET_SUGGESTED_QUESTION,
      payload: { surveyID: survey._id },
    });
  };

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Suggest More Question
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        {" "}
        <Stack direction="column" spacing={2}>
          {/* <TextField
            id="question-text"
            label="Question"
            variant="outlined"
            value={questionText}
            onChange={(e) => setQuestionText(e.target.value)}
          />
          {Object.keys(options).map((option, idx) => (
            <>
              {option !== "Other" && (
                <TextField
                  key={idx}
                  label={`Option ${idx + 1}`}
                  variant="outlined"
                  value={option}
                  onChange={(e) => handleOptionChange(idx, e.target.value)}
                />
              )}
            </>
          ))} */}
          {questionText !== "" && (
            <Typography>Question: {questionText}</Typography>
          )}
          {Object.keys(options).length > 0 && <Typography>Options:</Typography>}
          {Object.keys(options).map((option, idx) => (
            <Typography style={{ marginLeft: "4rem" }}>{`${
              idx + 1
            }: ${option}`}</Typography>
          ))}

          <Button
            variant="outlined"
            style={{ maxWidth: "120px" }}
            onClick={refreshQuestion}
          >
            <RefreshIcon style={{ cursor: "pointer" }} />
            Refresh
          </Button>
        </Stack>
      </Box>

      <Stack direction="row" spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          style={{
            background: "#D06283",
            color: "#fff",
          }}
          variant="contained"
        >
          Add
        </Button>
      </Stack>
    </Box>
  );
}

export default SuggestQuestionModal;
