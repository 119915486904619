import { createChatBotMessage } from "react-chatbot-kit";
const botName = "TruPulse Bot"
const config = {
  botName: botName,
  initialMessages: [
    createChatBotMessage(`Hi! I'm ${botName}, Ask me anything...`),
  ],
  customComponents: {
    // Replaces the default header
    // header: () => <div style={{ backgroundColor: 'red', padding: "5px", borderRadius: "3px" }}>This is the header</div>
    // Replaces the default bot avatar
    botAvatar: (props) => (
      <div class="react-chatbot-kit-chat-bot-avatar-container">
        <p class="react-chatbot-kit-chat-bot-avatar-letter">TB</p>
      </div>
    ),
    // Replaces the default bot chat message container
    // botChatMessage: (props) => <MyCustomChatMessage {...props} />,
    // Replaces the default user icon
    // userAvatar: (props) => <MyCustomAvatar {...props} />,
    // Replaces the default user chat message
    // userChatMessage: (props) => <MyCustomUserChatMessage {...props} />
  },
  customStyles: {
    botMessageBox: {
      backgroundColor: "#3629b7",
    },
    chatButton: {
      backgroundColor: "#3629b7",
    },
  },
};

export default config;
