import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {
  GET_ALL_SURVEYS,
  UPDATE_SURVEY,
} from "../../../../redux/Dashboard/Dashboard.types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function StartActionPlanModal({ plan, handleClose }) {
  const dispatch = useDispatch();

  //   const archive = () => {
  //     dispatch({
  //       type: UPDATE_SURVEY,
  //       payload: {
  //         surveyID: survey._id,
  //         surveyName: survey.surveyName,
  //         status: survey.status !== "archived" ? "archived" : "available",
  //       },
  //     });
  //     setTimeout(() => {
  //       dispatch({
  //         type: GET_ALL_SURVEYS,
  //       });
  //       handleClose();
  //     }, 2000);
  //   };

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Start Action Plan
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Typography sx={{ margin: "1rem 0" }}>
          Duration Required for Action Completion
        </Typography>
        <Input type="number" placeholder="Enter number of days" />
      </Box>
      <Stack direction="row" spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          //   onClick={archive}
          style={{
            background: "#D06283",
            color: "#fff",
          }}
          variant="contained"
        >
          Update
        </Button>
      </Stack>
    </Box>
  );
}

export default StartActionPlanModal;
