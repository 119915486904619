import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import CloseIcon from "@mui/icons-material/Close";
import Checkbox from "@mui/material/Checkbox";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PeopleIcon from "@mui/icons-material/People";

import { demoSurveys } from "../../../Feedback/demoSurveys";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const targetGroups = {
  gender: ["Male", "Female", "Non-binary", "Transgender"],
  department: [
    "Accounting and Finance",
    "Engineering",
    "Human Resource",
    "Information Technology",
    "Operations",
    "Research and Development",
    "Sales and Marketing",
  ],
  region: [
    "Asia Pacific",
    "Europe",
    "Middle East/Africa",
    "North America",
    "South America",
  ],
  location: ["Headquarters", "Regional Office", "Home office"],
  length_of_service: [
    "<1 year",
    "1-5 years",
    "5-9 years",
    "10+ years",
    "exited employee",
  ],
  management_level: [
    "Executive",
    "SVP",
    "VP",
    "Director",
    "Manager",
    "Individual Contributor",
  ],
  ethnicity: [
    "African American",
    "Asian",
    "Hispanic",
    "Native American or Alaskan Native",
    "Native Hawaiian or Pacific Islander",
    "white",
    "Others",
  ],
  marital_status: ["Married", "Single"],
  Salary_level: [
    "<$50,000",
    "$50,001-$100,000",
    "$100,001-$250,000",
    "$250,001",
  ],
  age: ["<25", "26-35", "36-49", "50+"],
  performance: [
    "Outstanding",
    "Very Satisfactory",
    "Satisfactory",
    "Unsatisfactory",
    "Poor",
  ],
};

function EditModal({ metric, handleClose, focusGroup }) {
  const dispatch = useDispatch();
  const statusBoxItems = useSelector((data) => data.dashboard.statusBoxItems);
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const filterEmails = isDemo
    ? demoSurveys
    : useSelector((data) => data.dashboard.filterEmails);
  const [isAnnonymous, setIsAnnonymous] = useState(focusGroup.anonymous);
  const [startDate, setStartDate] = useState(focusGroup.startTime);
  const [duration, setDuration] = useState(
    (focusGroup.endTime - focusGroup.startTime) / (1000 * 60)
  );
  const [numOfEmp, setNumOfEmp] = useState(focusGroup.users.length);
  const [name, setName] = useState(focusGroup.topic);
  const [topics, setTopics] = useState(focusGroup.discussionTopics);

  const [filter1, setFilter1] = useState("gender");
  const [option1, setOption1] = useState("");
  const [filter2, setFilter2] = useState("gender");
  const [option2, setOption2] = useState("");
  const [allChecked, setAllChecked] = useState("");
  const [mulipleInput, setMultipleInputs] = useState([0]);

  const changeFilter1 = (event) => {
    setFilter1(event.target.value);
  };
  const changeOption1 = (event) => {
    setOption1(event.target.value);
  };

  const changeFilter2 = (event) => {
    setFilter2(event.target.value);
  };
  const changeOption2 = (event) => {
    setOption2(event.target.value);
  };

  const handleTopicChange = (index, event) => {
    const newTopics = [...topics];
    newTopics[index] = event.target.value;
    setTopics(newTopics);
  };

  const addTopicField = () => {
    setTopics([...topics, ""]);
  };

  const removeTopicField = (index) => {
    const newTopics = topics.filter((_, i) => i !== index);
    setTopics(newTopics);
  };

  const handleSave = () => {
    const endDate = new Date(startDate);
    endDate.setMinutes(endDate.getMinutes() + duration);
  };
  function transformObjectFormat(input) {
    const transformed = {};

    // Iterate over each key-value pair in the input object
    for (const key in input) {
      if (input.hasOwnProperty(key)) {
        // Wrap each value in an array
        transformed[key] = [input[key]];
      }
    }

    return transformed;
  }
  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Create Focus Group
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />
      <Box style={{ maxHeight: "80vh", overflowY: "scroll" }}>
        <Box
          sx={{
            margin: "1rem 0",
          }}
        >
          <TextField
            fullWidth
            id={`name`}
            label={`Name`}
            value={name}
            onChange={(event) => setName(index, event)}
          />

          <Stack direction="column" spacing={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAnnonymous}
                  onChange={(e) => {
                    setIsAnnonymous(e.target.checked);
                  }}
                />
              }
              label="Anonymous"
            />
          </Stack>

          <Stack direction="column" spacing={2}>
            {topics.map((topic, index) => (
              <Stack
                direction="row"
                spacing={2}
                key={index}
                alignItems="center"
              >
                <TextField
                  fullWidth
                  id={`topic-text-${index}`}
                  label={`Topic ${index + 1}`}
                  variant="outlined"
                  value={topic}
                  onChange={(event) => handleTopicChange(index, event)}
                />
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => removeTopicField(index)}
                >
                  Remove
                </Button>
              </Stack>
            ))}
            <Typography variant="outlined" onClick={addTopicField}>
              <AddIcon style={{ position: "relative", top: "5px" }} /> Add
            </Typography>
          </Stack>

          <Box
            sx={{
              margin: "1rem 0",
            }}
          >
            <Typography>Target Group</Typography>
            <FormControlLabel
              control={
                <Checkbox
                  value={allChecked}
                  onChange={(e) => {
                    setAllChecked(e.target.checked);
                  }}
                />
              }
              label="All"
            />
            {!allChecked && (
              <Box>
                <Stack direction="row">
                  <FormControl sx={{ margin: "1rem ", width: "50%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Choose Filter
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={filter1}
                      onChange={changeFilter1}
                    >
                      {Object.keys(targetGroups).map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ margin: "1rem ", width: "50%" }}>
                    <InputLabel id="demo-simple-select-label">
                      Choose Option
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={option1}
                      onChange={changeOption1}
                    >
                      {targetGroups[filter1].map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
                {mulipleInput.length > 1 && (
                  <Stack direction="row">
                    <FormControl sx={{ margin: "1rem ", width: "50%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Choose Filter
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={filter2}
                        onChange={changeFilter2}
                      >
                        {Object.keys(targetGroups).map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ margin: "1rem ", width: "50%" }}>
                      <InputLabel id="demo-simple-select-label">
                        Choose Option
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={option2}
                        onChange={changeOption2}
                      >
                        {targetGroups[filter2].map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <CloseIcon
                      onClick={() => {
                        setMultipleInputs([0]);
                      }}
                      sx={{
                        position: "relative",
                        top: "30px",
                        color: "#727272",
                      }}
                    />
                  </Stack>
                )}
                {mulipleInput.length < 2 && (
                  <Typography
                    onClick={() => {
                      const temp = mulipleInput;
                      setMultipleInputs([...temp, temp.length]);
                    }}
                  >
                    +Add more
                  </Typography>
                )}
              </Box>
            )}
            {/* <ReactMultiEmail
          placeholder="Enter guests email"
          emails={emails}
          onChange={(_emails) => {
            setEmails(_emails);
          }}
          style={{
            borderTop: "none",
            borderLeft: "none",
            borderRight: "none",
            borderBottom: "1px solid #484848",
            boxShadow: "none",
          }}
          autoFocus={true}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          getLabel={(email, index, removeEmail) => {
            return (
              <div data-tag key={index}>
                <div data-tag-item>{email}</div>
                <span data-tag-handle onClick={() => removeEmail(index)}>
                  ×
                </span>
              </div>
            );
          }}
        /> */}
          </Box>

          <Stack direction="column" spacing={2}>
            <Typography style={{ margin: "1rem 0 0" }}>
              Start Date-Time
            </Typography>
            <TextField
              fullWidth
              id="question-text"
              type="datetime-local"
              variant="outlined"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </Stack>
          <Stack direction="column" spacing={2}>
            <Typography style={{ margin: "1rem 0 0" }}>Duration</Typography>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Duration</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={duration}
                label="Duration"
                onChange={(e) => {
                  setDuration(e.target.value);
                }}
              >
                <MenuItem value={30}>30 Minutes</MenuItem>
                <MenuItem value={60}>1 Hour</MenuItem>
                <MenuItem value={120}>2 Hours</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </Box>

        <Stack direction="row" spacing={2} justifyContent="end">
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            onClick={handleSave}
            style={{
              background: "#D06283",
              color: "#fff",
            }}
            variant="contained"
          >
            Create
          </Button>
        </Stack>
      </Box>
    </Box>
  );
}

export default EditModal;
