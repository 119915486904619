import React, { useState, useEffect, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Skeleton from "@mui/material/Skeleton";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { GET_SENTIMENTS_DIST } from "../../../../../redux/Dashboard/Dashboard.types";
import Chart from "react-apexcharts";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@material-ui/core";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { useScreenshot } from "use-react-screenshot";
import * as clipboard from "clipboard-polyfill";
import { base64StringToBlob } from "blob-util";

function SentimentDist({
  sentimentsDist,
  currentFilter,
  customDate,
  currentWorkflowID,
}) {
  const dispatch = useDispatch();

  const [clipProcessing, setClipProcessing] = useState(false);

  async function clip(b64Data) {
    const item = new clipboard.ClipboardItem({
      "image/png": base64StringToBlob(b64Data.split(",")[1], "image/png"),
    });

    await clipboard.write([item]);
  }

  const sentScoreRef = createRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const getImage = async () => {
    setClipProcessing(true);
    await takeScreenshot(sentScoreRef.current);
    setClipProcessing(false);
    image && clip(image);
  };

  useEffect(() => {
    image && clip(image);
  }, [image]);

  const filters = useSelector((data) => data.dashboard.filters);
  const [isLoading, setIsLoading] = useState(true);
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const [smallNumber, setSmallNumber] = useState(0);
  useEffect(() => {
    setSmallNumber(getRandomInt(50, 65));
  }, [filters, currentFilter, customDate]);

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  var options = {
    chart: {
      type: "pie",
    },
    dataLabels: {
      enabled: true,
    },
    fill: {
      colors: ["#75C7C3", "#C23E65"],
    },
    plotOptions: {
      pie: {
        startAngle: -180,
        endAngle: 180,
        offsetY: 10,
        dataLabels: {
          offset: -30,
        },
      },
    },

    stroke: {
      show: false,
    },
    grid: {
      padding: {
        // bottom: -80
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "right",
      fontSize: "14px",
      fontWeight: 400,
      // offsetY: 4,
      labels: {
        colors: !isNight ? ["#727272"] : ["#666"],
        useSeriesColors: false,
      },
      markers: {
        width: 14,
        height: 14,
        fillColors: ["#75C7C3", "#C23E65"],
        radius: 0,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    tooltip: {
      fillSeriesColor: true,
      y: {
        formatter: function (value) {
          return value + "%";
        },
      },
    },
    style: {
      fontSize: "12px",
      color: "#333",
    },
    colors: ["#666", "#666"],
    labels: ["Positive", "Negative"],
  };

  const series = [133, 139];
//   const series = [
//     parseFloat(
//       (
//         (filters.gender === "male"
//           ? isDemo
//             ? smallNumber / 100
//             : sentimentsDist.percent_male_positive
//           : filters.gender === "female"
//           ? isDemo
//             ? smallNumber / 100
//             : sentimentsDist.percent_female_positive
//           : isDemo
//           ? smallNumber / 100
//           : sentimentsDist.percent_positive) * 100
//       ).toFixed(2)
//     ),
//     parseFloat(
//       (
//         (filters.gender === "male"
//           ? isDemo
//             ? (100 - smallNumber) / 100
//             : sentimentsDist.percent_male_negative
//           : filters.gender === "female"
//           ? isDemo
//             ? (100 - smallNumber) / 100
//             : sentimentsDist.percent_female_negative
//           : isDemo
//           ? (100 - smallNumber) / 100
//           : sentimentsDist.percent_negative) * 100
//       ).toFixed(2)
//     ),
//   ];

  return (
    <div
      ref={sentScoreRef}
      style={{
        height: "360px",
        borderRadius: "12px",
        backgroundColor: !isNight ? "#2A2953" : "#fff",
        position: "relative",
        margin: "20px 0 0 0.3rem",
      }}
    >
      <div
        style={{
          width: "93%",
          position: "relative",
          top: "15px",
          margin: "1rem",
          display: "flex",
          justifyContent: "space-between",
          margin: "1rem",
        }}
      >
        <p
          style={{
            margin: "0",
            textAlign: "left",
            fontWeight: 500,
            color: !isNight ? "#fff" : "#484848",
          }}
        >
          Topic Sentiment
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                Breakdown of Positive vs Negative Sentiment for a Specific Topic
              </span>
            }
          >
            <InfoIcon
              style={{
                fontSize: "1rem",
                filter: "opacity(0.7)",
                position: "relative",
                top: "3px",
              }}
            />
          </Tooltip>
        </p>
        <Tooltip
          title={
            <span
              style={{
                fontSize: "0.8rem",
                textAlign: "center",
              }}
            >
              Copy to clipboard
            </span>
          }
        >
          {clipProcessing ? (
            <CircularProgress size={16} color="inherit" />
          ) : (
            <ContentCopyOutlinedIcon
              onClick={getImage}
              style={{
                fontSize: "1.2rem",
                cursor: clipProcessing ? "wait" : "pointer",
              }}
            />
          )}
        </Tooltip>
      </div>

      {sentimentsDist.status === "success" || (isDemo && !isLoading) ? (
        <div>
          <div
            className="sentiment-score-container"
            style={{ position: "relative", top: "-10px" }}
          >
            <Chart
              options={options}
              series={series}
              type="pie"
              width="100%"
              height="300px"
            />
          </div>
        </div>
      ) : sentimentsDist.status === "fail" || currentWorkflowID === "" ? (
        <div style={{ position: "relative", top: "100px" }}>
          <ErrorOutlineIcon style={{ color: "#94989f" }} />
          <p style={{ margin: "0", color: "#94989f" }}>
            {currentWorkflowID === "" ? "Select a workflow!" : "No Data found!"}
          </p>
        </div>
      ) : (
        <Skeleton
          variant="circular"
          width={"75%"}
          height={"250px"}
          style={{
            position: "relative",
            top: "20px",
            margin: "0 auto",
          }}
        />
      )}
    </div>
  );
}

export default SentimentDist;
