import * as React from "react";
import { SHOW_BUILD_STATUS } from "../../../redux/Dashboard/Dashboard.types";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { Tooltip, makeStyles } from "@material-ui/core";

export default function BuildProgress({ value, workflowID, workflowName }) {
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  const userWorkflows = useSelector((data) => data.dashboard.userWorkflows);
  const buildProgressCallCount = useSelector(
    (data) => data.dashboard.buildProgressCallCount
  );
  const buildProgressStatus = useSelector(
    (data) => data.dashboard.buildProgressStatus
  );

  const useStyles = makeStyles((theme) => ({
    root: {
      color: "#fff",
      padding: "0!important",
    },
    getColor: {
      color: !isNight ? "#aebcc9" : "#000",
    },
    getBg: {
      backgroundColor: !isNight ? "#212047" : "#fff",
    },
  }));
  const classes = useStyles();
  const dispatch = useDispatch();
  React.useEffect(() => {
    setTimeout(function () {
      workflowID !== "" &&
        buildProgressCallCount > 0 &&
        dispatch({
          type: SHOW_BUILD_STATUS,
          payload: { workflowID: workflowID },
        });
    }, 6000);
  }, [buildProgressCallCount, dispatch, workflowID]);

  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (
      userWorkflows?.data.Workflows.filter(
        (item) => item.workflowID === workflowID
      )[0]?.builtSuccessful
    ) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [workflowID]);
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "80px" }}>
          <span className={classes.getColor}>Build Status:</span>
        </Box>
        <Tooltip
          title={
            !loading &&
            (buildProgressCallCount === 0 || buildProgressStatus === "rest")
              ? "100%"
              : `${Math.round(value)}`
          }
          arrow
        >
          <Box sx={{ width: "110px", margin: "0.7rem 0.5rem 0.5rem 0.8rem" }}>
            <LinearProgress
              variant="determinate"
              value={
                !loading &&
                (buildProgressCallCount === 0 || buildProgressStatus === "rest")
                  ? 100
                  : Math.round(value)
              }
            />
          </Box>
        </Tooltip>
        <Box sx={{ width: "10px" }}>
          <span className={classes.getColor}>
            {!loading &&
            (buildProgressCallCount === 0 || buildProgressStatus === "rest")
              ? "100%"
              : `${Math.round(value)}%`}
          </span>
        </Box>
      </Box>
    </>
  );
}
