import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";
import Gauge from "../Gauge";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Skeleton from "@mui/material/Skeleton";
import SettingsIcon from "@mui/icons-material/Settings";
import TopStatusBoxGauge from "../Gauge/TopStatusBoxGauge";
import TopStatusBoxDist from "../Gauge/TopStatusBoxDist";
import Modal from "@mui/material/Modal";
import {
  demoData,
  generateHateSpeechData,
  generatePositiveCultureData,
  generateNegativeCultureData,
  generateInsultData,
  generateSexualData,
  demoOverallENPSTrend,
  generateRacismData,
  demoEngagementTopicsData,
  generateDemoCultureData,
} from "../demoData";
import { Box, Typography, Tooltip } from "@material-ui/core";
import { GET_STATUS_BOX } from "../../../../redux/Dashboard/Dashboard.types";
import ConfigModal from "./ConfigModal";

function TopStatusBox({
  moraleScore,
  currentWorkflowID,
  customDate,
  currentFilter,
  engagementData,
  retentionData,
  supporterData,
  sentimentsDist,
}) {
  const dispatch = useDispatch();
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const filters = useSelector((data) => data.dashboard.filters);
  const teamSize = useSelector((data) => data.dashboard.teamSize);
  const statusBoxItems = useSelector((data) => data.dashboard.statusBoxItems);
  const trendingTopicsData = isDemo
    ? demoEngagementTopicsData?.topics
    : useSelector((data) => data.dashboard.trendingTopicsData);

  console.log("trendingTopicsData", trendingTopicsData);
  const positiveCultureData = isDemo
    ? generatePositiveCultureData(currentFilter, false)
    : useSelector((data) => data.dashboard.positiveCultureData);
  const stateoverallCultureData = useSelector(
    (data) => data.dashboard.overallCultureData
  );

  const overallCultureData = useMemo(() => {
    return isDemo
      ? generateDemoCultureData(currentFilter, customDate)
      : stateoverallCultureData;
  }, [isDemo, currentFilter, customDate, stateoverallCultureData]);

  const cultureDataArray = Array.isArray(overallCultureData?.cultureData)
    ? overallCultureData?.cultureData
    : [];

  console.log(overallCultureData);

  const negativeCultureData = isDemo
    ? generateNegativeCultureData(currentFilter, false)
    : useSelector((data) => data.dashboard.negativeCultureData);

  const overallENPSScore = useSelector(
    (data) => data.dashboard.overallENPSScore
  );

  const overallENPSTrend = isDemo
    ? demoOverallENPSTrend
    : useSelector((data) => data.dashboard.overallENPSTrend);

  const [isLoading, setIsLoading] = useState(true);
  const [demoScore, setScore] = useState(58);
  const [smallNumber, setSmallNumber] = useState(0);
  const [minMaxMorale, setMinMaxMorale] = useState({ min: 0, max: 0 });
  const [configModalOpen, setConfigModalOpen] = useState(false);

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  const getAvgFromTrend = (trend, term) => {
    if (trend) {
      let sum = 0;
      for (let i = 0; i < trend.length; i++) {
        sum += trend[i][term];
      }

      return isDemo
        ? Math.round(sum / trend.length)
        : Math.round(sum / trend.length);
    } else {
      return 0;
    }
  };

  useEffect(() => {
    !isDemo && dispatch({ type: GET_STATUS_BOX });
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    setScore(getRandomInt(50, 65));
  }, [filters, customDate, currentFilter]);

  useEffect(() => {
    setSmallNumber(getRandomInt(20, 90));
  }, [filters, currentFilter, customDate]);

  useEffect(() => {
    moraleScore?.moraleScoreAvg?.length > 0 &&
      setMinMaxMorale({
        min: Math.min(
          ...moraleScore?.moraleScoreAvg
            ?.filter((item) => item.morale_avg !== null)
            ?.map((item) => (isDemo ? item?.morale_avg : item?.morale_avg))
        ),
        max: Math.max(
          ...moraleScore?.moraleScoreAvg
            ?.filter((item) => item.morale_avg !== null)
            ?.map((item) => (isDemo ? item?.morale_avg : item?.morale_avg))
        ),
      });
  }, [moraleScore]);

  return (
    <div
      style={{
        margin: "0 1rem 2rem",
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "end" }}>
        <Tooltip
          title={
            <span
              style={{
                fontSize: "0.8rem",
                textAlign: "center",
              }}
            >
              Configure
            </span>
          }
        >
          <SettingsIcon
            onClick={() => {
              setConfigModalOpen(true);
            }}
            style={{ color: "#727272", cursor: "pointer", zIndex: "400" }}
          />
        </Tooltip>
      </Box>

      <Grid
        container
        spacing={3}
        style={{
          borderRadius: "12px",
          marginLeft: ".1rem",
          padding: "0",
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        {statusBoxItems.includes("Morale") && (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <div
              style={{
                width: "100%",
                margin: "0 0 0px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  top: "0px",
                }}
              >
                {moraleScore.status === "success" || (isDemo && !isLoading) ? (
                  <>
                    <TopStatusBoxGauge
                      value={Math.round(
                        isDemo ? demoScore : moraleScore.avgMorale
                      )}
                      min={0}
                      max={100}
                      name="Morale"
                      minMax={minMaxMorale}
                    />

                    <p
                      style={{
                        color: "#727272",
                        fontSize: "1.2rem",
                        textAlign: "center",
                        width: "100%",
                        position: "absolute",
                        bottom: "-10px",
                        width: "100%",
                      }}
                    >
                      Morale
                    </p>
                  </>
                ) : moraleScore?.status === "fail" ||
                  currentWorkflowID === "" ? (
                  <div
                    style={{
                      position: "relative",
                      top: "100px",
                    }}
                  >
                    <ErrorOutlineIcon style={{ color: "#94989f" }} />
                    <p
                      style={{
                        margin: "0",
                        color: "#94989f",
                      }}
                    >
                      No Data found!
                    </p>
                  </div>
                ) : (
                  <Skeleton
                    variant="circular"
                    width={"250px"}
                    height={"250px"}
                    style={{
                      position: "relative",
                      top: "30px",
                      margin: "0 auto 3rem",
                    }}
                  />
                )}
              </div>
            </div>
          </Grid>
        )}
        {statusBoxItems.includes("Engagement") && (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <div
              style={{
                width: "100%",
                margin: "0 0 0px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  top: "0px",
                }}
              >
                {engagementData.status === "success" ||
                (isDemo && !isLoading) ? (
                  <>
                    <TopStatusBoxGauge
                      value={getAvgFromTrend(
                        engagementData?.overAllEngagement,
                        "count"
                      )}
                      name="Engagement Score"
                      min={0}
                      max={100}
                      minMax={{
                        min: Math.min(
                          ...engagementData?.overAllEngagement?.map(
                            (item) => item?.count
                          )
                        ),
                        max: Math.max(
                          ...engagementData?.overAllEngagement?.map(
                            (item) => item?.count
                          )
                        ),
                      }}
                    />

                    <p
                      style={{
                        color: "#727272",
                        fontSize: "1.2rem",
                        textAlign: "center",
                        position: "absolute",
                        bottom: "-10px",
                        width: "100%",
                      }}
                    >
                      Engagement
                    </p>
                  </>
                ) : engagementData?.status === "fail" ||
                  currentWorkflowID === "" ? (
                  <div
                    style={{
                      position: "relative",
                      top: "100px",
                    }}
                  >
                    <ErrorOutlineIcon style={{ color: "#94989f" }} />
                    <p
                      style={{
                        margin: "0",
                        color: "#94989f",
                      }}
                    >
                      No Data found!
                    </p>
                  </div>
                ) : (
                  <Skeleton
                    variant="circular"
                    width={"250px"}
                    height={"250px"}
                    style={{
                      position: "relative",
                      top: "30px",
                      margin: "0 auto 3rem",
                    }}
                  />
                )}
              </div>
            </div>
          </Grid>
        )}
        {statusBoxItems.includes("Retention") && (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <div
              style={{
                width: "100%",
                margin: "0 0 0px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  top: "0px",
                }}
              >
                {retentionData.status === "success" ||
                (isDemo && !isLoading) ? (
                  <>
                    <TopStatusBoxGauge
                      value={getAvgFromTrend(
                        retentionData?.retentionData,
                        "score"
                      )}
                      name="Retention Risk"
                      min={0}
                      max={100}
                      minMax={{
                        min: Math.min(
                          ...retentionData?.retentionData?.map(
                            (item) => item?.score
                          )
                        ),
                        max: Math.max(
                          ...retentionData?.retentionData?.map(
                            (item) => item?.score
                          )
                        ),
                      }}
                    />

                    <p
                      style={{
                        color: "#727272",
                        fontSize: "1.2rem",
                        textAlign: "center",
                        position: "absolute",
                        bottom: "-10px",
                        width: "100%",
                      }}
                    >
                      Retention Risk
                    </p>
                  </>
                ) : retentionData?.status === "fail" ||
                  currentWorkflowID === "" ? (
                  <div
                    style={{
                      position: "relative",
                      top: "100px",
                    }}
                  >
                    <ErrorOutlineIcon style={{ color: "#94989f" }} />
                    <p
                      style={{
                        margin: "0",
                        color: "#94989f",
                      }}
                    >
                      No Data found!
                    </p>
                  </div>
                ) : (
                  <Skeleton
                    variant="circular"
                    width={"250px"}
                    height={"250px"}
                    style={{
                      position: "relative",
                      top: "30px",
                      margin: "0 auto 3rem",
                    }}
                  />
                )}
              </div>
            </div>
          </Grid>
        )}
        {statusBoxItems.includes("ENPS") && (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <div
              style={{
                width: "100%",
                margin: "0 0 0px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  top: "-20px",
                }}
              >
                {(overallENPSScore?.status === "success" &&
                  typeof overallENPSScore?.overall === "number") ||
                (isDemo && !isLoading) ? (
                  <>
                    <TopStatusBoxGauge
                      value={getAvgFromTrend(overallENPSTrend?.result, "enps")}
                      name="eNPS"
                      min={-100}
                      max={100}
                      minMax={{
                        min: Math.min(
                          ...(overallENPSTrend?.result || [])?.map(
                            (item) => item?.enps
                          )
                        ),
                        max: Math.max(
                          ...(overallENPSTrend?.result || [])?.map(
                            (item) => item?.enps
                          )
                        ),
                      }}
                    />

                    <p
                      style={{
                        color: "#727272",
                        fontSize: "1.2rem",
                        textAlign: "center",
                        position: "absolute",
                        bottom: "-10px",
                        width: "100%",
                      }}
                    >
                      eNPS
                    </p>
                  </>
                ) : overallENPSScore?.status === "fail" ||
                  currentWorkflowID === "" ? (
                  <div
                    style={{
                      position: "relative",
                      top: "100px",
                    }}
                  >
                    <ErrorOutlineIcon style={{ color: "#94989f" }} />
                    <p
                      style={{
                        margin: "0",
                        color: "#94989f",
                      }}
                    >
                      No Data found!
                    </p>
                  </div>
                ) : (
                  <Skeleton
                    variant="circular"
                    width={"250px"}
                    height={"250px"}
                    style={{
                      position: "relative",
                      top: "30px",
                      margin: "0 auto",
                    }}
                  />
                )}
              </div>
            </div>
          </Grid>
        )}
        {statusBoxItems.includes("Culture") && (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <div
              style={{
                width: "100%",
                margin: "0 0 0px",
              }}
            >
              <div
                style={{
                  position: "relative",
                  top: "-20px",
                }}
              >
                {positiveCultureData.status === "success" ||
                (isDemo && !isLoading) ? (
                  <>
                    <TopStatusBoxGauge
                      value={getAvgFromTrend(cultureDataArray, "score")}
                      name="Overall Culture"
                      min={-100}
                      max={100}
                      minMax={{
                        min: Math.min(
                          ...cultureDataArray.map((item) => item?.score)
                        ),
                        max: Math.max(
                          ...cultureDataArray.map((item) => item?.score)
                        ),
                      }}
                    />

                    <p
                      style={{
                        color: "#727272",
                        fontSize: "1.2rem",
                        textAlign: "center",
                        position: "absolute",
                        bottom: "-10px",
                        width: "100%",
                      }}
                    >
                      Overall Culture
                    </p>
                  </>
                ) : positiveCultureData?.status === "fail" ||
                  currentWorkflowID === "" ? (
                  <div
                    style={{
                      position: "relative",
                      top: "100px",
                    }}
                  >
                    <ErrorOutlineIcon style={{ color: "#94989f" }} />
                    <p
                      style={{
                        margin: "0",
                        color: "#94989f",
                      }}
                    >
                      No Data found!
                    </p>
                  </div>
                ) : (
                  <Skeleton
                    variant="circular"
                    width={"250px"}
                    height={"250px"}
                    style={{
                      position: "relative",
                      top: "30px",
                      margin: "0 auto",
                    }}
                  />
                )}
              </div>
            </div>
          </Grid>
        )}
        {statusBoxItems.includes("Topic") && (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <div
              style={{
                width: "100%",
                margin: "0 0 0px",
              }}
            >
              <div
                style={{
                  position: "relative",
                }}
              >
                {negativeCultureData.status === "success" ||
                (isDemo && !isLoading) ? (
                  <>
                    <div style={{ height: "200px", padding: "1rem 2rem 1rem" }}>
                      {Object.entries(trendingTopicsData)
                        .sort(([, valueA], [, valueB]) => valueB - valueA) // Sort by value in descending order
                        .slice(0, 5) // Take the top 5
                        .map(([item, value], index) => (
                          <Typography
                            key={index} // Unique key for each item
                            style={{
                              textAlign: "center",
                              color: "#484848",
                              padding: "0.25rem 0.5rem",
                              fontSize: "1rem",
                              margin: "0.5rem auto",
                              border: "1px solid #D06283",
                              borderRadius: "8px",
                              maxWidth: "200px",
                            }}
                          >
                            {`${item} - ${value}`}{" "}
                            {/* Render the item with its value */}
                          </Typography>
                        ))}
                      <p
                        style={{
                          color: "#727272",
                          fontSize: "1.2rem",
                          textAlign: "center",
                          position: "relative",
                          bottom: "5px",
                          width: "100%",
                        }}
                      >
                        Trending Topics
                      </p>
                    </div>

                    {/* <p
                      style={{
                        color: "#727272",
                        fontSize: "1.2rem",
                        textAlign: "center",
                        position: "absolute",
                        bottom: "-10px",
                        width: "100%",
                      }}
                    >
                      Trending Topics
                    </p> */}
                  </>
                ) : negativeCultureData?.status === "fail" ||
                  currentWorkflowID === "" ? (
                  <div
                    style={{
                      position: "relative",
                      top: "100px",
                    }}
                  >
                    <ErrorOutlineIcon style={{ color: "#94989f" }} />
                    <p
                      style={{
                        margin: "0",
                        color: "#94989f",
                      }}
                    >
                      No Data found!
                    </p>
                  </div>
                ) : (
                  <Skeleton
                    variant="circular"
                    width={"250px"}
                    height={"250px"}
                    style={{
                      position: "relative",
                      top: "30px",
                      margin: "0 auto",
                    }}
                  />
                )}
              </div>
            </div>
          </Grid>
        )}
      </Grid>

      <Modal
        open={configModalOpen}
        onClose={() => {
          setConfigModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ConfigModal
          handleClose={() => {
            setConfigModalOpen(false);
          }}
        />
      </Modal>
    </div>
  );
}

export default TopStatusBox;
