import React from "react";

const Step2Modal = ({
  handleClose,
  setCurrentScreen,
  currentScreen,
  currentStep,
  setCurrentStep,
}) => {
  const handleNextStep = () => {
    // handleClose();
    // setCurrentScreen("logic");
    setCurrentStep(3);
  };

  const handleSkip = () => {
    handleClose();
    localStorage.setItem("skipped", true);
  };

  return (
    <>
     
      <div
        className="addChartsContainer"
        style={{
          width: "24vw",
          marginTop: "55px",
          position: "absolute",
          left: "6%",
          top: "17%",
        }}
      >
        <div style={{ color: "#484848", fontSize: "1rem" }}>
          <b>Customize Your View!</b>
        </div>
        <p style={{ color: "#484848" }}>
          {/* <b> */}
          You can select from the available filters on the left-hand side and
          apply them to see changes reflected across all the graphs on the
          dashboard.
          {/* </b> */}
        </p>
        <div className="btnOptions" style={{ marginTop: "0px" }}>
          <div className="submitBtn">
            <div className="rangeBtn" type="submit" onClick={handleNextStep}>
              <div className="btnText">{"NEXT >>"}</div>
            </div>
          </div>
          <div className="submitBtn">
            <div className="rangeBtn" type="submit" onClick={handleSkip}>
              <div className="btnText">{"SKIP >>>>"}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step2Modal;
