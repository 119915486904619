import {
   
} from "./Overview.types";

const initialState = {
  
};

const OverviewReducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default OverviewReducer;
