/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from "react";
import "../../../styles/loginPage.css";
import "../../../styles/profilePage.css";
import { useDispatch } from "react-redux";
import "../../../styles/setting.css";
import TurnedInNotIcon from "@material-ui/icons/TurnedInNot";
import ClearIcon from "@material-ui/icons/Clear";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import Select from "react-select";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import {
  UPDATE_USER,
  GET_USERS,
} from "../../../redux/Dashboard/Dashboard.types";

export default function UpdateUserModal({ handleClose, userData }) {
  const levelOptions = [
    { value: "admin", label: "Admin" },
    { value: "manager", label: "Manager" },
    { value: "employee", label: "Employee" },
  ];
  const deptOptions = [
    { value: "Accounting & Finance", label: "Accounting & Finance" },
    { value: "Engineering", label: "Engineering" },
    { value: "Human Resources", label: "Human Resources" },
    { value: "Information Technology", label: "Information Technology" },
    { value: "Operations", label: "Operations" },
    { value: "Research & Development", label: "Research & Development" },
    { value: " Sales & Marketing", label: " Sales & Marketing" },
  ];
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const [email, setEmail] = useState(userData.email);
  const [firstName, setFirstName] = useState(userData.first_name);
  const [lastName, setLastName] = useState(userData.last_name);
  const [selectedLevel, setSelectedLevel] = useState(userData.level);
  const [selectedDept, setSelectedDept] = useState(userData.department);
  const [directReports, setDirectReports] = useState(
    userData.access.direct_reports
  );
  const [overallCompany, setOverallCompany] = useState(
    userData.access.overall_company
  );
  const [filters, SetFilters] = useState(userData.access.filters);
  const [keyInsights, setKeyInsights] = useState(userData.access.key_insights);
  const [morale, setMorale] = useState(userData.access.morale);
  const [engagement, setEngagement] = useState(userData.access.engagement);
  const [retentionRisk, setRetentionRisk] = useState(
    userData.access.retention_risk
  );
  const [supporterDetractor, setSupporterDetractor] = useState(
    userData.access.supporter_detractor
  );
  const [topicAnalysis, setTopicAnalysis] = useState(
    userData.access.topic_analysis
  );
  const [culture, setCulture] = useState(userData.access.culture);
  const handleClick = (event) => {
  };

  useEffect(() => {
    handleClick();
  }, []);

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const handleAddUser = () => {
    if (firstName === "") {
      toast.error("Please fill First Name");
    } else if (lastName === "") {
      toast.error("Please fill Last Name");
    } else if (email === "") {
      toast.error("Please fill Email");
    } else {
      !isDemo &&
        dispatch({
          type: UPDATE_USER,
          payload: {
            first_name: firstName,
            last_name: lastName,
            email,
            level: selectedLevel.label,
            department: selectedDept.label,
            access: {
              direct_reports: directReports,
              overall_company: overallCompany,
              filters: filters,
              key_insights: keyInsights,
              morale: morale,
              engagement: engagement,
              retention_risk: retentionRisk,
              supporter_detractor: supporterDetractor,
              topic_analysis: topicAnalysis,
              culture: culture,
            },
            permissions: {},
          },
        });
      // });
      setTimeout(() => {
        !isDemo &&
          dispatch({
            type: GET_USERS,
          });
      }, 1500);
      handleClose();
    }
  };

  return (
    <div className="userManagementCard" style={{ overflow: "scroll" }}>
      <div style={{ margin: "0 2rem" }}>
        <p style={{ color: "#000", margin: "0" }}>{email}</p>
        <p style={{ color: "#000", margin: "0" }}>{userData.level}</p>
        <p style={{ color: "#000", margin: "0" }}>{userData.department}</p>
      </div>
      <div style={{ padding: "0 20px", margin: "20px" }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <div className="inputContainer">
              <label className="formLabels form_label">First Name</label>
              <input
                type="text"
                name="first-name"
                value={firstName}
                onChange={(event) => {
                  setFirstName(event.target.value);
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div className="inputContainer">
              <label className="formLabels form_label">Last Name</label>
              <input
                type="text"
                name="last-name"
                value={lastName}
                onChange={(event) => {
                  setLastName(event.target.value);
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6}>
            <div id="countryFlag" className="inputContainer">
              <label className="formLabels form_label" htmlFor="number">
                Level
              </label>
              <div className="countryContainer">
                <Select
                  defaultValue={selectedLevel}
                  onChange={setSelectedLevel}
                  options={levelOptions}
                  sx={{ padding: "0", fontSize: "0.8rem" }}
                ></Select>
              </div>
            </div>
          </Grid>
          <Grid item xs={6}>
            {" "}
            <div id="countryFlag" className="inputContainer">
              <label className="formLabels form_label" htmlFor="number">
                Department
              </label>
              <div className="countryContainer">
                <Select
                  // onChange={handleChangeLevel}
                  // IconComponent={ExpandMoreIcon}
                  defaultValue={selectedDept}
                  onChange={setSelectedDept}
                  options={deptOptions}
                  sx={{ padding: "0", fontSize: "0.8rem" }}
                ></Select>
              </div>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div id="countryFlag" className="inputContainer">
              <label
                style={{ color: "#000" }}
                className="formLabels form_label"
                htmlFor="number"
              >
                Access
              </label>

              <FormGroup
                sx={{
                  display: "flex !important",
                  flexDirection: "row !important",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={directReports}
                      onChange={(e) => {
                        setDirectReports(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <span style={{ fontSize: "14px" }}>Direct Reports</span>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={overallCompany}
                      onChange={(e) => {
                        setOverallCompany(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <span style={{ fontSize: "14px" }}>Overall Company</span>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={filters}
                      onChange={(e) => {
                        SetFilters(e.target.checked);
                      }}
                    />
                  }
                  label={<span style={{ fontSize: "14px" }}>Filters</span>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={keyInsights}
                      onChange={(e) => {
                        setKeyInsights(e.target.checked);
                      }}
                    />
                  }
                  label={<span style={{ fontSize: "14px" }}>Key Insights</span>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={morale}
                      onChange={(e) => {
                        setMorale(e.target.checked);
                      }}
                    />
                  }
                  label={<span style={{ fontSize: "14px" }}>Morale</span>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={engagement}
                      onChange={(e) => {
                        setEngagement(e.target.checked);
                      }}
                    />
                  }
                  label={<span style={{ fontSize: "14px" }}>Engagement</span>}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={retentionRisk}
                      onChange={(e) => {
                        setRetentionRisk(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <span style={{ fontSize: "14px" }}>Retention Risk</span>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={supporterDetractor}
                      onChange={(e) => {
                        setSupporterDetractor(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <span style={{ fontSize: "14px" }}>
                      Supporter/Detractor
                    </span>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={topicAnalysis}
                      onChange={(e) => {
                        setTopicAnalysis(e.target.checked);
                      }}
                    />
                  }
                  label={
                    <span style={{ fontSize: "14px" }}>Topic Analysis</span>
                  }
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={culture}
                      onChange={(e) => {
                        setCulture(e.target.checked);
                      }}
                    />
                  }
                  label={<span style={{ fontSize: "14px" }}>Culture</span>}
                />
              </FormGroup>
            </div>
          </Grid>
        </Grid>
      </div>
      <div
        className="btnOptions"
        style={{ margin: "30px 30px 0 0", position: "relative", right: "20px" }}
      >
        <div className="cancelBtn" onClick={handleClose}>
          <div className="rangeBtn">
            <ClearIcon style={{ color: "#000" }} />
            <div className="btnText" style={{ color: "#000" }}>
              Cancel
            </div>
          </div>
        </div>
        <div className="submitBtn">
          <div className="rangeBtn" type="submit" onClick={handleAddUser}>
            <TurnedInNotIcon style={{ color: "#000" }} />
            <div className="btnText" style={{ color: "#000" }}>
              Save
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
