export function getColor(isNight) {
  if (isNight) {
    return "#000";
  } else {
    return "#aebcc9";
  }
}
export function getBackground(isNight) {
  if (isNight) {
    return "#000";
  } else {
    return "#aebcc9";
  }
}
