import { useState } from "react";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import api from "../../../../services/axiosConfig";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Tooltip } from "@material-ui/core";
import ToggleButton from "@mui/material/ToggleButton";
import { Link } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Modal from "@mui/material/Modal";
import { Box } from "@material-ui/core";
import OutlinedInput from "@mui/material/OutlinedInput";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MenuItem from "@material-ui/core/MenuItem";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupsIcon from "@mui/icons-material/Groups";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import CallToActionIcon from "@mui/icons-material/CallToAction";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import "./index.css";
import {
  demoMultipleInsights,
  demoSingleInsights,
  demoNoneInsights,
  demoEventSingleInsight,
  demoEventMultipleInsight,
  demoEventNoneInsight,
  demoTopicNoneInsight,
  demoTopicMultipleInsight,
  demoTopicSingleInsight,
} from "../demoData";
import EventBasedInsights from "./EventBasedInsights";
import { demoSurveys } from "../../Feedback/demoSurveys";
import _ from "lodash";
import ConfigModal from "./ConfigModal";
import FocusGroupModal from "./FocusGroupModal";
import CreateSurveyModal from "./CreateSurveyModal";
import CreateActionPlanModal from "./CreateActionPlanModal";
import { demoActionPlans } from "../../Assistant/Recommendation/demoActionPlans";
import { demoFavoritesInsights } from "./demoData";

export default function Insights() {
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const singleInsights = isDemo
    ? demoSingleInsights
    : useSelector((data) => data.dashboard.singleInsights);
  const multipleInsights = isDemo
    ? demoMultipleInsights
    : useSelector((data) => data.dashboard.multipleInsights);
  const noneInsights = isDemo
    ? demoNoneInsights
    : useSelector((data) => data.dashboard.noneInsights);

  const topicSingleInsights = isDemo
    ? demoTopicSingleInsight
    : useSelector((data) => data.dashboard.topicSingleInsights);
  const topicMultipleInsights = isDemo
    ? demoTopicMultipleInsight
    : useSelector((data) => data.dashboard.topicMultipleInsights);
  const topicNoneInsights = isDemo
    ? demoTopicNoneInsight
    : useSelector((data) => data.dashboard.topicNoneInsights);

  const eventSingleInsights = isDemo
    ? demoEventSingleInsight
    : useSelector((data) => data.dashboard.eventSingleInsights);
  const eventMultipleInsights = isDemo
    ? demoEventMultipleInsight
    : useSelector((data) => data.dashboard.eventMultipleInsights);
  const eventNoneInsights = isDemo
    ? demoEventNoneInsight
    : useSelector((data) => data.dashboard.eventNoneInsights);

  const surveyData = isDemo
    ? demoSurveys
    : useSelector((data) => data.dashboard.surveyQuestion);

  const actionPlans = isDemo
    ? demoActionPlans
    : useSelector((data) => data.dashboard.actionPlans);

  const [configModalOpen, setConfigModalOpen] = useState(false);
  const [focusModalOpen, setFocusModalOpen] = useState(false);
  const [surveyModalOpen, setSurveyModalOpen] = useState(false);
  const [actionPlanModalOpen, setActionPlanModalOpen] = useState(false);
  const [currentInsight, setCurrentInsight] = useState({});
  const [selectedOption, setSelectedOption] = useState("Morale");
  const [selectedSwich, setSelectedSwitch] = useState("none");
  const [positiveOrNegative, setPositiveOrNegative] = useState("negative");
  const [selectedNumber, setSelectedNumber] = useState(2);
  const [eventBased, setEventBased] = useState(false);
  const [topicBased, setTopicBased] = useState(false);
  const [points, setPoints] = useState([]);
  const [favoritePoints, setFavoritePoints] = useState([]);
  const [favoriteFilter, setFavoriteFilter] = useState("top");
  const [insights, setInsights] = useState({});
  const [insightByMonth, setInsightByMonth] = useState([]);
  const [favInsightsByMonth, setFavInsightsByMonth] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(getLastMonth(insights));

  useEffect(() => {
    if (eventBased) {
      selectedSwich === "single"
        ? setInsights(eventSingleInsights)
        : selectedSwich === "multiple"
        ? setInsights(eventMultipleInsights)
        : setInsights(eventNoneInsights);
    } else if (topicBased) {
      selectedSwich === "single"
        ? setInsights(topicSingleInsights)
        : selectedSwich === "multiple"
        ? setInsights(topicMultipleInsights)
        : setInsights(topicNoneInsights);
    } else {
      selectedSwich === "single"
        ? setInsights(singleInsights)
        : selectedSwich === "multiple"
        ? setInsights(multipleInsights)
        : setInsights(noneInsights);
    }
  }, [
    eventSingleInsights,
    eventMultipleInsights,
    eventNoneInsights,
    topicSingleInsights,
    topicMultipleInsights,
    topicNoneInsights,
    singleInsights,
    multipleInsights,
    noneInsights,
    selectedSwich,
    topicBased,
    eventBased,
    selectedMonth,
  ]);

  const changeMonth = (event) => {
    setSelectedMonth(parseInt(event.target.value));
    setSelectedSwitch("multiple");
  };
  useEffect(() => {
    setInsightByMonth(
      !_.isEmpty(insights) &&
        insights.filter(
          (item) => moment(item.timeProcessed).month() + 1 === selectedMonth
        )[0]
    );
  }, [insights]);

  useEffect(() => {
    setFavInsightsByMonth(
      !_.isEmpty(demoFavoritesInsights) &&
        demoFavoritesInsights.filter(
          (item) => moment(item.month).month() + 1 === selectedMonth
        )[0]
    );
  }, [demoFavoritesInsights]);

  console.log(favInsightsByMonth);

  const getPoints = (insight, option) => {
    if (!insight) return [];
    switch (option) {
      case "Morale":
        return selectedSwich === "none"
          ? insight.insight?.morale
          : positiveOrNegative === "positive"
          ? insight.positiveinsight
            ? insight.positiveinsight?.morale || []
            : insight.insight?.morale
          : insight.negativeinsight
          ? insight.negativeinsight?.morale || []
          : insight.insight?.morale;
      case "Engagement":
        return selectedSwich === "none"
          ? insight.insight?.engagement
          : positiveOrNegative === "positive"
          ? insight.positiveinsight
            ? [...(insight.positiveinsight?.engagement || [])]
            : insight.insight?.engagement
          : insight.negativeinsight
          ? [...(insight.negativeinsight?.engagement || [])]
          : [...(insight.insight?.engagement || [])];
      case "Retention Risk":
        return selectedSwich === "none"
          ? insight.insight?.retention
          : positiveOrNegative === "positive"
          ? insight.positiveinsight
            ? insight.positiveinsight?.retention || []
            : insight.insight?.retention
          : insight.negativeinsight
          ? insight.negativeinsight?.retention || []
          : insight.insight?.retention;
      case "eNPS":
        return selectedSwich === "none"
          ? insight.insight?.enps
          : positiveOrNegative === "positive"
          ? insight.positiveinsight
            ? [...(insight.positiveinsight?.enps || [])]
            : insight.insight?.enps
          : insight.negativeinsight
          ? [...(insight.negativeinsight?.enps || [])]
          : insight.insight?.enps;

      case "Topics":
        return selectedSwich === "none"
          ? insight.insight?.topic
          : positiveOrNegative === "positive"
          ? insight.positiveinsight
            ? [...(insight.positiveinsight?.topic || [])]
            : insight.insight?.topic
          : insight.negativeinsight
          ? [...(insight.negativeinsight?.topic || [])]
          : insight.insight?.topic;
      case "Culture":
        return selectedSwich === "none"
          ? insight.insight?.culture
          : positiveOrNegative === "positive"
          ? insight.positiveinsight
            ? [...(insight.positiveinsight?.culture || [])]
            : insight.insight?.culture
          : insight.negativeinsight
          ? [...(insight.negativeinsight?.culture || [])]
          : insight.insight?.culture;
      default:
        return [];
    }
  };

  const getFavoritePoints = (insight, option) => {
    if (!insight) return [];
    switch (option) {
      case "Morale":
        return insight?.morale;
      case "Engagement":
        return insight?.engagement;
      case "Retention Risk":
        return insight?.retention;
      case "eNPS":
        return insight?.enps;
      case "Topics":
        return insight?.topic;
      case "Culture":
        return insight?.culture;
      default:
        return [];
    }
  };
  useEffect(() => {
    favoriteFilter === "favorite"
      ? setPoints(getFavoritePoints(favInsightsByMonth, selectedOption))
      : setPoints(getPoints(insightByMonth, selectedOption));
  }, [
    insightByMonth,
    selectedSwich,
    selectedOption,
    insights,
    selectedMonth,
    positiveOrNegative,
    favoriteFilter,
    demoFavoritesInsights,
  ]);

  const options = [
    "Morale",
    "Engagement",
    "Retention Risk",
    "eNPS",
    // "Supporter/Detractor",
    "Topics",
    "Culture",
  ];

  function getLastMonth(insights) {
    if (insights.length === 0) return null;

    const lastInsight = insights[insights.length - 1];
    const lastMonth = moment(lastInsight?.timeProcessed).month() + 1; // moment().month() returns 0-11, adding 1 for 1-12
    return lastMonth;
  }

  const renderinsight = (item, index) => {
    const currentSurvey = surveyData.filter(
      (survey) => survey.insightID === item.surveyID
    )[0];

    const currentActionPlan = actionPlans.filter(
      (plan) => plan.insightID === item.surveyID
    )[
      actionPlans.filter((plan) => plan.insightID === item.surveyID).length - 1
    ];

    const wordsToBold = [
      "decline",
      "increase",
      "decreased",
      "decrease",
      "increased",
      "dropped",
      "rose",
    ];

    // Combine wordsToBold and percentage regex
    const regex = new RegExp(
      `(${wordsToBold.join("|")}|\\d+(\\.\\d+)?%)`, // Matches words or valid percentages
      "gi"
    );

    // Replace matches with bold tags directly
    const renderBoldText = (inputText) =>
      inputText.replace(regex, (match) => `<strong>${match}</strong>`);

    return (
      <li
        style={{
          background: "#fff",
          borderRadius: "8px",
          color: "#484848",
          textAlign: "left",
          margin: "0.5rem 0",
        }}
      >
        <span
          dangerouslySetInnerHTML={{
            __html: renderBoldText(item.statement?.trim()),
          }}
        />

        <Tooltip
          title={
            <span
              style={{
                fontSize: "0.8rem",
                textAlign: "center",
              }}
            >
              Create Focus Group
            </span>
          }
        >
          <GroupsIcon
            onClick={() => {
              setFocusModalOpen(true);
              setCurrentInsight(item);
            }}
            style={{
              color: "#727272",
              fontSize: "1.5rem",
              cursor: "pointer",
            }}
          />
        </Tooltip>
        {!_.isEmpty(currentSurvey) && (
          <Link
            target="_blank"
            to={`/dashboard/feedback/survey/${currentSurvey._id}`}
            style={{
              textDecoration: "none",
              color: "#484848",
            }}
          >
            <Tooltip
              title={
                <span
                  style={{
                    fontSize: "0.8rem",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  View Survey
                </span>
              }
            >
              <FileOpenIcon
                style={{
                  color: "#727272",
                  fontSize: "1.2rem",
                }}
              />
            </Tooltip>
          </Link>
        )}
        {_.isEmpty(currentSurvey) && (
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                Create Survey
              </span>
            }
          >
            <NoteAddIcon
              onClick={() => {
                setSurveyModalOpen(true);
                setCurrentInsight(item);
              }}
              style={{
                color: "#727272",
                fontSize: "1.2rem",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        )}
        {!_.isEmpty(currentActionPlan) && (
          <Link
            target="_blank"
            to={`/dashboard/action-plan/${currentActionPlan._id}`}
            style={{
              textDecoration: "none",
              color: "#484848",
            }}
          >
            <Tooltip
              title={
                <span
                  style={{
                    fontSize: "0.8rem",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  View Action Plan
                </span>
              }
            >
              <PendingActionsIcon
                style={{
                  color: "#727272",
                  fontSize: "1.2rem",
                }}
              />
            </Tooltip>
          </Link>
        )}
        {_.isEmpty(currentActionPlan) && (
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                  cursor: "pointer",
                }}
              >
                Create Action Plan
              </span>
            }
          >
            <CallToActionIcon
              onClick={() => {
                setActionPlanModalOpen(true);
                setCurrentInsight(item);
              }}
              style={{
                color: "#727272",
                fontSize: "1.2rem",
                cursor: "pointer",
              }}
            />
          </Tooltip>
        )}
      </li>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        margin: "2rem 0 1.5rem",
      }}
    >
      <Grid
        container
        spacing={3}
        style={{
          background: !isNight ? "#2A2953" : "#fff",
          borderRadius: "12px",
          marginLeft: ".1rem",
          padding: "1rem 0 0",
          display: "flex",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        {" "}
        <SettingsIcon
          onClick={() => {
            setConfigModalOpen(true);
          }}
          style={{
            color: "#727272",
            cursor: "pointer",
            zIndex: "400",
            position: "absolute",
            right: "65px",
            top: "-60px",
          }}
        />
        <div class="switches-container">
          <Box component="div" position="relative" right="10px">
            <Box>
              <FormControl fullWidth>
                <Select
                  value={selectedMonth}
                  input={<OutlinedInput sx={{ padding: "0" }} />}
                  inputProps={{ "aria-label": "Without label" }}
                  onChange={changeMonth}
                  sx={{ padding: "0", fontSize: "0.8rem" }}
                >
                  {!_.isEmpty(insights) &&
                    insights
                      .sort(
                        (a, b) =>
                          new Date(b.timeProcessed) - new Date(a.timeProcessed)
                      )
                      .map((item) => (
                        <MenuItem
                          value={moment(item.timeProcessed).month() + 1}
                        >
                          {moment(item.timeProcessed).format("MMMM 'YY")}
                        </MenuItem>
                      ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box
            style={{
              position: "absolute",
              right: "350px",
              filter:
                selectedSwich === "none" || favoriteFilter === "favorite"
                  ? "opacity(0.5)"
                  : "",
              pointerEvents:
                selectedSwich === "none" || favoriteFilter === "favorite"
                  ? "none"
                  : "",
              cursor:
                selectedSwich === "none" || favoriteFilter === "favorite"
                  ? "not-allowed"
                  : "",
            }}
          >
            <ToggleButtonGroup
              size="small"
              value={positiveOrNegative}
              exclusive
              // onChange={handleSwitch}
              aria-label="text alignment"
            >
              <ToggleButton
                onClick={() => {
                  setPositiveOrNegative("positive");
                }}
                value="positive"
                aria-label="left aligned"
                style={{ padding: "0.8rem" }}
              >
                Positive
              </ToggleButton>
              <ToggleButton
                onClick={() => {
                  setPositiveOrNegative("negative");
                }}
                value="negative"
                aria-label="centered"
                style={{ padding: "0.8rem" }}
              >
                Negative
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Box style={{ position: "absolute", right: "550px" }}>
            <Tooltip
              title={
                <span
                  style={{
                    fontSize: "0.8rem",
                    textAlign: "center",
                  }}
                >
                  Favorite filter insights or top insights
                </span>
              }
            >
              <ToggleButtonGroup
                size="medium"
                value={favoriteFilter}
                exclusive
                // onChange={handleSwitch}
                aria-label="text alignment"
              >
                <ToggleButton
                  onClick={() => {
                    setFavoriteFilter("favorite");
                  }}
                  value="favorite"
                  aria-label="left aligned"
                >
                  Favorite
                </ToggleButton>
                <ToggleButton
                  onClick={() => {
                    setFavoriteFilter("top");
                  }}
                  value="top"
                  aria-label="centered"
                >
                  Top
                </ToggleButton>
              </ToggleButtonGroup>
            </Tooltip>
          </Box>
          <Box
            style={{
              filter: favoriteFilter === "favorite" ? "opacity(0.5)" : "",
              pointerEvents: favoriteFilter === "favorite" ? "none" : "",
              cursor: favoriteFilter === "favorite" ? "not-allowed" : "",
            }}
          >
            <Tooltip
              title={
                <span
                  style={{
                    fontSize: "0.8rem",
                    textAlign: "center",
                  }}
                >
                  Number of filter categories used to generate the insights
                </span>
              }
            >
              <ToggleButtonGroup
                size="small"
                value={selectedSwich}
                exclusive
                // onChange={handleSwitch}
                aria-label="text alignment"
              >
                <ToggleButton
                  onClick={() => {
                    setSelectedSwitch("none");
                  }}
                  value="none"
                  aria-label="left aligned"
                >
                  None
                </ToggleButton>
                <ToggleButton
                  onClick={() => {
                    setSelectedSwitch("single");
                  }}
                  value="single"
                  aria-label="centered"
                >
                  Single
                </ToggleButton>
                <ToggleButton
                  onClick={() => {
                    setSelectedSwitch("multiple");
                  }}
                  value="multiple"
                  aria-label="right aligned"
                >
                  Multiple
                </ToggleButton>
              </ToggleButtonGroup>
            </Tooltip>
          </Box>
        </div>
        <ContentCopyOutlinedIcon
          onClick={() => {
            navigator.clipboard.writeText(
              `Insights: \n \n ${points.map((item, id) => `${item}\n`)}`
            );
          }}
          style={{
            fontSize: "1.2rem",
            cursor: "pointer",
            position: "absolute",
            top: "75px",
            right: "15px",
          }}
        />
        <Grid item xs={3.5}>
          <div
            style={{
              width: "100%",
              margin: "70px 0 20px",
            }}
          >
            {options.map((item) => (
              <li
                onClick={() => {
                  setSelectedOption(item);
                }}
                style={{
                  listStyle: "none",
                  margin: "0.5rem 1rem",
                  padding: "0.5rem",
                  width: "100%",
                  background: selectedOption === item ? "#E8E8E8" : "#fff",
                  borderRadius: "6px",
                  fontWeight: "600",
                  color: "#484848",
                  cursor: "pointer",
                }}
              >
                {item}
              </li>
            ))}
          </div>
        </Grid>
        <Grid item xs={7.5}>
          <div
            style={{
              width: "95%",
              margin: "70px 0 20px",
            }}
          >
            <p
              style={{
                textAlign: "left",
                fontWeight: "600",
                color: "#484848",
                margin: "1rem 0 1rem",
              }}
            >
              {selectedOption} Insights
            </p>
            <div style={{ height: "250px", overflowY: "scroll" }}>
              {!_.isEmpty(points) ? (
                <div>
                  {points && points.slice(0, selectedNumber).map(renderinsight)}
                  <div
                    style={{
                      width: "100%",
                      justifyContent: "start",
                      display: "flex",
                    }}
                  >
                    {selectedNumber === 2 && (
                      <Tooltip
                        title={
                          <span
                            style={{
                              fontSize: "0.8rem",
                              textAlign: "center",
                            }}
                          >
                            See More
                          </span>
                        }
                      >
                        <MoreHorizIcon
                          onClick={() => {
                            setSelectedNumber(points.length);
                          }}
                          style={{ fontSize: "2rem", cursor: "pointer" }}
                        />
                      </Tooltip>
                    )}
                    {selectedNumber !== 2 && (
                      <Tooltip
                        title={
                          <span
                            style={{
                              fontSize: "0.8rem",
                              textAlign: "center",
                            }}
                          >
                            See Less
                          </span>
                        }
                      >
                        <ExpandLessIcon
                          onClick={() => {
                            setSelectedNumber(2);
                          }}
                          style={{ fontSize: "2rem", cursor: "pointer" }}
                        />
                      </Tooltip>
                    )}
                  </div>
                </div>
              ) : (
                <Stack spacing={1}>
                  <Skeleton variant="text" width={"95%"} height={"60px"} />
                  <Skeleton
                    variant="rectangular"
                    width={"95%"}
                    height={"150px"}
                  />
                </Stack>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
      <Modal
        open={configModalOpen}
        onClose={() => {
          setConfigModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ConfigModal
          eventBased={eventBased}
          topicBased={topicBased}
          setEventBased={setEventBased}
          setTopicBased={setTopicBased}
          handleClose={() => {
            setConfigModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        open={focusModalOpen}
        onClose={() => {
          setFocusModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <FocusGroupModal
          currentInsight={currentInsight}
          metric={selectedOption}
          handleClose={() => {
            setFocusModalOpen(false);
          }}
        />
      </Modal>

      <Modal
        open={surveyModalOpen}
        onClose={() => {
          setSurveyModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateSurveyModal
          currentInsight={currentInsight}
          metric={selectedOption}
          handleClose={() => {
            setSurveyModalOpen(false);
          }}
        />
      </Modal>

      <Modal
        open={actionPlanModalOpen}
        onClose={() => {
          setActionPlanModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <CreateActionPlanModal
          currentInsight={currentInsight}
          metric={selectedOption}
          handleClose={() => {
            setActionPlanModalOpen(false);
          }}
        />
      </Modal>
    </div>
  );
}
