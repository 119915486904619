import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { demoSurveys } from "../demoSurveys";
import axios from "axios";
import Stack from "@mui/material/Stack";
import { Box, Tooltip, Typography } from "@material-ui/core";
import Chart from "react-apexcharts";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import Modal from "@mui/material/Modal";
import EventIcon from "@mui/icons-material/Event";
import CustomDatePicker from "../../Overview/CustomDatePicker";
import IosShareIcon from "@mui/icons-material/IosShare";
import _ from "lodash";

const categories = [
  "Accomplishment",
  "Autonomy & Empowerment",
  "Company Benefits",
  "Career Development & Growth",
  "Coaching & Mentorship",
  "Collaboration",
  "Commitment",
  "Communication",
  "Compensation & Pay Scale",
  "Diversity, Equity & Inclusion",
  "Freedom of Opinion",
  "Job Satisfaction",
  "Leadership",
  "job change",
  "Management Support",
  "Meaningful Work",
  "management support",
  "meaningful work",
  "Mental Health",
  "Motivation",
  "Company Culture",
  "passion for the mission",
  "peer relationships",
  "Recognition",
  "sense of purpose",
  "strategy",
  "work culture",
  "workload",
  "workplace environment"
];


function Report({ setSelectedTab }) {
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const surveyData = isDemo
    ? demoSurveys
    : useSelector((data) => data.dashboard.surveyQuestion);

 

  // Function to filter questions by classification category
  function filterQuestionsByCategory(category) {
    const filteredQuestions = [];
    surveyData.forEach((survey) => {
      const surveyQuestions = survey.questions.filter((question) =>
        question.classification.includes(category)
      );
      filteredQuestions.push(...surveyQuestions);
    });
    return filteredQuestions;
  }

  function calculateResponsesStats(data) {
    let totalPositive = 0;
    let totalNegative = 0;
    let totalNeutral = 0;
    data.forEach((question) => {
      question?.response?.forEach((response) => {
        const value = response?.answer ? Object.values(response?.answer)[0] : 0;
        if (value > 0) {
          totalPositive++;
        } else if (value < 0) {
          totalNegative++;
        } else if (value === 0) {
          totalNeutral++;
        }
      });
    });

    return {
      totalPositive,
      totalNegative,
      totalNeutral,
    };
  }

  const [survey, setSurvey] = React.useState("");
  const [currentFilter, setCurrentFilter] = useState(isDemo ? "3M" : "max");
  const [customDate, setcustomDate] = useState({ start: "", end: "" });
  const [customDatePickerOpen, setCustomDatePickerOpen] = useState(false);

  const handleChangeSurvey = (event) => {
    setSurvey(event.target.value);
  };
  const onDateChange = (type, value) => {
    const date = new Date(value);
    const formattedDate = date
      .toLocaleDateString("en-GB", {
        day: "numeric",
        month: "short",
        year: "numeric",
      })
      .replace(/ /g, "-");
    if (type === "start") {
      setcustomDate({ ...customDate, start: formattedDate });
    } else if (type === "end") {
      setcustomDate({ ...customDate, end: formattedDate });
    }
    setCurrentFilter("custom");
  };
  return (
    <Box sx={{ padding: "1rem" }}>
      <Box
        sx={{
          margin: "1rem",
          background: "#e5e5e5",
          padding: "1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Typography
            style={{
              textAlign: "left",
              fontWeight: "bold",
              fontSize: "1.1rem",
              marginTop: "1rem",
            }}
          >
            {survey.length > 0 ? survey : "Overall"}
          </Typography>
          {/* <Tooltip title="Download Report">
            <IosShareIcon
              style={{
                margin: "0.8rem 0.5rem 0",
                filter: "invert(0.5)",
                cursor: "pointer",
                maxHeight: "50px",
              }}
              
            />
          </Tooltip> */}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          {/* <Box sx={{ minWidth: 120, maxWidth: 300, marginX: "0.5rem" }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Survey</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={survey}
                label="Survey"
                onChange={handleChangeSurvey}
              >
                {surveyData.map((item) => (
                  <MenuItem key={item._id} value={item._id}>
                    {item.surveyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>{" "}
          </Box> */}
          <div
            style={{
              display: "flex",
              margin: " 0.5rem ",
            }}
          >
            <div
              className="filterContainer"
              onClick={() => {
                setCustomDatePickerOpen(true);
              }}
              style={
                currentFilter === "custom"
                  ? {
                      backgroundColor: "#D06283",
                      color: "#fff",
                    }
                  : {}
              }
            >
              <EventIcon
                style={
                  currentFilter !== "custom"
                    ? {
                        color: "#A1A4AC",
                        fontSize: "14px",
                        position: "relative",
                        top: "3px",
                      }
                    : {
                        color: "#fff",
                        fontSize: "14px",
                        position: "relative",
                        top: "3px",
                      }
                }
              />
              {customDate.start !== ""
                ? `${customDate.start} - ${customDate.end}`
                : "Custom"}
            </div>
            <Modal
              open={customDatePickerOpen}
              onClose={() => {
                setCustomDatePickerOpen(false);
              }}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <CustomDatePicker
                currentDate={customDate}
                open={customDatePickerOpen}
                handleClose={() => {
                  setCustomDatePickerOpen(false);
                }}
                title="Choose a time"
                handleDateChange={onDateChange}
              />
            </Modal>
            <div
              className="filterContainer"
              onClick={() => {
                setCurrentFilter("1D");
              }}
              style={
                currentFilter === "1D"
                  ? {
                      backgroundColor: "#D06283",
                      color: "#fff",
                    }
                  : {}
              }
            >
              1D
            </div>
            <div
              className="filterContainer"
              onClick={() => {
                setCurrentFilter("5D");
              }}
              style={
                currentFilter === "5D"
                  ? {
                      backgroundColor: "#D06283",
                      color: "#fff",
                    }
                  : {}
              }
            >
              5D
            </div>
            <div
              className="filterContainer"
              onClick={() => {
                setCurrentFilter("1M");
              }}
              style={
                currentFilter === "1M"
                  ? {
                      backgroundColor: "#D06283",
                      color: "#fff",
                    }
                  : {}
              }
            >
              1M
            </div>
            <div
              className="filterContainer"
              onClick={() => {
                setCurrentFilter("3M");
              }}
              style={
                currentFilter === "3M"
                  ? {
                      backgroundColor: "#D06283",
                      color: "#fff",
                    }
                  : {}
              }
            >
              3M
            </div>
            <div
              className="filterContainer"
              onClick={() => {
                setCurrentFilter("1Y");
              }}
              style={
                currentFilter === "1Y"
                  ? {
                      backgroundColor: "#D06283",
                      color: "#fff",
                    }
                  : {}
              }
            >
              1Y
            </div>
            <div
              className="filterContainer"
              onClick={() => {
                setCurrentFilter("max");
              }}
              style={
                currentFilter === "max"
                  ? {
                      backgroundColor: "#D06283",
                      color: "#fff",
                    }
                  : {}
              }
            >
              Max
            </div>
          </div>
        </Box>
      </Box>
      <Grid container spacing={0}>
        {categories.map((item, id) => {
          let stats = calculateResponsesStats(filterQuestionsByCategory(item))
            ? calculateResponsesStats(filterQuestionsByCategory(item))
            : { totalPositive: 0, totalNegative: 0, totalNeutral: 0 };

          const total =
            stats.totalPositive + stats.totalNegative + stats.totalNeutral;

         

          return total ? (
            <Grid item lg={12} xs={12}>
              <Box
                sx={{
                  margin: "0.5rem 1rem",
                  background: "#e5e5e5",
                  padding: "1rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex" }}>
                  <Typography
                    style={{
                      textAlign: "left",
                      fontWeight: "normal",
                      fontSize: "1.1rem",
                    }}
                  >
                    {item}
                  </Typography>

                </Box>
                <Box
                  sx={{ width: "500px", display: "flex", background: "#fff" }}
                >
                  <Tooltip
                    title={
                      <span
                        style={{
                          fontSize: "0.8rem",
                          textAlign: "center",
                        }}
                      >
                        {stats.totalPositive}
                      </span>
                    }
                  >
                    <Box
                      sx={{
                        width: `${(stats.totalPositive / total) * 100}%`,
                        background: "#75C7C3",
                        fontSize: "1rem",
                        padding: "0.5rem 0",
                        color:"#fff"
                      }}
                    >
                      {stats.totalPositive > 0 &&
                        `${parseInt((stats.totalPositive / total) * 100)}%`}
                    </Box>
                  </Tooltip>
                  <Tooltip
                    title={
                      <span
                        style={{
                          fontSize: "0.8rem",
                          textAlign: "center",
                        }}
                      >
                        {stats.totalNeutral}
                      </span>
                    }
                  >
                    <Box
                      sx={{
                        width: `${(stats.totalNeutral / total) * 100}%`,
                        background: "#cca529",
                        fontSize: "1rem",
                        padding: "0.5rem 0",
                        color:"#fff"
                      }}
                    >
                      {stats.totalNeutral > 0 &&
                        `${parseInt((stats.totalNeutral / total) * 100)}%`}
                    </Box>
                  </Tooltip>
                  <Tooltip
                    title={
                      <span
                        style={{
                          fontSize: "0.8rem",
                          textAlign: "center",
                        }}
                      >
                        {stats.totalNegative}
                      </span>
                    }
                  >
                    <Box
                      sx={{
                        width: `${(stats.totalNegative / total) * 100}%`,
                        background: "#C23E65",
                        fontSize: "1rem",
                        padding: "0.5rem 0",
                        color:"#fff"
                      }}
                    >
                      {stats.totalNegative > 0 &&
                        `${parseInt((stats.totalNegative / total) * 100)}%`}
                    </Box>
                  </Tooltip>
                </Box>
              </Box>
            </Grid>
          ) : (
            <></>
          );
        })}
      </Grid>
    </Box>
  );
}

export default Report;
