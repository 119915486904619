import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";

function ChatDelete() {
  const [step, setStep] = useState(1);
  useEffect(() => {
    if (
      window.location.href.includes("/dashboard/setting/configure")
    ) {
      setStep(2);
    } else {
      setStep(1);
    }
  });
  return (
    <Box>
      {step === 1 && (
        <Box>
          <Typography
            style={{
              fontWeight: "bold",
              color: "#fff",
              textAlign: "left",
            }}
          >
            Delete Archived Chats
          </Typography>
          <Typography style={{ color: "#fff", textAlign: "left" }}>
            Please set the interval to delete archived messages.
          </Typography>

          <Link
            to={`/dashboard/setting/configure`}
            style={{ textDecoration: "none", color: "#484848" }}
          >
            <Button
              style={{
                background: "#fff",
                color: "#D06283",
                textTransform: "capitalize",
                margin: "0.5rem",
              }}
              variant="outlined"
            >
              Setup
            </Button>
          </Link>
        </Box>
      )}
      {step === 2 && (
        <Box>
          <Typography
            style={{
              fontWeight: "bold",
              color: "#fff",
              textAlign: "left",
            }}
          >
            Delete Archived Chats
          </Typography>
          <Typography style={{ color: "#fff", textAlign: "left" }}>
            You can update the interval to delete the archived chats, by default
            it is 90 days.
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default ChatDelete;
