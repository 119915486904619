import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../styles/global.css";
import { useDispatch } from "react-redux";
import { LOGIN_EMAIL } from "../redux/Login/Login.types";
import { Box } from "@material-ui/core";
import Navbar from "../Components/Navbar";

const ForgetPasswordPage = () => {
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const handleForgetPass = (e) => {
    e.preventDefault();
    const email = { email: username };
    dispatch({ type: LOGIN_EMAIL, payload: email });

    e.target.value = "";
    setUsername("");
  };

  return (
    <div className="loginPage">
      <div style={{ width: "100%" }}>
        <Navbar isLoggedIn={false} />
      </div>
      <div className="formLoginContainer">
        <div className="formLogo">
          <img src="/Logo-3x.png" alt="logo" width="150px" />
        </div>
        <form className="loginForm">
          <div className="inputContainer">
            <label className="formLabels">Email</label>
            <input
              type="text"
              onChange={(event) => setUsername(event.target.value)}
              placeholder="Enter your email"
              name="uname"
              required
            />
          </div>
          <Box
            component="div"
            sx={{ my: 2 }}
            role="presentation"
            className="submitButton my-2"
          >
            <Link
              to="verify-otp"
              className="td-none"
              onClick={(event) => handleForgetPass(event)}
            >
              <div className="buttonText">Send Link</div>
            </Link>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default ForgetPasswordPage;
