import React from 'react';

// Loader styles
const loaderStyle = {
  background: "#bfe4f5" ,
  padding: "10px",
  margin: "10px 20px",
  borderRadius: '8%',
  maxWidth: "75%", 
  alignSelf: "flex-start", 
  wordWrap: "break-word",
  display: "flex", 
  alignItems: "center",
};

const dotStyle = {
  width: '8px',
  height: '8px',
  margin: '0 4px',
  borderRadius: '50%',
  backgroundColor: '#888',
  animation: 'bounce 1.2s infinite ease-in-out',
};

// Keyframes for bounce animation
const keyframes = `
  @keyframes bounce {
    0%, 80%, 100% { transform: scale(0); }
    40% { transform: scale(1); }
  }
`;

const ChatLoader = () => {
  return (
    <>
      <style>{keyframes}</style>
      <div style={loaderStyle}>
        <span style={{ marginRight: '8px' }}></span>
        <div style={{ display: 'flex' }}>
          <div style={{ ...dotStyle, animationDelay: '0s' }}></div>
          <div style={{ ...dotStyle, animationDelay: '-0.2s' }}></div>
          <div style={{ ...dotStyle, animationDelay: '-0.4s' }}></div>
        </div>
      </div>
    </>
  );
};

export default ChatLoader;
