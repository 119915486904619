import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import SetGoal from "./SetGoal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

// goals: {
//     morale: {},
//     engagement: {
//       overall: {},
//       user: {},
//       message: {},
//       topic: {},
//     },
//     retention: {},
//     enps: {
//       overall: {},
//       topic: {},
//       survey: {},
//     },
//     culture: {
//       overall: {},
//       individual: {},
//     },
//   },
function SetGoalModal({ metric, handleClose }) {
  const dispatch = useDispatch();
  const goals = useSelector((data) => data.dashboard.goals);

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Settings
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />

      <SetGoal metric={metric} handleClose={handleClose} />
    </Box>
  );
}

export default SetGoalModal;
