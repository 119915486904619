import React from "react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import SettingsIcon from "@material-ui/icons/Settings";
import { useSelector } from "react-redux";

const TutorialModal = ({ handleClose, selectedModel }) => {
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  return (
    <div>
      <div
        className="addChartsContainer"
        style={{
          width: "60vw",
          height: "70vh",
          overflowY: "scroll",
          marginTop: "20px",
          background: isNight ? "#f6f7fc" : "#17123b",
        }}
      >
        {" "}
        <div
          style={{
            color: !isNight ? "#aebcc9" : "#666",
            fontSize: "2rem",
            height: "100px",
          }}
        >
          {selectedModel.title}
        </div>
        <div
          style={{
            color: !isNight ? "#aebcc9" : "#666",
            fontSize: "2rem",
            height: "100px",
          }}
          className="closeBtn"
          onClick={handleClose}
        >
          <HighlightOffIcon
            style={{
              fill: !isNight ? "#aebcc9" : "#666",
              position: "absolute",
              top: "15px",
              right: "15px",
            }}
          />
        </div>
        {selectedModel.title === "Archive" && (
          <div
            style={{
              position: "relative",
              bottom: "150px",
              color: !isNight ? "#aebcc9" : "#666",
            }}
          >
            <ul>
              <li>
                <span>Click on the </span>
                <SettingsIcon
                  fontSize="small"
                  // className="sourceCardSetting"
                  style={{ fill: !isNight ? "#aebcc9" : "#666" }}
                />
                <span> on the Upload Archive tile from the sidebar.</span>
              </li>
              <li>Upload the zip file.</li>
              <li>Wait for file to be uploaded and close the window.</li>
            </ul>
          </div>
        )}
        {selectedModel.title === "Slack" && (
          <div
            style={{
              position: "relative",
              bottom: "150px",
              color: !isNight ? "#aebcc9" : "#666",
            }}
          >
            <ul>
              <li>
                <span>Click on the </span>
                <SettingsIcon
                  fontSize="small"
                  // className="sourceCardSetting"
                  style={{ fill: !isNight ? "#aebcc9" : "#666" }}
                />
                <span> on the Slack tile from the sidebar.</span>
              </li>
              <li>
                {" "}
                <span>Click on </span>
                <img
                  style={{ position: "relative", top: "10px" }}
                  alt="Sign in with Slack"
                  height="40"
                  width="172"
                  src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
                  srcSet="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x"
                />
                <span> button and authenticate with your slack workspace.</span>
              </li>
              <li>Close the popup window after successful authentication.</li>
            </ul>
          </div>
        )}
        {selectedModel.title === "Teams" && (
          <div
            style={{
              position: "relative",
              bottom: "150px",
              color: !isNight ? "#aebcc9" : "#666",
            }}
          >
            <ul>
              <li>
                <span>Click on the </span>
                <SettingsIcon
                  fontSize="small"
                  // className="sourceCardSetting"
                  style={{ fill: !isNight ? "#aebcc9" : "#666" }}
                />
                <span> on the Teams tile from the sidebar.</span>
              </li>
              <li>
                Click on Sign in with Microsoft button and authenticate with
                your account
              </li>
              <li>
                After authentication click on Fetch Teams button to list all
                teams.
              </li>
              <li>Click on the team you want to use to fetch channels.</li>
            </ul>
          </div>
        )}
        {selectedModel.title === "Discord" && (
          <div
            style={{
              position: "relative",
              bottom: "150px",
              color: !isNight ? "#aebcc9" : "#666",
            }}
          >
            <ul>
              <li>
                <span>Click on the </span>
                <SettingsIcon
                  fontSize="small"
                  // className="sourceCardSetting"
                  style={{ fill: !isNight ? "#aebcc9" : "#666" }}
                />
                <span> on the Discord tile from the sidebar.</span>
              </li>
              <li>
                Click on Add Bot button to add our bot to your discord server.
              </li>
              <li>Add the chec bot to your discord server.</li>
              <li>Close the popup window.</li>
              <li>Enter your discord server ID and click submit.</li>
            </ul>
          </div>
        )}
        {selectedModel.title === "Emotional" && (
          <div
            style={{
              position: "relative",
              bottom: "150px",
              color: !isNight ? "#aebcc9" : "#666",
            }}
          >
            <ul>
              <li>
                <span>Click on the </span>
                <SettingsIcon
                  fontSize="small"
                  // className="sourceCardSetting"
                  style={{ fill: !isNight ? "#aebcc9" : "#666" }}
                />
                <span> on the Emotional tile from the sidebar.</span>
              </li>
              <li>Choose some values for different emotions.</li>
              <li>Click on Save button.</li>
            </ul>
          </div>
        )}
        {selectedModel.title === "Personal Information" && (
          <div
            style={{
              position: "relative",
              bottom: "150px",
              color: !isNight ? "#aebcc9" : "#666",
            }}
          >
            <ul>
              <li>
                <span>Click on the </span>
                <SettingsIcon
                  fontSize="small"
                  // className="sourceCardSetting"
                  style={{ fill: !isNight ? "#aebcc9" : "#666" }}
                />
                <span> on the Personal Information tile from the sidebar.</span>
              </li>
              <li>Choose the information you want to add to the workflow.</li>
              <li>Click on Save button.</li>
            </ul>
          </div>
        )}
        {selectedModel.title === "Profanity" && (
          <div
            style={{
              position: "relative",
              bottom: "150px",
              color: !isNight ? "#aebcc9" : "#666",
            }}
          >
            <ul>
              <li>
                <span>Click on the </span>
                <SettingsIcon
                  fontSize="small"
                  // className="sourceCardSetting"
                  style={{ fill: !isNight ? "#aebcc9" : "#666" }}
                />
                <span> on the Profanity tile from the sidebar.</span>
              </li>
              <li>Choose the profanity value.</li>
              <li>Click on Save button.</li>
            </ul>
          </div>
        )}
        {selectedModel.title === "Hate Speech" && (
          <div
            style={{
              position: "relative",
              bottom: "150px",
              color: !isNight ? "#aebcc9" : "#666",
            }}
          >
            <ul>
              <li>
                <span>Click on the </span>
                <SettingsIcon
                  fontSize="small"
                  // className="sourceCardSetting"
                  style={{ fill: !isNight ? "#aebcc9" : "#666" }}
                />
                <span> on the Hate Speech tile from the sidebar.</span>
              </li>
              <li>Choose any or percentage with a value as per your choise.</li>
              <li>Click on Save button.</li>
            </ul>
          </div>
        )}
        {selectedModel.title === "Script" && (
          <div
            style={{
              position: "relative",
              bottom: "150px",
              color: !isNight ? "#aebcc9" : "#666",
            }}
          >
            <ul>
              <li>
                <span>Click on the </span>
                <SettingsIcon
                  fontSize="small"
                  // className="sourceCardSetting"
                  style={{ fill: !isNight ? "#aebcc9" : "#666" }}
                />
                <span> on the Script tile from the sidebar.</span>
              </li>
              <li>Choose any or percentage with a value as per your choise.</li>
              <li>Click on Save button.</li>
            </ul>
          </div>
        )}
        {selectedModel.title === "Insult Detection" && (
          <div
            style={{
              position: "relative",
              bottom: "150px",
              color: !isNight ? "#aebcc9" : "#666",
            }}
          >
            <ul>
              <li>
                <span>Click on the </span>
                <SettingsIcon
                  fontSize="small"
                  // className="sourceCardSetting"
                  style={{ fill: !isNight ? "#aebcc9" : "#666" }}
                />
                <span> on the Insult Detection tile from the sidebar.</span>
              </li>
              <li>Choose any or percentage with a value as per your choise.</li>
              <li>Click on Save button.</li>
            </ul>
          </div>
        )}
        {selectedModel.title === "Sexual Harrasment" && (
          <div
            style={{
              position: "relative",
              bottom: "150px",
              color: !isNight ? "#aebcc9" : "#666",
            }}
          >
            <ul>
              <li>
                <span>Click on the </span>
                <SettingsIcon
                  fontSize="small"
                  // className="sourceCardSetting"
                  style={{ fill: !isNight ? "#aebcc9" : "#666" }}
                />
                <span> on the Sexual Harrasment tile from the sidebar.</span>
              </li>
              <li>Choose any or percentage with a value as per your choise.</li>
              <li>Click on Save button.</li>
            </ul>
          </div>
        )}
        {selectedModel.title === "Diversity Inclusion" && (
          <div
            style={{
              position: "relative",
              bottom: "150px",
              color: !isNight ? "#aebcc9" : "#666",
            }}
          >
            <ul>
              <li>
                <span>Click on the </span>
                <SettingsIcon
                  fontSize="small"
                  // className="sourceCardSetting"
                  style={{ fill: !isNight ? "#aebcc9" : "#666" }}
                />
                <span> on the Diversity Inclusion tile from the sidebar.</span>
              </li>
              <li>Choose any or percentage with a value as per your choise.</li>
              <li>Click on Save button.</li>
            </ul>
          </div>
        )}
        {selectedModel.title === "Sexism Detection" && (
          <div
            style={{
              position: "relative",
              bottom: "150px",
              color: !isNight ? "#aebcc9" : "#666",
            }}
          >
            <ul>
              <li>
                <span>Click on the </span>
                <SettingsIcon
                  fontSize="small"
                  // className="sourceCardSetting"
                  style={{ fill: !isNight ? "#aebcc9" : "#666" }}
                />
                <span> on the Sexism Detection tile from the sidebar.</span>
              </li>
              <li>Choose any or percentage with a value as per your choise.</li>
              <li>Click on Save button.</li>
            </ul>
          </div>
        )}
        {selectedModel.title === "Sentiment Analysis" && (
          <div
            style={{
              position: "relative",
              bottom: "150px",
              color: !isNight ? "#aebcc9" : "#666",
            }}
          >
            <ul>
              <li>
                <span>Click on the </span>
                <SettingsIcon
                  fontSize="small"
                  // className="sourceCardSetting"
                  style={{ fill: !isNight ? "#aebcc9" : "#666" }}
                />
                <span> on the Sentiment Analysis tile from the sidebar.</span>
              </li>
              <li>Choose a sentiment with a value as per your choise.</li>
              <li>Click on Save button.</li>
            </ul>
          </div>
        )}
        {selectedModel.title === "Textual Abuse" && (
          <div
            style={{
              position: "relative",
              bottom: "150px",
              color: !isNight ? "#aebcc9" : "#666",
            }}
          >
            <ul>
              <li>
                <span>Click on the </span>
                <SettingsIcon
                  fontSize="small"
                  // className="sourceCardSetting"
                  style={{ fill: !isNight ? "#aebcc9" : "#666" }}
                />
                <span> on the Textual Abuse tile from the sidebar.</span>
              </li>
              <li>Choose the options with a value as per your choise.</li>
              <li>Click on Save button.</li>
            </ul>
          </div>
        )}
        {selectedModel.title === "Email" && (
          <div
            style={{
              position: "relative",
              bottom: "150px",
              color: !isNight ? "#aebcc9" : "#666",
            }}
          >
            <ul>
              <li>
                <span>Click on the </span>
                <SettingsIcon
                  fontSize="small"
                  // className="sourceCardSetting"
                  style={{ fill: !isNight ? "#aebcc9" : "#666" }}
                />
                <span> on the Email tile from the sidebar.</span>
              </li>
              <li>Fill all the fields and click on save.</li>
            </ul>
          </div>
        )}
        {selectedModel.title === "Servicenow" && (
          <div
            style={{
              position: "relative",
              bottom: "150px",
              color: !isNight ? "#aebcc9" : "#666",
            }}
          >
            <ul>
              <li>
                <span>Click on the </span>
                <SettingsIcon
                  fontSize="small"
                  // className="sourceCardSetting"
                  style={{ fill: !isNight ? "#aebcc9" : "#666" }}
                />
                <span> on the Email tile from the sidebar.</span>
              </li>
              <li>Fill all the fields and click on save.</li>
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default TutorialModal;
