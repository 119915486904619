import { toast } from "react-toastify";
import api from "../axiosConfig";
import { SessionOut } from "../../utilities/dashboardUtils";

export function* resetPassword(action) {
  let resp;
  const data = action.payload;
  const userId = action.payload.userId;
  const token = action.payload.tokenId;

  delete data.userId;
  delete data.tokenId;

  yield api
    .post(`/reset-password/${userId}/${token}`, data)
    .then((response) => {
      if (response.status >= 400 && response.status < 500) {
        toast.error(response.message);
      } else if (response.status === 400) {
        toast.error(response.message);
      } else {
        resp = response;
        toast.success("Password Changed Successfully");
        SessionOut();
      }
    })
    .catch((error) => {
      toast.error(error?.response?.data?.message);
      return error;
    });
  return resp;
}
