export const demoFavoritesInsights = [
    {
        "month": "2024-12",
        "morale": [
            {
                "statement": "Morale score for transgender employees decreased by 59.66% in October compared to September.",
                "score": -59.65909090909091,
                "feature": "{\"gender\":[\"transgender\"]}",
                "surveyID": "3213dbd6a9b60c152dd20b6d"
            },
            {
                "statement": "Morale score for transgender and male employees in the Accounting & Finance department decreased by 61.11% in October compared to September.",
                "score": -61.10526315789474,
                "feature": "{\"gender\":[\"transgender\",\"male\"],\"department\":[\"Accounting & Finance\"]}",
                "surveyID": "5fc39bbed9417d59bcb5773a"
            },
            {
                "statement": "Morale score for non-binary employees in the Operations department decreased by 62.63% in October compared to September.",
                "score": -62.63157894736842,
                "feature": "{\"gender\":[\"non-binary\"],\"department\":[\"Operations\"]}",
                "surveyID": "9408333f59aa2df20ed6e308"
            }
        ],
        "retention": [
            {
                "statement": "Retention for transgender employees increased by 29.25% in October compared to September.",
                "score": -29.25,
                "feature": "{\"gender\":[\"transgender\"]}",
                "surveyID": "5cc6ed67654b786158118334"
            },
            {
                "statement": "Retention for transgender and male employees in the Accounting & Finance department increased by 33.42% in October compared to September.",
                "score": -33.42,
                "feature": "{\"gender\":[\"transgender\",\"male\"],\"department\":[\"Accounting & Finance\"]}",
                "surveyID": "d250bfd4e060c9852282c398"
            },
            {
                "statement": "Retention for non-binary employees in the Operations department increased by 38.63% in October compared to September.",
                "score": -38.63,
                "feature": "{\"gender\":[\"non-binary\"],\"department\":[\"Operations\"]}",
                "surveyID": "b08a8f1e8b6ac2d9fb57b21c"
            }
        ],
        "enps": [
            {
                "statement": "Employee net promoter score for transgender employees remained unchanged by 0 in October compared to September.",
                "score": 0,
                "feature": "{\"gender\":[\"transgender\"]}",
                "surveyID": "d3502006fed46feca1d7902b"
            },
            {
                "statement": "Employee net promoter score for transgender and males in Accounting & Finance remained unchanged by 0 in October compared to September.",
                "score": 0,
                "feature": "{\"gender\":[\"transgender\",\"male\"],\"department\":[\"Accounting & Finance\"]}",
                "surveyID": "1e5b99457114387acfd282ad"
            },
            {
                "statement": "Employee net promoter score for non-binary employees in Operations remained unchanged by 0 in October compared to September.",
                "score": 0,
                "feature": "{\"gender\":[\"non-binary\"],\"department\":[\"Operations\"]}",
                "surveyID": "f661e9c8fe30a5f480128d1c"
            }
        ],
        "engagement": [
            {
                "statement": "Transgender users saw a 40.9% decrease in overall engagement from September to October.",
                "score": -40.904761904761905,
                "feature": "{\"gender\":[\"transgender\"]}",
                "surveyID": "1722446c9c16d3ef7449d18b"
            },
            {
                "statement": "Transgender and male users in Accounting & Finance saw a 45.74% decrease in overall engagement from September to October.",
                "score": -45.73809523809524,
                "feature": "{\"gender\":[\"transgender\",\"male\"],\"department\":[\"Accounting & Finance\"]}",
                "surveyID": "eff44e4524df14489b77d7e2"
            },
            {
                "statement": "Non-binary users in Operations saw a 37.25% decrease in overall engagement from September to October.",
                "score": -37.25,
                "feature": "{\"gender\":[\"non-binary\"],\"department\":[\"Operations\"]}",
                "surveyID": "b4a3fc9a7afe524876cf082a"
            }
        ],
        "culture": [
            {
                "statement": "Transgender users experienced a decrease of 2 in Overall culture in October compared to September.",
                "score": -2,
                "feature": "{\"gender\":[\"transgender\"]}",
                "surveyID": "f44436230b7236a6d21fb60c"
            },
            {
                "statement": "Transgender and male users in Accounting & Finance saw a decrease of 7 in Overall culture from September to October.",
                "score": -7,
                "feature": "{\"gender\":[\"transgender\",\"male\"],\"department\":[\"Accounting & Finance\"]}",
                "surveyID": "4201f08350921841a66decca"
            },
            {
                "statement": "Non-binary users in Operations experienced a decrease of 11 in Overall culture in October compared to September.",
                "score": -11,
                "feature": "{\"gender\":[\"non-binary\"],\"department\":[\"Operations\"]}",
                "surveyID": "0cef271a2f73f50c37f6b4a8"
            }
        ],
        "topic": []
    }
]
