import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../../styles/loginPage.css";
import "../../../styles/profilePage.css";
import "../../../styles/setting.css";
import "react-phone-input-2/lib/style.css";
import { ICONS } from "../../../AppConfig";
import { Box, Button, Grid, Link, ListItemText } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { toast } from "react-toastify";
import "../../../styles/mailserver.css";
import Modal from "@mui/material/Modal";
import _ from "lodash";
import KeysModal from "./Configurations/KeyModal";
import SlackModal from "./Configurations/SlackModal";
import TeamsModal from "./Configurations/TeamsModal";
import GlassdoorModal from "./Configurations/GlassdoorModal";
import {
  VERIFY_TOKEN,
  GET_ACCESS_TOKEN,
  GET_CHANNELS,
  SAVING_ACCESS_TOKEN,
  GET_USER_WORKFLOW,
  BUILD_WORKFLOW,
  DELETE_WORKFLOW,
  GET_USER_DETAILS,
} from "../../../redux/Dashboard/Dashboard.types";
import FileUploader from "./Configurations/FileUploader";
import { getUserWorkflowsData } from "../../../services/Logic/getUserWorkflowsAsync.api";
import api from "../../../services/axiosConfig";
import { deleteWorkflowData } from "../../../services/Logic/deleteWorkflow.api";
import ZoomModal from "../Logic/Configuration/ZoomModal";
import BlindModal from "./Configurations/BlindModal";
import HrisModal from "./Configurations/HrisModal";
import DeleteMessagesModal from "./Configurations/DeleteMessagesModal";

function MailServer() {
  const dispatch = useDispatch();
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const slackKey = useSelector((data) => data.dashboard.slackKey);
  const accessToken = useSelector((data) => data.dashboard.accessToken);
  const userID = JSON.parse(localStorage.getItem("userData")).userId;
  const userToken = JSON.parse(localStorage.getItem("userData")).usertoken;
  const accessTokenSave = useSelector((data) => data.dashboard.accessTokenSave);
  const workflows = useSelector((data) => data.dashboard.userWorkflows.data);
  const userDetails = useSelector((data) => data.dashboard.userDetails);

  const uploadedChannels = useSelector(
    (data) => data.dashboard.uploadSourceStatus
  );

  const messageDeletionDays = useSelector((data) => data.dashboard.messageDeletionDays);


  const uploadSourceStatus = useSelector(
    (data) => data.dashboard.uploadSourceStatus
  );

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "transparent",
    border: !isNight ? "1px solid #F6F7Fc" : "1px solid #000",
    borderRadius: "8px",
    ...theme.typography.body2,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: "left",
    color: !isNight ? "#F6F7Fc" : "#000",
  }));

  const [open, setOpen] = useState(false);

  const [channels, setChannels] = useState([]);

  const [fileUploader, setFileUploader] = useState(false);

  const [teamsOpen, setTeamsOpen] = useState(false);

  const [zoomOpen, setZoomOpen] = useState(false);

  const [glassDoorOpen, setGlassDoorOpen] = useState(false);

  const [blindOpen, setBlindOpen] = useState(false);

  const [hrisModal, setHrisModal] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);

  useEffect(() => {
    if (uploadedChannels.status === "FAIL") {
      setChannels([]);
    } else if (uploadedChannels.status === "PASS") {
      setChannels(uploadedChannels.channels);
    } else if (uploadedChannels.status === "EMPTY") {
      setChannels([]);
    }
  }, [uploadedChannels]);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [datailedWorkflows, setDetailedWorkflows] = useState([]);

  useEffect(() => {
    dispatch({ type: GET_USER_WORKFLOW });
    dispatch({ type: GET_USER_DETAILS });
  }, []);

  const buildWorkflow = (id) => {
    !isDemo &&
      dispatch({
        type: BUILD_WORKFLOW,
        payload: {
          workflowID: id,
          userID,
          buildOnce: true,
        },
      });
    setTimeout(() => {
      toast.info("Build is processing");
    }, 1000);
  };

  const deleteWorkflow = (id) => {
    !isDemo &&
      dispatch({
        type: DELETE_WORKFLOW,
        payload: {
          workflowID: id,
          userID,
        },
      });
    dispatch({ type: GET_USER_WORKFLOW });
  };

  return (
    <div
      className=" mailserver"
      style={
        !isNight
          ? { backgroundColor: "#212047", overflow: "hidden" }
          : { backgroundColor: "#F6F7FC" }
      }
    >
      <div style={{ margin: "2rem 4rem ", width: "80%" }}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <div
              onClick={handleOpen}
              style={{
                color: "#999",
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                margin: "0 1rem",
                borderRadius: "12px",
                boxShadow:
                  "rgba(0, 0, 0, 0.19) 3px 0px 2px, rgba(0, 0, 0, 0.23) -1px 1px 11px",
              }}
            >
              <img
                style={{ width: "80px", margin: "0.5rem auto" }}
                src={ICONS.SLACK}
                alt="slack"
              />
              <p
                style={{
                  textAlign: "center",
                  color: "#484848",
                  margin: "0 1.4rem 0.5rem",
                }}
              >
                Slack
              </p>
              {userDetails.company?.slackAuth ? (
                <button
                  style={{
                    maxWidth: "120px",
                    padding: "0.5rem",
                    fontSize: "1rem",
                    borderRadius: "5px",
                    border: "1px solid #D06283",
                    color: "#484848",
                    cursor: "pointer",
                    filter: "opacity(0.8)",
                    margin: "0 auto 1rem",
                  }}
                >
                  Connected
                </button>
              ) : (
                <button
                  style={{
                    maxWidth: "120px",
                    padding: "0.5rem",
                    fontSize: "1rem",
                    borderRadius: "5px",
                    border: "1px solid #D06283",
                    color: "#fff",
                    cursor: "pointer",
                    background: "#D06283",
                    filter: "opacity(0.8)",
                    margin: "0 auto 1rem",
                  }}
                >
                  Connect
                </button>
              )}
            </div>
          </Grid>
          <Grid item xs={3}>
            <div
              onClick={handleOpen}
              style={{
                color: "#999",
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                margin: "0 1rem",
                borderRadius: "12px",
                boxShadow:
                  "rgba(0, 0, 0, 0.19) 3px 0px 2px, rgba(0, 0, 0, 0.23) -1px 1px 11px",
              }}
            >
              <img
                style={{ width: "80px", margin: "0.5rem auto" }}
                src={ICONS.TEAMS}
                alt="slack"
              />
              <p
                style={{
                  textAlign: "center",
                  color: "#484848",
                  margin: "0 1.4rem 0.5rem",
                }}
              >
                Teams
              </p>
              {userDetails.company?.slackAuth ? (
                <button
                  style={{
                    maxWidth: "120px",
                    padding: "0.5rem",
                    fontSize: "1rem",
                    borderRadius: "5px",
                    border: "1px solid #D06283",
                    color: "#484848",
                    cursor: "pointer",
                    filter: "opacity(0.8)",
                    margin: "0 auto 1rem",
                  }}
                >
                  Connected
                </button>
              ) : (
                <button
                  style={{
                    maxWidth: "120px",
                    padding: "0.5rem",
                    fontSize: "1rem",
                    borderRadius: "5px",
                    border: "1px solid #D06283",
                    color: "#fff",
                    cursor: "pointer",
                    background: "#D06283",
                    filter: "opacity(0.8)",
                    margin: "0 auto 1rem",
                  }}
                >
                  Connect
                </button>
              )}
            </div>
          </Grid>
          <Grid item xs={3}>
            <div
              onClick={() => {
                setZoomOpen(true);
              }}
              style={{
                color: "#999",
                display: "flex",
                cursor: "pointer",
                flexDirection: "column",
                justifyContent: "center",
                margin: "0 1rem",
                borderRadius: "12px",
                boxShadow:
                  "rgba(0, 0, 0, 0.19) 3px 0px 2px, rgba(0, 0, 0, 0.23) -1px 1px 11px",
              }}
            >
              <img
                style={{ width: "80px", margin: "0.5rem auto" }}
                src={ICONS.ZOOM}
                alt="zoom"
              />
              <p
                style={{
                  textAlign: "center",
                  color: "#484848",
                  margin: "0 1.4rem 0.5rem",
                }}
              >
                Zoom
              </p>
              {userDetails.company?.zoomAuth ? (
                <button
                  style={{
                    maxWidth: "120px",
                    padding: "0.5rem",
                    fontSize: "1rem",
                    borderRadius: "5px",
                    border: "1px solid #D06283",
                    color: "#484848",
                    cursor: "pointer",
                    filter: "opacity(0.8)",
                    margin: "0 auto 1rem",
                  }}
                >
                  Connected
                </button>
              ) : (
                <button
                  style={{
                    maxWidth: "120px",
                    padding: "0.5rem",
                    fontSize: "1rem",
                    borderRadius: "5px",
                    border: "1px solid #D06283",
                    color: "#fff",
                    cursor: "pointer",
                    background: "#D06283",
                    filter: "opacity(0.8)",
                    margin: "0 auto 1rem",
                  }}
                >
                  Connect
                </button>
              )}
            </div>
            {/* </Item> */}
          </Grid>
          <Grid item xs={3}>
            <div
              onClick={() => {
                setGlassDoorOpen(true);
              }}
              style={{
                color: "#999",
                display: "flex",
                cursor: "pointer",
                flexDirection: "column",
                justifyContent: "center",
                margin: "0 1rem",
                borderRadius: "12px",
                boxShadow:
                  "rgba(0, 0, 0, 0.19) 3px 0px 2px, rgba(0, 0, 0, 0.23) -1px 1px 11px",
              }}
            >
              <img
                style={{ width: "65px", margin: "1rem auto" }}
                src={ICONS.GlassDoor}
                alt="zoom"
              />
              <p
                style={{
                  textAlign: "center",
                  color: "#484848",
                  margin: "0 1.4rem 0.5rem",
                }}
              >
                Glassdoor
              </p>
              {userDetails.company?.glassDoor ? (
                <button
                  style={{
                    maxWidth: "120px",
                    padding: "0.5rem",
                    fontSize: "1rem",
                    borderRadius: "5px",
                    border: "1px solid #D06283",
                    color: "#484848",
                    cursor: "pointer",
                    filter: "opacity(0.8)",
                    margin: "0 auto 1rem",
                  }}
                >
                  Connected
                </button>
              ) : (
                <button
                  style={{
                    maxWidth: "120px",
                    padding: "0.5rem",
                    fontSize: "1rem",
                    borderRadius: "5px",
                    border: "1px solid #D06283",
                    color: "#fff",
                    cursor: "pointer",
                    background: "#D06283",
                    filter: "opacity(0.8)",
                    margin: "0 auto 1rem",
                  }}
                >
                  Connect
                </button>
              )}
            </div>
            {/* </Item> */}
          </Grid>
          <Grid item xs={3}>
            <div
              onClick={() => {
                setBlindOpen(true);
              }}
              style={{
                color: "#999",
                display: "flex",
                cursor: "pointer",
                flexDirection: "column",
                justifyContent: "center",
                margin: "0 1rem",
                borderRadius: "12px",
                boxShadow:
                  "rgba(0, 0, 0, 0.19) 3px 0px 2px, rgba(0, 0, 0, 0.23) -1px 1px 11px",
              }}
            >
              <img
                style={{ width: "65px", margin: "0.5rem auto" }}
                src={ICONS.Blind}
                alt="zoom"
              />
              <p
                style={{
                  textAlign: "center",
                  color: "#484848",
                  margin: "0 1.4rem 0.5rem",
                }}
              >
                Blind
              </p>
              {userDetails.company?.blind ? (
                <button
                  style={{
                    maxWidth: "120px",
                    padding: "0.5rem",
                    fontSize: "1rem",
                    borderRadius: "5px",
                    border: "1px solid #D06283",
                    color: "#484848",
                    cursor: "pointer",
                    filter: "opacity(0.8)",
                    margin: "0 auto 1rem",
                  }}
                >
                  Connected
                </button>
              ) : (
                <button
                  style={{
                    maxWidth: "120px",
                    padding: "0.5rem",
                    fontSize: "1rem",
                    borderRadius: "5px",
                    border: "1px solid #D06283",
                    color: "#fff",
                    cursor: "pointer",
                    background: "#D06283",
                    filter: "opacity(0.8)",
                    margin: "0 auto 1rem",
                  }}
                >
                  Connect
                </button>
              )}
            </div>
            {/* </Item> */}
          </Grid>
        </Grid>
      </div>

      <div style={{ margin: "2rem 4rem ", width: "80%" }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div
              style={{
                color: "#999",
                cursor: "pointer",
                padding: "1rem",
                display: "flex",
                justifyContent: "space-between",
                margin: "0 1rem",
                borderRadius: "12px",
                boxShadow:
                  "rgba(0, 0, 0, 0.19) 3px 0px 2px, rgba(0, 0, 0, 0.23) -1px 1px 11px",
              }}
            >
              <p
                style={{
                  textAlign: "center",
                  color: "#484848",
                  margin: "1rem",
                }}
              >
                Delete Messages after <b>{messageDeletionDays}</b> Days.
              </p>
              <button onClick={() => { setDeleteModal(true) }}
                style={{
                  maxWidth: "120px",
                  padding: "0.5rem",
                  height: "40px",
                  fontSize: "1rem",
                  borderRadius: "5px",
                  border: "1px solid #D06283",
                  color: "#fff",
                  cursor: "pointer",
                  background: "#D06283",
                  filter: "opacity(0.8)",
                  margin: "1rem",
                }}
              >
                Change
              </button>
            </div>
          </Grid>
        </Grid>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <SlackModal
          open={open}
          handleClose={handleClose}
          title="Connect Slack"
          source="slack"
        />
      </Modal>
      <Modal
        open={fileUploader}
        onClose={() => {
          setFileUploader(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <FileUploader
          handleClose={() => {
            setFileUploader(false);
          }}
        />
      </Modal>
      <Modal
        open={teamsOpen}
        onClose={() => {
          setTeamsOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <TeamsModal
          handleClose={() => {
            setTeamsOpen(false);
          }}
          open={teamsOpen}
          title="Teams Authentication"
        />
      </Modal>
      <Modal
        open={zoomOpen}
        onClose={() => {
          setZoomOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ZoomModal
          handleClose={() => {
            setZoomOpen(false);
          }}
          open={zoomOpen}
          title="Zoom"
        />
      </Modal>
      <Modal
        open={glassDoorOpen}
        onClose={() => {
          setGlassDoorOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <GlassdoorModal
          handleClose={() => {
            setGlassDoorOpen(false);
          }}
          open={glassDoorOpen}
          title="Glassdoor"
        />
      </Modal>
      <Modal
        open={blindOpen}
        onClose={() => {
          setBlindOpen(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <BlindModal
          handleClose={() => {
            setBlindOpen(false);
          }}
          open={blindOpen}
          title="Blind"
        />
      </Modal>
      <Modal
        open={hrisModal}
        onClose={() => {
          setHrisModal(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <HrisModal
          survey={{}}
          handleClose={() => {
            setHrisModal(false);
          }}
        />
      </Modal>
      <Modal
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DeleteMessagesModal
          handleClose={() => {
            setDeleteModal(false);
          }}
        />
      </Modal>
    </div>
  );
}
export default MailServer;
