import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import AskSection from "./AskSection";
import RecommendationSection from "./RecommendationSection";
import { GET_ALL_ACTION_PLANS, GET_ALL_SURVEYS } from "../../../redux/Dashboard/Dashboard.types";
import { Link } from "react-router-dom";

function Assistant() {
  const dispatch = useDispatch();
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const [currentScreen, setCurrentScreen] = useState("plan");

  useEffect(() => {
    if (window.location.href.split("/")[5] === "plan") {
      setCurrentScreen("plan");
    } else if (window.location.href.split("/")[4] === "action-plan") {
      setCurrentScreen("plan");
    }
  });

  useEffect(() => {
    !isDemo && dispatch({ type: GET_ALL_ACTION_PLANS });
    !isDemo && dispatch({ type: GET_ALL_SURVEYS });

  }, []);

  return (
    <Box
      sx={{
        marginTop: "0",
        background: "#f2f2f2",
        // height: "100vh",
        padding: "2rem",
      }}
    >
      {currentScreen === "ask" && <AskSection />}
      {currentScreen === "plan" && <RecommendationSection />}
    </Box>
  );
}

export default Assistant;
