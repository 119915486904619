import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";
import CheckIcon from "@material-ui/icons/Check";
import { Input } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import CircularProgress from "@mui/material/CircularProgress";
import DownloadIcon from "@mui/icons-material/Download";
import _ from "lodash";
import moment from "moment";
import {
  SAVE_ZOOM_TRANSCRIPT,
  ZOOM_FILE_UPLOAD_SUCCESS,
  VERIFY_TOKEN,
  GET_ZOOM_TOKEN,
  GET_CHANNELS,
  SAVING_ACCESS_TOKEN,
  GET_USER_DETAILS,
} from "../../../../redux/Dashboard/Dashboard.types";
import axios from "axios";
import api from "../../../../services/axiosConfig";
import ZoomLogin from "./ZoomLogin";
const useStyles = makeStyles((theme) => ({
  root: {
    color: "#fff",
    padding: "0!important",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    "& div": {
      color: "#fff",
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    marginTop: 20,
    color: "rgba(0, 0, 0, 0.87)!important",
    borderBlockColor: "#6352C6",
  },
  input: {
    color: "#fff",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    padding: 0,
  },
}));

export default function ZoomModal({ open, handleClose, title }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const userID = JSON.parse(localStorage.getItem("userData")).userId;
  const userToken = JSON.parse(localStorage.getItem("userData")).usertoken;
  const slackKey = useSelector((data) => data.dashboard.slackKey);

  const zoomRecordings = useSelector((data) => data.dashboard.zoomRecordings);

  const accessToken = useSelector((data) => data.dashboard.accessToken);
  const accessTokenSave = useSelector((data) => data.dashboard.accessTokenSave);

  const [dateTime, setDateTime] = useState(
    new Date(new Date().getTime() - 12 * 60 * 60 * 1000)
  );
  const [method, setMethod] = useState("choose");
  const [selectedRec, setSelectedRec] = useState(null);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const onFailed = () => {
    !isDemo &&
      dispatch({
        type: VERIFY_TOKEN,
        payload: { app: "zoom", userID },
      });
  };
  const onSuccess = () => {
    toast.success("Oauth Successful");
  };

  useEffect(() => {
    if (slackKey?.data?.length > 0 && !accessTokenSave) {
      !isDemo &&
        dispatch({
          type: GET_ZOOM_TOKEN,
          payload: { code: slackKey.data[0].token },
        });
    } else {
      // toast.error("Authorization Failed ! Please oauth again.");
    }
  }, [slackKey]);

  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);
  const userDetails = useSelector((data) => data.dashboard.userDetails);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("createTime", moment(dateTime).format("DD-MM-YYYY"));
    formData.append("user", userID);
    try {
      const response = await axios.post(
        "http://trupulse-ml-morale-lb-790268635.us-east-2.elb.amazonaws.com/transcribe",
        formData,
        {
          timeout: 500000,
          headers: {
            "Access-Control-Allow-Headers": "x-access-token",
            "x-access-token": localStorage.getItem("auth-token"),
          },
        } 
      );
      // Handle the response here
      const data = response.data; //await response.json();

      dispatch({
        type: ZOOM_FILE_UPLOAD_SUCCESS,
        payload: { filename: data.filename },
      });
      setIsLoading(false);
      toast.success("Transcript generated successfully");
      handleClose();
    } catch (error) {
      console.error("Error uploading file:", error);
      toast.error("Error uploading file");
    }
  };
  useEffect(() => {
    dispatch({ type: GET_USER_DETAILS });
  }, []);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <DialogContent classes={{ root: classes.root }} style={{ width: 550 }}>
        <div
          className="popUp"
          style={{
            backgroundColor: !isNight ? "#212047" : "#e5e5e5",
          }}
        >
          <div
            className="popUpTitle"
            style={{ color: !isNight ? "#aebcc9" : "#000" }}
          >
            {title}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {method === "choose" && (
              <>
                <div
                  style={{
                    padding: "0 1rem",
                    borderRight: "1px solid #aebcc9",
                  }}
                >
                  <p style={{ color: !isNight ? "#aebcc9" : "#000" }}>
                    Connect your Zoom Account
                  </p>
                  {userDetails.company?.zoomAuth ? (
                    <div
                      className="cancelBtn"
                      style={{
                        width: "100px",
                        margin: "auto",
                        cursor: "not-allowed",
                      }}
                    >
                      <div
                        className="btnText"
                        style={{
                          textAlign: "center",
                          padding: "0.3rem",
                          color: "#484848",
                          pointerEvents: "none",
                          cursor: "not-allowed",
                        }}
                      >
                        Connected
                      </div>
                    </div>
                  ) : (
                    <div
                      className="submitBtn"
                      style={{ width: "100px", margin: "auto" }}
                      onClick={() => {
                        setMethod("auth");
                      }}
                    >
                      <div
                        className="btnText"
                        style={{ textAlign: "center", padding: "0.3rem" }}
                      >
                        Connect
                      </div>
                    </div>
                  )}
                </div>
                <div style={{ padding: "0 1rem" }}>
                  <p style={{ color: !isNight ? "#aebcc9" : "#000" }}>
                    Upload Audio/Video File
                  </p>
                  <div
                    className="submitBtn"
                    style={{ width: "100px", margin: "auto" }}
                    onClick={() => {
                      setMethod("file");
                    }}
                  >
                    <div
                      className="btnText"
                      style={{ textAlign: "center", padding: "0.3rem" }}
                    >
                      Upload
                    </div>
                  </div>
                </div>
              </>
            )}
            {method === "file" && (
              <div style={{ width: "500px" }}>
                <div>
                  <p style={{ color: !isNight ? "#aebcc9" : "#000" }}>
                    Please upload an audio/video file
                  </p>
                </div>
                <Input
                  type="file"
                  style={{ color: !isNight ? "#aebcc9" : "#000" }}
                  onChange={handleFileChange}
                  accept=".mp3"
                />
                <div
                  style={{
                    color: !isNight ? "#aebcc9" : "#000",
                    margin: "1rem 0",
                  }}
                >
                  Meeting time
                  <DateTimePicker onChange={setDateTime} value={dateTime} />
                </div>
                <div className="btnOptions">
                  <div
                    className="cancelBtn"
                    onClick={() => {
                      setMethod("choose");
                    }}
                  >
                    <div className="rangeBtn">
                      <ArrowBackIosIcon
                        style={{
                          color: !isNight ? "#aebcc9" : "#000",
                          fontSize: "1rem",
                        }}
                      />
                      <div
                        className="btnText"
                        style={{ color: !isNight ? "#aebcc9" : "#000" }}
                      >
                        back
                      </div>
                    </div>{" "}
                  </div>
                  <div className="submitBtn">
                    <div
                      className="rangeBtn"
                      type="submit"
                      onClick={handleUpload}
                    >
                      {isLoading ? (
                        <CircularProgress
                          size="1rem"
                          color="inherit"
                          sx={{ margin: "0 0.5rem" }}
                        />
                      ) : (
                        <CheckIcon />
                      )}
                      <div className="btnText">Upload</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {method === "auth" && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "500px",
                  }}
                >
                  {slackKey?.data?.length > 0 ? (
                    <p style={{ color: !isNight ? "#aebcc9" : "#000" }}>
                      Already Authenicated
                    </p>
                  ) : (
                    <ZoomLogin
                      zoomClientID="BHEAWyJVRtWZF5xBuOklg"
                      responseType="code"
                      redirectUrl="https://api.trupulse.ai/oauth"
                      onFailure={onFailed}
                      onSuccess={onSuccess}
                      state={`zoom-${userID}`}
                    />
                  )}
                </div>
                <div className="btnOptions">
                  <div
                    className="cancelBtn"
                    onClick={() => {
                      setMethod("choose");
                    }}
                  >
                    <div className="rangeBtn">
                      <ArrowBackIosIcon
                        style={{
                          color: !isNight ? "#aebcc9" : "#000",
                          fontSize: "1rem",
                        }}
                      />
                      <div
                        className="btnText"
                        style={{ color: !isNight ? "#aebcc9" : "#000" }}
                      >
                        Back
                      </div>
                    </div>{" "}
                  </div>
                </div>
              </div>
            )}
            {method === "listRecording" && (
              <div style={{ width: "500px" }}>
                <div
                  style={{
                    color: !isNight ? "#aebcc9" : "#000",
                    margin: "1rem 0",
                  }}
                >
                  Select the file you want to use
                  {zoomRecordings?.meetings[0]?.recording_files &&
                    zoomRecordings?.meetings[0]?.recording_files?.map(
                      (item, idx) => (
                        <p
                          onClick={() => {
                            setSelectedRec(idx);
                          }}
                          style={{
                            color:
                              selectedRec === idx
                                ? "#fff"
                                : !isNight
                                ? "#aebcc9"
                                : "#000",
                            background: selectedRec === idx ? "#6352c6" : "",
                            padding: "0.5rem",
                            borderRadius: "8px",
                          }}
                        >
                          {`${idx + 1}. ${item.recording_start}`}
                          <a href={item.download_url} target="_blank">
                            <DownloadIcon
                              style={{
                                color:
                                  selectedRec === idx
                                    ? "#fff"
                                    : !isNight
                                    ? "#aebcc9"
                                    : "#000",
                                fontSize: "1.2rem",
                                margin: "0.2rem 0.5rem",
                                position: "relative",
                                top: "8px",
                                cursor: "pointer",
                              }}
                            />
                          </a>
                        </p>
                      )
                    )}
                </div>
                <div className="btnOptions">
                  <div
                    className="cancelBtn"
                    onClick={() => {
                      setMethod("choose");
                    }}
                  >
                    <div className="rangeBtn">
                      <ArrowBackIosIcon
                        style={{
                          color: !isNight ? "#aebcc9" : "#000",
                          fontSize: "1rem",
                        }}
                      />
                      <div
                        className="btnText"
                        style={{ color: !isNight ? "#aebcc9" : "#000" }}
                      >
                        back
                      </div>
                    </div>{" "}
                  </div>
                  <div className="submitBtn">
                    <div
                      className="rangeBtn"
                      type="submit"
                      onClick={handleClose}
                    >
                      {isLoading ? (
                        <CircularProgress
                          size="1rem"
                          color="inherit"
                          sx={{ margin: "0 0.5rem" }}
                        />
                      ) : (
                        <CheckIcon />
                      )}
                      <div className="btnText">Upload</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
