import { toast } from "react-toastify";
import api from "../axiosConfig";
import { SessionOut } from "../../utilities/dashboardUtils";

export function* createFocusGroup(action) {
  let resp;
  yield api
    .post(`focusGroup/create`, action.payload, {
      timeout: 500000,
      headers: {
        "Access-Control-Allow-Headers": "x-access-token",
        "x-access-token": localStorage.getItem("auth-token"),
      },
    })
    .then((response) => {
      if (response.status === 404) {
        toast.error("Data not found");
      } else if (response.status === 400) {
        response.data.message && toast.error(response.data.message)
        SessionOut();
      } else {
        resp = response;
        toast.success("New Focus Group Created");
      }
    })
    .catch((error) => {
      if (error.response) {
        // Handle response errors (like 400, 404)
        const errorMessage = error.response.data.message || "An error occurred";
        console.error("API Error:", errorMessage);
        toast.error(errorMessage);
      } else {
        // Handle network errors or other issues
        console.error("Network or other error:", error.message);
        toast.error("An error occurred while creating the focus group");
      }      return error;
    });
  return resp;
}
