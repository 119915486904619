import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import { ICONS } from "../../../../AppConfig";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#fff",
    padding: "0!important",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    "& div": {
      color: "#fff",
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    marginTop: 20,
    color: "rgba(0, 0, 0, 0.87)!important",
    borderBlockColor: "#6352C6",
  },
  input: {
    color: "#fff",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    padding: 0,
  },
}));

export default function FindServerIDModal({
  open,
  handleClose,
  //   source,
}) {
  const classes = useStyles();
  const [toEmail, setToEmail] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [emailBody, setEmailBody] = useState("");
  const dispatch = useDispatch();

  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <DialogContent classes={{ root: classes.root }} style={{ width: 650 }}>
        <div
          className="popUp"
          style={{
            backgroundColor: !isNight ? "#212047" : "#e5e5e5",
          }}
          //   style={{ justifyContent: "center", alignItems: "center" }}
        >
          <div className="popUpTitle" style={{ marginBottom: "1rem" }}>
            Find discord server ID{" "}
          </div>
          <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <img
              src={ICONS.DISCORDSHOT}
              width="30%"
              height="400px"
              alt="discord screenshot"
            />
            <div style={{ width: "70%" }}>
              <ol>
                <li>Login to disocrd app</li>
                <li>Select the server you want to connect</li>
                <li>
                  Turn on developer mode
                  <ul>
                    <li>Click on setting icon on the bottom</li>
                    <li>Go to advance tab and enable developer mode</li>
                  </ul>
                </li>
                <li>
                  Right click on server name on the top and copy Server ID
                </li>
              </ol>
              <div
                className="cancelBtn"
                onClick={handleClose}
                style={{ width: "100px", marginLeft: "20px" }}
              >
                <div className="rangeBtn">
                  <CloseIcon />
                  <div className="btnText">Cancel</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
