import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DataGrid,
  GridToolbarExport,
  gridClasses,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core/styles";
import { demoDetailedData } from "./demoData";
import { GET_DETAILED_INVESTIGATING_TABLE } from "../../../redux/Dashboard/Dashboard.types";

const columns = [
  { field: "UserId", headerName: "User ID", width: 150 },
  { field: "UserName", headerName: "User Name", width: 150 },

  {
    field: "text_id",
    headerName: "Text ID",
    width: 350,
    editable: true,
  },
  {
    field: "text",
    headerName: "Text",
    width: 200,
    editable: true,
  },
  {
    field: "sendingTime",
    headerName: "Sending Time",
    width: 220,
    editable: true,
  },
  {
    field: "source",
    headerName: "Source",
    width: 120,
    editable: true,
  },
  {
    field: "channelName",
    headerName: "Channel Name",
    width: 200,
    editable: true,
  },

  {
    field: "PII",
    headerName: "PII",
    width: 250,
    editable: true,
  },
  {
    field: "profanity_detection",
    headerName: "Profanity",
    width: 150,
    editable: true,
  },
  {
    field: "script_detection",
    headerName: "Script ",
    width: 150,
    editable: true,
  },
  {
    field: "insult_detection",
    headerName: "Insult",
    width: 150,
    editable: true,
  },
  {
    field: "hate_speech",
    headerName: "Hate Speech",
    width: 170,
    editable: true,
  },
  {
    field: "emotion_detection",
    headerName: "Emotion",
    width: 150,
    editable: true,
  },
  {
    field: "sentiment",
    headerName: "Sentiment",
    width: 250,
    editable: true,
  },
  {
    field: "sexualHarassment",
    headerName: "Sexual Harrasment",
    width: 250,
    editable: true,
  },
];

function DetailedInvestigation() {
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
    root: {
      color: !isNight ? "#aebcc9 !important" : "#000! important",
      fontSize: 13,
      border: "none",
      backgroundColor: !isNight ? "#212047" : "#F6F7FC",
      fontWeight: "500",
      "& .MuiDataGrid-columnHeaderWrapper": {
        backgroundColor: !isNight ? "#17123B" : "#e5e5e5!important",
      },
      "& .MuiTablePagination-root": {
        color: !isNight ? "#aebcc9" : "#000!important",
      },
      "& .Mui-disabled": {
        color: "rgba(255,255,255,0.5)!important",
      },
      "& .MuiIconButton-root": {
        color: "#6352C6!important",
      },
    },
    columnHeader: {
      backgroundColor: !isNight ? "#17123B" : "#e5e5e5",
    },
    cell: {
      "& .MuiDataGrid-sortIcon": {
        fill: "white!important",
        opacity: 1,
      },
      "& .MuiDataGrid-cellCheckbox": {
        borderColor: "white",
        color: "white",
      },
      "& 	.MuiDataGrid-iconSeparator": {
        fill: "white",
      },
    },
    filterRoot: {
      color: "white!important",
      "& .MuiButton-root": {
        color: "white!important",
      },
      "& .MuiButtonBase": {
        color: "white!important",
      },
    },
  });

  const classes = useStyles();
  const dispatch = useDispatch();

  const detailedTableData = useSelector(
    (data) => data.dashboard.detailedTableData
  );
  const [rows, setRows] = useState([]);

  const userWorkflows = useSelector((data) => data.dashboard.userWorkflows);
  const [selectedWorkflow, setselectedWorkflow] = useState(
    userWorkflows.data.Workflows[userWorkflows.data.Workflows.length - 1]
  );

  const updateSearchColumn = (event) => {
    const currentWorkflow = userWorkflows.data.Workflows.filter(
      (userWorkflow) => userWorkflow.workflowName === event.target.value
    );
    setselectedWorkflow(currentWorkflow[0]);
  };
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  useEffect(() => {
    // setNewRows(rows);
    !isDemo &&
      dispatch({
        type: GET_DETAILED_INVESTIGATING_TABLE,
        payload: {
          workflowID: selectedWorkflow?.workflowID,
        },
      });
  }, [selectedWorkflow]);

  useEffect(() => {
    const updatedRow = [];
    detailedTableData.forEach((item, idx) => {
      let sentimentStr = "";
      if (item?.models.includes("sentiment")) {
        item?.analysis?.sentiment?.length > 0 &&
          item?.analysis?.sentiment?.forEach((item) => {
            let itemLabel = item?.label;
            let itemScore = item?.score?.toFixed(2);
            sentimentStr += `${itemLabel} ${itemScore}`;
          });
      } else {
        sentimentStr = "-";
      }
      updatedRow.push({
        id: idx + 1,
        UserId: item?.user?.userID,
        UserName: item?.user?.userName,
        text_id: item?.textID,
        text: item?.text,
        sendingTime: item?.timeSent,
        source: item?.platform,
        channelName: item?.channel?.channelName,
        PII: item?.models.includes("personalInfo")
          ? item?.analysis?.personalInfo?.toString()
          : "-",
        profanity_detection: item?.models.includes("profanityDetection")
          ? item?.analysis?.profanityDetection
            ? item?.analysis?.profanityDetection?.is_profane
            : "-"
          : "-",
        script_detection: item?.models.includes("scriptDetection")
          ? item?.analysis?.scriptDetection?.is_XSS
            ? "XSS"
            : item?.analysis?.scriptDetection?.is_code
            ? "Code"
            : "-"
          : "-",
        insult_detection: item?.models.includes("Insult")
          ? item?.analysis?.Insult
          : "-",
        hate_speech: item?.models.includes("hateSpeech")
          ? item?.analysis?.hateSpeech
          : "-",
        emotion_detection: item?.models.includes("emotionDetection")
          ? item?.analysis?.emotionDetection?.emotion
          : "-",
        sentiment: item?.models.includes("sentiment") ? sentimentStr : "-",
        sexualHarassment: item?.models.includes("SexualHarassment")
          ? item?.analysis?.SexualHarassment
          : "-",
      });
    });
    setRows(updatedRow);
    // setNewRows(rows);
  }, [detailedTableData]);

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport style={{ color: !isNight ? "#aebcc9" : "#000" }} />
      </GridToolbarContainer>
    );
  }

  return (
    <div
    // style={{
    //   justifyContent: "center",
    //   width: "90%",
    //   backgroundColor: !isNight ? "#17123B" : "fff",
    //   borderRadius: "20px",
    //   padding: "1rem",
    //   margin: "0rem 0 4rem",
    // }}
    >
      <div className="filterBar">
        {/* <img src={ExportLogo} alt="export-logo" /> */}
        <div>
          <select
            style={
              !isNight
                ? {
                    border: "1px solid #aebcc9",
                    backgroundColor: "#212047",
                    color: "#aebcc9",
                    justifyContent: "flex-end",
                  }
                : { justifyContent: "flex-end" }
            }
            name="Model"
            className="dropdown"
            onChange={(event) => {
              updateSearchColumn(event);
            }}
          >
            {userWorkflows.data.Workflows.slice(0)
              .reverse()
              .map((workflowData, id) => (
                <option key={id + 1} id="time-date">
                  {workflowData.workflowName}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div style={{ height: 480, color: "white" }}>
        <DataGrid
          components={{
            Toolbar: CustomToolbar,
          }}
          rows={isDemo ? demoDetailedData : rows}
          columns={columns}
          classes={{
            root: classes.root,
            columnHeader: classes.columnHeader,
            columnHeaderWrapper: classes.columnHeaderWrapper,
            cell: classes.cell,
          }}
          //   autoHeight
        />
      </div>
    </div>
  );
}

export default DetailedInvestigation;
