import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Tooltip } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";

import CloseIcon from "@mui/icons-material/Close";
import {
  GET_ENGAGEMENT_TOPICS,
  SAVE_ENGAGEMENT_TOPICS,
} from "../../../../../redux/Dashboard/Dashboard.types";
import PlanCard from "./PlanCard";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function PlansModal({ plans, handleClose }) {
  const dispatch = useDispatch();

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const [topics, setTopics] = useState([""]);
  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Recommended Action Plans
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />
      <Box
        sx={{
          margin: "1rem 0",
          maxHeight: "75vh",
          overflowY: "scroll",
        }}
      >
        {/* <Typography>Do you want to delete this topic?</Typography> */}
        {/* <Grid container spacing={2}>
                {!_.isEmpty(currentPlans) &&
                  currentPlans
                    .reverse()
                    .filter((item) => item.status === "recommended")
                    .map((plan, index) => (
                      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}> */}
        <Grid container spacing={2}>
          {plans.map((plan) => (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Box>
                <PlanCard plan={plan} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>{" "}
      <Stack direction="row" spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
      </Stack>
    </Box>
  );
}

export default PlansModal;
