export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_BY_LOCAL_STORAGE = "LOGIN_BY_LOCAL_STORAGE";
export const LOGIN_BY_LOCAL_STORAGE_SUCCESS = "LOGIN_BY_LOCAL_STORAGE_SUCCESS";
export const LOGIN_BY_LOCAL_STORAGE_FAILURE = "LOGIN_BY_LOCAL_STORAGE_FAILURE";
export const CHANGE_LOGIN = "CHANGE_LOGIN";
export const CHANGE_LOGIN_SUCCESS = "CHANGE_LOGIN_SUCCESS";
export const LOGIN_EMAIL = "LOGIN_EMAIL";

export const DEMO_LOGIN = "DEMO_LOGIN";
export const SAVE_DEMO_ID = "SAVE_DEMO_ID";

export const CHECK_DOMAIN_EXISTS = "CHECK_DOMAIN_EXISTS";
export const CHECK_DOMAIN_EXISTS_SUCCESS = "CHECK_DOMAIN_EXISTS_SUCCESS";
export const CHECK_DOMAIN_EXISTS_FAILURE = "CHECK_DOMAIN_EXISTS_FAILURE";