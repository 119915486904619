import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";
// import CheckIcon from "@material-ui/icons/Check";
import SlackLogin from "./SlackLogin";
import DiscordLogin from "./DiscordLogin";
import {
  GET_ACCESS_TOKEN,
  GET_CHANNELS,
  SAVING_ACCESS_TOKEN,
  VERIFY_TOKEN,
} from "../../../../redux/Dashboard/Dashboard.types";
// import { CHECK_KEYS } from "../../redux/Dashboard/Dashboard.types";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#fff",
    padding: "0!important",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    "& div": {
      color: "#fff",
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    marginTop: 20,
    color: "rgba(0, 0, 0, 0.87)!important",
    borderBlockColor: "#6352C6",
  },
  input: {
    color: "#fff",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    padding: 0,
  },
}));

export default function KeysModal({ open, handleClose, title }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const slackKey = useSelector((data) => data.dashboard.slackKey);
  const accessToken = useSelector((data) => data.dashboard.accessToken);
  const userID = JSON.parse(localStorage.getItem("userData")).userId;
  const userToken = JSON.parse(localStorage.getItem("userData")).usertoken;
  const accessTokenSave = useSelector((data) => data.dashboard.accessTokenSave);

  const onFailed = () => {};

  const onSuccess = () => {
    toast.success("Bot added successfully.");
  };

  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <DialogContent classes={{ root: classes.root }} style={{ width: 300 }}>
        <div
          className="popUp"
          style={{
            backgroundColor: !isNight ? "#212047" : "#e5e5e5",
          }}
        >
          <div
            className="popUpTitle"
            style={{ color: !isNight ? "#aebcc9" : "#000" }}
          >
            {title}
          </div>
          <form className={classes.container}>
            {/* <p style={{ marginBottom: "0" }}>1. Add our bot to discord</p> */}
            <div style={{ marginBottom: 20, marginTop: 10 }}>
              {!accessTokenSave ? (
                <>
                  <DiscordLogin
                    // redirectUrl="https://api.trupulse.ai/oauth"
                    onFailure={onFailed}
                    onSuccess={onSuccess}
                    // slackClientId="2415696530561.2965925345619"
                    // slackUserScope="channels:read,channels:history,groups:history,mpim:history,im:history"
                    state={`slack-${userID}`}
                  />
                </>
              ) : (
                <></>
                // <p>Already Authenicated</p>
              )}
            </div>
            <div className="btnOptions">
              <div className="cancelBtn" onClick={handleClose}>
                <div className="rangeBtn">
                  <CloseIcon style={{ color: !isNight ? "#aebcc9" : "#000" }} />
                  <div
                    className="btnText"
                    style={{ color: !isNight ? "#aebcc9" : "#000" }}
                  >
                    Cancel
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
}
