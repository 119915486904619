import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@mui/material/Grid";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";
import LockClockOutlinedIcon from "@mui/icons-material/LockClockOutlined";
import {
  GET_LICENSE_PLANS,
  OPT_LICENSE,
} from "../../redux/Dashboard/Dashboard.types";
// import CheckIcon from "@material-ui/icons/Check";
// import { CHECK_KEYS } from "../../redux/Dashboard/Dashboard.types";
import RedeemIcon from "@mui/icons-material/Redeem";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
const useStyles = makeStyles((theme) => ({
  root: {
    color: "#000",
    padding: "0 !important",
  },
  container: {
    // display: "flex",
    // flexWrap: "wrap",
    flexDirection: "column",
    "& div": {
      color: "#fff",
    },
  },
}));

export default function LessUsersWarning({ open, handleClose, teamSize }) {
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      style={{ width: "700px", margin: "auto" }}
    >
      <DialogContent style={{ background: "#212047" }}>
        <p
          style={{ color: !isNight ? "#aebcc9" : "#666", textAlign: "center" }}
        >{`${teamSize} users detetected!`}</p>
        <p
          style={{ color: !isNight ? "#aebcc9" : "#666", textAlign: "center" }}
        >
          There Should be more than 5 users to plot the graphs correctly.
        </p>
      </DialogContent>
    </Dialog>
  );
}
