import React, { useContext, useState, useEffect } from "react";
import "../styles/dashboard.css";
import TrialBanner from "../Components/common/TrialBanner";
import TrialModal from "../Components/common/TrialModal";
import Navbar from "../Components/Navbar";
import { useDispatch, useSelector } from "react-redux";
import LogicScreen from "../Components/Dashboard/Logic/LogicScreen";
import Overview from "../Components/Dashboard/Overview/Overview";
import GlassdoorOverview from "../Components/Dashboard/Overview/GlassdoorOverview";
import TeamblindOverview from "../Components/Dashboard/Overview/TeamblindOverview";
import Investigation from "../Components/Dashboard/Investigation/Investigation";
import ProfilePage from "../Components/Dashboard/ProfileMenu/ProfilePage";
import SettingPage from "../Components/Dashboard/ProfileMenu/Settings";
import { CHANGE_LOGIN } from "../redux/Login/Login.types";
import { CLEAR_DATA } from "../redux/Dashboard/Dashboard.types";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../Context/UserContext";
import { SET_NIGHT_MODE } from "../redux/Dashboard/Dashboard.types";
import Modal from "@material-ui/core/Modal";
import PlansModal from "../Components/common/PlansModal";
import UserSubPopup from "../Components/common/UserSubPopup";
import LessUsersWarning from "../Components/common/LessUsersWarning";
import ArchieveOverview from "../Components/Dashboard/Overview/ArchieveOverview";
import SlackOverview from "../Components/Dashboard/Overview/SlackOverview";
import TeamsOverview from "../Components/Dashboard/Overview/TeamsOverview";
import ZoomOverview from "../Components/Dashboard/Overview/ZoomOverview";
import Feedback from "../Components/Dashboard/Feedback/Feedback";
import Assistant from "../Components/Dashboard/Assistant/Assistant";
import Widgets from "../Components/Dashboard/Widgets";
import SupportPage from "./SupportPage";

export default function Dashboard() {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useContext(AuthContext);

  const userDetails = useSelector((data) => data.dashboard.userDetails);
  const storedData = JSON.parse(localStorage.getItem("userData"));
  const nightMode = JSON.parse(localStorage.getItem("nightMode"));
  const teamSize = useSelector((data) => data.dashboard.teamSize);

  const [currentScreen, setCurrentScreen] = useState("overview");

  useEffect(() => {
    if (window.location.href.includes("logic")) {
      setCurrentScreen("logic");
    }
    //  else if (window.location.href.includes("archive")) {
    //   setCurrentScreen("archive");
    // }
    else if (window.location.href.includes("slack")) {
      setCurrentScreen("slack");
    } else if (window.location.href.includes("teams")) {
      setCurrentScreen("teams");
    } else if (window.location.href.includes("email")) {
      setCurrentScreen("zoom");
    } else if (window.location.href.includes("glassdoor")) {
      setCurrentScreen("glassdoor");
    } else if (window.location.href.includes("teamblind")) {
      setCurrentScreen("teamblind");
    } else if (window.location.href.includes("overview")) {
      setCurrentScreen("overview");
    } else if (window.location.href.includes("investigation")) {
      setCurrentScreen("investigation");
    } else if (window.location.href.includes("profile")) {
      setCurrentScreen("profile");
    } else if (window.location.href.includes("setting")) {
      setCurrentScreen("setting");
    } else if (window.location.href.includes("action-plan")) {
      setCurrentScreen("action-plan");
    } else if (window.location.href.includes("feedback")) {
      setCurrentScreen("feedback");
    } else if (window.location.href.includes("support")) {
      setCurrentScreen("support");
    }
    dispatch({ type: SET_NIGHT_MODE, payload: nightMode });
  });

  const logout = () => {
    dispatch({ type: CLEAR_DATA });
    dispatch({ type: CHANGE_LOGIN });
    auth.logout();
    localStorage.removeItem("auth-token");
    localStorage.removeItem("userData");
    localStorage.removeItem("userDetails");
    // window.location.reload();
    history.push("/");
  };

  useEffect(() => {
    if (!storedData && !storedData?.usertoken) {
      logout();
    }
  });
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const [isTrialBannerOpen, setIsTrialBannerOpen] = useState(false);
  const [isTrialModalOpen, setIsTrialModalOpen] = useState(false);
  const [plansModalOpen, setPlansModalOpen] = useState(true);
  const [userSubPopupOpen, setUserSubPopup] = useState(false);
  const [trialExpired, setTrialExpired] = useState(false);

  const openTrialBanner = () => {
    setIsTrialBannerOpen(true);
  };

  const closeTrialBanner = () => {
    setIsTrialBannerOpen(false);
  };

  const openTrialModal = () => {
    setIsTrialModalOpen(true);
  };

  const closeTrialModal = () => {
    setIsTrialModalOpen(close);
    openPlansModal();
  };

  const openPlansModal = () => {
    setPlansModalOpen(true);
  };

  const closePlansModal = () => {
    setPlansModalOpen(false);
  };
  const openSubMdal = () => {
    setUserSubPopup(true);
  };
  const closeSubModal = () => {
    setUserSubPopup(false);
  };

  useEffect(() => {
    if (userDetails.licenseActive) {
      closePlansModal();
      setTrialExpired(true);
      if (
        parseInt(
          (new Date(userDetails?.license[0]?.expirationTime) - new Date()) /
          (1000 * 60 * 60 * 24),
          10
        ) < 5
      ) {
        openTrialBanner();
      }
    } else if (new Date(userDetails?.license[0]?.expirationTime) < new Date()) {
      setTrialExpired(true);
      closePlansModal();
      openTrialModal();
      openTrialBanner();
    } else if (userDetails?.role === "user") {
      closePlansModal();
      openSubMdal();
    }
  }, [userDetails]);

  const [lessUserWarningOpen, setLessUserWarningOpen] = useState(false);
  const closeLessUserWarning = () => {
    setLessUserWarningOpen(false);
  };
  useEffect(() => {
    if (teamSize < 5 && teamSize > 0) {
      setLessUserWarningOpen(true);
    }
  }, [teamSize]);
  return (
    <div
      className="dashboardContainer"
      style={{ background: !isNight ? "#17123B" : "#fff" }}
    >
      <Widgets />
      {isTrialBannerOpen && (
        <TrialBanner
          open={isTrialBannerOpen}
          handleClose={closeTrialBanner}
          openPlans={openPlansModal}
          days={parseInt(
            (new Date(userDetails?.license[0]?.expirationTime) - new Date()) /
            (1000 * 60 * 60 * 24),
            10
          )}
        />
      )}
      <div>
        <Navbar
          setCurrentScreen={(screen) => setCurrentScreen(screen)}
          currentScreen={currentScreen}
          isLoggedIn={true}
        />
      </div>
      {currentScreen === "overview" &&
        userDetails.permissions.overview !== null && (
          <Overview
            setCurrentScreen={(screen) => setCurrentScreen(screen)}
            currentScreen={currentScreen}
          />
        )}
      {currentScreen === "feedback" &&
        userDetails.permissions.overview !== null && (
          <Feedback
            setCurrentScreen={(screen) => setCurrentScreen(screen)}
            currentScreen={currentScreen}
          />
        )}
      {currentScreen === "action-plan" &&
        userDetails.permissions.overview !== null && (
          <Assistant
            setCurrentScreen={(screen) => setCurrentScreen(screen)}
            currentScreen={currentScreen}
          />
        )}
      {currentScreen === "archive" &&
        userDetails.permissions.overview !== null && (
          <ArchieveOverview
            setCurrentScreen={(screen) => setCurrentScreen(screen)}
            currentScreen={currentScreen}
          />
        )}
      {currentScreen === "slack" &&
        userDetails.permissions.overview !== null && (
          <SlackOverview
            setCurrentScreen={(screen) => setCurrentScreen(screen)}
            currentScreen={currentScreen}
          />
        )}
      {currentScreen === "teams" &&
        userDetails.permissions.overview !== null && (
          <TeamsOverview
            setCurrentScreen={(screen) => setCurrentScreen(screen)}
            currentScreen={currentScreen}
          />
        )}
      {currentScreen === "zoom" &&
        userDetails.permissions.overview !== null && (
          <ZoomOverview
            setCurrentScreen={(screen) => setCurrentScreen(screen)}
            currentScreen={currentScreen}
          />
        )}
      {currentScreen === "glassdoor" &&
        userDetails.permissions.overview !== null && (
          <GlassdoorOverview
            setCurrentScreen={(screen) => setCurrentScreen(screen)}
            currentScreen={currentScreen}
          />
        )}
      {currentScreen === "teamblind" &&
        userDetails.permissions.overview !== null && (
          <TeamblindOverview
            setCurrentScreen={(screen) => setCurrentScreen(screen)}
            currentScreen={currentScreen}
          />
        )}
      {currentScreen === "logic" && userDetails.permissions.logic !== null && (
        <LogicScreen />
      )}
      {currentScreen === "investigation" &&
        userDetails.permissions.investigation !== null && <Investigation />}
      {currentScreen === "profile" && <ProfilePage />}
      {currentScreen === "setting" && <SettingPage />}
      {currentScreen === "support" && <SupportPage />}
      {!isDemo && (
        <>
          {" "}
          <Modal
            open={isTrialModalOpen}
            onClose={closeTrialModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <TrialModal
              open={isTrialModalOpen}
              handleClose={closeTrialModal}
              title="Test"
            />
          </Modal>
          <Modal
            open={userSubPopupOpen}
            onClose={closeSubModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <UserSubPopup open={userSubPopupOpen} handleClose={closeSubModal} />
          </Modal>
          <Modal
            open={plansModalOpen}
            onClose={closePlansModal}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <PlansModal
              open={plansModalOpen}
              handleClose={closePlansModal}
              showTrial={trialExpired}
            />
          </Modal>
          <Modal
            open={lessUserWarningOpen}
            onClose={closeLessUserWarning}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
          >
            <LessUsersWarning
              open={lessUserWarningOpen}
              handleClose={closeLessUserWarning}
              teamSize={teamSize}
            />
          </Modal>
        </>
      )}
    </div>
  );
}
