/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
import React, { useState, useRef, useEffect } from "react";
import ReactFlow, {
  ReactFlowProvider,
  addEdge,
  removeElements,
  Controls,
  Background,
} from "react-flow-renderer";
import { useDispatch, useSelector } from "react-redux";
import BuildIcon from "@material-ui/icons/Build";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import AddIcon from "@material-ui/icons/Add";
import Modal from "@material-ui/core/Modal";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputBase from "@material-ui/core/InputBase";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { toast } from "react-toastify";
import { Tooltip } from "@material-ui/core";
import Sidebar from "./Sidebar/Sidebar";
import { getIcon } from "../../../utilities/dashboardUtils";
import { ICONS } from "../../../AppConfig";
import "../../../styles/Sidebar.css";
import "../../../styles/dashboard.css";
import axios from "axios";
import BuildProgress from "./BuildProgress";
import {
  BUILD_WORKFLOW,
  DELETE_WORKFLOW,
  EMPTY_CHANNELS,
  GET_USER_WORKFLOW,
  GET_WORKFLOW,
  SAVE_WORKFLOW,
  UPDATE_WORKFLOW,
  SAVE_TEAM_CHATS,
  CHECK_SOURCE_DISCORD,
  SAVE_DEMO_WORK,
  SHOW_BUILD_STATUS,
} from "../../../redux/Dashboard/Dashboard.types";
import DefaultModal from "../DefaultModal";
import DeleteModal from "./Configuration/DeleteModal";
import TutorialModal from "./Configuration/TutorialModal";
import BuildOptionModal from "./Configuration/BuildOptionModal";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

const initialElements = [];
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },

  paper: {
    padding: 0,
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "transparent!important",
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}))(InputBase);

const DnDFlow = () => {
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  const reactFlowWrapper = useRef(null);

  const useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
    root: {
      color: "#000!important",
      fill: "#000!important",
      padding: "0!important",
      boxShadow: "none!important",
      backgroundColor: !isNight ? "#212047" : "#F6F7FC",
      outline: "none!important",
      border: "none!important",
      "& .MuiSvgIcon-root": {
        color: "#000!important",
        fill: "#000!important",
      },
      "& .MuiInputBase-root": {
        color: "#000!important",
      },
    },
  }));
  const classes = useStyles();
  const userID = JSON.parse(localStorage.getItem("userData")).userId;
  const workflow = useSelector((data) => data.dashboard.workflow);
  const saveStatus = useSelector((data) => data.dashboard.saveStatus);
  const buildProgressStatus = useSelector(
    (data) => data.dashboard.buildProgressStatus
  );
  const buildProgressValue = useSelector(
    (data) => data.dashboard.buildProgressValue
  );
  const userWorkflows = useSelector((data) => data.dashboard.userWorkflows);
  const uploadSourceStatus = useSelector(
    (data) => data.dashboard.uploadSourceStatus
  );

  const zoomTranscript = useSelector((data) => data.dashboard.zoomTranscript);
  const zoomMeetTime = useSelector((data) => data.dashboard.zoomMeetTime);
  const zoomfileName = useSelector((data) => data.dashboard.zoomfileName);
  const slackToken = useSelector((data) => data.dashboard.slackKey);
  const accessToken = useSelector((data) => data.dashboard.accessToken);

  const modelsConfigData = useSelector(
    (data) => data.dashboard.modelsConfigData
  );
  const actionConfigData = useSelector(
    (data) => data.dashboard.actionConfigData
  );
  const userDetails = useSelector((data) => data.dashboard.userDetails);
  const channelList = useSelector(
    (data) => data.dashboard.uploadSourceStatus.channels
  );

  const teamChannels = useSelector((data) => data.dashboard.teamChannels);
  const teamID = useSelector((data) => data.dashboard.teamID);
  const discordServerID = useSelector((data) => data.dashboard.discordServerID);

  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [elements, setElements] = useState(initialElements);
  const [workflowName, setWorkflowName] = useState("");
  const [workflowNameOpen, setWorkflowNameOpen] = useState(false);
  const [buildOptionModalOpen, setBuildOptionModalOpen] = useState(false);
  const [currentWorkflowID, setCurrentWorkflowID] = useState("");
  const [deleteWorkflowOpen, setDeleteWorkflowOpen] = useState(false);
  const [tutorialModalOpen, setTutorialModalOpen] = useState(false);
  const [currentTeamChannel, setCurrentTeamChannel] = useState([]);
  const [channelsToFetch, setChannelsToFetch] = useState([]);
  const [teamChats, setTeamChats] = useState([]);
  const [sourceLabel, setSourceLabel] = useState("");

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  function allStorage() {
    var archive = [],
      keys = Object.keys(sessionStorage),
      i = 0,
      key;

    for (; (key = keys[i]); i++) {
      if (key.includes("accesstoken")) {
        archive.push(
          key.includes("accesstoken") && JSON.parse(sessionStorage.getItem(key))
        );
      }
    }

    return archive[archive.length - 1];
  }
  const fetchTeamsChat = async (channelArray) => {
    channelArray.forEach(async (item) => {
      const res = await axios.get(
        `https://graph.microsoft.com/beta/teams/${teamID}/channels/${item.id}/messages/`,
        {
          headers: {
            "Access-Control-Allow-Headers": "Authorization",
            Authorization: `Bearer ${allStorage().secret}`,
          },
        }
      );
      let currentChats = res.data.value;
      Array.prototype.push.apply(currentChats, teamChats);

      setTeamChats(currentChats);
    });
  };

  useEffect(() => {
    let currentID = "";
    userWorkflows?.data.Workflows.forEach((item) => {
      // if(item)
      if (workflowName === item.workflowName) {
        currentID = item.workflowID;
      }
    });

    if (teamChats.length > 0 && !isDemo) {
      dispatch({
        type: SAVE_TEAM_CHATS,
        payload: { workflow_id: currentID, texts: teamChats },
      });
    }
  }, [userWorkflows]);

  useEffect(() => {
    if (currentTeamChannel.length > 0) {
      const tempChannel = [];
      for (let i = 0; i < teamChannels.length; i++) {
        for (let j = 0; j < currentTeamChannel.length; j++) {
          if (currentTeamChannel[j] === teamChannels[i].name) {
            tempChannel.push(teamChannels[i]);
          }
        }
      }
      setChannelsToFetch(tempChannel);
      fetchTeamsChat(tempChannel);
    }
  }, [currentTeamChannel]);

  const onConnect = (params) => {
    params.arrowHeadType = "arrowclosed";
    params.animated = true;
    params.style = { stroke: "red" };

    setElements((els) => addEdge(params, els));
  };
  const dispatch = useDispatch();
  const onElementsRemove = (elementsToRemove) =>
    setElements((els) => removeElements(elementsToRemove, els));

  const onLoad = (_reactFlowInstance) =>
    setReactFlowInstance(_reactFlowInstance);

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const getBorderColor = (tab) => {
    switch (tab) {
      case "source":
        return "#5FA7F5";
      case "filter":
        return "#FFA300";
      case "model":
        return "#4cda89";
      case "action":
        return "#FF0892";
      default:
        return "";
    }
  };

  const getBackgroundColor = (tab) => {
    switch (tab) {
      case "source":
        return "rgba(26, 188, 254,0.7)";
      case "filter":
        return "rgba(255, 163, 0,0.7)";
      case "model":
        return "rgba(76, 218, 137, 0.7)";
      case "action":
        return "rgba(255, 20, 49,0.7)";
      default:
        return "";
    }
  };

  const onDrop = (event) => {
    event.preventDefault();
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData("application/reactflow");
    let label = event.dataTransfer.getData("application/messenger");
    const tabType = event.dataTransfer.getData("application/tabType");
    const position = reactFlowInstance.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    // Check validation for connection
    let isValidConnection;

    if (tabType === "source") {
      setSourceLabel(label);
      isValidConnection = () => tabType === "filter";
    }

    // put background image
    let backgroundImage;
    if (tabType === "source" || tabType === "action") {
      backgroundImage = getIcon(label);
    }
    if (tabType === "model") {
      if (label.includes("-")) {
        const iconLabel = label.split("-");
        backgroundImage = getIcon(iconLabel[1]);
        label = iconLabel[0];
      } else {
        backgroundImage = getIcon(label);
      }
    }
    if (tabType === "filter") {
      backgroundImage = ICONS.CHANNEL;
    }

    // let id =  elements.length;
    const getId = () => `dndnode_${elements.length}`;
    const newNode = {
      id: getId(),
      type,
      position,
      targetPosition: "left",
      sourcePosition: "right",
      data: { label },
      style: {
        backgroundRepeat: "no-repeat",
        backgroundSize: "40% 40%",
        backgroundOrigin: "content-box",
        fontSize: "14px",
        color: !isNight ? "#aebcc9" : "#666",
        fontWeight: "600",
        borderRadius: "8px ",
        background: `url(${backgroundImage}) no-repeat center center`,
        // filter: isNight ? "invert(0.5)":"",
        border: `3px solid ${getBorderColor(tabType)}`,
      },
      connectable: isValidConnection,
    };

    const emptyNode = [];
    if (tabType === "source") {
      setElements(() => emptyNode.concat(newNode));
    } else {
      setElements((es) => es.concat(newNode));
    }
  };

  if (sourceLabel === "Discord" && !isDemo) {
    dispatch({ type: CHECK_SOURCE_DISCORD, payload: true });
  }
  if (sourceLabel !== "Discord" && !isDemo) {
    dispatch({ type: CHECK_SOURCE_DISCORD, payload: false });
  }

  useEffect(() => {
    if (
      elements.length > 1 &&
      !elements[elements.length - 1]?.id.includes("react")
    ) {
      if (
        elements.filter((element) => element.data?.label === "Teams").length > 0
      ) {
        const channelsFromTeams = [];
        for (let i = 0; i < elements.length; i++) {
          for (let j = 0; j < channelList.length; j++) {
            if (channelList[j] === elements[i].data?.label) {
              channelsFromTeams.push(channelList[j]);
            }
          }
        }
        if (channelsFromTeams.length > 0) {
          setCurrentTeamChannel(channelsFromTeams);
        }
      }
      try {
        const emotionElements = elements.filter(
          (element) => element.data?.label === "Emotional"
        );
        const PIElements = elements.filter(
          (element) => element.data?.label === "Personal Information"
        );
        const profanityElements = elements.filter(
          (element) => element.data?.label === "Profanity"
        );
        const hateSpeechElements = elements.filter(
          (element) => element.data?.label === "Hate Speech"
        );
        const scriptElements = elements.filter(
          (element) => element.data?.label === "Script"
        );
        const sexualHarassmentElements = elements.filter(
          (element) => element.data?.label === "Sexual Harrasment"
        );
        const racismElements = elements.filter(
          (element) => element.data?.label === "Racism Detection"
        );
        const textAbuseElements = elements.filter(
          (element) => element.data?.label === "Textual Abuse"
        );
        const sentimentsElements = elements.filter(
          (element) => element.data?.label === "Sentiment Analysis"
        );
        const insultsElements = elements.filter(
          (element) => element.data?.label === "Insult Detection"
        );
        const sexismsElements = elements.filter(
          (element) => element.data?.label === "Sexism Detection"
        );
        const diversityElements = elements.filter(
          (element) => element.data?.label === "Diversity Inclusion"
        );

        emotionElements.forEach((ele, idx) => {
          modelsConfigData.emotionData[idx].id = ele.id;
        });

        PIElements.forEach((ele, idx) => {
          modelsConfigData.personalInfoData[idx].id = ele.id;
        });

        profanityElements.forEach((ele, idx) => {
          modelsConfigData.profanityData[idx].id = ele.id;
        });
        hateSpeechElements.forEach((ele, idx) => {
          modelsConfigData.hateSpeechData[idx].id = ele.id;
        });
        scriptElements.forEach((ele, idx) => {
          modelsConfigData.scriptData[idx].id = ele.id;
        });
        sexualHarassmentElements.forEach((ele, idx) => {
          modelsConfigData.sexualHarassmentData[idx].id = ele.id;
        });
        racismElements.forEach((ele, idx) => {
          modelsConfigData.RacismData[idx].id = ele.id;
        });
        textAbuseElements.forEach((ele, idx) => {
          modelsConfigData.textAbuseData[idx].id = ele.id;
        });
        sentimentsElements.forEach((ele, idx) => {
          modelsConfigData.SentimentData[idx].id = ele.id;
        });
        insultsElements.forEach((ele, idx) => {
          modelsConfigData.insultData[idx].id = ele.id;
        });
        sexismsElements.forEach((ele, idx) => {
          modelsConfigData.sexismData[idx].id = ele.id;
        });
        diversityElements.forEach((ele, idx) => {
          modelsConfigData.diversityData[idx].id = ele.id;
        });

        const emailElements = elements.filter(
          (element) => element.data?.label === "Email"
        );

        emailElements.forEach((ele, idx) => {
          actionConfigData.email[idx].id = ele.id;
        });
      } catch (err) {
        if (currentWorkflowID === "") {
          toast.error("Add configuration first!");
          delete elements[elements.length - 1];
        }
      }
    }
  }, [elements]);

  useEffect(() => {
    saveStatus?.data?.data?.workflowID &&
      setCurrentWorkflowID(saveStatus?.data?.data?.workflowID);
  }, [saveStatus]);

  const workflowNameOpenHandler = () => {
    setWorkflowNameOpen(true);
  };
  const workflowNameCloseHandler = () => {
    setWorkflowNameOpen(false);
  };
  const buildOptionModalOpenHandler = () => {
    setBuildOptionModalOpen(true);
  };
  const buildOptionModalCloseHandler = () => {
    setBuildOptionModalOpen(false);
  };

  const deleteWorkflowOpenHandler = () => {
    setDeleteWorkflowOpen(true);
  };

  const tutorialModalOpenHandler = () => {
    setTutorialModalOpen(true);
  };

  const tutorialModalCloseHandler = () => {
    setTutorialModalOpen(false);
  };

  const deleteWorkflowCloseHandler = () => {
    setDeleteWorkflowOpen(false);
  };

  const onSave = () => {
    if (!isDemo && currentWorkflowID === "") {
      dispatch({
        type: SAVE_WORKFLOW,
        payload: {
          workflowID: userID + Math.round(new Date().getTime() / 1000),
          workflow: elements,
          token: accessToken && accessToken?.data?.authed_user?.access_token,
          teamFilters: channelsToFetch,
          discordServerID,
          workflowName,
          userID,
          clientID: JSON.parse(localStorage.getItem("userData")).userId,
          fileName: uploadSourceStatus.fileName,
          model_config: modelsConfigData,
          action_config: actionConfigData,
          workSpaceName: accessToken?.data?.team?.name,
          zoomData: {
            transcript: zoomTranscript,
            meetTime: zoomMeetTime,
            zoomfileName: zoomfileName,
          },
        },
      });
    } else {
      dispatch({
        type: UPDATE_WORKFLOW,
        payload: {
          workflowID: currentWorkflowID,
          workflow: elements,
          token: accessToken && accessToken?.data?.authed_user?.access_token,
          teamFilters: channelsToFetch,
          discordServerID,
          workflowName,
          userID,
          clientID: JSON.parse(localStorage.getItem("userData")).userId,
          fileName: uploadSourceStatus.fileName,
          model_config: modelsConfigData,
          action_config: actionConfigData,
        },
      });
    }
    if (isDemo) {
      var demoWork = userWorkflows;
      demoWork.data?.Workflows?.push({
        workflowID: userID + Math.round(new Date().getTime() / 1000),
        workflowName,
      });
      dispatch({ type: SAVE_DEMO_WORK, payload: demoWork });
      setTimeout(() => {
        toast.success("Workflow Saved");
      }, 1000);
    }
  };

  const onLoadWorflow = (value) => {
    setCurrentWorkflowID(value);
    dispatch({
      type: SHOW_BUILD_STATUS,
      payload: { workflowID: value },
    });
    !isDemo &&
      dispatch({
        type: GET_WORKFLOW,
        payload: {
          workflowID: value,
          userID,
        },
      });
  };

  const onBuilt = (onceOrPeriodically) => {
    if (saveStatus || currentWorkflowID) {
      if (currentWorkflowID) {
        !isDemo &&
          dispatch({
            type: BUILD_WORKFLOW,
            payload: {
              workflowID: currentWorkflowID,
              userID,
              buildOnce: onceOrPeriodically !== "periodically" ? true : false,
            },
          });
      } else {
        !isDemo &&
          dispatch({
            type: BUILD_WORKFLOW,
            payload: {
              workflowID: saveStatus.data.data.workflowID,
              userID,
              buildOnce: onceOrPeriodically !== "periodically" ? true : false,
            },
          });
      }
    } else {
      toast.error("Please save workflow first");
    }

    setTimeout(() => {
      toast.info("Build is processing");
    }, 1000);
    buildOptionModalCloseHandler();
  };

  const resetWorkflow = () => {
    !isDemo && dispatch({ type: EMPTY_CHANNELS, payload: "EMPTY" });
    setElements([]);
    setWorkflowName("");
    setCurrentWorkflowID("");
  };

  const onDeleteWorkflow = () => {
    if (saveStatus || currentWorkflowID) {
      if (currentWorkflowID) {
        !isDemo &&
          dispatch({
            type: DELETE_WORKFLOW,
            payload: {
              workflowID: currentWorkflowID,
              userID,
            },
          });
      } else {
        !isDemo &&
          dispatch({
            type: DELETE_WORKFLOW,
            payload: {
              workflowID: saveStatus.data?.data?.workflowID,
              userID,
            },
          });
      }
    } else {
      toast.error("Please save workflow first");
    }
    deleteWorkflowCloseHandler();
    setTimeout(() => {
      resetWorkflow();
      !isDemo && dispatch({ type: GET_USER_WORKFLOW });
    }, 3000);
  };

  useEffect(() => {
    if (workflow.data.length > 0) {
      setElements(workflow.data);
      setWorkflowName(workflow.workflowName);
    } else {
      setElements([]);
    }
  }, [workflow]);

  useEffect(() => {
    !isDemo && dispatch({ type: GET_USER_WORKFLOW });
  }, [saveStatus]);

  const isArchive =
    elements?.filter((item) => item?.data?.label === "Archive")?.length > 0;

  return (
    <>
      <div
        className="logicOptionContainer"
        style={!isNight ? { backgroundColor: "#212047" } : {}}
      >
        <div className="leftMenu">
          <div style={{ marginLeft: "2rem" }}>
            <Tooltip title="Help">
              <HelpOutlineIcon
                onClick={tutorialModalOpenHandler}
                style={{
                  cursor: "pointer",
                  marginLeft: "2rem",
                  color: !isNight ? "#aebcc9" : "#000",
                }}
              />
            </Tooltip>
            {/* {userDetails.permissions.logic === "W" && ( */}
            <Tooltip title="Delete workflow">
              <DeleteOutlineIcon
                onClick={deleteWorkflowOpenHandler}
                style={{
                  cursor: "pointer",
                  marginLeft: "2rem",
                  color: !isNight ? "#aebcc9" : "#000",
                }}
              />
            </Tooltip>
            {/* )} */}
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <Tooltip title="Rename Workflow">
            <div
              className="workflowName"
              onClick={workflowNameOpenHandler}
              style={{
                cursor: "pointer",
                border: workflowName === "" ? "1px solid #e77803" : "",
                color:
                  workflowName === ""
                    ? "#e77803"
                    : !isNight
                    ? "#aebcc9"
                    : "#000",
                borderRadius: "5px",
                padding: "0.2rem 0.5rem",
              }}
            >
              {workflowName === "" ? "Workflow Name" : workflowName}{" "}
              <span style={{ position: "relative", top: "4px" }}>
                <EditOutlinedIcon fontSize="small" />
              </span>
            </div>
          </Tooltip>
        </div>
        <Modal
          open={workflowNameOpen}
          onClose={workflowNameCloseHandler}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <DefaultModal
            open={workflowNameOpen}
            handleClose={workflowNameCloseHandler}
            title="Workflow Name"
            currentState={workflowName}
            changeStateData={setWorkflowName}
            dispatch={false}
          />
        </Modal>
        <Modal
          open={deleteWorkflowOpen}
          onClose={deleteWorkflowCloseHandler}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <DeleteModal
            open={deleteWorkflowOpen}
            handleClose={deleteWorkflowCloseHandler}
            title="Delete Workflow"
            handleDelete={onDeleteWorkflow}
          />
        </Modal>
        <Modal
          open={tutorialModalOpen}
          onClose={tutorialModalCloseHandler}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <TutorialModal
            open={tutorialModalOpen}
            handleClose={tutorialModalCloseHandler}
            title="Delete Workflow"
            // handleDelete={onDeleteWorkflow}
          />
        </Modal>
        <div className="rightMenu">
          {currentWorkflowID !== "" && (
            <div style={{ margin: "0.4rem 2rem" }}>
              <BuildProgress
                value={buildProgressValue}
                workflowID={currentWorkflowID}
                status={buildProgressStatus}
                workflowName={workflowName}
              />
            </div>
          )}
          {/* <FolderOpenIcon style={{ color: !isNight ? "#aebcc9" : "#000" }} /> */}
          <Select
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value=""
            onChange={(event) => onLoadWorflow(event.target.value)}
            // input={<BootstrapInput />}
            className="workflowLIst"
            classes={{ root: classes.root }}
            MenuProps={{
              className: "margin35",
              MenuListProps: { classes: { root: classes.root } },
            }}
            disableGutters
            // IconComponent={FolderOpenIcon}
            IconComponent={() => (
              <span style={{ display: "flex" }}>
                <FolderOpenIcon
                  style={{
                    color: !isNight ? "#aebcc9" : "#000",
                    position: "relative",
                    left: "-60px",
                  }}
                />
                <ArrowDropDownIcon
                  style={{
                    color: !isNight ? "#aebcc9" : "#000",
                    position: "relative",
                    left: "-60px",
                  }}
                />
              </span>
            )}
          >
            {userWorkflows?.data.Workflows.map((item, id) => {
              return (
                <MenuItem key={id + 1} value={item.workflowID}>
                  <p
                    style={{
                      margin: "0",
                      color: !isNight ? "#aebcc9" : "#000",
                    }}
                  >
                    {item.workflowName}
                    <span
                      className="workflowBuildStatus"
                      style={{ position: "relative", top: "5px", left: "5px" }}
                    >
                      <img
                        style={{
                          width: "20px",
                          position: "relative",
                          bottom: "3px",
                        }}
                        src={
                          item.builtSuccessful
                            ? "https://img.icons8.com/emoji/48/000000/check-mark-emoji.png"
                            : "https://img.icons8.com/color/48/000000/delete-sign--v1.png"
                        }
                      />
                      {/* <img
                        style={{ width: "20px" }}
                        src="https://img.icons8.com/color/48/000000/delete-sign--v1.png"
                      /> */}
                    </span>
                  </p>
                </MenuItem>
              );
            })}
          </Select>
          {(userDetails.permissions.logic === "W" || isDemo) && (
            <div className="rightMenuOptions">
              <Tooltip title="New">
                <div className="notFilledBtn" onClick={resetWorkflow}>
                  <div className="rangeBtn">
                    <AddIcon style={{ color: !isNight ? "#fff" : "#000" }} />
                    <div
                      className="btnText"
                      style={{ color: !isNight ? "#fff" : "#000" }}
                    >
                      New
                    </div>
                  </div>
                </div>
              </Tooltip>
              <Tooltip title="Save">
                <div
                  className="notFilledBtn"
                  onClick={onSave}
                  style={
                    workflowName === ""
                      ? {
                          pointerEvents: "none",
                          opacity: "0.65",
                          filter: "alpha(opacity = 65)",
                          boxShadow: "none",
                        }
                      : {}
                  }
                >
                  <div
                    className="rangeBtn"
                    style={
                      workflowName === ""
                        ? {
                            cursor: "not-allowed",
                          }
                        : {}
                    }
                  >
                    <div
                      className="btnText"
                      style={
                        workflowName !== "" &&
                        (saveStatus === "" || saveStatus === undefined)
                          ? { color: "#e77803" }
                          : {}
                      }
                    >
                      <BookmarkBorderIcon
                        style={{ color: !isNight ? "#fff" : "#000" }}
                      />
                      <span
                        style={{
                          position: "relative",
                          bottom: "0.4rem",
                          color: !isNight ? "#fff" : "#000",
                        }}
                      >
                        Save
                      </span>
                    </div>
                  </div>
                </div>
              </Tooltip>
              <Tooltip title="Build">
                <div
                  className="filledBtn"
                  onClick={isArchive ? onBuilt : buildOptionModalOpenHandler}
                  style={
                    saveStatus === "" || saveStatus === undefined
                      ? // saveStatus.data?.status === 201
                        {
                          pointerEvents: "none",
                          opacity: "0.65",
                          filter: "alpha(opacity = 65)",
                          boxShadow: "none",
                        }
                      : {}
                  }
                >
                  <div className="rangeBtn">
                    <BuildIcon />
                    <div
                      className="btnText"
                      type="submit"
                      // style={{ color: isNight ? "#2A2953" : "#000" }}
                    >
                      Build
                    </div>
                  </div>
                </div>
              </Tooltip>
              <Modal
                open={buildOptionModalOpen}
                onClose={buildOptionModalCloseHandler}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
              >
                <BuildOptionModal
                  open={buildOptionModalOpen}
                  handleClose={buildOptionModalCloseHandler}
                  onBuilt={onBuilt}
                />
              </Modal>
            </div>
          )}
        </div>
      </div>
      <div className="dndflow">
        <ReactFlowProvider>
          <Sidebar />
          <div className="reactflow-wrapper" ref={reactFlowWrapper}>
            <ReactFlow
              elements={elements}
              onConnect={onConnect}
              onElementsRemove={onElementsRemove}
              onLoad={onLoad}
              onDrop={onDrop}
              onDragOver={onDragOver}
              deleteKeyCode={46}
            >
              <Background
                variant="lines"
                gap={20}
                size={1}
                style={{ background: !isNight ? "#17123B" : "#fff" }}
                color={!isNight ? "rgba(121,85,72,0.3)" : "rgba(121,85,72,0.3)"}
              />
              <Controls />
            </ReactFlow>
          </div>
        </ReactFlowProvider>
      </div>
    </>
  );
};

export default DnDFlow;
