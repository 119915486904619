import React, { useState } from "react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// import DoughnutChart from "./Doughnut";
// import HorizontalBarChart from "./HorizontalChart";
// import LineChart from "./LineChart";
// import VerticalBar from "./VerticalBar";
// import CircleProgress from "./GradientCircle";
import "../../styles/dashboard.css";
import { FormGroup, FormControlLabel, Checkbox } from "@material-ui/core/";

const ChartsModal = ({
  handleClose,
  handleAddRequestedSection,
  //  doughnutData
}) => {
  // const [availableSections, setAvailableSection] = useState([]);
  const [availableSections, setAvailableSection] = useState([
    {
      id: 1,
      name: "emotionDetection",
      label: "Emotion",
      isChecked: false,
    },
    {
      id: 2,
      name: "personalInfo",
      label: "Personal Information",
      isChecked: false,
    },
    {
      id: 3,
      name: "Morale",
      label: "Morale",
      isChecked: false,
    },
  ]);

  const [requestedSections, setRequestedSections] = useState([]);

  const handlePersonalInfos = (e, value, data) => {
    const availableSectionsCopy = [...availableSections];
    availableSectionsCopy[data.id - 1] = {
      ...availableSectionsCopy[data.id - 1],
      isChecked: value,
    };
    setAvailableSection(availableSectionsCopy);
  };

  const handleAddSection = () => {
    const availableSectionsData = [];
    availableSections.forEach((section) => {
      if (section.isChecked === true) {
        availableSectionsData.push(section.name);
      }
    });
    setRequestedSections(availableSectionsData);
  };

  const handleShowSections = () => {
    handleAddRequestedSection(requestedSections);
    handleClose();
  };

  return (
    <div
      className="addChartsContainer"
      style={{
        width: "70vw",
        height: "85vh",
        overflowY: "scroll",
        marginTop: "20px",
      }}
    >
      {/* <div className="closeBtn" onClick={handleClose}>
        <HighlightOffIcon style={{ fill: "white" }} />
      </div>
      <div>
        <div style={{ margin: "0 1rem" }}>
          <h5 style={{ color: "#fff", fontSize: "1.5rem", margin: "0 0 1rem" }}>
            Available sections for you
          </h5>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
          }}
        >
          <FormGroup sx={{ display: "flex" }}>
            {availableSections.map((personalInfo) => (
              <div key={personalInfo.id}>
                <FormControlLabel
                  control={<Checkbox color="white" style={{ color: "#fff" }} />}
                  label={
                    <span style={{ color: "#fff" }}>{personalInfo.label}</span>
                  }
                  name={personalInfo.name}
                  onChange={(e, value, data = personalInfo) =>
                    handlePersonalInfos(e, value, data)
                  }
                />
              </div>
            ))}
          </FormGroup>
        </div>
        <div>
          <button
            style={{
              background: "#6352C6",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "6px",
              minWidth: "80px",
              padding: "0.5rem",
              margin: "0 1rem",
            }}
            type="button"
            onClick={handleAddSection}
          >
            <span style={{ color: "#fff", fontSize: "1rem" }}>Add</span>
          </button>
        </div>
        <div style={{ margin: "1rem" }}>
          <h5 style={{ color: "#fff", fontSize: "1.5rem", margin: "0 0 1rem" }}>
            Requested sections
          </h5>
          <div
            style={{
              padding: "0.5rem 1rem",
              // minWidth: "50%",
              minHeight: "2rem",
              backgroundColor: "#313150",
              borderRadius: "5px",
            }}
          >
            {requestedSections.map((requestedSection) => (
              <span style={{ margin: "0 0.5rem", color: "#fff" }}>
                {requestedSection}
              </span>
            ))}
          </div>
        </div>
        <div>
          <button
            style={{
              background: "#6352C6",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
              borderRadius: "6px",
              minWidth: "80px",
              padding: "0.5rem",
              margin: "0 1rem",
            }}
            type="button"
            onClick={handleShowSections}
          >
            <span style={{ color: "#fff", fontSize: "1rem" }}>Show</span>
          </button>
        </div>
        
      </div> */}
      {/* <div className="addChartSection">
        <div className="singleAddChart">
          <div className="progressTitle">Numerial Data</div>
          <div className="singleChart">
            <CircleProgress
              percentage={100}
              strokeWidth={8}
              primaryColor={["#1ABCFE", "#A259FF"]}
              fontSize="24"
              fontColor="#FFFFFF"
              title={100}
              width={225}
            />
          </div>
        </div>
        <div className="singleAddChart">
          <div className="progressTitle">Multi Line Chart</div>
          <LineChart
            chartData={[
              { data: [10, 20, 30, 23, 16, 3, 19, 23], label: "ANGER" },
              { data: [4, 18, 23, 29, 11, 13, 29, 20], label: "JOY" },
              { data: [12, 29, 7, 17, 30, 13, 26, 2], label: "NEUTRAL" },
              { data: [2, 12, 17, 9, 25, 18, 19, 22], label: "SAD" },
              { data: [30, 19, 13, 29, 11, 21, 4, 12], label: "LOVING" },
            ]}
            chartLables={[1, 2, 3, 4, 5, 6, 7, 8]}
          />
        </div>
        <div className="singleAddChart">
          <div className="progressTitle">Vertical Bar Chart</div>
          <VerticalBar />
        </div>
        <div className="singleAddChart">
          <div className="progressTitle">Doughnut Chart</div>
          <div className="doughContainer">
            <DoughnutChart
              labels={doughnutData.labels}
              backgroundColor={doughnutData.backgroundColor}
              borderColor={doughnutData.borderColor}
              doughnutData={[12, 19, 3, 5, 2]}
              cutout={80}
            />
          </div>
        </div>
        <div className="singleAddChart">
          <div className="progressTitle">Horizontal Chart</div>
          <HorizontalBarChart />
        </div>
        <div className="singleAddChart">
          <div className="progressTitle">Single Line Chart</div>
          <LineChart
            chartData={[
              { data: [10, 20, 30, 35, 39, 45, 56, 76, 99], label: "Votes" },
            ]}
            chartLables={[1, 2, 3, 4, 5, 6, 7, 8]}
          />
        </div>
      </div> */}
    </div>
  );
};

export default ChartsModal;
