import React, { useState, useEffect, createRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Chart from "react-apexcharts";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@material-ui/core";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { useScreenshot } from "use-react-screenshot";
import * as clipboard from "clipboard-polyfill";
import { base64StringToBlob } from "blob-util";
import ReactECharts from "echarts-for-react";
import { ICONS } from "../../../../../AppConfig";
import {
  demoOverallCultureData,
  generateDemoCultureData,
} from "../../demoData";

function SubCultureMeter({
  currentWorkflowID,
  currentFilter,
  customDate,
  subculture,
}) {
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  const [clipProcessing, setClipProcessing] = useState(false);
  const totalText = useSelector((data) => data.dashboard.totalText);

  const getAvgFromTrend = (trend, term) => {
    let sum = 0;
    for (let i = 0; i < trend.length; i++) {
      sum += trend[i][term];
    }
    return Math.round(sum / trend.length);
  };

  async function clip(b64Data) {
    const item = new clipboard.ClipboardItem({
      "image/png": base64StringToBlob(b64Data.split(",")[1], "image/png"),
    });

    await clipboard.write([item]);
  }

  const PositiveMeterRef = createRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const getImage = async () => {
    setClipProcessing(true);
    await takeScreenshot(moraleTrendRef.current);
    setClipProcessing(false);
    image && clip(image);
  };

  useEffect(() => {
    image && clip(image);
  }, [image]);

  const filters = useSelector((data) => data.dashboard.filters);

  const stateCultureData = useSelector(
    (data) => data.dashboard.overallCultureData
  );

  const overallCultureData = useMemo(() => {
    return isDemo
      ? generateDemoCultureData(currentFilter, customDate)
      : stateCultureData;
  }, [isDemo, currentFilter, customDate, stateCultureData]);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const cultureDataArray = Array.isArray(overallCultureData?.cultureData)
    ? overallCultureData.cultureData
    : [];

  const value =
    overallCultureData.status === "success"
      ? getAvgFromTrend(cultureDataArray, "score")
      : 0;

  const min =
    overallCultureData.status === "success"
      ? Math.min(...cultureDataArray.map((item) => item?.score))
      : 0;

  const max =
    overallCultureData.status === "success"
      ? Math.max(...cultureDataArray.map((item) => item?.score))
      : 0;

  // const value =
  //   overallCultureData.status === "success"
  //     ? getAvgFromTrend(overallCultureData?.cultureData, "cultureScore")
  //     : 0;

  // const min =
  //   overallCultureData.status === "success"
  //     ? Math.min(
  //         ...overallCultureData?.cultureData?.map((item) => item?.cultureScore)
  //       )
  //     : 0;

  // const max =
  //   overallCultureData.status === "success"
  //     ? Math.max(
  //         ...overallCultureData?.cultureData?.map((item) => item?.cultureScore)
  //       )
  //     : 0;

  const option = {
    tooltip: {
      confine: true,
      formatter: "{b}: {c}",
    },
    series: [
      {
        name: "User",
        startAngle: 224,
        endAngle: 316,
        min: -100,
        max: 100,
        type: "gauge",
        axisLine: {
          roundCap: true,
          lineStyle: {
            width: 8,
            color: [
              [0.3, "#D06283"],
              [0.7, "#9FDCD9"],
              [1, "#04B8AF"],
            ],
          },
        },

        splitLine: {
          length: 8,
          distance: 5,
          lineStyle: {
            width: 1,
            color: !isNight ? "#aebcc9" : "#666",
          },
        },
        axisLabel: {
          distance: 5,
          color: !isNight ? "#aebcc9" : "#666",
          formatter: function (value) {
            return value.toFixed(0);
          },
        },
        splitNumber: 5,
        axisTick: {
          show: true,
          distance: 0,
          lineStyle: {
            color: !isNight ? "#aebcc9" : "#666",
          },
        },

        pointer: {
          icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
        },
        anchor: {
          show: true,
          showAbove: true,
          size: 18,
          itemStyle: {
            borderWidth: 5,
            color: "#484848",
            borderColor: "#fff",
            shadowColor: "rgba(0, 0, 0, 0.5)",
            shadowBlur: 8,
          },
        },
        title: {
          show: false,
        },
        data: [
          {
            value: value ? value : 0,
            name: `Average ${subculture} Score`,
            pointer: {
              icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
              length: "75%",
              width: 8,
              offsetCenter: [0, "0"],
              itemStyle: {
                color: "#04B8AF",
              },
            },
            detail: {
              valueAnimation: true,
              formatter: "{value}",
              offsetCenter: [0, "90%"],
              fontSize: 23,
              borderRadius: 8,
              color: !isNight ? "#fff" : "#333",
            },
          },
          {
            itemStyle: {
              color: "none",
            },
            value: Math.round(min),
            name: `Lowest ${subculture} Score`,
            pointer: {
              length: "12%",
              width: 8,
              offsetCenter: [0, "-80"],
              itemStyle: {
                color: "#D06283",
              },
            },
            detail: {
              show: false,
            },
          },
          {
            itemStyle: {
              color: "none",
            },
            value: Math.round(max),
            name: `Highest ${subculture} Score`,
            pointer: {
              length: "12%",
              width: 8,
              offsetCenter: [0, "-80"],
              itemStyle: {
                color: "#04B8AF",
              },
            },
            detail: {
              show: false,
            },
          },
        ],
      },
    ],
  };

  return (
    <div
      ref={PositiveMeterRef}
      style={{
        height: "360px",
        borderRadius: "12px",
        backgroundColor: !isNight ? "#2A2953" : "#fff",
      }}
    >
      <div
        style={{
          position: "relative",
          top: "15px",
          margin: "1rem",
          display: "flex",
          width: "92%",
          justifyContent: "space-between",
        }}
      >
        <p
          style={{
            textAlign: "left",
            fontWeight: 500,
            margin: "0",
            color: !isNight ? "#fff" : "#484848",
          }}
        >
          {`${subculture} Score`}
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                Culture Score for the Selected Cultural Trait Over the Selected Date Range
              </span>
            }
          >
            <InfoIcon
              style={{
                fontSize: "1rem",
                position: "relative",
                top: "3px",
                filter: "opacity(0.7)",
              }}
            />
          </Tooltip>
        </p>
        <Tooltip
          title={
            <span
              style={{
                fontSize: "0.8rem",
                textAlign: "center",
              }}
            >
              Copy to clipboard
            </span>
          }
        >
          {clipProcessing ? (
            <CircularProgress size={16} color="inherit" />
          ) : (
            <ContentCopyOutlinedIcon
              onClick={getImage}
              style={{
                fontSize: "1.2rem",
                zIndex: 3,
                cursor: clipProcessing ? "wait" : "pointer",
              }}
            />
          )}
        </Tooltip>
      </div>
      <div>
        {overallCultureData.status === "success" || (isDemo && !isLoading) ? (
          <>
            <div
              style={{
                position: "relative",
              }}
            >
              <img
                src={ICONS.GuageGradient}
                style={{
                  position: "absolute",
                  left: "0",
                  right: "0",
                  top: "-25px",
                  bottom: "0",
                  margin: "auto",
                  transform: "scaleX(-1)",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
              <ReactECharts option={option} />
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "0 2rem",
                position: "relative",
                top: "-50px",
              }}
            >
              <p
                style={{
                  color: "#727272",
                  fontSize: "0.8rem",
                  textAlign: "center",
                  width: "20%",
                }}
              >
                Score
              </p>
            </div>
          </>
        ) : overallCultureData?.status === "fail" ||
          currentWorkflowID === "" ? (
          <div
            style={{
              position: "relative",
              top: "100px",
            }}
          >
            <ErrorOutlineIcon style={{ color: "#94989f" }} />
            <p
              style={{
                margin: "0",
                color: "#94989f",
              }}
            >
              {currentWorkflowID === ""
                ? "Select a workflow!"
                : "No Data found!"}
            </p>
          </div>
        ) : (
          <Skeleton
            variant="circular"
            width={"75%"}
            height={"250px"}
            style={{
              position: "relative",
              top: "30px",
              margin: "0 auto",
            }}
          />
        )}
      </div>
    </div>
  );
}

export default SubCultureMeter;
