/* eslint-disable import/prefer-default-export */
import api from "./axiosConfig";

export function* loginByLC(action) {
  let resp;
  yield api
    .post("/loginByLC", action.payload)
    .then((response) => {
      resp = response;
      localStorage.setItem("auth-token", action.payload.usertoken);
      localStorage.setItem("userData", JSON.stringify(action.payload));
    })
    .catch((error) => {
      resp = error;
    });
  return resp;
}
