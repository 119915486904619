export const demoActionData = [
    {
        "id": "628229e0b177190014f0adf4",
        "UserId": "U02B6BQU8NT",
        "timeAction": "2022-05-16T10:39:28.336Z",
        "model": "emotion",
        "category": "joy",
        "voilationScore": 41.37931034482759,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0adf6",
        "UserId": "U02B6BQU8NT",
        "timeAction": "2022-05-16T10:39:28.361Z",
        "model": "emotion",
        "category": "sadness",
        "voilationScore": 20.689655172413794,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0adf8",
        "UserId": "U02B6BQU8NT",
        "timeAction": "2022-05-16T10:39:28.370Z",
        "model": "emotion",
        "category": "surprise",
        "voilationScore": 0,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0adfa",
        "UserId": "U02B6BQU8NT",
        "timeAction": "2022-05-16T10:39:28.389Z",
        "model": "emotion",
        "category": "anger",
        "voilationScore": 31.03448275862069,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0adfc",
        "UserId": "U02B6BQU8NT",
        "timeAction": "2022-05-16T10:39:28.398Z",
        "model": "emotion",
        "category": "fear",
        "voilationScore": 6.896551724137931,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0adfe",
        "UserId": "U02BEB9F9T8",
        "timeAction": "2022-05-16T10:39:28.429Z",
        "model": "emotion",
        "category": "joy",
        "voilationScore": 33.333333333333336,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae00",
        "UserId": "U02BEB9F9T8",
        "timeAction": "2022-05-16T10:39:28.457Z",
        "model": "emotion",
        "category": "sadness",
        "voilationScore": 33.333333333333336,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae02",
        "UserId": "U02BEB9F9T8",
        "timeAction": "2022-05-16T10:39:28.481Z",
        "model": "emotion",
        "category": "surprise",
        "voilationScore": 0,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae04",
        "UserId": "U02BEB9F9T8",
        "timeAction": "2022-05-16T10:39:28.490Z",
        "model": "emotion",
        "category": "anger",
        "voilationScore": 33.333333333333336,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae06",
        "UserId": "U02BEB9F9T8",
        "timeAction": "2022-05-16T10:39:28.498Z",
        "model": "emotion",
        "category": "fear",
        "voilationScore": 0,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae08",
        "UserId": "U02BM3U2JQJ",
        "timeAction": "2022-05-16T10:39:28.521Z",
        "model": "emotion",
        "category": "surprise",
        "voilationScore": 0,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae0a",
        "UserId": "U02BM3U2JQJ",
        "timeAction": "2022-05-16T10:39:28.529Z",
        "model": "emotion",
        "category": "anger",
        "voilationScore": 100,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae0c",
        "UserId": "U02BM3U2JQJ",
        "timeAction": "2022-05-16T10:39:28.541Z",
        "model": "emotion",
        "category": "fear",
        "voilationScore": 0,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae0e",
        "UserId": "U02BM66DMC3",
        "timeAction": "2022-05-16T10:39:28.569Z",
        "model": "emotion",
        "category": "joy",
        "voilationScore": 36.8421052631579,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae10",
        "UserId": "U02BM66DMC3",
        "timeAction": "2022-05-16T10:39:28.601Z",
        "model": "emotion",
        "category": "surprise",
        "voilationScore": 2.6315789473684212,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae12",
        "UserId": "U02BM66DMC3",
        "timeAction": "2022-05-16T10:39:28.617Z",
        "model": "emotion",
        "category": "anger",
        "voilationScore": 39.473684210526315,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae15",
        "UserId": "U02BM66DMC3",
        "timeAction": "2022-05-16T10:39:28.632Z",
        "model": "emotion",
        "category": "fear",
        "voilationScore": 7.894736842105263,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae19",
        "UserId": "U02BM9L45L3",
        "timeAction": "2022-05-16T10:39:28.658Z",
        "model": "emotion",
        "category": "joy",
        "voilationScore": 25,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae1b",
        "UserId": "U02BM9L45L3",
        "timeAction": "2022-05-16T10:39:28.689Z",
        "model": "emotion",
        "category": "sadness",
        "voilationScore": 12.5,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae1d",
        "UserId": "U02BM9L45L3",
        "timeAction": "2022-05-16T10:39:28.705Z",
        "model": "emotion",
        "category": "surprise",
        "voilationScore": 0,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae1f",
        "UserId": "U02BM9L45L3",
        "timeAction": "2022-05-16T10:39:28.741Z",
        "model": "emotion",
        "category": "anger",
        "voilationScore": 56.25,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae21",
        "UserId": "U02BM9L45L3",
        "timeAction": "2022-05-16T10:39:28.750Z",
        "model": "emotion",
        "category": "fear",
        "voilationScore": 6.25,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae23",
        "UserId": "U02CB354PK4",
        "timeAction": "2022-05-16T10:39:28.765Z",
        "model": "emotion",
        "category": "joy",
        "voilationScore": 50,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae25",
        "UserId": "U02CB354PK4",
        "timeAction": "2022-05-16T10:39:28.781Z",
        "model": "emotion",
        "category": "surprise",
        "voilationScore": 0,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae27",
        "UserId": "U02CB354PK4",
        "timeAction": "2022-05-16T10:39:28.813Z",
        "model": "emotion",
        "category": "anger",
        "voilationScore": 50,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae29",
        "UserId": "U02CB354PK4",
        "timeAction": "2022-05-16T10:39:28.820Z",
        "model": "emotion",
        "category": "fear",
        "voilationScore": 0,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae2b",
        "UserId": "U02CH7HHZAQ",
        "timeAction": "2022-05-16T10:39:28.845Z",
        "model": "emotion",
        "category": "joy",
        "voilationScore": 100,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae2d",
        "UserId": "U02CH7HHZAQ",
        "timeAction": "2022-05-16T10:39:28.854Z",
        "model": "emotion",
        "category": "surprise",
        "voilationScore": 0,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae2f",
        "UserId": "U02CH7HHZAQ",
        "timeAction": "2022-05-16T10:39:28.862Z",
        "model": "emotion",
        "category": "fear",
        "voilationScore": 0,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae31",
        "UserId": "U02CLAP6J4U",
        "timeAction": "2022-05-16T10:39:28.870Z",
        "model": "emotion",
        "category": "joy",
        "voilationScore": 50,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae33",
        "UserId": "U02CLAP6J4U",
        "timeAction": "2022-05-16T10:39:28.882Z",
        "model": "emotion",
        "category": "surprise",
        "voilationScore": 0,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae35",
        "UserId": "U02CLAP6J4U",
        "timeAction": "2022-05-16T10:39:28.908Z",
        "model": "emotion",
        "category": "anger",
        "voilationScore": 50,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae37",
        "UserId": "U02CLAP6J4U",
        "timeAction": "2022-05-16T10:39:28.916Z",
        "model": "emotion",
        "category": "fear",
        "voilationScore": 0,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae3a",
        "UserId": "U02D8UKK4GJ",
        "timeAction": "2022-05-16T10:39:28.932Z",
        "model": "emotion",
        "category": "joy",
        "voilationScore": 32,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae3c",
        "UserId": "U02D8UKK4GJ",
        "timeAction": "2022-05-16T10:39:28.949Z",
        "model": "emotion",
        "category": "sadness",
        "voilationScore": 16,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae3e",
        "UserId": "U02D8UKK4GJ",
        "timeAction": "2022-05-16T10:39:28.954Z",
        "model": "emotion",
        "category": "surprise",
        "voilationScore": 0,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae40",
        "UserId": "U02D8UKK4GJ",
        "timeAction": "2022-05-16T10:39:28.961Z",
        "model": "emotion",
        "category": "anger",
        "voilationScore": 52,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    },
    {
        "id": "628229e0b177190014f0ae42",
        "UserId": "U02D8UKK4GJ",
        "timeAction": "2022-05-16T10:39:28.979Z",
        "model": "emotion",
        "category": "fear",
        "voilationScore": 0,
        "workflowID": "oBxKDQP0yw",
        "actionType": "Email"
    }
]

export const demoDetailedData = [
    {
        "id": 1,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "ed18920e-19da-4d7f-a88d-a7013ca7a63c",
        "text": "Doing demos is a blast!",
        "sendingTime": "2022-02-28T20:40:04.627Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 2,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "6ed7abd0-bfad-44e4-811c-cba03bb614bd",
        "text": "DEI my foot, I wanna every asian out of here",
        "sendingTime": "2022-02-16T13:29:49.856Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 3,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "84ea59cb-8c26-4f03-981d-26765e4f48b4",
        "text": "what's up buddy, long time",
        "sendingTime": "2021-12-23T05:12:12.000Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 4,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "82f2ba09-8de5-42bb-a49f-1aa8ab1bf434",
        "text": "I am fed up of people here,",
        "sendingTime": "2021-12-22T15:17:44.000Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 5,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "95b9df66-b1a8-45d3-9646-537da2b72a1f",
        "text": "[Mean] Don't think too much, otherwise your knee will get tired.",
        "sendingTime": "2021-10-23T10:05:11.006Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " sadness",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 6,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "7de2a7d8-b474-418e-b02e-c4f6595c1230",
        "text": "[Harassment] You can't be spoon-fed every time.",
        "sendingTime": "2021-10-23T10:02:52.006Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " sadness",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 7,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "8c71543b-ea18-4a57-8e4b-aabbd73eebc1",
        "text": "[Harassment] Management can sack you any moment they want. Always keep that in mind.",
        "sendingTime": "2021-10-23T10:00:29.005Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 8,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "c8d3c15b-8ca4-4e4f-9dd4-6b3c40f87c78",
        "text": "[Harassment] What kind of covid do you have ?, I have recovered from covid in 2 days.",
        "sendingTime": "2021-10-23T09:58:50.004Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 9,
        "UserId": "U02CLAP6J4U",
        "UserName": null,
        "text_id": "A1BFD047-04E5-4EC4-8024-5B26140FF4C3",
        "text": "[Joy] Good job with project! Glad to be a part of this team!",
        "sendingTime": "2021-10-22T16:05:03.014Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 10,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "8e68415d-95ec-4b0f-8e32-ca39558f9e59",
        "text": "[Normal] Does anybody knows Jeoffery Hinton, the father of deep learning, He can't sit, He always works while standing.",
        "sendingTime": "2021-10-22T10:53:20.011Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 11,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "445e9d70-efe6-4e87-b1bc-7afcc4d65e8c",
        "text": "[Sad] I won't be able to drive my car anymore, petrol prices are so high.",
        "sendingTime": "2021-10-22T10:51:51.009Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " sadness",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 12,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "07cd7281-ece6-4b3b-a90f-f1301eab53c9",
        "text": "[Normal] Writing code is much better than writing texts here.",
        "sendingTime": "2021-10-22T10:48:51.007Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 13,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "9ca2a7c1-1ab6-4845-abc6-cfff3388da7c",
        "text": "[Harassment] Bro, You are so dumb that you couldn't figure out difference between different products, go home buddy, you don't belong here.",
        "sendingTime": "2021-10-22T10:47:42.006Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " sadness",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 14,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "87c1a1c8-6e5b-4e0e-9af2-55e381a91f77",
        "text": "[Confused] So many technologies and tools for just one task. which one should I choose?",
        "sendingTime": "2021-10-22T10:46:10.004Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 15,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "b4ba655d-31f8-4e3f-b190-cc0e4f46037b",
        "text": "[Normal] I hope you resolved the bug today.",
        "sendingTime": "2021-10-22T10:45:20.003Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 16,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "1160ab69-fe2d-4e21-90d2-6274e36007af",
        "text": "[frustrated] never try competitive programming, you will end up losing your sleep, peace of mind and addicted. There is no coming back to regular life.",
        "sendingTime": "2021-10-19T04:50:05.002Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 17,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "7a3589ed-ea9f-4905-94a1-efb4ea7b2426",
        "text": "[Frustrated] I am so frustrated working here, everyone behaves like they don't care about the task and by completing they are doing favor on me.",
        "sendingTime": "2021-10-13T05:35:43.010Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 18,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "2fdf1d83-1cc8-4bd0-a7bd-eecaf93cefdd",
        "text": "[Greatful] I am thankful to management and my entire team, despite my poor english they never made me feel bad.",
        "sendingTime": "2021-10-12T09:37:38.009Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " sadness",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 19,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "e5a5f0a7-aff7-4760-9162-e04db34f6536",
        "text": "[Neutral] My favorite comedian is Dave Chapell",
        "sendingTime": "2021-10-12T09:34:29.006Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 20,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "693c5625-90ee-4254-8e38-be9008d146b8",
        "text": "[Happy] Congratulations you all made it possible, we are a great team and we are off to something great.",
        "sendingTime": "2021-10-12T09:32:50.005Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 21,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "16955d15-7bef-47b9-a091-c10ee644b901",
        "text": "[Sad] I don't like the work and environment here but I don't want to change the job.",
        "sendingTime": "2021-10-12T09:32:04.004Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " sadness",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 22,
        "UserId": "U02CLAP6J4U",
        "UserName": null,
        "text_id": "F0B8CC14-C799-42C1-8EDB-07B6C248A802",
        "text": "[Discriminatory] I did not get him on board for the project because he’s too old. I think we should fire him.",
        "sendingTime": "2021-10-12T07:26:06.001Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 23,
        "UserId": "U02D8UKK4GJ",
        "UserName": null,
        "text_id": "181AEEFE-972C-41A8-96AA-4F12E095EE27",
        "text": "[Joy] Good morning everyone!\n\n[Happy] “You can’t use up creativity. The more you use, the more you have.” — Maya Angelou\n\n[Annoyed] Monday morning makes me feel like crap!\n\n[Angry] Dave makes me so angry, I just feel like killing him!\n\n[Annoyed] Can people just learn how to use proper grammar while texting, I mean wtf is “K” just say “Okay”\n\n[Annoyed] Super long texts are just so dumb, I feel like Ross!\n\n[Disappointment] I’m disappointed that you can’t even do your job properly!\n\n[Angry] This is the worst day ever!\n\n[Upset] Josh just pushed me in the elevator and walked away like nothing happened!\n\n[Angry] Can I kill just this stupid ass guy, I told him the same thing like 10 time already!\n\n[Happy] Today is going to be an awesome day!",
        "sendingTime": "2021-10-11T04:46:50.000Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 24,
        "UserId": "U02BM9L45L3",
        "UserName": null,
        "text_id": "35594070-986e-4fbe-9207-7ad54dcc8676",
        "text": "[Joy] Yea Yea, we closed the deal!",
        "sendingTime": "2021-10-08T18:58:26.024Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 25,
        "UserId": "U02BM9L45L3",
        "UserName": null,
        "text_id": "f59d4471-f4f0-44b3-9419-da59a52edd92",
        "text": "[Morale] Stop with all the bitching guys, the decision is final. We have got to produce.",
        "sendingTime": "2021-10-08T18:55:24.024Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 26,
        "UserId": "U02BM9L45L3",
        "UserName": null,
        "text_id": "711fc4b7-1928-4792-9408-347e5de46f1b",
        "text": "[Morale] Great work on the project team! We are nailing schedules!",
        "sendingTime": "2021-10-08T18:51:21.022Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 27,
        "UserId": "U02BM9L45L3",
        "UserName": null,
        "text_id": "22acca3c-c578-42e8-ae16-1c961e623bcf",
        "text": "[Morale] Dude, stock options here will never be worth shit…",
        "sendingTime": "2021-10-08T18:49:49.021Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 28,
        "UserId": "U02BM9L45L3",
        "UserName": null,
        "text_id": "7a7b434f-2576-4e68-bab7-d5d3be42ebe4",
        "text": "[Morale] Did you listen in to the earnings call? WTF is John saying. The turnaround is not working!!!",
        "sendingTime": "2021-10-08T18:42:58.021Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 29,
        "UserId": "U02BM9L45L3",
        "UserName": null,
        "text_id": "facc5cd3-41fa-4d3a-9edf-26bcc6a9e58d",
        "text": "[Abusive] SDK stands for Software Development Kit not So Damn Late. I am tired of engineering always being the group that screws up the product launch.",
        "sendingTime": "2021-10-08T18:39:02.018Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 30,
        "UserId": "U02BM9L45L3",
        "UserName": null,
        "text_id": "1549d521-67d4-46e2-9d95-47a32a127761",
        "text": "[Abusive] You are a waste of human flesh, I am done with working with you",
        "sendingTime": "2021-10-08T18:34:54.016Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 31,
        "UserId": "U02BM9L45L3",
        "UserName": null,
        "text_id": "6d1814bc-e0f8-4911-8278-900f3af8b694",
        "text": "[Abusive] You are driving me nuts, a monkey could write better code",
        "sendingTime": "2021-10-08T18:32:32.015Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 32,
        "UserId": "U02BM9L45L3",
        "UserName": null,
        "text_id": "908cd838-e599-4e56-b8a9-d9f290d24db1",
        "text": "[Profanity] I like the way you negotiated with that supplier today, you really made him our bitch!",
        "sendingTime": "2021-10-08T18:03:08.014Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 33,
        "UserId": "U02BM9L45L3",
        "UserName": null,
        "text_id": "88caecf2-1a7c-4136-87d1-33f2537a7482",
        "text": "[Profanity] That dude in the cafeteria we were sitting next to really smelled like ass…",
        "sendingTime": "2021-10-08T17:59:29.013Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " fear",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 34,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "B088E3E5-124D-42FC-9E50-B26180168C81",
        "text": "[joy sport] Formula one this weekend!!! Go Lando!!",
        "sendingTime": "2021-10-08T17:56:20.011Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 35,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "405D58DB-5F4E-4901-893A-1C6F7DC09BD9",
        "text": "[morale] the new HR program is a real soup sandwich. ",
        "sendingTime": "2021-10-08T17:55:11.009Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 36,
        "UserId": "U02BM9L45L3",
        "UserName": null,
        "text_id": "ab6d6b64-f624-4fc8-9955-692eedae3121",
        "text": "[Profanity] Put in some god damn effort today! Way to fucking lazy…",
        "sendingTime": "2021-10-08T17:55:48.010Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " sadness",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 37,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "A00CFADF-4E0F-48A8-9EAB-CF33378CD7D6",
        "text": "[kudos] The dev team is really kicking ass on this project. Way to go!",
        "sendingTime": "2021-10-08T17:51:04.005Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 38,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "B10AD773-942F-44FC-9DE4-182C01FB5CD8",
        "text": "[morale] the new acquisition is interesting. I wonder if they will run it into the ground like the last one. ",
        "sendingTime": "2021-10-08T17:50:21.004Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " surprise",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 39,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "8A103258-2079-4346-B5E9-940A3175C707",
        "text": "[Abusive] @steve, your such a moron! How did you get this job?",
        "sendingTime": "2021-10-08T17:49:16.003Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 40,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "8063BAE5-8EBD-4FFA-B2B8-64ADE4CA94EC",
        "text": "[Anger, profanity] Fuck this week. It’s been a total shit show. Bring on the weekend!",
        "sendingTime": "2021-10-08T17:48:18.002Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 41,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "7EDF0ADE-8F85-45FA-AB95-5AA9D513E9BE",
        "text": "[Joy] I have the best team on earth! ",
        "sendingTime": "2021-10-08T17:47:28.001Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 42,
        "UserId": "U02D8UKK4GJ",
        "UserName": null,
        "text_id": "8C58356B-36AC-452F-B3AB-2596888ECE9C",
        "text": "[Joy] What you do today can improve all your tomorrow’s!",
        "sendingTime": "2021-10-08T11:35:38.014Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 43,
        "UserId": "U02D8UKK4GJ",
        "UserName": null,
        "text_id": "B6A62E3A-7576-4C30-8B5C-687964A571E3",
        "text": "Am I not good enough for a pay raise, but Jayant is!? This is just offensive :face_with_symbols_on_mouth::face_with_symbols_on_mouth:",
        "sendingTime": "2021-10-07T14:22:39.041Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 44,
        "UserId": "U02D8UKK4GJ",
        "UserName": null,
        "text_id": "25BF9343-FD43-429D-A58C-0E0A615B3CCC",
        "text": "Literally counting the minutes to Friday :tired_face:",
        "sendingTime": "2021-10-07T13:47:17.039Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 45,
        "UserId": "U02D8UKK4GJ",
        "UserName": null,
        "text_id": "E8408B56-D8B4-4CAD-B0AD-37E081F84209",
        "text": "We need to get better security, this company sucks!",
        "sendingTime": "2021-10-07T12:50:11.038Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 46,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "fec3e183-5f4e-4452-a07a-97e724d7b093",
        "text": "I am underpaid here, I deserve more.",
        "sendingTime": "2021-10-07T08:06:53.037Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " sadness",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 47,
        "UserId": "U02D8UKK4GJ",
        "UserName": null,
        "text_id": "97357F3A-A07F-4CB6-A601-35449604B4D1",
        "text": "Why am I working overtime when I'm not even getting paid for it! :woman-facepalming::skin-tone-4:",
        "sendingTime": "2021-10-07T07:50:23.035Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 48,
        "UserId": "U02D8UKK4GJ",
        "UserName": null,
        "text_id": "78BCEF4F-F6F9-4D15-8225-02EA31D49CEE",
        "text": "This sucks, I’m done ",
        "sendingTime": "2021-10-07T07:45:13.034Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " sadness",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 49,
        "UserId": "U02D8UKK4GJ",
        "UserName": null,
        "text_id": "CF89E716-42BF-4A96-B39D-ACD6DFFE23CE",
        "text": "Okay so why does Amy get special treatment at work, it’s so unfair",
        "sendingTime": "2021-10-07T07:34:00.033Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 50,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "47238c5c-7b1f-45be-b22b-f6c5f307c114",
        "text": "python 3.10 is out, the coolest thing is error log is very descriptive and sort of intelligent.",
        "sendingTime": "2021-10-06T05:45:19.030Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 51,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "D75FDEB7-8EAD-46CC-9FFC-06F307D78622",
        "text": "[threat] if Joe asks me to work this weekend, I swear I’ll go get my gun. ",
        "sendingTime": "2021-10-08T17:52:04.006Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 52,
        "UserId": "U02CB354PK4",
        "UserName": null,
        "text_id": "9209bd40-a479-444d-8dfa-de6e652e9a23",
        "text": "[Angry] What a pathetic service. I will not use that service again.",
        "sendingTime": "2021-10-08T14:30:37.000Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 53,
        "UserId": "U02D8UKK4GJ",
        "UserName": null,
        "text_id": "8C89F8DF-C692-4CDE-9424-1B3484B0DCFE",
        "text": "I feel like it’s just a bore fest 24x7!",
        "sendingTime": "2021-10-05T07:01:05.024Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " sadness",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 54,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "09b914de-7d3f-4398-90d1-570889aaa8a2",
        "text": "YOUR MENTAL HEALTH DOES NOT GIVE YOU A FREE PASS TO BE SHITTY TO OTHER PEOPLE AND PEOPLE ARE ALLOWED TO BE DONE WITH YOU BECAUSE OF IT.",
        "sendingTime": "2022-01-27T21:34:59.323Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 55,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "f2a56baf-7e9d-45e4-9fa0-ef7476930c53",
        "text": "I was finally able to get out of bed and take a shower after being too depressed to leave my room for a few days.",
        "sendingTime": "2022-01-27T21:29:39.531Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " sadness",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 56,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "dd7d3874-5ebf-48bc-a418-a16bc50c4f57",
        "text": "I hate the way kids can casually say heartbreaking things",
        "sendingTime": "2022-01-27T21:27:04.308Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " sadness",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 57,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "455e9e15-cdb8-49e6-9442-45e7872a5826",
        "text": "Testing the slack ap integration.",
        "sendingTime": "2022-01-27T21:21:07.360Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " fear",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 58,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "ffbda824-6ba1-4dbd-b215-6d3d775d78c2",
        "text": "I just want to leave",
        "sendingTime": "2022-01-26T15:30:16.861Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 59,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "5eb5df7d-2c1b-4e96-936b-7a202ac2de1d",
        "text": "I'm so tired",
        "sendingTime": "2022-01-26T15:29:46.173Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " sadness",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 60,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "e1840d58-9b7b-4fbc-9906-d6d4002b255a",
        "text": "The \"you're gay?\" One pisses me off. i'm not gay but if someone said to me, I can change your mind; I'd be so annoyed.",
        "sendingTime": "2022-01-26T15:06:01.099Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 61,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "8b18cbbf-d152-4e0a-b926-12ffc37f2378",
        "text": "YouTube Music is Fucking Garbage",
        "sendingTime": "2022-01-26T01:04:25.098Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 62,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "8e71811d-eee6-4e9d-8586-50c2d9f1381d",
        "text": "You know what? Fuck politics!",
        "sendingTime": "2022-01-26T01:04:09.259Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 63,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "dcb1155c-343a-4a5d-ab6a-362a6c8be035",
        "text": "Not every part of this house is yours.",
        "sendingTime": "2022-01-26T01:03:47.594Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 64,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "6c661506-407c-449b-8f92-836a0a36f33e",
        "text": "For the love of Pete stop drinking and driving!",
        "sendingTime": "2022-01-26T01:03:39.324Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 65,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "3b176e1c-fb67-41f4-acc4-a3de484ec52a",
        "text": "Work and live are getting to me.",
        "sendingTime": "2022-01-26T01:01:47.334Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 66,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "7d3ae596-f210-4242-9bf0-c2e83abc18a3",
        "text": "People in general are the worst!",
        "sendingTime": "2022-01-26T01:02:22.476Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " sadness",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 67,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "918c4daa-bbc6-4ab3-bc73-9950cb1ac7ad",
        "text": "I fucking hate people who keep saying “we humans suck” “humans worst species” and all that shit!",
        "sendingTime": "2022-01-26T00:59:26.535Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 68,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "1d505df8-a3dc-44aa-97d4-aafe3d01769a",
        "text": "Adding more messages to fill out our test data… buckle up.",
        "sendingTime": "2022-01-26T00:58:10.151Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " fear",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 69,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "4ecf6216-7411-48ec-a5b9-e8010e177aba",
        "text": "thank you so much!!",
        "sendingTime": "2021-09-30T15:15:53.014Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 70,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "48cb2ec6-217a-4f9e-a1aa-855455cb581c",
        "text": "<@U02CH7HHZAQ> can you please list down all the personal informations that we are able to detect right now.",
        "sendingTime": "2021-09-30T12:47:34.007Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 71,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "89ccacba-b8a8-46d4-8c64-861295406ba6",
        "text": "[I think tagline is long, but it explains our product very well. There is always room for improvement.]",
        "sendingTime": "2021-09-23T04:29:26.005Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 72,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "CB3E755F-43FD-45BE-934D-0341CED89F5C",
        "text": "First I want to thank the team for stepping up and delivering on our goals for today. The demo was well received. That said, we still have more to do. Take a breath, and get ready for a wild ride. ",
        "sendingTime": "2021-09-22T20:00:46.003Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 73,
        "UserId": "U02D8UKK4GJ",
        "UserName": null,
        "text_id": "D92D442D-A08B-4D52-8C4E-9BFAFAACF326",
        "text": "The logo is sooo dumb it’s like they didn’t put any effort and just picked up something from Canva!  :stuck_out_tongue_closed_eyes:",
        "sendingTime": "2021-09-22T15:51:26.001Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " sadness",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 74,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "8dd0155c-373c-4a7d-ac5c-da9ae7c971f8",
        "text": "me?",
        "sendingTime": "2021-09-22T15:37:28.001Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " fear",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 75,
        "UserId": "U02BEB9F9T8",
        "UserName": null,
        "text_id": "0d7e9073-cb35-4eb4-b746-634fcd989a03",
        "text": "You look horrible",
        "sendingTime": "2021-09-22T15:37:06.000Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " sadness",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 76,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "05df33a0-7999-4431-b551-3604a6c27267",
        "text": "Welcome!!",
        "sendingTime": "2021-09-03T06:56:35.003Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 77,
        "UserId": "U02D8UKK4GJ",
        "UserName": null,
        "text_id": "b9589435-822d-4504-8db4-69f58a3a20f4",
        "text": ":wave: Hello, team!",
        "sendingTime": "2021-09-03T06:16:12.003Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 78,
        "UserId": "U02BM3U2JQJ",
        "UserName": null,
        "text_id": "DA219AD2-71FD-4082-92A0-CE4E902A51FE",
        "text": ":+1: ",
        "sendingTime": "2021-09-03T04:31:33.003Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 79,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "d3a3c665-273e-4fef-b870-ba264c61ca71",
        "text": "Anjani and Praveen showed a great demo of the dashboard today. The progress has been great so far. Thank you everyone for your hard work.",
        "sendingTime": "2021-09-02T18:57:28.001Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 80,
        "UserId": "U02BEB9F9T8",
        "UserName": null,
        "text_id": "c3d703f0-5fbb-4374-9738-ea3ad1759462",
        "text": ":wave: Hello, team!",
        "sendingTime": "2021-08-25T16:43:16.000Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 81,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "d546eb88-ae37-41d8-9b2f-176708a11065",
        "text": "Welcome!",
        "sendingTime": "2021-08-19T13:21:13.000Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 82,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "0c4245b2-a2a3-4a93-91b2-91e6252af262",
        "text": "I love seeing how people respond to the walkthrough.",
        "sendingTime": "2022-02-28T20:40:20.664Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 83,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "ae9ad01c-fb34-438b-9530-6dadf1736519",
        "text": "[sexist] I couldn't take her seriously in that presentation. did you see what she was wearing?",
        "sendingTime": "2022-02-21T06:40:23.089Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 84,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "f77b3242-3cff-4dc0-ac9f-e4e2a22ef3e8",
        "text": "[Mean] You are new here, and you are gonna feel new for a very long time, no one is gonna be exactly friendly with you.",
        "sendingTime": "2021-10-23T09:57:25.003Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 85,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "48e917b1-cc7a-46d5-a11e-245c4fd14eb0",
        "text": "[Harassment] You are a fucking handicapped, you deserve to be in streets.",
        "sendingTime": "2021-10-23T09:56:18.002Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " fear",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 86,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "d93e5ddf-ece1-48c1-a2e7-f2b34fc6a6a0",
        "text": "[Discriminating] I don't know why HR shortlisted girl's resume, They can't code.",
        "sendingTime": "2021-10-22T10:50:18.008Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 87,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "0ae94e47-ed0e-4000-9789-2c1016a0c925",
        "text": "[Happy] Hello Justin, It's my last working day today in this company. I wish all the best to you. have fun buddy.",
        "sendingTime": "2021-10-22T10:44:23.002Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 88,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "fc3da8a9-b1b2-454d-81e3-c86ddf449e97",
        "text": "[Sad] Ohh this company sucks, I wish I can get release as soon as possible.",
        "sendingTime": "2021-10-22T10:43:05.001Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " sadness",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 89,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "f0ac2be6-9340-4c6d-a3d2-fc1a4f634e82",
        "text": "[Discriminatory] I am happy that I am not in the South on Dussehra and Diwali",
        "sendingTime": "2021-10-13T13:37:33.012Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 90,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "bab084cc-0057-453a-a3a3-0c32dd8504b6",
        "text": "[Happy] Playing Garba every evening is so refreshing and fun!!",
        "sendingTime": "2021-10-13T13:30:23.011Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 91,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "a94c4405-d790-47ef-a345-1fc0e45c4778",
        "text": "[Neutral] Does anyone else also watch Pogo and Cartoon Network?",
        "sendingTime": "2021-10-12T09:36:00.007Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " fear",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 92,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "398a796c-f865-4fa3-b18b-3ef9f6e342c8",
        "text": "[Mean] Working with Ryan is so stressful, His mind is slower than bullock cart.",
        "sendingTime": "2021-10-12T09:30:05.002Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 93,
        "UserId": "U02BM9L45L3",
        "UserName": null,
        "text_id": "e1e778e3-066c-4bb7-b0e1-2bc81263ef07",
        "text": "[Morale] I think things are turning around here, if we get the new model out for Christmas, sales will be big.",
        "sendingTime": "2021-10-08T18:52:38.023Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 94,
        "UserId": "U02BM9L45L3",
        "UserName": null,
        "text_id": "f3f66018-09c3-4b56-916f-38b7efe51d22",
        "text": "[Profanity] That was a real dumb idea you presented in front of the CEO, Shit for brains…",
        "sendingTime": "2021-10-08T17:57:53.012Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " sadness",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 95,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "88d268c5-c534-4dee-8e46-2afd035c8ca0",
        "text": "[Anger] Here most people don't have sense of responsibility and they handle the job very unprofessionally.",
        "sendingTime": "2021-10-08T07:02:20.013Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 96,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "75b5de76-5b29-49db-9149-f0a77239de1a",
        "text": "[Joy] someone suggested me to read atomic habits, It's life changing, must read for everyone.",
        "sendingTime": "2021-10-08T07:00:29.012Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 97,
        "UserId": "U02D8UKK4GJ",
        "UserName": null,
        "text_id": "0FA68924-AD48-41EB-BA19-A7E8ABC80970",
        "text": "[Hate] Sometime I really wish I could just kill all my colleagues!",
        "sendingTime": "2021-10-08T06:57:18.009Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 98,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "a8bfa8bb-7ef7-44a4-9497-4d9b17d639df",
        "text": "[Hate] She is slut, I hate her so much that I don't even want to look at her face.",
        "sendingTime": "2021-10-08T06:52:00.004Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 99,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "dd61c57e-f1eb-4f4e-80bb-d4b15422533d",
        "text": "[Insult] You come from India, go sit on elephants and dance on snake flutes, white people kept your forefathers as slave for 200 years.",
        "sendingTime": "2021-10-08T06:50:29.003Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 100,
        "UserId": "U02D8UKK4GJ",
        "UserName": null,
        "text_id": "B81411CC-73D2-4EAB-9E26-664A24D1885E",
        "text": "I think Jonah doesn’t deserve the Executives position :face_with_symbols_on_mouth:",
        "sendingTime": "2021-10-07T13:01:51.039Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 101,
        "UserId": "U02D8UKK4GJ",
        "UserName": null,
        "text_id": "8C49D3F7-B8E5-4DF7-9635-6C9D0FE09765",
        "text": "Good to know Friday is not that far away",
        "sendingTime": "2021-10-07T08:05:54.036Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 102,
        "UserId": "U02D8UKK4GJ",
        "UserName": null,
        "text_id": "A1C142C3-033F-4A23-8986-3FA8CB9759CF",
        "text": "It would be great, if everyone just did their work on time.",
        "sendingTime": "2021-10-07T07:55:35.036Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 103,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "d056af56-568f-4d43-8656-97b0a86cebd3",
        "text": "Another cool thing is that we have switch statements now in python :star-struck:",
        "sendingTime": "2021-10-06T05:50:23.031Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 104,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "267e38e0-d12b-4d01-80ef-00b8eaba65ab",
        "text": "[Danger] This place is so jacked. Someone should burn it down!",
        "sendingTime": "2021-10-05T13:36:23.025Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 105,
        "UserId": "U02D8UKK4GJ",
        "UserName": null,
        "text_id": "C198A0BB-B658-4456-8651-8B6F71222CD6",
        "text": "I think it’s time we actually learn something at this job!",
        "sendingTime": "2021-10-05T06:56:09.024Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 106,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "1907ed8d-9489-4dea-8df2-ef53d794781b",
        "text": "Every day is worse than the last",
        "sendingTime": "2022-01-26T15:30:01.345Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " sadness",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 107,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "874281b4-8f53-4757-bc38-2c04800ed6af",
        "text": "If you like socialism so much why don't you go to Venezuela?",
        "sendingTime": "2022-01-26T15:13:08.255Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 108,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "a2671d07-04f1-4928-a18e-fe3e831ad03f",
        "text": "I wrote a play and my drama teaching is trying to completely change it.",
        "sendingTime": "2022-01-26T01:04:49.538Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 109,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "c5bd24d2-5221-481a-8dcc-d449ee56dca6",
        "text": "We know why she was hired...",
        "sendingTime": "2022-02-21T06:36:02.065Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 110,
        "UserId": "U02CH7HHZAQ",
        "UserName": null,
        "text_id": "0c61b053-ce31-46f3-9783-ad1328786afa",
        "text": "Sure I'll do so",
        "sendingTime": "2021-09-30T14:57:33.008Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 111,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "7ff0fead-9285-4232-9331-28bfae0bb363",
        "text": "and please paste output of each along with input example.",
        "sendingTime": "2021-09-30T12:49:30.008Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 112,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "62E1B790-900E-4252-9CC8-9D5F2AE1AFD8",
        "text": "[Joy] I thought the logo was lovely, and very well thought out. ",
        "sendingTime": "2021-09-22T20:01:58.004Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " love",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 113,
        "UserId": "U02BEB9F9T8",
        "UserName": null,
        "text_id": "9abc40e1-6023-41bf-871c-20a8a8985b4c",
        "text": "This is the worst thing ever",
        "sendingTime": "2021-09-22T15:37:24.000Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 114,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "ce66815d-33d1-486d-9e7f-de75fbe96f3b",
        "text": "Thank you everyone, Specially <@U02BM3U2JQJ> and <@U02BM66DMC3> for consistent support and motivating us. All credits from development side goes to Praveen.",
        "sendingTime": "2021-09-03T04:08:19.003Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 115,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "262b6bc9-22b1-40e7-bd2a-62e0f98f199a",
        "text": ":wave: Hello, team!",
        "sendingTime": "2021-08-19T03:45:09.000Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 116,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "69e9eee1-d715-4828-b1c7-170cdbc25fdd",
        "text": "I'm happy with the configuration, and I really like to get the visual indicators in the terminal about my git status, and the overall terminal look and feel resonate well with me.",
        "sendingTime": "2022-01-28T04:27:51.765Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 117,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "778a34b8-f644-434b-bb84-55187a23a2fd",
        "text": "Edit: Hey you fuckers that keep saying I should listen to his podcast and that I’m just not his “target demographic”. Get bent.",
        "sendingTime": "2022-01-27T21:28:17.662Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 118,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "96d93827-40e8-439b-810c-823796e83c76",
        "text": "Channel data was successfully captured.",
        "sendingTime": "2022-01-27T21:21:38.470Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 119,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "3e97a60d-6bdb-4b93-8ccc-ae51892dde0e",
        "text": "Most relationship problems have such obvious solutions.",
        "sendingTime": "2022-01-26T01:03:20.791Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 120,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "dc6ce78a-2f7f-4150-b64c-395fcdd65597",
        "text": "Why is it so hard to just dislike food online?",
        "sendingTime": "2022-01-26T01:02:05.442Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " sadness",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 121,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "59fce2dc-f87d-452d-810c-afb08e1ccf63",
        "text": "If people spent half the amount of time worrying about themselves instead of always worrying about what everybody else is doing, this world would be a much better place!",
        "sendingTime": "2022-01-26T01:01:36.622Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " fear",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 122,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "827a1bc3-3429-47e2-928e-ca0263432cc4",
        "text": "The workers in the smoothie video’s ‘excuse’ to why they added peanut butter confuses and annoys me",
        "sendingTime": "2022-01-26T01:00:42.209Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 123,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "e97f6a84-7235-47af-ab58-6c709f9fb2ec",
        "text": "No, girls are not leading you on. You just don’t know how to read social cues.",
        "sendingTime": "2022-01-26T00:59:48.468Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 124,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "1ce0828b-8186-4aa4-952b-5b0ab3e43cb2",
        "text": "<https://www.reddit.com/r/rant/comments/s8v5b0/is_this_a_stroke_is_this_what_having_a_stroke/|is this a stroke? is this what having a stroke feels like?>",
        "sendingTime": "2022-01-26T00:58:21.369Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 125,
        "UserId": "U02CH7HHZAQ",
        "UserName": null,
        "text_id": "53ed9ec2-5846-49ca-8879-5a8afdd9df2a",
        "text": "1. *Card Details*: My credit card number is 1111 2222 3333, the cvv is 123 and the expiry date is 03/21 (_Card details present. Detected cvv/otp. Detected expiry date._)\n2. *Date of Birth*: DoB is 05/05/2018 (_Found valid DoB_)\n3. *Driving License*: License No. is HR-0619850034761 (_Found valid License No._)\n4. *Email id*: email id is a.cb@y.z (_Found valid email_)\n5. *IP address*: IP is 129.144.50.56 (_Found valid IP_)\n6. *Login Details*:-\n• *Username*: Prakriti_21 (_Username Detected_)\n• *Password*: Prakriti_21 (_Password Detected_)\n• *OTP*: 098767 (_OTP detected_)\n7. *MAC Address*: MAC address is 3D:F2:C9:A6:B3:4F (_Found MAC_)\n8. *Passport Number*: PassPort is A2096457 (_Found valid passport_)\n9. *Phone Number*: PhoneNo is <tel:+919992223333|+91 9992223333> (_Found valid PhoneNo_)\n10. *Social Security Number (SSN)*: My SSN is 856-24-6789 (_Found ssn_)\n11. *Vehicle Identification Number (VIN)*: Vehicle Identification Number is SALVA2AE4EH877322 (_Found Valid Vehicle Identification Number_)\n12. *Zip Code*: Zip Code is 160022 (_Found valid Zip Code_)",
        "sendingTime": "2021-09-30T15:20:02.017Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 126,
        "UserId": "U02CB354PK4",
        "UserName": null,
        "text_id": "5ff521eb-7f36-451a-baca-1592a94c7be2",
        "text": "<@U02BM66DMC3> <@U02B6BQU8NT> Thank you so much",
        "sendingTime": "2021-09-03T09:04:04.004Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 127,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "DAE72B5B-4B63-49DE-A13D-126FD0922E35",
        "text": "We can build channels to produce data for models and example data. Depending on the model, we may have to be offensive. Please understand that it’s for training purposes only. We don’t want anyone to be uncomfortable, so if you would prefer to opt out of some channels, please let us know. ",
        "sendingTime": "2021-08-25T21:12:55.003Z",
        "source": "Slack",
        "channelName": "conversation-analysis",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 128,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "c4cfe782-5e81-464d-987c-f371f1caa3ae",
        "text": "you son of a bitch",
        "sendingTime": "2021-12-22T14:29:40.000Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 129,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "de330ee0-fd8d-4dc6-ab37-edb2eba2ff39",
        "text": "[Mean] Yesterday my TL said Nobody is dying here because of cancer so continue working.",
        "sendingTime": "2021-10-23T09:55:11.001Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " sadness",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 130,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "2f843f82-b488-4fdf-81ad-1c8c7dcb08a3",
        "text": "[Happy] Hey John, do you know dropouts with neural networks is same as random forest for decision trees.",
        "sendingTime": "2021-10-22T10:55:11.012Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 131,
        "UserId": "U02D8UKK4GJ",
        "UserName": null,
        "text_id": "ADE5EBF6-34B8-4239-8255-80C9EE12361E",
        "text": "[Happy] Oh it’s such a lovely day, it's Friday!\n\n[Angry] If only my collegues weren’t such dumbasses, I could actually get my work done!\n\n[Sad] What have I done so wrong in life, this is just not fair.\n\n[Rude] You know the sales team really needs to get their act together or they're all going to get fired soon!\n\n[Discriminating] Can we just get rid of these female employees already! The EQ is too high in the office.\n\n[Harrassment] Why doesn’t Dave wear those tight trousers anymore!? I really liked it when he bent over\n\n[Happy] Today I achieved all my targets, it's definitely been a productive day!\n\n[Happy] This Pizza from the cafeteria is too good, I could eat it all day.\n\n[Sad] Would it matter to anyone if I killed myself today?\n\n[Discriminating] Maternity leave is such a dumb concept, why do they even need it?",
        "sendingTime": "2021-10-22T09:14:45.000Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 132,
        "UserId": "U02D8UKK4GJ",
        "UserName": null,
        "text_id": "3F04C0C9-5683-4997-B06B-A2115352BCF9",
        "text": "[Happy] It’s time to disco!!\n\n[Happy] I’m so excited for Thanksgiving!\n\n[Scam] Mr. Reynolds this is an emergency, your mother Mrs. Reynolds is in the emergency room! Please call this number: <tel:+91 2314567849|+91 2314567849> and get in touch with us immediately!\n\n[Harrassment] It sucks that Gina is wearing a long dress today, I really like those legs of hers!\n\n[Discriminating] These yellow faces really need to go back where they fucking came from!\n\n[Sexist] If only this task was given to man, it would be done much sooner\n\n[Sexist] Urgh! Another employee is taking maternity leave! Can they stay at home, some of us actually work for a living!\n\n[Angry] Why the fuck does Roy get the praise, while I have to do all the dirty work, this is bullshit!\n\n[Sad] I wish today would go without people criticizing my work, just one day.\n\n[Happy] Oh yeahhh!! Only 3 days for the weekend!",
        "sendingTime": "2021-10-19T18:00:00.000Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 133,
        "UserId": "U02D8UKK4GJ",
        "UserName": null,
        "text_id": "9D39491C-BB92-4160-AF15-E248983E3CB3",
        "text": "[Quote] \"Light travels faster than sound. This is why some people appear bright until they speak.\" - Steven Wright.\n\n[Discriminating] Jane is definitely not “Leader material”, I mean she’s a woman!\n\n[Harrassment] Sayeed took a holiday again! I mean seriously how many days off do these Muslims need in a year!\n\n[Harrassment] These garbs look ridiculous, I mean what’s the point in covering a whole face with a black robe?\n\n[Rude] Can people just do what I ask not do what they think! I don’t need these pea-brained people to think!\n\n[Angry] I wish Jayesh would get fired! He simply sucks at his job!\n\n[Phishing] Dear Miss.Rao, your payment is pending for your mAsteR cArD xxxx xxxx xxxx 2345. Please make your payment on this link <http://btw.ysuck.com|btw.ysuck.com>\n\n [Phishing] Warning! Your email account <mailto:shrgdj@gmail.com|shrgdj@gmail.com> has been Hacked! Please click on this link to report it: <http://dfgb.gnog.com|dfgb.gnog.com>\n\n[Happy] I feel like I’m on a cloud, nothing can bring me down today!\n\n[Happy] I’m glad it’s Wednesday, only 2 more working days!",
        "sendingTime": "2021-10-13T05:50:57.010Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 134,
        "UserId": "U02D8UKK4GJ",
        "UserName": null,
        "text_id": "3D651F37-A82E-46F9-8C82-5F03F835E949",
        "text": "[Happy] I made it to work on time, it’s such a good feeling\n\n[Annoyed] This coffee is terrible, I can’t work like this!\n\n[Annoyed] I thought Jane was just stupid, but she’s worse than that and she can’t do anything right!\n\n[Hate] Dave is an asshole, I wish he dies!\n\n[Sarcastic] I can only make one person happy a day, and today is not your day neither is tomorrow.\n\n[Sarcastic] You know Joan feels I’m insulting her, but I’m just stating the facts that her outfit does not suit her and makes her look fat.\n\n[Sarcastic] If only closed minds came with closed mouths!\n\n[Harrassment] Oooooh Dina looks so sexy in that skirt, I love it when she bends over.\n\n[Funny] It’s amazing how clean my house can get when I’m pissed off.\n\n[Rude] When people ask me stupid questions, it’s my legal obligation to give a sarcastic remark.\n\n[Angry] Urghhhh! Can this day just get over already I’m PMSing as is!\n\n[Mean] It’s a good thing Dina has a good body, her skills alone would never be enough",
        "sendingTime": "2021-10-12T04:38:28.000Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 135,
        "UserId": "U02BM9L45L3",
        "UserName": null,
        "text_id": "ea5a6d6b-8932-46d2-a706-cbed989f3940",
        "text": "[Abusive] The sales team is too stupid to sell this product. Covid infested bats would be more successful at selling this stuff to our customers.",
        "sendingTime": "2021-10-08T18:41:16.020Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 136,
        "UserId": "U02BM9L45L3",
        "UserName": null,
        "text_id": "406835f2-d87c-4cd9-87d3-f484da26ce9b",
        "text": "[Abusive] You are off the thread on this because you contribute nothing of value",
        "sendingTime": "2021-10-08T18:37:16.017Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 137,
        "UserId": "U02BM9L45L3",
        "UserName": null,
        "text_id": "06d415db-6848-41e0-b0a5-806f7244e59d",
        "text": "[Profanity] We nailed that presentation today. Fuck Yes We Did!!!",
        "sendingTime": "2021-10-08T17:56:39.011Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 138,
        "UserId": "U02BM66DMC3",
        "UserName": null,
        "text_id": "BAF5BE33-D27A-407B-B16C-22297B2F122F",
        "text": "[social personal] Who’s up for a happy hour after? We can meet at that new western bar, Gun smoke. I hear they do a mean margarita. ",
        "sendingTime": "2021-10-08T17:54:14.008Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 139,
        "UserId": "U02D8UKK4GJ",
        "UserName": null,
        "text_id": "B9718591-189E-4155-AF14-B6AF6CC7C397",
        "text": "[Mean] Your knowledge is so limited that only your common sense is found in a lesser quantity in this world.\n\n[Condescending] You are obviously a throw back to the Neanderthal period and can't cope with the modern world. \n\n[Angry] This behavior is not tolerable at all. I wish I could do something about it. I’m really very angry!\n\n[Sadistic] I enjoy seeing people hurt.\n\n[Sadistic] I have fantasies which involve hurting the people around me.\n\n[Harassment] I love it so much when you do bend over Janet\n\n[Harassment] Hey Diane, I wish we were lying next to each other… naked.",
        "sendingTime": "2021-10-08T08:08:39.013Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 140,
        "UserId": "U02D8UKK4GJ",
        "UserName": null,
        "text_id": "52A06B25-4CA0-4F9B-B574-1F05D57B34CA",
        "text": "[Angry] Urghh this is just such a stupid job!\n\n[Sad] I really don’t feel like living anymore\n\n[Happy] Oh My God! It’s finally Friday!!\n\n[Hate] Fuck this life, I’m done!\n\n[Sarcastic] Who was even listening to the boss during the team call? He thinks people actually give a fuck!\n\n[Angry] What kind of ca-ca is that? Doesn't anyone read this stuff before it goes into print? \n\n[Discriminating] This company needs to make better choices when it comes to hiring people, why are there so many people in goddamn wheelchairs!\n\n[Sexist] If Dina thinks her skirts can help her get the job, so be it. It’s mine anyway, bit I’ll just enjoy the show!\n\n[Racist] The niggers have got to start showing some respect, they always seem to forget their place!\n\n[Sexist] Women have a serious attitude problem, that’s why they should just stay at home!",
        "sendingTime": "2021-10-08T07:35:44.013Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " joy",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 141,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "69eb2ccd-47e5-4dc0-9419-9b02e4433f66",
        "text": "[Normal] Never join a development team, which is lead by non-engineering person.",
        "sendingTime": "2021-10-08T06:58:16.010Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " fear",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 142,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "afb8ddc8-3493-4ed2-a7ee-258f8201589e",
        "text": "[Sad] It's so depressing how our management treat me, they don't care how hard I worked and delivered, they don't give a damn about me. They don't even recognize my contribution to the team and to this company. I want to stay but they don't deserve me.",
        "sendingTime": "2021-10-08T06:55:54.008Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " sadness",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 143,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "10b404d6-444c-40ea-abc6-892bf490de9a",
        "text": "[Hate] You are such a piece of shit, go fuck yourself.",
        "sendingTime": "2021-10-08T06:48:39.001Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 144,
        "UserId": "U02D8UKK4GJ",
        "UserName": null,
        "text_id": "004545C6-4F30-411C-9094-DDD2481AE9BB",
        "text": "Even after working here for 12 years I still have to beg for a raise :woman-shrugging::skin-tone-4:",
        "sendingTime": "2021-10-07T07:40:03.034Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 145,
        "UserId": "U02D8UKK4GJ",
        "UserName": null,
        "text_id": "7BCBEB98-A59B-44D4-9DBE-E703CA8A6823",
        "text": "You know it’s so sad no one cares about other people anymore. As long as it’s not about them, they just don’t give a damn!",
        "sendingTime": "2021-10-07T05:18:57.032Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " sadness",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 146,
        "UserId": "U02B6BQU8NT",
        "UserName": null,
        "text_id": "cc32f817-2a48-4aac-8d30-baa02bde4781",
        "text": "Ohh, I forgot to send nginx configurations here it is: server{listen 80; location/ { root      /usr/nginx/share/html;             index    index.html, index.htm     uri_files         $uri   $uri/  /index.html;} this is very basic one, please add more things as per your application...",
        "sendingTime": "2021-10-06T05:35:40.028Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    },
    {
        "id": 147,
        "UserId": "U02D8UKK4GJ",
        "UserName": null,
        "text_id": "4F08BF06-BAC4-4D60-950A-516029D1F978",
        "text": "I think I’m getting too fat these days, I mean I can feel people staring at me all the time :cold_sweat:",
        "sendingTime": "2021-10-05T06:45:14.023Z",
        "source": "Slack",
        "channelName": "product_test",
        "PII": "-",
        "profanity_detection": "-",
        "script_detection": "-",
        "insult_detection": "-",
        "hate_speech": "-",
        "emotion_detection": " anger",
        "sentiment": "",
        "sexualHarassment": "-"
    }
]