/* eslint-disable import/prefer-default-export */
import { toast } from "react-toastify";
import api from "../axiosConfig";
import { SessionOut } from "../../utilities/dashboardUtils";

export function* getPiAndPhishingData(action) {
  let resp;
  const data = action.payload;
  delete data.model;
  yield api
    .post("/getModelTrends", data, {
      timeout: 500000,
      headers: {
        "Access-Control-Allow-Headers": "x-access-token",
        "x-access-token": localStorage.getItem("auth-token"),
      },
    })
    .then((response) => {
      if (response.status === 404) {
        toast.error("Data not found");
      } else if (response.status === 400) {
        SessionOut();
      } else {
        const piXAxis = [];
        const phishingXAxis = [];
        response.data?.timeSlotsPII.forEach((date) => {
          piXAxis.push(date.start.split("T")[0]);
        });

        response.data?.timeSlotsPhising.forEach((date) => {
          phishingXAxis.push(date.start.split("T")[0]);
        });

        resp = {
          piModels: {
            xAxisData: piXAxis,
            yAxisData: response.data?.slotCountsPII,
          },
          phishingModel: {
            xAxisData: phishingXAxis,
            yAxisData: response.data?.slotsCountPhising,
          },
        };
      }
    })
    .catch(() => {
      // toast.error("Data not found");
    });
  return resp;
}
