import React, { useState, useEffect } from "react";
import { ICONS } from "../../../../AppConfig";
import Skeleton from "@mui/material/Skeleton";
import { useDispatch, useSelector } from "react-redux";
import { Tooltip } from "@material-ui/core";
import Modal from "@mui/material/Modal";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddTopic from "../KeyTopics/popup/AddTopic";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteTopic from "./popup/DeleteTopic";
function GeneralTopics({ setCards }) {
  const dispatch = useDispatch();
  const filters = useSelector((data) => data.dashboard.filters);
  const dateFilterSentiment = useSelector(
    (data) => data.dashboard.dateFilterSentiment
  );
  const trendingTopicsData = useSelector(
    (data) => data.dashboard.trendingTopicsData
  );
  const politicalWords = useSelector((data) => data.dashboard.politicalWords);
  const addedTopics = useSelector((data) => data.dashboard.addedTopics);

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const [isLoading, setIsLoading] = useState(true);
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState("");
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const handleTopicSelect = (topic) => {
    if (topic === selectedTopic) {
      setSelectedTopic("");
      dispatch({
        type: "SET_FILTERS",
        payload: {
          ...filters,
          engagementTopic: [""],
          generalTopic: [""],
          trendingTopic: [""],
        },
      });
    } else {
      setSelectedTopic(topic);
      dispatch({
        type: "SET_FILTERS",
        payload: {
          ...filters,
          engagementTopic: [""],
          generalTopic: [topic],
          trendingTopic: [""],
        },
      });
    }
  };

  const keyTopicData =
    JSON.parse(localStorage.getItem("keyTopicData")) !== null
      ? JSON.parse(localStorage.getItem("keyTopicData"))
      : [];

  const handleSearchTextChange = (value) => {
    const tempData = keyTopicData;
    tempData.push({ id: keyTopicData.length + 1, text: value });
    setCards(_.uniqBy(tempData, "text"));
    localStorage.setItem(
      "keyTopicData",
      JSON.stringify(_.uniqBy(tempData, "text"))
    );
    // setSelected(value);
    dispatch({
      type: "SET_FILTERS",
      payload: {
        ...filters,
        engagementTopic: [""],
        generalTopic: [value],
        trendingTopic: [""],
      },
    });
  };

  useEffect(() => {
    if (selectedTopic !== "") {
    }
  }, [selectedTopic]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const [numOfTopics, setNumOfTopics] = React.useState(10);

  // const []

  const handleChange = (event) => {
    const num = event.target.value;
    const res =
      num === "10"
        ? 10
        : num === "20"
        ? 20
        : isDemo
        ? Object.keys(addedTopics.topics).length
        : Object.keys(trendingTopicsData).length;
    setNumOfTopics(res);
  };

  const handleDelete = () => {};
  const [configModalOpen, setConfigModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  return (
    <div>
      <div
        style={{
          margin: " 0 0.5rem 1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex" }}>
          <p
            style={{
              fontSize: "1rem",
              fontWeight: 500,
              textAlign: "left",
              color: !isNight ? "#aebcc9" : "#484848",
            }}
          >
            Added Topics
          </p>
          <Tooltip title={"Add new Topics"}>
            <AddCircleOutlineIcon
              onClick={() => {
                setConfigModalOpen(true);
              }}
              style={{ margin: "1rem 0.5rem", cursor: "pointer" }}
            />
          </Tooltip>
        </div>
        <select
          style={
            !isNight
              ? {
                  height: "40px",
                  border: "1px solid #aebcc9",
                  backgroundColor: "#212047",
                  color: "#aebcc9",
                  position: "relative",
                  top: "10px",
                }
              : { height: "40px", position: "relative", top: "10px" }
          }
          name="Workflow"
          className="dropdown"
          onChange={(value) => {
            handleChange(value);
          }}
        >
          <option key={-1} value={"10"}>
            Top 10
          </option>
          <option key={1} value={"20"}>
            Top 20
          </option>
          <option key={2} value={"all"}>
            All
          </option>
        </select>
      </div>
      {isDemo ? (
        <div>
          {(addedTopics && !_.isEmpty(addedTopics.topics)) ||
          (isDemo && !isLoading) ? (
            <div
              style={{
                overflowY: isDemo ? "" : "scroll",
                maxHeight: "850px",
                overflowX: "hidden",
              }}
            >
              <div>
                <div key={1}>
                  <div
                    className="trending-topic-item"
                    onClick={() => {
                      handleTopicSelect("");
                    }}
                    style={
                      filters.generalTopic.length === 0
                        ? {
                            backgroundColor: "#D06283",
                            cursor: "pointer",
                            borderRadius: "5px",
                          }
                        : { cursor: "pointer", borderRadius: "5px" }
                    }
                  >
                    <div
                      style={{
                        margin: "0 1rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <p
                          style={{
                            fontSize: "14px",
                            textAlign: "left",
                            margin: "10px",
                            color:
                              filters.generalTopic.length === 0 && !isNight
                                ? "#fff"
                                : filters.generalTopic.length === 0 && isNight
                                ? "#fff"
                                : !isNight
                                ? "#aebcc9"
                                : "#484848",
                          }}
                        >
                          {"All"}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {Object.keys(addedTopics.topics)
                  .sort(function (a, b) {
                    return addedTopics.topics[a] - addedTopics.topics[b];
                  })
                  .reverse()
                  .slice(0, numOfTopics)
                  .map((topic, id) => (
                    <div key={id + 2}>
                      <div
                        className="trending-topic-item"
                        style={
                          topic === filters.generalTopic[0]
                            ? {
                                backgroundColor: "#D06283",
                                cursor: "pointer",
                                borderRadius: "5px",
                              }
                            : { cursor: "pointer", borderRadius: "5px" }
                        }
                      >
                        <div
                          style={{
                            margin: "0 1rem",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <p
                              onClick={() => {
                                handleTopicSelect(topic);
                              }}
                              style={{
                                fontSize: "14px",
                                textAlign: "left",
                                margin: "10px",
                                textTransform: "capitalize",
                                color:
                                  topic === filters.generalTopic[0] && !isNight
                                    ? "#fff"
                                    : topic === filters.generalTopic[0] &&
                                      isNight
                                    ? "#fff"
                                    : !isNight
                                    ? "#aebcc9"
                                    : "#484848",
                              }}
                            >
                              {`${capitalizeFirstLetter(topic)} ${
                                addedTopics.topics[topic] > 0
                                  ? " - " + addedTopics.topics[topic]
                                  : ""
                              }`}
                            </p>
                          </div>
                          <div>
                            <DeleteIcon
                              onClick={() => {
                                setDeleteModalOpen(true);
                              }}
                              style={{ color: "#999", fontSize: "1.1rem" }}
                            />
                            <Tooltip title={"Pin to Keytopics"}>
                              <img
                                src={ICONS.PIN}
                                alt="prev"
                                onClick={() => {
                                  handleSearchTextChange(topic);
                                }}
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  opacity: "0.4",
                                  cursor: "pointer",
                                  filter:
                                    topic === filters.generalTopic[0] &&
                                    !isNight
                                      ? "invert(1)"
                                      : topic === filters.generalTopic[0] &&
                                        isNight
                                      ? "invert(1)"
                                      : !isNight
                                      ? "invert(1)"
                                      : "invert(0)",
                                }}
                              />
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <div
              style={{
                // height: isDemo ? "770px" : "380px",
                overflowY: "scroll",
              }}
            >
              {[0, 0, 0, 0, 0, 0].map((item, id) => (
                <div
                  key={id + 1}
                  className="trending-topic-item"
                  style={{ margin: "1rem" }}
                >
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"50px"}
                    style={{
                      position: "relative",
                      top: "0px",
                      margin: "0 auto",
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div>
          {(trendingTopicsData && !_.isEmpty(trendingTopicsData)) ||
          (isDemo && !isLoading) ? (
            <div
              style={{
                overflowY: isDemo ? "" : "scroll",
                maxHeight: "850px",
                overflowX: "hidden",
              }}
            >
              <div>
                <div key={0}>
                  <div
                    className="trending-topic-item"
                    onClick={() => {
                      handleTopicSelect("");
                    }}
                    style={
                      filters.generalTopic.length === 0
                        ? {
                            backgroundColor: "#D06283",
                            cursor: "pointer",
                            borderRadius: "5px",
                          }
                        : { cursor: "pointer", borderRadius: "5px" }
                    }
                  >
                    <div
                      style={{
                        margin: "0 1rem",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div>
                        <p
                          style={{
                            fontSize: "14px",
                            textAlign: "left",
                            margin: "10px",
                            color:
                              filters.generalTopic.length === 0 && !isNight
                                ? "#fff"
                                : filters.generalTopic.length === 0 && isNight
                                ? "#fff"
                                : !isNight
                                ? "#aebcc9"
                                : "#484848",
                          }}
                        >
                          {"All"}
                        </p>
                      </div>
                      <div></div>
                    </div>
                  </div>
                </div>
                {Object.keys(trendingTopicsData)
                  .sort(function (a, b) {
                    return trendingTopicsData[a] - trendingTopicsData[b];
                  })
                  .reverse()
                  .slice(0, numOfTopics)
                  .map((topic, id) => (
                    <div key={id + 1}>
                      <div
                        className="trending-topic-item"
                        onClick={() => {
                          handleTopicSelect(topic);
                        }}
                        style={
                          topic === filters.generalTopic[0]
                            ? {
                                backgroundColor: "#D06283",
                                cursor: "pointer",
                                borderRadius: "5px",
                              }
                            : { cursor: "pointer", borderRadius: "5px" }
                        }
                      >
                        <div
                          style={{
                            margin: "0 1rem",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <div>
                            <p
                              style={{
                                fontSize: "14px",
                                textAlign: "left",
                                margin: "10px",
                                textTransform: "capitalize",
                                color:
                                  topic === filters.generalTopic[0] && !isNight
                                    ? "#fff"
                                    : topic === filters.generalTopic[0] &&
                                      isNight
                                    ? "#fff"
                                    : !isNight
                                    ? "#aebcc9"
                                    : "#484848",
                              }}
                            >
                              {capitalizeFirstLetter(topic)} -{" "}
                              {trendingTopicsData[topic]}
                            </p>
                          </div>
                          <div>
                            <Tooltip title={"Pin to Keytopics"}>
                              <img
                                src={ICONS.PIN}
                                alt="prev"
                                onClick={() => {
                                  handleSearchTextChange(topic);
                                }}
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  cursor: "pointer",
                                  opacity: "0.4",
                                  filter:
                                    topic === filters.generalTopic[0] &&
                                    !isNight
                                      ? "invert(1)"
                                      : topic === filters.generalTopic[0] &&
                                        isNight
                                      ? "invert(1)"
                                      : !isNight
                                      ? "invert(1)"
                                      : "invert(0)",
                                }}
                              />
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            <div
              style={{
                // height: isDemo ? "770px" : "380px",
                overflowY: "scroll",
              }}
            >
              {[0, 0, 0, 0, 0, 0].map((item, id) => (
                <div
                  key={id + 1}
                  className="trending-topic-item"
                  style={{ margin: "1rem" }}
                >
                  <Skeleton
                    variant="rectangular"
                    width={"100%"}
                    height={"50px"}
                    style={{
                      position: "relative",
                      top: "0px",
                      margin: "0 auto",
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <Modal
        open={configModalOpen}
        onClose={() => {
          setConfigModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <AddTopic
          handleClose={() => {
            setConfigModalOpen(false);
          }}
        />
      </Modal>
      <Modal
        open={deleteModalOpen}
        onClose={() => {
          setDeleteModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DeleteTopic
          handleClose={() => {
            setDeleteModalOpen(false);
          }}
        />
      </Modal>
    </div>
  );
}

export default GeneralTopics;
