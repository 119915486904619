import React, { useState } from "react";
import { Box, Tooltip, Typography } from "@material-ui/core";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import {
  GET_ALL_SURVEYS,
  UPDATE_SURVEY,
} from "../../../../../redux/Dashboard/Dashboard.types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function ReminderModal({ survey, handleClose }) {
  const dispatch = useDispatch();
  const [customMsg, setCustomMsg] = useState(false);
  const [msg, setMsg] = useState("");
  const rename = () => {
    // dispatch({
    //   type: UPDATE_SURVEY,
    //   payload: {
    //     surveyID: survey._id,
    //     surveyName: newName,
    //   },
    // });
    // setTimeout(() => {
    //   dispatch({
    //     type: GET_ALL_SURVEYS,
    //   });
    //   handleClose();
    // }, 2000);
  };

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Reminder
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />
      <Typography
        style={{ background: "#e5e5e5", padding: "1rem", margin: "1rem 0" }}
      >
        Subject: Quick Reminder: Complete Our Survey
        <br /> <br /> Hi [Recipient's Name],
        <br /> Just a friendly reminder to complete our survey on [topic]. Your
        input matters! Click here to fill it out:
        <br />
        [Survey Link]. <br />
        Thanks!
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            onChange={(e) => {
              setCustomMsg(e.target.checked);
            }}
          />
        }
        label="Custom Message"
      />

      {customMsg && (
        <Box
          sx={{
            margin: "1rem 0 2rem",
          }}
        >
          <Input
            fullWidth
            type="textarea"
            placeholder="Enter custom message"
            value={msg}
            onChange={(e) => {
              setMsg(e.target.value);
            }}
          />
        </Box>
      )}
      <Stack direction="row" spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={rename}
          style={{ background: "#D06283", color: "#fff" }}
          variant="contained"
        >
          Send
        </Button>
      </Stack>
    </Box>
  );
}

export default ReminderModal;
