import React, { useEffect, useState } from "react";
import "../../../styles/loginPage.css";
import "../../../styles/profilePage.css";
import { useSelector } from "react-redux";
import "../../../styles/setting.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import RedeemIcon from "@mui/icons-material/Redeem";
import Grid from "@mui/material/Grid";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

function CompanyProfile() {
  const licensePlans = useSelector((data) => data.dashboard.licensePlans);
  const userDetails = useSelector((data) => data.dashboard.userDetails);

  const [phoneNumber, setPhoneNumber] = useState("+91 9999999999");
  const [address] = useState("New Delhi, 111000");
  const [companyName] = useState("Scanta");
  const [licenseStatus] = useState("Active");

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function getFormattedDate(date) {
    let year = new Date(date).getFullYear();
    let month = (1 + new Date(date).getMonth()).toString().padStart(2, "0");
    let day = new Date(date).getDate().toString().padStart(2, "0");

    return month + "/" + day + "/" + year;
  }
  return (
    <div className="CompanyProfile">
      <Grid
        container
        style={{
          width: "100%",
          justifyContent: "center",
          margin: "2rem auto",
        }}
      >
        {licensePlans?.data?.data?.map((item) => (
          <Grid item xl={6}>
            <div
              style={{
                width: "220px",
                height: "360px",
                border: "none",
                borderRadius: "8px",
                margin: "1rem",
                background: "rgb(33, 32, 71)",
                boxShadow: "rgba(0, 0, 0, 0.05) 0px 3.86842px 23.2105px",
                position: "relative",
              }}
            >
              {userDetails?.userLicense?.licenseId?.id === item?.id && (
                <div class="ribbon ribbon-top-left">
                  <span>Active</span>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    borderRadius: "8px 8px 0 0",
                    background:
                      item.type === "trial"
                        ? "linear-gradient(90deg, rgba(147,157,187,1) 0%, rgba(86,63,138,1) 68%, rgba(99,21,189,1) 100%)"
                        : "linear-gradient(90deg, rgba(228,68,101,1) 0%, rgba(255,132,78,1) 55%, rgba(246,183,82,1) 100%)",
                  }}
                >
                  <p
                    style={{
                      color: "#fff",
                      fontSize: "18px",
                      fontWeight: "bolder",
                      margin: "1rem auto",
                      textAlign: "center",
                    }}
                  >
                    {item.name}
                  </p>
                </div>
                <RedeemIcon
                  style={{
                    color: "#fff",
                    margin: "1rem auto 0rem",
                    fontSize: "60px",
                  }}
                />

                <p
                  style={{
                    color: "#fff",
                    fontSize: "14px",
                    margin: "0.5rem auto",
                    textAlign: "center",
                  }}
                >
                  {capitalizeFirstLetter(item.type)}
                </p>
                {userDetails?.userLicense?.licenseId?.id === item?.id ? (
                  <>
                    <p
                      style={{
                        color: "#fff",
                        fontSize: "14px",
                        margin: "0.5rem auto",
                        textAlign: "center",
                      }}
                    >
                      Start Date:{" "}
                      {getFormattedDate(userDetails?.userLicense?.createdAt)}
                    </p>
                    <p
                      style={{
                        color: "#fff",
                        fontSize: "14px",
                        margin: "0.5rem auto",
                        textAlign: "center",
                      }}
                    >
                      End Date:{" "}
                      {getFormattedDate(
                        userDetails?.userLicense?.expirationTime
                      )}
                    </p>
                  </>
                ) : (
                  <p
                    style={{
                      color: "#fff",
                      fontSize: "14px",
                      margin: "0 auto 0",
                      textAlign: "center",
                    }}
                  >
                    <AccessTimeIcon
                      style={{
                        color: "#fff",
                        margin: "0.2rem 0.5rem 0.5rem",
                        fontSize: "16px",
                        position: "relative",
                        top: "10px",
                      }}
                    />
                    {`${item.duration} ${item.durationType}`}
                  </p>
                )}
                <p
                  style={{
                    color: "#fff",
                    fontSize: "12px",
                    margin: "0.5rem auto 0",
                    textAlign: "center",
                  }}
                >
                  {item.type === "trial"
                    ? "upload an archive up to 20Mb"
                    : "upload unlimited archive"}
                </p>
                <p
                  style={{
                    color: "#fff",
                    fontSize: "12px",
                    margin: "0.5rem auto 0",
                    textAlign: "center",
                  }}
                >
                  {item.type === "trial"
                    ? "read-only access to dashboard"
                    : "read-write access to dashboard"}
                </p>
                <p
                  style={{
                    color: "#fff",
                    fontSize: "12px",
                    margin: "0.5rem auto ",
                    textAlign: "center",
                  }}
                >
                  {item.type === "trial"
                    ? "One account per company"
                    : "Users can create multiple accounts"}
                </p>
                <button
                  style={{
                    color: "#fff",
                    margin: "1rem auto 2rem",
                    borderRadius: "12px",
                    background: "#3629b7",
                    padding: "0.5rem 1rem",
                    border: "none",
                    cursor: "pointer",
                    visibility: item.type !== "trial" ? "visible" : "hidden",
                  }}
                >
                  Start
                </button>
              </div>
            </div>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
export default CompanyProfile;
