/* eslint-disable import/no-named-as-default-member */
import "./App.css";
import React, { useState, useCallback, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { Route, HashRouter as Router, Switch } from "react-router-dom";
import { BrowserRouter } from "react-router-dom";
import axios from "axios";
import SignUpPage from "./Pages/SignUp";
import LoginPage from "./Pages/LoginPage";
import Dashboard from "./Pages/Dashboard";
import Survey from "./Pages/Survey";
import SuccessfulRegistration from "./Pages/SuccessfulRegistration";
import ForgetPasswordPage from "./Pages/ForgetPasswordPage";
import TandCPage from "./Pages/TandCPage";
import DemoLoginPage from "./Pages/DemoLoginPage";
import VerifyOTPPage from "./Pages/VerifyOTPPage";
import SetPasswordPage from "./Pages/SetPasswordPage";
import "react-toastify/dist/ReactToastify.css";
import "react-flow-renderer/dist/style.css";
import { AuthContext } from "./Context/UserContext";

let logoutTimer;

const App = () => {
  const [token, setToken] = useState(localStorage.getItem("auth-token"));
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [userId, setUserId] = useState(false);
  const storedData = JSON.parse(localStorage.getItem("userData"));

  const login = useCallback((uid, usertoken, expirationDate) => {
    setToken(usertoken);
    setUserId(uid);
    const usertokenExpirationDate =
      expirationDate || new Date(new Date().getTime() + 12 * 1000 * 60 * 60);
    setTokenExpirationDate(usertokenExpirationDate);
    localStorage.setItem(
      "userData",
      JSON.stringify({
        userId: uid,
        usertoken,
        expiration: usertokenExpirationDate.toISOString(),
      })
    );
    localStorage.setItem(
      "keyTopicData",
      JSON.stringify([
        {
          id: 1,
          text: "All",
        },
        {
          id: 2,
          text: "Bonus",
        },
        {
          id: 3,
          text: "Promotions",
        },
        {
          id: 4,
          text: "WFH",
        },
      ])
    );
    axios.defaults.headers.common["x-access-token"] = token;
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem("userData");
    localStorage.removeItem("auth-token");
    localStorage.removeItem("userDetails");
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);
    const usertoken = null;
    axios.defaults.headers.common["x-access-token"] = usertoken;
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime =
        tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(
        storedData.userId,
        storedData.token,
        new Date(storedData.expiration)
      );
    }
  }, [login]);

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token,
        userId,
        login,
        logout,
      }}
    >
      <div className="App">
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <BrowserRouter>
          {/* <Router> */}
          <Switch>
            <Route exact path="/" component={LoginPage} />
            <Route path="/signup" component={SignUpPage} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/survey" component={Survey} />
            <Route
              exact
              path="/successful-registration"
              component={SuccessfulRegistration}
            />
            <Route exact path="/verify-otp" component={VerifyOTPPage} />
            <Route
              exact
              path="/forget-password"
              component={ForgetPasswordPage}
            />
            <Route
              path="/forget-password/:userId/:tokenId"
              component={SetPasswordPage}
            />
            <Route exact path="/demo-login" component={DemoLoginPage} />
            <Route exact path="/terms-and-conditions" component={TandCPage} />
          </Switch>
          {/* </Router> */}
        </BrowserRouter>
      </div>
    </AuthContext.Provider>
  );
};

export default App;
