import React, { useState } from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import Modal from "@material-ui/core/Modal";
import { Tooltip } from "@material-ui/core";
import ModelConfigModal from "../../Configuration/ModelConfigModal";
import { ICONS } from "../../../../../AppConfig";

export default function Source() {
  const onDragStart = (event, nodeType, messenger, tabType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.setData("application/messenger", messenger);
    event.dataTransfer.setData("application/tabType", tabType);
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.effectAllowed = "move";
  };

  const [open, setOpen] = useState(false);
  const [selectedModel, setSelectedModel] = useState({
    title: null,
  });

  const handleOpen = (e) => {
    setSelectedModel({ title: e });
    if (selectedModel.title !== null) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <aside>
        <div
          className="dndnode dnd-model"
          onDragStart={(event) =>
            onDragStart(event, "default", "Emotional-INTELLECTUAL", "model")
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "#666666" }}
                onClick={() => handleOpen("Emotion Detection")}
              />
            </Tooltip>
            <img src={ICONS.INTELLECTUAL} alt="zoom"   />
            <p style={{  color:"#666666"}}>Emotional</p>
          </div>
        </div>
        <div
          className="dndnode  dnd-model"
          onDragStart={(event) =>
            onDragStart(
              event,
              "default",
              "Personal Information-INTELLECTUAL",
              "model"
            )
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "#666666" }}
                onClick={() => handleOpen("Personal Information")}
              />
            </Tooltip>
            <img src={ICONS.INTELLECTUAL} alt="zoom" />
            <p style={{  color:"#666666"}}>Personal Information</p>
          </div>
        </div>
        {/* <div
          className="dndnode  dnd-model"                    
          onDragStart={(event) =>
            onDragStart(event, "default", "Text Analysis-PLL", "model")
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "#666666" }}
                onClick={() => handleOpen("Text Analysis")}
              />
            </Tooltip>
            <img src={ICONS.PLL} alt="zoom" />
            <p style={{  color:"#666666"}}>Text Analysis</p>
          </div>
        </div> */}
        <div
          className="dndnode  dnd-model"                  
            
          onDragStart={(event) =>
            onDragStart(event, "default", "Profanity-INTELLECTUAL", "model")
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "#666666" }}
                onClick={() => handleOpen("Profanity")}
              />
            </Tooltip>
            <img src={ICONS.INTELLECTUAL} alt="zoom" />
            <p style={{  color:"#666666"}}>Profanity</p>
          </div>
        </div>
        <div
          className="dndnode  dnd-model"        

          onDragStart={(event) =>
            onDragStart(event, "default", "Hate Speech-INTELLECTUAL", "model")
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "#666666" }}
                onClick={() => handleOpen("Hate Speech")}
              />
            </Tooltip>
            <img src={ICONS.INTELLECTUAL} alt="zoom" />
            <p style={{  color:"#666666"}}>Hate Speech</p>
          </div>
        </div>
        {/* <div
          className="dndnode  dnd-model"              

          onDragStart={(event) =>
            onDragStart(event, "default", "Script-INTELLECTUAL", "model")
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "#666666" }}
                onClick={() => handleOpen("Script")}
              />
            </Tooltip>
            <img src={ICONS.INTELLECTUAL} alt="zoom" />
            <p style={{  color:"#666666"}}>Script</p>
          </div>
        </div> */}
        <div
          className="dndnode  dnd-model"            

          onDragStart={(event) =>
            onDragStart(event, "default", "Textual Abuse-INTELLECTUAL", "model")
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "#666666" }}
                onClick={() => handleOpen("Text Abuse")}
              />
            </Tooltip>
            <img src={ICONS.INTELLECTUAL} alt="zoom" />
            <p style={{  color:"#666666"}}>Textual Abuse</p>
          </div>
        </div>
        {/* <div
          className="dndnode"
          onDragStart={(event) =>
            onDragStart(
              event,
              "default",
              "Morale Detection-EMOTIONAL",
              "model"
            )
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "#666666" }}
                onClick={() => handleOpen("Morale Detection")}
              />
            </Tooltip>
            <img src={ICONS.EMOTIONAL} alt="zoom" />
            <p>Morale Detection</p>
          </div>
        </div> */}
        {/* <div
          className="dndnode"
          onDragStart={(event) =>
            onDragStart(
              event,
              "default",
              "Activism Analysis-INTELLECTUAL",
              "model"
            )
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "#666666" }}
                onClick={() => handleOpen("Activism Analysis")}
              />
            </Tooltip>
            <img src={ICONS.INTELLECTUAL} alt="zoom" />
            <p>Activism Analysis</p>
          </div>
        </div> */}
        <div
          className="dndnode  dnd-model"                    
         
          onDragStart={(event) =>
            onDragStart(
              event,
              "default",
              "Insult Detection-INTELLECTUAL",
              "model"
            )
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "#666666" }}
                onClick={() => handleOpen("Insult Detection")}
              />
            </Tooltip>
            <img src={ICONS.INTELLECTUAL} alt="zoom" />
            <p style={{  color:"#666666"}}>Insult Detection</p>
          </div>
        </div>
        <div
          className="dndnode  dnd-model"   

          onDragStart={(event) =>
            onDragStart(
              event,
              "default",
              "Sexual Harrasment-INTELLECTUAL",
              "model"
            )
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "#666666" }}
                onClick={() => handleOpen("Sexual Harrasment")}
              />
            </Tooltip>
            <img src={ICONS.INTELLECTUAL} alt="zoom" />
            <p style={{  color:"#666666"}}>Sexual Harrasment</p>
          </div>
        </div>
        {/* <div
          className="dndnode"
          onDragStart={(event) =>
            onDragStart(
              event,
              "default",
              "Racism Detection-INTELLECTUAL",
              "model"
            )
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "#666666" }}
                onClick={() => handleOpen("Racism Detection")}
              />
            </Tooltip>
            <img src={ICONS.INTELLECTUAL} alt="zoom" />
            <p>Racism Detection</p>
          </div>
        </div> */}
        {/* <div
          className="dndnode  dnd-model"                 

          onDragStart={(event) =>
            onDragStart(
              event,
              "default",
              "Sentiment Analysis-INTELLECTUAL",
              "model"
            )
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "#666666" }}
                onClick={() => handleOpen("Sentiment Analysis")}
              />
            </Tooltip>
            <img src={ICONS.INTELLECTUAL} alt="zoom" />
            <p style={{  color:"#666666"}}>Sentiment Analysis</p>
          </div>
        </div> */}
        {/* <div
          className="dndnode"
          onDragStart={(event) =>
            onDragStart(
              event,
              "default",
              "Resignation Prediction-EMOTIONAL",
              "model"
            )
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "#666666" }}
                onClick={() => handleOpen("Resignation Prediction")}
              />
            </Tooltip>
            <img src={ICONS.EMOTIONAL} alt="zoom" />
            <p>Resignation Prediction</p>
          </div>
        </div> */}
        
          {/* <div
            className="dndnode  dnd-model"                   

            onDragStart={(event) =>
              onDragStart(event, "default", "Diversity Inclusion-INTELLECTUAL", "model")
            }
            draggable
          >
            <div className="sourceCard">
              <Tooltip title="Configure">
                <SettingsIcon
                  fontSize="small"
                  className="sourceCardSetting"
                  style={{ fill: "#666666" }}
                  onClick={() => handleOpen("Diversity Inclusion")}
                />
              </Tooltip>
              <img src={ICONS.INTELLECTUAL} alt="zoom" />
              <p style={{  color:"#666666"}}>Diversity Inclusion</p>
            </div>
          </div> */}
        {/* <div
          className="dndnode  dnd-model"                   
          onDragStart={(event) =>
            onDragStart(event, "default", "Sexism Detection-INTELLECTUAL", "model")
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "#666666" }}
                onClick={() => handleOpen("Sexism Detection")}
              />
            </Tooltip>
            <img src={ICONS.INTELLECTUAL} alt="zoom" />
            <p style={{  color:"#666666"}}>Sexism Detection</p>
          </div>
        </div> */}
      </aside>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModelConfigModal
          open={open}
          handleClose={handleClose}
          selectedModel={selectedModel}
        />
      </Modal>
    </>
  );
}
