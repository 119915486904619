import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "./teamsConfig";
// import CheckIcon from "@material-ui/icons/Check";
import {
  GET_CHANNELS_SUCCESS,
  SAVE_CHANNELSFROMTEAMS,
  SAVE_TEAMID,
  VERIFY_TOKEN,
} from "../../../../redux/Dashboard/Dashboard.types";
// import { CHECK_KEYS } from "../../redux/Dashboard/Dashboard.types";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#fff",
    padding: "0!important",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    "& div": {
      color: "#fff",
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    marginTop: 20,
    color: "rgba(0, 0, 0, 0.87)!important",
    borderBlockColor: "#6352C6",
  },
  input: {
    color: "#fff",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    padding: 0,
  },
}));

export default function KeysModal({ open, handleClose, title }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userID = JSON.parse(localStorage.getItem("userData")).userId;
  const { instance } = useMsal();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [teams, setTeams] = useState({});
  const [channels, setChannels] = useState({});
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  useEffect(() => {
    if (channels.length > 0) {
      const channelList = [];
      const channelData = [];
      for (let i = 0; i < channels.length; i++) {
        channelList.push(channels[i].displayName);
        channelData.push({ id: channels[i].id, name: channels[i].displayName });
      }
      !isDemo &&
        dispatch({
          type: GET_CHANNELS_SUCCESS,
          payload: { status: "PASS", channels: channelList },
        });
      !isDemo &&
        dispatch({
          type: SAVE_CHANNELSFROMTEAMS,
          payload: channelData,
        });
      handleClose();
    }
  }, [channels]);
  async function handleLogin(instance) {
    // instance.loginPopup(loginRequest).catch((e) => {
    // });
    try {
      await instance.loginPopup(loginRequest).catch((e) => {
        console.log(e);
      });
      setIsAuthenticated(true);
    } catch (e) {
      setIsAuthenticated(false);
    }
  }
  function allStorage() {
    var archive = [],
      keys = Object.keys(sessionStorage),
      i = 0,
      key;

    for (; (key = keys[i]); i++) {
      if (key.includes("accesstoken")) {
        archive.push(
          key.includes("accesstoken") && JSON.parse(sessionStorage.getItem(key))
        );
      }
    }

    // console.log(archive);
    return archive[archive.length - 1];
  }

  async function fetchTeams() {
    const teamResp = await axios.get(
      "https://graph.microsoft.com/v1.0/me/joinedTeams",
      // "https://graph.microsoft.com/beta/me/chats/getAllMessages",

      {
        headers: {
          "Access-Control-Allow-Headers": "Authorization",
          Authorization: `Bearer ${allStorage().secret}`,
        },
      }
    );
    if (teamResp.data.value) {
      setTeams(teamResp.data.value);
      toast.success("teams fetched successfully");
    }
  }

  async function fetchChannels(id) {
    const channelResp = await axios.get(
      `https://graph.microsoft.com/v1.0/teams/${id}/channels`,
      {
        headers: {
          "Access-Control-Allow-Headers": "Authorization",
          Authorization: `Bearer ${allStorage().secret}`,
        },
      }
    );
    if (channelResp.data.value.length > 0) {
      !isDemo &&
        dispatch({
          type: SAVE_TEAMID,
          payload: id,
        });
      setChannels(channelResp.data.value);
      toast.success("channels fetched successfully");
    }
  }
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <DialogContent classes={{ root: classes.root }} style={{ width: 300 }}>
        <div
          className="popUp"
          style={{
            backgroundColor: !isNight ? "#212047" : "#e5e5e5",
          }}
        >
          <div className="popUpTitle">{title}</div>
          {!isAuthenticated ? (
            <button
              onClick={() => handleLogin(instance)}
              style={{
                background: "#6352c6",
                margin: " 10px",
                padding: "0.5rem",
                color: "#fff",
                fontSize: "1rem",
                border: "2px solid #6352c6",
                borderRadius: "5px",
              }}
            >
              Sign in with Microsoft
            </button>
          ) : (
            <p style={{ color: !isNight ? "#aebcc9" : "#000"  }}>Authenticated!</p>
          )}
          {isAuthenticated && (
            <button
              style={{
                background: "#e5e5e5",
                margin: " 10px",
                padding: "0.5rem",
                color: "#000",
                border: "2px solid #6352c6",
                fontSize: "1rem",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={fetchTeams}
            >
              Fetch Teams
            </button>
          )}
          {teams.length > 0 && (
            <div style={{ padding: "10px 0  0", color: !isNight ? "#aebcc9" : "#000" }}>
              Click on a team to fetch channels
              <ul style={{ listStyle: "none", padding: " 0", color: !isNight ? "#aebcc9" : "#000"  }}>
                {teams.map((item, id) => (
                  <li
                    key={id + 1}
                    onClick={() => {
                      fetchChannels(item.id);
                    }}
                    style={{
                      cursor: "pointer",
                      listStyle: "none",
                      fontSize: "0.8rem",
                      marginTop: "10px",
                      color: !isNight ? "#aebcc9" : "#000" 
                    }}
                  >
                    {item.displayName}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <form className={classes.container}>
            <div className="btnOptions">
              <div className="cancelBtn" onClick={handleClose}>
                <div className="rangeBtn">
                  <CloseIcon style={{ color: !isNight ? "#aebcc9" : "#000" }} />
                  <div
                    className="btnText"
                    style={{ color: !isNight ? "#aebcc9" : "#000" }}
                  >
                    Cancel
                  </div>
                </div>{" "}
              </div>
              {/* <div className="submitBtn"> 
                <div
                  className="rangeBtn"
                  type="submit"
                  onClick={(e) => {
                    checkHandler(e);
                  }}
                >
                  <CheckIcon />
                  <div className="btnText">CHECK</div>
                </div>
              </div> */}
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
}
