import React from "react";

const Step4Modal = ({
  handleClose,
  setCurrentScreen,
  currentScreen,
  currentStep,
  setCurrentStep,
  setTopStatusAccordian,
  setInsightAccordion,
  setMoraleAccordion,
}) => {
  const handleNextStep = () => {
    // handleClose();
    setInsightAccordion(false);
    setMoraleAccordion(true);
    // setCurrentScreen("logic");
    setCurrentStep(5);
  };

  const handleSkip = () => {
    handleClose();
    localStorage.setItem("skipped", true);
  };

  return (
    <>
      
      <div
        className="addChartsContainer"
        style={{
          width: "24vw",
          marginTop: "55px",
          position: "absolute",
          left: "35%",
          top: "22%",
        }}
      >
        <div style={{ color: "#484848", fontSize: "1rem" }}>
          <b> Key Insights Section</b>
        </div>
        <p style={{ color: "#484848" }}>
          In this section, you'll find all the key insights generated from the
          analysis of your data. These include both positive and negative
          insights, helping you take informed action accordingly.
        </p>
        <div className="btnOptions" style={{ marginTop: "0px" }}>
          <div className="submitBtn">
            <div className="rangeBtn" type="submit" onClick={handleNextStep}>
              <div className="btnText">{"NEXT >>"}</div>
            </div>
          </div>
          <div className="submitBtn">
            <div className="rangeBtn" type="submit" onClick={handleSkip}>
              <div className="btnText">{"SKIP >>>>"}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step4Modal;
