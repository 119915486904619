import React, { useState, useEffect, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Skeleton from "@mui/material/Skeleton";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Chart from "react-apexcharts";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@material-ui/core";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { useScreenshot } from "use-react-screenshot";
import * as clipboard from "clipboard-polyfill";
import { base64StringToBlob } from "blob-util";
import { demoGlassdoorReviews } from "../../demoData";
import Grid from "@mui/material/Grid";
import CircularWithValueLabel from "../../../../Charts/CircularProgressWithLabel";
import CircularProgressWithLabel from "../../../../Charts/CircularProgressWithLabel";

function SubRatingDist({
  currentFilter,
  customDate,
  currentWorkflowID,
  textAbuseData,
}) {
  const dispatch = useDispatch();

  const [clipProcessing, setClipProcessing] = useState(false);

  async function clip(b64Data) {
    const item = new clipboard.ClipboardItem({
      "image/png": base64StringToBlob(b64Data.split(",")[1], "image/png"),
    });

    await clipboard.write([item]);
  }

  const subRatingDistRef = createRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const getImage = async () => {
    setClipProcessing(true);
    await takeScreenshot(subRatingDistRef.current);
    setClipProcessing(false);
    image && clip(image);
  };

  useEffect(() => {
    image && clip(image);
  }, [image]);

  const filters = useSelector((data) => data.dashboard.filters);
  const [isLoading, setIsLoading] = useState(true);
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const [smallNumber, setSmallNumber] = useState(0);
  useEffect(() => {
    setSmallNumber(randomFromInterval(-0.3, 0.3));
  }, [filters, currentFilter, customDate]);

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function randomFromInterval(min, max) {
    return (Math.random() * (max - min) + min).toFixed(2);
  }

  return (
    <div
      ref={subRatingDistRef}
      style={{
        height: "300px",
        borderRadius: "12px",
        backgroundColor: !isNight ? "#2A2953" : "#fff",
        position: "relative",
        margin: "20px 0 0 0.3rem",
      }}
    >
      <div
        style={{
          width: "93%",
          position: "relative",
          top: "15px",
          margin: "1rem",
          display: "flex",
          justifyContent: "space-between",
          margin: "1rem",
        }}
      >
        <p
          style={{
            margin: "0",
            textAlign: "left",
            fontWeight: 500,
            color: !isNight ? "#fff" : "#484848",
          }}
        >
          Monthly Culture Ratings
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                Monthly Culture Ratings by Major Category
              </span>
            }
          >
            <InfoIcon
              style={{
                fontSize: "1rem",
                filter: "opacity(0.7)",
                position: "relative",
                top: "3px",
              }}
            />
          </Tooltip>
        </p>
        <Tooltip
          title={
            <span
              style={{
                fontSize: "0.8rem",
                textAlign: "center",
              }}
            >
              Copy to clipboard
            </span>
          }
        >
          {clipProcessing ? (
            <CircularProgress size={16} color="inherit" />
          ) : (
            <ContentCopyOutlinedIcon
              onClick={getImage}
              style={{
                fontSize: "1.2rem",
                cursor: clipProcessing ? "wait" : "pointer",
              }}
            />
          )}
        </Tooltip>
      </div>
      {/* [2.8, 3.1, 3.5, 3.5, 3.7]; */}
      {demoGlassdoorReviews.status === "success" || (isDemo && !isLoading) ? (
        <div>
          <div
            className="sentiment-score-container"
            style={{ margin: " 2rem", padding: "3rem 0" }}
          >
            <Grid container spacing={3}>
              <Grid item xl={2.4} lg={2.4} sm={2.4} xs={2.4}>
                <CircularProgressWithLabel
                  value={(3.03 / 5) * 100}
                  size={130}
                  label={3.03}
                  thickness={3.5}
                  sx={{
                    color: "#F05919",
                    borderRadius: "50%",
                    boxShadow: "inset 0 0 0 10.5px #d1d1d1",
                  }}
                />
                <p>Work/Life Balance </p>
              </Grid>
              <Grid item xl={2.4} lg={2.4} sm={2.4} xs={2.4}>
                <CircularProgressWithLabel
                  value={(3.1 / 5) * 100}
                  size={130}
                  label={3.1}
                  thickness={3.5}
                  sx={{
                    color: "#D06283",
                    borderRadius: "50%",
                    boxShadow: "inset 0 0 0 10.5px #d1d1d1",
                  }}
                />
                <p>Culture and Values</p>
              </Grid>
              <Grid item xl={2.4} lg={2.4} sm={2.4} xs={2.4}>
                <CircularProgressWithLabel
                  value={(3.5 / 5) * 100}
                  size={130}
                  label={3.5}
                  thickness={3.5}
                  sx={{
                    color: "#578826",
                    borderRadius: "50%",
                    boxShadow: "inset 0 0 0 10.5px #d1d1d1",
                  }}
                />
                <p>Career Growth </p>
              </Grid>
              <Grid item xl={2.4} lg={2.4} sm={2.4} xs={2.4}>
                <CircularProgressWithLabel
                  value={(3.02 / 5) * 100}
                  size={130}
                  label={3.02}
                  thickness={3.5}
                  sx={{
                    color: "#0E71AA",
                    borderRadius: "50%",
                    boxShadow: "inset 0 0 0 10.5px #d1d1d1",
                  }}
                />
                <p>Management</p>
              </Grid>
              <Grid item xl={2.4} lg={2.4} sm={2.4} xs={2.4}>
                <CircularProgressWithLabel
                  value={(3.83 / 5) * 100}
                  size={130}
                  label={3.83}
                  thickness={3.5}
                  sx={{
                    color: "#607D8B",
                    borderRadius: "50%",
                    boxShadow: "inset 0 0 0 10.5px #d1d1d1",
                  }}
                />
                <p>Compensation and Benefits</p>
              </Grid>
            </Grid>
          </div>
        </div>
      ) : demoGlassdoorReviews.status === "fail" || currentWorkflowID === "" ? (
        <div style={{ position: "relative", top: "100px" }}>
          <ErrorOutlineIcon style={{ color: "#94989f" }} />
          <p style={{ margin: "0", color: "#94989f" }}>
            {currentWorkflowID === "" ? "Select a workflow!" : "No Data found!"}
          </p>
        </div>
      ) : (
        <Skeleton
          variant="circular"
          width={"75%"}
          height={"250px"}
          style={{
            position: "relative",
            top: "20px",
            margin: "0 auto",
          }}
        />
      )}
    </div>
  );
}

export default SubRatingDist;
