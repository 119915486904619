import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import { Button } from "@material-ui/core";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import FormControl from "@mui/material/FormControl";
import { useDispatch } from "react-redux";
import {
  GET_ALL_SURVEYS,
  UPDATE_SURVEY,
} from "../../../../redux/Dashboard/Dashboard.types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function FavoriteModal({ survey, handleClose }) {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [interval, setInterval] = useState("monthly");
  const archive = () => {
    setTimeout(() => {
      dispatch({
        type: GET_ALL_SURVEYS,
      });
      handleClose();
    }, 2000);
  };

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Repeat Survey
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Typography>{`Do you want to mark this survey as Favorite?`}</Typography>
      </Box>
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <FormControl fullWidth>
          <Typography>Interval</Typography>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={interval}
            label="Age"
            onChange={(e) => {
              setInterval(e.target.value);
            }}
          >
            <MenuItem value={"once"}>Once</MenuItem>
            <MenuItem value={"monthly"}>Monthly</MenuItem>
            <MenuItem value={"quaterly"}>Quaterly</MenuItem>
            <MenuItem value={"annually"}>Annually</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        {" "}
        <Typography>Start Date</Typography>
        <TextField
          fullWidth
          id="date"
          type="date"
          variant="outlined"
          value={startDate.toISOString().split("T")[0]}
          onChange={(e) =>
            setStartDate(new Date(e.target.value + "T" + startTime))
          }
        />
      </Box>

      <Stack direction="row" spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={archive}
          style={{
            background: "#D06283",
            color: "#fff",
          }}
          variant="contained"
        >
          Mark
        </Button>
      </Stack>
    </Box>
  );
}

export default FavoriteModal;
