import React from "react";
import "../styles/global.css";


const TandCPage = () => { 
  
    return (
        <div className="loginPage">
            <div style={{ width: "60%", backgroundColor: "#fff", height: "90vh", margin: "4rem 2rem" }}>
                <div style={{  height: "100%" }}>
                    <iframe title="tandC" style={{  height: "100%", width: "100%",border:"none"}} src={"/tandc.html"}></iframe> 
                </div>
            </div>
        </div>
    ); 
};

export default TandCPage;
 