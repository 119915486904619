import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useHistory } from "react-router-dom";
import {
  SET_CURRENT_SOURCE,
  SET_LOADING,
} from "../../redux/Dashboard/Dashboard.types";
import { FormGroup, FormControlLabel, Checkbox } from "@material-ui/core/";

export default function CompareOptions({
  filterToCompare,
  setFiltersToCompare,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  const [open, setOpen] = useState(false);

  const toggleList = () => {
    setOpen(!open);
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <div
          className="dropdown"
          style={{
            fontSize: "0.8rem",
            padding: "0.1rem 0.5rem",
            position: "relative",
            top: "1px",
          }}
          onClick={toggleList}
        >
          <span style={{ position: "relative", top: "-5px" }}>
            {/* {currentWorkflowName} */}
            Choose Filters
          </span>
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </div>
        {open && (
          <div
            style={{
              position: "absolute",
              zIndex: 500,
              right: "0",
            }}
          >
            <div
              style={{
                width: isDemo ? "320px" : "450px",
                // height: isDemo ? "250px" : "310px",
                background: "#fff",
                right: "0",
                padding: "0.5rem 1rem",
                borderRadius: "8px",
                boxShadow:
                  "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                position: "relative",
                top: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: isDemo ? "column" : "row",
                }}
              >
                <>
                  <div style={{ margin: "1rem" }}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filterToCompare.includes("Overall")}
                            onClick={() => {
                              setFiltersToCompare([
                                ...filterToCompare,
                                "Overall",
                              ]);
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Overall
                          </p>
                        }
                      />
                    </FormGroup>
                    <p
                      style={{
                        margin: "0",
                        textAlign: "left",
                        color: "#484848",
                        fontWeight: "bold",
                      }}
                    >
                      Gender
                    </p>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filterToCompare.includes("Male")}
                            onClick={() => {
                              filterToCompare.includes("Male")
                                ? setFiltersToCompare(
                                    filterToCompare.filter(
                                      (item) => item !== "Male"
                                    )
                                  )
                                : setFiltersToCompare([
                                    ...filterToCompare,
                                    "Male",
                                  ]);
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Male
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filterToCompare.includes("Female")}
                            onClick={() => {
                              filterToCompare.includes("Female")
                                ? setFiltersToCompare(
                                    filterToCompare.filter(
                                      (item) => item !== "Female"
                                    )
                                  )
                                : setFiltersToCompare([
                                    ...filterToCompare,
                                    "Female",
                                  ]);
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Female
                          </p>
                        }
                      />

                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filterToCompare.includes("Non-binary")}
                              onClick={() => {
                                filterToCompare.includes("Non-binary")
                                  ? setFiltersToCompare(
                                      filterToCompare.filter(
                                        (item) => item !== "Non-binary"
                                      )
                                    )
                                  : setFiltersToCompare([
                                      ...filterToCompare,
                                      "Non-binary",
                                    ]);
                              }}
                              style={{
                                color: !isNight ? "#aebcc9" : "#1554F6",
                              }}
                            />
                          }
                          label={
                            <p
                              style={{
                                fontSize: "14px",
                                color: !isNight ? "#aebcc9" : "#343434",
                                margin: "0",
                                textAlign: "left",
                              }}
                            >
                              Non-binary
                            </p>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={filterToCompare.includes("Transgender")}
                              onClick={() => {
                                filterToCompare.includes("Transgender")
                                  ? setFiltersToCompare(
                                      filterToCompare.filter(
                                        (item) => item !== "Transgender"
                                      )
                                    )
                                  : setFiltersToCompare([
                                      ...filterToCompare,
                                      "Transgender",
                                    ]);
                              }}
                              style={{
                                color: !isNight ? "#aebcc9" : "#1554F6",
                              }}
                            />
                          }
                          label={
                            <p
                              style={{
                                fontSize: "14px",
                                color: !isNight ? "#aebcc9" : "#343434",
                                margin: "0",
                                textAlign: "left",
                              }}
                            >
                              Transgender
                            </p>
                          }
                        />
                      </>
                    </FormGroup>
                  </div>
                  <div style={{ margin: "1rem" }}>
                    <p
                      style={{
                        margin: "0",
                        textAlign: "left",
                        color: "#484848",
                        fontWeight: "bold",
                      }}
                    >
                      Department
                    </p>
                    {/* <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            // checked={filters.gender.includes("male")}
                            // onClick={() => {
                            //   handleGenderChange("male");
                            // }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Male
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            // checked={filters.gender.includes("female")}
                            // onClick={() => {
                            //   handleGenderChange("female");
                            // }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Female
                          </p>
                        }
                      />

                      <>
                        <FormControlLabel
                          control={
                            <Checkbox
                              //   checked={filters.gender.includes("non-binary")}
                              //   onClick={() => {
                              //     handleGenderChange("non-binary");
                              //   }}
                              style={{
                                color: !isNight ? "#aebcc9" : "#1554F6",
                              }}
                            />
                          }
                          label={
                            <p
                              style={{
                                fontSize: "14px",
                                color: !isNight ? "#aebcc9" : "#343434",
                                margin: "0",
                                textAlign: "left",
                              }}
                            >
                              Non-binary
                            </p>
                          }
                        />
                        <FormControlLabel
                          control={
                            <Checkbox
                              //   checked={filters.gender.includes("transgender")}
                              //   onClick={() => {
                              //     handleGenderChange("transgender");
                              //   }}
                              style={{
                                color: !isNight ? "#aebcc9" : "#1554F6",
                              }}
                            />
                          }
                          label={
                            <p
                              style={{
                                fontSize: "14px",
                                color: !isNight ? "#aebcc9" : "#343434",
                                margin: "0",
                                textAlign: "left",
                              }}
                            >
                              Transgender
                            </p>
                          }
                        />
                      </>
                    </FormGroup> */}

                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filterToCompare.includes(
                              "Accounting & Finance"
                            )}
                            onClick={() => {
                              filterToCompare.includes("Accounting & Finance")
                                ? setFiltersToCompare(
                                    filterToCompare.filter(
                                      (item) => item !== "Accounting & Finance"
                                    )
                                  )
                                : setFiltersToCompare([
                                    ...filterToCompare,
                                    "Accounting & Finance",
                                  ]);
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Accounting & Finance
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filterToCompare.includes("Engineering")}
                            onClick={() => {
                              filterToCompare.includes("Engineering")
                                ? setFiltersToCompare(
                                    filterToCompare.filter(
                                      (item) => item !== "Engineering"
                                    )
                                  )
                                : setFiltersToCompare([
                                    ...filterToCompare,
                                    "Engineering",
                                  ]);
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Engineering
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filterToCompare.includes(
                              "Human Resources"
                            )}
                            onClick={() => {
                              filterToCompare.includes("Human Resources")
                                ? setFiltersToCompare(
                                    filterToCompare.filter(
                                      (item) => item !== "Human Resources"
                                    )
                                  )
                                : setFiltersToCompare([
                                    ...filterToCompare,
                                    "Human Resources",
                                  ]);
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Human Resources
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filterToCompare.includes(
                              "Information Technology"
                            )}
                            onClick={() => {
                              filterToCompare.includes("Information Technology")
                                ? setFiltersToCompare(
                                    filterToCompare.filter(
                                      (item) =>
                                        item !== "Information Technology"
                                    )
                                  )
                                : setFiltersToCompare([
                                    ...filterToCompare,
                                    "Information Technology",
                                  ]);
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Information Technology
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filterToCompare.includes("Operations")}
                            onClick={() => {
                              filterToCompare.includes("Operations")
                                ? setFiltersToCompare(
                                    filterToCompare.filter(
                                      (item) => item !== "Operations"
                                    )
                                  )
                                : setFiltersToCompare([
                                    ...filterToCompare,
                                    "Operations",
                                  ]);
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Operations
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filterToCompare.includes(
                              "Research & Development"
                            )}
                            onClick={() => {
                              filterToCompare.includes("Research & Development")
                                ? setFiltersToCompare(
                                    filterToCompare.filter(
                                      (item) =>
                                        item !== "Research & Development"
                                    )
                                  )
                                : setFiltersToCompare([
                                    ...filterToCompare,
                                    "Research & Development",
                                  ]);
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Research & Development
                          </p>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={filterToCompare.includes(
                              "Sales & Marketing"
                            )}
                            onClick={() => {
                              filterToCompare.includes("Female")
                                ? setFiltersToCompare(
                                    filterToCompare.filter(
                                      (item) => item !== "Sales & Marketing"
                                    )
                                  )
                                : setFiltersToCompare([
                                    ...filterToCompare,
                                    "Sales & Marketing",
                                  ]);
                            }}
                            style={{
                              color: !isNight ? "#aebcc9" : "#1554F6",
                            }}
                          />
                        }
                        label={
                          <p
                            style={{
                              fontSize: "14px",
                              color: !isNight ? "#aebcc9" : "#343434",
                              margin: "0",
                              textAlign: "left",
                            }}
                          >
                            Sales & Marketing
                          </p>
                        }
                      />
                    </FormGroup>
                  </div>
                  <div style={{ display: "flex", width: "100%" }}>
                    <div
                      // onClick={handleApply}
                      style={{
                        cursor: "pointer",
                        width: "100%",
                        margin: "0.5rem",
                        background: "#D06283",
                        color: "#fff",
                        padding: "0.5rem",
                        boxShadow:
                          "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                      }}
                    >
                      Apply
                    </div>
                  </div>
                </>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
