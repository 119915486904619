import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@mui/material/Grid";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";
import LockClockOutlinedIcon from "@mui/icons-material/LockClockOutlined";
import {
  GET_LICENSE_PLANS,
  OPT_LICENSE,
} from "../../redux/Dashboard/Dashboard.types";
// import CheckIcon from "@material-ui/icons/Check";
// import { CHECK_KEYS } from "../../redux/Dashboard/Dashboard.types";
import RedeemIcon from "@mui/icons-material/Redeem";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
const useStyles = makeStyles((theme) => ({
  root: {
    color: "#000",
    padding: "0 !important",
  },
  container: {
    // display: "flex",
    // flexWrap: "wrap",
    flexDirection: "column",
    "& div": {
      color: "#fff",
    },
  },
}));

export default function PlansModal({ open, handleClose, showTrial }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const licensePlans = useSelector((data) => data.dashboard.licensePlans);
  const licenseOptStatus = useSelector(
    (data) => data.dashboard.licenseOptStatus
  );

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const userDetails = useSelector((data) => data.dashboard.userDetails);

  useEffect(() => {
    dispatch({ type: GET_LICENSE_PLANS });
  }, []);

  const [currentScreen, setCurrentScreen] = useState("plans");

  useEffect(() => {
    licenseOptStatus?.isSuccess && setCurrentScreen("success");
  }, [licenseOptStatus]);

  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  const handleOptLicense = (id) => {
    dispatch({ type: OPT_LICENSE, payload: { id } });
  };
  return (
    <Dialog open={open} style={{ width: "700px", margin: "auto" }}>
      <DialogContent style={{ background: "#e5e5e5" }}>
        {currentScreen === "plans" &&
          licensePlans?.data?.data?.length > 0 &&
          !userDetails.licenseActive && (
            <div
              className="popUp"
              style={{
                backgroundColor: "#e5e5e5",
              }}
            >
              <p
                style={{
                  color: "#484848",
                  fontSize: "20px",
                  fontWeight: "bold",
                  margin: "0 auto 1rem ",
                  textAlign: "center",
                }}
              >
                Choose a Plan
              </p>
              <p
                style={{
                  color: "#484848",
                  fontSize: "12px",
                  margin: "auto",
                  textAlign: "center",
                }}
              >
                Please upgrade to a paid plan or choose free trial for a full
                acces to the dashboard
              </p>

              <Grid
                container
                style={{
                  width: "100%",
                  justifyContent: "center",
                  margin: "2rem auto",
                }}
              >
                {showTrial
                  ? licensePlans?.data?.data
                      ?.filter((item) => item.type !== "trial")
                      .map((item) => (
                        <Grid item xl={6}>
                          <div
                            style={{
                              width: "220px",
                              // height: "350px",
                              border: "none",
                              borderRadius: "8px",
                              margin: "1rem",
                              background: "#17123b",
                              boxShadow:
                                "rgba(0, 0, 0, 0.05) 0px 3.86842px 23.2105px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "center",
                                flexDirection: "column",
                              }}
                            >
                              <div
                                style={{
                                  borderRadius: "8px 8px 0 0",
                                  background:
                                    item.type === "trial"
                                      ? "linear-gradient(90deg, rgba(147,157,187,1) 0%, rgba(86,63,138,1) 68%, rgba(99,21,189,1) 100%)"
                                      : "linear-gradient(90deg, rgba(228,68,101,1) 0%, rgba(255,132,78,1) 55%, rgba(246,183,82,1) 100%)",
                                }}
                              >
                                <p
                                  style={{
                                    color: "#fff",
                                    fontSize: "18px",
                                    fontWeight: "bolder",
                                    margin: "1rem auto",
                                    textAlign: "center",
                                  }}
                                >
                                  {item.name}
                                </p>
                              </div>
                              <RedeemIcon
                                style={{
                                  color: "#fff",
                                  margin: "1rem auto 0rem",
                                  fontSize: "60px",
                                }}
                              />

                              <p
                                style={{
                                  color: "#fff",
                                  fontSize: "14px",
                                  margin: "0.5rem auto",
                                  textAlign: "center",
                                }}
                              >
                                {capitalizeFirstLetter(item.type)}
                              </p>
                              <p
                                style={{
                                  color: "#fff",
                                  fontSize: "14px",
                                  margin: "0.5rem auto",
                                  textAlign: "center",
                                }}
                              >
                                {item.type === "trial"
                                  ? "GREAT FOR STARTERS"
                                  : "FOR PROFESSIONAL USE"}
                              </p>
                              <p
                                style={{
                                  color: "#fff",
                                  fontSize: "14px",
                                  margin: "0 auto 0",
                                  textAlign: "center",
                                }}
                              >
                                <AccessTimeIcon
                                  style={{
                                    color: "#fff",
                                    margin: "0.2rem 0.5rem 0.5rem",
                                    fontSize: "16px",
                                    position: "relative",
                                    top: "10px",
                                  }}
                                />
                                {`${item.duration} ${item.durationType}`}
                              </p>
                              <p
                                style={{
                                  color: "#fff",
                                  fontSize: "12px",
                                  margin: "0.5rem auto 0",
                                  textAlign: "center",
                                }}
                              >
                                {item.type === "trial"
                                  ? "upload an archive up to 20Mb"
                                  : "upload unlimited archive"}
                              </p>
                              <p
                                style={{
                                  color: "#fff",
                                  fontSize: "12px",
                                  margin: "0.5rem auto 0",
                                  textAlign: "center",
                                }}
                              >
                                {item.type === "trial"
                                  ? "read-only access to dashboard"
                                  : "read-write access to dashboard"}
                              </p>
                              <p
                                style={{
                                  color: "#fff",
                                  fontSize: "12px",
                                  margin: "0.5rem auto ",
                                  textAlign: "center",
                                }}
                              >
                                {item.type === "trial"
                                  ? "One account per company"
                                  : "Users can create multiple accounts"}
                              </p>
                              <button
                                onClick={() => {
                                  handleOptLicense(item.id);
                                }}
                                style={{
                                  color: "#fff",
                                  margin: "1rem auto 2rem",
                                  borderRadius: "12px",
                                  background: "#3629b7",
                                  padding: "0.5rem",
                                  border: "none",
                                  cursor: "pointer",
                                }}
                              >
                                GET STARTED
                              </button>
                            </div>
                          </div>
                        </Grid>
                      ))
                  : licensePlans?.data?.data?.map((item) => (
                      <Grid item xl={6}>
                        <div
                          style={{
                            width: "220px",
                            // height: "350px",
                            border: "none",
                            borderRadius: "8px",
                            margin: "1rem",
                            background: "#17123b",
                            boxShadow:
                              "rgba(0, 0, 0, 0.05) 0px 3.86842px 23.2105px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            <div
                              style={{
                                borderRadius: "8px 8px 0 0",
                                background:
                                  item.type === "trial"
                                    ? "linear-gradient(90deg, rgba(147,157,187,1) 0%, rgba(86,63,138,1) 68%, rgba(99,21,189,1) 100%)"
                                    : "linear-gradient(90deg, rgba(228,68,101,1) 0%, rgba(255,132,78,1) 55%, rgba(246,183,82,1) 100%)",
                              }}
                            >
                              <p
                                style={{
                                  color: "#fff",
                                  fontSize: "18px",
                                  fontWeight: "bolder",
                                  margin: "1rem auto",
                                  textAlign: "center",
                                }}
                              >
                                {item.name}
                              </p>
                            </div>
                            <RedeemIcon
                              style={{
                                color: "#fff",
                                margin: "1rem auto 0rem",
                                fontSize: "60px",
                              }}
                            />

                            <p
                              style={{
                                color: "#fff",
                                fontSize: "14px",
                                margin: "0.5rem auto",
                                textAlign: "center",
                              }}
                            >
                              {capitalizeFirstLetter(item.type)}
                            </p>
                            <p
                              style={{
                                color: "#fff",
                                fontSize: "14px",
                                margin: "0.5rem auto",
                                textAlign: "center",
                              }}
                            >
                              {item.type === "trial"
                                ? "GREAT FOR STARTERS"
                                : "FOR PROFESSIONAL USE"}
                            </p>
                            <p
                              style={{
                                color: "#fff",
                                fontSize: "14px",
                                margin: "0 auto 0",
                                textAlign: "center",
                              }}
                            >
                              <AccessTimeIcon
                                style={{
                                  color: "#fff",
                                  margin: "0.2rem 0.5rem 0.5rem",
                                  fontSize: "16px",
                                  position: "relative",
                                  top: "10px",
                                }}
                              />
                              {`${item.duration} ${item.durationType}`}
                            </p>
                            <p
                              style={{
                                color: "#fff",
                                fontSize: "12px",
                                margin: "0.5rem auto 0",
                                textAlign: "center",
                              }}
                            >
                              {item.type === "trial"
                                ? "upload an archive up to 20Mb"
                                : "upload unlimited archive"}
                            </p>
                            <p
                              style={{
                                color: "#fff",
                                fontSize: "12px",
                                margin: "0.5rem auto 0",
                                textAlign: "center",
                              }}
                            >
                              {item.type === "trial"
                                ? "read-only access to dashboard"
                                : "read-write access to dashboard"}
                            </p>
                            <p
                              style={{
                                color: "#fff",
                                fontSize: "12px",
                                margin: "0.5rem auto ",
                                textAlign: "center",
                              }}
                            >
                              {item.type === "trial"
                                ? "One account per company"
                                : "Users can create multiple accounts"}
                            </p>
                            <button
                              onClick={() => {
                                handleOptLicense(item.id);
                              }}
                              style={{
                                color: "#fff",
                                margin: "1rem auto 2rem",
                                borderRadius: "12px",
                                background: "#3629b7",
                                padding: "0.5rem",
                                border: "none",
                                cursor: "pointer",
                              }}
                            >
                              GET STARTED
                            </button>
                          </div>
                        </div>
                      </Grid>
                    ))}
              </Grid>
            </div>
          )}
        {currentScreen === "success" && (
          <div
            className="popUp"
            style={{
              backgroundColor:"#e5e5e5",
            }}
          >
            <p
              style={{
                color: "#484848",
                fontSize: "20px",
                fontWeight: "bold",
                margin: "0 auto 1rem ",
                textAlign: "center",
              }}
            >
              Congrats!
            </p>
            <p
              style={{
                color: "#484848",
                fontSize: "12px",
                margin: "auto",
                textAlign: "center",
              }}
            >
              {" "}
              You have successfully Opted for the license
            </p>
            <button
              onClick={handleClose}
              style={{
                color: "#fff",
                margin: "1rem auto 2rem",
                borderRadius: "12px",
                background: "#3629b7",
                padding: "0.5rem",
                border: "none",
                cursor: "pointer",
              }}
            >
              Close
            </button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
