import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";
// import CheckIcon from "@material-ui/icons/Check";
import SlackLogin from "./SlackLogin";
import {
  GET_ACCESS_TOKEN,
  GET_CHANNELS,
  SAVING_ACCESS_TOKEN,
  VERIFY_TOKEN,
} from "../../../../redux/Dashboard/Dashboard.types";
// import { CHECK_KEYS } from "../../redux/Dashboard/Dashboard.types";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#000",
    padding: "0!important",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    "& div": {
      color: "#fff",
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    marginTop: 20,
    color: "rgba(0, 0, 0, 0.87)!important",
    borderBlockColor: "#6352C6",
  },
  input: {
    color: "#fff",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    padding: 0,
  },
}));

export default function KeysModal({ open, handleClose, title }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const slackKey = useSelector((data) => data.dashboard.slackKey);
  const accessToken = useSelector((data) => data.dashboard.accessToken);
  const userID = JSON.parse(localStorage.getItem("userData")).userId;
  const userToken = JSON.parse(localStorage.getItem("userData")).usertoken;
  const accessTokenSave = useSelector((data) => data.dashboard.accessTokenSave);

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const onFailed = () => {
    !isDemo &&
      dispatch({
        type: VERIFY_TOKEN,
        payload: { app: "slack", userID },
      });
  };

  useEffect(() => {
    if (slackKey?.data?.length > 0 && !accessTokenSave) {
      !isDemo &&
        dispatch({
          type: GET_ACCESS_TOKEN,
          payload: { code: slackKey.data[0].token },
        });
    } else {
      // toast.error("Authorization Failed ! Please oauth again.");
    }
  }, [slackKey]);

  useEffect(() => {
    if (accessToken.data && slackKey.data.length > 0 && !accessTokenSave) {
      !isDemo &&
        dispatch({
          type: SAVING_ACCESS_TOKEN,
          payload: {
            app: "Slack",
            token: accessToken?.data?.authed_user?.access_token,
            headerToken: userToken,
          },
        });
      setTimeout(() => {
        handleClose();
      }, 2000);
      // handleClose();
    } else {
      // toast.error("Error while saving the token");
    }
  }, [accessToken]);

  useEffect(() => {
    if (accessTokenSave) {
      !isDemo &&
        dispatch({
          type: GET_CHANNELS,
          payload: { key: accessToken?.data?.authed_user?.access_token },
        });
      toast.success("Channels fetched Successfully");
    }
  }, [accessTokenSave]);

  const onSuccess = () => {
    toast.success("Oauth Successfull. Channels successfully imported.");
  };

  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <DialogContent classes={{ root: classes.root }} style={{ width: 300 }}>
        <div
          className="popUp"
          style={{
            backgroundColor: !isNight ? "#212047" : "#e5e5e5",
          }}
        >
          <div
            className="popUpTitle"
            style={{ color: !isNight ? "#aebcc9" : "#000" }}
          >
            {title}
          </div>
          <form className={classes.container}>
            <p style={{ color: !isNight ? "#aebcc9" : "#000" }}>
              First Allow permissions to retrieve channel message by
            </p>
            <div style={{ marginBottom: 20, marginTop: 10 }}>
              {!accessTokenSave ? (
                <SlackLogin
                  redirectUrl="https://api.trupulse.ai/oauth"
                  onFailure={onFailed}
                  onSuccess={onSuccess}
                  slackClientId="2415696530561.2965925345619"
                  slackUserScope="channels:read,channels:history,groups:history,mpim:history,im:history,users:read,users.profile:read,groups:read,im:read,mpim:read"
                  state={`slack-${userID}`}
                />
              ) : (
                <p style={{ color: !isNight ? "#aebcc9" : "#000" }}>
                  Already Authenicated
                </p>
              )}
            </div>
            <div className="btnOptions">
              <div className="cancelBtn" onClick={handleClose}>
                <div className="rangeBtn">
                  <CloseIcon style={{ color: !isNight ? "#aebcc9" : "#000" }} />
                  <div
                    className="btnText"
                    style={{ color: !isNight ? "#aebcc9" : "#000" }}
                  >
                    Cancel
                  </div>
                </div>
              </div>
              {/* <div className="submitBtn">
                <div
                  className="rangeBtn"
                  type="submit"
                  onClick={(e) => {
                    checkHandler(e);
                  }}
                >
                  <CheckIcon />
                  <div className="btnText">CHECK</div>
                </div>
              </div> */}
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
}
