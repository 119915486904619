import { Box, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import EventIcon from "@mui/icons-material/Event";
import { useSelector } from "react-redux";
import moment from "moment";
import CloseIcon from "@material-ui/icons/Close";

function EventDrop() {
  const calenderEvents = useSelector((data) => data.dashboard.calenderEvents);
  const dateFilter = useSelector((data) => data.dashboard.dateFilterSentiment);

  const [showEvent, setShowEvent] = useState(false);
  const [eventsToShow, setEventsToShow] = useState([]);

  useEffect(() => {
    if (dateFilter.start !== "" || dateFilter.end !== "") {
      setEventsToShow(
        calenderEvents
          .filter(
            (event) =>
              new Date(event.date) >= new Date(dateFilter.start) &&
              new Date(event.date) <= new Date(dateFilter.end)
          )
          .sort((a, b) => new Date(b.date) - new Date(a.date))
      );
    } else {
      setEventsToShow(calenderEvents);
    }
  }, [dateFilter]);

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <EventIcon
        sx={{ fontSize: "1.5rem", marginTop: "1rem", cursor: "pointer" }}
        onClick={() => {
          setShowEvent(!showEvent);
        }}
      />
      {showEvent && (
        <Box
          sx={{
            position: "absolute",
            top: "60px",
            background: "#e5e5e5",
            minWidth: "250px",
            minHeight: "300px",
            padding: "1rem",
            borderRadius: "12px",
            zIndex: "400",
          }}
        >
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography style={{ fontWeight: "bold" }}>Events</Typography>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowEvent(false);
              }}
            />
          </Box>
          {eventsToShow.map((event) => (
            <Box
              sx={{
                width: "90%",
                background: "#fff",
                margin: "1rem 0.2rem",
                borderRadius: "8px",
                padding: "0.5rem",
                border: `1px solid ${event.color}`,
                borderTop: `5px solid ${event.color}`,
              }}
            >
              <Typography style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                {moment(event.date).format("MMM Do YY")}
              </Typography>
              <Typography style={{ fontWeight: "bold", fontSize: "0.8rem" }}>
                {event.name}
              </Typography>
              <Typography style={{ fontSize: "0.8rem" }}>
                {event.description}
              </Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
}

export default EventDrop;
