import React from "react";
import { useHistory } from "react-router-dom";

const Step2Modal = ({ handleClose, setCurrentStep,setCurrentScreen }) => {
  const history = useHistory();

  const handleNextStep = () => {
    // handleClose();
    // setInsightAccordion(false);
    // history.push("/dashboard/feedback");
    // setCurrentScreen("feedback");
    setCurrentScreen("employeeManagement");
    setCurrentStep(3);
  };

  const handleSkip = () => {
    handleClose();
    localStorage.setItem("skipped", true);
  };

  return (
    <>
      <div
        className="addChartsContainer"
        style={{
          width: "24vw",
          marginTop: "55px",
          position: "absolute",
          left: "15%",
          top: "17%",
        }}
      >
        <div style={{ color: "#484848", fontSize: "1rem" }}>
          <b>User Management Section</b>
        </div>
        <p style={{ color: "#484848" }}>
          In this section, you can manage user details by editing or updating
          their information. You can also add new users and assign them roles
          according to their responsibilities within the TruPulse dashboard.
        </p>
        <div className="btnOptions" style={{ marginTop: "0px" }}>
          <div className="submitBtn">
            <div className="rangeBtn" type="submit" onClick={handleNextStep}>
              <div className="btnText">{"NEXT >>"}</div>
            </div>
          </div>
          <div className="submitBtn">
            <div className="rangeBtn" type="submit" onClick={handleSkip}>
              <div className="btnText">{"SKIP >>>>"}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step2Modal;
