import { toast } from "react-toastify";
import api from "../axiosConfig";
import { SessionOut } from "../../utilities/dashboardUtils";

export function* updateSurvey(action) {
  let resp;
  yield api
    .put(`feedback/surveyOperations/updateSurvey`, action.payload, {
      timeout: 500000,
      headers: {
        "Access-Control-Allow-Headers": "x-access-token",
        "x-access-token": localStorage.getItem("auth-token"),
      },
    })
    .then((response) => {
      if (response.status === 404) {
        toast.error("Please try again");
      } else if (response.status === 400) {
        SessionOut();
      } else {
        resp = response;
      }
    })
    .catch((error) => {
      toast.error("Please try again");
      return error;
    });
  return resp;
}
