import React, { useState } from "react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// import DoughnutChart from "./Doughnut";
// import HorizontalBarChart from "./HorizontalChart";
// import LineChart from "./LineChart";
// import VerticalBar from "./VerticalBar";
// import CircleProgress from "./GradientCircle";
// import "../../../styles/";
import { FormGroup, FormControlLabel, Checkbox } from "@material-ui/core/";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { CSVReader } from "react-dropzone";
import * as XLSX from "xlsx";
import { toast } from "react-toastify";

import {
  ADD_CALENDER_EVENT,
  GET_CALENDER_EVENTS,
  SAVE_DEMO_ANNOTATION,
} from "../../../redux/Dashboard/Dashboard.types";

const UploadAnnotaion = ({ handleClose, setCurrentScreen }) => {
  const dispatch = useDispatch();
  const calenderEventSaved = useSelector(
    (data) => data.dashboard.calenderEventSaved
  );
  const calenderEvents = useSelector((data) => data.dashboard.calenderEvents);

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const [selectedFiles, setSelectedFiles] = useState([]);

  const selectFile = (event) => {
    setSelectedFiles(event.target.files);
  };
  const [jsonData, setJsonData] = useState([]);
  const [alert, setAlert] = useState("");

  const excelDateToJSDate = (serial) => {
    const utcDays = Math.floor(serial - 25569);
    const utcValue = utcDays * 86400;
    const dateInfo = new Date(utcValue * 1000);
    const month = dateInfo.getUTCMonth() + 1;
    const day = dateInfo.getUTCDate();
    const year = dateInfo.getUTCFullYear();
    return `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
  };

  const handleAddEvent = () => {
    const file = selectedFiles[0];
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const parsedData = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      const processedData = parsedData.map((item) => ({
        date: excelDateToJSDate(item.Date),
        name: item.Name,
        description: item.Description,
      }));

      if (processedData.length > 0 && processedData[0].date) {
        if (!isDemo) {
          processedData.forEach((item) => {
            dispatch({
              type: ADD_CALENDER_EVENT,
              payload: {
                name: item.name,
                description: item.description
                  ? item.description
                  : "Custom Event",
                date: item.date,
                isVisible: true,
                color: "rgba(208, 98, 131, 0.5)",
              },
            });
            setTimeout(() => {
              if (calenderEventSaved) {
                setCurrentScreen("list");
                dispatch({
                  type: GET_CALENDER_EVENTS,
                  payload: {},
                });
              }
            }, 2000);
          });
          toast.success("Events added successfully");
        } else {
          const temp = [];

          processedData.forEach((item) => {
            temp.push({
              _id: Math.floor(Math.random() * 90000) + 10000,
              name: item.name,
              description: item.description ? item.description : "Custom Event",
              date: item.date,
              isVisible: true,
              color: "rgba(208, 98, 131, 0.5)",
            });
            dispatch({
              type: SAVE_DEMO_ANNOTATION,
              payload: [...calenderEvents, ...temp],
            });
            setCurrentScreen("list");
          });
        }
        toast.success("Events added successfully");
      } else {
        setAlert(
          "No Events found in the file, Please check sample file for required format"
        );
      }
    };
    reader.readAsBinaryString(file);

    // dispatch({
    //   type: ADD_CALENDER_EVENT,
    //   payload: {
    //     name: eventName,
    //     description: eventDescription,
    //     date: eventDate,
    //     color: eventColor,
    //   },
    // });
    // setTimeout(() => {
    //   if (calenderEventSaved) {
    //     setCurrentScreen("list");
    //     dispatch({
    //       type: GET_CALENDER_EVENTS,
    //       payload: {},
    //     });
    //   }
    // }, 2000);
  };
  return (
    <div
      style={{
        height: "100%",
        margin: "1rem 0.5rem",
        padding: "1rem",
      }}
    >
      <div className="inputContainer">
        <label className="formLabels">Upload File</label>
        <input
          type="file"
          placeholder=".csv, .xls, .xlsx only"
          name="file"
          required
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={selectFile}
          style={{ paddingTop: "20px" }}
        />
        <p style={{ fontSize: "0.8rem" }}>
          Only .csv, .xls and .xlsx files are allowed.{" "}
          <a
            href="https://scanta-web-resource.s3.amazonaws.com/calenderEvents.csv"
            target="_blank"
          >
            Sample file
          </a>
        </p>
        <p style={{ fontSize: "0.8rem", color: "#f00" }}>{alert}</p>
      </div>

      <br />
      <div className="btnOptions" style={{ width: "100%" }}>
        <div
          className="cancelBtn"
          onClick={() => {
            setCurrentScreen("list");
          }}
        >
          <div className="rangeBtn">
            <ArrowBackIosIcon style={{ color: "#000" }} />
            <div className="btnText" style={{ color: "#000" }}>
              Back
            </div>
          </div>
        </div>
        <div className="submitBtn" onClick={handleAddEvent}>
          <div className="rangeBtn">
            <BookmarkBorderIcon />
            <div className="btnText" type="submit">
              Submit
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadAnnotaion;
