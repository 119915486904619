import {
  GET_DETECTED_USER_DETAILS_SUCCESS,
  GET_DETECTED_USER_DETAILS_FAILURE
} from "./Other.types";

const initialState = {
  detectedUser:[],
  
};

const OtherReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DETECTED_USER_DETAILS_SUCCESS:
      return { ...state, detectedUser: action.payload.detectedUser };
    case GET_DETECTED_USER_DETAILS_FAILURE:
      return { ...state, detectedUser: action.payload };
    default:
      return state;
  }
};

export default OtherReducer;
