export const demoBaselineSurveys = [
    {
        "description": "The company is conducting an employee morale survey to assess the overall mood and emotional well-being of its workforce. The survey aims to understand how employees feel about their work, team dynamics, and the company culture. By gathering this information, the company can identify areas that may be affecting morale and take steps to improve the work environment, reduce stress, and foster a more positive, supportive atmosphere where employees feel valued and motivated.",
        "status": "available",
        "targetGroup": "",
        "frequency": 1,
        "_id": "66ec048b2772bbd4fd3cc4ed",
        "companyID": "64c8025269749c001af4dee0",
        "surveyName": "Morale Survey",
        "surveyType": "Morale",
        "questions": [
            {
                "questionID": "Q50593649",
                "question": "How satisfied are you with your current job role?",
                "options": {
                    "Very Satisfied": 100,
                    "Satisfied": 75,
                    "Neutral": 50,
                    "Unsatisfied": 25,
                    "Very Unsatisfied": 0
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Very Unsatisfied": 0
                        },
                        "time": "2024-09-19T15:24:51.234Z"
                    }
                ]
            },
            {
                "questionID": "Q61043649",
                "question": "How likely are you to recommend our company as a good place to work?",
                "options": {
                    "Neutral": 50,
                    "Likely": 75,
                    "Unlikely": 25,
                    "Very Likely": 100,
                    "Very Unlikely": 0
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Very Unlikely": 0
                        },
                        "time": "2024-09-19T15:24:51.261Z"
                    }
                ]
            },
            {
                "questionID": "Q48263649",
                "question": "Do you feel your work is valued and appreciated by your supervisor?",
                "options": {
                    "Always": 100,
                    "Never": 0,
                    "Often": 75,
                    "Rarely": 25,
                    "Sometimes": 50
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Never": 0
                        },
                        "time": "2024-09-19T15:24:51.273Z"
                    }
                ]
            },
            {
                "questionID": "Q75763649",
                "question": "How often do you feel proud of the work you do?",
                "options": {
                    "Always": 100,
                    "Never": 0,
                    "Often": 75,
                    "Rarely": 25,
                    "Sometimes": 50
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Never": 0
                        },
                        "time": "2024-09-19T15:24:51.284Z"
                    }
                ]
            },
            {
                "questionID": "Q73203649",
                "question": "How would you rate the overall work environment at our company?",
                "options": {
                    "Excellent": 100,
                    "Fair": 50,
                    "Good": 75,
                    "Poor": 25,
                    "Very Poor": 0
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Very Poor": 0
                        },
                        "time": "2024-09-19T15:24:51.297Z"
                    }
                ]
            },
            {
                "questionID": "Q28703649",
                "question": "Do you feel you have the necessary resources and tools to do your job effectively?",
                "options": {
                    "Always": 100,
                    "Never": 0,
                    "Often": 75,
                    "Rarely": 25,
                    "Sometimes": 50
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Never": 0
                        },
                        "time": "2024-09-19T15:24:51.308Z"
                    }
                ]
            },
            {
                "questionID": "Q76193649",
                "question": "How comfortable are you with the physical working conditions (e.g., office space, equipment)?",
                "options": {
                    "Neutral": 50,
                    "Comfortable": 75,
                    "Uncomfortable": 25,
                    "Very Comfortable": 100,
                    "Very Uncomfortable": 0
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Very Uncomfortable": 0
                        },
                        "time": "2024-09-19T15:24:51.317Z"
                    }
                ]
            },
            {
                "questionID": "Q48933649",
                "question": "How would you describe your relationship with your immediate supervisor?",
                "options": {
                    "Excellent": 100,
                    "Fair": 50,
                    "Good": 75,
                    "Poor": 25,
                    "Very Poor": 0
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Very Poor": 0
                        },
                        "time": "2024-09-19T15:24:51.330Z"
                    }
                ]
            },
            {
                "questionID": "Q40023649",
                "question": "How would you describe your relationship with your colleagues?",
                "options": {
                    "Excellent": 100,
                    "Fair": 50,
                    "Good": 75,
                    "Poor": 25,
                    "Very Poor": 0
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Very Poor": 0
                        },
                        "time": "2024-09-19T15:24:51.341Z"
                    }
                ]
            },
            {
                "questionID": "Q36793649",
                "question": "Do you feel your supervisor supports your professional development?",
                "options": {
                    "Always": 100,
                    "Never": 0,
                    "Often": 75,
                    "Rarely": 25,
                    "Sometimes": 50
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Never": 0
                        },
                        "time": "2024-09-19T15:24:51.352Z"
                    }
                ]
            },
            {
                "questionID": "Q74633649",
                "question": "How would you rate your work-life balance?",
                "options": {
                    "Excellent": 100,
                    "Fair": 50,
                    "Good": 75,
                    "Poor": 25,
                    "Very Poor": 0
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Very Poor": 0
                        },
                        "time": "2024-09-19T15:24:51.363Z"
                    }
                ]
            },
            {
                "questionID": "Q55343649",
                "question": "How often do you feel overwhelmed by your work?",
                "options": {
                    "Always": 0,
                    "Never": 100,
                    "Often": 25,
                    "Rarely": 75,
                    "Sometimes": 50
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Always": 0
                        },
                        "time": "2024-09-19T15:24:51.374Z"
                    }
                ]
            },
            {
                "questionID": "Q58133649",
                "question": "Do you feel the company supports a healthy work-life balance?",
                "options": {
                    "Always": 100,
                    "Never": 0,
                    "Often": 75,
                    "Rarely": 25,
                    "Sometimes": 50
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Never": 0
                        },
                        "time": "2024-09-19T15:24:51.385Z"
                    }
                ]
            },
            {
                "questionID": "Q54103649",
                "question": "How effective is communication within your team?",
                "options": {
                    "Neutral": 50,
                    "Effective": 75,
                    "Ineffective": 25,
                    "Very Effective": 100,
                    "Very Ineffective": 0
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Very Ineffective": 0
                        },
                        "time": "2024-09-19T15:24:51.397Z"
                    }
                ]
            },
            {
                "questionID": "Q42163649",
                "question": "How effective is communication between different departments?",
                "options": {
                    "Neutral": 50,
                    "Effective": 75,
                    "Ineffective": 25,
                    "Very Effective": 100,
                    "Very Ineffective": 0
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Very Ineffective": 0
                        },
                        "time": "2024-09-19T15:24:51.409Z"
                    }
                ]
            },
            {
                "questionID": "Q91513649",
                "question": "Do you feel well-informed about company news and updates?",
                "options": {
                    "Always": 100,
                    "Never": 0,
                    "Often": 75,
                    "Rarely": 25,
                    "Sometimes": 50
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Never": 0
                        },
                        "time": "2024-09-19T15:24:51.421Z"
                    }
                ]
            },
            {
                "questionID": "Q83243649",
                "question": "Do you feel recognized for your achievements at work?",
                "options": {
                    "Always": 100,
                    "Never": 0,
                    "Often": 75,
                    "Rarely": 25,
                    "Sometimes": 50
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Never": 0
                        },
                        "time": "2024-09-19T15:24:51.431Z"
                    }
                ]
            },
            {
                "questionID": "Q44673649",
                "question": "How satisfied are you with the company’s recognition and reward programs?",
                "options": {
                    "Very Satisfied": 100,
                    "Satisfied": 75,
                    "Neutral": 50,
                    "Unsatisfied": -25,
                    "Very Unsatisfied": 0
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Unsatisfied": -25
                        },
                        "time": "2024-09-19T15:24:51.442Z"
                    }
                ]
            },
            {
                "questionID": "Q68773649",
                "question": "How would you rate your overall morale at work?",
                "options": {
                    "Neutral": 50,
                    "High": 75,
                    "Low": 25,
                    "Very High": 100,
                    "Very Low": 0
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Very Low": 0
                        },
                        "time": "2024-09-19T15:24:51.455Z"
                    }
                ]
            }
        ],
        "__v": 0
    },
    {
        "description": "The company is conducting a company-wide eNPS (Employee Net Promoter Score) survey to assess employee satisfaction and engagement. This survey will measure how likely employees are to recommend the company as a great place to work. The feedback gathered will help identify strengths and areas for improvement, enabling the company to enhance the work environment, boost morale, and ensure it continues to attract and retain top talent",
        "status": "running",
        "targetGroup": "",
        "frequency": 1,
        "_id": "66ec048b2772bbd4fd3cc4ef",
        "companyID": "64c8025269749c001af4dee0",
        "surveyName": "eNPS Survey",
        "surveyType": "eNPS",
        "questions": [
            {
                "questionID": "Q71703649",
                "question": "On a scale of 0-10, how likely are you to recommend us as a place to work?",
                "response": [],
                "type": "rating"
            }
        ],
        "__v": 0
    },
    {
        "description": "The company is conducting an employee engagement survey to gain insights into how connected, motivated, and satisfied employees feel in their roles. The goal is to understand the level of engagement across the organization and identify key drivers of productivity, job satisfaction, and retention. By gathering this feedback, the company aims to address any challenges, improve the overall work experience, and create a more supportive and motivating workplace where employees can thrive.",
        "status": "running",
        "targetGroup": "",
        "frequency": 1,
        "_id": "66ec048c2772bbd4fd3cc4f1",
        "companyID": "64c8025269749c001af4dee0",
        "surveyName": "Engagement Survey",
        "surveyType": "Engagement",
        "questions": [
            {
                "questionID": "Q54453649",
                "question": "I know what is expected of me at work.",
                "options": {
                    "Yes": 100,
                    "No": 0
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Yes": 100
                        },
                        "time": "2024-09-20T11:17:24.638Z"
                    }
                ]
            },
            {
                "questionID": "Q71943649",
                "question": "I have the materials and equipment I need to do my job effectively.",
                "options": {
                    "Yes": 100,
                    "No": 0
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "No": 0
                        },
                        "time": "2024-09-20T11:17:24.668Z"
                    }
                ]
            },
            {
                "questionID": "Q41453649",
                "question": "I have the opportunity to do what I do best at work every day.",
                "options": {
                    "Yes": 100,
                    "No": 0
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Yes": 100
                        },
                        "time": "2024-09-20T11:17:24.680Z"
                    }
                ]
            },
            {
                "questionID": "Q62523649",
                "question": "In the past week, I have received recognition or praise for doing good work.",
                "options": {
                    "Yes": 100,
                    "No": 0
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "No": 0
                        },
                        "time": "2024-09-20T11:17:24.691Z"
                    }
                ]
            },
            {
                "questionID": "Q41373649",
                "question": "My supervisor, or someone at work, genuinely cares about me as a person",
                "options": {
                    "Yes": 100,
                    "No": 0
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Yes": 100
                        },
                        "time": "2024-09-20T11:17:24.702Z"
                    }
                ]
            },
            {
                "questionID": "Q11333649",
                "question": "There is someone at work who encourages my professional development",
                "options": {
                    "Yes": 100,
                    "No": 0
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Yes": 100
                        },
                        "time": "2024-09-20T11:17:24.713Z"
                    }
                ]
            },
            {
                "questionID": "Q79443649",
                "question": "At work, my opinions are valued.",
                "options": {
                    "Yes": 100,
                    "No": 0
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Yes": 100
                        },
                        "time": "2024-09-20T11:17:24.723Z"
                    }
                ]
            },
            {
                "questionID": "Q40943649",
                "question": "The mission or purpose of my company makes me feel that my job is important.",
                "options": {
                    "Yes": 100,
                    "No": 0
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Yes": 100
                        },
                        "time": "2024-09-20T11:17:24.735Z"
                    }
                ]
            },
            {
                "questionID": "Q99423649",
                "question": "My colleagues are committed to doing quality work.",
                "options": {
                    "Yes": 100,
                    "No": 0
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Yes": 100
                        },
                        "time": "2024-09-20T11:17:24.746Z"
                    }
                ]
            },
            {
                "questionID": "Q28533649",
                "question": "I have a best friend at work.",
                "options": {
                    "Yes": 100,
                    "No": 0
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Yes": 100
                        },
                        "time": "2024-09-20T11:17:24.757Z"
                    }
                ]
            },
            {
                "questionID": "Q25803649",
                "question": "In the past six months, someone at work has talked to me about my progress.",
                "options": {
                    "Yes": 100,
                    "No": 0
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Yes": 100
                        },
                        "time": "2024-09-20T11:17:24.770Z"
                    }
                ]
            },
            {
                "questionID": "Q64313649",
                "question": "In the past year, I have had opportunities to learn and grow at work.",
                "options": {
                    "Yes": 100,
                    "No": 0
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Yes": 100
                        },
                        "time": "2024-09-20T11:17:24.781Z"
                    }
                ]
            },
            {
                "questionID": "Q48373649",
                "question": "How satisfied are you with your company as a place to work?",
                "options": {
                    "Neutral": 50,
                    "Satisfied": 75,
                    "Unsatisfied": 25,
                    "Very Satisfied": 100,
                    "Very Unsatisfied": 0
                },
                "type": "radiogroup",
                "response": [
                    {
                        "email": "MovieMania_73@scanta.io",
                        "answer": {
                            "Unsatisfied": 25
                        },
                        "time": "2024-09-20T11:17:24.791Z"
                    }
                ]
            }
        ],
        "__v": 0
    },
    {
        "description": "The company is conducting a retention risk survey to identify factors that may influence employees' likelihood of leaving the organization. The survey aims to understand potential risks related to job satisfaction, career growth, workload, and work-life balance. By gathering this feedback, the company can proactively address concerns, improve retention strategies, and create a more supportive environment that encourages long-term employee commitment and growth",
        "status": "available",
        "targetGroup": "",
        "frequency": 1,
        "_id": "66ec048c2772bbd4fd3cc4f3",
        "companyID": "64c8025269749c001af4dee0",
        "surveyName": "Retention Survey",
        "surveyType": "Retention",
        "questions": [
            {
                "questionID": "Q78993649",
                "question": "On a scale of 1-10, how satisfied are you with your current job?",
                "type": "rating",
                "response": []
            },
            {
                "questionID": "Q80483649",
                "question": "Do you feel there are sufficient opportunities for growth and advancement in your role?",
                "options": {
                    "Yes": 100,
                    "No": 0
                },
                "type": "radiogroup",
                "response": []
            },
            {
                "questionID": "Q83743649",
                "question": "On a scale of 1-10, how would you rate your work-life balance?",
                "type": "rating",
                "response": []
            },
            {
                "questionID": "Q61103649",
                "question": "Do you feel you have the flexibility to manage your work and personal life effectively?",
                "options": {
                    "Yes": 100,
                    "No": 0
                },
                "type": "radiogroup",
                "response": []
            },
            {
                "questionID": "Q38253649",
                "question": "Do you feel recognized and appreciated for your contributions at work?",
                "options": {
                    "Yes": 100,
                    "No": 0
                },
                "type": "radiogroup",
                "response": []
            },
            {
                "questionID": "Q44603649",
                "question": "On a scale of 1-10, how would you rate the support you receive from your direct manager?",
                "type": "rating",
                "response": []
            },
            {
                "questionID": "Q58573649",
                "question": "Do you feel that management communicates effectively and transparently?",
                "options": {
                    "Yes": 100,
                    "No": 0
                },
                "type": "radiogroup",
                "response": []
            },
            {
                "questionID": "Q91703649",
                "question": "How likely are you to look for a new job within the next six months? ",
                "options": {
                    "Very Unlikely": 100,
                    "Unlikely": 75,
                    "Neutral": 50,
                    "Likely": 25,
                    "Very Likely": 0
                },
                "type": "radiogroup",
                "response": []
            },
            {
                "questionID": "Q64983649",
                "question": "Do you feel challenged and engaged by your work?",
                "options": {
                    "Yes": 100,
                    "No": 0
                },
                "type": "radiogroup",
                "response": []
            },
            {
                "questionID": "Q63123649",
                "question": "On a scale of 1-10, how satisfied are you with your compensation and benefits?",
                "type": "rating",
                "response": []
            },
            {
                "questionID": "Q46593649",
                "question": "Do you feel that the company’s culture aligns with your values? ",
                "options": {
                    "Yes": 100,
                    "No": 0
                },
                "type": "radiogroup",
                "response": []
            },
            {
                "questionID": "Q50483649",
                "question": "On a scale of 1-10, how would you rate your overall work environment?",
                "type": "rating",
                "response": []
            }
        ],
        "__v": 0
    }
]