import React from "react";
import PeopleIcon from "@mui/icons-material/People";
import MessageIcon from "@mui/icons-material/Message";

function StatsOnTrendline({ textCount, userCount }) {
  return (
    <div style={{ display: "flex" }}>
      <span style={{ color: "#484848", display: "flex", margin: "0.5rem" }}>
        <PeopleIcon />
        <p style={{ margin: "0.1rem 0.2rem" }}>{userCount}</p>
      </span>
      <span style={{ color: "#484848", display: "flex", margin: "0.5rem" }}>
        <MessageIcon />
        <p style={{ margin: "0.1rem 0.2rem" }}>{textCount}</p>
      </span>
    </div>
  );
}

export default StatsOnTrendline;
