import update from "immutability-helper";
import { useCallback, useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@material-ui/icons/Search";
import _ from "lodash";
import { Card } from "./Card.js";
const style = {
  // width: 200,
  marginTop: "0",
};
export const Container = ({ cards, setCards, workflowChangeHandler }) => {
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);
  const filters = useSelector((data) => data.dashboard.filters);
  const dispatch = useDispatch();

  const [selected, setSelected] = useState("");

  const keyTopicData =
    JSON.parse(localStorage.getItem("keyTopicData")) !== null
      ? JSON.parse(localStorage.getItem("keyTopicData"))
      : [];

  // const [cards, setCards] = useState(keyTopicData);

  const handleChange = (value) => {
    if (filters.keyTopic[0] !== value) {
      dispatch({
        type: "SET_FILTERS",
        payload: { ...filters, keyTopic: [value] },
      });
    } else {
      // setSelected("");
      dispatch({
        type: "SET_FILTERS",
        payload: { ...filters, keyTopic: [""] },
      });
    }
  };
  // const [moved, setMoved] = useState(0)
  const moveCard = useCallback((dragIndex, hoverIndex) => {
    const temp = (prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      });
    // setMoved(moved + 1)
    setCards(temp);
    // window.location.reload()
  }, []);
  const renderCard = useCallback((card, index) => {
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        text={card.text}
        moveCard={moveCard}
        deleteTopic={deleteTopic}
        handleChange={handleChange}
        selected={selected}
        workflowChangeHandler={workflowChangeHandler}
      />
    );
  }, []);

  const handleSearchTextChange = (value) => {
    const tempData = keyTopicData;
    tempData.push({ id: keyTopicData.length + 1, text: value });
    setCards(_.uniqBy(tempData, "text"));
    localStorage.setItem(
      "keyTopicData",
      JSON.stringify(_.uniqBy(tempData, "text"))
    );
    // setSelected(value);
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, keyTopic: value },
    });
  };
  const [deleteHitted, setDeleteHitted] = useState([]);
  useEffect(() => {
    // deleteHitted.length > 0 && window.location.reload()
  }, [deleteHitted]);

  const afterDelete = (temp) => {
    const keyTopic =
      JSON.parse(localStorage.getItem("keyTopicData")) !== null
        ? JSON.parse(localStorage.getItem("keyTopicData"))
        : [];
    setCards(temp);
  };

  const deleteTopic = (topic) => {
    const keyTopic =
      JSON.parse(localStorage.getItem("keyTopicData")) !== null
        ? JSON.parse(localStorage.getItem("keyTopicData"))
        : [];
    const temp = keyTopic.filter((item) => item.text !== topic);
    localStorage.setItem("keyTopicData", JSON.stringify(temp));
    setDeleteHitted(temp);
    setTimeout(() => {
      afterDelete(temp);
    }, 1000);
    setCards(temp);
  };

  return (
    <>
      <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div>
    </>
  );
};
