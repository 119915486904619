import React, { useState, useEffect, createRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
// import "./index.css";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Chart from "react-apexcharts";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@material-ui/core";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { useScreenshot } from "use-react-screenshot";
import * as clipboard from "clipboard-polyfill";
import moment from "moment";
import { base64StringToBlob } from "blob-util";
import {
  demoOverallCultureData,
  generateDemoCultureData,
} from "../../demoData";
import EventDrop from "../../../../common/Annotation/EventDrop";
import StatsOnTrendline from "../../../../common/StatsOnTrendline";

function SubCultureTrend({
  currentWorkflowID,
  isZoom,
  positiveCultureData,
  currentFilter,
  customDate,
  subculture,
}) {
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);
  const totalText = useSelector((data) => data.dashboard.totalText);
  const teamSize = useSelector((data) => data.dashboard.teamSize);
  const [clipProcessing, setClipProcessing] = useState(false);
  async function clip(b64Data) {
    const item = new clipboard.ClipboardItem({
      "image/png": base64StringToBlob(b64Data.split(",")[1], "image/png"),
    });

    await clipboard.write([item]);
  }
  const moraleTrendRef = createRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const getImage = async () => {
    setClipProcessing(true);
    await takeScreenshot(moraleTrendRef.current);
    setClipProcessing(false);
    image && clip(image);
  };

  useEffect(() => {
    image && clip(image);
  }, [image]);

  const filters = useSelector((data) => data.dashboard.filters);
  const stateCultureData = useSelector(
    (data) => data.dashboard.overallCultureData
  );
  const overallCultureData = useMemo(() => {
    return isDemo
      ? generateDemoCultureData(currentFilter, customDate)
      : stateCultureData;
  }, [isDemo, currentFilter, customDate, stateCultureData]);

  const [isLoading, setIsLoading] = useState(true);

  const [positiveTrend, setPositiveTrend] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const [smallNumber, setSmallNumber] = useState(0);
  useEffect(() => {
    setSmallNumber(getRandomInt(-10, 10));
  }, [filters]);

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  useEffect(() => {
    var tempHateData = [];
    tempHateData = overallCultureData?.cultureData
      ?.filter((item) => new Date(item.id) < new Date())
      ?.sort((a, b) => (new Date(a.id) > new Date(b.id) ? 1 : -1))
      .map((item) => {
        return {
          y: isDemo ? item.score + smallNumber : item.score,
          x: new Date(item.id),
          color: "#3A53BC",
          markerBorderColor: "#3A53BC",
        };
      });
    setPositiveTrend(tempHateData);
  }, [overallCultureData, filters]);
  const goals = useSelector((data) => data.dashboard.goals);
  const publicHolidays = useSelector((data) => data.dashboard.publicHolidays);
  const calenderEvents = useSelector((data) => data.dashboard.calenderEvents);
  const publicHolidaySwitch = useSelector(
    (data) => data.dashboard.publicHolidaySwitch
  );
  const [annotations, setAnnotations] = useState([]);

  // Add Graph annotations for public holidays
  useEffect(() => {
    const temp = [];
    publicHolidaySwitch &&
      publicHolidays.forEach((holiday) => {
        temp.push({
          x: new Date(holiday.date).getTime(),
          borderColor: "#d06283",
          label: {
            borderRadius: 4,
            position: "bottom",
            style: {
              color: "#fff",
              opacity: "0.3",
              fontSize: "13px",
              background: "rgba(208, 98, 131, 0.8)",
            },
            text: holiday.name,
          },
        });
      });
    calenderEvents.forEach((item) => {
      temp.push({
        x: new Date(item.date).getTime(),
        borderColor: item.color,
        label: {
          borderRadius: 4,
          position: "bottom",
          style: {
            color: "#fff",
            opacity: "0.3",
            fontSize: "13px",
            background: item.color,
          },
          text: item.name,
        },
      });
    });
    setAnnotations(temp);
  }, [publicHolidays, calenderEvents]);

  var options = {
    annotations: {
      xaxis: annotations,
      yaxis: [
        {
          y: goals.culture.individual,
          borderColor: "#135714",
          borderWidth: 3,
          label: {
            borderColor: "#135714",
            borderWidth: 3,
            style: {
              color: "#fff",
              background: "#135714",
            },
            text: `Goal - ${goals.culture.individual}`,
          },
        },
      ],
    },
    chart: {
      type: "area",
      toolbar: {
        show: true,
        tools: {
          download: false, // <== line to add
        },
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#1F3BB3"],
    stroke: {
      show: true,
      curve: "straight",
      lineCap: "butt",
      colors: undefined,
      width: 2,
      dashArray: 0,
    },
    markers: {
      size: [3, 5],
      strokeColors: "#0396FE",
      strokeWidth: 2,
      strokeOpacity: 0.3,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: isNight ? "light" : "dark",
        shadeIntensity: 0.3,
        opacityFrom: 0.7,
        opacityTo: 0.3,
        stops: [0, 90, 100],
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          colors: !isNight ? ["#727272"] : ["#666"],
          cssClass: "apexcharts-yaxis-label",
        },
        formatter: function (value) {
          return moment(value).format("MMM DD yyyy");
        },
      },
    },
    yaxis: {
      min: -100,
      max: 100,
      tickAmount: 7,
      seriesName: "Score",
      axisTicks: {
        show: true,
        color: !isNight ? "#727272" : "#666",
      },
      labels: {
        style: {
          colors: !isNight ? ["#727272"] : ["#666"],
          cssClass: "apexcharts-yaxis-label",
        },
        formatter: function (value) {
          return parseInt(value);
        },
      },
      title: {
        text: "Score",
        style: {
          color: !isNight ? "#727272" : "#666",
          fontSize: "12px",
        },
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      x: {
        show: true,
        format: isZoom ? "HH:mm" : "MM/DD/YYYY",
      },
      y: {
        show: true,
        formatter: function (val) {
          return `${parseInt(val)} Alerts`;
        },
        title: {
          formatter: function (seriesName) {
            return seriesName;
          },
        },
      },
    },
  };

  var series = [
    {
      name: subculture,
      color: "#F05919",
      data: positiveTrend,
    },
  ];

  return (
    <div
      ref={moraleTrendRef}
      style={{
        height: "360px",
        borderRadius: "12px",
        backgroundColor: !isNight ? "#2A2953" : "#fff",
        position: "relative",
      }}
    >
      <div
        style={{
          width: "97.5%",
          position: "relative",
          top: "15px",
          margin: "1rem",
          display: "flex",
          justifyContent: "space-between",
          margin: "1rem",
        }}
      >
        <p
          style={{
            margin: "0",
            textAlign: "left",
            fontWeight: 500,
            color: !isNight ? "#fff" : "#484848",
          }}
        >
          {`${subculture} Trend`}
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                Culture Score Over Time
              </span>
            }
          >
            <InfoIcon
              style={{
                fontSize: "1rem",
                filter: "opacity(0.7)",
                position: "relative",
                top: "2px",
              }}
            />
          </Tooltip>
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                Available Events
              </span>
            }
          >
            <EventDrop />
          </Tooltip>
        </p>
        <div
          style={{
            position: "absolute",
            top: "28px",
            left: "30px",
          }}
        >
          <StatsOnTrendline userCount={teamSize} textCount={totalText} />
        </div>
        <Tooltip
          title={
            <span
              style={{
                fontSize: "0.8rem",
                textAlign: "center",
              }}
            >
              Copy to clipboard
            </span>
          }
        >
          {clipProcessing ? (
            <CircularProgress size={16} color="inherit" />
          ) : (
            <ContentCopyOutlinedIcon
              onClick={getImage}
              style={{
                fontSize: "1.2rem",
                cursor: clipProcessing ? "wait" : "pointer",
              }}
            />
          )}
        </Tooltip>
      </div>
      {overallCultureData.status === "success" || (isDemo && !isLoading) ? (
        <div style={{ marginTop: "4rem" }}>
          <div className="morale-trend-container">
            <Chart
              options={options}
              series={series}
              type="area"
              width="100%"
              height="290px"
            />
          </div>
        </div>
      ) : overallCultureData.status === "fail" || currentWorkflowID === "" ? (
        <div style={{ position: "relative", top: "100px" }}>
          <ErrorOutlineIcon style={{ color: "#94989f" }} />
          <p style={{ margin: "0", color: "#94989f" }}>
            {currentWorkflowID === "" ? "Select a workflow!" : "No Data found!"}
          </p>
        </div>
      ) : (
        <>
          <div style={{ margin: "2rem" }}>
            <Stack spacing={1}>
              <Skeleton
                variant="text"
                width={"100%"}
                height={"40px"}
                style={{ position: "relative", top: "10px" }}
              />
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={"220px"}
                style={{ position: "relative", top: "10px" }}
              />
            </Stack>
          </div>
        </>
      )}
    </div>
  );
}

export default SubCultureTrend;
