import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Tooltip } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import {
  GET_INSIGHTS_THRESHOLD,
  GET_STATUS_BOX,
  GET_STATUS_BOX_SUCCESS,
  SAVE_INSIGHTS_THRESHOLD,
  UPDATE_STATUS_BOX,
} from "../../../../redux/Dashboard/Dashboard.types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function ConfigModal({
  eventBased,
  setEventBased,
  topicBased,
  setTopicBased,
  handleClose,
}) {
  const dispatch = useDispatch();
  const insightThreshold = useSelector(
    (data) => data.dashboard.insightThreshold
  );

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const [moraleThreshold, setMoraleThreshold] = useState(
    insightThreshold.moraleInsights
  );
  const [textEngageThreshold, setTextEngageThreshold] = useState(
    insightThreshold.textEngageInsights
  );
  const [userEngageThreshold, setUserEngageThreshold] = useState(
    insightThreshold.userEngageInsights
  );
  const [retentionThreshold, setRetentionThreshold] = useState(
    insightThreshold.retentionInsights
  );
  const [supporterThreshold, setSupporterThreshold] = useState(
    insightThreshold.supporterInsights
  );
  const [detractorThreshold, setDetractorThreshold] = useState(
    insightThreshold.detractorInsights
  );
  const [positiveSentimentThreshold, setPositiveSentimentThreshold] = useState(
    insightThreshold.positiveInsights
  );
  const [negativeSentimentThreshold, setNegativeSentimentThreshold] = useState(
    insightThreshold.negativeInsights
  );
  const [culturalThreshold, setCulturalThreshold] = useState(
    insightThreshold.culture
  );

  const [interval, setInterval] = useState("monthly");

  useEffect(() => {
    setMoraleThreshold(insightThreshold.moraleInsights);
    setTextEngageThreshold(insightThreshold.textEngageInsights);
    setUserEngageThreshold(insightThreshold.userEngageInsights);
    setRetentionThreshold(insightThreshold.retentionInsights);
    setSupporterThreshold(insightThreshold.supporterInsights);
    setDetractorThreshold(insightThreshold.detractorInsights);
    setPositiveSentimentThreshold(insightThreshold.positiveInsights);
    setNegativeSentimentThreshold(insightThreshold.negativeInsights);
    setCulturalThreshold(insightThreshold.culture);
  }, [insightThreshold]);
  const handleSave = () => {
    dispatch({
      type: SAVE_INSIGHTS_THRESHOLD,
      payload: {
        moraleInsights: parseInt(moraleThreshold),
        userEngageInsights: parseInt(userEngageThreshold),
        textEngageInsights: parseInt(textEngageThreshold),
        retentionInsights: parseInt(retentionThreshold),
        positiveInsights: parseInt(positiveSentimentThreshold),
        negativeInsights: parseInt(negativeSentimentThreshold),
        detractorInsights: parseInt(detractorThreshold),
        supporterInsights: parseInt(supporterThreshold),
        culture: parseInt(culturalThreshold),
      },
    });
    setTimeout(() => {
      dispatch({ type: GET_INSIGHTS_THRESHOLD });
      handleClose();
    }, 2000);
  };
  useEffect(() => {
    dispatch({ type: GET_INSIGHTS_THRESHOLD });
  }, []);

  return (
    <Box sx={style}>
       <Typography id="modal-modal-title" variant="h6" component="h2">
        Settings
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />
      <Box style={{maxHeight:"75vh", overflowY:"scroll"}}>
     
      <hr />
      <Stack direction="row" spacing={2}>
        <div style={{ display: "flex" }}>
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                Use Events in Explaining Key Insights
              </span>
            }
          >
            <Checkbox
              checked={eventBased}
              onChange={(e) => {
                setEventBased(e.target.checked);
              }}
            />
          </Tooltip>
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                Use Events in Explaining Key Insights
              </span>
            }
          >
            <span
              onClick={() => {
                setEventBased(!eventBased);
              }}
              style={{ color: "#484848", margin: "1rem 0 ", cursor: "pointer" }}
            >
              Use Events
            </span>
          </Tooltip>
        </div>
        <div style={{ display: "flex" }}>
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                Use Topics in Explaining Key Insights
              </span>
            }
          >
            <Checkbox
              checked={topicBased}
              onChange={(e) => {
                setTopicBased(e.target.checked);
              }}
            />
          </Tooltip>
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                Use Topics in Explaining Key Insights
              </span>
            }
          >
            <span
              onClick={() => {
                setTopicBased(!eventBased);
              }}
              style={{ color: "#484848", margin: "1rem 0 ", cursor: "pointer" }}
            >
              Use Topics
            </span>
          </Tooltip>
        </div>
      </Stack>
      <div style={{ display: "flex", justifyContent: "end" }}>
        <Button
          onClick={handleSave}
          style={{
            background: "#D06283",
            color: "#fff",
          }}
          variant="contained"
        >
          Set
        </Button>
      </div>
      <br />
      <hr />
      {/* <Typography>Interval</Typography>
      <Box paddingBottom={2}>
        <FormControl fullWidth>
          <Select
            value={interval}
            input={<OutlinedInput sx={{ padding: "0" }} />}
            inputProps={{ "aria-label": "Without label" }}
            onChange={(e) => {
              setInterval(e.target.value);
            }}
            sx={{ padding: "0", fontSize: "0.8rem" }}
          >
            <MenuItem value={"monthly"}>Monthly</MenuItem>
            <MenuItem value={"quarterly"}>Quarterly</MenuItem>
            <MenuItem value={"half-yearly"}>Half-Yearly</MenuItem>
            <MenuItem value={"annually"}>Annually</MenuItem>
          </Select>
        </FormControl>
      </Box> */}
      <Typography style={{ fontSize: "1.1rem" }}>Threshold</Typography>
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Typography style={{ margin: "1rem 0" }}>Morale </Typography>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Overall"
            variant="outlined"
            value={moraleThreshold}
            onChange={(e) => setMoraleThreshold(e.target.value)}
          />
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Single"
            variant="outlined"
            value={moraleThreshold}
            onChange={(e) => setMoraleThreshold(e.target.value)}
          />
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Multiple"
            variant="outlined"
            value={moraleThreshold}
            onChange={(e) => setMoraleThreshold(e.target.value)}
          />
        </Stack>
      </Box>
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Typography style={{ margin: "1rem 0" }}>Engagement </Typography>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Overall"
            variant="outlined"
            value={moraleThreshold}
            onChange={(e) => setMoraleThreshold(e.target.value)}
          />
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Single"
            variant="outlined"
            value={moraleThreshold}
            onChange={(e) => setMoraleThreshold(e.target.value)}
          />
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Multiple"
            variant="outlined"
            value={moraleThreshold}
            onChange={(e) => setMoraleThreshold(e.target.value)}
          />
        </Stack>
      </Box>
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Typography style={{ margin: "1rem 0" }}>Retention Risk </Typography>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Overall"
            variant="outlined"
            value={moraleThreshold}
            onChange={(e) => setMoraleThreshold(e.target.value)}
          />
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Single"
            variant="outlined"
            value={moraleThreshold}
            onChange={(e) => setMoraleThreshold(e.target.value)}
          />
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Multiple"
            variant="outlined"
            value={moraleThreshold}
            onChange={(e) => setMoraleThreshold(e.target.value)}
          />
        </Stack>
      </Box>
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Typography style={{ margin: "1rem 0" }}>eNPS </Typography>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Overall"
            variant="outlined"
            value={moraleThreshold}
            onChange={(e) => setMoraleThreshold(e.target.value)}
          />
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Single"
            variant="outlined"
            value={moraleThreshold}
            onChange={(e) => setMoraleThreshold(e.target.value)}
          />
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Multiple"
            variant="outlined"
            value={moraleThreshold}
            onChange={(e) => setMoraleThreshold(e.target.value)}
          />
        </Stack>
      </Box>
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Typography style={{ margin: "1rem 0" }}>Topic </Typography>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Overall"
            variant="outlined"
            value={moraleThreshold}
            onChange={(e) => setMoraleThreshold(e.target.value)}
          />
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Single"
            variant="outlined"
            value={moraleThreshold}
            onChange={(e) => setMoraleThreshold(e.target.value)}
          />
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Multiple"
            variant="outlined"
            value={moraleThreshold}
            onChange={(e) => setMoraleThreshold(e.target.value)}
          />
        </Stack>
      </Box>
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Typography style={{ margin: "1rem 0" }}>Culture</Typography>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Overall"
            variant="outlined"
            value={moraleThreshold}
            onChange={(e) => setMoraleThreshold(e.target.value)}
          />
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Single"
            variant="outlined"
            value={moraleThreshold}
            onChange={(e) => setMoraleThreshold(e.target.value)}
          />
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Multiple"
            variant="outlined"
            value={moraleThreshold}
            onChange={(e) => setMoraleThreshold(e.target.value)}
          />
        </Stack>
      </Box>
      <Stack direction="row" spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          style={{
            background: "#D06283",
            color: "#fff",
          }}
          variant="contained"
        >
          Save
        </Button>
      </Stack>
      </Box>
    </Box>
  );
}

export default ConfigModal;
