import React, { useState } from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import Modal from "@material-ui/core/Modal";
import { Tooltip } from "@material-ui/core";
import { ICONS } from "../../../../../AppConfig";
import ActionConfigModal from "../../Configuration/ActionConfigModal";

export default function Source() {
  const onDragStart = (event, nodeType, messenger, tabType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.setData("application/messenger", messenger);
    event.dataTransfer.setData("application/tabType", tabType);
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.effectAllowed = "move";
  };

  const [open, setOpen] = useState(false);
  const [selectedAction, setSelectedAction] = useState({
    title: null,
  });

  const handleOpen = (e) => {
    setSelectedAction({ title: e });
    if (selectedAction.title !== null) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <aside>
        <div
          className="dndnode dnd-action"                     
          onDragStart={(event) =>
            onDragStart(event, "default", "Email", "action")
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "#666666" }}
                onClick={() => handleOpen("Email configuration")}
              />
            </Tooltip>
            <img src={ICONS.EMAIL} alt="zoom" syle={{filter:"invert(1)"}}/>
            <p style={{  color:"#666666"}}>Email</p>
          </div>
        </div>
        <div
          className="dndnode dnd-action"
          onDragStart={(event) =>
            onDragStart(event, "default", "Notification", "action")
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "#666666" }}
                onClick={() => handleOpen("Notification")}
              />
            </Tooltip>
            <img src={ICONS.NOTIFICATION} alt="zoom" />
            <p style={{  color:"#666666"}}>Notification</p>
          </div>
        </div>
        <div
          className="dndnode dnd-action"
          onDragStart={(event) =>
            onDragStart(event, "default", "SMS", "action")
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "#666666" }}
                onClick={() => handleOpen("SMS")}
              />
            </Tooltip>
            <img src={ICONS.SMS} alt="zoom" style={{filter:"opacity(0.5)",filter:"invert(0.6)"}}/>
            <p style={{  color:"#666666"}}>SMS</p>
          </div>
        </div>
        <div
          className="dndnode dnd-action"
          onDragStart={(event) =>
            onDragStart(event, "default", "Alert", "action")
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "#666666" }}
                onClick={() => handleOpen("Alert")}
              />
            </Tooltip>
            <img src={ICONS.ALERT} alt="zoom" style={{filter:"opacity(0.5)",filter:"invert(0.6)"}}/>
            <p style={{  color:"#666666"}}>Alert</p>
          </div>
        </div>
        <div
          className="dndnode dnd-action"
          onDragStart={(event) =>
            onDragStart(event, "default", "ServiceNow", "action")
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "#666666" }}
                onClick={() => handleOpen("ServiceNow configuration")}
              />
            </Tooltip>
            <img src={ICONS.SERVICENOW} alt="zoom" width="30px" />
            <p style={{  color:"#666666"}}>ServiceNow</p>
          </div>
        </div>
      </aside>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="email-action-config"
        aria-describedby="email-action-config"
      >
        <ActionConfigModal
          open={open}
          handleClose={handleClose}
          selectedAction={selectedAction}
        />
      </Modal>
    </>
  );
}
