import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import "../../../styles/loginPage.css";
import "../../../styles/profilePage.css";
import TurnedInNotIcon from "@material-ui/icons/TurnedInNot";
import ClearIcon from "@material-ui/icons/Clear";
import PhoneInput from "react-phone-input-2";
import MenuItem from "@material-ui/core/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import countryList from "react-select-country-list";
import InputLabel from "@mui/material/InputLabel";
import { Box } from "@material-ui/core";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  ADD_NEW_USER,
  GET_USERS,
} from "../../../redux/Dashboard/Dashboard.types";
import "../../../styles/profilePage.css";
import Grid from "@mui/material/Grid";

export default function AddUserModal({ handleClose }) {
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  const useStyles = makeStyles((theme) => ({
    root: {
      color: "white !important",
      fontSize: 13,
      border: "none",
      backgroundColor: "#2D2B45",
      fontWeight: "600",
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
      "& div": {
        color: "#fff",
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
      marginTop: 20,
      color: "rgba(0, 0, 0, 0.87)!important",
      borderBlockColor: "#6352C6",
    },
    input: {
      color: "#fff",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    paper: {
      padding: 0,
    },
  }));

  const countryOptions = useMemo(() => countryList().getData(), []);
  const levelOptions = [
    { value: "admin", label: "Admin" },
    { value: "manager", label: "Manager" },
    { value: "employee", label: "Employee" },
  ];
  const deptOptions = [
    { value: "Accounting & Finance", label: "Accounting & Finance" },
    { value: "Engineering", label: "Engineering" },
    { value: "Human Resources", label: "Human Resources" },
    { value: "Information Technology", label: "Information Technology" },
    { value: "Operations", label: "Operations" },
    { value: "Research & Development", label: "Research & Development" },
    { value: " Sales & Marketing", label: " Sales & Marketing" },
  ];
  const [selectedLevel, setSelectedLevel] = useState(null);

  const [selectedDept, setSelectedDept] = useState(null);

  const classes = useStyles();
  const dispatch = useDispatch();

  const [companyName, setCompanyName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  // eslint-disable-next-line
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countryVal, setCountryVal] = useState({});
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const userDetails = useSelector((data) => data.dashboard.userDetails);
  const [directReports, setDirectReports] = useState(false);
  const [overallCompany, setOverallCompany] = useState(false);
  const [filters, SetFilters] = useState(false);
  const [keyInsights, setKeyInsights] = useState(false);
  const [morale, setMorale] = useState(false);
  const [engagement, setEngagement] = useState(false);
  const [retentionRisk, setRetentionRisk] = useState(false);
  const [supporterDetractor, setSupporterDetractor] = useState(false);
  const [topicAnalysis, setTopicAnalysis] = useState(false);
  const [culture, setCulture] = useState(false);

  useEffect(() => {
    setCompanyName(userDetails.company_name);
  }, [userDetails]);

  const handleAddUser = () => {
    if (firstName === "") {
      toast.error("Please fill First Name");
    } else if (lastName === "") {
      toast.error("Please fill Last Name");
    } else if (email === "") {
      toast.error("Please fill Email");
    } else if (phoneNumber === "") {
      toast.error("Please fill Phone Number");
    } else if (!countryVal) {
      toast.error("Please Select Country");
    } else {
      !isDemo &&
        dispatch({
          type: ADD_NEW_USER,
          payload: {
            company_name: companyName,
            company_id: userDetails.company.id,
            first_name: firstName,
            last_name: lastName,
            email,
            phone_number: phoneNumber,
            country: countryVal.value,
            level: selectedLevel.value,
            department: selectedDept.value,
            role: "user",
            access: {
              direct_reports: directReports,
              overall_company: overallCompany,
              filters: filters,
              key_insights: keyInsights,
              morale: morale,
              engagement: engagement,
              retention_risk: retentionRisk,
              supporter_detractor: supporterDetractor,
              topic_analysis: topicAnalysis,
              culture: culture,
            },
          },
        });
      setTimeout(() => {
        !isDemo &&
          dispatch({
            type: GET_USERS,
          });
      }, 1500);
      handleClose();
    }
  };

  return (
    <div classes={{ root: classes.root }} style={{ height: "95vh" }}>
      <div
        className="userForm"
        style={{ background: !isNight ? "#212047" : "#f6f7fc" }}
      >
        <div style={{ padding: "0 20px", margin: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <div className="inputContainer">
                <label className="formLabels form_label">First Name</label>
                <input
                  type="text"
                  name="first-name"
                  value={firstName}
                  onChange={(event) => {
                    setFirstName(event.target.value);
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className="inputContainer">
                <label className="formLabels form_label">Last Name</label>
                <input
                  type="text"
                  name="last-name"
                  value={lastName}
                  onChange={(event) => {
                    setLastName(event.target.value);
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <div className="inputContainer">
                <label className="formLabels form_label">Email</label>
                <input
                  type="email"
                  name="email"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <div className="inputContainer">
                <label className="formLabels form_label" htmlFor="number">
                  Phone Number
                </label>
                <PhoneInput
                  country="in"
                  className="phoneNumber"
                  value={phoneNumber}
                  onChange={(event) => setPhoneNumber(event)}
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              {" "}
              <div id="countryFlag" className="inputContainer">
                <label className="formLabels form_label" htmlFor="number">
                  Country
                </label>
                <div className="countryContainer">
                  <Select
                    isSearchable
                    options={countryOptions}
                    // value={countryVal}
                    onChange={(value) => setCountryVal(value)}
                  />
                </div>
              </div>
            </Grid>
            <Grid item xs={6}>
              <div id="countryFlag" className="inputContainer">
                <label className="formLabels form_label" htmlFor="number">
                  Level
                </label>
                <div className="countryContainer">
                  <Select
                    defaultValue={selectedLevel}
                    onChange={setSelectedLevel}
                    options={levelOptions}
                    sx={{ padding: "0", fontSize: "0.8rem" }}
                  ></Select>
                </div>
              </div>
            </Grid>{" "}
            <Grid item xs={6}>
              {" "}
              <div id="countryFlag" className="inputContainer">
                <label className="formLabels form_label" htmlFor="number">
                  Department
                </label>
                <div className="countryContainer">
                  <Select
                    // onChange={handleChangeLevel}
                    // IconComponent={ExpandMoreIcon}
                    defaultValue={selectedDept}
                    onChange={setSelectedDept}
                    options={deptOptions}
                    sx={{ padding: "0", fontSize: "0.8rem" }}
                  ></Select>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div id="countryFlag" className="inputContainer">
                <label
                  style={{ color: "#000" }}
                  className="formLabels form_label"
                  htmlFor="number"
                >
                  Access
                </label>

                <FormGroup
                  sx={{
                    display: "flex !important",
                    flexDirection: "row !important",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={directReports}
                        onChange={(e) => {
                          setDirectReports(e.target.checked);
                        }}
                      />
                    }
                    label={
                      <span style={{ fontSize: "14px" }}>Direct Reports</span>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={overallCompany}
                        onChange={(e) => {
                          setOverallCompany(e.target.checked);
                        }}
                      />
                    }
                    label={
                      <span style={{ fontSize: "14px" }}>Overall Company</span>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={filters}
                        onChange={(e) => {
                          SetFilters(e.target.checked);
                        }}
                      />
                    }
                    label={<span style={{ fontSize: "14px" }}>Filters</span>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={keyInsights}
                        onChange={(e) => {
                          setKeyInsights(e.target.checked);
                        }}
                      />
                    }
                    label={
                      <span style={{ fontSize: "14px" }}>Key Insights</span>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={morale}
                        onChange={(e) => {
                          setMorale(e.target.checked);
                        }}
                      />
                    }
                    label={<span style={{ fontSize: "14px" }}>Morale</span>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={engagement}
                        onChange={(e) => {
                          setEngagement(e.target.checked);
                        }}
                      />
                    }
                    label={<span style={{ fontSize: "14px" }}>Engagement</span>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={retentionRisk}
                        onChange={(e) => {
                          setRetentionRisk(e.target.checked);
                        }}
                      />
                    }
                    label={
                      <span style={{ fontSize: "14px" }}>Retention Risk</span>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={supporterDetractor}
                        onChange={(e) => {
                          setSupporterDetractor(e.target.checked);
                        }}
                      />
                    }
                    label={
                      <span style={{ fontSize: "14px" }}>
                        Supporter/Detractor
                      </span>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={topicAnalysis}
                        onChange={(e) => {
                          setTopicAnalysis(e.target.checked);
                        }}
                      />
                    }
                    label={
                      <span style={{ fontSize: "14px" }}>Topic Analysis</span>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={culture}
                        onChange={(e) => {
                          setCulture(e.target.checked);
                        }}
                      />
                    }
                    label={<span style={{ fontSize: "14px" }}>Culture</span>}
                  />
                </FormGroup>
              </div>
            </Grid>
          </Grid>
        </div>
        <div>
          <div
            className="btnOptions"
            style={{
              position: "relative",
              bottom: "20px",
              right: "20px",
            }}
          >
            <div className="cancelBtn">
              <div className="rangeBtn" onClick={() => handleClose()}>
                <ClearIcon style={{ color: "#000" }} />
                <div style={{ color: "#000" }} className="btnText">
                  Cancel
                </div>
              </div>
            </div>
            <div className="submitBtn">
              <div className="rangeBtn" type="submit" onClick={handleAddUser}>
                <TurnedInNotIcon />
                <div className="btnText">Add</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
