import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import { Button } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import {
  DELETE_ACTION_PLAN,
  GET_ALL_SURVEYS,
  UPDATE_SURVEY,
} from "../../../../../redux/Dashboard/Dashboard.types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function DeleteActionPlanModal({ plan, handleClose }) {
  const dispatch = useDispatch();

  const deletePlan = () => {
    dispatch({ type: DELETE_ACTION_PLAN, payload: { _id: plan._id } });
    setTimeout(() => {
      handleClose();
    }, 3000);
  };

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Do you want to delete this action plan?
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />

      <Stack direction="row" spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={deletePlan}
          style={{ background: "#D06283", color: "#fff" }}
          variant="contained"
        >
          Delete
        </Button>
      </Stack>
    </Box>
  );
}

export default DeleteActionPlanModal;
