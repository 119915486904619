import React, { useEffect, useState } from "react";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// import DoughnutChart from "./Doughnut";
// import HorizontalBarChart from "./HorizontalChart";
// import LineChart from "./LineChart";
// import VerticalBar from "./VerticalBar";
// import CircleProgress from "./GradientCircle";
// import "../../../styles/";
import { FormGroup, FormControlLabel, Checkbox } from "@material-ui/core/";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { toast } from "react-toastify";
import moment from "moment";

import {
  ADD_CALENDER_EVENT,
  GET_CALENDER_EVENTS,
  SAVE_DEMO_ANNOTATION,
  UPDATE_CALENDER_EVENT,
} from "../../../redux/Dashboard/Dashboard.types";

const ViewAnnotation = ({ handleClose, setCurrentScreen, seletedEvent }) => {
  const dispatch = useDispatch();
  const calenderEventSaved = useSelector(
    (data) => data.dashboard.calenderEventSaved
  );
  const calenderEvents = useSelector((data) => data.dashboard.calenderEvents);

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const [eventName, setEventName] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventColor, setEventColor] = useState("#d06283");
  const [eventDate, setEventDate] = useState(new Date());
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const temp = calenderEvents.filter((item) => item._id === seletedEvent)[0];
    setEventName(temp.name);
    setEventDescription(temp.description);
    setEventDate(temp.date);
    setEventColor(temp.color);
    setVisible(temp.isVisible);
  }, [seletedEvent]);
  return (
    <div
      style={{
        height: "100%",
        margin: "1rem 0.5rem",
        padding: "1rem",
      }}
    >
      {eventName.length > 3 && (
        <div style={{ height: "330px" }}>
          <p>{eventName ? eventName : ""}</p>
          <p>{eventDescription ? eventDescription : ""}</p>
          <p>{eventDate ? eventDate : ""}</p>
          <p>{visible ? "Visible" : "Hidden"}</p>

          <span
            style={{
              width: "20px",
              height: "20px",
              borderRadius: "10px",
              background: eventColor ? eventColor : "#fff",
              position: "relative",
              top: "10px",
              margin: "0.5rem",
            }}
          />
        </div>
      )}

      <div className="btnOptions" style={{ width: "100%" }}>
        <div
          className="cancelBtn"
          onClick={() => {
            setCurrentScreen("list");
          }}
        >
          <div className="rangeBtn">
            <ArrowBackIosIcon style={{ color: "#000" }} />
            <div className="btnText" style={{ color: "#000" }}>
              Back
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAnnotation;
