import React, { useState, useEffect, createRef } from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import { demoWords } from "./demoWords";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ReactWordcloud from "react-wordcloud";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@material-ui/core";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/scale.css";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { useScreenshot } from "use-react-screenshot";
import * as clipboard from "clipboard-polyfill";
import { base64StringToBlob } from "blob-util";

function Wordcloud({ words, currentFilter, customDate, currentWorkflowID }) {
  const dispatch = useDispatch();

  const [clipProcessing, setClipProcessing] = useState(false);

  async function clip(b64Data) {
    const item = new clipboard.ClipboardItem({
      "image/png": base64StringToBlob(b64Data.split(",")[1], "image/png"),
    });

    await clipboard.write([item]);
  }

  const wordCloudRef = createRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const getImage = async () => {
    setClipProcessing(true);
    await takeScreenshot(wordCloudRef.current);
    setClipProcessing(false);
    image && clip(image);
  };

  useEffect(() => {
    image && clip(image);
  }, [image]);

  const wordCloudData = useSelector((data) => data.dashboard.wordcloudData);

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const filters = useSelector((data) => data.dashboard.filters);
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  const options = {
    colors: ["#2D7044", "#9467BD", "#FF7F0E", "#1F77B4", "#8C564B", "#D06283"],
    enableTooltip: true,
    deterministic: false,
    fontFamily: "Poppins",
    fontSizes: [25, 70],
    fontStyle: "normal",
    fontWeight: 400,
    padding: 5,
    rotations: 2,
    rotationAngles: [0, 90],
    scale: "sqrt",
    spiral: "archimedean",
    transitionDuration: 1000,
  };

  const callbacks = {
    getWordTooltip: (word) =>
      `The word "${word.text}" appears ${word.value} times.`,
  };

  function getMultipleRandom(arr, num) {
    const shuffled = [...arr].sort(() => 0.5 - Math.random());

    return shuffled.slice(0, num);
  }

  const [testWords, setTestWords] = useState([]);

  useEffect(() => {
    setTestWords(getMultipleRandom(demoWords, 60));
  }, [filters.keyTopic]);
  return (
    <div>
      <div
        ref={wordCloudRef}
        style={{
          minHeight: "450px",
          borderRadius: "12px",
          backgroundColor: !isNight ? "#2A2953" : "#fff",
          margin: "20px 0 0 0.5rem",
          padding: "0 1rem",
        }}
      >
        <div
          style={{
            width: "98%",
            position: "relative",
            top: "15px",
            margin: "1rem",
            display: "flex",
            justifyContent: "space-between",
            margin: "1rem",
          }}
        >
          <p
            style={{
              margin: "0",
              textAlign: "left",
              fontWeight: 500,
              color: !isNight ? "#fff" : "#484848",
            }}
          >
            Topic Word Cloud
            <Tooltip
              title={
                <span
                  style={{
                    fontSize: "0.8rem",
                    textAlign: "center",
                  }}
                >
                  Most Frequently Used Words in Messages on a Specific Topic
                </span>
              }
            >
              <InfoIcon
                style={{
                  fontSize: "1rem",
                  filter: "opacity(0.7)",
                  position: "relative",
                  top: "3px",
                }}
              />
            </Tooltip>
          </p>
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                Copy to clipboard
              </span>
            }
          >
            {clipProcessing ? (
              <CircularProgress size={16} color="inherit" />
            ) : (
              <ContentCopyOutlinedIcon
                onClick={getImage}
                style={{
                  fontSize: "1.2rem",
                  cursor: clipProcessing ? "wait" : "pointer",
                }}
              />
            )}
          </Tooltip>
        </div>
        <div style={{ position: "relative", top: "50px" }}>
          {(!isLoading && isDemo) || words.length > 2 ? (
            <>
              <ReactWordcloud
                callbacks={callbacks}
                options={options}
                words={isDemo ? testWords : words}
              />
            </>
          ) : currentWorkflowID === "" ? (
            <div style={{ position: "relative", top: "120px" }}>
              <ErrorOutlineIcon style={{ color: "#94989f" }} />
              <p style={{ margin: "0", color: "#94989f" }}>
                {currentWorkflowID === ""
                  ? "Select a workflow!"
                  : "No Data found!"}
              </p>
            </div>
          ) : (
            <Stack spacing={1}>
              <Skeleton
                variant="text"
                width={"90%"}
                height={"40px"}
                style={{ position: "relative", top: "-30px", margin: "0 auto" }}
              />
              <Skeleton
                variant="rectangular"
                width={"90%"}
                height={"280px"}
                style={{ position: "relative", top: "-20px", margin: "0 auto" }}
              />
            </Stack>
          )}
        </div>
      </div>
    </div>
  );
}

export default Wordcloud;
