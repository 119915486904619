export const msalConfig = {
  auth: {
    // clientId: "67798ada-b63c-438f-a9d6-6229ded03486",
    clientId: "765b9d7c-c6b3-49e5-8511-a63b3a7c6397",
    // authority: "Enter_the_Cloud_Instance_Id_Here/Enter_the_Tenant_Info_Here", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    // redirectUri: "https://trupulse.scanta.io/",
    redirectUri: "https://dashboard.trupulse.ai/",
  }, 
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [
    "User.Read Team.ReadBasic.All Application.Read.All Channel.ReadBasic.All ChannelMessage.Read.All User.Read.All",
  ],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
// export const graphConfig = {
//   graphMeEndpoint: "Enter_the_Graph_Endpoint_Here/v1.0/me",
// };
