import React from "react";

const SourceTutModal = ({ handleClose, setNextOpen, nextOpen }) => {

  const setNextOpenTrue = () => {
    setNextOpen(true);
  }

  const handleNextStep = () => {
    setNextOpenTrue();
    handleClose();
  }

  const handleSkip = () => {
    handleClose();
    localStorage.setItem('skipped', true);
  }

  return (
    <>
      <div style={{
          width: "0",
          height: "0",
          borderLeft: "5px solid transparent",
          borderRight: "5px solid transparent",
          borderBottom: "10px solid #1b1834",
          position: "absolute",
          marginTop: "150px",
          left: "2%",
        }}>
      </div>
      <div
        className="addChartsContainer"
        style={{
          width: "24vw",
          marginTop: "160px",
          position: "absolute",
          left: "0.5%",
        }}
      >
        <div style={{ color: "#fff", fontSize: "1rem" }}>Source</div>
        <p style={{ color: "#ddd"}}>Click on Source tab to select a source</p>
        <div className="btnOptions" style={{ marginTop: "0px" }}>
          <div className="submitBtn">
            <div
              className="rangeBtn"
              type="submit"
              onClick={handleNextStep} >
              <div className="btnText">NEXT >></div>
            </div>
          </div>
          <div className="submitBtn">
            <div
              className="rangeBtn"
              type="submit"
              onClick={handleSkip} >
              <div className="btnText">SKIP >>>></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SourceTutModal;
