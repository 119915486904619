import { combineReducers } from "redux";
import LoginReducer from "./Login/Login.reducer";
import DashboardReducer from "./Dashboard/Dashboard.reducer";
import OverviewReducer from "./Overview/Overview.reducer";
import LogicReducer from "./Logic/Logic.reducer";
import OtherReducer from "./Other/Other.reducer";



const rootReducer = combineReducers({
  login: LoginReducer,
  dashboard: DashboardReducer,
  overview:OverviewReducer,
  logic:LogicReducer,
  other: OtherReducer
});

export default rootReducer;
