import React, { useState, useEffect } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { useDispatch, useSelector } from "react-redux";
import DialogContent from "@material-ui/core/DialogContent";
import CheckIcon from "@material-ui/icons/Check";
import { DataGrid } from "@mui/x-data-grid";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import * as XLSX from "xlsx";
import Stack from "@mui/material/Stack";
import TeamsImport from "./TeamsImport";
import { CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import CSVReader from "react-csv-reader";
import {
  SAVE_HRIS_USERS,
  GET_HRIS_USERS,
} from "../../../redux/Dashboard/Dashboard.types";
import { Box } from "@material-ui/core";
import HrisModal from "./Configurations/HrisModal";
import CloseIcon from "@mui/icons-material/Close";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Hrisheaders = [
  { label: "userID", key: "userID" },
  { label: "User Name", key: "username" },
  { label: "Email", key: "email" },
  { label: "gender", key: "gender" },
  { label: "Age", key: "age" },
  { label: "ethnicity", key: "ethnicity" },
  { label: "marital_status", key: "marital_status" },
  { label: "department", key: "department" },
  { label: "region", key: "region" },
  { label: "location", key: "location" },
  { label: "experience", key: "experience" },
  { label: "management_level", key: "management_level" },
  { label: "income", key: "income" },
  { label: "joining_date", key: "joining_date" },
  { label: "exit_date", key: "exit_date" },
];

const Hrisdata = [
  {
    userID: "U02B5bf859b",
    username: "MusicJunkie_99",
    email: "MusicJunkie_99@scanta.io",
    gender: "male",
    age: 32,
    ethnicity: "Hispanic",
    marital_status: "single",
    department: "Research & Development",
    region: "Europe",
    location: "Home Office",
    experience: 10,
    management_level: "Individual Contributor",
    income: "196342",
    joining_date: "2012-06-13",
    exit_date: "",
  },
];
const useStyles = makeStyles((theme) => ({
  root: {
    color: "#fff",
    padding: "0!important",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    "& div": {
      color: "#fff",
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    marginTop: 20,
    // color: "rgba(0, 0, 0, 0.87)!important",
    borderBlockColor: "#6352C6",
  },
  input: {
    color: "#fff",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    padding: 0,
  },
}));

function ImportModal({ open, handleClose }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [users, setUsers] = useState([]);

  const [currentScreen, setCurrentScreen] = useState("choose");

  const handleCSV = () => {
    dispatch({
      type: SAVE_HRIS_USERS,
      payload: { userDetails: users },
    });
    handleClose();
  };

  const processCSV = (data) => {
    const userDetails = [];
    for (let i = 1; i < data.length; i++) {
      const temp = {
        gender: data[i][3],
        department: data[i][7],
        age: data[i][4],
        region: data[i][8],
        location: data[i][9],
        experience: data[i][10],
        management_level: data[i][11],
        ethnicity: data[i][5],
        marital_status: data[i][6],
        income: data[i][12],
        email: data[i][2],
        joining_date: data[i][13],
        userName: data[i][1],
        userID: data[i][0],
        exit_date: data[i][14],
      };
      userDetails.push(temp);
    }
    setUsers(userDetails);
  };
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  return (
    <Box sx={style}>
      <div className="popUpTitle" style={{ marginBottom: "15px" }}>
        Import Employees Data
      </div>
      {currentScreen === "choose" && (
        <Box style={{ width: "100%", margin: "2rem 0 4rem" }}>
          <Stack direction="row" spacing={2} justifyContent="space-around">
            <div
              style={{
                padding: "0 1rem",
              }}
            >
              <p style={{ color: "#484848" }}>Connect your HRIS</p>
              <div
                className="submitBtn"
                style={{ width: "100px", margin: "auto" }}
                onClick={() => {
                  setCurrentScreen("hris");
                }}
              >
                <div
                  className="btnText"
                  style={{ textAlign: "center", padding: "0.3rem" }}
                >
                  Connect
                </div>
              </div>
            </div>
            <div style={{ padding: "0 1rem" }}>
              <p style={{ color: "#484848" }}>Upload File</p>
              <div
                className="submitBtn"
                style={{ width: "100px", margin: "auto" }}
                onClick={() => {
                  setCurrentScreen("file");
                }}
              >
                <div
                  className="btnText"
                  style={{ textAlign: "center", padding: "0.3rem" }}
                >
                  Upload
                </div>
              </div>
            </div>
          </Stack>
        </Box>
      )}
      {currentScreen === "hris" && <HrisModal handleClose={handleClose} />}
      {currentScreen === "file" && (
        <>
          <p style={{ color: "#484848" }}>
            Please upload the csv file with HRIS data.
          </p>
          <CSVLink
            data={Hrisdata}
            headers={Hrisheaders}
            filename={"sample-hris-data.csv"}
            style={{
              color: "#484848",
              textDecoration: "none",
              margin: "0 0 10px",
            }}
          >
            <DownloadIcon
              style={{
                fontSize: "1rem",
                position: "relative",
                top: "4px",
              }}
            />
            Download Sample
          </CSVLink>
          <CSVReader
            onFileLoaded={(data, fileInfo, originalFile) => processCSV(data)}
          />

          <div className="btnOptions">
            <div
              className="cancelBtn"
              onClick={() => {
                setCurrentScreen("choose");
              }}
            >
              <div className="rangeBtn">
                <ArrowBackIosIcon style={{ color: "#484848" }} />
                <div className="btnText" style={{ color: "#484848" }}>
                  Back
                </div>
              </div>
            </div>
            <div className="submitBtn" onClick={handleCSV}>
              <div className="rangeBtn" type="submit">
                <CheckIcon />
                <div className="btnText">Save</div>
              </div>
            </div>
          </div>
        </>
      )}
      {currentScreen === "choose" && (
        <div className="btnOptions">
          <div className="cancelBtn" onClick={handleClose}>
            <div className="rangeBtn">
              <ClearIcon style={{ color: "#484848" }} />
              <div className="btnText" style={{ color: "#484848" }}>
                Cancel
              </div>
            </div>
          </div>
        </div>
      )}
    </Box>
  );
}

export default ImportModal;
