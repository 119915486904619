import {
  BUILD_WORKFLOW_SUCCESS,
  BUILD_WORKFLOW_FAILURE,
  DELETE_WORKFLOW_SUCCESS,
  DELETE_WORKFLOW_FAILURE
  } from "./Logic.types";
  
  const initialState = {
    buildStatus:"",
    workflow: {
      data: [],
      workflowName: "",
    },
        workflowName:"",
  };
  
  const LogicReducer = (state = initialState, action) => {
    switch (action.type) {
      case BUILD_WORKFLOW_SUCCESS:
      return { ...state, buildStatus: action.payload };
    case BUILD_WORKFLOW_FAILURE:
      return { ...state, buildStatus: action.payload };
    case DELETE_WORKFLOW_SUCCESS:
      return { ...state, workflow: initialState.workflow, workflowName: "" };
    case DELETE_WORKFLOW_FAILURE:
      return { ...state };


      default:
        return state;
    }
  };
  
  export default LogicReducer;
  