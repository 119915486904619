import React, { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../styles/global.css";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { ICONS } from "../AppConfig";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import SettingsIcon from "@material-ui/icons/Settings";
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { useHistory, Link } from "react-router-dom";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import WbSunnyIcon from "@mui/icons-material/WbSunny";
import { AuthContext } from "../Context/UserContext";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { CHANGE_LOGIN } from "../redux/Login/Login.types";
import InputLabel from "@mui/material/InputLabel";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Box } from "@material-ui/core";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  CLEAR_DATA,
  SET_LEVEL,
  SET_NIGHT_MODE,
} from "../redux/Dashboard/Dashboard.types";
import classNames from "classnames";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AskSection from "./Dashboard/Assistant/AskSection";
import AskSectionPopup from "./Dashboard/Assistant/AskSectionPopup";
import Notification from "./common/Notification";
const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const Navbar = ({ setCurrentScreen, currentScreen, isLoggedIn }) => {
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const useStyles = makeStyles({
    avatar: {
      borderRadius: "50%",
    },
    links: {
      textDecoration: "none",
      color: "#484848",
    },
    sunnyIcon: {
      fontSize: "20px !important",
      color: "#ffbb52",
    },
    darkIcon: { fontSize: "14px !important", color: "#fff" },
    demo: {
      cursor: "pointer",
      position: "relative",
      color: "#000",
      fontSize: "12px",
      margin: "0.5rem",
      padding: "0.2rem 0.4rem",
      background: "#ff8005",
      borderRadius: "8px",
    },
    textDecorationNone: {
      textDecoration: "none",
    },
    setPadding: {
      padding: "0.5rem 0 0",
    },
    getColor: {
      color: "#484848",
    },

    getBg: {
      backgroundColor: "#fff",
    },
  });

  const classes = useStyles();
  const navbarClass = classNames("navbar");
  const sunicon = classNames("sun-icon", classes.sunnyIcon);
  const darkicon = classNames("moon-icon", classes.darkIcon);
  const level1 = useSelector((data) => data.dashboard.level);

  const nightMode = JSON.parse(localStorage.getItem("nightMode"));
  const [anchorEl, setAnchorEl] = useState(null);
  const auth = useContext(AuthContext);
  const dispatch = useDispatch();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const history = useHistory();
  const logout = () => {
    dispatch({ type: CLEAR_DATA });
    dispatch({ type: CHANGE_LOGIN });
    auth.logout();
    localStorage.removeItem("auth-token");
    localStorage.removeItem("userData");
    localStorage.removeItem("demoMode");
    localStorage.removeItem("userDetails");
    isDemo ? history.push("/demo-login") : history.push("/");
  };

  const profilePic = useSelector(
    (data) => data.dashboard.userDetails
  ).profilePic;

  const toggleNightMode = (e) => {
    dispatch({ type: SET_NIGHT_MODE, payload: !isNight });
    setTimeout(() => {
      localStorage.setItem("nightMode", e.target.checked);
    }, 200);
  };

  const [showSuggestion, setShowSuggestion] = useState(false);

  const [level, setLevel] = useState("admin");

  const handleChangeLevel = (event) => {
    setLevel(event.target.value);
    dispatch({ type: SET_LEVEL, payload: { level: event.target.value } });
  };

  const [helpModal, setHelpModal] = useState(false);

  return (
    <>
      {isLoggedIn ? (
        <div className={navbarClass} style={{ backgroundColor: "#FFFFFF" }}>
          <div className="navbarLeft">
            <div className="navbarLogo">
              <Link to="/dashboard/overview">
                <img
                  onClick={() => setCurrentScreen("overview")}
                  src={"/Logo-3x.png"}
                  alt="logo"
                  width={"80px"}
                  className={classes.setPadding}
                />
              </Link>
            </div>
            <div className="navTabs">
              <Link
                to="/dashboard/overview"
                className={classes.textDecorationNone}
              >
                <div
                  role="presentation"
                  className={`navTab ${currentScreen === "overview" && "navTabActive"
                    }`}
                  id="overview"
                  onClick={() => setCurrentScreen("overview")}
                >
                  Insights
                </div>
              </Link>
              {/* {level1 === "admin" && (
                <Link
                  to="/dashboard/logic"
                  className={classes.textDecorationNone}
                >
                  <div
                    onClick={() => setCurrentScreen("logic")}
                    className={`navTab ${
                      currentScreen === "logic" && "navTabActive"
                    }`}
                    id="logic"
                  >
                    Logic
                  </div>
                </Link>
              )} */}
              <Link
                to="/dashboard/feedback"
                className={classes.textDecorationNone}
              >
                <div
                  onClick={() => setCurrentScreen("feedback")}
                  className={`navTab ${currentScreen === "feedback" && "navTabActive"
                    }`}
                  id="feedback"
                  style={
                    currentScreen === "feedback" && !isNight
                      ? { backgroundColor: "#212047", color: "#aebcc9" }
                      : !isNight
                        ? { color: "#aebcc9" }
                        : {}
                  }
                >
                  Feedback
                </div>
              </Link>
              <Link
                to="/dashboard/action-plan"
                className={classes.textDecorationNone}
              >
                <div
                  onClick={() => setCurrentScreen("action-plan")}
                  className={`navTab ${currentScreen === "action-plan" && "navTabActive"
                    }`}
                  id="action-plan"
                  style={
                    currentScreen === "action-plan" && !isNight
                      ? { backgroundColor: "#212047", color: "#aebcc9", width: "150px" }
                      : !isNight
                        ? { color: "#aebcc9", width: "150px" }
                        : { width: "150px" }
                  }
                >
                  Recommendations
                </div>
              </Link>
            </div>
          </div>
          <Box position="absolute" right="90px" top="17px" display="flex">
            <Box component="div" display="flex" margin="0.25rem 0.5rem">
              <HelpOutlineIcon
                onClick={() => {
                  setHelpModal(true);
                }}
                style={{ color: "#484848", cursor: "pointer" }}
              />
              <Notification />
              <Modal
                open={helpModal}
                onClose={() => {
                  setHelpModal(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <AskSectionPopup
                  handleClose={() => {
                    setHelpModal(false);
                  }}
                />
              </Modal>


            </Box>
            {isDemo && (
              <Box component="div" >
                <Box>
                  <FormControl fullWidth size="small">
                    <Select
                      value={level}
                      input={<OutlinedInput />}
                      inputProps={{ "aria-label": "Without label" }}
                      onChange={handleChangeLevel}
                      // IconComponent={ExpandMoreIcon}
                      sx={{ padding: "0", fontSize: "0.8rem" }}
                    >
                      <MenuItem value={"admin"}>Admin</MenuItem>
                      <MenuItem value={"manager"}>Manager</MenuItem>
                      <MenuItem value={"employee"}>Employee</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            )}
            <Box component="div" margin="0.25rem 0.5rem">
              <>{isDemo && <span className={classes.demo}>Demo</span>}</>
            </Box></Box>
          {/* <Box component="div" position="absolute" right="5.8%">
        <label>
          <input
            className="toggle-checkbox" 
            type="checkbox"
            defaultChecked={nightMode}
            onChange={toggleNightMode}
          ></input>
          <div className="toggle-slot">
            <div className="sun-icon-wrapper">
              <WbSunnyIcon className={sunicon} />
            </div>
            <div className="toggle-button"></div>
            <div className="moon-icon-wrapper">
              <DarkModeIcon className={darkicon} />
            </div>
          </div>
        </label>
      </Box> */}
          <Box
            component="div"
            role="presentation"
            className="navbarRight"
            onClick={handleClick}
            marginTop="1px"
            marginRight="20px"
          >
            {!profilePic ? (
              <svg
                width="35"
                height="35"
                viewBox="0 0 37 37"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18.5 0C8.47115 0 0 8.47115 0 18.5C0 28.5289 8.47115 37 18.5 37C28.5289 37 37 28.5289 37 18.5C37 8.47115 28.5289 0 18.5 0ZM18.5 9.25C21.695 9.25 24.05 11.6032 24.05 14.8C24.05 17.9968 21.695 20.35 18.5 20.35C15.3069 20.35 12.95 17.9968 12.95 14.8C12.95 11.6032 15.3069 9.25 18.5 9.25ZM9.0539 27.3282C10.7134 24.8862 13.481 23.2582 16.65 23.2582H20.35C23.5209 23.2582 26.2867 24.8862 27.9461 27.3282C25.5818 29.859 22.2278 31.45 18.5 31.45C14.7723 31.45 11.4182 29.859 9.0539 27.3282Z"
                  fill="white"
                />
              </svg>
            ) : (
              <img
                src={profilePic}
                alt=""
                width="35"
                height="35"
                className={classes.avatar}
              />
            )}
          </Box>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <Link to="/dashboard/profile" className={classes.links}>
              <StyledMenuItem
                onClick={() => setCurrentScreen("profile")}
                className={`navTab ${currentScreen === "profile" && "navTabActive"
                  }`}
                id="profile"
                style={{ paddingBottom: "0" }}
              >
                <ListItemIcon>
                  <AccountCircleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Profile" />
              </StyledMenuItem>
            </Link>
            <Link to="/dashboard/settings" className={classes.links}>
              <StyledMenuItem
                onClick={() => setCurrentScreen("setting")}
                className={`navTab ${currentScreen === "userManagement" && "navTabActive"
                  }`}
                id="setting"
                style={{ paddingtop: "0" }}
              >
                <ListItemIcon>
                  <SettingsIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Settings" />
              </StyledMenuItem>
            </Link>
            <Link to="/dashboard/support" className={classes.links}>
              <StyledMenuItem
                onClick={() => setCurrentScreen("support")}
                className={`navTab ${currentScreen === "userManagement" && "navTabActive"
                  }`}
                id="setting"
                style={{ paddingtop: "0" }}
              >
                <ListItemIcon>
                  <SupportAgentIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText primary="Support" />
              </StyledMenuItem>
            </Link>

            <StyledMenuItem onClick={() => logout()}>
              <ListItemIcon>
                <ExitToAppIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </StyledMenuItem>
          </StyledMenu>
        </div>
      ) : (
        <div
          className={navbarClass}
          style={{
            backgroundColor: "#faeff3",
            padding: "0 4rem",
            width: "90%",
            position: "absolute",
            top: 0,
          }}
        >
          <div className="navbarLeft">
            <div className="navbarLogo">
              <Link to="/dashboard/overview">
                <img
                  onClick={() => setCurrentScreen("overview")}
                  src={"/Logo-3x.png"}
                  alt="logo"
                  width={"100px"}
                  className={classes.setPadding}
                  style={{ position: "relative", top: "10px" }}
                />
              </Link>
            </div>
          </div>
          <div className="navTabs">
            <a
              href="https://trupulse.ai"
              className={classes.textDecorationNone}
            >
              <div
                role="presentation"
                className="navTab"
                style={{ padding: "0.5rem 1rem" }}
              >
                Home
              </div>
            </a>
            <a
              href="https://trupulse.ai/product"
              className={classes.textDecorationNone}
            >
              <div className="navTab" style={{ padding: "0.5rem 1rem" }}>
                Product
              </div>
            </a>
            <a
              href="https://trupulse.ai/company"
              className={classes.textDecorationNone}
            >
              <div className="navTab" style={{ padding: "0.5rem 1rem" }}>
                Company
              </div>
            </a>
            <a
              href="https://trupulse.ai/blog"
              className={classes.textDecorationNone}
            >
              <div className="navTab" style={{ padding: "0.5rem 1rem" }}>
                Blog
              </div>
            </a>
          </div>
          <div style={{ position: "relative", top: "10px" }}>
            <a
              href="https://trupulse.ai/demo"
              className={classes.textDecorationNone}
            >
              <button id="demoButton" className="mx-8 font-[lora]">
                Play a Demo
              </button>
            </a>
            <a
              href="https://trupulse.ai/contact"
              className={classes.textDecorationNone}
            >
              <button id="contactButton" className="mx-8 font-[lora]">
                Contact Sales
              </button>
            </a>
            <a
              href="https://dashboard.trupulse.ai"
              className={classes.textDecorationNone}
            >
              <button id="loginButton" className="mx-8 font-[lora]">
                Login
              </button>
            </a>
          </div>
        </div>
      )}
    </>
  );
};
export default Navbar;
