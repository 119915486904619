import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_ALL_SURVEYS,
  GET_MESSAGE_DELETION_DAYS_SUCCESS,
  UPDATE_SURVEY,
} from "../../../../redux/Dashboard/Dashboard.types";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function DeleteMessagesModal({ handleClose }) {
  const dispatch = useDispatch();

  const messageDeletionDays = useSelector(
    (data) => data.dashboard.messageDeletionDays
  );

  const [days, setDays] = useState(messageDeletionDays);
  const [alert, setAlert] = useState("");

  const handleSave = () => {
    if (days < 30) {
      setAlert("Days must be 30 or more.");
      return;
    }

    dispatch({
      type: GET_MESSAGE_DELETION_DAYS_SUCCESS,
      payload: { days: days },
    });
    // Add your dispatch logic here, for example:
    // dispatch({ type: UPDATE_SURVEY, payload: { days } });
    setAlert(""); // Clear the alert when conditions are met
    handleClose();
  };

  return (
    <Box sx={style}>
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Typography>In how many days you want to delete messages?</Typography>
      </Box>
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <TextField
          fullWidth
          id="question-text"
          type="number"
          label="Number of Days"
          variant="outlined"
          value={days}
          onChange={(e) => setDays(e.target.value)}
        />
        {alert && (
          <Typography color="error" variant="body2">
            {alert}
          </Typography>
        )}
      </Box>
      <Stack direction="row" spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          style={{
            background: "#D06283",
            color: "#fff",
          }}
          variant="contained"
        >
          Update
        </Button>
      </Stack>
    </Box>
  );
}

export default DeleteMessagesModal;
