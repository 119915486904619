import { toast } from "react-toastify";
import api from "../axiosConfig";

export function* forgetPassword(action) {
  let resp;
  const data = action.payload;
  yield api
    .post(`/forgetPassword`, data)
    .then((response) => {
      if (response.status >= 400 && response.status < 500) {
        toast.error(response?.message);
      } else if (response.status === 400) {
        toast.error("User not found!!!");
      } else {
        resp = response;
        toast.success("Password Reset Link Send Successfully");
      }
    })
    .catch((error) => {
      // toast.error("Data not found");
      return error;
    });
  return resp;
}
