import React from "react";

const Step3Modal = ({
  handleClose,
  setCurrentScreen,
  currentScreen,
  currentStep,
  setCurrentStep,
  setTopStatusAccordian,
  setInsightAccordion,
}) => {
  const handleNextStep = () => {
    // handleClose();

    setTopStatusAccordian(false);
    setInsightAccordion(true);
    // setCurrentScreen("logic");
    setCurrentStep(4);
  };

  const handleSkip = () => {
    handleClose();
    localStorage.setItem("skipped", true);
  };

  return (
    <>
      
      <div
        className="addChartsContainer"
        style={{
          width: "24vw",
          marginTop: "55px",
          position: "absolute",
          left: "35%",
          top: "12%",
        }}
      >
        <div style={{ color: "#484848", fontSize: "1rem" }}>
          <b>Overview Section</b>
        </div>
        <p style={{ color: "#484848" }}>
          In this section, you'll see the overall performance, including
          important metrics such as{" "}
          <b>
            morale, engagement, retention risk, eNPS (Employee Net Promoter
            Score), key topics, and culture.
          </b>
        </p>
        <div className="btnOptions" style={{ marginTop: "0px" }}>
          <div className="submitBtn">
            <div className="rangeBtn" type="submit" onClick={handleNextStep}>
              <div className="btnText">{"NEXT >>"}</div>
            </div>
          </div>
          <div className="submitBtn">
            <div className="rangeBtn" type="submit" onClick={handleSkip}>
              <div className="btnText">{"SKIP >>>>"}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step3Modal;
