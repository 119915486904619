import React, { useEffect, useState } from "react";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.min.css";
import axios from "axios";
import "../styles/survey.css";
import { Box, Typography } from "@material-ui/core";

export default function SurveyComponent() {
  const [isVerified, setIsVerified] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [isNPS, setIsNPS] = useState(false);
  const [isBaseline, setIsBaseline] = useState(false);
  const [surveyData, setSurveyData] = useState([]);
  const [surveyName, setSurveyName] = useState("");
  const [surveyDesc, setSurveyDesc] = useState("");

  const [baslineType, setBaselineType] = useState("")


  const getSurvey = async () => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (params.id && params.email) {
      const res = await axios.get(
        `https://api.trupulse.ai/feedback/verifiedEmailSurvey?id=${params.id}&email=${params.email}`
      );
      if (res.status === 200) {
        // res.data.result.surveyType === "generalsurvey-eNPS" && setIsNPS(true);
        setQuestions(res.data?.result?.questions);
        setSurveyName(res.data?.result?.name);
        setSurveyDesc(res.data?.result?.description);
      } else {
        alert("Something went wrong!");
      }
    }
  };

  const getBaselineSurvey = async () => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    if (params.id && params.email) {
      const res = await axios.get(
        `https://api.trupulse.ai/baselineSurveys?id=${params.id}&email=${params.email}`
      );
      if (res.status === 200) {
        res.data.surveyType === "eNPS" && setIsNPS(true);
        setQuestions(res.data.questions);
        setBaselineType(res.data.surveyType)
        setSurveyDesc(res.data.description)
      } else {
        alert("Something went wrong!");
      }
    }
  };

  const submitResponse = (data) => {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    var formattedData = [];
    const comment = data.filter((item) => item.questionID === "comment")[0]
      ? data.filter((item) => item.questionID === "comment")[0].answer
      : "";
    if (isNPS) {
      data
        .filter((item) => item.questionID !== "comment")
        .forEach((item) => {
          formattedData.push({
            questionID: item.questionID,
            answer: item.answer,
          });
        });
    } else {
      data
        .filter((item) => item.questionID !== "comment")
        .forEach((item) => {
          const currentQuest = questions.filter(
            (ques) => ques.questionID === item.questionID
          )[0];
          formattedData.push({
            questionID: item.questionID,
            answer: { [item.answer]: currentQuest.options[item.answer] },
          });
        });
    }

    if (params.id && params.email) {
      if (isBaseline) {
        const res = axios.post("https://api.trupulse.ai/baselineSurveys/submit", {
          email: params.email,
          surveyID: params.id,
          response: formattedData,
          comment: comment.length > 0 && comment,
        });
      } else {
        const res = axios.post("https://api.trupulse.ai/feedback/submitSurvey", {
          email: params.email,
          surveyID: params.id,
          response: formattedData,
          comment: comment.length > 0 && comment,
        });
      }
    }
  };
  useEffect(() => {
    if (window.location.href.includes("baseline")) {
      setIsBaseline(true);
      getBaselineSurvey();
    } else {
      setIsBaseline(false);
      getSurvey();
    }
  }, []);

  useEffect(() => {
    const temp = [];

    questions.length > 0 &&
      questions.forEach((question) => {
        const data = {
          type: question.type,
          name: question.questionID,
          title: question.question,
          isRequired: true,
          showNoneItem: false,
          // showOtherItem: true,
          colCount: 1,

          separateSpecialChoices: true,
          showClearButton: true,
        };
        if (question.type === "rating") {
          data["rateMin"] = 0;
          data["rateMax"] = 10;
          data["minRateDescription"] = {
            default: "Very unlikely",
            fr: "Très improbable",
          };
          data["maxRateDescription"] = {
            default: "Very likely",
            fr: "Très probable",
          };
          // data["rateDescriptionLocation"] = "bottom";
        } else {
          data["choices"] = Object.keys(question.options).sort((a, b) => question.options[a] - question.options[b]).filter(
            (item) => item !== "Other"
          );
        }
        temp.push(data);
      });

    temp.push({
      type: "comment",
      name: "comment",
      title: "Is there any other feedback you want to give on this issue?",
      isRequired: false,
      colCount: 1,

      showClearButton: true,
    });

    setSurveyData({
      elements: temp,
      showQuestionNumbers: true,
    });
  }, [questions]);

  function processSurveyData(data) {
    // Create an array to store processed data
    const processedData = [];

    // Iterate over each key-value pair in the data
    Object.entries(data).forEach(([key, value]) => {
      // Extract the question ID
      const questionId = key.split("-")[0];

      // Check if the question ID already exists in the processed data
      const existingEntryIndex = processedData.findIndex(
        (entry) => entry.questionID === questionId
      );

      // If the question ID already exists
      if (existingEntryIndex !== -1) {
        // If comment exists, replace value with comment
        if (key.endsWith("-Comment")) {
          processedData[existingEntryIndex].answer = value;
        } else {
          // If comment doesn't exist, check if current value is not "other"
          if (value !== "other") {
            processedData[existingEntryIndex].answer = value;
          }
        }
      } else {
        // If question ID doesn't exist, add it to processed data
        if (!key.endsWith("-Comment")) {
          processedData.push({
            questionID: questionId,
            answer: value,
          });
        }
      }
    });

    return processedData;
  }

  const survey = new Model(surveyData);
  survey.onComplete.add((sender, options) => {
    submitResponse(processSurveyData(sender.data));
  });
  return (
    <div style={{ minHeight: "100vh", background: "#F3F3F3" }}>
      {questions.length > 0 ? (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: "100vw",
          }}
        >
          <Box
            style={{
              maxWidth: "610px",
              background: "#fff",
              width: "100%",
              margin: "2rem auto 0",
              padding: "1rem",
              boxShadow: "0px 0px 5px -3px rgba(0,0,0,0.75)",
            }}
          >
            <Typography
              style={{
                fontSize: "1.5rem",
                fontWeight: "bold",
                textAlign: "left",
              }}
            >
              {isBaseline ? `${baslineType} Baseline Survey` : surveyName}
            </Typography>
            <Typography
              style={{
                fontSize: "1.2rem",
                textAlign: "left",
              }}
            >
              {surveyDesc}
            </Typography>
          </Box>
          <Survey model={survey} />
        </Box>
      ) : (
        <p style={{ padding: "4rem 0" }}>Please wait...</p>
      )}
    </div>
  );
}
