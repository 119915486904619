
export const demoWords = [
  { text: 'with', value: 13 },
  { text: 'the', value: 67 },
  { text: 'are', value: 11 },
  { text: 'not', value: 21 },
  { text: 'each', value: 6 },
  { text: 'good', value: 44 },
  { text: 'job', value: 13 },
  { text: 'this', value: 6 }, 
  { text: 'company', value: 10 },
  { text: 'salary', value: 8 },
  { text: 'very', value: 25 },
  { text: 'pay', value: 20 },
  { text: 'hours', value: 18 },
  { text: 'they', value: 6 },
  { text: 'will', value: 7 },
  { text: 'you', value: 23 },
  { text: 'for', value: 34 },
  { text: 'benefits', value: 12 },
  { text: 'and', value: 76 },
  { text: 'shifts', value: 6 },
  { text: 'career', value: 8 },
  { text: 'growth', value: 11 },
  { text: 'work', value: 51 },
  { text: 'there', value: 7 },
  { text: 'lot', value: 11 },
  { text: 'culture', value: 11 },
  { text: 'from', value: 6 },
  { text: 'sometimes', value: 6 },
  { text: 'work-life', value: 7 },
  { text: 'pay,', value: 7 },
  { text: 'many', value: 7 },
  { text: 'decent', value: 6 },
  { text: 'shift', value: 6 },
  { text: 'can', value: 16 },
  { text: 'great', value: 20 },
  { text: 'learn', value: 8 },
  { text: 'get', value: 10 },
  { text: 'long', value: 6 },
  { text: 'working', value: 9 },
  { text: 'always', value: 6 },
  { text: 'your', value: 9 },
  { text: 'fast', value: 11 },
  { text: 'have', value: 11 },
  { text: 'lots', value: 7 },
  { text: 'learning', value: 14 },
  { text: 'but', value: 6 },
  { text: 'work.', value: 7 },
  { text: 'worst', value: 6 },
  { text: 'flexible', value: 8 },
  { text: 'too', value: 6 },
  { text: 'much', value: 8 },
  { text: 'time', value: 8 },
  { text: 'nothing', value: 6 },
  { text: 'their', value: 6 },
  { text: 'balance', value: 9 },
  { text: 'best', value: 7 },
  { text: 'environment', value: 7 },
  { text: 'leadership', value: 9 },
  { text: 'office', value: 7 },
  { text: 'high', value: 9 },
  { text: 'team', value: 7 },
  { text: 'day', value: 6 },
  { text: 'opportunities,', value: 7 },
  { text: 'process', value: 6 },
  { text: 'people', value: 10 },
  { text: 'amazon', value: 8 },
  { text: 'life', value: 6 }
]