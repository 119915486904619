import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Tooltip } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import CloseIcon from "@mui/icons-material/Close";
import { SAVE_SURVEY_SETTINGS } from "../../../../redux/Dashboard/Dashboard.types";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function SettingsModal({
  eventBased,
  setEventBased,
  topicBased,
  setTopicBased,
  handleClose,
}) {
  const dispatch = useDispatch();
  const insightThreshold = useSelector(
    (data) => data.dashboard.insightThreshold
  );

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const surveySettings = useSelector(
    (data) => data.dashboard.surveySettings
  );

  const [questions, setQuestions] = useState(surveySettings.questions);
  const [allowComments, setAllowComments] = useState(surveySettings.comments);

  const handleCommentToggle = (event, allow) => {
    setAllowComments(allow);
  };

  const handleSave = () => {
    const data = {
      questions: questions,
      comments: allowComments,
    };

    dispatch({ type: SAVE_SURVEY_SETTINGS, payload: data });

    handleClose();
  };


  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Settings
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Stack direction="column" spacing={2}>
          <label>Default number of questions</label>
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Questions"
            variant="outlined"
            value={questions}
            onChange={(e) => setQuestions(parseInt(e.target.value))}
          />
        </Stack>
      </Box>
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Stack direction="column" spacing={2}>
          <label>Add an additional open ended Comments question?</label>
          <ToggleButtonGroup
            value={allowComments}
            exclusive
            onChange={handleCommentToggle}
            aria-label="Allow comments"
          >
            <ToggleButton value={true} aria-label="left aligned">
              Yes
            </ToggleButton>
            <ToggleButton value={false} aria-label="centered">
              No
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Box>

      <Stack direction="row" spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          style={{
            background: "#D06283",
            color: "#fff",
          }}
          variant="contained"
        >
          Save
        </Button>
      </Stack>
    </Box>
  );
}

export default SettingsModal;
