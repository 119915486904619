import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import cloneDeep from "lodash/cloneDeep";
import Pagination from "rc-pagination";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const tableHead = {
  first_name: "first_name",
  last_name: "last_name",
  title: "title",
  division: "division",
  department: "department",
};

const APP_ID = "ZmURtymlNxOvNkn9WtDV3fXMyVmfE8pdFcTrWtDV";
const API_KEY =
  "sk_live_7358c6df-e21b-4eb7-bde5-7a43b6e0eba0-pC37y3ckkjqLpw1IWtDV-97ce1512-7104-4cb4-848e-ea26c3f612f2";

function HrisModal({ survey, handleClose }) {
  const dispatch = useDispatch();

  const userDetails = useSelector((data) => data.dashboard.userDetails);
  const [consumer, setConsumer] = useState("");
  const [sessionURL, setSessionURL] = useState("");
  const [employees, setEmployees] = useState([]);

  const createConsumer = async () => {
    const url = "https://unify.apideck.com/vault/consumers";
    const headers = {
      "x-apideck-app-id": APP_ID,
      Authorization: `Bearer ${API_KEY}`,
      "Content-Type": "application/json",
    };

    const body = {
      consumer_id: userDetails.company.id,
      metadata: {
        account_name: userDetails.company.name,
        user_name: `${userDetails.first_name} ${userDetails.last_name}`,
        email: userDetails.email,
      },
    };

    try {
      const response = await axios.post(url, body, { headers });
      setConsumer(response.data.data);
      createSession();
    } catch (error) {
      if (error.response) {
        console.error("Error response:", error.response.data);
      } else {
        console.error("Error message:", error.message);
      }
    }
  };

  const createSession = async () => {
    const url = "https://unify.apideck.com/vault/sessions";
    const headers = {
      Authorization: `Bearer ${API_KEY}`,
      "x-apideck-app-id": APP_ID,
      "x-apideck-consumer-id": userDetails.company.id,
      "Content-Type": "application/json",
    };

    const body = {
      consumer_metadata: {
        account_name: userDetails.company.name,
        user_name: `${userDetails.first_name} ${userDetails.last_name}`,
        email: userDetails.email,
        image: "https://dashboard.trupulse.ai/Logo-3x.png",
      },
      redirect_uri: "https://dashboard.trupulse.ai/",
      settings: {
        unified_apis: ["hris"],
        hide_resource_settings: false,
        sandbox_mode: false,
        isolation_mode: false,
        session_length: "30m",
        show_logs: true,
        show_suggestions: false,
        show_sidebar: true,
        auto_redirect: false,
        hide_guides: false,
        allow_actions: ["delete"],
      },
    };

    try {
      const response = await axios.post(url, body, { headers });

      setSessionURL(response.data.data.session_uri);
    } catch (error) {
      if (error.response) {
        console.error("Error response:", error.response.data);
      } else {
        console.error("Error message:", error.message);
      }
    }
  };
  const getAllConsumer = async () => {
    const res = await axios.get("https://unify.apideck.com/vault/consumers", {
      headers: {
        Authorization:
          "Bearer sk_live_7358c6df-e21b-4eb7-bde5-7a43b6e0eba0-pC37y3ckkjqLpw1IWtDV-97ce1512-7104-4cb4-848e-ea26c3f612f2",
        "x-apideck-app-id": "ZmURtymlNxOvNkn9WtDV3fXMyVmfE8pdFcTrWtDV",
      },
    });

    const consumerExist =
      res.data.data.filter(
        (item) => item.consumer_id === userDetails.company.id
      ).length > 0;

    if (consumerExist) {
      //create Session
      createSession();
      setConsumer(
        res.data.data.filter(
          (item) => item.consumer_id === userDetails.company.id
        )[0]
      );
    } else {
      // create consumer and then session
      createConsumer();
    }
  };

  const getEmployees = async () => {
    const employees = [];
    consumer.services.forEach(async (service) => {
      const url = "https://unify.apideck.com/hris/employees";
      const headers = {
        Authorization: `Bearer ${API_KEY}`,
        "x-apideck-app-id": APP_ID,
        "x-apideck-consumer-id": userDetails.company.id,
        "x-apideck-service-id": service,
      };

      try {
        const response = await axios.get(url, { headers });
        employees.push(...response.data.data);
        setEmployees(employees);
      } catch (error) {
        if (error.response) {
          console.error("Error response:", error.response.data);
        } else {
          console.error("Error message:", error.message);
        }
      }
    });
  };

  const openPopupWindow = () => {
    const popupUrl = sessionURL; // Replace with your desired URL
    const popupFeatures = "width=700,height=600,scrollbars=yes,resizable=yes";
    window.open(popupUrl, "_blank", popupFeatures);
  };

  useEffect(() => {
    getAllConsumer();
  }, []);

  const countPerPage = 5;
  const [value, setValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedData, setSortedData] = useState([]);
  const [collection, setCollection] = useState(
    cloneDeep(sortedData.slice(0, countPerPage))
  );

  useEffect(() => {
    setCollection(cloneDeep(sortedData.slice(0, countPerPage)));
  }, [sortedData]);

  useEffect(() => {
    setSortedData(employees);
  });

  useEffect(() => {
    if (!value) {
      updatePage(1);
    } else {
      search(value);
      // searchData.current(value);
    }
  }, [value]);

  const updatePage = (p) => {
    setCurrentPage(p);
    const to = countPerPage * p;
    const from = to - countPerPage;
    setCollection(cloneDeep(sortedData.slice(from, to)));
  };

  const tableData = () => {
    return collection.map((key, index) => tableRows({ key, index }));
  };

  const headRow = () => {
    return Object.values(tableHead).map((title, index) => (
      <td key={index}>{title}</td>
    ));
  };

  const tableRows = (rowData) => {
    const { key, index } = rowData;
    const tableCell = Object.keys(tableHead);
    const columnData = tableCell.map((keyD, i) => {
      return (
        <td style={{ cursor: "pointer" }} key={i}>
          {key[keyD]}
        </td>
      );
    });

    return (
      <tr style={{ border: "1px solid #727272" }} key={index}>
        {columnData}
      </tr>
    );
  };

  return (
    <Box >
      <Typography id="modal-modal-title" variant="h6" component="h2" style={{color:"#484848"}}>
        Connect HRIS
      </Typography>
      
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Typography style={{color:"#484848"}}>Connect HRIS and click on fetch employees</Typography>
        {sessionURL.length > 0 && (
          <button
            onClick={openPopupWindow}
            style={{
              padding: "0.5rem",
              fontSize: "1rem",
              borderRadius: "5px",
              border: "1px solid #D06283",
              color: "#fff",
              cursor: "pointer",
              background: "#D06283",
              margin: "1.5rem 1.4rem 0.5rem",
            }}
          >
            Connect
          </button>
        )}
        {consumer.services && (
          <button
            onClick={getEmployees}
            style={{
              padding: "0.5rem",
              fontSize: "1rem",
              borderRadius: "5px",
              border: "1px solid #D06283",
              color: "#fff",
              cursor: "pointer",
              background: "#D06283",
              margin: "0.5rem 1.4rem 0.5rem",
            }}
          >
            Get Employees
          </button>
        )}

        {sortedData.length > 0 && (
          <Box className="survey">
            <div class="survey-search"></div>
            <table>
              <thead>
                <tr>{headRow()}</tr>
              </thead>
              <tbody className="survey-trhover">{tableData()}</tbody>
            </table>
            <Pagination
              pageSize={countPerPage}
              onChange={updatePage}
              current={currentPage}
              total={sortedData.length}
            />
          </Box>
        )}
      </Box>
      <Stack direction="row" spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          style={{
            background: "#D06283",
            color: "#fff",
          }}
          variant="contained"
        >
          Save
        </Button>
      </Stack>
    </Box>
  );
}

export default HrisModal;
