import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { demoBaselineSurveys } from "../demoBaselineSurveys";
import { demoSurveys } from "../demoSurveys";
import axios from "axios";
import Stack from "@mui/material/Stack";
import { Box, Typography } from "@material-ui/core";
import Chart from "react-apexcharts";
import InputLabel from "@mui/material/InputLabel";
import Markdown from "react-markdown";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { GET_FILTER_EMAILS } from "../../../../redux/Dashboard/Dashboard.types";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import moment from "moment";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useHistory } from "react-router-dom";

function BaselineRes({ surveyID }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const id = window.location.href.split("/")[6];
  const [gender, setGender] = useState("");
  const [department, setDepartment] = useState("");
  const [location, setLocation] = useState("");
  const [region, setRegion] = useState("");
  const [ethnicity, setEthnicity] = useState("");
  const [maritalStatus, setMaritalStatus] = useState("");
  const [managementLevel, setManagementLevel] = useState("");
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const [alignment, setAlignment] = useState("summary");
  const [summarizedComments, setSummarizedComments] = useState("");
  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  const surveyData = isDemo
    ? demoBaselineSurveys
    : useSelector((data) => data.dashboard.baseLineSurveys);
  const filterEmails = isDemo
    ? demoSurveys
    : useSelector((data) => data.dashboard.filterEmails);
  const [currentSurvey, setCurrentSurvey] = useState({});
  //  surveyData.filter((item) => item._id === surveyID)[0];
  const [result, setResult] = useState("");

  useEffect(() => {
    setCurrentSurvey(surveyData.filter((item) => item._id === id)[0]);
  }, [surveyData, surveyID]);


  useEffect(() => {
    const payload = {
      workflowID: "overall",
      timeOption: "all",
      channels: [],
      gender: [],
      startDate: "",
      endDate: "",
      location: [],
      department: [],
      ethinicity: [],
      marital_status: [],
      region: [],
      length_of_service: [],
      management_level: [],
      income: [],
      age: [],
      performance: [],
      term: "",
    };
    if (gender !== "") {
      payload["gender"] = [gender];
    }
    if (department !== "") {
      payload["department"] = [department];
    }
    if (region !== "") {
      payload["region"] = [region];
    }
    if (location !== "") {
      payload["location"] = [location];
    }
    if (ethnicity !== "") {
      payload["ethinicity"] = [ethnicity];
    }
    if (maritalStatus !== "") {
      payload["marital_status"] = [maritalStatus];
    }
    if (managementLevel !== "") {
      payload["management_level"] = [managementLevel];
    }

    !isDemo &&
      dispatch({
        type: GET_FILTER_EMAILS,
        payload: payload,
      });
  }, [
    gender,
    department,
    region,
    location,
    ethnicity,
    maritalStatus,
    managementLevel,
  ]);

const summarizeComments = async (comments) => {
  const res = await axios.post("https://api.trupulse.ai/openai/query", {
    query: `${JSON.stringify(
      comments
    )}, can you summarize these comments without revealing users email or name?`,
    context: [],
  });
  setSummarizedComments(res.data.data.choices[0].message.content);
};

  useEffect(() => {
    currentSurvey.comments?.length > 0 &&
      summarizeComments(currentSurvey.comments);
  }, [alignment]);

  return (
    <Box sx={{ padding: "2rem" }}>
      {currentSurvey && currentSurvey._id && (
        <Box>
          <Box>
            <Box sx={{ display: "flex", justifyContent: "start" }}>
              
              <Button
                onClick={() => {
                  history.goBack();
                }}
                variant="outlined"
                style={{
                  padding: "0.5rem 0",
                  border: "2px solid #D06283",
                  color: "#D06283",
                  margin: "1rem",
                }}
              >
                <ArrowBackIcon />
              </Button>
              {/* </Link> */}
            </Box>
            <Box
              sx={{
                padding: "1rem 1rem",
                backgroundColor: "#f9f9f9",
                border: "1px solid #dddddd",
                borderRadius: "4px",
                margin: " 1rem 0",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography
                  style={{
                    fontSize: "1.5rem",
                    fontWeight: "bold",
                    textAlign: "left",
                  }}
                >
                  Survey Name: {currentSurvey?.surveyName}
                </Typography>

                <Stack direction="row">
                  <Link
                    to={`/dashboard/feedback/result/${currentSurvey._id}`}
                    style={{ textDecoration: "none", color: "#484848" }}
                  >
                    <Typography
                      style={{
                        fontSize: "1rem",
                        fontWeight: "normal",
                        textAlign: "left",
                        color: "#D06283",
                        cursor: "default",
                      }}
                    >
                      {`${currentSurvey.questions[0].response.length} ${
                        currentSurvey.questions[0].response.length > 1
                          ? "Responses"
                          : "Response"
                      }`}
                    </Typography>
                  </Link>
                </Stack>
              </Box>
            </Box>
            {currentSurvey?.report && (
              <Box
                style={{
                  background: "#f9f9f9",
                  border: "1px solid #dddddd",
                  borderRadius: "4px",
                  margin: "1rem 0",
                  textAlign: "left",
                  padding: " 0.5rem 1rem",
                }}
              >
                <Typography style={{ fontSize: "1.1rem", fontWeight: "bold" }}>
                  Summary
                </Typography>
                <Markdown>{currentSurvey.report.data}</Markdown>
              </Box>
            )}
            <Box
              sx={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "start",
              }}
            >
              <FormControl sx={{ minWidth: "120px", margin: "0 0.5rem" }}>
                <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={gender}
                  label="Gender"
                  onChange={(e) => {
                    setGender(e.target.value);
                  }}
                >
                  <MenuItem value={""}>Gender</MenuItem>
                  <MenuItem value={"male"}>Male</MenuItem>
                  <MenuItem value={"female"}>Female</MenuItem>
                  <MenuItem value={"non-binary"}>Non-Binary</MenuItem>
                  <MenuItem value={"transgender"}>Transgender</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: "150px", margin: "0 0.5rem" }}>
                <InputLabel id="demo-simple-select-label">
                  Department
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={department}
                  label="Department"
                  onChange={(e) => {
                    setDepartment(e.target.value);
                  }}
                >
                  <MenuItem value={""}>Department</MenuItem>
                  <MenuItem value={"Accounting and Finance"}>
                  Accounting and Finance
                  </MenuItem>
                  <MenuItem value={"Engineering"}>Engineering</MenuItem>
                  <MenuItem value={"Human Resource"}>Human Resource</MenuItem>
                  <MenuItem value={"Information Technology"}>
                    Information Technology
                  </MenuItem>
                  <MenuItem value={"Operations"}>Operations</MenuItem>
                  <MenuItem value={"Research and Development"}>
                    Research and Development
                  </MenuItem>
                  <MenuItem value={"Sales and Marketing"}>
                    Sales and Marketing
                  </MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: "120px", margin: "0 0.5rem" }}>
                <InputLabel id="demo-simple-select-label">Region</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={region}
                  label="Region"
                  onChange={(e) => {
                    setRegion(e.target.value);
                  }}
                >
                  <MenuItem value={""}>Region</MenuItem>
                  <MenuItem value={"Asia Pacific"}>Asia Pacific</MenuItem>
                  <MenuItem value={"Europe"}>Europe</MenuItem>
                  <MenuItem value={"Middle East/Africa"}>
                    Middle East/Africa
                  </MenuItem>
                  <MenuItem value={"North America"}>North America</MenuItem>
                  <MenuItem value={"South America"}>South America</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: "120px", margin: "0 0.5rem" }}>
                <InputLabel id="demo-simple-select-label">Location</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={location}
                  label="Location"
                  onChange={(e) => {
                    setLocation(e.target.value);
                  }}
                >
                  <MenuItem value={""}>Location</MenuItem>
                  <MenuItem value={"Headquarters"}>Headquarters</MenuItem>
                  <MenuItem value={"Regional Office"}>Regional Office</MenuItem>
                  <MenuItem value={"Home office"}>Home office</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: "120px", margin: "0 0.5rem" }}>
                <InputLabel id="demo-simple-select-label">Race</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={ethnicity}
                  label="Race"
                  onChange={(e) => {
                    setEthnicity(e.target.value);
                  }}
                >
                  <MenuItem value={""}>Race</MenuItem>
                  <MenuItem value={"American Indian or Alaska Native"}>
                    American Indian or Alaska Native
                  </MenuItem>
                  <MenuItem value={"Asian"}>Asian</MenuItem>
                  <MenuItem value={"Black or African American"}>
                    Black or African American
                  </MenuItem>

                  <MenuItem value={"Hispanic or Latino"}>
                    Hispanic or Latino
                  </MenuItem>
                  <MenuItem value={"Native Hawaiian or Other Pacific Islander"}>
                    Native Hawaiian or Other Pacific Islander
                  </MenuItem>
                  <MenuItem value={"White"}>White</MenuItem>
                  <MenuItem value={"Others"}>Others</MenuItem>
                </Select>
              </FormControl>

              <FormControl sx={{ minWidth: "150px", margin: "0 0.5rem" }}>
                <InputLabel id="demo-simple-select-label">
                  Marital Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={maritalStatus}
                  label="Location"
                  onChange={(e) => {
                    setMaritalStatus(e.target.value);
                  }}
                >
                  <MenuItem value={""}>Marital Status</MenuItem>
                  <MenuItem value={"Married"}>Married</MenuItem>
                  <MenuItem value={"Single"}>Single</MenuItem>
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: "160px", margin: "0 0.5rem" }}>
                <InputLabel id="demo-simple-select-label">
                  Management Level
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={managementLevel}
                  label="Management Level"
                  onChange={(e) => {
                    setManagementLevel(e.target.value);
                  }}
                >
                  {" "}
                  <MenuItem value={""}>Management Level</MenuItem>
                  <MenuItem value={"Executive"}>Executive</MenuItem>
                  <MenuItem value={"SVP"}>SVP</MenuItem>
                  <MenuItem value={"VP"}>VP</MenuItem>
                  <MenuItem value={"Director"}>Director</MenuItem>
                  <MenuItem value={"Manager"}>Manager</MenuItem>
                  <MenuItem value={"Individual Contributor"}>
                    Individual Contributor
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>

          {currentSurvey.questions.length > 0 &&
            currentSurvey.questions
              .filter((que) => que.type === "radiogroup")
              .map((item, id) => {
                var options = {
                  chart: {
                    type: "pie",
                  },
                  dataLabels: {
                    enabled: true,
                  },
                  fill: {
                    colors: [
                      "#094A25",
                      "#0C6B37",
                      "#F8B324",
                      "#EB442C",
                      "#BC2023",
                    ],
                  },
                  plotOptions: {
                    pie: {
                      startAngle: -180,
                      endAngle: 180,
                      offsetY: 10,
                      dataLabels: {
                        offset: -30,
                      },
                    },
                  },

                  stroke: {
                    show: false,
                  },
                  grid: {
                    padding: {
                      // bottom: -80
                    },
                  },
                  responsive: [
                    {
                      breakpoint: 480,
                      options: {
                        chart: {
                          width: 200,
                        },
                      },
                    },
                  ],
                  legend: {
                    show: true,
                    position: "top",
                    horizontalAlign: "right",
                    fontSize: "14px",
                    fontWeight: 400,
                    // offsetY: 4,
                    labels: {
                      colors: ["#666"],
                      useSeriesColors: false,
                    },
                    markers: {
                      width: 14,
                      height: 14,
                      fillColors: [
                        "#094A25",
                        "#0C6B37",
                        "#F8B324",
                        "#EB442C",
                        "#BC2023",
                      ],
                      radius: 0,
                      offsetX: 0,
                      offsetY: 0,
                    },
                    itemMargin: {
                      horizontal: 5,
                      vertical: 0,
                    },
                    onItemClick: {
                      toggleDataSeries: true,
                    },
                    onItemHover: {
                      highlightDataSeries: true,
                    },
                  },
                  tooltip: {
                    fillSeriesColor: true,
                    y: {
                      formatter: function (value) {
                        return value;
                      },
                    },
                  },
                  style: {
                    fontSize: "12px",
                    color: "#333",
                  },
                  colors: ["#666", "#666"],
                  labels: Object.keys(item.options),
                };

                var series = [];

                if (isDemo) {
                  item.options &&
                    Object.keys(item.options).forEach((option, id) => {
                      series.push(
                        item.response.filter(
                          (res) =>
                            Object.keys(res.answer ? res.answer : {})[0] ===
                            option
                        ).length
                      );
                    });
                } else {
                  item.options &&
                    Object.keys(item.options).forEach((option, id) => {
                      series.push(
                        item.response
                          .filter((item) => filterEmails.includes(item.email))
                          .filter(
                            (res) =>
                              Object.keys(res.answer ? res.answer : {})[0] ===
                              option
                          ).length
                      );
                    });
                }

                return (
                  <Box>
                    {!series.every((item) => item === 0) && (
                      <Box sx={{ margin: "1rem" }}>
                        <Typography
                          style={{ textAlign: "left", fontWeight: "bold" }}
                        >
                          {`${id + 1}. ${item.question}`}
                        </Typography>
                        <Box>
                          {/* {item.response.map((item) => (
                  <Box sx={{ border: "1px solid #e5e5e5" }}>
                    <Stack direction="row" spacing={2}>
                      <Box>{item.email}</Box>
                      <hr /> <Box>{item.answer}</Box>
                    </Stack>
                  </Box>
                ))} */}
                          {
                            <Chart
                              options={options}
                              series={series}
                              type="pie"
                              width="100%"
                              height="300px"
                            />
                          }
                        </Box>
                      </Box>
                    )}
                  </Box>
                );
              })}
          {currentSurvey?.comments?.length > 0 && (
            <>
              <Typography
                style={{
                  fontWeight: "bold",
                  textAlign: "left",
                  fontSize: "1.5rem",
                  margin: "0.5rem 0",
                }}
              >
                Comments:
              </Typography>
              <Box style={{ display: "flex", justifyContent: "start" }}>
                <ToggleButtonGroup
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleChange}
                  aria-label="Platform"
                >
                  <ToggleButton value="summary">Summary</ToggleButton>
                  <ToggleButton value="general">Details</ToggleButton>
                </ToggleButtonGroup>
              </Box>

              <Box style={{ margin: "1rem 0" }}>
                {alignment === "general" &&
                  currentSurvey.comments.map((comment) => (
                    <Box style={{ display: "flex" }}>
                      {comment.comment.length > 0 && (
                        <Typography style={{ textAlign: "left" }}>
                          - {comment.comment}
                        </Typography>
                      )}
                    </Box>
                  ))}
                {alignment === "summary" && (
                  <Box style={{ display: "flex" }}>
                    {summarizedComments.length > 0 && (
                      <Typography style={{ textAlign: "left" }}>
                        {summarizedComments}
                      </Typography>
                    )}
                  </Box>
                )}
              </Box>
            </>
          )}
        </Box>
      )}
    </Box>
  );
}

export default BaselineRes;
