import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import CloseIcon from "@mui/icons-material/Close";
import {
  CREATE_CUSTOM_SURVEY,
  GET_ALL_SURVEYS,
  UPDATE_SURVEY,
} from "../../../../redux/Dashboard/Dashboard.types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const metrics = [
  "Morale",
  "Engagement",
  "Retention Risk",
  "eNPS",
  "Topic",
  "Culture",
];

const targetGroups = {
  gender: ["Male", "Female", "Non-binary", "Transgender"],
  department: [
    "Accounting and Finance",
    "Engineering",
    "Human Resource",
    "Information Technology",
    "Operations",
    "Research and Development",
    "Sales and Marketing",
  ],
  region: [
    "Asia Pacific",
    "Europe",
    "Middle East/Africa",
    "North America",
    "South America",
  ],
  location: ["Headquarters", "Regional Office", "Home office"],
  length_of_service: [
    "<1 Year",
    "1-5 Years",
    "5-9 Years",
    "10+ Years",
    "Exited Employee",
  ],
  management_level: [
    "Executive",
    "SVP",
    "VP",
    "Director",
    "Manager",
    "Individual Contributor",
  ],
  ethnicity: [
    "African American",
    "Asian",
    "Hispanic",
    "Native American or Alaskan Native",
    "Native Hawaiian or Pacific Islander",
    "White",
    "Others",
  ],
  marital_status: ["Married", "Single"],
  Salary_level: [
    "<$50,000",
    "$50,001-$100,000",
    "$100,001-$250,000",
    "$250,001",
  ],
  age: ["<25", "26-35", "36-49", "50+"],
  performance: [
    "Outstanding",
    "Very Satisfactory",
    "Satisfactory",
    "Unsatisfactory",
    "Poor",
  ],
};

function CustomSurveyModal({ handleClose }) {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [desc, setDesc] = useState("");
  const [metric, setMetric] = useState("");
  const [filter1, setFilter1] = useState("gender");
  const [option1, setOption1] = useState("");
  const [filter2, setFilter2] = useState("gender");
  const [option2, setOption2] = useState("");

  const [allChecked, setAllChecked] = useState("");
  const [mulipleInput, setMultipleInputs] = useState([0]);

  function removeUnderscoreAndCapitalize(str) {
    return str
      .split('_') // Split by underscores
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join the words back together with spaces
  }
  const createSurvey = () => {
    let data = {
      surveyName: name,
      topic: metric,
      description: desc,
      targetGroup: {
        [`${filter1}`]: option1,
      },
    };

    if (mulipleInput.length > 1) {
      data["targetGroup"][`${filter2}`] = option2;
    }
    if (allChecked) {
      data["targetGroup"] = {};
    }
    dispatch({
      type: CREATE_CUSTOM_SURVEY,
      payload: data,
    });
    setTimeout(() => {
      dispatch({
        type: GET_ALL_SURVEYS,
      });
      handleClose();
    }, 2000);
  };

  const changeMetric = (event) => {
    setMetric(event.target.value);
  };
  const changeFilter1 = (event) => {
    setFilter1(event.target.value);
  };
  const changeOption1 = (event) => {
    setOption1(event.target.value);
  };

  const changeFilter2 = (event) => {
    setFilter2(event.target.value);
  };
  const changeOption2 = (event) => {
    setOption2(event.target.value);
  };
  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Create Your Own Survey
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />
      <Box
        sx={{
          margin: "1rem 0",
        }}
      ><Typography>Choose Metric</Typography>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={metric}
            label="Age"
            onChange={changeMetric}
          >
            {metrics.map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Typography sx={{ margin: "1rem 0" }}>Title</Typography>
        <Input
          type="text"
          placeholder=""
          sx={{ width: "100%" }}
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
        />
      </Box>
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Typography sx={{ margin: "1rem 0" }}>Description</Typography>
        <Input
          type="text"
          placeholder=""
          sx={{ width: "100%" }}
          value={desc}
          onChange={(e) => {
            setDesc(e.target.value);
          }}
        />
      </Box>

      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Typography>Target Group</Typography>
        <FormControlLabel
          control={
            <Checkbox
              value={allChecked}
              onChange={(e) => {
                setAllChecked(e.target.checked);
              }}
            />
          }
          label="All"
        />
        {!allChecked && (
          <Box>
            <Stack direction="row">
              <FormControl sx={{ margin: "1rem ", width: "50%" }}>
              <Typography>Choose Filter</Typography>
                
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={filter1}
                  onChange={changeFilter1}
                >
                  {Object.keys(targetGroups).map((item) => (
                    <MenuItem value={item}>{removeUnderscoreAndCapitalize(item)}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ margin: "1rem ", width: "50%" }}>
              <Typography>Choose Option</Typography>
                
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={option1}
                  onChange={changeOption1}
                >
                  {targetGroups[filter1].map((item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            {mulipleInput.length > 1 && (
              <Stack direction="row">
                <FormControl sx={{ margin: "1rem ", width: "50%" }}>
                <Typography>Choose Filter</Typography>
                  
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={filter2}
                    onChange={changeFilter2}
                  >
                    {Object.keys(targetGroups).map((item) => (
                      <MenuItem value={item}>{removeUnderscoreAndCapitalize(item)}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl sx={{ margin: "1rem ", width: "50%" }}>
                <Typography>Choose Option</Typography>
                  
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={option2}
                    onChange={changeOption2}
                  >
                    {targetGroups[filter2].map((item) => (
                      <MenuItem value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <CloseIcon
                  onClick={() => {
                    setMultipleInputs([0]);
                  }}
                  sx={{ position: "relative", top: "30px", color: "#727272" }}
                />
              </Stack>
            )}
            {mulipleInput.length < 2 && (
              <Typography
                onClick={() => {
                  const temp = mulipleInput;
                  setMultipleInputs([...temp, temp.length]);
                }}
              >
                +Add more
              </Typography>
            )}
          </Box>
        )}
      </Box>

      <Stack direction="row" spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={createSurvey}
          style={{
            background: "#D06283",
            color: "#fff",
          }}
          variant="contained"
        >
          Create
        </Button>
      </Stack>
    </Box>
  );
}

export default CustomSurveyModal;
