import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Input from "@mui/material/Input";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import { Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CloseIcon from "@mui/icons-material/Close";
import {
  GET_ALL_SURVEYS,
  INITIATE_ACTION_PLAN,
  UPDATE_SURVEY,
} from "../../../../../redux/Dashboard/Dashboard.types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function InitiateActionPlanModal({ handleClose, plan }) {
  const dispatch = useDispatch();
  const [duration, setDuration] = useState(30);
  const initiatePlan = () => {
    dispatch({ type: INITIATE_ACTION_PLAN, payload: { _id: plan._id } });
    setTimeout(() => {
      handleClose();
    }, 3000);
  };

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Initiate Action Plan
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />
      <Box style={{ margin: "1rem 0" }}>
        <FormControl fullWidth>
          {/* <InputLabel id="demo-simple-select-label">Duration</InputLabel> */}
          {/* <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={duration}
            label="Duration"
            onChange={(e) => {
              setDuration(e.target.value);
            }}
          >
            <MenuItem value={30}>30 Days</MenuItem>
            <MenuItem value={60}>60 Days</MenuItem>
            <MenuItem value={90}>90 Days</MenuItem>
          </Select> */}
          <TextField
            fullWidth
            type="number"
            id="outlined-basic"
            label="Enter number of days"
            variant="outlined"
            value={duration}
            onChange={(e) => {
              setDuration(e.target.value);
            }}
          />
        </FormControl>
      </Box>
      <Stack direction="row" spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={initiatePlan}
          style={{ background: "#D06283", color: "#fff" }}
          variant="contained"
        >
          Initiate
        </Button>
      </Stack>
    </Box>
  );
}

export default InitiateActionPlanModal;
