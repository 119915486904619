import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Tooltip } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import {
  GET_ENGAGEMENT_TOPICS,
  SAVE_ENGAGEMENT_TOPICS,
} from "../../../../../redux/Dashboard/Dashboard.types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function AddTopic({
  eventBased,
  setEventBased,
  topicBased,
  setTopicBased,
  handleClose,
}) {
  const dispatch = useDispatch();
  const engagementTopics = useSelector(
    (data) => data.dashboard.engagementTopics
  );

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const filters = useSelector((data) => data.dashboard.filters);

  const [keyTopics, setKeyTopics] = useState(() => {
    return JSON.parse(localStorage.getItem("keyTopicData")) || [];
  });

  const [topics, setTopics] = useState([""]);

  const handleTopicChange = (index, event) => {
    const newTopics = [...topics];
    newTopics[index] = event.target.value;
    setTopics(newTopics);
  };

  const addTopicField = () => {
    setTopics([...topics, ""]);
  };

  const removeTopicField = (index) => {
    const newTopics = topics.filter((_, i) => i !== index);
    setTopics(newTopics);
  };

  const handleSave = () => {
    if (topics.every((topic) => topic.trim() !== "")) {
      const newTopicList = [...engagementTopics, ...topics];

      const uniqueTopicList = Array.from(new Set(newTopicList));

      dispatch({
        type: SAVE_ENGAGEMENT_TOPICS,
        payload: {
          topicList: uniqueTopicList,
        },
      });
      uniqueTopicList.forEach((item) => {
        handleSearchTextChange(item);
      });
      handleClose();
    }
  };

  const handleSearchTextChange = (value) => {
    const tempData = keyTopics;
    tempData.push({ id: keyTopics.length + 1, text: value });
    localStorage.setItem(
      "keyTopicData",
      JSON.stringify(_.uniqBy(tempData, "text"))
    );
    setKeyTopics(tempData);
    // setSelected(value);
    dispatch({
      type: "SET_FILTERS",
      payload: { ...filters, keyTopic: [value] },
    });
  };

  useEffect(() => {
    dispatch({ type: GET_ENGAGEMENT_TOPICS });
  }, []);

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Add Topics
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Stack direction="column" spacing={2}>
          {topics.map((topic, index) => (
            <Stack direction="row" spacing={2} key={index} alignItems="center">
              <TextField
                fullWidth
                id={`topic-text-${index}`}
                label={`Topic ${index + 1}`}
                variant="outlined"
                value={topic}
                onChange={(event) => handleTopicChange(index, event)}
              />
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => removeTopicField(index)}
              >
                Remove
              </Button>
            </Stack>
          ))}
          <Typography variant="outlined" onClick={addTopicField}>
            <AddIcon style={{ position: "relative", top: "5px" }} /> Add
          </Typography>
        </Stack>
      </Box>{" "}
      <Stack direction="row" spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          style={{
            background: "#D06283",
            color: "#fff",
          }}
          variant="contained"
        >
          Save
        </Button>
      </Stack>
    </Box>
  );
}

export default AddTopic;
