import { useRef, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "./ItemTypes.js";
import { useDispatch, useSelector } from "react-redux";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { Tooltip } from "@material-ui/core";
import { ICONS } from "../../../../AppConfig";

export const Card = ({ id, text, index, moveCard, deleteTopic, selected }) => {
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  const style = { 
    // borderBottom: '1px dashed gray',
    padding: "0.5rem 1rem",
    marginBottom: ".5rem",
    backgroundColor: !isNight ? "#212047" : "white",
    cursor: "move",
    display: "flex",
    color: !isNight ? "#aebcc9" : "#666",
    justifyContent: "space-between",
    borderRadius: "5px",
  };
  const dispatch = useDispatch();
  const filters = useSelector((data) => data.dashboard.filters);

  const handleChange = (value) => {
    const keyTopic =
      JSON.parse(localStorage.getItem("keyTopicData")) !== null
        ? JSON.parse(localStorage.getItem("keyTopicData"))
        : [];
    const avialable = keyTopic.filter((item) => item.text === value);
    if (filters.keyTopic !== value && avialable.length>0) {
      dispatch({
        type: "SET_FILTERS",
        payload: { ...filters, keyTopic: [value] },
      });
    } else {
      // setSelected("");
      dispatch({
        type: "SET_FILTERS",
        payload: { ...filters, keyTopic: [""] },
      });
    }
  };
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <>
      <div
        ref={ref}
        style={{
          ...style,
          opacity,
          backgroundColor:
            filters.keyTopic[0] === text
              ? "#D06283"
              : !isNight
              ? "#212047"
              : "#f6f7fc",
        }}
        data-handler-id={handlerId}
        onClick={() => {
          handleChange(text);
        }}
      >
        <p
          style={{
            fontSize: "14px",
            margin: "0",
            color:
              filters.keyTopic[0] === text
                ? "#fff"
                : !isNight
                ? "#aebcc9"
                : "#666",
          }}
        >
          {text}
        </p>
        <Tooltip title="Unpin">
          <img
            src={ICONS.UNPIN}
            alt="prev"
            onClick={() => deleteTopic(text)}
            style={{
              width: "18px",
              height: "18px",
              opacity: "0.4",
              cursor:"pointer",
              filter:
                text === filters.keyTopic[0] && !isNight
                  ? "invert(1)"
                  : text === filters.keyTopic[0] && isNight
                  ? "invert(1)"
                  : !isNight
                  ? "invert(1)"
                  : "invert(0)",
            }}
          />
        </Tooltip>
      </div>
      <hr style={{ opacity: "0.2", color: !isNight ? "#aebcc9" : "#666" }} />
    </>
  );
};
