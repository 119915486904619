import React, { useState } from "react";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import Button from "@mui/material/Button";
import Modal from "@material-ui/core/Modal";
import ImportModal from "./ImportModal";
import HrisTable from "./HrisTable";
function EmpMgmt() {
  const [importModalOpen, setImportModalOpen] = useState(false);
  return (
    <div>
      <div className="userManagementContainer">
        <div className="userTable">
          <div style={{ width: "85%", color: "#000", margin: "0rem auto" }}>
            <div
              style={{ width: "100%", display: "flex", justifyContent: "end" }}
            >
              <Button
                onClick={() => {
                  setImportModalOpen(true);
                }}
                style={{
                  color: "rgb(255, 255, 255)",
                  background: "rgb(98, 60, 231)",
                  fontWeight: "600",
                  width: "125px",
                  padding: "5px 0px",
                  margin: "10px 0px 10px",
                  boxShadow: "rgb(0 0 0 / 5%) 0px 3.86842px 23.2105px",
                  borderRadius: "5px",
                }}
              >
                <CloudDownloadOutlinedIcon style={{ margin: "0 0.5rem 0 0" }} />
                <span>Import</span>
              </Button>
            </div>
            <HrisTable />
            <Modal
              open={importModalOpen}
              onClose={() => {
                setImportModalOpen(false);
              }}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <ImportModal
                open={importModalOpen}
                handleClose={() => {
                  setImportModalOpen(false);
                }}
                // importExcel={importExcel}
              />
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmpMgmt;
