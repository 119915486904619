import { Typography } from "@material-ui/core";
import React from "react";

// Message component to display individual messages
const Message = ({ message }) => {
  return (
    <div style={styles.messageContainer}>
      <strong>{message.name}</strong>: {message.message}
    </div>
  );
};

// ChatTranscript component to render the list of messages
const ChatTranscript = ({ messages }) => {
  return (
    <div style={styles.transcriptContainer}>
      <Typography
        variant="h2"
        style={{ fontSize: "1.5rem", fontWeight: "bold", textAlign: "left" }}
      >
        Chat Transcript
      </Typography>
      {messages &&
        messages.length > 0 &&
        messages.map((msg) => <Message key={msg._id} message={msg} />)}
    </div>
  );
};

// Styles for the components
const styles = {
  transcriptContainer: {
    padding: "20px",
    border: "1px solid #ddd",
    borderRadius: "5px",
    // maxWidth: "600px",
    margin: "0 auto",
    backgroundColor: "#f9f9f9",
  },
  messageContainer: {
    margin: "10px 0",
    padding: "10px",
    borderRadius: "5px",
    backgroundColor: "#fff",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
  },
};

export default ChatTranscript;
