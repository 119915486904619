import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useHistory } from "react-router-dom";

// import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';

import {
  SET_CURRENT_SOURCE,
  SET_LOADING,
} from "../../redux/Dashboard/Dashboard.types";
import Checkbox from "@mui/material/Checkbox";
import { TextField } from "@material-ui/core";

export default function TimeFilterSelect({
  workflowChangeHandler,
  currentWorkflowName,
  currentWorkflowID,
  dateRange,
  setDateRange,
  dateRangeOption,
  setDateRangeOption,
  onDateChange,
  setCurrentFilter,
}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const [open, setOpen] = useState(false);

  const toggleList = () => {
    setOpen(!open);
  };

  const handleOptionClick = (option) => {
    const today = new Date();
    let start, end;

    switch (option) {
      case "thisWeek":
        start = new Date(today.setDate(today.getDate() - today.getDay()));
        end = new Date();
        break;
      case "7Days":
        start = new Date(today.setDate(today.getDate() - 7));
        end = new Date();
        break;
      case "thisMonth":
        start = new Date(today.getFullYear(), today.getMonth(), 1);
        end = new Date();
        break;
      case "30Days":
        start = new Date(today.setDate(today.getDate() - 30));
        end = new Date();
        break;
      case "60Days":
        start = new Date(today.setDate(today.getDate() - 60));
        end = new Date();
        break;
      case "90Days":
        start = new Date(today.setDate(today.getDate() - 90));
        end = new Date();
        break;
      case "all":
        start = new Date("02/01/2022");
        end = new Date();
        break;
      default:
        start = end = null;
        break;
    }

    setDateRange({
      start: start ? start.toISOString().split("T")[0] : "",
      end: end ? end.toISOString().split("T")[0] : "",
    });

    setDateRangeOption(option);
    // setOpen(false);
  };
  const handleApply = () => {
    setCurrentFilter("custom");
    // onDateChange("start", dateRange.start);
    onDateChange(dateRange);
    // onDateChange("end", dateRange.end);

    setOpen(false);
  };
  return (
    <>
      <div style={{ position: "relative" }}>
        <div
          className="dropdown"
          style={{
            fontSize: "0.8rem",
            padding: "0.1rem 0.5rem",
            position: "relative",
            top: "1px",
            cursor: "pointer",
          }}
          onClick={toggleList}
        >
          <span
            style={{ position: "relative", top: "-5px", cursor: "pointer" }}
          >
            {dateRangeOption === "custom"
              ? "Custom"
              : dateRangeOption === "thisWeek"
              ? "This Week so far"
              : dateRangeOption === "7Days"
              ? "Last 7 Days"
              : dateRangeOption === "thisMonth"
              ? "This month so far"
              : dateRangeOption === "30Days"
              ? "Last 30 Days"
              : dateRangeOption === "60Days"
              ? "Last 60 Days"
              : dateRangeOption === "90Days"
              ? "Last 90 Days"
              : dateRangeOption === "all"
              ? "All"
              : "All"}
          </span>
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </div>
        {open && (
          <div
            style={{
              position: "absolute",
              zIndex: 500,
              right: "0",
            }}
          >
            <div
              style={{
                width: "500px",
                height: "480px",
                background: "#fff",
                right: "0",
                padding: "0.5rem 1rem",
                borderRadius: "8px",
                boxShadow:
                  "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                position: "relative",
                top: "20px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {
                  <>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        margin: "2rem 0 1rem",
                      }}
                    >
                      <div>
                        <div
                          onClick={() => {
                            handleOptionClick("custom");
                          }}
                          style={{
                            color:
                              dateRangeOption === "custom" ? "#fff" : "#484848",
                            margin: "1rem 0 ",
                            cursor: "pointer",
                            background:
                              dateRangeOption === "custom" ? "#D06283" : "#fff",
                            border: "1px solid #D06283",
                            padding: "0.5rem",
                            margin: "0.5rem",
                            borderRadius: "5px",
                            fontSize: "0.8rem",
                          }}
                        >
                          Custom
                        </div>
                        <div
                          onClick={() => {
                            handleOptionClick("thisWeek");
                          }}
                          style={{
                            color:
                              dateRangeOption === "thisWeek"
                                ? "#fff"
                                : "#484848",
                            margin: "1rem 0 ",
                            cursor: "pointer",
                            background:
                              dateRangeOption === "thisWeek"
                                ? "#D06283"
                                : "#fff",
                            border: "1px solid #D06283",
                            padding: "0.5rem",
                            margin: "0.5rem",
                            borderRadius: "5px",
                            fontSize: "0.8rem",
                          }}
                        >
                          This Week so far
                        </div>
                        <div
                          onClick={() => {
                            handleOptionClick("7Days");
                          }}
                          style={{
                            color:
                              dateRangeOption === "7Days" ? "#fff" : "#484848",
                            margin: "1rem 0 ",
                            cursor: "pointer",
                            background:
                              dateRangeOption === "7Days" ? "#D06283" : "#fff",
                            border: "1px solid #D06283",
                            padding: "0.5rem",
                            margin: "0.5rem",
                            borderRadius: "5px",
                            fontSize: "0.8rem",
                          }}
                        >
                          Last 7 Days
                        </div>
                        <div
                          onClick={() => {
                            handleOptionClick("thisMonth");
                          }}
                          style={{
                            color:
                              dateRangeOption === "thisMonth"
                                ? "#fff"
                                : "#484848",
                            margin: "1rem 0 ",
                            cursor: "pointer",
                            background:
                              dateRangeOption === "thisMonth"
                                ? "#D06283"
                                : "#fff",
                            border: "1px solid #D06283",
                            padding: "0.5rem",
                            margin: "0.5rem",
                            borderRadius: "5px",
                            fontSize: "0.8rem",
                          }}
                        >
                          This month so far
                        </div>
                        <div
                          onClick={() => {
                            handleOptionClick("30Days");
                          }}
                          style={{
                            color:
                              dateRangeOption === "30Days" ? "#fff" : "#484848",
                            margin: "1rem 0 ",
                            cursor: "pointer",
                            background:
                              dateRangeOption === "30Days" ? "#D06283" : "#fff",
                            border: "1px solid #D06283",
                            padding: "0.5rem",
                            margin: "0.5rem",
                            borderRadius: "5px",
                            fontSize: "0.8rem",
                          }}
                        >
                          Last 30 Days
                        </div>
                        <div
                          onClick={() => {
                            handleOptionClick("60Days");
                          }}
                          style={{
                            color:
                              dateRangeOption === "60Days" ? "#fff" : "#484848",
                            margin: "1rem 0 ",
                            cursor: "pointer",
                            background:
                              dateRangeOption === "60Days" ? "#D06283" : "#fff",
                            border: "1px solid #D06283",
                            padding: "0.5rem",
                            margin: "0.5rem",
                            borderRadius: "5px",
                            fontSize: "0.8rem",
                          }}
                        >
                          Last 60 Days
                        </div>
                        <div
                          onClick={() => {
                            handleOptionClick("90Days");
                          }}
                          style={{
                            color:
                              dateRangeOption === "90Days" ? "#fff" : "#484848",
                            margin: "1rem 0 ",
                            cursor: "pointer",
                            background:
                              dateRangeOption === "90Days" ? "#D06283" : "#fff",
                            border: "1px solid #D06283",
                            padding: "0.5rem",
                            margin: "0.5rem",
                            borderRadius: "5px",
                            fontSize: "0.8rem",
                          }}
                        >
                          Last 90 Days
                        </div>
                        <div
                          onClick={() => {
                            handleOptionClick("all");
                          }}
                          style={{
                            color:
                              dateRangeOption === "all" ? "#fff" : "#484848",
                            margin: "1rem 0 ",
                            cursor: "pointer",
                            background:
                              dateRangeOption === "all" ? "#D06283" : "#fff",
                            border: "1px solid #D06283",
                            padding: "0.5rem",
                            margin: "0.5rem",
                            borderRadius: "5px",
                            fontSize: "0.8rem",
                          }}
                        >
                          All
                        </div>
                      </div>
                      <div style={{ display: "flex" }}>
                        <span style={{ margin: "0 1rem" }}>
                          <p style={{ textAlign: "left" }}>From</p>
                          <TextField
                            // label="FROM"
                            type="date"
                            value={dateRange.start}
                            // className={classes.textField}
                            // InputLabelProps={{
                            //   className: classes.input,
                            // }}
                            onChange={(event) => {
                              setDateRange({
                                start: event.target.value,
                                end: dateRange.end,
                              });
                              setDateRangeOption("custom");
                            }}
                          />
                        </span>
                        <span style={{ margin: "0 1rem" }}>
                          <p style={{ textAlign: "left" }}>To</p>
                          <TextField
                            // label="To"
                            type="date"
                            value={dateRange.end}
                            // className={classes.textField}
                            // InputLabelProps={{
                            //   className: classes.input,
                            // }}
                            onChange={(event) => {
                              setDateRange({
                                start: dateRange.start,
                                end: event.target.value,
                              });
                              setDateRangeOption("custom");
                            }}
                          />
                        </span>
                      </div>
                    </div>{" "}
                    <div style={{ display: "flex", width: "100%" }}>
                      <div
                        onClick={handleApply}
                        style={{
                          cursor: "pointer",
                          width: "100%",
                          margin: "0.5rem",
                          background: "#D06283",
                          color: "#fff",
                          padding: "0.5rem",
                          boxShadow:
                            "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                        }}
                      >
                        Apply
                      </div>
                    </div>{" "}
                  </>
                }
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
