import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import TextField from "@material-ui/core/TextField";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";

export default function CustomDatePicker({
  open,
  handleClose,
  handleDateChange,
  currentDate,
}) {
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  const useStyles = makeStyles((theme) => ({
    root: {
      color: "#fff",
      padding: "0!important",
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "column",
      "& div": {
        color: "#fff",
      },
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
      marginTop: 20,
      color: !isNight ? "#aebcc9 !important" : "rgba(0, 0, 0, 0.87)!important",
      borderBlockColor: "#6352C6",
    },
    input: {
      color: !isNight ? "#aebcc9" : "#484848",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    paper: {
      padding: 0,
    },
  }));

  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <DialogContent classes={{ root: classes.root }}>
        <div
          className="popUp"
          style={{
            padding: "30px 20px",
            backgroundColor: !isNight ? "#212047" : "#e5e5e5",
          }}
        >
          <div
            style={{
              marginBottom: "10px",
              color: !isNight ? "#aebcc9" : "#484848",
            }}
          >
            Choose a date range{" "}
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>
              <Typography style={{color:"#484848"}}>From</Typography>
              <TextField
                id="fromRange"
                // label="FROM"
                type="date"
                className={classes.textField}
                InputLabelProps={{
                  className: classes.input,
                }}
                onChange={(event) => {
                  handleDateChange("start", event.target.value);
                }}
              />
            </div>
            <div>
              <Typography style={{color:"#484848"}}>To</Typography>
              <TextField
                id="fromRange"
                // label="To"
                type="date"
                className={classes.textField}
                InputLabelProps={{
                  className: classes.input,
                }}
                onChange={(event) => {
                  handleDateChange("end", event.target.value);
                }}
              />
            </div>
          </div>

          <div className="btnOptions">
            <div className="cancelBtn" onClick={handleClose}>
              <div className="rangeBtn">
                <ClearIcon
                  style={{ color: !isNight ? "#aebcc9" : "#484848" }}
                />
                <div
                  className="btnText"
                  style={{ color: !isNight ? "#aebcc9" : "#484848" }}
                >
                  Cancel
                </div>
              </div>
            </div>
            <div className="submitBtn" onClick={handleClose}>
              <div className="rangeBtn" type="submit">
                <CheckIcon />
                <div className="btnText">Submit</div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
