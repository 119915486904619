import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { toast } from "react-toastify";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Box, Typography, Button, withStyles } from "@material-ui/core";
import {
  UPLOAD_SOURCE_FILES,
  SAVE_WORKFLOW,
} from "../../../../redux/Dashboard/Dashboard.types";

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

export default function FileUploader({ handleClose }) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentFile, setCurrentFile] = useState();
  const [workflowName, setWorkflowName] = useState("");
  const [progress, setProgess] = useState({
    message: "",
    percentage: 0,
    isError: false,
  });
  const uploadStatus = useSelector((data) => data.dashboard.uploadSourceStatus);
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const uploadedChannels = useSelector(
    (data) => data.dashboard.uploadSourceStatus
  );
  // eslint-disable-next-line no-underscore-dangle
  const user = JSON.parse(localStorage.getItem("userData"));
  const userID = user?.userId;
  const dispatch = useDispatch();

  // const handleSave = () => {
  //   if (!isDemo) {
  //     dispatch({
  //       type: SAVE_WORKFLOW,
  //       payload: {
  //         workflowID: userID + Math.round(new Date().getTime() / 1000),
  //         workflow: elements,
  //         token: accessToken && accessToken?.data?.authed_user?.access_token,
  //         teamFilters: channelsToFetch,
  //         discordServerID,
  //         workflowName,
  //         userID,
  //         clientID: JSON.parse(localStorage.getItem("userData")).userId,
  //         fileName: uploadSourceStatus.fileName,
  //         model_config: modelsConfigData,
  //         action_config: actionConfigData,
  //       },
  //     });
  //   } else {
  //     var demoWork = userWorkflows;
  //     demoWork.data?.Workflows?.push({
  //       workflowID: userID + Math.round(new Date().getTime() / 1000),
  //       workflowName,
  //     });
  //     dispatch({ type: SAVE_DEMO_WORK, payload: demoWork });
  //     setTimeout(() => {
  //       toast.success("Workflow Saved");
  //     }, 1000);
  //   }
  // };

  useEffect(() => {
    if (uploadStatus.status === "FAIL") {
      setProgess({
        message: "FAILED!! Please upload the correct file",
        percentage: 0,
        isError: true,
      });
    } else if (uploadStatus.status === "PASS") {
      setProgess({
        message: "File Successfully uploaded",
        percentage: 100,
        isError: false,
      });
      // handleSave();
    } else if (uploadStatus.status === "EMPTY") {
      setProgess({
        message: "",
        percentage: 0,
        isError: false,
      });
    }
  }, [uploadStatus]);

  const selectFile = (event) => {
    setSelectedFiles(event.target.files);
  };

  const upload = () => {
    const file = selectedFiles[0];
    if (!file) {
      toast.error("Please select file first");
    } else {
      setCurrentFile(file);
      !isDemo &&
        dispatch({
          type: UPLOAD_SOURCE_FILES,
          payload: {
            file,
            userID,
            fileName: selectedFiles[0].name,
          },
        });
    }

    isDemo &&
      setTimeout(() => {
        setProgess({
          message: "File Successfully uploaded",
          percentage: 100,
          isError: false,
        });
      }, 2000);
  };

  const handleFileClose = () => {
    setProgess(
      {
        message: "",
        percentage: 0,
        isError: false,
      },
      handleClose(false)
    );
  };

  const handleSaveWorkflow = () => {
    if (!isDemo) {
      const tempChannel = [];
      const work = [
        {
          id: "dndnode_0",
          type: "input",
          position: {
            x: 50.77777099609375,
            y: 150.99999237060547,
          },
          targetPosition: "left",
          sourcePosition: "right",
          data: {
            label: "Archive",
          },
          style: {
            backgroundRepeat: "no-repeat",
            backgroundSize: "40% 40%",
            backgroundOrigin: "content-box",
            fontSize: "14px",
            color: "#aebcc9",
            fontWeight: "600",
            borderRadius: "8px ",
            background:
              "url(/static/media/upload.dda4abc2.svg) no-repeat center center",
            border: "3px solid #5FA7F5",
          },
        },
      ];
      uploadedChannels?.channels?.forEach((item, id) => {
        tempChannel.push({
          id: `dndnode_${id + 1}`,
          type: "default",
          position: {
            x: 265.77777099609375,
            y: (id + 1) * 180.99999237060547,
          },
          targetPosition: "left",
          sourcePosition: "right",
          data: {
            label: item,
          },
          style: {
            backgroundRepeat: "no-repeat",
            backgroundSize: "40% 40%",
            backgroundOrigin: "content-box",
            fontSize: "14px",
            color: "#aebcc9",
            fontWeight: "600",
            borderRadius: "8px ",
            background:
              "url(https://chec-front.s3.amazonaws.com/icons/channel.svg) no-repeat center center",
            border: "3px solid #FFA300",
          },
        });
        tempChannel.push({
          source: "dndnode_0",
          sourceHandle: null,
          target: `dndnode_${id + 1}`,
          targetHandle: null,
          arrowHeadType: "arrowclosed",
          animated: true,
          style: {
            stroke: "red",
          },
          id: "reactflow__edge-dndnode_0null-dndnode_1null",
        });
      });
      work.push(...tempChannel);
      const Models = [
        "Emotional",
        "Personal Information",
        "Profanity",
        "Hate Speech",
        "Textual Abuse",
        "Insult Detection",
        "Sexual Harrasment",
      ];
      const tempModels = [];
      Models.forEach((item, id) => {
        tempModels.push({
          id: `dndnode_${tempChannel.length + id + 1}`,
          type: "default",
          position: {
            x: 510.77777099609375,
            y: (id + 1) * 180.99999237060547,
          },
          targetPosition: "left",
          sourcePosition: "right",
          data: {
            label: item,
          },
          style: {
            backgroundRepeat: "no-repeat",
            backgroundSize: "40% 40%",
            backgroundOrigin: "content-box",
            fontSize: "14px",
            color: "#aebcc9",
            fontWeight: "600",
            borderRadius: "8px ",
            background:
              "url(/static/media/intellectual.bf40f944.svg) no-repeat center center",
            border: "3px solid #4cda89",
          },
        });
      });
      work.push(...tempModels);
      work.push({
        id: `dndnode_${work.length + 1}`,
        type: "default",
        position: {
          x: 752.8959970605468,
          y: 180.0627866931152,
        },
        targetPosition: "left",
        sourcePosition: "right",
        data: {
          label: "Email",
        },
        style: {
          backgroundRepeat: "no-repeat",
          backgroundSize: "40% 40%",
          backgroundOrigin: "content-box",
          fontSize: "14px",
          color: "#aebcc9",
          fontWeight: "600",
          borderRadius: "8px ",
          background:
            "url(/static/media/email.ab571ebd.svg) no-repeat center center",
          border: "3px solid #FF0892",
        },
      });
      dispatch({
        type: SAVE_WORKFLOW,
        payload: {
          workflowID: userID + Math.round(new Date().getTime() / 1000),
          workflow: work,
          dm:uploadStatus.dms,
          workflowName,
          userID,
          clientID: JSON.parse(localStorage.getItem("userData")).userId,
          fileName: uploadStatus.fileName,
          model_config: {
            emotionData: [
              {
                id: "dndnode_5",
                data: {
                  emotionData: {
                    joy: 2,
                    sadness: 3,
                    love: 3,
                    anger: 2,
                    fear: 2,
                    surprise: 3,
                    neutral: 3,
                  },
                },
              },
            ],
            personalInfoData: [
              {
                id: "dndnode_6",
                data: {
                  personalInfoData: [
                    "cardDetails",
                    "DOB",
                    "drivingLicence",
                    "email",
                    "IPaddress",
                    "otp",
                    "MACaddress",
                    "passportNumber",
                    "phoneNumber",
                    "SSN",
                    "VIN",
                    "ZIP",
                    "userName",
                    "password",
                  ],
                },
              },
            ],
            profanityData: [
              {
                id: "dndnode_7",
                data: {
                  profanity: 3,
                },
              },
            ],
            hateSpeechData: [
              {
                id: "dndnode_8",
                data: {
                  hateSpeechData: {
                    any: null,
                  },
                },
              },
            ],
            scriptData: [],
            textAbuseData: [
              {
                id: "dndnode_9",
                data: {
                  textualAbuseData: {
                    positive: 1,
                    negative: 2,
                    neutral: 1,
                    toxic: 3,
                    severe_toxic: 1,
                    obscene: 2,
                    threat: 2,
                    insult: 0,
                    identity_hate: 4,
                  },
                },
              },
            ],
            sexualHarassmentData: [
              {
                id: "dndnode_11",
                data: {
                  SexualHarassmentData: {
                    any: null,
                  },
                },
              },
            ],
            RacismData: [],
            SentimentData: [],
            insultData: [
              {
                id: "dndnode_10",
                data: {
                  insultData: {
                    any: null,
                  },
                },
              },
            ],
            sexismData: [],
            diversityData: [],
          },
          action_config: {
            email: [
              {
                id: "dndnode_12",
                data: {
                  toEmail: "test@scanta.io",
                  emailSubject: "test",
                  emailBody: "test",
                },
              },
            ],
            sms: [],
            alert: [],
            notification: [],
          },
        },
      });
      handleClose();
    }
  };
  return (
    <div className="">
      {currentFile && (
        <Box className="mb25" display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <BorderLinearProgress
              variant="determinate"
              value={progress.percentage}
            />
          </Box>
          <Box minWidth={35}>
            <Typography
              variant="body2"
              color="textSecondary"
            >{`${progress.percentage}%`}</Typography>
          </Box>
        </Box>
      )}
      <div className="">
        <div className="fileNameAndInput" style={{ margin: "1rem 0" }}>
          <label htmlFor="btn-upload">
            <input
              id="btn-upload"
              name="btn-upload"
              style={{ display: "none" }}
              type="file"
              onChange={selectFile}
            />
            <Button className="btn-choose" variant="outlined" component="span">
              Choose Files
            </Button>
          </label>
          <div className="file-name">
            {selectedFiles && selectedFiles.length > 0
              ? selectedFiles[0].name
              : null}
          </div>
        </div>
        <Button
          className="btn-upload"
          color="#D06283"
          variant="contained"
          component="span"
          disabled={!selectedFiles}
          onClick={upload}
          style={{ backgroundColor: "#D06283", color:"#fff"}}
        >
          Upload
        </Button>
      </div>
      <Typography
        variant="subtitle2"
        className={`upload-message ${progress.isError ? "error" : ""}`}
      >
        {progress.message}
      </Typography>
    </div>
  );
}
