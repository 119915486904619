import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TurnedInNotIcon from "@material-ui/icons/TurnedInNot";
import ClearIcon from "@material-ui/icons/Clear";
import {
  Dialog,
  DialogContent,
  Slider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl,
} from "@material-ui/core/";
import { toast } from "react-toastify";
import { SET_MODEL_CONFIGURATION } from "../../../../redux/Dashboard/Dashboard.types";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#000",
    padding: "0!important",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    "& div": {
      color: "#000",
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  submitButton: {
    background: "#6352C6",
    borderRadius: "6px",
  },
  paper: {
    padding: 0,
  },
}));

function ModelConfigModal({ open, handleClose, selectedModel }) {
  const classes = useStyles();

  const modelsConfigData = useSelector(
    (data) => data.dashboard.modelsConfigData
  );
  const dispatch = useDispatch();
  const [emotions, setEmotions] = useState([
    { id: 1, name: "joy", value: 0, label: "Joy" },
    { id: 2, name: "sadness", value: 0, label: "Sadness" },
    { id: 3, name: "love", value: 0, label: "Love" },
    { id: 4, name: "anger", value: 0, label: "Anger" },
    { id: 5, name: "fear", value: 0, label: "Fear" },
    { id: 6, name: "surprise", value: 0, label: "Surprise" },
    {id: 7, name: "neutral", value: 0, label: "Neutral" },
  ]);
  const [personalInfos, setPersonalInfos] = useState([
    {
      id: 1,
      name: "cardDetails",
      label: "Card Details",
      isChecked: false,
    },
    {
      id: 2,
      name: "DOB",
      label: "Date of Birth",
      isChecked: false,
    },
    {
      id: 3,
      name: "drivingLicence",
      label: "Driving Licence",
      isChecked: false,
    },
    { id: 4, name: "email", label: "Email id", isChecked: false },
    { id: 5, name: "IPaddress", label: "IP Address", isChecked: false },
    { id: 6, name: "otp", label: "OTP", isChecked: false },
    { id: 7, name: "MACaddress", label: "MAC Address", isChecked: false },
    {
      id: 8,
      name: "passportNumber",
      label: "Passport Number",
      isChecked: false,
    },
    { id: 9, name: "phoneNumber", label: "Phone Number", isChecked: false },
    {
      id: 10,
      name: "SSN",
      label: "Social Security Number (SSN)",
      isChecked: false,
    },
    {
      id: 11,
      name: "VIN",
      label: "Vehicle Identification Number (VIN)",
      isChecked: false,
    },
    { id: 12, name: "ZIP", label: "ZIP Code", isChecked: false },
    { id: 13, name: "userName", label: "User Name", isChecked: false },
    { id: 14, name: "password", label: "Password", isChecked: false },
  ]);

  const [profanity, setProfanity] = useState(0);
  const [sentiments, setSentiments] = useState([
    { id: 1, name: "positive", label: "Positive", isChecked: false, value: 0 },
    { id: 2, name: "negative", label: "Negative", isChecked: false, value: 0 },
    { id: 3, name: "neutral", label: "Neutral", isChecked: false, value: 0 },
  ]);

  const [sentiments1, setSentiments1] = useState([
    { id: 1, name: "positive", label: "Positive", isChecked: false, value: 0 },
    { id: 2, name: "negative", label: "Negative", isChecked: false, value: 0 },
    // { id: 3, name: "neutral", label: "Neutral", isChecked: false, value: 0 },
  ]);

  const [abuses, setAbuses] = useState([
    { id: 1, name: "toxic", label: "Toxic", isChecked: false, value: 0 },
    {
      id: 2,
      name: "severe_toxic",
      label: "Severe Toxic",
      isChecked: false,
      value: 0,
    },
    { id: 3, name: "obscene", label: "Obscene", isChecked: false, value: 0 },
    { id: 4, name: "threat", label: "Threat", isChecked: false, value: 0 },
    { id: 5, name: "insult", label: "Insult", isChecked: false, value: 0 },
    {
      id: 6,
      name: "identity_hate",
      label: "Identity Hate",
      isChecked: false,
      value: 0,
    },
  ]);

  const [hateSpeeches, sethateSpeeches] = useState([
    { id: 1, name: "any", label: "Any", isChecked: false, value: null },
    {
      id: 2,
      name: "percentage",
      label: "Percentage",
      isChecked: false,
      value: 0,
    },
  ]);

  const [scripts, setScripts] = useState([
    { id: 1, name: "any", label: "Any", isChecked: false, value: null },
    {
      id: 2,
      name: "percentage",
      label: "Percentage",
      isChecked: false,
      value: 0,
    },
  ]);
  const [sexualHarassments, setSexualHarassments] = useState([
    { id: 1, name: "any", label: "Any", isChecked: false, value: null },
    {
      id: 2,
      name: "percentage",
      label: "Percentage",
      isChecked: false,
      value: 0,
    },
  ]);
  const [racisms, setRacisms] = useState([
    { id: 1, name: "any", label: "Any", isChecked: false, value: null },
    {
      id: 2,
      name: "percentage",
      label: "Percentage",
      isChecked: false,
      value: 0,
    },
  ]);

  const [insults, setInsults] = useState([
    { id: 1, name: "any", label: "Any", isChecked: false, value: null },
    {
      id: 2,
      name: "percentage",
      label: "Percentage",
      isChecked: false,
      value: 0,
    },
  ]);

  const [sexisms, setSexisms] = useState([
    { id: 1, name: "any", label: "Any", isChecked: false, value: null },
    {
      id: 2,
      name: "percentage",
      label: "Percentage",
      isChecked: false,
      value: 0,
    },
  ]);

  const [diversities, setDiversities] = useState([
    { id: 1, name: "any", label: "Any", isChecked: false, value: null },
    {
      id: 2,
      name: "percentage",
      label: "Percentage",
      isChecked: false,
      value: 0,
    },
  ]);

  const handleEmotions = (e, currentValue, data) => {
    const emotionsCopy = [...emotions];
    emotionsCopy[data.id - 1] = {
      ...emotionsCopy[data.id - 1],
      value: currentValue,
    };
    setEmotions(emotionsCopy);
  };
  const handlePersonalInfos = (e, value, data) => {
    const personalInfoCopy = [...personalInfos];
    personalInfoCopy[data.id - 1] = {
      ...personalInfoCopy[data.id - 1],
      isChecked: value,
    };
    setPersonalInfos(personalInfoCopy);
  };
  const handleProfanity = (e, value) => {
    setProfanity(value);
  };

  const handleTextAbuse = (e, currentValue, data, type) => {
    if (type[0] === "sentiments") {
      const sentimentCopy = [...sentiments];
      sentimentCopy[data.id - 1] = {
        id: data.id,
        name: data.name,
        label: data.label,
        isChecked: type[1] === "checkbox" ? currentValue : data.isChecked,
        value: type[1] === "slider" ? currentValue : data.value,
      };
      setSentiments(sentimentCopy);
    }
    if (type[0] === "abuse") {
      const abuseCopy = [...abuses];
      abuseCopy[data.id - 1] = {
        id: data.id,
        name: data.name,
        label: data.label,
        isChecked: type[1] === "checkbox" ? currentValue : data.isChecked,
        value: type[1] === "slider" ? currentValue : data.value,
      };
      setAbuses(abuseCopy);
    }
  };
  const handleSentiment = (e, currentValue, data, type) => {
    const sentimentCopy = [...sentiments1];
    sentimentCopy[data.id - 1] = {
      id: data.id,
      name: data.name,
      label: data.label,
      isChecked: type[1] === "checkbox" ? currentValue : data.isChecked,
      value: type[1] === "slider" ? currentValue : data.value,
    };
    setSentiments1(sentimentCopy);
  };

  const handleHateSpeech = (e, currentValue, data, type) => {
    const HateSpeechCopy = [...hateSpeeches];
    HateSpeechCopy[data.id - 1] = {
      id: data.id,
      name: data.name,
      label: data.label,
      isChecked: type === "radio" ? currentValue : data.isChecked,
      value: type === "slider" ? currentValue : data.value,
    };
    if (data.id === 1) {
      HateSpeechCopy[0] = {
        ...HateSpeechCopy[0],
        isChecked: type === "radio" ? currentValue : data.isChecked,
        value: type === "slider" ? currentValue : data.value,
      };
      HateSpeechCopy[1] = {
        ...HateSpeechCopy[1],
        isChecked: false,
      };
    } else if (data.id === 2) {
      HateSpeechCopy[1] = {
        ...HateSpeechCopy[1],
        isChecked: type === "radio" ? currentValue : data.isChecked,
        value: type === "slider" ? currentValue : data.value,
      };
      HateSpeechCopy[0] = {
        ...HateSpeechCopy[0],
        isChecked: false,
      };
    }
    sethateSpeeches(HateSpeechCopy);
  };
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const handleScript = (e, currentValue, data, type) => {
    const ScriptCopy = [...scripts];
    ScriptCopy[data.id - 1] = {
      id: data.id,
      name: data.name,
      label: data.label,
      isChecked: type === "radio" ? currentValue : data.isChecked,
      value: type === "slider" ? currentValue : data.value,
    };
    if (data.id === 1) {
      ScriptCopy[0] = {
        ...ScriptCopy[0],
        isChecked: type === "radio" ? currentValue : data.isChecked,
        value: type === "slider" ? currentValue : data.value,
      };
      ScriptCopy[1] = {
        ...ScriptCopy[1],
        isChecked: false,
      };
    } else if (data.id === 2) {
      ScriptCopy[1] = {
        ...ScriptCopy[1],
        isChecked: type === "radio" ? currentValue : data.isChecked,
        value: type === "slider" ? currentValue : data.value,
      };
      ScriptCopy[0] = {
        ...ScriptCopy[0],
        isChecked: false,
      };
    }
    setScripts(ScriptCopy);
  };

  const handleSexualHarassment = (e, currentValue, data, type) => {
    const SexualHarassmentCopy = [...sexualHarassments];
    SexualHarassmentCopy[data.id - 1] = {
      id: data.id,
      name: data.name,
      label: data.label,
      isChecked: type === "radio" ? currentValue : data.isChecked,
      value: type === "slider" ? currentValue : data.value,
    };
    if (data.id === 1) {
      SexualHarassmentCopy[0] = {
        ...SexualHarassmentCopy[0],
        isChecked: type === "radio" ? currentValue : data.isChecked,
        value: type === "slider" ? currentValue : data.value,
      };
      SexualHarassmentCopy[1] = {
        ...SexualHarassmentCopy[1],
        isChecked: false,
      };
    } else if (data.id === 2) {
      SexualHarassmentCopy[1] = {
        ...SexualHarassmentCopy[1],
        isChecked: type === "radio" ? currentValue : data.isChecked,
        value: type === "slider" ? currentValue : data.value,
      };
      SexualHarassmentCopy[0] = {
        ...SexualHarassmentCopy[0],
        isChecked: false,
      };
    }
    setSexualHarassments(SexualHarassmentCopy);
  };
  const handleRacism = (e, currentValue, data, type) => {
    const RacismCopy = [...racisms];
    RacismCopy[data.id - 1] = {
      id: data.id,
      name: data.name,
      label: data.label,
      isChecked: type === "radio" ? currentValue : data.isChecked,
      value: type === "slider" ? currentValue : data.value,
    };
    if (data.id === 1) {
      RacismCopy[0] = {
        ...RacismCopy[0],
        isChecked: type === "radio" ? currentValue : data.isChecked,
        value: type === "slider" ? currentValue : data.value,
      };
      RacismCopy[1] = {
        ...RacismCopy[1],
        isChecked: false,
      };
    } else if (data.id === 2) {
      RacismCopy[1] = {
        ...RacismCopy[1],
        isChecked: type === "radio" ? currentValue : data.isChecked,
        value: type === "slider" ? currentValue : data.value,
      };
      RacismCopy[0] = {
        ...RacismCopy[0],
        isChecked: false,
      };
    }
    setRacisms(RacismCopy);
  };

  const handleInsults = (e, currentValue, data, type) => {
    const InsultCopy = [...insults];
    InsultCopy[data.id - 1] = {
      id: data.id,
      name: data.name,
      label: data.label,
      isChecked: type === "radio" ? currentValue : data.isChecked,
      value: type === "slider" ? currentValue : data.value,
    };
    if (data.id === 1) {
      InsultCopy[0] = {
        ...InsultCopy[0],
        isChecked: type === "radio" ? currentValue : data.isChecked,
        value: type === "slider" ? currentValue : data.value,
      };
      InsultCopy[1] = {
        ...InsultCopy[1],
        isChecked: false,
      };
    } else if (data.id === 2) {
      InsultCopy[1] = {
        ...InsultCopy[1],
        isChecked: type === "radio" ? currentValue : data.isChecked,
        value: type === "slider" ? currentValue : data.value,
      };
      InsultCopy[0] = {
        ...InsultCopy[0],
        isChecked: false,
      };
    }
    setInsults(InsultCopy);
  };

  const handleSexisms = (e, currentValue, data, type) => {
    const sexismCopy = [...sexisms];
    sexismCopy[data.id - 1] = {
      id: data.id,
      name: data.name,
      label: data.label,
      isChecked: type === "radio" ? currentValue : data.isChecked,
      value: type === "slider" ? currentValue : data.value,
    };
    if (data.id === 1) {
      sexismCopy[0] = {
        ...sexismCopy[0],
        isChecked: type === "radio" ? currentValue : data.isChecked,
        value: type === "slider" ? currentValue : data.value,
      };
      sexismCopy[1] = {
        ...sexismCopy[1],
        isChecked: false,
      };
    } else if (data.id === 2) {
      sexismCopy[1] = {
        ...sexismCopy[1],
        isChecked: type === "radio" ? currentValue : data.isChecked,
        value: type === "slider" ? currentValue : data.value,
      };
      sexismCopy[0] = {
        ...sexismCopy[0],
        isChecked: false,
      };
    }
    setSexisms(sexismCopy);
  };

  const handleDiversity = (e, currentValue, data, type) => {
    const diversityCopy = [...diversities];
    diversityCopy[data.id - 1] = {
      id: data.id,
      name: data.name,
      label: data.label,
      isChecked: type === "radio" ? currentValue : data.isChecked,
      value: type === "slider" ? currentValue : data.value,
    };
    if (data.id === 1) {
      diversityCopy[0] = {
        ...diversityCopy[0],
        isChecked: type === "radio" ? currentValue : data.isChecked,
        value: type === "slider" ? currentValue : data.value,
      };
      diversityCopy[1] = {
        ...diversityCopy[1],
        isChecked: false,
      };
    } else if (data.id === 2) {
      diversityCopy[1] = {
        ...diversityCopy[1],
        isChecked: type === "radio" ? currentValue : data.isChecked,
        value: type === "slider" ? currentValue : data.value,
      };
      diversityCopy[0] = {
        ...diversityCopy[0],
        isChecked: false,
      };
    }
    setDiversities(diversityCopy);
  };

  const handleSave = () => {
    if (selectedModel.title === "Emotion Detection") {
      const emotionData = {};
      emotions.forEach((emotion) => {
        emotionData[emotion.name] = emotion.value;
      });
      modelsConfigData.emotionData.push({ id: "", data: { emotionData } });
      // return true;
    } else if (selectedModel.title === "Personal Information") {
      const personalInfoData = [];
      personalInfos.forEach((personalInfo) => {
        if (personalInfo.isChecked === true) {
          personalInfoData.push(personalInfo.name);
        }
      });
      modelsConfigData.personalInfoData.push({
        id: "",
        data: { personalInfoData },
      });
    } else if (selectedModel.title === "Profanity") {
      modelsConfigData.profanityData.push({ id: "", data: { profanity } });
    } else if (selectedModel.title === "Text Abuse") {
      const textualAbuseData = {};
      sentiments.forEach((sentiment) => {
        if (sentiment.isChecked === true) {
          textualAbuseData[sentiment.name] = sentiment.value;
        }
      });
      abuses.forEach((abuse) => {
        if (abuse.isChecked === true) {
          textualAbuseData[abuse.name] = abuse.value;
        }
      });
      modelsConfigData.textAbuseData.push({
        id: "",
        data: { textualAbuseData },
      });
    } else if (selectedModel.title === "Sentiment Analysis") {
      const sentimentData = {};
      sentiments1.forEach((sentiment) => {
        if (sentiment.isChecked === true) {
          sentimentData[sentiment.name] = sentiment.value;
        }
      });

      modelsConfigData.SentimentData.push({
        id: "",
        data: { sentimentData },
      });
    } else if (selectedModel.title === "Hate Speech") {
      const hateSpeechData = {};
      hateSpeeches.forEach((hateSpeech) => {
        if (hateSpeech.isChecked === true) {
          hateSpeechData[hateSpeech.name] = hateSpeech.value;
        }
      });
      modelsConfigData.hateSpeechData.push({
        id: "",
        data: { hateSpeechData },
      });
    } else if (selectedModel.title === "Script") {
      const ScriptData = {};
      scripts.forEach((script) => {
        if (script.isChecked === true) {
          ScriptData[script.name] = script.value;
        }
      });
      modelsConfigData.scriptData.push({ id: "", data: { ScriptData } });
    } else if (selectedModel.title === "Sexual Harrasment") {
      const SexualHarassmentData = {};
      sexualHarassments.forEach((sh) => {
        if (sh.isChecked === true) {
          SexualHarassmentData[sh.name] = sh.value;
        }
      });
      modelsConfigData.sexualHarassmentData.push({
        id: "",
        data: { SexualHarassmentData },
      });
    } else if (selectedModel.title === "Racism Detection") {
      const RacismData = {};
      racisms.forEach((racism) => {
        if (racism.isChecked === true) {
          RacismData[racism.name] = racism.value;
        }
      });
      modelsConfigData.RacismData.push({
        id: "",
        data: { RacismData },
      });
    } else if (selectedModel.title === "Insult Detection") {
      const insultData = {};
      insults.forEach((insult) => {
        if (insult.isChecked === true) {
          insultData[insult.name] = insult.value;
        }
      });
      modelsConfigData.insultData.push({
        id: "",
        data: { insultData },
      });
    } else if (selectedModel.title === "Sexism Detection") {
      const sexismData = {};
      sexisms.forEach((sexism) => {
        if (sexism.isChecked === true) {
          sexismData[sexism.name] = sexism.value;
        }
      });
      modelsConfigData.sexismData.push({
        id: "",
        data: { sexismData },
      });
    } else if (selectedModel.title === "Diversity Inclusion") {
      const diversityData = {};
      diversities.forEach((diversity) => {
        if (diversity.isChecked === true) {
          diversityData[diversity.name] = diversity.value;
        }
      });
      modelsConfigData.diversityData.push({
        id: "",
        data: { diversityData },
      });
    }
    !isDemo &&
      dispatch({
        type: SET_MODEL_CONFIGURATION,
        payload: {
          modelsConfigData,
        },
      });
    toast.success(
      (selectedModel.title === "Emotion Detection" &&
        "Emotion configuration updated!") ||
        (selectedModel.title === "Personal Information" &&
          "Personal information updated!") ||
        (selectedModel.title === "Profanity" &&
          "Profanity configurations updated!") ||
        (selectedModel.title === "Hate Speech" &&
          "Hate Speech configuration updated!") ||
        (selectedModel.title === "Script" && "Script configuration updated!") ||
        (selectedModel.title === "Sexual Harrasment" &&
          "Sexual Harrasment configuration updated!") ||
        (selectedModel.title === "Racism Detection" &&
          "Racism Detection configuration updated!") ||
        (selectedModel.title === "Sentiment Analysis" &&
          "Sentiment Analysis configuration updated!") ||
        (selectedModel.title === "Text Abuse" &&
          "Textual Abuse configuration updated!") ||
        (selectedModel.title === "Insult Detection" &&
          "Insult Detection configuration updated!") ||
        (selectedModel.title === "Sexism Detection" &&
          "Sexism Detection configuration updated!") ||
        (selectedModel.title === "Diversity Inclusion" &&
          "Diversity Inclusion configuration updated!")
    );
    handleClose();
  };
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <DialogContent
        classes={{ root: classes.root }}
        style={{ width: "500px" }}
      >
        <div
          className="popUp"
          style={{
            padding: "30px 20px",
            backgroundColor: !isNight ? "#212047" : "#e5e5e5",
          }}
        >
          <div
            className="popUpTitle"
            style={{ color: !isNight ? "#aebcc9" : "#666" }}
          >
            {selectedModel.title}
          </div>

          {selectedModel.title === "Emotion Detection" &&
            emotions.map((emotion) => (
              <div key={emotion.id} style={{ width: "90%", margin: "10px" }}>
                <label
                  style={{
                    fontSize: "14px",
                    color: !isNight ? "#aebcc9" : "#666",
                  }}
                >{`${emotion.label}: ${emotion.value}%`}</label>
                <span>
                  <Slider
                    onChange={(e, value, data = emotion) =>
                      handleEmotions(e, value, data)
                    }
                    aria-label="Always visible"
                    defaultValue={emotion.value}
                    valueLabelDisplay="auto"
                  />
                </span>
              </div>
            ))}

          {selectedModel.title === "Personal Information" && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                color: !isNight ? "#aebcc9" : "#666",
              }}
            >
              <div>
                <FormGroup>
                  {personalInfos.map((personalInfo) => (
                    <div key={personalInfo.id}>
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                        label={personalInfo.label}
                        name={personalInfo.name}
                        onChange={(e, value, data = personalInfo) =>
                          handlePersonalInfos(e, value, data)
                        }
                      />
                    </div>
                  ))}
                </FormGroup>
              </div>
            </div>
          )}
          {selectedModel.title === "Profanity" && (
            <div style={{ width: "90%", margin: "10px" }}>
              <label
                style={{
                  fontSize: "14px",
                  color: !isNight ? "#aebcc9" : "#666",
                }}
              >{`Profane Text(${profanity}%)`}</label>
              <span>
                <Slider
                  defaultValue={profanity}
                  aria-label="small"
                  valueLabelDisplay="auto"
                  onChange={handleProfanity}
                />
              </span>
            </div>
          )}
          {selectedModel.title === "Text Abuse" && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "right",
              }}
            >
              <div>
                <FormGroup>
                  <label style={{ color: !isNight ? "#aebcc9" : "#666" }}>
                    Sentiments:
                  </label>
                  {sentiments.map((sentiment) => (
                    <div
                      key={sentiment.id}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        color: !isNight ? "#aebcc9" : "#666",
                      }}
                    >
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                        label={sentiment.label}
                        onChange={(
                          e,
                          value,
                          data = sentiment,
                          type = ["sentiments", "checkbox"]
                        ) => handleTextAbuse(e, value, data, type)}
                      />
                      {sentiment.isChecked === true && (
                        <div style={{ width: "90%" }}>
                          <span>
                            <Slider
                              defaultValue={sentiment.value}
                              aria-label="small"
                              valueLabelDisplay="on"
                              onChange={(
                                e,
                                value,
                                data = sentiment,
                                type = ["sentiments", "slider"]
                              ) => handleTextAbuse(e, value, data, type)}
                            />
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
                </FormGroup>
              </div>
              <div>
                <FormGroup>
                  <label style={{ color: !isNight ? "#aebcc9" : "#666" }}>
                    Abuse:
                  </label>
                  {abuses.map((abuse) => (
                    <div
                      key={abuse.id}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        color: !isNight ? "#aebcc9" : "#666",
                      }}
                    >
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                        label={abuse.label}
                        name={abuse.name}
                        onChange={(
                          e,
                          value,
                          data = abuse,
                          type = ["abuse", "checkbox"]
                        ) => handleTextAbuse(e, value, data, type)}
                      />
                      {abuse.isChecked === true && (
                        <div style={{ width: "90%" }}>
                          <span>
                            <Slider
                              defaultValue={abuse.value}
                              aria-label="small"
                              valueLabelDisplay="on"
                              onChange={(
                                e,
                                value,
                                data = abuse,
                                type = ["abuse", "slider"]
                              ) => {
                                handleTextAbuse(e, value, data, type);
                              }}
                            />
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
                </FormGroup>
              </div>
            </div>
          )}
          {selectedModel.title === "Sentiment Analysis" && (
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "right",
                color: !isNight ? "#aebcc9" : "#666",
              }}
            >
              <div>
                <FormGroup>
                  {sentiments1.map((sentiment) => (
                    <div
                      key={sentiment.id}
                      style={{ display: "flex", flexDirection: "row" }}
                    >
                      <FormControlLabel
                        control={<Checkbox color="primary" />}
                        label={sentiment.label}
                        onChange={(
                          e,
                          value,
                          data = sentiment,
                          type = ["sentiments", "checkbox"]
                        ) => handleSentiment(e, value, data, type)}
                      />
                      {sentiment.isChecked === true && (
                        <div style={{ width: "90%" }}>
                          <span>
                            <Slider
                              defaultValue={sentiment.value}
                              aria-label="small"
                              valueLabelDisplay="on"
                              onChange={(
                                e,
                                value,
                                data = sentiment,
                                type = ["sentiments", "slider"]
                              ) => handleSentiment(e, value, data, type)}
                            />
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
                </FormGroup>
              </div>
            </div>
          )}

          {selectedModel.title === "Script" && (
            <div
              style={{
                width: "90%",
                margin: "10px",
                color: !isNight ? "#aebcc9" : "#666",
              }}
            >
              <div>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="script"
                    defaultValue=""
                    name="radio-buttons-group"
                  >
                    {scripts.map((script) => (
                      <div key={script.id}>
                        <FormControlLabel
                          value={script.name}
                          control={<Radio color="primary" />}
                          label={script.label}
                          onChange={(e, value, data = script, type = "radio") =>
                            handleScript(e, value, data, type)
                          }
                        />
                      </div>
                    ))}
                  </RadioGroup>
                </FormControl>
                {scripts[1].isChecked && scripts[1].value !== null && (
                  <div style={{ width: "100%" }}>
                    <Slider
                      defaultValue={scripts[1].value}
                      aria-label="small"
                      valueLabelDisplay="auonto"
                      onChange={(
                        e,
                        value,
                        data = scripts[1],
                        type = "slider"
                      ) => {
                        handleScript(e, value, data, type);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {selectedModel.title === "Sexual Harrasment" && (
            <div
              style={{
                width: "90%",
                margin: "10px",
                color: !isNight ? "#aebcc9" : "#666",
              }}
            >
              <div>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="sexual Harassment"
                    defaultValue=""
                    name="radio-buttons-group"
                  >
                    {sexualHarassments.map((sh) => (
                      <div key={sh.id}>
                        <FormControlLabel
                          value={sh.name}
                          control={<Radio color="primary" />}
                          label={sh.label}
                          onChange={(e, value, data = sh, type = "radio") =>
                            handleSexualHarassment(e, value, data, type)
                          }
                        />
                      </div>
                    ))}
                  </RadioGroup>
                </FormControl>
                {sexualHarassments[1].isChecked &&
                  sexualHarassments[1].value !== null && (
                    <div style={{ width: "100%" }}>
                      <Slider
                        defaultValue={sexualHarassments[1].value}
                        aria-label="small"
                        valueLabelDisplay="auonto"
                        onChange={(
                          e,
                          value,
                          data = sexualHarassments[1],
                          type = "slider"
                        ) => {
                          handleSexualHarassment(e, value, data, type);
                        }}
                      />
                    </div>
                  )}
              </div>
            </div>
          )}
          {selectedModel.title === "Racism Detection" && (
            <div style={{ width: "90%", margin: "10px" }}>
              <div>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="Racism Detection"
                    defaultValue=""
                    name="radio-buttons-group"
                  >
                    {racisms.map((racism) => (
                      <div key={racism.id}>
                        <FormControlLabel
                          value={racism.name}
                          control={<Radio color="primary" />}
                          label={racism.label}
                          onChange={(e, value, data = racism, type = "radio") =>
                            handleRacism(e, value, data, type)
                          }
                        />
                      </div>
                    ))}
                  </RadioGroup>
                </FormControl>
                {racisms[1].isChecked && racisms[1].value !== null && (
                  <div style={{ width: "100%" }}>
                    <Slider
                      defaultValue={racisms[1].value}
                      aria-label="small"
                      valueLabelDisplay="auonto"
                      onChange={(
                        e,
                        value,
                        data = racisms[1],
                        type = "slider"
                      ) => {
                        handleRacism(e, value, data, type);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          {selectedModel.title === "Hate Speech" && (
            <div
              style={{
                width: "90%",
                margin: "10px",
                color: !isNight ? "#aebcc9" : "#666",
              }}
            >
              <div>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="script"
                    defaultValue=""
                    name="radio-buttons-group"
                  >
                    {hateSpeeches.map((hateSpeech) => (
                      <div key={hateSpeech.id}>
                        <FormControlLabel
                          value={hateSpeech.name}
                          control={<Radio color="primary" />}
                          label={hateSpeech.label}
                          onChange={(
                            e,
                            value,
                            data = hateSpeech,
                            type = "radio"
                          ) => handleHateSpeech(e, value, data, type)}
                        />
                      </div>
                    ))}
                  </RadioGroup>
                </FormControl>
                {hateSpeeches[1].isChecked && hateSpeeches[1].value !== null && (
                  <div
                    style={{
                      width: "100%",
                      color: !isNight ? "#aebcc9" : "#666",
                    }}
                  >
                    <Slider
                      defaultValue={hateSpeeches[1].value}
                      aria-label="small"
                      valueLabelDisplay="on"
                      onChange={(
                        e,
                        value,
                        data = hateSpeeches[1],
                        type = "slider"
                      ) => {
                        handleHateSpeech(e, value, data, type);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {selectedModel.title === "Insult Detection" && (
            <div
              style={{
                width: "90%",
                margin: "10px",
                color: !isNight ? "#aebcc9" : "#666",
              }}
            >
              <div>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="script"
                    defaultValue=""
                    name="radio-buttons-group"
                  >
                    {insults.map((insult) => (
                      <div key={insult.id}>
                        <FormControlLabel
                          value={insult.name}
                          control={<Radio color="primary" />}
                          label={insult.label}
                          onChange={(e, value, data = insult, type = "radio") =>
                            handleInsults(e, value, data, type)
                          }
                        />
                      </div>
                    ))}
                  </RadioGroup>
                </FormControl>
                {insults[1].isChecked && insults[1].value !== null && (
                  <div
                    style={{
                      width: "100%",
                      color: !isNight ? "#aebcc9" : "#666",
                    }}
                  >
                    <Slider
                      defaultValue={insults[1].value}
                      aria-label="small"
                      valueLabelDisplay="on"
                      onChange={(
                        e,
                        value,
                        data = insults[1],
                        type = "slider"
                      ) => {
                        handleInsults(e, value, data, type);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {selectedModel.title === "Sexism Detection" && (
            <div
              style={{
                width: "90%",
                margin: "10px",
                color: !isNight ? "#aebcc9" : "#666",
              }}
            >
              <div>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="script"
                    defaultValue=""
                    name="radio-buttons-group"
                  >
                    {sexisms.map((sexism) => (
                      <div key={sexism.id}>
                        <FormControlLabel
                          value={sexism.name}
                          control={<Radio color="primary" />}
                          label={sexism.label}
                          onChange={(e, value, data = sexism, type = "radio") =>
                            handleSexisms(e, value, data, type)
                          }
                        />
                      </div>
                    ))}
                  </RadioGroup>
                </FormControl>
                {sexisms[1].isChecked && sexisms[1].value !== null && (
                  <div
                    style={{
                      width: "100%",
                      color: !isNight ? "#aebcc9" : "#666",
                    }}
                  >
                    <Slider
                      defaultValue={sexisms[1].value}
                      aria-label="small"
                      valueLabelDisplay="on"
                      onChange={(
                        e,
                        value,
                        data = sexisms[1],
                        type = "slider"
                      ) => {
                        handleSexisms(e, value, data, type);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          {selectedModel.title === "Diversity Inclusion" && (
            <div
              style={{
                width: "90%",
                margin: "10px",
                color: !isNight ? "#aebcc9" : "#666",
              }}
            >
              <div>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="script"
                    defaultValue=""
                    name="radio-buttons-group"
                  >
                    {diversities.map((diversity) => (
                      <div key={diversity.id}>
                        <FormControlLabel
                          value={diversity.name}
                          control={<Radio color="primary" />}
                          label={diversity.label}
                          onChange={(
                            e,
                            value,
                            data = diversity,
                            type = "radio"
                          ) => handleDiversity(e, value, data, type)}
                        />
                      </div>
                    ))}
                  </RadioGroup>
                </FormControl>
                {diversities[1].isChecked && diversities[1].value !== null && (
                  <div
                    style={{
                      width: "100%",
                      color: !isNight ? "#aebcc9" : "#666",
                    }}
                  >
                    <Slider
                      defaultValue={diversities[1].value}
                      aria-label="small"
                      valueLabelDisplay="on"
                      onChange={(
                        e,
                        value,
                        data = diversities[1],
                        type = "slider"
                      ) => {
                        handleDiversity(e, value, data, type);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="btnOptions">
            <div className="cancelBtn" onClick={handleClose}>
              <div className="rangeBtn">
                <ClearIcon style={{ color: !isNight ? "#aebcc9" : "#000" }} />
                <div
                  className="btnText"
                  style={{ color: !isNight ? "#aebcc9" : "#000" }}
                >
                  Cancel
                </div>
              </div>
            </div>
            <div className="submitBtn">
              <div className="rangeBtn" type="submit" onClick={handleSave}>
                <TurnedInNotIcon />
                <div className="btnText">Save</div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default ModelConfigModal;
