/* eslint-disable react/no-array-index-key */
import React from "react";
import "../../../styles/loginPage.css";
import "../../../styles/profilePage.css";
import { useDispatch } from "react-redux";
import "../../../styles/setting.css";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@mui/icons-material/Check";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import "react-phone-input-2/lib/style.css";
import {
  DELETE_USER,
  GET_USERS,
} from "../../../redux/Dashboard/Dashboard.types";

export default function AssignLicenseModal({ handleClose, userData}) {
  const dispatch = useDispatch();
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const handleSave = () => {
    // !isDemo &&
    //   dispatch({
    //     type: DELETE_USER,
    //     payload: { email: userMail },
    //   });
    // setTimeout(() => {
    //   !isDemo &&
    //     dispatch({
    //       type: GET_USERS,
    //     });
    // }, 1500);
    handleClose();
  };
  return (
    <div className="deleteUserCard">
      <p style={{ fontSize: "1.2rem", color: "white", marginBottom: "0" }}>
        Do you want to assign the current license to this user?
      </p>

      <div className="submitBtnSection">
        <div className="btnOptions" style={{ marginTop: "10px" }}>
          <div className="cancelBtn" onClick={handleClose}>
            <div className="rangeBtn">
              <ClearIcon />
              <div className="btnText">No</div>
            </div>
          </div>
          <div className="submitBtn">
            <div className="rangeBtn" type="submit" onClick={handleSave}>
              <CheckIcon />
              <div className="btnText">Yes</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
