import React, { useState } from "react";
import { useSelector } from "react-redux";
import Filter from "./Tabs/Filter";
import Source from "./Tabs/Source";
import Model from "./Tabs/Model";
import Actions from "./Tabs/Actions";
import Modal from "@material-ui/core/Modal";
import SourceTutModal from "../../Walkthrough/SourceModal.jsx";
import FilterTutModal from "../../Walkthrough/FilterModal.jsx";
import ModelTutModal from "../../Walkthrough/ModelModal.jsx";
import ActionTutModal from "../../Walkthrough/ActionModal.jsx";
import NameTutModal from "../../Walkthrough/NameModal.jsx";
import SaveTutModal from "../../Walkthrough/SaveModal.jsx";
import BuildTutModal from "../../Walkthrough/BuildModal.jsx";
import TutorialTutModal from "../../Walkthrough/TutorialModal.jsx";

export default function SideBar() {
  const [currentScreen, setCurrentScreen] = useState("source");

  const workflows = useSelector((data) => data.dashboard.userWorkflows.data);

  const [openSourceTutModal, setOpenSourceTutModal] = useState(true);
  const [openFilterTutModal, setOpenFilterTutModal] = useState(false);
  const [openModelTutModal, setOpenModelTutModal] = useState(false);
  const [openActionTutModal, setOpenActionTutModal] = useState(false);
  const [openNameTutModal, setOpenNameTutModal] = useState(false);
  const [openSaveTutModal, setOpenSaveTutModal] = useState(false);
  const [openBuildTutModal, setOpenBuildTutModal] = useState(false);
  const [openTutorialTutModal, setOpenTutorialTutModal] = useState(false);

  const handleCloseSourceTutModal = () => {
    setOpenSourceTutModal(false);
  };

  const handleCloseFilterTutModal = () => {
    setOpenFilterTutModal(false);
  };

  const handleCloseModelTutModal = () => {
    setOpenModelTutModal(false);
  };

  const handleCloseActionTutModal = () => {
    setOpenActionTutModal(false);
  };

  const handleCloseNameTutModal = () => {
    setOpenNameTutModal(false);
  };

  const handleCloseSaveTutModal = () => {
    setOpenSaveTutModal(false);
  };

  const handleCloseBuildTutModal = () => {
    setOpenBuildTutModal(false);
  };

  const handleCloseTutorialTutModal = () => {
    setOpenTutorialTutModal(false);
  };

  let skipped = !JSON.parse(localStorage.getItem("skipped"));
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  return (
    <div
      className="logic-sidebar logicscreen-sidebar"
      style={!isNight ? { backgroundColor: "#212047" } : {}}
    >
      {workflows.Workflows.length == 0 ? (
        skipped ? (
          <>
            <Modal
              open={openSourceTutModal}
              onClose={handleCloseSourceTutModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <SourceTutModal
                handleClose={handleCloseSourceTutModal}
                setNextOpen={setOpenFilterTutModal}
                nextOpen={openFilterTutModal}
              />
            </Modal>

            <Modal
              open={openFilterTutModal}
              onClose={handleCloseFilterTutModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <FilterTutModal
                handleClose={handleCloseFilterTutModal}
                setNextOpen={setOpenModelTutModal}
                nextOpen={openModelTutModal}
              />
            </Modal>

            <Modal
              open={openModelTutModal}
              onClose={handleCloseModelTutModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <ModelTutModal
                handleClose={handleCloseModelTutModal}
                setNextOpen={setOpenActionTutModal}
                nextOpen={openActionTutModal}
              />
            </Modal>

            <Modal
              open={openActionTutModal}
              onClose={handleCloseActionTutModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <ActionTutModal
                handleClose={handleCloseActionTutModal}
                setNextOpen={setOpenNameTutModal}
                nextOpen={openNameTutModal}
              />
            </Modal>

            <Modal
              open={openNameTutModal}
              onClose={handleCloseNameTutModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <NameTutModal
                handleClose={handleCloseNameTutModal}
                setNextOpen={setOpenSaveTutModal}
                nextOpen={openSaveTutModal}
              />
            </Modal>

            <Modal
              open={openSaveTutModal}
              onClose={handleCloseSaveTutModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <SaveTutModal
                handleClose={handleCloseSaveTutModal}
                setNextOpen={setOpenBuildTutModal}
                nextOpen={openBuildTutModal}
              />
            </Modal>

            <Modal
              open={openBuildTutModal}
              onClose={handleCloseBuildTutModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <BuildTutModal
                handleClose={handleCloseBuildTutModal}
                setNextOpen={setOpenTutorialTutModal}
                nextOpen={openTutorialTutModal}
              />
            </Modal>

            <Modal
              open={openTutorialTutModal}
              onClose={handleCloseTutorialTutModal}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <TutorialTutModal handleClose={handleCloseTutorialTutModal} />
            </Modal>
          </>
        ) : (
          ""
        )
      ) : (
        ""
      )}

      <div className="logic-navbar">
        <div>
          <li
            onClick={() => setCurrentScreen("source")}
            className={`${currentScreen === "source" ? "active source" : ""}`}
            style={{
              color:
                currentScreen === "source"
                  ? "#5FA7F5"
                  : !isNight
                  ? "#aebcc9"
                  : "#000",
            }}
          >
            Source
          </li>
        </div>
        <div>
          <li
            onClick={() => setCurrentScreen("filter")}
            className={`${currentScreen === "filter" ? "active filter" : ""}`}
            style={{
              color:
                currentScreen === "filter"
                  ? "#FFA300"
                  : !isNight
                  ? "#aebcc9"
                  : "#000",
            }}
          >
            Filter
          </li>
        </div>
        <div>
          <li
            onClick={() => setCurrentScreen("model")}
            className={`${currentScreen === "model" ? "active model" : ""}`}
            style={{
              color:
                currentScreen === "model"
                  ? "#4CDA89"
                  : !isNight
                  ? "#aebcc9"
                  : "#000",
            }}
          >
            Model
          </li>
        </div>
        <div>
          <li
            onClick={() => setCurrentScreen("action")}
            className={`${currentScreen === "action" ? "active action" : ""}`}
            style={{
              color:
                currentScreen === "action"
                  ? "#FF0892"
                  : !isNight
                  ? "#aebcc9"
                  : "#000",
            }}
          >
            Action
          </li>
        </div>
      </div>
      {currentScreen === "source" && <Source />}
      {currentScreen === "filter" && <Filter />}
      {currentScreen === "model" && <Model />}
      {currentScreen === "action" && <Actions />}
    </div>
  );
}
