import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarExport,
  gridClasses,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { GET_INVESTIGATING_TABLE } from "../../../redux/Dashboard/Dashboard.types";
import { demoActionData } from "./demoData";
import DetailedInvestigation from "./DetailedInvestigation";
import { getColor } from "../../../utilities/theme";
import { Box } from "@material-ui/core";
import classNames from "classnames";

const columns = [
  { field: "id", headerName: "Action ID", width: 150 },
  { field: "UserId", headerName: "User ID", width: 150 },

  {
    field: "timeAction",
    headerName: "Time Action",
    width: 250,
    editable: true,
  },
  {
    field: "model",
    headerName: "Model",
    width: 150,
    editable: true,
  },
  {
    field: "category",
    headerName: "Category",
    width: 150,
    editable: true,
  },
  {
    field: "voilationScore",
    headerName: "Voilation Score",
    width: 200,
    editable: true,
  },
  {
    field: "workflowID",
    headerName: "Workflow ID",
    width: 150,
    editable: true,
  },

  {
    field: "actionType",
    headerName: "Action Type",
    width: 150,
    editable: true,
  },
];

export default function Investigation() {
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
    root: {
      color: !isNight ? "#aebcc9 !important" : "#000! important",
      fontSize: 13,
      border: "none",
      backgroundColor: !isNight ? "#212047" : "#F6F7FC",
      fontWeight: "500",
      "& .MuiDataGrid-columnHeaderWrapper": {
        backgroundColor: !isNight ? "#17123B" : "#e5e5e5!important",
      },
      "& .MuiTablePagination-root": {
        color: !isNight ? "#aebcc9" : "#000!important",
      },
      "& .Mui-disabled": {
        color: !isNight ? "#17123B" : "rgba(255,255,255,0.5)!important",
      },
      "& .MuiIconButton-root": {
        color: "#6352C6!important",
      },
    },
    columnHeader: {
      backgroundColor: !isNight ? "#17123B" : "#e5e5e5",
    },
    cell: {
      "& .MuiDataGrid-sortIcon": {
        fill: "white!important",
        opacity: 1,
      },
      "& .MuiDataGrid-cellCheckbox": {
        borderColor: "white",
        color: "white",
      },
      "& 	.MuiDataGrid-iconSeparator": {
        fill: "white",
      },
    },
    filterRoot: {
      color: "white!important",
      "& .MuiButton-root": {
        color: "white!important",
      },
      "& .MuiButtonBase": {
        color: "white!important",
      },
    },
    tagHeading: {
      // color: getColor(isNight),
      fontSize: "1rem",
      margin: "0.5rem",
      position: "relative",
      top: "-3px",
    },
    actionTab: {
      justifyContent: "center",
      width: "90%",
      backgroundColor: !isNight ? "#17123B" : "fff",
      borderRadius: "20px",
      padding: "1rem",
      margin: "0rem 0 4rem",
    },
    dropdown: {
      border: "1px solid #aebcc9",
      backgroundColor: !isNight ? "#212047" : "#fff",
      color: !isNight ? "#aebcc9" : "#000",
      justifyContent: "flex-end",
    },
    InvestigationContainer: {
      backgroundColor: !isNight ? "#212047" : "#fff",
      minHeight: "100vh",
      margin: "auto",
      display: "flex",
      zIndex: 3,
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
    getColor: {
      color: !isNight ? "#aebcc9" : "#000",
    },
    getBg: {
      backgroundColor: !isNight ? "#212047" : "#fff",
    },
  });

  const classes = useStyles();
  const dropdownClass = classNames("dropdown", classes.dropdown);
  const dispatch = useDispatch();
  const tableData = useSelector((data) => data.dashboard.tableData);
  const [rows, setRows] = useState([]);
  const [searchColumn, setSearchColumn] = useState("");
  const userWorkflows = useSelector((data) => data.dashboard.userWorkflows);
  const [selectedWorkflow, setselectedWorkflow] = useState(
    userWorkflows.data.Workflows[userWorkflows.data.Workflows.length - 1]
  );
  const [currentInspectionTab, setCurrentInspectionTab] = useState("action");

  const userID = JSON.parse(localStorage.getItem("userData")).userId;

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  useEffect(() => {
    // setNewRows(rows);
    !isDemo &&
      dispatch({
        type: GET_INVESTIGATING_TABLE,
        payload: {
          clientID: userID,
          workflowID: selectedWorkflow?.workflowID,
        },
      });
  }, [selectedWorkflow]);

  useEffect(() => {
    const updatedRow = [];
    tableData.forEach((item) => {
      updatedRow.push({
        id: item.actionID,
        UserId: item.userID,
        timeAction: item.timeAction,
        model: item.model,
        category: item.category,
        voilationScore: item.voilationScore,
        actionName: item.actionName,
        workflowID: item.workflowID,
        actionType: item.actionType,
      });
    });
    setRows(updatedRow);
    // setNewRows(rows);
  }, [tableData]);

  // const searchEvent = () => {
  //   dispatch({
  //     type: GET_EVENT,
  //     payload: { column: searchColumn, searchText },
  //   });
  // };

  const updateSearchColumn = (event) => {
    setSearchColumn(event.target.value);
    const currentWorkflow = userWorkflows.data.Workflows.filter(
      (userWorkflow) => userWorkflow.workflowName === event.target.value
    );
    setselectedWorkflow(currentWorkflow[0]);
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer className={gridClasses.toolbarContainer}>
        <GridToolbarExport className={classes.getColor} />
      </GridToolbarContainer>
    );
  }

  return (
    <div className={classes.InvestigationContainer}>
      <div className="tabContainer">
        <div
          className={
            currentInspectionTab === "action"
              ? "inspectionTabActive"
              : "inspectionTabs"
          }
          style={{
            backgroundColor:
              currentInspectionTab === "action" && !isNight
                ? "#17123B"
                : !isNight
                ? "#212047"
                : currentInspectionTab === "action"
                ? "#fff"
                : "#f6f7fc",
          }}
          onClick={() => {
            setCurrentInspectionTab("action");
          }}
        >
          <Box
            component="span"
            color={getColor(isNight)}
            className={classes.tagHeading}
          >
            Action Investigation
          </Box>
        </div>
        {/* <div
          className={
            currentInspectionTab === "detailed"
              ? "inspectionTabActive"
              : "inspectionTabs"
          }
          onClick={() => {
            setCurrentInspectionTab("detailed");
          }}
          style={{
            backgroundColor:
              currentInspectionTab === "detailed" && !isNight
                ? "#17123B"
                : !isNight
                ? "#212047"
                : currentInspectionTab === "detailed"
                ? "#fff"
                : "#f6f7fc",
          }}
        >
          <span
            style={{
              color: !isNight ? "#aebcc9" : "#000",
              fontSize: "1rem",
              margin: "0.5rem",
            }}
          >
            Detailed Investigation
          </span>
        </div> */}
      </div>
      {currentInspectionTab === "action" && (
        <Box component="div" className={classes.actionTab}>
          <div className="filterBar">
            {/* <img src={ExportLogo} alt="export-logo" /> */}
            <div>
              <select
                name="Model"
                className={dropdownClass}
                onChange={(event) => {
                  updateSearchColumn(event);
                }}
              >
                {userWorkflows.data.Workflows.slice(0)
                  .reverse()
                  .map((workflowData, id) => (
                    <option key={id + 1} id="time-date">
                      {workflowData.workflowName}
                    </option>
                  ))}
              </select>
            </div>
          </div>
          <Box component="div" color="#fff" height="450px">
            <DataGrid
              components={{
                Toolbar: CustomToolbar,
              }}
              rows={isDemo ? demoActionData : rows}
              columns={columns}
              classes={{
                root: classes.root,
                columnHeader: classes.columnHeader,
                columnHeaderWrapper: classes.columnHeaderWrapper,
                cell: classes.cell,
              }}
              // autoHeight
            />
          </Box>
        </Box>
      )}
      {currentInspectionTab === "detailed" && <DetailedInvestigation />}
    </div>
  );
}
