import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Tooltip } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import {
  GET_ENGAGEMENT,
  GET_ENGAGEMENT_THRESHOLD,
  SAVE_ENGAGEMENT_THRESHOLD,
} from "../../../../../redux/Dashboard/Dashboard.types";
import SetGoal from "../../../../common/SetGoal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function ConfigModal({
  eventBased,
  setEventBased,
  topicBased,
  setTopicBased,
  handleClose,
}) {
  const dispatch = useDispatch();
  const insightThreshold = useSelector(
    (data) => data.dashboard.insightThreshold
  );

  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const engagementThreshold = useSelector(
    (data) => data.dashboard.engagementThreshold
  );

  const [textEngageThreshold, setTextEngageThreshold] = useState(
    engagementThreshold.text
  );
  const [userEngageThreshold, setUserEngageThreshold] = useState(
    engagementThreshold.user
  );

  const [topicEngageThreshold, setTopicEngageThreshold] = useState(
    engagementThreshold.topic
  );

  const [surveyEngageThreshold, setSurveyEnagegThreshold] = useState(
    engagementThreshold.survey
  );

  const [interval, setInterval] = useState("monthly");

  const handleSave = () => {
    const data = {
      topicThreshold: parseInt(topicEngageThreshold),
      surveyThreshold: parseInt(surveyEngageThreshold),
      userThreshold: parseInt(userEngageThreshold),
      textThreshold: parseInt(textEngageThreshold),
    };

    dispatch({ type: SAVE_ENGAGEMENT_THRESHOLD, payload: data });

    handleClose();
  };

  useEffect(() => {
    setTopicEngageThreshold(engagementThreshold.topic);
    setTextEngageThreshold(engagementThreshold.text);
    setUserEngageThreshold(engagementThreshold.user);
    setSurveyEnagegThreshold(engagementThreshold.survey);
  }, [engagementThreshold]);

  useEffect(() => {
    dispatch({ type: GET_ENGAGEMENT_THRESHOLD });
  }, []);
  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Settings
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />
      <hr />
      <Typography id="modal-modal-title" style={{ fontSize: "1.1rem" }}>
        Engagement Goal
      </Typography>
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <SetGoal metric="engagement" handleClose={handleClose} />
      </Box>
      <hr />
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Typography
          id="modal-modal-title"
          style={{ margin: "1rem 0", fontSize: "1.1rem" }}
        >
          Overall Engagement Score Weighting (%)
        </Typography>
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Topic Engagement"
            variant="outlined"
            value={topicEngageThreshold}
            onChange={(e) => setTopicEngageThreshold(e.target.value)}
          />
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Survey Engagement"
            variant="outlined"
            value={surveyEngageThreshold}
            onChange={(e) => setSurveyEnagegThreshold(e.target.value)}
          />
        </Stack>
      </Box>
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Text Engagement"
            variant="outlined"
            value={textEngageThreshold}
            onChange={(e) => setTextEngageThreshold(e.target.value)}
          />
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="User Engagement"
            variant="outlined"
            value={userEngageThreshold}
            onChange={(e) => setUserEngageThreshold(e.target.value)}
          />
        </Stack>
      </Box>

      <Stack direction="row" spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          style={{
            background: "#D06283",
            color: "#fff",
          }}
          variant="contained"
        >
          Save
        </Button>
      </Stack>
    </Box>
  );
}

export default ConfigModal;
