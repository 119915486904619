import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import { useDispatch, useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AddAnnotation from "./AddAnnotation";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { alpha, styled } from "@mui/material/styles";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import {
  DELETE_CALENDER_EVENT,
  GET_CALENDER_EVENTS,
  DELETE_DEMO_ANNOTATION,
  SAVE_DEMO_ANNOTATION,
  UPDATE_CALENDER_EVENT,
  TOGGLE_PUBLIC_HOLIDAYS,
} from "../../../redux/Dashboard/Dashboard.types";
import UploadAnnotaion from "./UploadAnnotaion";
import UpdateAnnotation from "./UpdateAnnotation";
import _ from "lodash";
import ViewAnnotation from "./ViewAnnotation";
const AnnotationModal = ({
  handleClose,
  handleAddRequestedSection,
  //  doughnutData
}) => {
  const dispatch = useDispatch();

  const publicHolidaySwitch = useSelector(
    (data) => data.dashboard.publicHolidaySwitch
  );

  const calenderEvents = useSelector((data) => data.dashboard.calenderEvents);
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const [currentScreen, setCurrentScreen] = useState("list");
  const [seletedEvent, setSelectedEvent] = useState("");
  const deleteCalenderEvent = (value) => {
    dispatch({
      type: DELETE_CALENDER_EVENT,
      payload: { eventId: value },
    });
    setTimeout(() => {
      dispatch({
        type: GET_CALENDER_EVENTS,
        payload: { eventId: value },
      });
    }, 2000);
  };

  const deleteDemoCalender = (value) => {
    const temp = calenderEvents.filter((item) => item._id !== value);
    dispatch({
      type: SAVE_DEMO_ANNOTATION,
      payload: temp,
    });
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const PinkSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#d06283",
      "&:hover": {
        backgroundColor: alpha("#d06283", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#d06283",
    },
  }));

  const changeVisibility = (item) => {
    if (isDemo) {
      const otherEvents = calenderEvents.filter(
        (event) => event._id !== item._id
      );
      dispatch({
        type: SAVE_DEMO_ANNOTATION,
        payload: [
          ...otherEvents,
          {
            _id: item._id,
            name: item.name,
            description: item.description,
            date: item.date,
            color: item.color,
            isVisible: !item.isVisible,
          },
        ],
      });
    } else {
    }
  };

  const hideAll = () => {
    if (isDemo) {
      let temp = [];
      calenderEvents.forEach((event) => {
        temp.push({ ...event, isVisible: false });
      });
      dispatch({
        type: SAVE_DEMO_ANNOTATION,
        payload: temp,
      });
    } else {
    }
  };

  const togglePublicHolidays = () => {
    dispatch({
      type: TOGGLE_PUBLIC_HOLIDAYS,
      payload: !publicHolidaySwitch,
    });
  };

  return (
    <div
      className=""
      style={{
        width: "750px",
        height: "550px",
        overflowY: "scroll",
        margin: "20px",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%,-50%)",
        background: "#fff",
        padding: "1rem",
        borderRadius: "8px",
      }}
    >
      <div style={{ width: "100%", position: "relative" }}>
        <CloseIcon
          style={{ position: "absolute", right: "0", cursor: "pointer" }}
          onClick={handleClose}
        />
      </div>
      <div>
        {currentScreen === "list" && (
          <div
            style={{
              // border: "1px solid #888",
              height: "380px",
              margin: " 0.5rem",
              padding: "0 1rem",
              position: "relative",
            }}
          >
            {" "}
            <div
              onClick={() => {
                setCurrentScreen("file");
              }}
              style={{
                position: "absolute",
                right: "130px",
                top: "30px",
                width: "100px",
                color: "#D06283",
                background: "#fff",
                border: "1px solid #D06283",
                padding: "0.5rem",
                borderRadius: "4px",
                textAlign: "center",
                cursor: "pointer",
              }}
            >
              <FileUploadIcon
                style={{ fontSize: "1.1rem", position: "relative", top: "3px" }}
              />{" "}
              Upload
            </div>
            <div
              onClick={() => {
                setCurrentScreen("add");
              }}
              style={{
                position: "absolute",
                right: "0",
                top: "30px",
                width: "100px",
                color: "#fff",
                background: "#D06283",
                padding: "0.5rem",
                borderRadius: "4px",
                cursor: "pointer",
              }}
            >
              +Add Event
            </div>
            <div style={{ position: "relative", top: "80px" }}>
              <div style={{ display: "flex" }}>
                <div
                  onClick={hideAll}
                  style={{
                    color: "#D06283",
                    background: "#fff",
                    padding: "0.5rem 0.5rem 0.5rem 0",
                    borderRadius: "4px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  <VisibilityOffIcon
                    style={{
                      fontSize: "1.1rem",
                      position: "relative",
                      top: "3px",
                    }}
                  />{" "}
                  Hide All
                </div>
                <div
                  onClick={togglePublicHolidays}
                  style={{
                    color: "#D06283",
                    background: "#fff",
                    padding: "0.5rem",
                    borderRadius: "4px",
                    textAlign: "center",
                    cursor: "pointer",
                  }}
                >
                  {publicHolidaySwitch ? (
                    <VisibilityOffIcon
                      style={{
                        fontSize: "1.1rem",
                        position: "relative",
                        top: "3px",
                      }}
                    />
                  ) : (
                    <VisibilityIcon
                      style={{
                        fontSize: "1.1rem",
                        position: "relative",
                        top: "3px",
                      }}
                    />
                  )}{" "}
                  {`${publicHolidaySwitch ? "Hide" : "Show"} Public Holidays`}
                </div>
              </div>
              <div style={{height:"400px", overflow:"scroll", marginBottom:"2rem"}}>
              {calenderEvents.length > 0 ? (
                _.sortBy(calenderEvents, "date").map((item) => (
                  <>
                    <div
                      style={{
                        width: "100%",
                        // height: "40px",
                        display: "flex",
                        justifyContent: "space-between",
                        paddingBottom: "10px",
                        borderBottom: "1px solid #484848",
                      }}
                    >
                      <div
                        onClick={() => {
                          setCurrentScreen("view");
                          setSelectedEvent(item._id);
                        }}
                        style={{
                          display: "flex",
                          cursor: "pointer",
                        }}
                      >
                        <p>{`${item.name} `}</p>
                        <p>{` - ${moment(item.date).format("MM/DD/YYYY")}`}</p>
                        <span
                          style={{
                            width: "20px",
                            height: "20px",
                            borderRadius: "10px",
                            background: item.color,
                            position: "relative",
                            top: "10px",
                            margin: "0.5rem",
                          }}
                        />
                      </div>
                      <div>
                        <PinkSwitch
                          {...label}
                          checked={item.isVisible}
                          onChange={() => {
                            changeVisibility(item);
                          }}
                          sx={{ position: "relative", top: "-10px" }}
                        />
                        <EditIcon
                          onClick={() => {
                            setCurrentScreen("update");
                            setSelectedEvent(item._id);
                          }}
                          style={{
                            position: "relative",
                            top: "8px",
                            margin: "0.5rem",
                            cursor: "pointer",
                            color: "#aaa",
                          }}
                        />
                        <DeleteIcon
                          onClick={() => {
                            isDemo
                              ? deleteDemoCalender(item._id)
                              : deleteCalenderEvent(item._id);
                          }}
                          style={{
                            position: "relative",
                            top: "8px",
                            cursor: "pointer",
                            margin: "0.5rem",
                            color: "#aaa",
                          }}
                        />
                      </div>
                    </div>
                  </>
                ))
              ) : (
                <div
                  style={{
                    position: "relative",
                    top: "100px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <ErrorOutlineIcon
                    style={{
                      color: "#94989f",
                      textAlign: "center",
                      margin: "auto",
                    }}
                  />
                  <p
                    style={{
                      margin: "0",
                      color: "#94989f",
                      textAlign: "center",
                    }}
                  >
                    {"No Events found!"}
                  </p>
                </div>
              )}</div>
            </div>
          </div>
        )}
        {currentScreen === "add" && (
          <AddAnnotation
            handleClose={handleClose}
            setCurrentScreen={setCurrentScreen}
          />
        )}
        {currentScreen === "update" && (
          <UpdateAnnotation
            handleClose={handleClose}
            setCurrentScreen={setCurrentScreen}
            seletedEvent={seletedEvent}
          />
        )}
        {currentScreen === "view" && (
          <ViewAnnotation
            handleClose={handleClose}
            setCurrentScreen={setCurrentScreen}
            seletedEvent={seletedEvent}
          />
        )}
        {currentScreen === "file" && (
          <UploadAnnotaion
            handleClose={handleClose}
            setCurrentScreen={setCurrentScreen}
          />
        )}
      </div>
    </div>
  );
};

export default AnnotationModal;
