import React from "react";
import { render } from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./Components/Dashboard/Logic/Configuration/teamsConfig";
const msalInstance = new PublicClientApplication(msalConfig);

const storedData = JSON.parse(localStorage.getItem("userData"));
const userDetails = JSON.parse(localStorage.getItem("userDetails"));

// LogRocket.init("cz8ja9/trupulse");
// setupLogRocketReact(LogRocket);

userDetails &&
  LogRocket.identify(storedData?.userId, {
    name: userDetails && `${userDetails?.first_name} ${userDetails?.last_name}`,
    email: userDetails?.email,
  });

render(
  <Provider store={store}>
    <React.StrictMode>
    {/* <BrowserRouter> */}
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    {/* </BrowserRouter> */}
    </React.StrictMode>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
