import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { demoSurveys, demoFocusGroup } from "../demoSurveys";
import { Link } from "react-router-dom";
function SidePanel({ setSelectedTab, selectedTab, setSeletedSurvey }) {
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const surveyQuestion = isDemo
    ? demoSurveys
    : useSelector((data) => data.dashboard.surveyQuestion);
  const focusGroups = isDemo
    ? demoFocusGroup
    : useSelector((data) => data.dashboard.focusGroups);
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#f2f2f2",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "left",
    padding: "1rem",
    color: theme.palette.text.secondary,
  }));
  return (
    <Box
      sx={{
        height: "100%",
        minHeight: "100px",
        backgroundColor: "#f2f2f2",
        paddingTop: "2rem",
      }}
    >
      <Item sx={{ fontSize: "1.3rem", fontWeight: "Bold", textAlign: "left" }}>
        Surveys & Focus Groups
      </Item>
      <Link
        to="/dashboard/feedback/survey"
        // to={`${window.location.origin}/dashboard/feedback/survey`}
        style={{ textDecoration: "none" }}
      >
        <Item
          sx={
            selectedTab === "survey"
              ? { background: "#D06283", color: "#fff" }
              : {}
          }
        >
          {`Surveys`}
        </Item>
      </Link>
      <Link to="/dashboard/feedback/result" style={{ textDecoration: "none" }}>
        <Item
          sx={
            selectedTab === "result"
              ? { background: "#D06283", color: "#fff" }
              : {}
          }
        >
          {`Survey Results`}
        </Item>
      </Link>
      {/* <Link to="/dashboard/feedback/report" style={{ textDecoration: "none" }}>
        <Item
          sx={
            selectedTab === "report"
              ? { background: "#D06283", color: "#fff" }
              : {}
          }
        >
          Cross Survey Analysis
        </Item>
      </Link> */}
      <Link
        to="/dashboard/feedback/focus-group"
        style={{ textDecoration: "none" }}
      >
        <Item
          sx={
            selectedTab === "focusGroup"
              ? { background: "#D06283", color: "#fff" }
              : {}
          }
        >
          {`Focus Groups`}
        </Item>
      </Link>
      <Link
        to="/dashboard/feedback/focus-group-res/"
        style={{ textDecoration: "none" }}
      >
        <Item
          sx={
            selectedTab === "focusGroupRes"
              ? { background: "#D06283", color: "#fff" }
              : {}
          }
        >
          {`Focus Group Results`}
        </Item>
      </Link>
      {/* <Link to="/dashboard/feedback/action" style={{ textDecoration: "none" }}>
        <Item
          sx={
            selectedTab === "action"
              ? { background: "#D06283", color: "#fff" }
              : {}
          }
        >
          Action Plan
        </Item>
      </Link> */}
    </Box>
  );
}

export default SidePanel;
