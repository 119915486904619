import { Box, Typography } from "@material-ui/core";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import StartActionPlanModal from "../Popups/StartActionPlanModal";

function ActionPlan() {
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const actionPlans = useSelector((data) => data.dashboard.actionPlans);

  const [startModalOpen, setStartModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  return (
    <Box>
      <Box
        sx={{
          margin: "1rem",
          background: "#e5e5e5",
          padding: "1rem",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography
            style={{
              textAlign: "left",
              fontWeight: "bold",
              fontSize: "1.1rem",
              marginTop: "0.5rem",
            }}
          >
            Action Plans
          </Typography>
        </Box>
      </Box>
      {actionPlans.map((plan) => (
        <Box
          sx={{
            margin: "1rem",
            background: "#e5e5e5",
            padding: "1rem",
          }}
        >
          <Box>
            <Typography
              style={{
                textAlign: "left",
                fontWeight: "bold",
                fontSize: "1.4rem",
                marginTop: "0.5rem",
              }}
            >
              {plan.topic}
            </Typography>
          </Box>
          <Box>
            <Typography
              style={{
                textAlign: "left",
                fontWeight: "bold",
                fontSize: "1.2rem",
                marginTop: "0.5rem",
              }}
            >
              Recommended Steps
            </Typography>
          </Box>
          {plan.recommendedSteps.map((item) => (
            <Box
              sx={{
                border: "1px solid #484848",
                background: "#ffff",
                borderRadius: "12px",
                margin: "1rem",
                padding: "0.5rem 1rem",
              }}
            >
              <FormControlLabel
                sx={{ display: "flex", justifyContent: "start" }}
                control={<Checkbox />}
                label={
                  <Box>
                    <Typography
                      style={{
                        textAlign: "left",
                        fontSize: "1.1rem",
                        fontWeight: "bold",
                        marginTop: "0.5rem",
                      }}
                    >
                      {item.description}
                    </Typography>
                    <ol>
                      {item.steps.map((step) => (
                        <li
                          style={{
                            textAlign: "left",
                            fontSize: "1.1rem",
                            marginTop: "0.5rem",
                            marginLeft: "1rem",
                          }}
                        >
                          {step}
                        </li>
                      ))}
                    </ol>
                  </Box>
                }
              />
            </Box>
          ))}
          <Box sx={{ display: "flex", justifyContent: "end" }}>
            <Button
              onClick={() => {
                setStartModalOpen(true);
                setSelectedPlan(plan);
              }}
              variant="contained"
              sx={{ background: "#D06283", margin: "1rem 0 0.5rem" }}
              startIcon={<RocketLaunchIcon />}
            >
              Start
            </Button>
          </Box>
        </Box>
      ))}
      <Modal
        open={startModalOpen}
        onClose={() => {
          setStartModalOpen(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <StartActionPlanModal
          plan={selectedPlan}
          handleClose={() => {
            setStartModalOpen(false);
          }}
        />
      </Modal>
    </Box>
  );
}

export default ActionPlan;
