import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import CloseIcon from "@material-ui/icons/Close";
import CheckIcon from "@material-ui/icons/Check";
import { toast } from "react-toastify";
import axios from "axios";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../Logic/Configuration/teamsConfig";
import { Box } from "@material-ui/core";
import api from "../../../../services/axiosConfig";
import {
  GET_CHANNELS_SUCCESS,
  SAVE_CHANNELSFROMTEAMS,
  SAVE_TEAMID,
  SAVE_TEAM_CHATS,
  VERIFY_TOKEN,
} from "../../../../redux/Dashboard/Dashboard.types";

const useStyles = makeStyles((theme) => ({
  root: {
    color: "#fff",
    padding: "0!important",
  },
  container: {
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "column",
    "& div": {
      color: "#fff",
    },
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
    marginTop: 20,
    color: "rgba(0, 0, 0, 0.87)!important",
    borderBlockColor: "#6352C6",
  },
  input: {
    color: "#fff",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  paper: {
    padding: 0,
  },
}));

export default function KeysModal({ open, handleClose, title }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const userID = JSON.parse(localStorage.getItem("userData")).userId;
  const { instance } = useMsal();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [teams, setTeams] = useState({});
  const [channels, setChannels] = useState({});
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const [workflowName, setWorkflowName] = useState("");
  const uploadedChannels = useSelector(
    (data) => data.dashboard.uploadSourceStatus
  );

  useEffect(() => {
    if (channels.length > 0) {
      const channelList = [];
      const channelData = [];
      for (let i = 0; i < channels.length; i++) {
        channelList.push(channels[i].displayName);
        channelData.push({ id: channels[i].id, name: channels[i].displayName });
      }
      !isDemo &&
        dispatch({
          type: GET_CHANNELS_SUCCESS,
          payload: { status: "PASS", channels: channelList },
        });
      !isDemo &&
        dispatch({
          type: SAVE_CHANNELSFROMTEAMS,
          payload: channelData,
        });
    }
  }, [channels]);
  async function handleLogin(instance) {
    // instance.loginPopup(loginRequest).catch((e) => {
    // });
    try {
      await instance.loginPopup(loginRequest).catch((e) => {
        console.log(e);
      });
      setIsAuthenticated(true);
    } catch (e) {
      setIsAuthenticated(false);
    }
  }
  function allStorage() {
    var archive = [],
      keys = Object.keys(sessionStorage),
      i = 0,
      key;

    for (; (key = keys[i]); i++) {
      if (key.includes("accesstoken")) {
        archive.push(
          key.includes("accesstoken") && JSON.parse(sessionStorage.getItem(key))
        );
      }
    }

    return archive[archive.length - 1];
  }

  async function fetchTeams() {
    const teamResp = await axios.get(
      "https://graph.microsoft.com/v1.0/me/joinedTeams",
      // "https://graph.microsoft.com/beta/me/chats/getAllMessages",

      {
        headers: {
          "Access-Control-Allow-Headers": "Authorization",
          Authorization: `Bearer ${allStorage().secret}`,
        },
      }
    );
    if (teamResp.data.value) {
      setTeams(teamResp.data.value);
      toast.success("teams fetched successfully");
    }
  }

  async function fetchChannels(id) {
    const channelResp = await axios.get(
      `https://graph.microsoft.com/v1.0/teams/${id}/channels`,
      {
        headers: {
          "Access-Control-Allow-Headers": "Authorization",
          Authorization: `Bearer ${allStorage().secret}`,
        },
      }
    );
    if (channelResp.data.value.length > 0) {
      !isDemo &&
        dispatch({
          type: SAVE_TEAMID,
          payload: id,
        });
      setChannels(channelResp.data.value);
      toast.success("channels fetched successfully");
    }
  }
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);
  const teamChannels = useSelector((data) => data.dashboard.teamChannels);
  const teamID = useSelector((data) => data.dashboard.teamID);

  const [teamChats, setTeamChats] = useState([]);
  const fetchTeamsChat =  (channelArray) => {
    const temp = [];
    channelArray.forEach(async (item) => {
      const res = await axios.get(
        `https://graph.microsoft.com/beta/teams/${teamID}/channels/${item.id}/messages/`,
        {
          headers: {
            "Access-Control-Allow-Headers": "Authorization",
            Authorization: `Bearer ${allStorage().secret}`,
          },
        }
      );
      temp.push(res.data.value);
      setTeamChats(res.data.value);
    });
  };
  const handleSaveWorkflow = async () => {
    if (!isDemo) {
      const tempChannel = [];
      await fetchTeamsChat(teamChannels);
      const work = [
        {
          id: "dndnode_0",
          type: "input",
          position: {
            x: 50.77777099609375,
            y: 186.99999237060547,
          },
          targetPosition: "left",
          sourcePosition: "right",
          data: {
            label: "Teams",
          },
          style: {
            backgroundRepeat: "no-repeat",
            backgroundSize: "40% 40%",
            backgroundOrigin: "content-box",
            fontSize: "14px",
            color: "#aebcc9",
            fontWeight: "600",
            borderRadius: "8px ",
            background:
              "url(/static/media/slack.e45eba1a.svg) no-repeat center center",
            border: "3px solid #5FA7F5",
          },
        },
      ];
      uploadedChannels?.channels?.forEach((item, id) => {
        tempChannel.push({
          id: `dndnode_${id + 1}`,
          type: "default",
          position: {
            x: 265.77777099609375,
            y: (id + 1) * 180.99999237060547,
          },
          targetPosition: "left",
          sourcePosition: "right",
          data: {
            label: item,
          },
          style: {
            backgroundRepeat: "no-repeat",
            backgroundSize: "40% 40%",
            backgroundOrigin: "content-box",
            fontSize: "14px",
            color: "#aebcc9",
            fontWeight: "600",
            borderRadius: "8px ",
            background:
              "url(https://chec-front.s3.amazonaws.com/icons/channel.svg) no-repeat center center",
            border: "3px solid #FFA300",
          },
        });
        tempChannel.push({
          source: "dndnode_0",
          sourceHandle: null,
          target: `dndnode_${id + 1}`,
          targetHandle: null,
          arrowHeadType: "arrowclosed",
          animated: true,
          style: {
            stroke: "red",
          },
          id: "reactflow__edge-dndnode_0null-dndnode_1null",
        });
      });
      work.push(...tempChannel);
      const Models = [
        "Emotional",
        "Personal Information",
        "Profanity",
        "Hate Speech",
        "Textual Abuse",
        "Insult Detection",
        "Sexual Harrasment",
      ];
      const tempModels = [];
      Models.forEach((item, id) => {
        tempModels.push({
          id: `dndnode_${tempChannel.length + id + 1}`,
          type: "default",
          position: {
            x: 510.77777099609375,
            y: (id + 1) * 180.99999237060547,
          },
          targetPosition: "left",
          sourcePosition: "right",
          data: {
            label: item,
          },
          style: {
            backgroundRepeat: "no-repeat",
            backgroundSize: "40% 40%",
            backgroundOrigin: "content-box",
            fontSize: "14px",
            color: "#aebcc9",
            fontWeight: "600",
            borderRadius: "8px ",
            background:
              "url(/static/media/intellectual.bf40f944.svg) no-repeat center center",
            border: "3px solid #4cda89",
          },
        });
      });
      work.push(...tempModels);
      work.push({
        id: `dndnode_${work.length + 1}`,
        type: "default",
        position: {
          x: 752.8959970605468,
          y: 180.0627866931152,
        },
        targetPosition: "left",
        sourcePosition: "right",
        data: {
          label: "Email",
        },
        style: {
          backgroundRepeat: "no-repeat",
          backgroundSize: "40% 40%",
          backgroundOrigin: "content-box",
          fontSize: "14px",
          color: "#aebcc9",
          fontWeight: "600",
          borderRadius: "8px ",
          background:
            "url(/static/media/email.ab571ebd.svg) no-repeat center center",
          border: "3px solid #FF0892",
        },
      });

      api
        .post(
          "/createRawWorkflow",
          {
            workflowID: userID + Math.round(new Date().getTime() / 1000),
            workflow: work,
            workflowName,
            userID,
            teamFilters: teamChannels,
            clientID: JSON.parse(localStorage.getItem("userData")).userId,
            model_config: {
              emotionData: [
                {
                  id: "dndnode_5",
                  data: {
                    emotionData: {
                      joy: 2,
                      sadness: 3,
                      love: 3,
                      anger: 2,
                      fear: 2,
                      surprise: 3,
                      neutral: 3,
                    },
                  },
                },
              ],
              personalInfoData: [
                {
                  id: "dndnode_6",
                  data: {
                    personalInfoData: [
                      "cardDetails",
                      "DOB",
                      "drivingLicence",
                      "email",
                      "IPaddress",
                      "otp",
                      "MACaddress",
                      "passportNumber",
                      "phoneNumber",
                      "SSN",
                      "VIN",
                      "ZIP",
                      "userName",
                      "password",
                    ],
                  },
                },
              ],
              profanityData: [
                {
                  id: "dndnode_7",
                  data: {
                    profanity: 3,
                  },
                },
              ],
              hateSpeechData: [
                {
                  id: "dndnode_8",
                  data: {
                    hateSpeechData: {
                      any: null,
                    },
                  },
                },
              ],
              scriptData: [],
              textAbuseData: [
                {
                  id: "dndnode_9",
                  data: {
                    textualAbuseData: {
                      positive: 1,
                      negative: 2,
                      neutral: 1,
                      toxic: 3,
                      severe_toxic: 1,
                      obscene: 2,
                      threat: 2,
                      insult: 0,
                      identity_hate: 4,
                    },
                  },
                },
              ],
              sexualHarassmentData: [
                {
                  id: "dndnode_11",
                  data: {
                    SexualHarassmentData: {
                      any: null,
                    },
                  },
                },
              ],
              RacismData: [],
              SentimentData: [],
              insultData: [
                {
                  id: "dndnode_10",
                  data: {
                    insultData: {
                      any: null,
                    },
                  },
                },
              ],
              sexismData: [],
              diversityData: [],
            },
            action_config: {
              email: [
                {
                  id: "dndnode_12",
                  data: {
                    toEmail: "test@scanta.io",
                    emailSubject: "test",
                    emailBody: "test",
                  },
                },
              ],
              sms: [],
              alert: [],
              notification: [],
            },
          },
          {
            headers: {
              "Access-Control-Allow-Headers": "x-access-token",
              "x-access-token": localStorage.getItem("auth-token"),
            },
          }
        )
        .then((response) => {
          dispatch({
            type: SAVE_TEAM_CHATS,
            payload: {
              workflow_id: response.data.data.workflowID,
              texts: teamChats,
            },
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      classes={{
        root: classes.root,
        paper: classes.paper,
      }}
    >
      <DialogContent classes={{ root: classes.root }} style={{ width: 300 }}>
        <div
          className="popUp"
          style={{
            backgroundColor: !isNight ? "#212047" : "#e5e5e5",
          }}
        >
          <div className="popUpTitle">{title}</div>
          <Box component="div">
            {/* <div class="wrapper2"> */}
            <div class="search-input">
              <p
                style={{
                  marginBottom: "0.5rem",
                  color: !isNight ? "#aebcc9" : "#000",
                }}
              >
                Workflow Name:
              </p>
              <input
                type="text"
                placeholder="Enter workflow name"
                onChange={(e) => {
                  setWorkflowName(e.target.value);
                }}
                value={workflowName}
              />
            </div>
            {/* </div> */}
          </Box>
          {!isAuthenticated ? (
            <button
              onClick={() => handleLogin(instance)}
              style={{
                background: "#6352c6",
                margin: " 10px 0",
                padding: "0.5rem",
                color: "#fff",
                fontSize: "1rem",
                border: "2px solid #6352c6",
                borderRadius: "5px",
              }}
            >
              Sign in with Microsoft
            </button>
          ) : (
            <p style={{ color: !isNight ? "#aebcc9" : "#000" }}>
              Authenticated!
            </p>
          )}
          {isAuthenticated && (
            <button
              style={{
                background: "#e5e5e5",
                margin: " 10px",
                padding: "0.5rem",
                color: "#000",
                border: "2px solid #6352c6",
                fontSize: "1rem",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={fetchTeams}
            >
              Fetch Teams
            </button>
          )}
          {teams.length > 0 && (
            <div
              style={{
                padding: "10px 0  0",
                color: !isNight ? "#aebcc9" : "#000",
              }}
            >
              Click on a team to fetch channels
              <ul
                style={{
                  listStyle: "none",
                  padding: " 0",
                  color: !isNight ? "#aebcc9" : "#000",
                }}
              >
                {teams.map((item, id) => (
                  <li
                    key={id + 1}
                    onClick={() => {
                      fetchChannels(item.id);
                    }}
                    style={{
                      cursor: "pointer",
                      listStyle: "none",
                      fontSize: "0.8rem",
                      marginTop: "10px",
                      color: !isNight ? "#aebcc9" : "#000",
                    }}
                  >
                    {item.displayName}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <form className={classes.container}>
            <div className="btnOptions">
              <div className="cancelBtn" onClick={handleClose}>
                <div className="rangeBtn">
                  <CloseIcon style={{ color: !isNight ? "#aebcc9" : "#000" }} />
                  <div
                    className="btnText"
                    style={{ color: !isNight ? "#aebcc9" : "#000" }}
                  >
                    Cancel
                  </div>
                </div>{" "}
              </div>
              <div className="submitBtn">
                <div className="rangeBtn" type="submit">
                  <CheckIcon />
                  <div className="btnText" onClick={handleSaveWorkflow}>
                    Save
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </DialogContent>
    </Dialog>
  );
}
