/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/require-default-props */
/* eslint-disable no-restricted-globals */
import React from "react";
import PropTypes from "prop-types";

function SlackLogin({
  slackClientId,
  slackUserScope = "identity.basic",
  redirectUrl,
  state,
  onSuccess,
  onFailure,
}) {
  function openPopup() {
    const width = 400;
    const height = 600;
    const left = screen.width / 2 - width / 2;
    const top = screen.height / 2 - height / 2;

    let url = `https://slack.com/oauth/v2/authorize?user_scope=${slackUserScope}&client_id=${slackClientId}`;
    if (redirectUrl) {
      url += `&redirect_uri=${redirectUrl}&state=${state}`;
    }

    return window.open(
      url,
      "",
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
    );
  }

  function polling(popup) {
    const pollingInterval = setInterval(() => {
      if (!popup || popup.closed || popup.closed === undefined) {
        clearInterval(pollingInterval);
        onFailure("Popup has been closed by user");
      }

      const closeDialog = () => {
        clearInterval(pollingInterval);
        popup.close();
      };
      try {
        if (!popup.location.hostname.includes("slack.com")) {
          if (popup.location.search) {
            const query = new URLSearchParams(popup.location.search);
            const slackCode = query.get("code");
            closeDialog();
            if (slackCode) {
              return onSuccess(slackCode);
            }
            if (onFailure) {
              onFailure(query.get("error"));
            }
          }
        }
      } catch (error) {
        // console.error(error);
      }
    }, 1000);
  }

  function handleClick() {
    polling(openPopup());
  }

  return (
    <a>
      <img
        onClick={handleClick}
        alt="Sign in with Slack"
        height="40"
        width="172"
        src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
        srcSet="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x"
      />
    </a>
  );
}

SlackLogin.propTypes = {
  slackClientId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  slackUserScope: PropTypes.string,
  redirectUrl: PropTypes.string,
  onFailure: PropTypes.func,
};

export default SlackLogin;
