import React, { useState, useEffect, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Button from "@material-ui/core/Button";
import Skeleton from "@mui/material/Skeleton";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { GET_SENTIMENTS_DIST } from "../../../../redux/Dashboard/Dashboard.types";
import Chart from "react-apexcharts";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@material-ui/core";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { useScreenshot } from "use-react-screenshot";
import * as clipboard from "clipboard-polyfill";
import { base64StringToBlob } from "blob-util";

function TextualAbuseDist({
  currentFilter,
  customDate,
  currentWorkflowID,
  textAbuseData,
}) {
  const dispatch = useDispatch();

  const [clipProcessing, setClipProcessing] = useState(false);

  async function clip(b64Data) {
    const item = new clipboard.ClipboardItem({
      "image/png": base64StringToBlob(b64Data.split(",")[1], "image/png"),
    });

    await clipboard.write([item]);
  }

  const TextAbuseRef = createRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const getImage = async () => {
    setClipProcessing(true);
    await takeScreenshot(sentScoreRef.current);
    setClipProcessing(false);
    image && clip(image);
  };

  useEffect(() => {
    image && clip(image);
  }, [image]);

  const filters = useSelector((data) => data.dashboard.filters);
  const [isLoading, setIsLoading] = useState(true);
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  const [smallNumber, setSmallNumber] = useState(0);
  useEffect(() => {
    setSmallNumber(getRandomInt(20, 90));
  }, [filters, currentFilter, customDate]);

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const getSumFromTrend = (trend, term) => {
    let sum = 0;
    if (trend && trend.length > 0) {
      for (let i = 0; i < trend.length; i++) {
        sum += trend[i][term];
      }
    }

    return Math.round(sum);
  };

  var options = {
    chart: {
      type: "pie",
    },
    dataLabels: {
      enabled: true,
      offset: -50,
    },
    fill: {
      colors: [
        "#F05919",
        "#D06283",
        "#578826",
        "#0E71AA",
        "#607D8B",
        "#795548",
      ],
    },
    plotOptions: {
      pie: {
        startAngle: -180,
        endAngle: 180,
        offsetY: 10,
        dataLabels: {
          offset: -20,
        },
      },
    },

    stroke: {
      show: false,
    },
    grid: {
      padding: {
        // bottom: -80
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
        },
      },
    ],
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "right",
      fontSize: "14px",
      fontWeight: 400,
      // offsetY: 4,
      labels: {
        colors: !isNight ? ["#727272"] : ["#666"],
        useSeriesColors: false,
      },
      markers: {
        width: 14,
        height: 14,
        fillColors: [
          "#F05919",
          "#D06283",
          "#578826",
          "#0E71AA",
          "#607D8B",
          "#795548",
        ],
        radius: 0,
        offsetX: 0,
        offsetY: 0,
      },
      itemMargin: {
        horizontal: 5,
        vertical: 0,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
    tooltip: {
      fillSeriesColor: true,
      y: {
        formatter: function (value) {
          return value;
        },
      },
    },
    style: {
      fontSize: "12px",
      color: "#333",
    },
    colors: ["#666", "#666"],
    labels: [
      "Toxic",
      "Severe Toxic",
      "Obscene",
      "Threat",
      "Insult",
      "Identity Hate",
    ],
  };

  // const series = [20, 80]
  const series = [
    getSumFromTrend(textAbuseData?.result?.toxic, "count"),
    getSumFromTrend(textAbuseData?.result?.severe_toxic, "count"),
    getSumFromTrend(textAbuseData?.result?.obscene, "count"),
    getSumFromTrend(textAbuseData?.result?.threat, "count"),
    getSumFromTrend(textAbuseData?.result?.insult, "count"),
    getSumFromTrend(textAbuseData?.result?.identity_hate, "count"),
  ];

  return (
    <div
      ref={TextAbuseRef}
      style={{
        height: "360px",
        borderRadius: "12px",
        backgroundColor: !isNight ? "#2A2953" : "#fff",
        position: "relative",
        margin: "20px 0 0 0.3rem",
      }}
    >
      <div
        style={{
          width: "93%",
          position: "relative",
          top: "15px",
          margin: "1rem",
          display: "flex",
          justifyContent: "space-between",
          margin: "1rem",
        }}
      >
        <p
          style={{
            margin: "0",
            textAlign: "left",
            fontWeight: 500,
            color: !isNight ? "#fff" : "#484848",
          }}
        >
          Textual Abuse Breakdown
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                Breakdown of Textual Abuse Language by Category
              </span>
            }
          >
            <InfoIcon
              style={{
                fontSize: "1rem",
                filter: "opacity(0.7)",
                position: "relative",
                top: "3px",
              }}
            />
          </Tooltip>
        </p>
        <Tooltip
          title={
            <span
              style={{
                fontSize: "0.8rem",
                textAlign: "center",
              }}
            >
              Copy to clipboard
            </span>
          }
        >
          {clipProcessing ? (
            <CircularProgress size={16} color="inherit" />
          ) : (
            <ContentCopyOutlinedIcon
              onClick={getImage}
              style={{
                fontSize: "1.2rem",
                cursor: clipProcessing ? "wait" : "pointer",
              }}
            />
          )}
        </Tooltip>
      </div>

      {textAbuseData.status === "success" || (isDemo && !isLoading) ? (
        <div>
          <div
            className="sentiment-score-container"
            style={{ position: "relative", top: "-10px" }}
          >
            {/* <CanvasJSChart options={options} id="sentiment-score-container" /> */}
            <Chart
              options={options}
              series={series}
              type="pie"
              width="100%"
              height="300px"
            />
          </div>
        </div>
      ) : textAbuseData.status === "fail" || currentWorkflowID === "" ? (
        <div style={{ position: "relative", top: "100px" }}>
          <ErrorOutlineIcon style={{ color: "#94989f" }} />
          <p style={{ margin: "0", color: "#94989f" }}>
            {currentWorkflowID === "" ? "Select a workflow!" : "No Data found!"}
          </p>
        </div>
      ) : (
        <Skeleton
          variant="circular"
          width={"75%"}
          height={"250px"}
          style={{
            position: "relative",
            top: "20px",
            margin: "0 auto",
          }}
        />
      )}
    </div>
  );
}

export default TextualAbuseDist;
