import { toast } from "react-toastify";
import api from "../axiosConfig";
import { SessionOut } from "../../utilities/dashboardUtils";

export function* updatePassword(action) {
    let resp;
    const data = action.payload;
    const userId = action.payload.userId;

    delete data.userId;
    yield api
        .put(`/passwordUpdate/user/${userId}`, data, {
            headers: {
                "Access-Control-Allow-Headers": "x-access-token",
                "x-access-token": localStorage.getItem("auth-token"),
            },
        })
        .then((response) => {
            if (response.status >= 400 && response.status < 500) {
                toast.error(response.message);
            } else if (response.status === 400) {
                SessionOut();
            } else {
                resp = response;
                toast.success("Password Changed Successfully");
            }
        })
        .catch((error) => {
            // toast.error("Data not found");
            return error;
        });
    return resp;
}