import React, { useState } from "react";
import SettingsIcon from "@material-ui/icons/Settings";
import Modal from "@material-ui/core/Modal";
import { Tooltip } from "@material-ui/core";
import { ICONS } from "../../../../../AppConfig";
import KeysModal from "../../Configuration/KeyModal";
import TeamsModal from "../../Configuration/TeamsModal";
import DiscordModal from "../../Configuration/DiscordModal";
import ZoomModal from "../../Configuration/ZoomModal";
import FileUploader from "../../Configuration/FileUpload";

export default function Source() {
  const onDragStart = (event, nodeType, messenger, tabType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.setData("application/messenger", messenger);
    event.dataTransfer.setData("application/tabType", tabType);
    // eslint-disable-next-line no-param-reassign
    event.dataTransfer.effectAllowed = "move";
  };
  const [open, setOpen] = useState(false);
  const [fileUploader, setFileUploader] = useState(false);
  const [teamsModal, setTeamsModal] = useState(false);
  const [discordModal, setDiscordModal] = useState(false);
  const [zoomModal, setZoomModal] = useState(false)
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <aside>
        <div
          // style={{ backgroundColor: "#43B5F4"}}
          className="dndnode"
          onDragStart={(event) =>
            onDragStart(event, "input", "Archive", "source")
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "#666666" }}
                onClick={() => {
                  setFileUploader(true);
                }}
              />
            </Tooltip>

            <Modal
              open={fileUploader}
              onClose={() => {
                setFileUploader(false);
              }}
              aria-labelledby="file-upload"
              aria-describedby="file-upload-"
            >
              <FileUploader handleClose={setFileUploader} />
            </Modal>
            <img src={ICONS.ARCHIVE} alt="archive" />
            <p style={{  color:"#666666"}}>Upload Archive</p>
          </div>
        </div>
        <div
          // style={{ backgroundColor: "#43B5F4"}}
          className="dndnode"
          onDragStart={(event) =>
            onDragStart(event, "input", "Slack", "source")
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "#666666" }}
                onClick={handleOpen}
              />
            </Tooltip>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <KeysModal
                open={open}
                handleClose={handleClose}
                title="Oauth Configuration"
                source="slack"
              />
            </Modal>
            <img src={ICONS.SLACK} alt="slack"  />
            <p style={{  color:"#666666"}}>Slack</p>
          </div>
        </div>
        <div
          className="dndnode"
          // style={{ backgroundColor: "#43B5F4"}}
          onDragStart={(event) =>
            onDragStart(event, "input", "Teams", "source")
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "#666666" }}
                onClick={() => setTeamsModal(true)}
              />
            </Tooltip>
            <Modal
              open={teamsModal}
              onClose={() => {
                setTeamsModal(false);
              }}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <TeamsModal
                open={teamsModal}
                handleClose={() => {
                  setTeamsModal(false);
                }}
                title="Teams Authentication"
                source="teams"
              />
            </Modal>
            <img src={ICONS.TEAMS} alt="teams" width="35px"/>
            <p style={{  color:"#666666"}}>Teams</p>
          </div>
        </div>
        <div
          className="dndnode"
          // style={{ backgroundColor: "#43B5F4"}}
          onDragStart={(event) =>
            onDragStart(event, "input", "Discord", "source")
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "#666666" }}
                onClick={() => setDiscordModal(true)}
              />
            </Tooltip>
            <Modal
              open={discordModal}
              onClose={() => {
                setDiscordModal(false);
              }}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <DiscordModal
                open={discordModal}
                handleClose={() => {
                  setDiscordModal(false);
                }}
                title="Discord Authentication"
                source="discord"
              />
            </Modal>
            <img src={ICONS.DISCORD} alt="discord" width="25px"/>
            <p style={{  color:"#666666"}}>Discord</p>
          </div>
        </div>
        <div
          className="dndnode"
          // style={{ backgroundColor: "#43B5F4"}}
          onDragStart={(event) =>
            onDragStart(event, "input", "zoom", "source")
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "#666666" }}
                onClick={() => setZoomModal(true)}
              />
            </Tooltip>
            <Modal
              open={zoomModal}
              onClose={() => {
                setZoomModal(false);
              }}
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
            >
              <ZoomModal
                open={zoomModal}
                handleClose={() => {
                  setZoomModal(false);
                }}
                title="Zoom"
                source="zoom"
              />
            </Modal>
            <img src={ICONS.ZOOM} alt="zoom" width="25px"/>
            <p style={{  color:"#666666"}}>Zoom</p>
          </div>
        </div>
        {/* <div
          className="dndnode"
          onDragStart={(event) =>
            onDragStart(event, "input", "Messenger", "source")
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "white" }}
              />
            </Tooltip>
            <img src={ICONS.MESSENGER} alt="zoom" />
            <p>Messenger</p>
          </div>
        </div>
        <div
          className="dndnode"
          onDragStart={(event) =>
            onDragStart(event, "input", "Skype", "source")
          }
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "white" }}
              />
            </Tooltip>
            <img src={ICONS.SKYPE} alt="zoom" />
            <p>Skype</p>
          </div>
        </div>
        <div
          className="dndnode"
          onDragStart={(event) => onDragStart(event, "input", "Zoom", "source")}
          draggable
        >
          <div className="sourceCard">
            <Tooltip title="Configure">
              <SettingsIcon
                fontSize="small"
                className="sourceCardSetting"
                style={{ fill: "white" }}
              />
            </Tooltip>
            <img src={ICONS.ZOOM} alt="zoom" />
            <p>Zoom</p>
          </div>
        </div> */}
      </aside>
      {/* <p className="editMessage">
        you can configure setting by clicking the icon on respective card
      </p> */}
    </>
  );
}
