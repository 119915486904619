import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

const Step5Modal = ({ handleClose, setCurrentStep }) => {
  const history = useHistory();

  const handleNextStep = () => {
    // handleClose();
    // setInsightAccordion(false);
    // history.push("/dashboard/setting");
    // setCurrentStep(3);
  };

  const handleSkip = () => {
   
    handleClose();
    localStorage.setItem("skipped", true);
  };

  return (
    <>
      <div
        className="addChartsContainer"
        style={{
          width: "24vw",
          marginTop: "55px",

          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <div style={{ color: "#484848", fontSize: "1rem" }}>
          <b>Thank You!</b>
        </div>
        <p style={{ color: "#484848" }}>
          The tour is now complete. We hope this guided experience helped you
          get familiar with the TruPulse dashboard. If you have any further
          questions, feel free to use the help icon to interact with our HR
          assistant.
        </p>
        <div className="btnOptions" style={{ marginTop: "0px" }}>
          <div className="submitBtn">
            <div className="rangeBtn" type="submit" onClick={handleSkip}>
              <div className="btnText">{"Finish"}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step5Modal;
