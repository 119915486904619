import React, { useEffect, useState } from "react";
import api from "../../../../services/axiosConfig";
import { useSelector } from "react-redux";
const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const chatContext = useSelector((data) => data.dashboard.chatContext);

  const [context, setContext] = useState([]);

  useEffect(() => {
    var temp = [];
    for (let i = 0; i < chatContext.length; i++) {
      temp.push({
        role: "user",
        content: chatContext[i],
      });
    }
    setContext(temp);
  }, [chatContext]);

  const askOpenAI = async (question) => {
    const temp = context;
    let resp = { data: {} };
    const res = await api.post(
      `/openai/query?query=${question}`,
      {
        workflowID: "overall",
        query: question,
        context: context,
      },
      {
        headers: {
          "Access-Control-Allow-Headers": "x-access-token",
          "x-access-token": localStorage.getItem("auth-token"),
        },
      }
    );
    const botMessage = createChatBotMessage(
      res?.data?.data?.choices[0]?.message?.content
    );
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
    temp.push({
      role: "user",
      content: question,
    });
    temp.push({
      role: "assistant",
      content: res?.data?.data?.choices[0]?.message?.content,
    });
    setContext(temp);
  };
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: { askOpenAI },
        });
      })}
    </div>
  );
};

export default ActionProvider;
