import React, { useState, useEffect, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import "./index.css";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Chart from "react-apexcharts";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip } from "@material-ui/core";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CircularProgress from "@mui/material/CircularProgress";
import { useScreenshot } from "use-react-screenshot";
import * as clipboard from "clipboard-polyfill";
import { base64StringToBlob } from "blob-util";
import moment from "moment";
import { generateMoraleCompareData } from "../demoData";
import { SET_CHAT_CONTEXT } from "../../../../redux/Dashboard/Dashboard.types";
import EventDrop from "../../../common/Annotation/EventDrop";
import { filterToNumber } from "../../../../utilities/dashboardUtils";
import StatsOnTrendline from "../../../common/StatsOnTrendline";
function OverallENPSCompare({
  moraleData,
  currentWorkflowID,
  currentFilter,
  moraleLineToCompare,
}) {
  const dispatch = useDispatch();
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));
  const isNight = useSelector((data) => data.dashboard.isNightSwitchOn);
  const [clipProcessing, setClipProcessing] = useState(false);

  const moraleCompareData = isDemo
    ? generateMoraleCompareData(currentFilter)
    : useSelector((data) => data.dashboard.moraleComparison);
  const totalText = useSelector((data) => data.dashboard.totalText);
  const teamSize = useSelector((data) => data.dashboard.teamSize);

  const goals = useSelector((data) => data.dashboard.goals);

  //copy component to clipboard
  async function clip(b64Data) {
    const item = new clipboard.ClipboardItem({
      "image/png": base64StringToBlob(b64Data.split(",")[1], "image/png"),
    });

    await clipboard.write([item]);
  }
  const moraleCompareRef = createRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const getImage = async () => {
    setClipProcessing(true);
    await takeScreenshot(moraleCompareRef.current);
    setClipProcessing(false);
    image && clip(image);
  };
  useEffect(() => {
    image && clip(image);
  }, [image]);

  const filters = useSelector((data) => data.dashboard?.filters);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedOption, setSelectedOption] = useState("gender");
  const [overallTrends, setOverallTrends] = useState([]);
  const [genderTrends, setGenderTrends] = useState({
    male: [],
    female: [],
    trans: [],
    nb: [],
  });
  const [regionTrends, setRegionTrends] = useState({
    asiaPacific: [],
    middleEastAfrica: [],
    europe: [],
    northAmerica: [],
    southAmerica: [],
  });
  const [departmentTrend, setDepartmentTrend] = useState({
    marketing: [],
    researchAndDepelopment: [],
    operations: [],
    informationTech: [],
    accountsUserData: [],
    salesUserData: [],
    HRUserData: [],
    engineeringUserData: [],
  });
  const [locationTrend, setLocationTrend] = useState({
    homeOffice: [],
    regionalOffice: [],
    headquarters: [],
  });
  const [managementLevelTrend, setManagementLevelTrend] = useState({
    executiveUserData: [],
    SVPUserData: [],
    VPUserData: [],
    directorUserData: [],
    managerUserData: [],
    individualContributorUserData: [],
  });
  const [ageTrends, setAgeTrends] = useState({
    belowTwentyFiveTrend: [],
    thirtysixToFourtynineTrend: [],
    twentyfiveToThirtyfiveTrend: [],
    moreThanFiftyTrend: [],
  });
  const [incomeTrends, setIncomeTrends] = useState({
    belowFiftyThousandTrend: [],
    fiftyThousandToOnelakhTrend: [],
    OnelakhToTwolakhFiftyThousandTrend: [],
    moreThenTwolakhFiftyThousandTrend: [],
  });

  const [maritalTrend, setMaritalTrend] = useState({
    marriedTrend: [],
    unMarriedTrend: [],
  });
  const [ethnicityTrend, setEthnicityTrend] = useState({
    africanAmericanTrend: [],
    asianTrend: [],
    hispanicTrend: [],
    whiteTrend: [],
    otherTrend: [],
  });

  const [experienceTrend, setExperienceTrend] = useState({
    belowOneTrend: [],
    oneToFiveTrend: [],
    fiveToNineTrend: [],
    moreThanTen: [],
  });

  const [performanceTrend, setPerformanceTrend] = useState({
    outstandingTrend: [],
    verySatisfactryTrend: [],
    satisfactryTrend: [],
    unSatisfactryTrend: [],
    poorTrend: [],
  });

  const publicHolidays = useSelector((data) => data.dashboard.publicHolidays);
  const dateFilterSentiment = useSelector(
    (data) => data.dashboard.dateFilterSentiment
  );

  const calenderEvents = useSelector((data) => data.dashboard.calenderEvents);
  const publicHolidaySwitch = useSelector(
    (data) => data.dashboard.publicHolidaySwitch
  );
  const [annotations, setAnnotations] = useState([]);

  // Add Graph annotations for public holidays
  useEffect(() => {
    const temp = [];
    publicHolidaySwitch &&
      publicHolidays.forEach((holiday) => {
        temp.push({
          x: new Date(holiday.date).getTime(),
          borderColor: "#d06283",
          label: {
            borderRadius: 4,
            position: "bottom",
            style: {
              color: "#fff",
              opacity: "0.3",
              fontSize: "13px",
              background: "rgba(208, 98, 131, 0.8)",
            },
            text: holiday.name,
          },
        });
      });
    calenderEvents.forEach((item) => {
      temp.push({
        x: new Date(item.date).getTime(),
        borderColor: item.color,
        label: {
          borderRadius: 4,
          position: "bottom",
          style: {
            color: "#fff",
            opacity: "0.3",
            fontSize: "13px",
            background: item.color,
          },
          text: item.name,
        },
      });
    });
    setAnnotations(temp);
  }, [publicHolidays, calenderEvents]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    const overallData = moraleData.moraleScoreAvg
      ?.filter(
        (item) =>
          new Date(item._id) < new Date() &&
          new Date(item._id) > new Date(dateFilterSentiment.start) &&
          item.morale_avg !== null
      )
      ?.sort((a, b) => (a._id > b._id ? 1 : -1))
      .map((item) => {
        return {
          y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
          x: new Date(item._id),
        };
      });
    setOverallTrends(overallData);

    if (selectedOption === "gender") {
      const maleData = moraleCompareData?.result?.genderWiseTrend?.maleData
        ?.filter(
          (item) =>
            new Date(item._id) < new Date() &&
            new Date(item._id) > new Date(dateFilterSentiment.start) &&
            item.morale_avg !== null
        )
        ?.sort((a, b) => (a._id > b._id ? 1 : -1))
        .map((item) => {
          return {
            y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
            x: new Date(item._id),
          };
        });
      const femaleData = moraleCompareData?.result?.genderWiseTrend?.femaleData
        ?.filter(
          (item) =>
            new Date(item._id) < new Date() &&
            new Date(item._id) > new Date(dateFilterSentiment.start) &&
            item.morale_avg !== null
        )
        ?.sort((a, b) => (a._id > b._id ? 1 : -1))
        .reverse()
        .slice(0, filterToNumber(currentFilter))
        .reverse()
        .map((item) => {
          return {
            y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
            x: new Date(item._id),
          };
        });
      const transData = moraleCompareData?.result?.genderWiseTrend?.transData
        ?.filter(
          (item) =>
            new Date(item._id) < new Date() &&
            new Date(item._id) > new Date(dateFilterSentiment.start) &&
            item.morale_avg !== null
        )
        ?.sort((a, b) => (a._id > b._id ? 1 : -1))
        .reverse()
        .slice(0, filterToNumber(currentFilter))
        .reverse()
        .map((item) => {
          return {
            y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
            x: new Date(item._id),
          };
        });
      const nbData = moraleCompareData?.result?.genderWiseTrend?.nbData
        ?.filter(
          (item) =>
            new Date(item._id) < new Date() &&
            new Date(item._id) > new Date(dateFilterSentiment.start) &&
            item.morale_avg !== null
        )
        ?.sort((a, b) => (a._id > b._id ? 1 : -1))
        .reverse()
        .slice(0, filterToNumber(currentFilter))
        .reverse()
        .map((item) => {
          return {
            y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
            x: new Date(item._id),
          };
        });
      setGenderTrends({
        male: maleData,
        female: femaleData,
        trans: transData,
        nb: nbData,
      });
    } else if (selectedOption === "region") {
      const asiaPacificData =
        moraleCompareData?.result?.regionWiseTrend?.asiaPacificData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const europeData = moraleCompareData?.result?.regionWiseTrend?.europeData
        ?.filter(
          (item) =>
            new Date(item._id) < new Date() &&
            new Date(item._id) > new Date(dateFilterSentiment.start) &&
            item.morale_avg !== null
        )
        ?.sort((a, b) => (a._id > b._id ? 1 : -1))
        .map((item) => {
          return {
            y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
            x: new Date(item._id),
          };
        });
      const middleEastAfricaData =
        moraleCompareData?.result?.regionWiseTrend?.europeData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const northAmericaData =
        moraleCompareData?.result?.regionWiseTrend?.northAmericaData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const southAmericaData =
        moraleCompareData?.result?.regionWiseTrend?.southAmericaData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      setRegionTrends({
        asiaPacific: asiaPacificData,
        middleEastAfrica: europeData,
        europe: middleEastAfricaData,
        northAmerica: northAmericaData,
        southAmerica: southAmericaData,
      });
    } else if (selectedOption === "department") {
      const marketing =
        moraleCompareData?.result?.departmentWiseTrend?.marketingUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const researchAndDepelopment =
        moraleCompareData?.result?.departmentWiseTrend?.researchAndDepelopmentUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const operations =
        moraleCompareData?.result?.departmentWiseTrend?.operationsUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const informationTech =
        moraleCompareData?.result?.departmentWiseTrend?.informationTechUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const accounts =
        moraleCompareData?.result?.departmentWiseTrend?.accountsUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const sales =
        moraleCompareData?.result?.departmentWiseTrend?.salesUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const HR =
        moraleCompareData?.result?.departmentWiseTrend?.HRUserData?.filter(
          (item) =>
            new Date(item._id) < new Date() &&
            new Date(item._id) > new Date(dateFilterSentiment.start) &&
            item.morale_avg !== null
        )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const engineering =
        moraleCompareData?.result?.departmentWiseTrend?.engineeringUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      setDepartmentTrend({
        marketing: marketing,
        researchAndDepelopment: researchAndDepelopment,
        operations: operations,
        informationTech: informationTech,
        accountsUserData: accounts,
        salesUserData: sales,
        HRUserData: HR,
        engineeringUserData: engineering,
      });
    } else if (selectedOption === "location") {
      const homeOfficeUsers =
        moraleCompareData?.result?.locationWiseTrend?.homeOfficeUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const regionalOfficeUsers =
        moraleCompareData?.result?.locationWiseTrend?.regionalOfficeUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const headquartersUsers =
        moraleCompareData?.result?.locationWiseTrend?.headquartersUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      setLocationTrend({
        homeOffice: homeOfficeUsers,
        regionalOffice: regionalOfficeUsers,
        headquarters: headquartersUsers,
      });
    } else if (selectedOption === "managementLevel") {
      const executiveUserData =
        moraleCompareData?.result?.managementLevelWiseTrend?.executiveUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const SVPUserData =
        moraleCompareData?.result?.managementLevelWiseTrend?.SVPUserData?.filter(
          (item) =>
            new Date(item._id) < new Date() &&
            new Date(item._id) > new Date(dateFilterSentiment.start) &&
            item.morale_avg !== null
        )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const VPUserData =
        moraleCompareData?.result?.managementLevelWiseTrend?.VPUserData?.filter(
          (item) =>
            new Date(item._id) < new Date() &&
            new Date(item._id) > new Date(dateFilterSentiment.start) &&
            item.morale_avg !== null
        )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const directorUserData =
        moraleCompareData?.result?.managementLevelWiseTrend?.directorUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const managerUserData =
        moraleCompareData?.result?.managementLevelWiseTrend?.managerUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const individualContributorUserData =
        moraleCompareData?.result?.managementLevelWiseTrend?.individualContributorUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      setManagementLevelTrend({
        executiveUserData: executiveUserData,
        SVPUserData: SVPUserData,
        VPUserData: VPUserData,
        directorUserData: directorUserData,
        managerUserData: managerUserData,
        individualContributorUserData: individualContributorUserData,
      });
    } else if (selectedOption === "age") {
      const belowTwentyFiveTrend =
        moraleCompareData?.result?.ageWiseTrend?.belowTwentyFiveUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const thirtysixToFourtynineTrend =
        moraleCompareData?.result?.ageWiseTrend?.thirtysixToFourtynineUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const twentyfiveToThirtyfiveTrend =
        moraleCompareData?.result?.ageWiseTrend?.twentysixToThirtyfiveUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const moreThanFiftyTrend =
        moraleCompareData?.result?.ageWiseTrend?.moreThanFiftyUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      setAgeTrends({
        belowTwentyFiveTrend: belowTwentyFiveTrend,
        thirtysixToFourtynineTrend: thirtysixToFourtynineTrend,
        twentyfiveToThirtyfiveTrend: twentyfiveToThirtyfiveTrend,
        moreThanFiftyTrend: moreThanFiftyTrend,
      });
    } else if (selectedOption === "income") {
      const belowFiftyThousandTrend =
        moraleCompareData?.result?.incomeWiseTrend?.belowFiftyThousandUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const OnelakhToTwolakhFiftyThousandTrend =
        moraleCompareData?.result?.incomeWiseTrend?.OnelakhToTwolakhFiftyThousandUserData?.filter(
          (item) =>
            new Date(item._id) < new Date() &&
            new Date(item._id) > new Date(dateFilterSentiment.start) &&
            item.morale_avg !== null
        )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const fiftyThousandToOnelakhTrend =
        moraleCompareData?.result?.incomeWiseTrend?.fiftyThousandToOnelakhUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const moreThenTwolakhFiftyThousandTrend =
        moraleCompareData?.result?.incomeWiseTrend?.moreThenTwolakhFiftyThousandUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });

      setIncomeTrends({
        belowFiftyThousandTrend: belowFiftyThousandTrend,
        fiftyThousandToOnelakhTrend: fiftyThousandToOnelakhTrend,
        OnelakhToTwolakhFiftyThousandTrend: OnelakhToTwolakhFiftyThousandTrend,
        moreThenTwolakhFiftyThousandTrend: moreThenTwolakhFiftyThousandTrend,
      });
    } else if (selectedOption === "maritalStatus") {
      const marriedTrend =
        moraleCompareData?.result?.martialStatusWiseTrend?.marriedUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const unMarriedTrend =
        moraleCompareData?.result?.martialStatusWiseTrend?.singleUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });

      setMaritalTrend({
        marriedTrend: marriedTrend,
        unMarriedTrend: unMarriedTrend,
      });
    } else if (selectedOption === "ethinicty") {
      const africanAmericanTrend =
        moraleCompareData?.result?.ethnicityWiseTrend?.africanAmericanUsersData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const asianTrend =
        moraleCompareData?.result?.ethnicityWiseTrend?.asianUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const hispanicTrend =
        moraleCompareData?.result?.ethnicityWiseTrend?.hispanicUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const whiteTrend =
        moraleCompareData?.result?.ethnicityWiseTrend?.whiteUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const otherTrend =
        moraleCompareData?.result?.ethnicityWiseTrend?.otherEthnicityUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });

      setEthnicityTrend({
        africanAmericanTrend: africanAmericanTrend,
        asianTrend: asianTrend,
        hispanicTrend: hispanicTrend,
        whiteTrend: whiteTrend,
        otherTrend: otherTrend,
      });
    } else if (selectedOption === "serviceLength") {
      const belowOneTrend =
        moraleCompareData?.result?.experienceWiseTrend?.belowOneUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const oneToFiveTrend =
        moraleCompareData?.result?.experienceWiseTrend?.oneToFiveUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const fiveToNineTrend =
        moraleCompareData?.result?.experienceWiseTrend?.fiveToNineUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const moreThanTen =
        moraleCompareData?.result?.experienceWiseTrend?.moreThanTenUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });

      setExperienceTrend({
        belowOneTrend: belowOneTrend,
        oneToFiveTrend: oneToFiveTrend,
        fiveToNineTrend: fiveToNineTrend,
        moreThanTen: moreThanTen,
      });
    } else if (selectedOption === "performance") {
      const outstandingTrend =
        moraleCompareData?.result?.experienceWiseTrend?.belowOneUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const verySatisfactryTrend =
        moraleCompareData?.result?.experienceWiseTrend?.oneToFiveUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const satisfactryTrend =
        moraleCompareData?.result?.experienceWiseTrend?.fiveToNineUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });
      const unSatisfactryTrend =
        moraleCompareData?.result?.experienceWiseTrend?.moreThanTenUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });

      const poorTrend =
        moraleCompareData?.result?.experienceWiseTrend?.moreThanTenUserData
          ?.filter(
            (item) =>
              new Date(item._id) < new Date() &&
              new Date(item._id) > new Date(dateFilterSentiment.start) &&
              item.morale_avg !== null
          )
          ?.sort((a, b) => (a._id > b._id ? 1 : -1))
          .map((item) => {
            return {
              y: isDemo ? item.morale_avg + smallNumber : item.morale_avg,
              x: new Date(item._id),
            };
          });

      setPerformanceTrend({
        outstandingTrend: outstandingTrend,
        verySatisfactryTrend: verySatisfactryTrend,
        satisfactryTrend: satisfactryTrend,
        unSatisfactryTrend: unSatisfactryTrend,
        poorTrend: poorTrend,
      });
    }
  }, [
    selectedOption,
    moraleData,
    currentFilter,
    filters,
    !isDemo && moraleCompareData,
  ]);

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  const [smallNumber, setSmallNumber] = useState(0);
  useEffect(() => {
    setSmallNumber(getRandomInt(-10, 10));
  }, [filters]);

  var options = {
    annotations: {
      xaxis: annotations,
      yaxis: [
        {
          y: goals.morale,
          borderColor: "#135714",
          strokeWidth: 5,
          borderWidth: 3,
          label: {
            borderColor: "#135714",
            style: {
              color: "#fff",
              background: "#135714",
            },
            text: `Goal - ${goals.morale}`,
          },
        },
      ],
    },
    chart: {
      type: "line",
      toolbar: {
        show: true,
        tools: {
          download: false, // <== line to add
        },
      },
      animations: {
        enabled: true,
        easing: "easeinout",
        speed: 800,
        animateGradually: {
          enabled: true,
          delay: 150,
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#1F3BB3"],
    stroke: {
      show: true,
      curve: "straight",
      lineCap: "butt",
      colors: undefined,
      width: 3,
      dashArray: 0,
    },
    markers: {
      size: [2, 3],
      strokeWidth: 1,
      strokeOpacity: 0.3,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: isNight ? "light" : "dark",
        shadeIntensity: 0.3,
        opacityFrom: 0.7,
        opacityTo: 0.3,
        stops: [0, 90, 100],
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          colors: !isNight ? ["#727272"] : ["#666"],
          cssClass: "apexcharts-yaxis-label",
        },
        formatter: function (value) {
          return moment(value).format("MMM DD yyyy");
        },
      },
    },
    yaxis: {
      min: -100,
      max: 100,
      tickAmount: 7,
      seriesName: "eNPS Score",
      axisTicks: {
        show: true,
        color: !isNight ? "#727272" : "#666",
      },
      labels: {
        style: {
          colors: !isNight ? ["#727272"] : ["#666"],
          cssClass: "apexcharts-yaxis-label",
        },
        formatter: function (value) {
          return value && Math.round(value);
        },
      },
      title: {
        text: "eNPS Score",
        style: {
          color: !isNight ? "#727272" : "#666",
          fontSize: "12px",
        },
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: true,
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      x: {
        show: true,
        format: "MM/DD/YYYY",
      },
      y: {
        show: true,
        formatter: function (val) {
          return val && Math.round(val);
        },
        title: {
          formatter: function (seriesName) {
            return seriesName;
          },
        },
      },
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "right",
      floating: false,
      fontSize: "14px",
      fontWeight: 400,
      offsetX: 0,
      offsetY: 30,
      labels: {
        colors: !isNight ? ["#727272"] : ["#666"],
        useSeriesColors: false,
      },
      markers: {
        width: 12,
        height: 12,
        strokeWidth: 0,
        radius: 2,
      },
      onItemClick: {
        toggleDataSeries: true,
      },
      onItemHover: {
        highlightDataSeries: true,
      },
    },
  };

  var series = [
    // {
    //   name: "Overall",
    //   color: "#9B878A",
    //   data: moraleLineToCompare,
    // },
  ];
  if (selectedOption === "gender") {
    series.push(
      {
        name: "Male",
        color: "#04B8AF",
        data: genderTrends.male,
      },
      {
        name: "Female",
        color: "#FF9499",
        data: genderTrends.female,
      },
      {
        name: "Transgender",
        color: "#89788D",
        data: genderTrends.trans,
      },
      {
        name: "Non-Binary",
        color: "#D06283",
        data: genderTrends.nb,
      }
    );
  } else if (selectedOption === "region") {
    series.push(
      {
        name: "Asia Pacific",
        color: "#04B8AF",
        data: regionTrends.asiaPacific,
      },
      {
        name: "Europe",
        color: "#FF9499",
        data: regionTrends.europe,
      },
      {
        name: "Middle East/Africa",
        color: "#89788D",
        data: regionTrends.middleEastAfrica,
      },
      {
        name: "North America",
        color: "#D06283",
        data: regionTrends.northAmerica,
      },
      {
        name: "South America",
        color: "#666",
        data: regionTrends.southAmerica,
      }
    );
  } else if (selectedOption === "department") {
    series.push(
      {
        name: "Marketing",
        color: "#04B8AF",
        data: departmentTrend.marketing,
      },
      {
        name: "Research and Development",
        color: "#FF9499",
        data: departmentTrend.researchAndDepelopment,
      },
      {
        name: "Operations",
        color: "#89788D",
        data: departmentTrend.operations,
      },
      {
        name: "Information and Technology",
        color: "#D06283",
        data: departmentTrend.informationTech,
      },
      {
        name: "Accounts",
        color: "#666",
        data: departmentTrend.accountsUserData,
      },
      {
        name: "Sales",
        color: "#666",
        data: departmentTrend.salesUserData,
      },
      {
        name: "Human Resources",
        color: "#666",
        data: departmentTrend.HRUserData,
      },
      {
        name: "Engineering",
        color: "#666",
        data: departmentTrend.engineeringUserData,
      }
    );
  } else if (selectedOption === "location") {
    series.push(
      {
        name: "Home Office",
        color: "#04B8AF",
        data: locationTrend.homeOffice,
      },
      {
        name: "Regional Office",
        color: "#FF9499",
        data: locationTrend.regionalOffice,
      },
      {
        name: "Headquarters",
        color: "#D06283",
        data: locationTrend.headquarters,
      }
    );
  } else if (selectedOption === "managementLevel") {
    series.push(
      {
        name: "Executive",
        color: "#04B8AF",
        data: managementLevelTrend.executiveUserData,
      },
      {
        name: "SVP",
        color: "#FF9499",
        data: managementLevelTrend.SVPUserData,
      },
      {
        name: "VP",
        color: "#89788D",
        data: managementLevelTrend.VPUserData,
      },
      {
        name: "Director",
        color: "#D06283",
        data: managementLevelTrend.directorUserData,
      },
      {
        name: "Manager",
        color: "#666",
        data: managementLevelTrend.managerUserData,
      },
      {
        name: "Individual Contributors",
        color: "#666",
        data: managementLevelTrend.individualContributorUserData,
      }
    );
  } else if (selectedOption === "age") {
    series.push(
      {
        name: "<25 Years",
        color: "#04B8AF",
        data: ageTrends.belowTwentyFiveTrend,
      },
      {
        name: "25-35 Years",
        color: "#FF9499",
        data: ageTrends.twentyfiveToThirtyfiveTrend,
      },
      {
        name: "36-49 Years",
        color: "#89788D",
        data: ageTrends.thirtysixToFourtynineTrend,
      },
      {
        name: "50+ Years",
        color: "#D06283",
        data: ageTrends.moreThanFiftyTrend,
      }
    );
  } else if (selectedOption === "income") {
    series.push(
      {
        name: "<$50,000",
        color: "#04B8AF",
        data: incomeTrends.belowFiftyThousandTrend,
      },
      {
        name: "$50,000-$100,000",
        color: "#FF9499",
        data: incomeTrends.fiftyThousandToOnelakhTrend,
      },
      {
        name: "$100,000-$200,000",
        color: "#89788D",
        data: incomeTrends.OnelakhToTwolakhFiftyThousandTrend,
      },
      {
        name: "$250,000+",
        color: "#D06283",
        data: incomeTrends.moreThenTwolakhFiftyThousandTrend,
      }
    );
  } else if (selectedOption === "maritalStatus") {
    series.push(
      {
        name: "Married",
        color: "#04B8AF",
        data: maritalTrend.marriedTrend,
      },
      {
        name: "Unmarried",
        color: "#FF9499",
        data: maritalTrend.unMarriedTrend,
      }
    );
  } else if (selectedOption === "ethinicty") {
    series.push(
      {
        name: "African American",
        color: "#04B8AF",
        data: ethnicityTrend.africanAmericanTrend,
      },
      {
        name: "Asian",
        color: "#FF9499",
        data: ethnicityTrend.asianTrend,
      },
      {
        name: "Hispanic",
        color: "#89788D",
        data: ethnicityTrend.hispanicTrend,
      },
      {
        name: "White",
        color: "#D06283",
        data: ethnicityTrend.whiteTrend,
      },
      {
        name: "Others",
        color: "#666",
        data: ethnicityTrend.otherTrend,
      }
    );
  } else if (selectedOption === "serviceLength") {
    series.push(
      {
        name: "0-5 Years",
        color: "#04B8AF",
        data: experienceTrend.belowOneTrend,
      },
      {
        name: "1-5 Years",
        color: "#FF9499",
        data: experienceTrend.oneToFiveTrend,
      },
      {
        name: "5-9 Years",
        color: "#89788D",
        data: experienceTrend.fiveToNineTrend,
      },
      {
        name: "10+ Years",
        color: "#D06283",
        data: experienceTrend.moreThanTen,
      }
    );
  } else if (selectedOption === "performance") {
    series.push(
      {
        name: "Outstanding",
        color: "#04B8AF",
        data: performanceTrend.outstandingTrend,
      },
      {
        name: "Very Satisfactry",
        color: "#FF9499",
        data: performanceTrend.verySatisfactryTrend,
      },
      {
        name: "Satisfactry",
        color: "#89788D",
        data: performanceTrend.satisfactryTrend,
      },
      {
        name: "Unsatisfactry",
        color: "#D06283",
        data: performanceTrend.unSatisfactryTrend,
      },
      {
        name: "Poor",
        color: "#D06283",
        data: performanceTrend.poorTrend,
      }
    );
  }

  return (
    <div
      ref={moraleCompareRef}
      style={{
        height: "400px",
        borderRadius: "12px",
        backgroundColor: !isNight ? "#2A2953" : "#fff",

        position: "relative",
        margin: "20px 0 0 0.3rem",
      }}
    >
      {" "}
      <div
        style={{
          width: "97.5%",
          position: "relative",
          top: "15px",
          margin: "1rem",
          display: "flex",
          justifyContent: "space-between",
          margin: "1rem",
        }}
      >
        <p
          style={{
            margin: "0",
            textAlign: "left",
            fontWeight: 500,
            color: !isNight ? "#fff" : "#484848",
          }}
        >
          eNPS Comparison
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                eNPS Comparison by Groups
              </span>
            }
          >
            <InfoIcon
              style={{
                fontSize: "1rem",
                filter: "opacity(0.7)",
                position: "relative",
                top: "3px",
              }}
            />
          </Tooltip>
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                Available Events
              </span>
            }
          >
            <EventDrop />
          </Tooltip>
        </p>
        <div
          style={{
            position: "absolute",
            top: "28px",
            left: "30px",
          }}
        >
          <StatsOnTrendline userCount={teamSize} textCount={totalText} />
        </div>
        <div>
          <select
            style={
              !isNight
                ? {
                    border: "1px solid #aebcc9",
                    backgroundColor: "#212047",
                    color: "#aebcc9",
                    height: "2rem",
                    margin: "0 1rem ",
                    padding: "0.4rem",
                  }
                : {
                    height: "2rem",
                    margin: "0 1rem ",
                    padding: "0.4rem",
                  }
            }
            name="Workflow"
            className="dropdown"
            onChange={(e) => {
              setSelectedOption(e.target.value);
            }}
          >
            <option key={1} value="gender">
              Gender
            </option>

            <option key={2} value="department">
              Department
            </option>
            <option key={3} value="region">
              Region
            </option>
            <option key={4} value="location">
              Location
            </option>
            <option key={5} value="serviceLength">
              Length of Service
            </option>
            <option key={7} value="managementLevel">
              Management Level
            </option>
            <option key={8} value="ethinicty">
              Ethnicity
            </option>
            <option key={9} value="maritalStatus">
              Marital Status
            </option>
            <option key={10} value="income">
              Salary Level
            </option>
            <option key={11} value="age">
              Age
            </option>
            <option key={12} value="performance">
              Performance
            </option>
          </select>
          <Tooltip
            title={
              <span
                style={{
                  fontSize: "0.8rem",
                  textAlign: "center",
                }}
              >
                Copy to clipboard
              </span>
            }
          >
            {clipProcessing ? (
              <CircularProgress size={16} color="inherit" />
            ) : (
              <ContentCopyOutlinedIcon
                onClick={getImage}
                style={{
                  fontSize: "1.2rem",
                  cursor: clipProcessing ? "wait" : "pointer",
                }}
              />
            )}
          </Tooltip>
        </div>
      </div>
      {moraleCompareData.status === "success" || (isDemo && !isLoading) ? (
        <div>
          <div className="morale-compare-container">
            {/* {<CanvasJSChart options={options} id="morale-compare-container" />} */}
            <Chart
              options={options}
              series={series}
              type="line"
              width="100%"
              height="330px"
            />
          </div>
        </div>
      ) : moraleCompareData.status === "fail" || currentWorkflowID === "" ? (
        <div style={{ position: "relative", top: "100px" }}>
          <ErrorOutlineIcon style={{ color: "#94989f" }} />
          <p style={{ margin: "0", color: "#94989f" }}>
            {currentWorkflowID === "" ? "Select a workflow!" : "No Data found!"}
          </p>
        </div>
      ) : (
        <>
          <div style={{ margin: "2rem" }}>
            <Stack spacing={1}>
              <Skeleton
                variant="text"
                width={"100%"}
                height={"40px"}
                style={{ position: "relative", top: "10px" }}
              />
              <Skeleton
                variant="rectangular"
                width={"100%"}
                height={"200px"}
                style={{ position: "relative", top: "10px" }}
              />
            </Stack>
          </div>
        </>
      )}
    </div>
  );
}

export default OverallENPSCompare;
