import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import SidePanel from "./SidePanel";
import SurveyList from "./Survey/List";
import Survey from "./Survey";
import {
  GET_ALL_ACTION_PLANS,
  GET_ALL_FOCUS_GROUPS,
  GET_ALL_SURVEYS,
  GET_SURVEY_SETTINGS,
  GET_ALL_BASELINE_SURVEYS,
} from "../../../redux/Dashboard/Dashboard.types";
import { useDispatch, useSelector } from "react-redux";
import Result from "./Result";
import Report from "./Report";
import ActionPlan from "./ActionPlan";
import SettingsIcon from "@mui/icons-material/Settings";
import ResultList from "./Result/List";
import { demoFocusGroup, demoSurveys } from "./demoSurveys";
import { demoBaselineSurveys } from "./demoBaselineSurveys";
import FocusGroupList from "./FocusGroup/List";
import FocusGroup from "./FocusGroup/FocusGroup";
import Modal from "@material-ui/core/Modal";
import FocusGroupResList from "./FocusGroup/Results/List";
import FocusGroupRes from "./FocusGroup/Results/FocusGroup";
import BaselineSurvey from "./Survey/BaselineSurvey";
import BaselineRes from "./Result/BaselineSurvey";
import { useLocation } from "react-router-dom";
import Step10Modal from "../Walkthrough/Feedback/Step1Modal";
import Step1Modal from "../Walkthrough/Feedback/Step1Modal";
import Step2Modal from "../Walkthrough/Feedback/Step2Modal";
import Step3Modal from "../Walkthrough/Feedback/Step3Modal";

function Feedback() {
  const dispatch = useDispatch();
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const location = useLocation();
  const pathSegments = location.pathname.split("/");

  const [selectedTab, setSelectedTab] = useState("survey");
  const [selectedSurvey, setSeletedSurvey] = useState("");
  const [selectedFocusGroup, setSelectedFocusGroup] = useState("");

  const surveyData = isDemo
    ? demoSurveys
    : useSelector((data) => data.dashboard.surveyQuestion);

  const baselineSurveyData = isDemo
    ? demoBaselineSurveys
    : useSelector((data) => data.dashboard.baseLineSurveys);

  const focusGroups = isDemo
    ? demoFocusGroup
    : useSelector((data) => data.dashboard.focusGroups);

  useEffect(() => {
    surveyData.length > 0 && window.location.href.split("/")[6]
      ? setSeletedSurvey(window.location.href.split("/")[6])
      : setSeletedSurvey("");
    if (window.location.href.split("/")[5] === "survey") {
      setSelectedTab("survey");
    } else if (window.location.href.split("/")[5] === "result") {
      setSelectedTab("result");
    } else if (window.location.href.split("/")[5] === "report") {
      setSelectedTab("report");
    } else if (window.location.href.split("/")[5] === "action") {
      setSelectedTab("action");
    } else if (window.location.href.split("/")[5] === "focus-group") {
      setSelectedTab("focusGroup");
    } else if (window.location.href.split("/")[5] === "focus-group-res") {
      setSelectedTab("focusGroupRes");
    } else if (window.location.href.split("/")[5] === "baseline") {
      setSelectedTab("baseline");
    } else if (window.location.href.split("/")[5] === "baseline-res") {
      setSelectedTab("baselineRes");
    } else if (window.location.href.split("/")[4] === "feedback") {
      setSelectedTab("survey");
    }
  });

  useEffect(() => {
    if (
      selectedTab === "result" &&
      (window.location.href.split("/")[6] !== "insights-surveys" ||
        window.location.href.split("/")[6] !== "baseline-surveys")
    ) {
      setSeletedSurvey(window.location.href.split("/")[6] || "");
    }
    if (selectedTab === "baselineRes") {
      setSeletedSurvey(window.location.href.split("/")[7] || "");
    }
  }, [selectedTab, surveyData]);

  useEffect(() => {
    if (focusGroups.length > 0) {
      setSelectedFocusGroup(window.location.href.split("/")[6] || "");
    }
  }, [focusGroups, selectedSurvey]);

  useEffect(() => {
    !isDemo && dispatch({ type: GET_ALL_SURVEYS });
    !isDemo && dispatch({ type: GET_ALL_BASELINE_SURVEYS });
    !isDemo && dispatch({ type: GET_ALL_FOCUS_GROUPS });
    !isDemo && dispatch({ type: GET_SURVEY_SETTINGS });
  }, []);

  let skipped = !JSON.parse(localStorage.getItem("skipped"));

  const [openIntroTutModal, setOpenIntroTutModal] = useState(true);
  const [currentStep, setCurrentStep] = useState(1);

  const handleCloseIntroTutModal = () => {
    setOpenIntroTutModal(false);
  };

  return (
    <Box sx={{ marginTop: "0" }}>
      {skipped ? (
        <Modal
          open={openIntroTutModal}
          onClose={handleCloseIntroTutModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {currentStep === 1 ? (
            <Step1Modal
              handleClose={handleCloseIntroTutModal}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
            />
          ) : currentStep === 2 ? (
            <Step2Modal
              handleClose={handleCloseIntroTutModal}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              setSelectedTab={setSelectedTab}
            />
          ) : currentStep === 3 ? (
            <Step3Modal
              handleClose={handleCloseIntroTutModal}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              setSelectedTab={setSelectedTab}
            />
          ) : (
            <Step3Modal
              handleClose={handleCloseIntroTutModal}
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              setSelectedTab={setSelectedTab}
            />
          )}
        </Modal>
      ) : (
        ""
      )}
      <Grid container spacing={0}>
        <Grid item xs={2}>
          <SidePanel
            setSelectedTab={setSelectedTab}
            setSeletedSurvey={setSeletedSurvey}
            selectedTab={selectedTab}
          />
        </Grid>
        <Grid item xs={10}>
          <Box
            sx={{ width: "100%", height: "50px", background: "#f2f2f2" }}
          ></Box>
          {selectedTab === "survey" && selectedSurvey === "" ? (
            <SurveyList setSeletedSurvey={setSeletedSurvey} />
          ) : (
            selectedTab === "survey" && (
              <Survey
                surveyID={selectedSurvey}
                setSelectedTab={setSelectedTab}
              />
            )
          )}
          {selectedTab === "result" &&
          (selectedSurvey === "" ||
            selectedSurvey === "insights-surveys" ||
            selectedSurvey === "baseline-surveys" ||
            selectedSurvey === "recurring-surveys" ||
            selectedSurvey === "custom-surveys" ||
            selectedSurvey === "archived-surveys") ? (
            <ResultList setSeletedSurvey={setSeletedSurvey} />
          ) : (
            selectedTab === "result" && <Result surveyID={selectedSurvey} />
          )}
          {selectedTab === "report" && (
            <Report setSelectedTab={setSelectedTab} />
          )}
          {selectedTab === "action" && <ActionPlan />}
          {selectedTab === "focusGroup" && selectedFocusGroup === "" ? (
            <FocusGroupList setSelectedFocusGroup={setSelectedFocusGroup} />
          ) : (
            selectedTab === "focusGroup" && (
              <FocusGroup focusGroupID={selectedFocusGroup} />
            )
          )}
          {selectedTab === "focusGroupRes" && selectedFocusGroup === "" ? (
            <FocusGroupResList setSelectedFocusGroup={setSelectedFocusGroup} />
          ) : (
            selectedTab === "focusGroupRes" && (
              <FocusGroupRes focusGroupID={selectedFocusGroup} />
            )
          )}
          {selectedTab === "baseline" && (
            <BaselineSurvey
              surveyID={selectedSurvey}
              setSelectedTab={setSelectedTab}
            />
          )}
          {selectedTab === "baselineRes" &&
            selectedSurvey !== "baseline-surveys" && (
              <BaselineRes
                surveyID={selectedSurvey}
                setSelectedTab={setSelectedTab}
              />
            )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Feedback;
