import React from "react";

const Step1Modal = ({
  handleClose,
  setCurrentScreen,
  currentScreen,
  currentStep,
  setCurrentStep,
}) => {
  const handleNextStep = () => {
    // handleClose();
    // setCurrentScreen("logic");
    setCurrentStep(2);
  };

  const handleSkip = () => {
    handleClose();
    localStorage.setItem("skipped", true);
  };

  return (
    <>
      
      <div
        className="addChartsContainer"
        style={{
          width: "24vw",
          marginTop: "55px",
          position: "absolute",
          left: "12.5%",
        }}
      >
        <div style={{ color: "#484848", fontSize: "1rem" }}>
          <b> Welcome to the Insights Page!</b>
        </div>
        <p style={{ color: "#484848" }}>
          Here, you can access all the key insights, including{" "}
          <b>
            Morale, Engagement, Retention Risk, eNPS (Employee Net Promoter
            Score), Key Topics, Culture Metrics
          </b>
        </p>
        <div className="btnOptions" style={{ marginTop: "0px" }}>
          <div className="submitBtn">
            <div className="rangeBtn" type="submit" onClick={handleNextStep}>
              <div className="btnText">{"NEXT >>"}</div>
            </div>
          </div>
          <div className="submitBtn">
            <div className="rangeBtn" type="submit" onClick={handleSkip}>
              <div className="btnText">{"SKIP >>>>"}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step1Modal;
