import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import {
  GET_NEGATIVE_CULURE_THRESHOLD,
  SAVE_NEGATIVE_CULURE_THRESHOLD,
} from "../../../../../redux/Dashboard/Dashboard.types";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
// {
//   "hate_speech": 5,
//   "insult": 5,
//   "sexual_harassment": 5,
//   "racism": 5,
//   "toxic": 5,
//   "severe_toxic": 5,
//   "obscene": 5,
//   "threat": 5,
//   "identity_hate": 5
// }
function NegativeConfigModal({ plan, handleClose }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: GET_NEGATIVE_CULURE_THRESHOLD });
  }, []);
  const negativeThreshold = useSelector(
    (data) => data.dashboard.negativeThreshold
  );


  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  const [hate_speech, set_hate_speech] = useState(
    negativeThreshold.hate_speech
  );
  const [insult, set_insult] = useState(negativeThreshold.insult);
  const [sexual_harassment, set_sexual_harassment] = useState(
    negativeThreshold.sexual_harassment
  );
  const [racism, set_racism] = useState(negativeThreshold.racism);
  const [toxic, set_toxic] = useState(negativeThreshold.toxic);
  const [severe_toxic, set_severe_toxic] = useState(
    negativeThreshold.severe_toxic
  );
  const [obscene, set_obscene] = useState(negativeThreshold.obscene);
  const [threat, set_threat] = useState(negativeThreshold.threat);
  const [identity_hate, set_identity_hate] = useState(
    negativeThreshold.identity_hate
  );
  const handleSave = () => {
    dispatch({
      type: SAVE_NEGATIVE_CULURE_THRESHOLD,
      payload: {
        hate_speech: parseInt(hate_speech),
        insult: parseInt(insult),
        sexual_harassment: parseInt(sexual_harassment),
        racism: parseInt(racism),
        toxic: parseInt(toxic),
        severe_toxic: parseInt(severe_toxic),
        obscene: parseInt(obscene),
        threat: parseInt(threat),
        identity_hate: parseInt(identity_hate),
      },
    });
    setTimeout(() => {
      dispatch({ type: GET_NEGATIVE_CULURE_THRESHOLD });
    }, 3000);
  };

  return (
    <Box sx={style}>
      <Typography id="modal-modal-title" variant="h6" component="h2">
        Negative Culture Threshold
      </Typography>
      <CloseIcon
        style={{
          cursor: "pointer",
          position: "absolute",
          right: "20px",
          top: "20px",
        }}
        onClick={handleClose}
      />
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Hate Speech"
            variant="outlined"
            value={hate_speech}
            onChange={(e) => set_hate_speech(e.target.value)}
          />
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Insult"
            variant="outlined"
            value={insult}
            onChange={(e) => set_insult(e.target.value)}
          />
        </Stack>
      </Box>
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Sexual Harassment"
            variant="outlined"
            value={sexual_harassment}
            onChange={(e) => set_sexual_harassment(e.target.value)}
          />
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Racism"
            variant="outlined"
            value={racism}
            onChange={(e) => set_racism(e.target.value)}
          />
        </Stack>
      </Box>
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Toxic"
            variant="outlined"
            value={toxic}
            onChange={(e) => set_toxic(e.target.value)}
          />
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Severe Toxic"
            variant="outlined"
            value={severe_toxic}
            onChange={(e) => set_severe_toxic(e.target.value)}
          />
        </Stack>
      </Box>
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Obscene"
            variant="outlined"
            value={obscene}
            onChange={(e) => set_obscene(e.target.value)}
          />
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Threat"
            variant="outlined"
            value={threat}
            onChange={(e) => set_threat(e.target.value)}
          />
        </Stack>
      </Box>
      <Box
        sx={{
          margin: "1rem 0",
        }}
      >
        <Stack direction="row" spacing={2}>
          <TextField
            fullWidth
            id="question-text"
            type="number"
            label="Identity Hate"
            variant="outlined"
            value={identity_hate}
            onChange={(e) => set_identity_hate(e.target.value)}
          />
        </Stack>
      </Box>

      <Stack direction="row" spacing={2} justifyContent="end">
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          style={{
            background: "#D06283",
            color: "#fff",
          }}
          variant="contained"
        >
          Save
        </Button>
      </Stack>
    </Box>
  );
}

export default NegativeConfigModal;
