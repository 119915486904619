import React, { useState } from "react";
import { ICONS } from "../../../../AppConfig";
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import config from "./config.js";
import MessageParser from "./MessageParser";
import ActionProvider from "./ActionProvider";
import CloseIcon from "@mui/icons-material/Close";
import "./index.css";
function ChatBot() {
  let [showChat, setShowChat] = useState(false);

  const startChat = () => {
    setShowChat(true);
  };
  const hideChat = () => {
    setShowChat(false);
  };

  return (
    <div className="bot">
      <div style={{ display: showChat ? "" : "none" }}>
        <div className="bot-box">
          <div className="bot-close-icon" onClick={hideChat}>
            <CloseIcon />
          </div>
          <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
          />
        </div>
      </div>

      {/* <div style={{ display: "flex", justifyContent: "end" }}>
        {!showChat ? (
          <>
            <img
              src={ICONS.CHATBTN}
              alt="chat button"
              className="bot-button"
              onClick={startChat}
            />
            <div className="bot-button-text" onClick={startChat}>
              Ask me anything
            </div>
          </>
        ) : (
          <></>
        )}
      </div> */}
    </div>
  );
}

export default ChatBot;
