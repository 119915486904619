/* eslint-disable import/prefer-default-export */
import api from "./axiosConfig";

export function* checkDomain(action) {
    let resp;
    yield api
        .post("/saveDemo", action.payload)
        .then((response) => {
            resp = response;
            
        })
        .catch((error) => {
            resp = error;
        });
    return resp;
}
