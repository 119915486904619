import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import { toast } from "react-toastify";
import LinearProgress from "@material-ui/core/LinearProgress";
import { Box, Typography, Button, withStyles } from "@material-ui/core";
import { UPLOAD_SOURCE_FILES } from "../../../../redux/Dashboard/Dashboard.types";

const BorderLinearProgress = withStyles(() => ({
  root: {
    height: 15,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: "#EEEEEE",
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);

export default function FileUploader({ handleClose }) {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentFile, setCurrentFile] = useState();
  const [progress, setProgess] = useState({
    message: "",
    percentage: 0,
    isError: false,
  });
  const uploadStatus = useSelector((data) => data.dashboard.uploadSourceStatus);
  const isDemo = JSON.parse(localStorage.getItem("demoMode"));

  // eslint-disable-next-line no-underscore-dangle
  const user = JSON.parse(localStorage.getItem("userData"));
  const userID = user?.userId;
  const dispatch = useDispatch();

  // const handleSave = () => {
  //   if (!isDemo) {
  //     dispatch({
  //       type: SAVE_WORKFLOW,
  //       payload: {
  //         workflowID: userID + Math.round(new Date().getTime() / 1000),
  //         workflow: elements,
  //         token: accessToken && accessToken?.data?.authed_user?.access_token,
  //         teamFilters: channelsToFetch,
  //         discordServerID,
  //         workflowName,
  //         userID,
  //         clientID: JSON.parse(localStorage.getItem("userData")).userId,
  //         fileName: uploadSourceStatus.fileName,
  //         model_config: modelsConfigData,
  //         action_config: actionConfigData,
  //       },
  //     });
  //   } else {
  //     var demoWork = userWorkflows;
  //     demoWork.data?.Workflows?.push({
  //       workflowID: userID + Math.round(new Date().getTime() / 1000),
  //       workflowName,
  //     });
  //     dispatch({ type: SAVE_DEMO_WORK, payload: demoWork });
  //     setTimeout(() => {
  //       toast.success("Workflow Saved");
  //     }, 1000);
  //   }
  // };

  useEffect(() => {
    if (uploadStatus.status === "FAIL") {
      setProgess({
        message: "FAILED!! Please upload the correct file",
        percentage: 0,
        isError: true,
      });
    } else if (uploadStatus.status === "PASS") {
      setProgess({
        message: "File Successfully uploaded",
        percentage: 100,
        isError: false,
      });
      // handleSave();
    } else if (uploadStatus.status === "EMPTY") {
      setProgess({
        message: "",
        percentage: 0,
        isError: false,
      });
    }
  }, [uploadStatus]);

  const selectFile = (event) => {
    setSelectedFiles(event.target.files);
  };

  const upload = () => {
    const file = selectedFiles[0];
    if (!file) {
      toast.error("Please select file first");
    } else {
      setCurrentFile(file);
      !isDemo &&
        dispatch({
          type: UPLOAD_SOURCE_FILES,
          payload: {
            file,
            userID,
            fileName: selectedFiles[0].name,
          },
        });
    }

    isDemo &&
      setTimeout(() => {
        setProgess({
          message: "File Successfully uploaded",
          percentage: 100,
          isError: false,
        });
      }, 2000);
  };

  const handleFileClose = () => {
    setProgess(
      {
        message: "",
        percentage: 0,
        isError: false,
      },
      handleClose(false)
    );
  };

  return (
    <div className="fileUploaderContainer">
      <CloseIcon
        classname="closeFileUploader"
        style={{ fill: "black", position: "absolute", top: 20, right: 30 }}
        onClick={handleFileClose}
      />
      <div className="uploaderTitle">Archive File Uploader</div>
      {currentFile && (
        <Box className="mb25" display="flex" alignItems="center">
          <Box width="100%" mr={1}>
            <BorderLinearProgress
              variant="determinate"
              value={progress.percentage}
            />
          </Box>
          <Box minWidth={35}>
            <Typography
              variant="body2"
              color="textSecondary"
            >{`${progress.percentage}%`}</Typography>
          </Box>
        </Box>
      )}
      <div className="btnAndUpload">
        <div className="fileNameAndInput">
          <label htmlFor="btn-upload">
            <input
              id="btn-upload"
              name="btn-upload"
              style={{ display: "none" }}
              type="file"
              onChange={selectFile}
            />
            <Button className="btn-choose" variant="outlined" component="span">
              Choose Files
            </Button>
          </label>
          <div className="file-name">
            {selectedFiles && selectedFiles.length > 0
              ? selectedFiles[0].name
              : null}
          </div>
        </div>
        <Button
          className="btn-upload"
          color="primary"
          variant="contained"
          component="span"
          disabled={!selectedFiles}
          onClick={upload}
        >
          Upload
        </Button>
      </div>
      <Typography
        variant="subtitle2"
        className={`upload-message ${progress.isError ? "error" : ""}`}
      >
        {progress.message}
      </Typography>
    </div>
  );
}
