import { toast } from "react-toastify";
import api from "../axiosConfig";
import { SessionOut } from "../../utilities/dashboardUtils";

// eslint-disable-next-line import/prefer-default-export
export function* deleteWorkflowData(action) {
  let resp;
  yield api
    .post("/dWorkflow", action.payload, {
      headers: {
        "Access-Control-Allow-Headers": "x-access-token",
        "x-access-token": localStorage.getItem("auth-token"),
      },
    })
    .then((response) => {
      if (response.status === 404) {
        toast.error("Error in deleting workflow");
      } else if (response.status === 400) {
        SessionOut();
      } else {
        resp = response;
        toast.success("Workflow Deleted Successfully");
      }
    })
    .catch((err) => {
      resp = err;
      toast.error("ERROR IN DELETING");
    });
  return resp;
}
